import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy
} from "@angular/core";
import {
  trigger,
  state,
  keyframes,
  animate,
  transition,
  style
} from "@angular/animations";
import { Subscription } from "rxjs";
import { DateInterval } from "../../../shared/model";
import { MileageService } from "../../historical/mileage/mileage.service";
import { DatePipe } from "@angular/common";
import { GroupDto } from "../../data-management/data-management.model";
import { Router } from "@angular/router";
import { MapService } from "../../../utils/leaflet/service/map.service";
import { DashbordInfo } from "../../parc-management/model";
import { ModalDirective } from "ngx-bootstrap/modal";
import { DashbordService } from "../../parc-management";
import { DataManagementService } from "../../data-management";
import { Notification, Mileage } from "../../overview/notification";
const Highcharts = require('highcharts');
require('highcharts/modules/exporting')(Highcharts);

if (!Highcharts.Chart.prototype.addSeriesAsDrilldown) {
  require('highcharts/modules/drilldown')(Highcharts);
}

@Component({
  selector: 'app-tmm',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss'],
  animations: [
    trigger("flyInOut", [
      state("in", style({ transform: "translateX(0)" })),
      transition("void => *", [
        animate(
          900,
          keyframes([
            style({ opacity: 0, transform: "translateX(-100%)", offset: 0 }),
            style({ opacity: 1, transform: "translateX(15px)", offset: 0.3 }),
            style({ opacity: 1, transform: "translateX(0)", offset: 1.0 })
          ])
        )
      ]),
      transition("* => void", [
        animate(
          300,
          keyframes([
            style({ opacity: 1, transform: "translateX(0)", offset: 0 }),
            style({ opacity: 1, transform: "translateX(-15px)", offset: 0.7 }),
            style({ opacity: 0, transform: "translateX(100%)", offset: 1.0 })
          ])
        )
      ])
    ])
  ]
})
export class ActivityComponent implements OnInit, OnDestroy {

  loaded$: Subscription;

  show: boolean = true;
  notifications: Notification[] = [];
  mileages: Mileage[] = [];
  dashbordInfo: DashbordInfo | null = null;
  groups: GroupDto[] = [];
  options: Object | null = null;
  groupeStatistics: any[] = [];
  cost: number = 0;
  idGroup: number = 0;
  idVehicule: number = -1;
  date: DateInterval = new DateInterval();
  endDate: Date = new Date();
  startDate: Date = new Date();
  selectedMonth?: number;
  loader: boolean = false;
  error: boolean = false;
  months = [
    { id: 0, name: 'Janvier' },
    { id: 1, name: 'Février' },
    { id: 2, name: 'Mars' },
    { id: 3, name: 'Avril' },
    { id: 4, name: 'Mai' },
    { id: 5, name: 'Juin' },
    { id: 6, name: 'Juillet' },
    { id: 7, name: 'Août' },
    { id: 8, name: 'Septembre' },
    { id: 9, name: 'Octobre' },
    { id: 10, name: 'Novembre' },
    { id: 11, name: 'Décembre' }
  ];

  now: Date = new Date();
  @ViewChild("dashboardModal", { static: false }) dashboardModal!: ModalDirective;

  constructor(
    private mileageService: MileageService,
    public router: Router,
    private mapService: MapService,
    public datepipe: DatePipe,
    private dashbordService: DashbordService,
    private groupService: DataManagementService
  ) {
    this.loaded$ = this.mapService.mapLoaded.subscribe((value: any) => {
      this.mapService.map.removeLayer(this.mapService.baseMaps['Google Sat']);
      this.mapService.map.addLayer(this.mapService.baseMaps.OpenStreetMap);
    });
  }

  loadCostParc() {
    this.loader = true;
    this.error = false;
    const date = new Date();
    date.setMonth(this.selectedMonth ?? 0); // Use default value if selectedMonth is undefined
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.now = lastDay;
    this.dashbordInfo = null;
    this.dashbordService.getDashbordInfo(-1, -1, {
      startDate: firstDay.getTime(),
      endDate: lastDay.getTime()
    }).subscribe(() => {
      this.loader = false;
    }, () => {
      this.error = true;
      this.loader = false;
    });
  }

  ngOnInit() {
    this.show = true;
    const endTime = new Date();
    const month = endTime.getMonth() + 1;
    const startTime = new Date(`${endTime.getFullYear()}-${month}-${endTime.getDate()}`);

    const dateInterval = new DateInterval();
    dateInterval.startDate = startTime;
    dateInterval.endDate = endTime;

    this.mileageService.getMileageDetailsParc(dateInterval)
      .subscribe(response => {
        this.mileageManipulation(response);
      });
    this.loadGroups();
  }

  loadGroups() { 
    this.groupService.getAllGroups().subscribe(groups => {
      this.groups = groups; 
    });
  }

  mileageManipulation(mileages: Mileage[]) {
    for (const mileage of mileages) {
      this.prototypeToHHMMSS(mileage);
      this.mileages.push(mileage);
    }
  }

  prototypeToHHMMSS(mileage: Mileage) {
    const hours = Math.floor(mileage.drivingDuration / 3600);
    const minutes = Math.floor((mileage.drivingDuration - hours * 3600) / 60);
    const seconds = mileage.drivingDuration - hours * 3600 - minutes * 60;

    mileage.drivingDurationStr = hours === 0
      ? `${minutes} min ${seconds} sec`
      : `${hours} h ${minutes} min ${seconds} sec`;
  }

  changeContentStyle() {
    const x = document.getElementsByClassName("content") as HTMLCollectionOf<HTMLElement>;
    Array.from(x).forEach(el => {
      el.style.minHeight = el.style.minHeight === "0px" ? "250px" : "0px";
    });
  }

  ngOnDestroy(): void {
    if (this.loaded$) {
      this.loaded$.unsubscribe();
    }
  }
}
