import { Component, OnInit, OnDestroy } from '@angular/core';
import { DataManagementService } from '../../../data-management/data-management.service';
import { ToastrService } from 'ngx-toastr';
import { PointInterest } from '../../../data-management/data-management.model';
import { Alert, AlertConfiguration } from '../../alert';
import { AlertManagementService } from '../../alert-management.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-zone-settings',
  templateUrl: './zone-settings.component.html',
  styleUrls: ['./zone-settings.component.css']
})
export class ZoneSettingsComponent implements OnInit, OnDestroy {
  public showAdvancedSettings = false;
  public loader = false;

  public zones: PointInterest[] = [];
  public zoneItems: any[] = [];
  public zonesSelected: any[] = [];
  public selectedItem: string = "";
  public enableGlobalConfig = false;
  public enableGlobalConfigToAllVehicules = false;

  public stopTimeSelectMode = true;

  public minutes: string[] = [];
  public alert: Alert = new Alert();
  public zoneAlertConfiguration: AlertConfiguration = new AlertConfiguration();
  public cronAlertConfiguration: AlertConfiguration = new AlertConfiguration();

  public getVehiculeWasSelectedSubscription: Subscription;

  constructor(
    private dataManagementService: DataManagementService,
    private toastr: ToastrService,
    private alertManagementService: AlertManagementService
  ) {
    this.initMinutes();
  }

  ngOnInit() {
    this.loadPOI();
    if (this.dataManagementService.selectedVehicule) {
      this.loadAlertConfigOfDevice(
        this.dataManagementService.selectedVehicule.idDevice
      );
    }
    this.getVehiculeWasSelectedSubscription = this.dataManagementService
      .getVehiculeWasSelected()
      .subscribe(vehicule => {
        this.loadAlertConfigOfDevice(
          this.dataManagementService.selectedVehicule.idDevice
        );
      });
      console.log(this.zonesSelected)
  }

  ngOnDestroy() {
    if (this.getVehiculeWasSelectedSubscription) {
      this.getVehiculeWasSelectedSubscription.unsubscribe();
    }
  }

  loadAlertConfigOfDevice(device: number) {
    this.loader = true;
    this.alertManagementService
      .getAlertByNameAndDeviceId('ZONE', device)
      .subscribe(
        alert => {
          this.alert = alert;

          this.cronAlertConfiguration = this.alert.alertConfigurations.filter(
            alertConfiguration => alertConfiguration.type === 'DATE'
          )
            ? this.alert.alertConfigurations.filter(
              alertConfiguration => alertConfiguration.type === 'DATE'
            )[0]
            : this.cronAlertConfiguration;

          this.zoneAlertConfiguration = this.alert.alertConfigurations.filter(
            alertConfiguration => alertConfiguration.type === 'ZONE'
          )
            ? this.alert.alertConfigurations.filter(
              alertConfiguration => alertConfiguration.type === 'ZONE'
            )[0]
            : this.zoneAlertConfiguration;

          if (this.zoneAlertConfiguration && this.zoneAlertConfiguration.value1) {
            this.zonesSelected = [...this.selectedItemfromPoiId(this.zoneAlertConfiguration.value1)];
            this.zoneAlertConfiguration.value1 = +this.zoneAlertConfiguration.value1;
            this.selectedzone(this.zonesSelected);
          }

          
          this.loader = false;
          this.toastr.success('Configuration chargée', 'info !', {
            timeOut: 1000
          });
          console.log(this.zonesSelected)
        },
        () => {
          this.loader = false;
          this.toastr.error('Configuration non chargée', 'info !', {
            timeOut: 1000
          });
        }
      );
  }

  collapseAdvancedSettings() {
    this.showAdvancedSettings = !this.showAdvancedSettings;
  }

  //GET list of Points and Polygons
  loadPOI() {
    let poi: PointInterest = new PointInterest();
    poi.name = 'Tout les zones';
    poi.idPointInterest = null;
    if (this.dataManagementService.pointInterests) {
      this.zones = this.dataManagementService.getPolygons();
      this.zones.unshift(poi);
      this.zones.push(poi);
      this.zoneItems = this.poiToItem(this.zones);
    } else {
      this.dataManagementService
        .getAllPointInterests()
        .subscribe(pointInterests => {
          this.dataManagementService.pointInterests = pointInterests;
          this.zones = this.dataManagementService.getPolygons();
          this.zoneItems = this.poiToItem(this.zones);
          this.zones.unshift(poi);
        });
    }
  }



  initMinutes() {
    for (let i = 1; i <= 12; i++) {
      this.minutes.push(i * 10 + '');
    }
    this.minutes.push('Autre..');
  }

  emailSettings(emailsSettings: { emailMode: boolean; emails: string; enableGlobalConfig: boolean, enableGlobalConfigToAllVehicules: boolean  }) {
    this.alert.emailMode = emailsSettings.emailMode;
    this.alert.emails = emailsSettings.emails;
    this.enableGlobalConfig = emailsSettings.enableGlobalConfig;
    this.enableGlobalConfigToAllVehicules = emailsSettings.enableGlobalConfigToAllVehicules;
    if (emailsSettings.enableGlobalConfig || emailsSettings.enableGlobalConfigToAllVehicules) {
      this.updateEmails();
    }
  }
  selectedItemfromPoiId(value1: any) {
    let zoneId = ("" + value1).split(';');
    let pois = [];
    let poi;
    for (let id of zoneId) {
      poi = this.zones.filter(p => p.idPointInterest === +id);
      if (poi && poi[0]) {
        pois.push({ "id": poi[0].idPointInterest, "text": poi[0].name });
      }
    }
    return pois;
  }
  poiToItem(zones: any) {
    let items = [];
    for (let zone of zones) {
      items.push({ "id": zone.idPointInterest, "text": zone.name });
    }
    return items;
  }
  selectedzone($event: any[]) {
    this.selectedItem = "";
    for (let zone of $event) {
      if (this.selectedItem != "") {
        this.selectedItem = this.selectedItem + ";" + zone.id;
      } else {
        this.selectedItem = zone.id;
      }
    }
  }
  compareZones(item1: any, item2: any): boolean {
    return item1 && item2 ? item1.id === item2.id : item1 === item2;
  }

  save() {
    this.loader = true;
    this.alert.enableGlobalConfig = this.enableGlobalConfig;

    const zoneAlert = this.alert.alertConfigurations.find(alert => alert.type == "ZONE");
    if (zoneAlert) {
      zoneAlert.value1 = this.selectedItem;
    }

    this.alertManagementService.updateAlert(this.alert).subscribe(
      alert => {
        this.alert = alert;
        this.loader = false;
        this.toastr.success('Configuration enregistrée', 'info !');
      },
      error => {
        this.toastr.error('Configuration non enregistrée ', 'info !');
        this.loader = false;
      }
    );
  }

  updateEmails() {
    let id = 0;
    if (this.enableGlobalConfig || this.enableGlobalConfigToAllVehicules) {
      id = this.alert.deviceId;
    } else {
      id = this.alert.id;
    }
    this.alertManagementService.setEmailToAllAlert(this.alert.emails, id, this.enableGlobalConfig, this.enableGlobalConfigToAllVehicules)
      .subscribe(res => {
        if (res) {
          this.toastr.success('emails bien copiés ');
        } else {
          this.toastr.warning('Veuillez reverifier');
        }
      }
      )
  }
}
