import { Injectable, EventEmitter } from '@angular/core';
import { RtMarker } from '../marker';
import { imagesDir, token_google } from '../../../global.config';
import * as P from 'leaflet';
import Marker = P.Marker;
import Icon = P.Icon;
import Polyline = P.Polyline;
import Polygon = P.Polygon;
import Circle = P.Circle;
import FeatureGroup = P.FeatureGroup;
import 'leaflet.markercluster';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import drawLocales from 'leaflet-draw-locales';
import { DatePipe } from '@angular/common';
import { DurationSecPipe } from 'src/app/pipes/duration-sec.pipe';

import { ColorService } from '../../services/color.service';
import { Group } from 'src/app/client-management/parc-management';


@Injectable({
  providedIn: 'root',
})
export class MapService {
  markers: Marker[] = [];
  rtMarkers: RtMarker[] = [];
  polylines: Polyline[] = [];
  polygons: Polygon[] = [];
  circles: Circle[] = [];
  markersPoi: Marker[] = [];
  polygonsPoi: Polygon[] = [];
  theTrajetIndecator: Marker[] = [];
  markercluster = P.markerClusterGroup();
  regionPolygons: { [id: string]: P.Polygon } = {};


  stopExecution = false;
  
  pointMarkers: Marker[] = [];
  stopMarkers: Marker[] = [];
  fulMarkers: Marker[] = [];

  map: P.Map;
  baseMaps: any;
  drawnItems: FeatureGroup;
  drawControl: P.Control.Draw;
  isHidden: boolean = false;

  mapLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();
  mapLocked: boolean= false;
  isFullscreen = false;
  drawIntrests: EventEmitter<boolean> = new EventEmitter<boolean>();

  markerWasAdded: EventEmitter<any> = new EventEmitter<any>();
  markerWasEdited: EventEmitter<any> = new EventEmitter<any>();

  constructor(private _http: HttpClient, private colorService: ColorService) {
    // Automatically defines Leaflet.draw to the specified language
    drawLocales('fr');

    this.baseMaps = {
      OpenStreetMap: P.tileLayer(
        'http://tile.ttn.tn/osm/{z}/{x}/{y}.png',
        {
          maxZoom: 20,
          maxNativeZoom: 17,
          attribution:
            '&copy; <a href="rimtelecom.ma">Rim telecom</a>, Tiles courtesy of <a href="http://hot.openstreetmap.org/" target="_blank">Humanitarian OpenStreetMap Team</a>',
        }
      ),
      'Google Hybrid': P.tileLayer(
        'https://{s}.google.com/vt/lyrs=s,h&gl=MA&x={x}&y={y}&z={z}',
        {
          maxZoom: 20,
          subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
        }
      ),
      'Google Sat': P.tileLayer(
        'https://{s}.google.com/vt/lyrs=s&gl=MA&x={x}&y={y}&z={z}',
        {
          maxZoom: 20,
          subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
        }
      ),
    };
  }

  init() {
    this.polygons = [];
    this.markers = [];
    this.polylines = [];
    this.circles = [];
    this.rtMarkers = [];
    this.markersPoi = [];
    this.polygonsPoi = [];
  }

  initCluster() {
      this.markercluster = P.markerClusterGroup({
        disableClusteringAtZoom: 12,
      });
  }

  buttonConfig(){
    var CustomLockedControl = this.lockedButton();
    var CustomHideButton = this.hideButton();
    var CustomShowPoi = this.showPoi();
    
    
    this.map.addControl(new CustomLockedControl({ position: 'topright' }));
    this.map.addControl(new CustomHideButton({ position: 'topright' }));
    this.map.addControl(new CustomShowPoi({ position: 'topright' }));
    
  }

  addRtMarker(rtMarker: RtMarker) {
    rtMarker.value.setIcon(rtMarker.icon);
    this.rtMarkers.push(rtMarker);
    rtMarker.value.addTo(this.map);
  }

  removeLayers(){
    this.map.removeLayer(this.markercluster);
  } 
  
  controlMarkers(id: number, isHidden: boolean) {
    const marker = this.getRTMarker(id);
    if(marker !== undefined){
      const markerToKeep = marker.value;

      this.markercluster.removeLayer(markerToKeep);
  
      if (isHidden) {
        this.map.removeLayer(this.markercluster);
  
        this.map.addLayer(markerToKeep);
      } else {
        this.map.removeLayer(markerToKeep);
  
        this.markercluster.addLayer(markerToKeep);
  
        this.map.addLayer(this.markercluster);
      }
    }
    
  }

  getRTMarker(id: number): RtMarker {
    var res = this.rtMarkers.filter((rtMarker) => rtMarker.id === id);
    if (res) {
      return res[0];
    } else null;
  }

  updateRtMarker(
    rtMarker: RtMarker,
    isCurrentPathClicked: boolean,
    isHidden: boolean
  ) {
    let ok: boolean = false;
    if(this.stopExecution){
      return;
    }
    this.rtMarkers.map((rt) => {
      if (rt.id == rtMarker.id) {
        if (isCurrentPathClicked == true) {
          let marker = new Marker(rt.value.getLatLng());
          marker.setIcon(
            new Icon({
              iconUrl: imagesDir + 'green-point.png',
              iconAnchor: [2, 2],
            })
          );
          marker.on('click', () => {
            this.map.setView(rt.value.getLatLng(), 17);
          });
          this.markers.push(marker);
          this.markercluster.addLayer(marker);
          let points = [];
          points.push(rt.value.getLatLng());
          points.push(rtMarker.value.getLatLng());
          let polyline = P.polyline(points, { color: '#0031D9', weight: 3 });
          this.addPolyline(polyline);
        }
        rt.value.setLatLng(rtMarker.value.getLatLng());
        rt.value.getPopup().setContent(rtMarker.value.getPopup().getContent());
        rt.value.on('click', () => {
          this.map.setView(rtMarker.value.getLatLng(), 16);
        });
        rt.value.options.rotationAngle = rtMarker.angle;
        rt.value.setIcon(rtMarker.icon);
        ok = true;
      }
    });
    if (!ok) {
      rtMarker.value.setIcon(rtMarker.icon);
      this.rtMarkers.push(rtMarker);
      this.markercluster.addLayer(rtMarker.value);
    }
    this.controlMarkers(rtMarker.id, isHidden);
  }

  removeAllRtMarkers() {
    this.rtMarkers.forEach((m) => {
      this.map.removeLayer(m.value);
    });
    this.rtMarkers = [];
  }

  setView(coordinate: any) {
    this.map.setView(coordinate, 15);
  }

  addCircle(circle: Circle) {
    this.circles.push(circle);
    circle.addTo(this.map);
  }

  removeCirclesFromMap() {
    this.circles.forEach((m) => {
      this.map.removeLayer(m);
    });
    this.circles = [];
  }

  addMarker(marker: Marker) {
    this.markers.push(marker);
    marker.addTo(this.map);
  }

  addMarkerPoi(marker: Marker) {
    this.markersPoi.push(marker);
    marker.addTo(this.map);
  }

  removeMarkersPoiFromMap() {
    this.markersPoi.forEach((m) => {
      this.map.removeLayer(m);
    });
    this.markersPoi = [];
  }

  removeMarker(index: number) {
    this.map.removeLayer(this.markers[index]);
    delete this.markers[index];
  }

  addMarkersToMap() {
    this.markers.forEach((marker) => {
      marker.addTo(this.map);
    });
  }

  removeMarkersFromMap() {
    this.markers.forEach((m) => {
      this.map.removeLayer(m);
    });
    this.markers = [];
  }

  addPolyline(polyline: Polyline) {
    this.polylines.push(polyline);
    polyline.addTo(this.map);
  }

  removePolylinesFromMap() {
    this.polylines.forEach((m) => {
      this.map.removeLayer(m);
    });
    this.polylines = [];
  }
  reseatPolygons(){
    const initialStateOptions: L.PolylineOptions = {
        color: '#053887',
        weight: 1,
        fillColor: '#D0E2FF'
    };
    for (const id in this.regionPolygons) {
        if (this.regionPolygons.hasOwnProperty(id)) {
            const polygon = this.regionPolygons[id];
            polygon.setStyle(initialStateOptions); // Update polygon options
        }
    }
  }
  setActivePolygons(groups: Group[]){
    groups.forEach(group => {
      if(group.nom != "_Aucune_Information"){
        this.changePolygonFillColor(group.nom , "#19EB0F");
      }
    })
  }
  changePolygonFillColor(id: string, fillColor: string): void {
    const polygon = this.regionPolygons[id];
    if (polygon) {
        polygon.setStyle({ fillColor: fillColor });
    } else {
        console.error(`Polygon with ID ${id} not found.`);
    }
  }
  addPolygon(polygon: Polygon) {
    this.polygons.push(polygon);
    polygon.addTo(this.map);
  }
  addPolygonMap(id: string,coordinates: P.LatLngExpression[], options?: P.PolylineOptions): void {
    const customOptions = { ...options, id: id }; 
    const polygon = P.polygon(coordinates, customOptions).addTo(this.map);
    polygon.on('mouseover', this.handlePolygonHover.bind(this));
    polygon.on('mouseout', this.handlePolygonMouseOut.bind(this));
    this.regionPolygons[id] = polygon;
  }
  
  handlePolygonHover(event: P.LeafletEvent) {
    const polygon = event.target;
    const id = polygon.options.id;
    const color =  this.colorService.darkerColor(polygon.options.fillColor, .4);
    polygon.setStyle({ fillColor: color });
    polygon.bindTooltip(id, { permanent: true, className: 'polygon-label' }).openTooltip();
  }
  handlePolygonMouseOut(event: P.LeafletEvent) {
    const polygon = event.target;
    const color =  this.colorService.lighterColor(polygon.options.fillColor, .4);
    polygon.setStyle({ fillColor: color });
    polygon.unbindTooltip();
  }
  addPolygonPoi(polygon: Polygon) {
    this.polygonsPoi.push(polygon);
    polygon.addTo(this.map);
  }

  removePolygon(index: number) {
    //this.map.removeLayer(this.polygons[index]);
    delete this.polygons[index];
  }

  addPolygonsToMap() {
    this.polygons.forEach((Polygon) => {
      Polygon.addTo(this.map);
    });
  }

  removePolygonsFromMap() {
    this.polygons.forEach((m) => {
      this.map.removeLayer(m);
    });
    this.polygons = [];
  }

  removePolygonsPoiFromMap() {
    this.polygonsPoi.forEach((m) => {
      this.map.removeLayer(m);
    });
    this.polygonsPoi = [];
  }

  resetMap() {
    this.removeMarkersFromMap();
    this.removePolygonsFromMap();
    this.removePolylinesFromMap();
  }

  disableMouseEvent(elementId: string) {
    let element = <HTMLElement>document.getElementById(elementId);
    P.DomEvent.disableClickPropagation(element);
    P.DomEvent.disableScrollPropagation(element);
  }

  centerMap(arr: any, maxZoom: number) {
    var maxlat = -Infinity,
      minlat = +Infinity,
      maxlng = -Infinity,
      minlng = +Infinity;

    if (arr.length > 0) {
      arr.forEach((rt: { coordinate: { lat: number; lng: number; }; }) => {
        if (rt.coordinate.lat != 0 && rt.coordinate.lng != 0) {
          if (maxlat < rt.coordinate.lat) {
            maxlat = rt.coordinate.lat;
          }
          if (minlat > rt.coordinate.lat) {
            minlat = rt.coordinate.lat;
          }
          if (maxlng < rt.coordinate.lng) {
            maxlng = rt.coordinate.lng;
          }
          if (minlng > rt.coordinate.lng) {
            minlng = rt.coordinate.lng;
          }
        }
      });
      var center1 = new P.LatLng(maxlat, maxlng),
        center2 = new P.LatLng(minlat, minlng);
      var bounds = new P.LatLngBounds(center1, center2);
      this.map.fitBounds(bounds, { padding: [50, 50], maxZoom: maxZoom });
    }
  }

  offsetMap(arr: any, zoom: number, x: number, y: number) {
    this.map.setZoom(zoom);
    var centerPoint = this.map.latLngToContainerPoint([arr.lat, arr.lng]);
    var newPoint = P.point([centerPoint.x + x, centerPoint.y + y]);
    var targetLatLng = this.map.containerPointToLatLng(newPoint);
    this.map.panTo(targetLatLng);
  }

  getPathBetweenTwoPoints(): Observable<any> {
    let headers = new HttpHeaders({ Accept: 'application/json' });

    headers.append('Access-Control-Allow-Origin', 'http://localhost:4200');
    // Request methods you wish to allow
    headers.append(
      'Access-Control-Allow-Methods',
      'GET, POST, OPTIONS, PUT, PATCH, DELETE'
    ); 

    // Request headers you wish to allow
    headers.append(
      'Access-Control-Allow-Headers',
      'X-Requested-With,content-type'
    );

    headers.append(
      'Access-Control-Allow-Headers',
      'Origin, Content-Type, X-Auth-Token'
    );

    // Set to true if you need the website to include cookies in the requests sent
    // to the API (e.g. in case you use sessions)
    headers.append('Access-Control-Allow-Credentials', 'true');

    return this._http.get(
      'https://maps.googleapis.com/maps/api/directions/json?origin=Disneyland&destination=Universal+Studios+Hollywood4&key=' +
        token_google,
      { headers: headers }
    );
  }

  processMileageData(
    points: any[],
    stops: any[],
    stopUrl: string,
    urlPointIcon: string,
    roundedNumber: number
  ) {
    let polyline = P.polyline(points, { color: '#0031D9', weight: 3 });

    for (let i = 0; i < points.length; i += roundedNumber) {
      this.processCoordinate(points[i], i, points.length, urlPointIcon);
    }
    if (stops != null) {
      stops.forEach((stop) => {
        this.processStop(stop, stopUrl);
      });
    }
    this.addMarkersToOurMap();
    this.addPolyline(polyline);
    this.centerMapOnMiddlePoint(points);
  }

  processCoordinate(coordinate: any, i: number, pointsLength: number, url: string) {
    let marker = new Marker(coordinate);

    if (i != 0 && i != pointsLength - 1) {
      this.setMarkerIconAndPopup(marker, coordinate, url);
      this.pointMarkers.push(marker);
    }
  }
  setMarkerIconAndPopup(marker: any, coordinate: any, url: string) {
    const spped = (coordinate.speed >120) ? 120: coordinate.speed;
    let hue = 'red'; 
    if(spped < 60){
      hue = 'green';
    }else if (spped < 120){
      hue=  '#FFD500';
    }


    // Generate the HSL color
    const circleIcon = P.divIcon({
      className: 'custom-icon',
      html: `   
      <?xml version="1.0" encoding="utf-8"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->
      <svg fill="${hue}" width="8Px" height="8px" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
          <circle cx="16" cy="16" r="16"/>
      </svg>
    `,
    iconAnchor: [8, 8]
    });

    marker.setIcon(circleIcon);
    let pointDatePipe = new DatePipe(coordinate.date);
    let popup = `<b>Heure:</b> ${pointDatePipe.transform(
      coordinate.date,
      'dd/MM/yyyy HH:mm:ss',
      null,
      'fr-fr'
    )}<b><br>vitesse:</b> ${coordinate.speed} Km/h`;

    marker.bindPopup(popup);
    marker.on('click', () => {
      this.map.setView(coordinate, 17);
    });
  }

  processStop(stop :any, iconsurl: string) {
    let marker = new Marker({
      lat: stop.stopLatitude,
      lng: stop.stopLongitude,
    });

    this.setStopMarkerIconAndPopup(marker, stop, iconsurl);
    this.stopMarkers.push(marker);

  }

  setStopMarkerIconAndPopup(marker :any, stop :any, iconsurl: string) {
    let pointDatePipe = new DatePipe(stop.beginStopTime);
    let stopDuration = new DurationSecPipe();
    let popup = `<b>Date début d'arrêt: </b>${pointDatePipe.transform(
      stop.beginStopTime,
      'dd/MM/yyyy HH:mm:ss',
      null,
      'fr-fr'
    )}<br><b>Durée d'arrêt: </b>${stopDuration.transform(stop.stopDuration)}`;

    marker.setIcon(
      new Icon({
        iconUrl: iconsurl,
        iconAnchor: [-2, 30],
        popupAnchor: [10, -25],
      })
    );

    marker.bindPopup(popup);
    marker.on('click', () => {
      this.map.setView({ lat: stop.stopLatitude, lng: stop.stopLongitude }, 17);
    });
  }

  processFuelMarkers(fuel :any, iconurl: string){
    let marker = new Marker({
      lat: fuel.val.lat,
      lng: fuel.val.lng,
    });

    this.setFuelMarkerIcon(marker, fuel, iconurl);
    this.fulMarkers.push(marker);
  }

  setFuelMarkerIcon(marker: P.Marker<any>, fuel : any, iconsurl: any) {
    let numberDate = fuel.rep.approStartTime;
    let pointDatePipe = new DatePipe(numberDate);
    let v = ((fuel.rep.approEndFuelLevel - fuel.rep.approStartFuelLevel) / 100 * fuel.volume) ;

    let popup = `<b>Date début d'arrêt: </b>${pointDatePipe.transform(
      numberDate,
      'dd/MM/yyyy HH:mm:ss',
      null,
      'fr-fr'
    )}<b><br>odometre:</b> ${fuel.rep.approStartOdo} Km <b><br>Fuel:</b> ${v}`;
    marker.setIcon(
      new Icon({
        iconUrl: iconsurl,
        iconAnchor: [-2, 30],
        popupAnchor: [10, -25],
      })
    );
    marker.on('click', () => {
      this.map.setView({ lat: fuel.val.lat, lng: fuel.val.lng }, 17);
    });
    marker.bindPopup(popup);
    marker.addTo(this.map);
  }

  centerMapOnMiddlePoint(points: string | any[]) {
    let zoomLevel: number = 10;
    if(points.length < 500){zoomLevel= 13} else if (points.length < 1000) {zoomLevel= 11}
    const middle = points[Math.round((points.length - 1) / 2)];
    this.map.setView(middle, zoomLevel);
    //this.offsetMap(middle, zoomLevel, 0, 0);
  }

  clearPolylines() {
    this.removePolylinesFromMap();
    this.removeMarkersFromMap();
  }

  updateMarker(id: number, newLatLng: any) {
    let Oldmarker = this.getRTMarker(id);
    Oldmarker.value.setLatLng(newLatLng);
    this.updateRtMarker(Oldmarker, false, true);
  }

  addMarkersToOurMap() {
    this.pointMarkers.forEach((marker) => {
      marker.addTo(this.map);
    });
    this.stopMarkers.forEach((marker) => {
      marker.addTo(this.map);
    });
  }

  showMarkers() {
    this.stopMarkers.forEach((marker) => {
      marker.setOpacity(1);
    });
    this.pointMarkers.forEach((marker) => {
      marker.setOpacity(1);
    });
    this.fulMarkers.forEach((marker) => {
      marker.setOpacity(1);
    });
  }
  setFlagsIcons(poinets: any) {
    this.theTrajetIndecator.forEach((marker) => {
      marker.remove();
    });
    this.theTrajetIndecator = [];
    let startingMarker = new Marker({
      lat: poinets[0].lat,
      lng: poinets[0].lng,
    });
    this.theTrajetIndecator.push(startingMarker);
    let endingMarker = new Marker({
      lat: poinets[poinets.length - 1].lat,
      lng: poinets[poinets.length - 1].lng,
    });
    this.theTrajetIndecator.push(endingMarker);

    this.theTrajetIndecator[0].setIcon(
      P.icon({
        iconUrl: 'assets/icons/realtime/km-jour/flag-start.png',
        iconSize: [32, 32],
        iconAnchor: [0, 30],
      })
    );
    this.theTrajetIndecator[1].setIcon(
      P.icon({
        iconUrl: 'assets/icons/realtime/km-jour/flag-end.png',
        iconSize: [32, 32],
        iconAnchor: [0, 30],
      })
    );

    this.theTrajetIndecator.forEach((marker) => {
      marker.addTo(this.map);
    });
  }

  hideButton(){
    return P.Control.extend({
      onAdd: () =>{
        var container = P.DomUtil.create('div', 'fullscreen-toggle leaflet-control-fullscreen leaflet-control leaflet-bar');
        var link = P.DomUtil.create('a', 'leaflet-bar-part', container);
        
        link.title = "Plein ecran";
        link.style.outline = "none";
        container.style.marginTop = "5px";
        container.style.cursor= "pointer";


        P.DomEvent.on(link, 'click', () => {
          if (this.isFullscreen) {
            document.getElementById("map").style.zIndex = "4";
            this.isFullscreen = false;
        } else {
            document.getElementById("map").style.zIndex = "15";
            this.isFullscreen = true;
        }
        });
    
        return container;
      }
    });
  }

  showPoi(){
    return P.Control.extend({
      onAdd: () =>{
        var container = P.DomUtil.create('div', 'leaflet-control leaflet-bar');
        var link = P.DomUtil.create('a', 'fa fa-eye-slash leaflet-bar-part', container);
        
        link.title = "Afficher/Cacher Poi";
        link.style.outline = "none";
        container.style.marginTop = "5px";
        container.style.cursor= "pointer";

        P.DomEvent.on(link, 'click', () => {
          this.setDrawIntrests();
        });
    
        return container;
      }
    });
  }


  lockedButton(){
    return P.Control.extend({
      onAdd: () =>{
        var container = P.DomUtil.create('div', 'leaflet-control leaflet-bar');
        var link = P.DomUtil.create('a', 'fa fa-lock leaflet-bar-part custom-icon-not-locked', container);
        
        link.title = "Lock Map";
        link.style.outline = "none";
        container.style.marginTop = "5px";
        container.style.cursor= "pointer";

        P.DomEvent.on(link, 'click', () => {
          if(this.mapLocked){
            this.map.dragging.enable();
            this.map.touchZoom.enable();
            link.classList.remove('custom-icon-locked');
            link.classList.add('custom-icon-not-locked');
            this.mapLocked= false;
          }else{
            this.map.dragging.disable();
            this.map.touchZoom.disable();
            link.classList.remove('custom-icon-not-locked');
            link.classList.add('custom-icon-locked');
            this.mapLocked= true;
          }

        });
    
        return container;
      }
    });
  }

  getDrawIntrests(): Observable<boolean>{
    return this.drawIntrests.asObservable();
  }
  setDrawIntrests(){
    this.drawIntrests.next(true);
  }



  hideMarkers() {
    this.stopMarkers.forEach((marker) => {
      marker.setOpacity(0);
    });
    this.pointMarkers.forEach((marker) => {
      marker.setOpacity(0);
    });
    this.fulMarkers.forEach((marker) => {
      marker.setOpacity(0);
    });
  }

  removeMarkersAndClearArrays() {
    this.pointMarkers.forEach((marker) => {
      marker.remove();
    });
    this.stopMarkers.forEach((marker) => {
      marker.remove();
    });
    this.fulMarkers.forEach((marker) => {
      marker.remove();
    });
    this.theTrajetIndecator.forEach((marker) => {
      marker.remove();
    });
    this.theTrajetIndecator = [];
    this.pointMarkers = [];
    this.stopMarkers = [];
    this.fulMarkers= [];
  }

  resetAllMap() {
    this.removeAllRtMarkers();
    this.removeMarkersFromMap();
    this.markers = [];
    this.rtMarkers = [];
    this.polylines = [];
    this.polygons = [];
    this.circles = [];
    this.markersPoi = [];
    this.polygonsPoi = [];
    this.pointMarkers = [];
    this.stopMarkers = [];
    this.fulMarkers= [];
    this.markercluster = P.markerClusterGroup();
  }
}