import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
//import { CustomizerSettingsService } from 'src/app/core/customizer-settings/customizer-settings.service';
import { ImportExportService } from 'src/app/client-management/import-export';
import { Subscription } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NewImportExportService } from '../../_service/new-import-export.service';

import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-pdf-display-section',
  templateUrl: './pdf-display-section.component.html',
  styleUrls: ['./pdf-display-section.component.scss'],
})
export class PdfDisplaySectionComponent implements OnInit, OnDestroy {
  @Input() isShowing: boolean = false;
  @Output() onShowPDF = new EventEmitter<void>();

  @Output() onSelectReport = new EventEmitter<string>();

  private isDarkTheme: boolean;
  private isSidebarDarkTheme: boolean;
  private isRightSidebarTheme: boolean;
  private isHideSidebarTheme: boolean;
  private isHeaderDarkTheme: boolean;
  private isCardBorderTheme: boolean;
  private isCardBorderRadiusTheme: boolean;
  private isRTLEnabledTheme: boolean;

  showPdf: boolean = false;
  isPdf: boolean = false;
  file: SafeResourceUrl | null = null;
  showPdf$: Subscription;
  isPdf$: Subscription;
  file$: Subscription;
  rapport$: Subscription;
  currentRapport: string | null = null;

  constructor(
    //  public themeService: CustomizerSettingsService,
    private sanitizer: DomSanitizer,
    private importExportService: NewImportExportService
  ) {
    this.isDarkTheme = JSON.parse(localStorage.getItem('isDarkTheme')!);
    this.isSidebarDarkTheme = JSON.parse(
      localStorage.getItem('isSidebarDarkTheme')!
    );
    this.isRightSidebarTheme = JSON.parse(
      localStorage.getItem('isRightSidebarTheme')!
    );
    this.isHideSidebarTheme = JSON.parse(
      localStorage.getItem('isHideSidebarTheme')!
    );
    this.isHeaderDarkTheme = JSON.parse(
      localStorage.getItem('isHeaderDarkTheme')!
    );
    this.isCardBorderTheme = JSON.parse(
      localStorage.getItem('isCardBorderTheme')!
    );
    this.isCardBorderRadiusTheme = JSON.parse(
      localStorage.getItem('isCardBorderRadiusTheme')!
    );
    this.isRTLEnabledTheme = JSON.parse(
      localStorage.getItem('isRTLEnabledTheme')!
    );
  }

  ngOnInit(): void {
    this.subscribeToPdf();
    this.subscribeToShowPdf();
    this.subscribeToExistnaceOfPdf();
    this.subscribeToRapport();
  }

  private subscribeToPdf(): void {
    this.file$ = this.importExportService
      .getPdfToBePrinted()
      .subscribe((blob) => {
        this.file = blob;
      });
  }
  private subscribeToExistnaceOfPdf(): void {
    this.isPdf$ = this.importExportService.isPdfObs().subscribe((isPdf) => {
      this.isPdf = isPdf;
    });
  }

  private subscribeToShowPdf(): void {
    this.showPdf$ = this.importExportService.getShowPdf().subscribe((show) => {
      this.showPdf = show;
    });
  }

  private subscribeToRapport(): void {
    this.rapport$ = this.importExportService
      .getRapport()
      .subscribe((rapport) => {
        console.log('Valeur actuelle de currentRapport:', rapport);
        this.currentRapport = rapport;
      });
  }

  ngOnDestroy(): void {
    if (this.showPdf$) {
      this.showPdf$.unsubscribe();
    }
    if (this.isPdf$) {
      this.isPdf$.unsubscribe();
    }
    if (this.file$) {
      this.file$.unsubscribe();
    }
    if (this.rapport$) this.rapport$.unsubscribe();
  }

  showPDF() {
    this.onShowPDF.emit();
  }

  selectReport(reportType: string) {
    this.onSelectReport.emit(reportType);
  }

  toggleTheme() {
    this.isDarkTheme = !this.isDarkTheme;
    localStorage.setItem('isDarkTheme', JSON.stringify(this.isDarkTheme));
  }

  toggleSidebarTheme() {
    this.isSidebarDarkTheme = !this.isSidebarDarkTheme;
    localStorage.setItem(
      'isSidebarDarkTheme',
      JSON.stringify(this.isSidebarDarkTheme)
    );
  }

  toggleRightSidebarTheme() {
    this.isRightSidebarTheme = !this.isRightSidebarTheme;
    localStorage.setItem(
      'isRightSidebarTheme',
      JSON.stringify(this.isRightSidebarTheme)
    );
  }

  toggleHideSidebarTheme() {
    this.isHideSidebarTheme = !this.isHideSidebarTheme;
    localStorage.setItem(
      'isHideSidebarTheme',
      JSON.stringify(this.isHideSidebarTheme)
    );
  }

  toggleHeaderTheme() {
    this.isHeaderDarkTheme = !this.isHeaderDarkTheme;
    localStorage.setItem(
      'isHeaderDarkTheme',
      JSON.stringify(this.isHeaderDarkTheme)
    );
  }

  toggleCardBorderTheme() {
    this.isCardBorderTheme = !this.isCardBorderTheme;
    localStorage.setItem(
      'isCardBorderTheme',
      JSON.stringify(this.isCardBorderTheme)
    );
  }

  toggleCardBorderRadiusTheme() {
    this.isCardBorderRadiusTheme = !this.isCardBorderRadiusTheme;
    localStorage.setItem(
      'isCardBorderRadiusTheme',
      JSON.stringify(this.isCardBorderRadiusTheme)
    );
  }

  toggleRTLEnabledTheme() {
    this.isRTLEnabledTheme = !this.isRTLEnabledTheme;
    localStorage.setItem(
      'isRTLEnabledTheme',
      JSON.stringify(this.isRTLEnabledTheme)
    );
  }

  isDark() {
    return this.isDarkTheme;
  }

  isSidebarDark() {
    return this.isSidebarDarkTheme;
  }

  isRightSidebar() {
    return this.isRightSidebarTheme;
  }

  isHideSidebar() {
    return this.isHideSidebarTheme;
  }

  isHeaderDark() {
    return this.isHeaderDarkTheme;
  }

  isCardBorder() {
    return this.isCardBorderTheme;
  }

  isCardBorderRadius() {
    return this.isCardBorderRadiusTheme;
  }

  isRTLEnabled() {
    return this.isRTLEnabledTheme;
  }

  private isToggled = new BehaviorSubject<boolean>(false);

  get isToggled$() {
    return this.isToggled.asObservable();
  }

  toggle() {
    this.isToggled.next(!this.isToggled.value);
  }
}
