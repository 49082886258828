<div class="driver-actvity-container">
    <!--if report neads to choose start and end date-->
    <div class="row">
        <div class="form-group" class="col-md-6">


            <input *ngIf="isDateOnly" type="text" class="form-control input-sm" bsDatepicker name="startDate"
                placeholder="Date Début" [(ngModel)]="repport.dateInterval.startDate" [bsConfig]="{
                    dateInputFormat: 'DD-MM-YYYY',
                    containerClass: 'theme-dark-blue'
                  }" [maxDate]="maxDate" placement="bottom" autocomplete="off" />

            <input *ngIf="!isDateOnly" type="text" class="form-control input-sm" bsDatepicker name="startDate"
                placeholder="Date Début" [(ngModel)]="repport.dateInterval.startDate" [bsConfig]="{
                    dateInputFormat: 'DD-MM-YYYY, HH:mm',
                    containerClass: 'theme-dark-blue'
                  }" [maxDate]="maxDate" placement="bottom" autocomplete="off" />

        </div>
        <div class="form-group" class="col-md-6">

            <input *ngIf="isDateOnly" type="text" class="form-control input-sm" bsDatepicker name="endDate"
                placeholder="Date Fin" [(ngModel)]="repport.dateInterval.endDate" [bsConfig]="{
                        dateInputFormat: 'DD-MM-YYYY',
                        containerClass: 'theme-dark-blue'
                    }" [minDate]="repport.dateInterval.startDate" placement="bottom" autocomplete="off" />

            <input *ngIf="!isDateOnly" type="text" class="form-control input-sm" bsDatepicker name="endDate"
                placeholder="Date Fin" [(ngModel)]="repport.dateInterval.endDate" [bsConfig]="{
                        dateInputFormat: 'DD-MM-YYYY, HH:mm',
                        containerClass: 'theme-dark-blue'
                    }" [minDate]="repport.dateInterval.startDate" placement="bottom" autocomplete="off"
                (ngModelChange)="setTimeTo2359()" />

        </div>
    </div>

    <!-- choose report option (all vehicule ,by group or by vehicule)-->
    <div class="form-group" class="col-md-5">
        <div class="col-sm-10">
            <select class="form-control" [(ngModel)]="Selectedoption" (change)="onSelectingReportOf()"
                name="selectedoption" disabled>
                <option style="font-size: 14px" *ngFor="let opt of selectReportOf" [value]="opt.name">
                    {{ opt.label }}
                </option>
            </select>
        </div>
    </div>
    <!--display al groups if user has choose it-->
    <div class="form-group" class="col-md-5" *ngIf="this.repport.rapportPayloadDto.byGroups ">
        <!-- <label class="col-sm-2 control-label" style="color: limegreen">Groupes</label> -->
        <div class="col-sm-10">
            <ng-select *ngIf="groupsItems.length != 0 && multipleSelect" [items]="groupsItems"
                [active]="selectedGroupsItems" [multiple]="true" (data)="selectedGroups($event)" [allowClear]="true"
                placeholder="aucun groupe selectionné">
            </ng-select>
            <select *ngIf="!multipleSelect" class="form-control" [(ngModel)]="repport.rapportPayloadDto.idGroup"
                name="groups">
                <option style="font-size: 14px" *ngFor="let g of groups" [ngValue]="g.idGroupe">
                    <span *ngIf="g.nameGroupParent">{{
                        g.nameGroupParent | capitalizefirst | truncate : [30]
                        }}
                        / </span>{{ g.nom }}
                </option>
            </select>
        </div>
    </div>

    <!-- display all vehicules of user choose this option-->
    <div class="form-group" class="col-md-5" *ngIf="
          this.repport.rapportPayloadDto.byVehicules 
        ">
        <!-- <label class="col-sm-2 control-label" style="color: limegreen">Véhicules</label> -->
        <div class="col-sm-10">
            <ng-select *ngIf="vehiculesItems.length != 0 && multipleSelect" [multiple]="true" [items]="vehiculesItems"
                placeholder="aucun vehicule selectionné" [active]="vehiculesSelected" [allowClear]="true"
                (data)="selectedVehicules($event)">
            </ng-select>

            <select *ngIf="!multipleSelect" class="form-control" [(ngModel)]="repport.rapportPayloadDto.idVehicule"
                name="vehicules">
                <option style="font-size: 14px" *ngFor="let v of vehicules" [ngValue]="v.idVehicule">
                    {{ v.mark != null && v.mark != "" ? v.mark : v.matricule }}
                </option>
            </select>
        </div>
    </div>

    <div class="action-buttons">
        <div class="col-sm-10">
            <button *ngIf=" !isShowingExcel" class="download-button" style="background: rgba(0, 128, 0, .788);"
                (click)="showExcel()">
                <mat-icon>poll</mat-icon>
            </button>
            <div *ngIf="isShowingExcel" class="download-button" style="background: rgba(0, 128, 0, .788);">
                <span class="display-loader"></span>
            </div>
        </div>


        <!-- Global Download Button (if needed) -->
        <div class="col-sm-10">
            <button *ngIf=" !isDownloading" class="download-button" (click)="downloadExcel()">
                <mat-icon>download</mat-icon>
            </button>
            <div *ngIf="isDownloading" class="download-button">
                <span class="loader"></span>
            </div>
        </div>
    </div>
</div>