import { Component, OnInit, OnDestroy } from '@angular/core';
import { PointInterest } from '../../../data-management/data-management.model';
import { DataManagementService } from '../../../data-management/data-management.service';
import { ToastrService } from 'ngx-toastr';
import { Alert, AlertConfiguration } from '../../alert';
import { AlertManagementService } from '../../alert-management.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-poi-settings',
  templateUrl: './poi-settings.component.html',
  styleUrls: ['./poi-settings.component.css']
})
export class PoiSettingsComponent implements OnInit, OnDestroy {
  public showAdvancedSettings = false;

  public pois: PointInterest[] = [];
  public poiItems: any[] = [];
  public poisSelected: any[] = [];
  public selectedItem: string = "";

  public stopTimeSelectMode = true;
  public enableGlobalConfig = false;
  public enableGlobalConfigToAllVehicules = false;

  public minutes: string[] = [];
  public loader = false;
  public alert: Alert = new Alert();
  public poiAlertConfiguration: AlertConfiguration = new AlertConfiguration();
  public cronAlertConfiguration: AlertConfiguration = new AlertConfiguration();

  public getVehiculeWasSelectedSubscription: Subscription;

  constructor(
    private dataManagementService: DataManagementService,
    private toastr: ToastrService,
    private alertManagementService: AlertManagementService
  ) {
    this.initMinutes();
  }

  ngOnInit() {
    if (this.dataManagementService.selectedVehicule) {
      this.loadAlertConfigOfDevice(
        this.dataManagementService.selectedVehicule.idDevice
      );
    }
    this.loadPOI();
    this.getVehiculeWasSelectedSubscription = this.dataManagementService
      .getVehiculeWasSelected()
      .subscribe(vehicule => {
        this.loadAlertConfigOfDevice(
          this.dataManagementService.selectedVehicule!.idDevice
        );
      });
  }

  ngOnDestroy() {
    if (this.getVehiculeWasSelectedSubscription) {
      this.getVehiculeWasSelectedSubscription.unsubscribe();
    }
  }

  loadAlertConfigOfDevice(device: number) {
    this.loader = true;
    this.alertManagementService
      .getAlertByNameAndDeviceId('POI', device)
      .subscribe(
        alert => {
          this.alert = alert;

          this.cronAlertConfiguration = this.alert.alertConfigurations.filter(
            alertConfiguration => alertConfiguration.type === 'DATE'
          )
            ? this.alert.alertConfigurations.filter(
              alertConfiguration => alertConfiguration.type === 'DATE'
            )[0]
            : this.cronAlertConfiguration;
          this.poiAlertConfiguration = this.alert.alertConfigurations.filter(
            alertConfiguration => alertConfiguration.type === 'POI'
          )
            ? this.alert.alertConfigurations.filter(
              alertConfiguration => alertConfiguration.type === 'POI'
            )[0]
            : this.poiAlertConfiguration;

          if (this.poiAlertConfiguration && this.poiAlertConfiguration.value1) {
            this.poisSelected = this.selectedItemfromPoiId(this.poiAlertConfiguration.value1);
            this.poiAlertConfiguration.value1 = +this.poiAlertConfiguration.value1;
            this.selectedPoi(this.poisSelected);
          }

          this.loader = false;
          this.toastr.success('Configuration chargée', 'info !', {
            timeOut: 1000
          });
        },
        () => {
          this.loader = false;
          this.toastr.error('Configuration non chargée', 'info !', {
            timeOut: 1000
          });
        }
      );
  }

  collapseAdvancedSettings() {
    this.showAdvancedSettings = !this.showAdvancedSettings;
  }

  //GET list of Points and Polygons
  loadPOI() {
    let poi: PointInterest = new PointInterest();
    poi.name = 'Tout les POIs';
    poi.idPointInterest = null;

    if (this.dataManagementService.pointInterests) {
      this.pois = this.dataManagementService.getMarkers();
      this.pois.unshift(poi);
      this.pois.push(poi);
      this.poiItems = this.poiToItem(this.pois);
    } else {
      this.dataManagementService
        .getAllPointInterests()
        .subscribe(pointInterests => {
          this.dataManagementService.pointInterests = pointInterests;
          this.pois = this.dataManagementService.getMarkers();
          this.poiItems = this.poiToItem(this.pois);
          this.pois.unshift(poi);
        });
    }
  }

  selectedItemfromPoiId(value1: any) {
    let poiId = ("" + value1).split(';');
    let pois = [];
    let poi;
    for (let id of poiId) {
      poi = this.pois.filter(p => p.idPointInterest === +id);
      if (poi && poi[0]) {
        pois.push({ "id": poi[0].idPointInterest, "text": poi[0].name });
      }
    }
    return pois;
  }
  poiToItem(pois: any) {
    let items = [];
    for (let poi of pois) {
      items.push({ "id": poi.idPointInterest, "text": poi.name });
    }
    return items;
  }
  selectedPoi($event: any) {
    this.selectedItem = "";
    for (let poi of $event) {
      if (this.selectedItem != "") {
        this.selectedItem = this.selectedItem + ";" + poi.id;
      } else {
        this.selectedItem = poi.id;
      }
    }
  }

  choosePoi(poiId: number) {
    let pois: PointInterest[] = this.dataManagementService.getPointInterestById(
      poiId
    );
    if (pois && pois[0]) {
      this.dataManagementService.displayPointPolygon(pois[0]);
    }
  }

  chooseStopTime($event: any) {
    if ($event === 'Autre..') {
      this.stopTimeSelectMode = false;
      this.poiAlertConfiguration.value2 = '10';
    }
  }

  initMinutes() {
    for (var i = 1; i <= 12; i++) {
      this.minutes.push((i * 10).toString());
    }
    this.minutes.push('Autre..');
  }
  comparePois(item1: any, item2: any): boolean {
    return item1 && item2 ? item1.id === item2.id : item1 === item2;
  }

  save() {
    this.loader = true;
    this.alert.enableGlobalConfig = this.enableGlobalConfig;
    if(this.selectedItem == null || this.selectedItem == '')
    this.selectedItem = "*";
    this.alert.alertConfigurations.find(alert => alert.type == "ZONE" || alert.type == "POI").value1 = this.selectedItem;
    this.alertManagementService.updateAlert(this.alert).subscribe(
      alert => {
        this.alert = alert;
        this.loader = false;
        this.toastr.success('Configuration enregistrer', 'info !');
      },
      error => {
        this.toastr.error('Configuration non enregistrée', 'info !');
      }
    );
  }

  emailSettings(emailsSettings: { emailMode: boolean; emails: string; enableGlobalConfig: boolean, enableGlobalConfigToAllVehicules: boolean }) {
    this.alert.emailMode = emailsSettings.emailMode;
    this.alert.emails = emailsSettings.emails;
    this.enableGlobalConfig = emailsSettings.enableGlobalConfig;
    this.enableGlobalConfigToAllVehicules = emailsSettings.enableGlobalConfigToAllVehicules;
    if (emailsSettings.enableGlobalConfig || emailsSettings.enableGlobalConfigToAllVehicules) {
      this.updateEmails();
    }
  }
  updateEmails() {
    let id = 0;
    if (this.enableGlobalConfig || this.enableGlobalConfigToAllVehicules) {
      id = this.alert.deviceId;
    } else {
      id = this.alert.id;
    }
    this.alertManagementService.setEmailToAllAlert(this.alert.emails, id, this.enableGlobalConfig, this.enableGlobalConfigToAllVehicules)
      .subscribe(res => {
        if (res) {
          this.toastr.success('emails bien copiés ');
        } else {
          this.toastr.warning('Veuillez reverifier');
        }
      }
      )
  }
}
