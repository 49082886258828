<div [ngClass]="{ 'hidden': !hideComponent }">
  <km-jour-trajet-parent (showingItems)="showingTrajet()" [useFms]="useFms"
    [parentDetails]="kmJourPath ? kmJourPath.parentDetails:null"></km-jour-trajet-parent>
  <div class="container-bar" *ngIf="showTrajet">
    <div class="custom-bar">
      <progress-custom-bar [steps]="kmJourPath ? kmJourPath.childDetails:[]"></progress-custom-bar>
    </div>
    <div class="trajet-container">
      <km-jour-trajet-item *ngFor="let item of kmJourPath?.childDetails" [childDetail]="item" [useFms]="useFms"
        (displayDash)="displayChart($event)"></km-jour-trajet-item>
    </div>
  </div>
</div>



<router-outlet></router-outlet>

<div class="col-md-12 charts" [ngStyle]="{'display': hidChart ? 'none': null}">
  <div id="container" [ngClass]="{'container': hideComponent, 'containerTrajet': !hideComponent}"></div>
</div>