<section class="content" style="font-family: 'Inconsolata', monospace;font-size: 14px;">
    <div class="row">
        <div class="datatable-container" id="datatable-container" style="margin-top: 20px;">
            <div class="col-md-12 datatable-container-col">
                <div class="box" style="border-radius: 0px;">
                    <div class="overlay" *ngIf="!isResultLoaded">
                        <i class="fa fa-refresh fa-spin"></i>
                    </div>
                    <div class="box-body">
                        <form class="form-horizontal">
                            <div class="form-group row">
                                <label class="col-sm-1 control-label grp-lbl">Groupe</label>
                                <div class="col-sm-2 grp-slct">
                                    <select class="form-control input-sm" [(ngModel)]="selectedGroup" name="groups" (ngModelChange)="chooseGroup($event)">
                                        <option *ngFor="let group of groups" [ngValue]="group">
                                            <span *ngIf="group.nameGroupParent">{{group.nameGroupParent | capitalizefirst | truncate:[30]}}  / </span> {{group.nom}}
                                        </option>
                                    </select>
                                </div>
                                <label class="col-sm-1 control-label vhcl-lbl">Vehicule</label>
                                <div class="col-sm-2 vhcl-slct">
                                    <select class="form-control input-sm" [(ngModel)]="selectedVehicule" name="vehicules" (ngModelChange)="chooseVehicule($event)">
                                        <option *ngFor="let vehicule of selectedGroup.vehicules" [ngValue]="vehicule">{{dataManagementService.getVehiculeName(vehicule)}}
                                        </option>
                                    </select>
                                </div>
                                <label class="col-sm-1 control-label dd-lbl">Date début</label>
                                <div class="col-sm-2 dd-npt">
                                    <div class="input-group">

                                            <input type="text" class="form-control input-sm" bsDatepicker name="startDate" [(ngModel)]="startDate"
                                            [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY, HH:mm' , containerClass: 'theme-dark-blue'}" placement="bottom" [maxDate]="endDate" >
                                            <span class="input-group-addon"><i class="fa fa-clock-o"></i></span>
                                    </div>
                                </div>
                                <label class="col-sm-1 control-label df-lbl">Date fin</label>
                                <div class="col-sm-2 df-npt">
                                    <div class="input-group">

                                            <input type="text" class="form-control input-sm" bsDatepicker name="endDate" [(ngModel)]="endDate"
                                            [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY, HH:mm', containerClass: 'theme-dark-blue'}" placement="bottom" [minDate]="startDate">
                                            <span class="input-group-addon"><i class="fa fa-clock-o"></i></span>

                                    </div>
                                </div>
                                <div class="col-sm-1 form-btns">
                                    <button type="button" class="btn btn-success btn-flat btn-sm" (click)="displayChart()">
                                        Afficher
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-md-12 charts">
                <highcharts-chart
                [Highcharts]="Highcharts"
                [options]="options"
                style="width: 100%; height: 400px; display: block;"
              ></highcharts-chart>
            </div>
        </div>
    </div>
</section>