import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
// Remplacer moment et dayjs par Luxon
//import { DateTime } from 'luxon'; // Utilisation de Luxon pour la gestion des dates

import { ToastrService } from 'ngx-toastr';
import { rapportsDir, dns } from 'src/app/global.config';
import {
  providers,
  reportOf,
} from 'src/app/client-management/import-export/report-item';
import {
  Group,
  ImportFileDto,
  RapportDto,
  VehiculesDto,
  PointInterest,
  PoiClient,
  ReportAuto,
} from '../../../../data-management/data-management.model';
import { DataManagementService } from '../../../../data-management/data-management.service';
import { rapportsItem } from '../../../../import-export/report-item';
import { NewImportExportRestService } from '../../../_service/new-import-export-rest.service';
import { NewImportExportHelperService } from '../../../_service/new-import-export-helper.service';
import { NewImportExportService } from '../../../_service/new-import-export.service';
import { User } from 'src/app/authentification/signin/credentials';
import { Router } from '@angular/router';
import { SigninService } from 'src/app/authentification/signin/signin.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-etat_boitier_actuel',
  templateUrl: './etat_boitier_actuel.component.html',
  styleUrls: ['./etat_boitier_actuel.component.scss'],
})
export class EtatBoitierActuelComponent implements OnInit {
  file: Blob | null = null;
  vehicule$: Subscription;
  valueChange$: Subscription;

  multipleSelect = true;
  Selectedoption: string = 'ALL_VEHICULES';

  maxDate: Date = new Date();

  isDateOnly = false;
  // locale: LocaleConfig = {
  //   format: 'DD/MM/YYYY HH:mm',
  //   applyLabel: 'Appliquer',
  //   customRangeLabel: ' - ',
  //   daysOfWeek: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
  //   monthNames: [
  //     'Janvier',
  //     'Février',
  //     'Mars',
  //     'Avril',
  //     'Mai',
  //     'Juin',
  //     'Juillet',
  //     'Août',
  //     'Septembre',
  //     'Octobre',
  //     'Novembre',
  //     'Décembre',
  //   ],
  //   firstDay: 1,
  // };

  //Selectedoption: string = 'ALL_VEHICULES';
  isShowingExcel: boolean = false;
  loading: boolean = true;

  currentReport: void;
  groups: Group[] = [];
  vehicules: VehiculesDto[] = [];
  groupsItems: { id: number; text: string }[] = [];
  selectedGroupsItems: any[] = [];
  vehiculesSelected: any[] = [];
  vehiculesItems: { id: number; text: string }[] = [];
  selectedVehicule: { id: number; text: string }[] = [];

  selectedGroupsHash: string = '';
  selectedVehiculesHash: string = '';

  selectReportOf = reportOf;

  isDownloading: boolean = false;
  repport: RapportDto = new RapportDto();
  templateDownloadLocation: string = rapportsDir;

  constructor(
    private importExportRestService: NewImportExportRestService,
    private importExportHelperService: NewImportExportHelperService,
    private importExportService: NewImportExportService,
    public dataManagementService: DataManagementService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.Selectedoption = 'ALL_VEHICULES';
    this.getGroups();
    this.getVehicules();
    this.multipleSelect = true;
    this.selectReportOf = reportOf;

    this.currentReport = this.importExportService.setCurentRapport(
      'Etat actuel des boîtiers'
    );

    this.importExportService.setPdfExistince(false);
    // this.showExcel();
    var toDay = new Date();
    this.repport.dateInterval.startDate = new Date(
      toDay.getFullYear(),
      toDay.getMonth(),
      toDay.getDate(),
      0,
      0,
      0,
      0
    );
    this.repport.dateInterval.endDate = new Date(
      toDay.getFullYear(),
      toDay.getMonth(),
      toDay.getDate(),
      23,
      59,
      59,
      59
    );
    this.repport.dateInterval.startDate.setTime(
      this.repport.dateInterval.startDate.getTime()
    );

    this.dataManagementService.getAllGroupsDetails('').subscribe((res) => {
      this.groups = res.filter((group) => group.vehicules.length > 0);
      this.mapGroupsToItems(this.groups);
      if (this.groups) {
        this.getVehicules();
      }
    });
  }

  mapGroupsToItems(groups: Group[]) {
    this.groupsItems = [];
    for (let i = 0; i < groups.length; i++) {
      let parentName: string;
      if (groups[i].nameGroupParent) {
        parentName = groups[i].nameGroupParent + ' / ' + groups[i].nom;
      } else {
        parentName = groups[i].nom;
      }
      this.groupsItems.push({
        id: groups[i].idGroupe,
        text: parentName,
      });
    }
  }

  ngOnDestroy(): void {
    if (this.vehicule$) {
      this.vehicule$.unsubscribe();
    }
  }

  onSelectingReportOf() {
    /**give ability to downoald */

    this.loading = true;
    switch (this.Selectedoption) {
      case 'ALL_VEHICULES':
        this.repport.rapportPayloadDto.allVehicules = true;
        this.repport.rapportPayloadDto.byGroups = false;
        this.repport.rapportPayloadDto.byVehicules = false;
        break;
      case 'BY_GROUPS':
        this.repport.rapportPayloadDto.allVehicules = false;
        this.repport.rapportPayloadDto.byGroups = true;
        this.repport.rapportPayloadDto.byVehicules = false;
        break;
      case 'BY_VEHICULE':
        this.repport.rapportPayloadDto.allVehicules = false;
        this.repport.rapportPayloadDto.byGroups = false;
        this.repport.rapportPayloadDto.byVehicules = true;
        break;
      default:
        this.repport.rapportPayloadDto.allVehicules = true;
    }
  }

  selectedGroups(groups: { id: number; text: string }[]): void {
    let prefix = '';
    this.selectedGroupsHash = '';
    groups.forEach((group) => {
      this.selectedGroupsHash += prefix + group.id;
      prefix = ',';
    });
    this.repport.rapportPayloadDto.idGroups = this.selectedGroupsHash
      .split(',')
      .map((x) => +x);
  }

  selectedVehicules(vehicules: { id: number; text: string }[]): void {
    let prefix = '';
    this.selectedVehiculesHash = '';
    vehicules.forEach((vehicule) => {
      this.selectedVehiculesHash += prefix + vehicule.id;
      prefix = ',';
    });
    this.repport.rapportPayloadDto.idVehicules = this.selectedVehiculesHash
      .split(',')
      .map((x) => +x);
  }

  getGroups(): void {
    this.importExportRestService.getAllGroups().subscribe((groups) => {
      this.groups = groups;
      this.groupsToItems(groups);
    });
  }

  getVehicules(): void {
    this.importExportRestService.getAllVehicules().subscribe((vehicules) => {
      this.vehicules = vehicules;
      this.vehiculesToItems(vehicules);
    });
  }

  groupsToItems(groups: Group[]): void {
    this.groupsItems = groups.map((group) => ({
      id: group.idGroupe,
      text: group.nom,
    }));
  }

  vehiculesToItems(vehicules: VehiculesDto[]): void {
    this.vehiculesItems = vehicules.map((vehicule) => ({
      id: vehicule.idVehicule,
      text: vehicule.matricule,
    }));
  }

  showExcel(): void {
    this.currentReport = this.importExportService.setCurentRapport(
      'Etat actuel des boîtiers'
    );

    this.isShowingExcel = true;
    if (this.repport.rapportPayloadDto.byGroups) {
      this.repport.rapportPayloadDto.idGroups = this.selectedGroupsItems;
    }
    if (this.repport.rapportPayloadDto.byVehicules) {
      this.repport.rapportPayloadDto.idVehicules = this.vehiculesSelected;
    }
    this.importExportRestService.getEtatActuelReport(this.repport).subscribe(
      (blob) => {
        this.importExportService.setExcelToBeProcessed(blob);
        this.importExportService.setShowPdf(true);
        this.file = blob;
        this.isShowingExcel = false;

        this.toastr.success('Fichier chargé avec succès.');
      },
      (error) => {
        this.isShowingExcel = false;
        this.toastr.warning('Problème survenu lors du téléchargement.');
      }
    );
  }

  downloadExcel(): void {
    this.isDownloading = true;
    this.importExportRestService.getEtatActuelReport(this.repport).subscribe(
      (blob) => {
        this.importExportService.setExcelToBeProcessed(blob);

        this.file = blob;
        this.isDownloading = false;
        this.importExportHelperService.downloadEasyPDF(
          blob,
          `Etat_actuel_des_boîtiers.xlsx`
        );

        this.toastr.success('Fichier téléchargé avec succès.');
      },
      (error) => {
        this.isDownloading = false;
        this.toastr.warning('Problème survenu lors du téléchargement.');
      }
    );
  }

  setTimeTo2359() {
    // Check if endDate is set and not just an empty string
    if (this.repport.dateInterval.endDate) {
      // Set the time to 23:59
      this.repport.dateInterval.endDate.setHours(23);
      this.repport.dateInterval.endDate.setMinutes(59);
    }
  }
}
