import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ToolKmJourService } from '../../../../services/tool-km-jour.service';
import { KmJourPath, Point } from '../../../../model/km-jour.model';
import { RealtimeService } from 'src/app/client-management/realtime/services/realtime.service';
import { Subscription, forkJoin } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { MapService } from 'src/app/utils/leaflet/service/map.service';
import { RealtimeProcessingService } from 'src/app/client-management/realtime/services/real-time-processing.service';
import { TechnicalDataService } from 'src/app/client-management/statistical/technical-data/technical-data.service';
import { DeviceCarb, RepFuelVariation, TechnicalData } from 'src/app/client-management/statistical/technical-data/technical-data';

const Highcharts = require("highcharts/highstock");
import { RealtimeHelperService } from 'src/app/client-management/realtime/services/realtime-helper.service';
import { DateInterval } from 'src/app/shared/model';
import { ChartParametre } from 'src/app/client-management/realtime/model/chart-parameter.model';
import { MileageService } from 'src/app/client-management/historical';

@Component({
  selector: 'tool-km-jour',
  templateUrl: './tool-km-jour.component.html',
  styleUrls: ['./tool-km-jour.component.css'],
})
export class KmJrToolComponent implements OnInit, OnDestroy {
  [x: string]: any;
  showTrajet: boolean = false;
  idDevice: number;

  intervaleDate: DateInterval = this.realtimeServiceHelpper.IntervalDateConverter(new Date());
  intervaleDateSubscription: Subscription;

  kmJourPath: KmJourPath;
  kmJourPathSubscription: Subscription;

  mileage: any;
  mileageSubscription: Subscription;

  hideComponent: boolean = true;
  hideComponentSubscription: Subscription;

  kmJourPrintedSubscription: Subscription;

  toPrint: { stops: any[]; points: Point[] };
  completedSubscription: Subscription;

  repFuelVariation: RepFuelVariation[] = [];
  volume: number;

  technicaldata: TechnicalData[];
  options: Object;
  aproPoints: { [id: number]: Point } = {};

  hidChart: boolean = false;
  hidChart$: Subscription;

  //Device Carburant
  devicecarb: DeviceCarb;
  useFms: boolean;

  constructor(
    private toolKmJourService: ToolKmJourService,
    private realTimeService: RealtimeService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private mapService: MapService,
    private mileageService: MileageService,
    private realTimeProcessing: RealtimeProcessingService,
    private technicaldataservice: TechnicalDataService,
    private realtimeServiceHelpper: RealtimeHelperService
  ) { }

  ngOnInit() {
    this.setupRoutingAndData();
    this.setupSubscriptions();
  }

  setupRoutingAndData() {
    this.route.parent.paramMap.subscribe((params) => {
      this.initializeRealTime();
      this.idDevice = +params.get('id');
      this.mapService.controlMarkers(this.idDevice, true);
      this.toolKmJourService.loadKmJourPath(this.idDevice, this.intervaleDate);
      this.setUpMileageRealTime();
    });
    this.toolKmJourService.getDeviceoption().subscribe(dev => {

      this.useFms = (dev.useFms === 1);
    })
  }
  initializeRealTime() {
    this.realTimeService.stopRealTime();
    this.realTimeService.clearRealTime();
    this.toolKmJourService.setLoaded(false);
  }
  setUpMileageRealTime() {
    this.mileageService
      .getAllMileagesRealTime(this.idDevice)
      .subscribe(
        (mileages) => {
          this.realTimeService.selectedVehicule.mileage = mileages[mileages.length - 1];
          this.realTimeService.setVehicule(this.realTimeService.selectedVehicule);
        },
        () => { }
      );
  }

  setupSubscriptions() {
    this.setupKmJourPathSubscription();
    this.setUpPrintedKmJour();
    this.setupMileageSubscription();
    this.setupHideComponentSubscription();
    this.setUpCompletedSubscription();
    this.hidingTheChartSubscription();
  }

  hidingTheChartSubscription() {
    this.hidChart$ = this.realTimeProcessing.getHidingChart().subscribe((hidChart) => {
      this.hidChart = hidChart;
    })

    this.technicaldataservice
      .getDeviceCarb(this.idDevice)
      .subscribe(res => {
        this.devicecarb = res;
        this.volume = this.devicecarb.volume;
      });

  }

  setUpPrintedKmJour() {
    this.kmJourPrintedSubscription = this.toolKmJourService
      .getPrintedKmJour()
      .subscribe((kmJourPath) => {
        if (kmJourPath !== null && kmJourPath.points.length !== 0) {
          this.toPrint = kmJourPath;
          this.printPolyline();
          this.mapService.setFlagsIcons(kmJourPath.points);
          let time = (this.intervaleDate.endDate - this.intervaleDate.startDate) / 3600 / 1000 > 24 ? 60 * 24 : 60;
          this.displayChart({ intervald: this.intervaleDate, time, height: 180, fontSize: "10px" });
        } else if (kmJourPath !== null && kmJourPath.points.length === 0) {
          let time = (this.intervaleDate.endDate - this.intervaleDate.startDate) / 3600 / 1000 > 24 ? 60 * 24 : 60;
          this.displayChart({ intervald: this.intervaleDate, time, height: 180, fontSize: "10px" });
        }
      });
  }

  setUpCompletedSubscription() {
    this.intervaleDateSubscription = this.realTimeProcessing.getIntervalDate().subscribe((interval) => {
      this.intervaleDate = interval;
    });
    this.completedSubscription = this.realTimeProcessing
      .getCompleted()
      .subscribe((completed) => {
        this.printPolyline();
        this.mapService.setFlagsIcons(this.toPrint.points);
        let time = (this.intervaleDate.endDate - this.intervaleDate.startDate) / 3600 / 1000 > 24 ? 60 * 24 : 60;
        this.displayChart({ intervald: this.intervaleDate, time: 60, height: 180, fontSize: "10px" });
      });
  }

  printPolyline() {
    if (this.toPrint) {
      this.mapService.processMileageData(
        this.toPrint.points,
        this.toPrint.stops,
        this.realTimeService.imagesDir + 'stop_smal.png',
        '',
        Math.ceil(this.toPrint.points.length / 500)
      );
    }
  }

  showingTrajet() {
    this.showTrajet = !this.showTrajet;
  }

  setupKmJourPathSubscription() {
    this.kmJourPathSubscription = this.toolKmJourService
      .getKmJourPath()
      .subscribe((kmJourPath) => {
        this.kmJourPath = kmJourPath;
        this.cdr.detectChanges();
      });
  }

  setupMileageSubscription() {
    this.mileageSubscription = this.toolKmJourService
      .getMileage()
      .subscribe((mileage) => {
        this.mileage = mileage;
      });
  }

  setupHideComponentSubscription() {
    this.hideComponentSubscription = this.realTimeService
      .isBarToolStatus()
      .subscribe((status) => {
        this.hideComponent = status;
      });
  }

  displayChart(eventData: ChartParametre) {
    let devopt = ["speed", "ignition", "fuel"];

    // Options vars
    let speed: any[] = [];
    let ignition: any[] = [];
    let fuel: any = [];
    let tfu: any = [];

    let ok: boolean = false;
    forkJoin([
      this.technicaldataservice.getDeviceCarb(this.idDevice),
      this.technicaldataservice.getRepFuelVariation(this.idDevice, eventData.intervald),
      this.technicaldataservice.getFuelDetail(this.idDevice, eventData.intervald, devopt)
    ]).subscribe(([deviceCarbResult, repFuelVariationResult, fuelDetail]) => {

      // Now both requests are completed
      this.devicecarb = deviceCarbResult;
      this.volume = this.devicecarb.volume;

      this.repFuelVariation = repFuelVariationResult;
      this.printFulState(repFuelVariationResult);

      this.technicaldata = fuelDetail;
      if (this.technicaldata.length == 0) {
        this.toastr.warning("Pas de données à afficher");
      }
      this.technicaldata.forEach(stat => {
        if (stat.fuel != undefined && this.useFms) {
          if (this.devicecarb.max - this.devicecarb.min > 0) {
            if (
              stat.fuel >= this.devicecarb.min &&
              stat.fuel <= this.devicecarb.max
            ) {
              fuel.push({
                x: stat.date,
                y:
                  ((stat.fuel - this.devicecarb.min) /
                    (this.devicecarb.max - this.devicecarb.min)) *
                  this.devicecarb.volume
              });
            }
          } else {
            if (
              stat.fuel >= this.devicecarb.max &&
              stat.fuel <= this.devicecarb.min
            ) {
              fuel.push({
                x: stat.date,
                y:
                  ((stat.fuel - this.devicecarb.min) /
                    (this.devicecarb.max - this.devicecarb.min)) *
                  this.devicecarb.volume
              });
            }
          }
        }
        if (stat.speed != undefined) {
          speed.push({ x: stat.date, y: stat.speed });
        }
        if (stat.tfu != undefined && ok && this.useFms) {
          tfu.push({ x: stat.date, y: stat.tfu });
        }
        if (stat.ignition != undefined) {
          ignition.push({ x: stat.date, y: stat.ignition ? 1 : 0 });
          ok = true;
        }
      });
      //Charts vars
      let serie = [];
      let yaxis = [];
      let flags = [];

      //Flags
      if (this.useFms) {
        for (let j = 0; j < this.repFuelVariation.length; j++) {
          for (let i = 1; i < fuel.length; i++) {
            if (
              fuel[i - 1].x == this.repFuelVariation[j].approStartTime &&
              this.repFuelVariation[j].type == "APPRO"
            ) {
              flags.push({
                type: "flags",
                data: [
                  {
                    rep: this.repFuelVariation[j],
                    x: +fuel[i].x,
                    title: "Appro",
                    text:
                      "Appro Level: " +
                      (this.repFuelVariation[j].approEndFuelLevel -
                        this.repFuelVariation[j].approStartFuelLevel)
                  }
                ],
                linkedTo: "dataseries",
                onSeries: "dataseries",
                shape: "flag",
                showInLegend: false,
                color: "rgb(44, 161, 33)",
                fillColor: "rgb(44, 161, 33)",
                events: {
                  click: (event: any) => {
                    this.goToPoint(event.point.options.rep.approStartTime);
                  }
                }
              });
            } else if (
              fuel[i - 1].x == this.repFuelVariation[j].approStartTime &&
              this.repFuelVariation[j].type == "THIEF"
            ) {
              flags.push({
                type: "flags",
                data: [
                  {
                    rep: this.repFuelVariation[j],
                    x: fuel[i].x,
                    title: "Diminution",
                    text:
                      "Appro Level: " +
                      (this.repFuelVariation[j].approEndFuelLevel -
                        this.repFuelVariation[j].approStartFuelLevel)
                  }
                ],
                linkedTo: "dataseries",
                onSeries: "dataseries",
                shape: "flag",
                showInLegend: false,
                color: "rgb(230, 20, 9)",
                fillColor: "rgb(230, 20, 9)",
                events: {
                  click: (event: any) => {
                    this.goToPoint(event.point.options.rep.approStartTime);
                  }
                }
              });
            }
          }
        }
      }

      for (let i = 1; i < ignition.length; i++) {
        if (
          ignition[i].y > ignition[i - 1].y ||
          ignition[i].y < ignition[i - 1].y
        ) {
          ignition[i].x = ignition[i - 1].x;
        }
      }

      //Fill all series and yxais
      if (this.ChartSeries(ignition) == true) {
        yaxis.push({
          title: {
            text: "Ignition",
            style: {
              color: "rgb(255, 127, 0)",
              fontSize: eventData.fontSize
            }
          },
          labels: {
            style: {
              color: "rgb(255, 127, 0)",
              fontSize: eventData.fontSize
            }
          },
          min: 0,
          max: 1,
          alignTicks: false,
          endOnTick: false,
          tickInterval: 1
        });
        serie.push({
          name: "Ignition",
          type: "area",
          color: "rgb(255, 127, 0)",
          data: ignition,
          yAxis: this.PositionYaxis(yaxis, "Ignition")
        });
      }
      if (this.ChartSeries(fuel) && this.useFms) {
        yaxis.push({
          title: {
            text: "carburant",
            style: {
              color: "rgb(23, 119, 182)"
            }
          },
          labels: {
            format: "{value} " + "L",
            style: {
              color: "rgb(23, 119, 182)"
            }
          },
          min: 0,
          opposite: yaxis.length !== 0
        });
        serie.push({
          name: "Carburant",
          data: fuel,
          id: "dataseries",
          color: "rgb(23, 119, 182)",
          yAxis: this.PositionYaxis(yaxis, "carburant"),
          tooltip: {
            valueSuffix: "L",
            valueDecimals: 2
          }
        });
        flags.forEach(flag => {
          serie.push(flag);
        });
      }
      if (this.ChartSeries(speed) == true) {
        yaxis.push({
          title: {
            text: "vitesse",
            style: {
              color: "rgb(44, 161, 33)",
              fontSize: eventData.fontSize
            }
          },
          labels: {
            format: "{value} " + "Km/h",
            style: {
              color: "rgb(44, 161, 33)",
              fontSize: eventData.fontSize // Adjust the font size here
            }
          },
          min: 0
        });
        serie.push({
          name: "Vitesse",
          data: speed,
          style: {
            fontSize: eventData.fontSize // Adjust the font size here
          },
          color: "rgb(44, 161, 33)",
          yAxis: this.PositionYaxis(yaxis, "vitesse"),
          tooltip: {
            valueSuffix: "KM/H",
            valueDecimals: 2

          }
        });
      }

      this.options = new Highcharts.Chart({
        chart: { 
          renderTo: "container", 
          type: "spline", 
          height: 180, 
          width: 720, 
          zoomType: "x",
          style: {
            fontSize: eventData.fontSize // Apply font size to the chart container
          }
        },
        title: { 
          text: `Véhicule: ${this.realTimeService.getVehiculeVlue().matricule}`,
          style: {
            fontSize: eventData.fontSize // Apply font size to the title
          }
        },
        xAxis: { 
          type: "datetime", 
          tickInterval: 3600 * 1000,
          labels: {
            style: {
              fontSize: eventData.fontSize // Apply font size to x-axis labels
            }
          }
        },
        yAxis: yaxis,
        plotOptions: { series: { turboThreshold: 10000 } },
        series: serie
      });

    });


  }

  printFulState(repFuelVariation: RepFuelVariation[]) {
    repFuelVariation.forEach(rep => {
      var val = this.findClosestPoint(+rep.approStartTime, this.toPrint.points)
      this.aproPoints[+rep.approStartTime] = val;
      if (rep.type === 'APPRO') {
        this.mapService.processFuelMarkers({ val: val, rep: rep, volume: this.volume }, 'assets/icons/realtime/km-jour/station-essence-verify.png');
      } else if (rep.type === 'THIEF') {
        this.mapService.processFuelMarkers({ val: val, rep: rep, volume: this.volume }, 'assets/icons/realtime/km-jour/station-essence-not.png');
      }

    })
  }

  goToPoint(id: number) {
    const p = this.aproPoints[id];
    this.mapService.map.setView({ lat: p.lat, lng: p.lng }, 17)
  }
  //Return the position of Yaxis
  PositionYaxis(data: any, option: string) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].title.text === option) {
        return i;
      }
    }
  }
  isExactRoute(): boolean {
    const currentUrl = this.route.snapshot.url.map(segment => segment.path).join('/');
    return currentUrl.endsWith('km-jour');
  }
  //check if the series is empty or not
  ChartSeries(data: any = []) {
    let ok: boolean;
    data.forEach((dt: any) => {
      ok = dt.y == undefined ? false : true;
    });
    return ok;
  }

  scrrenWith(): number {
    const screenWidth = window.innerWidth;

    let chartWidth = 700; // Default width for larger screens

    if (screenWidth < 650) {
      chartWidth = 300; // Set width to 400 for smaller screens
    } else if (screenWidth >= 650 && screenWidth < 1000) {
      chartWidth = 600; // Set width to 600 for medium-sized screens
    }
    return chartWidth;
  }

  findClosestPoint(approStartTime: number, points: Point[]): Point | null {
    if (points.length === 0) {
      return null;
    }

    let closestPoint = points[0];
    let approStartTimeMillis = approStartTime;

    for (let i = 0; i < points.length; i++) {
      if (points[i].date >= approStartTimeMillis) {
        if (i === 0) return points[i]; // First point is the closest
        // Determine if the current or previous point is closer
        let prevDiff = approStartTimeMillis - points[i - 1].date;
        let currDiff = points[i].date - approStartTimeMillis;
        closestPoint = prevDiff < currDiff ? points[i - 1] : points[i];
        break;
      }
    }

    return closestPoint;
  }

  ngOnDestroy() {
    this.showTrajet = false;
    this.kmJourPathSubscription.unsubscribe();
    this.mileageSubscription.unsubscribe();
    this.hidChart$.unsubscribe();
    this.intervaleDateSubscription.unsubscribe();
    this.realTimeService.stopRealTime();
    this.realTimeService.clearRealTime();
    this.hideComponentSubscription.unsubscribe();
    this.kmJourPrintedSubscription.unsubscribe();
    this.completedSubscription.unsubscribe();
  }
}