import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { iconsCar } from "./svg.data";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Injectable({ providedIn: 'root' })
export class SvgService {
    constructor(private http: HttpClient, private sanitizer: DomSanitizer) { }

    loadSvgAsText(fileName: string): Promise<string> {
        return new Promise<string>((resolve, reject) => {
          this.http.get(`assets/${fileName}`, { responseType: 'text' }).subscribe(
            (svgText: string) => {
              resolve(svgText);
            },
            (error) => {
              console.error('Error loading SVG:', error);
              resolve(''); // Return empty string if SVG file not found or error occurs
            }
          );
        });
    }
    getVehiculeSvg(vehiculeIcon: string): string {
      return iconsCar[vehiculeIcon] ? iconsCar[vehiculeIcon] : iconsCar["rimtel.svg"];
    }
    getVehiculeSvgSafeHtml(vehiculeIcon: string): SafeHtml{
      return this.sanitizer.bypassSecurityTrustHtml(this.getVehiculeSvg(vehiculeIcon));
    }
    isItAnSvg(fileName: string): boolean {
      return fileName && fileName.includes('.svg');
    }
    getCarSvg(vehiculeIcon:string, colorPrimary: string, colorSecondary: string){
        vehiculeIcon = vehiculeIcon.replace(/#9cba73/g, colorPrimary);
        vehiculeIcon=  vehiculeIcon.replace(/#b7ce96/g, colorSecondary);
        return vehiculeIcon;
    }
    getSvgFromAssets(fileName: string): Observable<string> {
      return this.http.get(`assets/vehicules/${fileName}.svg`, { responseType: 'text' });
    }

    svgToBase64(filePath: string): Promise<string> {
      return new Promise<string>((resolve, reject) => {
        this.http.get(`${filePath}`, { responseType: 'text' }).subscribe(
          (svgText: string) => {
            resolve(`data:image/svg+xml;base64,${btoa(svgText)}`);
          },
          (error) => {
            console.error('Error loading SVG:', error);
            resolve(''); // Return empty string if SVG file not found or error occurs
          }
        );
      });
    }
}