<table class="table table-fixed" style="font-family: 'Inconsolata', monospace;font-size: 14px;">
  <thead>
  <tr>
    <th width="40%">P.O.I</th>
    <th width="60%">Véhicules</th>
  </tr>
  </thead>
  <tbody>
    <tr *ngIf="vehiculesAccordingToPoi.length == 0">
        <td colspan="2">
            <div class="alert alert-warning alert-dismissible" style="border-radius: 0px;text-align: center">
                <button type="button" class="close" data-dismiss="alert" aria-hidden="true">
                    ×
                </button>
                <h4 style="color: white">
                    <i class="fa fa-info-circle"></i> Information
                </h4>
                Aucune information n'a été disponible.
            </div>
        </td>
    </tr>

  <tr *ngFor="let vehiculeAccordingToPoi of vehiculesAccordingToPoi"
      (click)="selectedVehiculeAccordingToPoi = vehiculeAccordingToPoi;overviewService.displayVehiculesAccordingToPoi(vehiculeAccordingToPoi)" [ngClass]="{'selectedPOi': vehiculeAccordingToPoi === selectedVehiculeAccordingToPoi}">
    <td width="40%">
      {{vehiculeAccordingToPoi.poi.name}} <i style="font-weight: bold;">({{vehiculeAccordingToPoi.poi.ray}} mètres)</i>
    </td>
    <td width="60%">
      {{realTimeRestService.getVehiculesAlias(vehiculeAccordingToPoi.vehicules)}} <i style="font-weight: bold;">({{vehiculeAccordingToPoi.vehicules.length}})</i>
    </td>
  </tr>
  </tbody>
</table>
