import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NewImportExportComponent } from './new-import-export.component';
import { GlobalStopsReportComponent } from './_components/pdf-settings/global-stop-report/global-stop-report.component';
import { ListOfStopsReportComponent } from './_components/pdf-settings/list-of-stops-report/list-of-stops-report.component';
import { EtatBoitierActuelComponent } from './_components/pdf-settings/etat_boitier_actuel/etat_boitier_actuel.component';
import { AuthGuard } from 'src/app/utils/security/auth.guard';
const appRoutes: Routes = [
  {
    path: 'dataExport',
    component: NewImportExportComponent,
    // canActivate: [AuthGuard],

    children: [
      {
        path: 'global-stops-report',
        component: GlobalStopsReportComponent,
      },
      {
        path: 'list-of-stops',
        component: ListOfStopsReportComponent,
      },
      {
        path: 'etat-actuel',
        component: EtatBoitierActuelComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(appRoutes)],
  exports: [RouterModule],
})
export class NewImportExportRoutingModule {}
