import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { dns } from 'src/app/global.config';
import { createAuthorizationHeader } from 'src/app/utils/security/headers';
import {
  GroupsVehicules,
  RapportDto,
  ReportAuto,
} from 'src/app/client-management/data-management/data-management.model';

import { DateInterval } from 'src/app/shared/model';
import { User } from 'src/app/authentification/signin/credentials';

@Injectable({
  providedIn: 'root',
})
export class NewImportExportRestService {
  currentUser: User = new User();
  constructor(private _http: HttpClient, private router: Router) {}

  getAllGroups(): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.get(dns + 'groupes/minifyOnlyGroups', {
      headers: headers,
    });
  }

  getAllVehicules(): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.get(dns + 'vehicules/minifyVehicules', {
      headers: headers,
    });
  }

  getGlobalStopsRepport(rapportDto: RapportDto): Observable<Blob> {
    let headers = createAuthorizationHeader();
    return this._http.post<Blob>(
      dns + 'stops/globalStop?timezoneOffset=' + new Date().getTimezoneOffset(),
      rapportDto,
      { headers: headers, responseType: 'blob' as 'json' }
    );
  }

  getListOfStopsRepport(rapportDto: RapportDto): Observable<Blob> {
    let headers = createAuthorizationHeader();
    return this._http.post<Blob>(
      dns + 'stops/stopList?timezoneOffset=' + new Date().getTimezoneOffset(),
      rapportDto,
      { headers: headers, responseType: 'blob' as 'json' }
    );
  }

  getEtatActuelReport(rapportDto: RapportDto): Observable<Blob> {
    let headers = createAuthorizationHeader();
    return this._http.post<Blob>(
      dns +
        'devices/etat_actuel?timezoneOffset=' +
        new Date().getTimezoneOffset(),
      rapportDto,
      { headers: headers, responseType: 'blob' as 'json' }
    );
  }

  getAllPois(): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.get(dns + 'pointInterests/dtos', { headers: headers });
  }

  getPaths(
    repportOf: string,
    GroupsOrVehicules: GroupsVehicules,
    dateBegin: number,
    dateEnd: number
  ): Observable<Blob> {
    let headers = createAuthorizationHeader();
    return this._http.post<Blob>(
      dns +
        'pathsExGlobal?repportOf=' +
        repportOf +
        '&startDate=' +
        dateBegin +
        '&endDate=' +
        dateEnd,
      GroupsOrVehicules,
      { headers: headers, responseType: 'blob' as 'json' }
    );
  }

  getArretLieuxConnux(
    pois: string[],
    startDate: number,
    endDate: number,
    minute: number
  ): Observable<Blob> {
    let headers = createAuthorizationHeader();
    return this._http.post<Blob>(
      dns +
        'arretLieuxConnusEx?minute=' +
        minute +
        '&startDate=' +
        startDate +
        '&endDate=' +
        endDate,
      pois,
      { headers: headers, responseType: 'blob' as 'json' }
    );
  }
}
