<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '0px', fullScreenBackdrop: true }"></ngx-loading>

<div class="row">
  <ng-container *ngIf="!loading">
    <!-- Error Section ! -->
    <ng-container *ngIf="error.errorMessage">
      <div class="col-md-12">
        <div class="alert alert-danger alert-dismissible" style="border-radius: 0px; text-align: center">
          <button type="button" class="close" data-dismiss="alert" aria-hidden="true">
            ×
          </button>
          <h4 style="color: white">
            <i class="fa fa-exclamation-triangle"></i> Attention !
          </h4>
          {{ error.errorMessage }}
        </div>
      </div>
    </ng-container>

    <!-- SHOW mode ! -->
    <ng-container *ngIf="!error.errorMessage && mode == 'SHOW' && !loading">
      <app-vehicule-details [currentVehicule]="currentVehicule"></app-vehicule-details>
    </ng-container>
    <div class="container">
      <!-- ADD/UPDATE mode ! -->
      <ng-container *ngIf="mode == 'ADD' || mode == 'UPDATE'">
        <div class="col-lg-12 well formBC">
          <div class="row">
            <div class="mt-2 mb-2 text-center well bgg">
              <h4 style="color: white">Modification de Vehicule</h4>
            </div>
            <div class="col-md-12">
              <form #vehiculeForm="ngForm" class="form-horizontal">
                <div class="col-sm-12">
                  <!-- device & matricule -->
                  <div class="row">
                    <div class="col-sm-6 form-group mrg">
                      <label>Boitier</label>
                      <div class="form-control">
                        <ng-select
                        *ngIf="mode == 'ADD'"
                        [items]="unusedDevicesItems"
                        [multiple]="false"
                        (data)="selectedDevice($event)"
                        bindValue="id"
                        bindLabel="text"
                        placeholder="aucun boitier selectionné">
                        [allowClear]="true"
                        </ng-select>
                        <label *ngIf="mode == 'UPDATE'">{{
                          currentVehicule?.device?.idDevice
                          }}</label>
                      </div>
                    </div>

                    <div class="col-sm-6 form-group mrg">
                      <label>Matricule</label>
                      <div [ngClass]="{
                          'has-success': matricule.valid || !matricule.touched,
                          'has-error': !matricule.valid && matricule.touched
                        }">
                        <div>
                          <input [(ngModel)]="currentVehicule.matricule" name="matricule" #matricule="ngModel"
                            type="text" minlength="5" maxlength="50" placeholder="Matricule" class="form-control"
                            required="true" />
                          <span class="help-block" *ngIf="!matricule.valid && matricule.touched">
                            le champ matricule est obligatoire !
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Groupes & Chauffeurs -->

                  <div class="row">

                    <div class="col-sm-6 form-group mrg">
                      <label>Groupes</label>
                      <div>
                        <ng-select
                          *ngIf="groupsItems.length != 0"
                          [items]="groupsItems"
                          bindLabel="text"
                          bindValue="id"
                          [(ngModel)]="selectedGroupsItems"
                          [multiple]="true"
                          [compareWith]="compareGroups"
                          (ngModelChange)="onGroupsSelected($event)"
                          [allowClear]="true"
                          placeholder="aucun groupe selectionné">
                        </ng-select>
                    
                        <span *ngIf="groupsItems.length == 0" class="badge bg-green" style="cursor: pointer">
                          <i class="fa fa-plus-circle" aria-hidden="true"></i>
                          Vous n'avez aucun groupe pour le moment
                        </span>
                      </div>
                    </div>
                    
                    <div class="col-sm-6 form-group mrg">
                      <label>Conducteurs</label>
                      <div class="form-control">
                        ,,
                        <ng-select
                          *ngIf="driversItems.length != 0 && mode == 'ADD'"
                          [items]="driversItems"
                          [multiple]="false"
                          bindLabel="text"
                          bindValue="id"
                          (data)="selectedDrivers($event)"
                          [allowClear]="true"
                          placeholder="aucun conducteur selectionné">
                        </ng-select>

                        <span *ngIf="driversItems.length == 0 && mode == 'ADD'" class="badge bg-green"
                          style="cursor: pointer">
                          <i class="fa fa-plus-circle" aria-hidden="true"></i>
                          Vous n'avez aucun conducteur pour le moment
                        </span>

                        <label *ngIf="mode == 'UPDATE'" class="control-label">{{
                          dataManagementService.getDriverName(
                          currentVehicule?.driver
                          )
                          }}</label>
                      </div>
                    </div>
                  </div>

                  <!-- Surnom & mark -->

                  <div class="row">
                    <div class="col-sm-6 form-group mrg">
                      <label>Surnom</label>
                      <div>
                        <input [(ngModel)]="currentVehicule.alias" name="alias" type="text" maxlength="50"
                          placeholder="Surnom" class="form-control" />
                      </div>
                    </div>
                    <div class="col-sm-3 form-group mrg">
                      <label>Marque</label>
                      <div>
                        <input [(ngModel)]="currentVehicule.mark" name="mark" type="text" maxlength="50"
                          placeholder="Marque" class="form-control" />
                      </div>
                    </div>
                    <div class="col-sm-3 form-group mrg">
                      <label>Type</label>
                      <div>
                        <input [(ngModel)]="currentVehicule.type" name="type" type="text" maxlength="50"
                          placeholder="Type" class="form-control" />
                      </div>
                    </div>
                  </div>

                  <!-- Code & subMark & signification-->

                  <div class="row">
                    <div class="col-sm-6 form-group mrg">
                      <label>Remorque</label>
                      <div>
                        <input [(ngModel)]="currentVehicule.subMark" name="subMark" type="text" placeholder="Remorque"
                          class="form-control" />
                      </div>
                    </div>
                    <div class="col-sm-3 form-group mrg">
                      <label>Code</label>
                      <div>
                        <input [(ngModel)]="currentVehicule.code" name="code" type="text" placeholder="Code"
                          class="form-control" />
                      </div>
                    </div>

                    <div class="col-sm-3 form-group mrg">
                      <label>Signification </label>
                      <div>
                        <select [(ngModel)]="currentVehicule.signification" name="signification"
                          class="form-control input-sm select2">
                          <option value="KM">KM</option>
                          <option value="H">H</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <!-- SIM & Capacité & Speed -->

                  <div class="row">
                    <div class="col-sm-6 form-group mrg">
                      <label>N° SIM</label>
                      <div>
                        <input [(ngModel)]="currentVehicule.device.deviceNumber" name="deviceNumber" type="text"
                          placeholder="N° SIM" class="form-control" />
                      </div>
                    </div>
                    <div class="col-sm-3 form-group mrg">
                      <label>Capacité</label>
                      <div>
                        <input [(ngModel)]="currentVehicule.capacity" name="capacity" type="text" placeholder="Capacité"
                          class="form-control" />
                      </div>
                    </div>

                    <div class="col-sm-3 form-group mrg">
                      <label>Vitesse Maximale</label>
                      <div>
                        <input [(ngModel)]="currentVehicule.maxSpeed" name="maxSpeed" type="number"
                          placeholder="Vitesse Maximale" class="form-control" />
                      </div>
                    </div>
                  </div>

                  <!-- Rotation & Location -->
                  <div class="row" *ngIf="
                      signinService.isHasChargePfb() ||
                      signinService.isHasRole('ROLE_CHARGE_PFB') ||
                      signinService.isHasRole('ROLE_CHARGE_PFB_AFFECTATION')
                    ">
                    <div class="col-sm-6 form-group mrg">
                      <label>M.Rotation</label>
                      <div>
                        <input [(ngModel)]="currentVehicule.rotation" name="rotation" type="number" min="0"
                          placeholder="M.Rotation" class="form-control" />
                      </div>
                    </div>

                    <div class="col-sm-6 form-group mrg">
                      <label>Location</label>
                      <div>
                        <input [(ngModel)]="currentVehicule.rental" name="rental" type="number" min="0"
                          placeholder="Location" class="form-control" />
                      </div>
                    </div>
                  </div>
                  <!-- end Rotation & Location -->

                  <!--  Secteur & Carte Jawaz & gasoil -->
                  <div class="row">
                    <div class="col-sm-3 form-group mrg">
                      <label>Secteur</label>
                      <div>
                        <input [(ngModel)]="currentVehicule.secteur" name="secteur" type="text" placeholder="Secteur"
                          class="form-control" />
                      </div>
                    </div>

                    <div class="col-sm-3 form-group mrg">
                      <label>N° carte gasoil</label>
                      <div>
                        <input [(ngModel)]="currentVehicule.fuelCardNumber" name="fuelCardNumber" type="text"
                          placeholder="N° carte gasoil" class="form-control" />
                      </div>
                    </div>
                    <div class="col-sm-3 form-group mrg">
                      <label>Solde carte gasoil</label>
                      <div>
                        <input [(ngModel)]="currentVehicule.cardFuelSolde" name="cardFuelSolde" type="number"
                          placeholder="Solde carte gasoil" class="form-control" />
                      </div>
                    </div>
                    <div class="col-sm-3 form-group mrg">
                      <label>N° Tag Jawaz</label>
                      <div>
                        <input [(ngModel)]="currentVehicule.jawazCardNumber" name="jawazCardNumber" type="text"
                          placeholder="N° Tag Jawaz" class="form-control" />
                      </div>
                    </div>
                  </div>

                  <!--****************-->
                  <div class="row">
                    <div class="col-sm-6 form-group mrg">
                      <label>Mise en circulation</label>
                      <div>
                        <input type="text" class="form-control input-sm" bsDatepicker name="date"
                          [(ngModel)]="currentVehicule.circulationAt" [bsConfig]="{
                            dateInputFormat: 'DD-MM-YYYY, HH:mm',
                            containerClass: 'theme-dark-blue'
                          }" placement="bottom" />
                      </div>
                    </div>
                    <div class="col-sm-3 form-group mrg">
                      <label>Icon</label>
                      <div class="dropdown">
                        <button style="width: 70%;" class="btn btn-default dropdown-toggle select-button" type="button" data-toggle="dropdown"
                          aria-haspopup="true" aria-expanded="true">
                          <img class="vehicule-icon" *ngIf="!svgService.isItAnSvg(selectedImage.uri)" src="{{ imagesCarsDir }}{{ selectedImage.uri }}" />
                          <div class="vehicule-svg-icon" *ngIf="svgService.isItAnSvg(selectedImage.uri)" [innerHTML]="svgService.getVehiculeSvgSafeHtml(selectedImage.uri)"> 
                          </div>
                          {{ selectedImage.text }} <span class="caret"></span>
                        </button>

                        <ul class="dropdown-menu" style="top: auto; bottom: 100%; border-radius: 0px">
                          <li *ngFor="let image of images" (click)="
                              selectedImage = image;
                              currentVehicule.maxSpeed = image.speed
                            ">
                            <a class="select-button-item">
                              <img  *ngIf="!svgService.isItAnSvg(image.uri)" src="{{ imagesCarsDir }}{{ image.uri }}" width="20px" />
                              <div style="width: 17px; transform: rotate(90deg);" *ngIf="svgService.isItAnSvg(image.uri)" [innerHTML]="svgService.getVehiculeSvgSafeHtml(image.uri)"> 
                              </div>
                              {{ image.text }}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-sm-3 form-group mrg">
                      <label>Arrêt moteur</label>
                      <div>
                        <mat-slide-toggle [(ngModel)]="currentVehicule.arretMoteur"
                          name="arretMoteur"></mat-slide-toggle>
                      </div>

                    </div>
                  </div>

                  <!--**************-->
                  <div class="row">
                    <div class="col-sm-6 form-group mrg">
                      <label>Assurance</label>
                      <div>
                        <button *ngIf="!currentVehicule.avatar" class="btn btn-success" (click)="avatar.click()">
                          <i class="fa fa-camera" aria-hidden="true"></i>
                        </button>

                        <button *ngIf="currentVehicule.avatar" class="btn btn-success"
                          (click)="currentVehicule.avatar = null">
                          {{ currentVehicule.avatar.name }}
                          <i class="fa fa-trash-o" aria-hidden="true"></i>
                        </button>

                        <input #avatar name="avatar" style="display: none" (change)="onAvatarChange($event)" type="file"
                          class="form-control" />
                      </div>
                      <mat-checkbox *ngIf="currentVehicule?.avatarUri" class="col-sm-4" [value]="deleteAvatar"
                        (change)="avatarCheckboxChange($event.checked)">
                        Supprimer La photo (Assurance)
                      </mat-checkbox>
                    </div>

                    <div class="col-sm-6 form-group mrg">
                      <label>Carte grise</label>
                      <div>
                        <button *ngIf="currentVehicule.grayCard" class="btn btn-success"
                          (click)="currentVehicule.grayCard = null">
                          {{ currentVehicule.grayCard.name }}
                          <i class="fa fa-trash-o" aria-hidden="true"></i>
                        </button>

                        <button *ngIf="!currentVehicule.grayCard" class="btn btn-success" (click)="grayCard.click()">
                          <i class="fa fa-camera" aria-hidden="true"></i>
                        </button>

                        <input #grayCard name="grayCard" style="display: none" (change)="onGrayCardChange($event)"
                          type="file" class="form-control" />
                      </div>
                      <mat-checkbox *ngIf="currentVehicule?.grayCardUri" class="col-sm-4" [value]="deleteGrayCard"
                        (change)="grayCardCheckboxChange($event.checked)">
                        Supprimer La photo (Carte Grise)
                      </mat-checkbox>
                    </div>
                  </div>
                  <!--**************-->
                  <div class="row">
                    <div class="col-sm-6 form-group mrg">
                      <ng-container *ngIf="isMenaraOrCarrier; else label3">
                        <label>Carnet Tachygraphe</label>
                      </ng-container>

                      <ng-template #label3><label>Engagement</label></ng-template>
                      <div>
                        <button *ngIf="!currentVehicule.thirdContracting" class="btn btn-success"
                          (click)="thirdContracting.click()">
                          <i class="fa fa-camera" aria-hidden="true"></i>
                        </button>

                        <button *ngIf="currentVehicule.thirdContracting" class="btn btn-success"
                          (click)="currentVehicule.thirdContracting = null">
                          {{ currentVehicule.thirdContracting.name }}
                          <i class="fa fa-trash-o" aria-hidden="true"></i>
                        </button>

                        <input #thirdContracting name="thirdContracting" style="display: none"
                          (change)="onThirdContractingChange($event)" type="file" class="form-control" />
                      </div>
                      <mat-checkbox *ngIf="currentVehicule.thirdContractingUri" class="col-sm-4"
                        [value]="deleteThirdContracting" (change)="
                          thirdContractingCheckboxChange($event.checked)
                        ">
                        Supprimer La photo
                      </mat-checkbox>
                    </div>
                    <div class="col-sm-6 form-group mrg">
                      <label>Permis</label>
                      <div>
                        <button *ngIf="!currentVehicule.permis" class="btn btn-success" (click)="permis.click()">
                          <i class="fa fa-camera" aria-hidden="true"></i>
                        </button>

                        <button *ngIf="currentVehicule.permis" class="btn btn-success"
                          (click)="currentVehicule.permis = null">
                          {{ currentVehicule.permis.name }}
                          <i class="fa fa-trash-o" aria-hidden="true"></i>
                        </button>

                        <input #permis name="permis" style="display: none" (change)="onPermisChange($event)" type="file"
                          class="form-control" />
                      </div>
                      <mat-checkbox *ngIf="currentVehicule.permisUri" class="col-sm-4" [value]="deletePermisCard"
                        (change)="permisCheckboxChange($event.checked)">
                        Supprimer La photo
                      </mat-checkbox>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6 form-group mrg" *ngFor="let item of vehicules; let i = index">
                      <label>Vehicule {{ i + 1 }}</label>
                      <div>
                        <button class="btn btn-success" disabled>
                          <i class="fa fa-camera" aria-hidden="true"></i>
                        </button>

                        <input #fileInput style="display: none" (change)="onImageSelected($event, i)" type="file"
                          class="form-control" />
                      </div>

                      <mat-checkbox class="col-sm-4" (change)="deleteVehicule($event.checked, item)">
                        Supprimer la photo
                      </mat-checkbox>
                    </div>
                    <div class="col-sm-6 form-group mrg" *ngIf="vehicules.length < 2">
                      <label>Vehicule{{ vehicules.length + 1 }}</label>
                      <div>
                        <button *ngIf="!currentVehicule.vehiculeImage" class="btn btn-success"
                          (click)="vehiculeImage.click()">
                          <i class="fa fa-camera" aria-hidden="true"></i>
                        </button>

                        <button *ngIf="currentVehicule.vehiculeImage" class="btn btn-success"
                          (click)="currentVehicule.vehiculeImage = null">
                          {{ currentVehicule.vehiculeImage.name }}
                          <i class="fa fa-trash-o" aria-hidden="true"></i>
                        </button>

                        <input #vehiculeImage name="permis" style="display: none"
                          (change)="onVehiculeImageChange($event)" type="file" class="form-control" />
                      </div>
                    </div>
                  </div>

                  <!--******* Passassion *******-->
                  <div class="row">
                    <div class="col-sm-3 form-group mrg">
                      <ng-container *ngIf="isMenaraOrCarrier; else label1">
                        <label>Visite Technique</label>
                      </ng-container>

                      <ng-template #label1><label>Passation 1</label></ng-template>
                      <div>
                        <button *ngIf="!currentVehicule.contracting" class="btn btn-success"
                          (click)="contracting.click()">
                          <i class="fa fa-camera" aria-hidden="true"></i>
                        </button>

                        <button *ngIf="currentVehicule.contracting" class="btn btn-success"
                          (click)="currentVehicule.contracting = null">
                          {{ currentVehicule.contracting.name }}
                          <i class="fa fa-trash-o" aria-hidden="true"></i>
                        </button>

                        <input #contracting name="contracting" style="display: none"
                          (change)="onContractingChange($event)" type="file" class="form-control" />
                      </div>
                      <mat-checkbox *ngIf="currentVehicule.contractingUri" class="col-sm-4" [value]="deleteContracting"
                        (change)="contractingCheckboxChange($event.checked)">
                        Supprimer la photo
                      </mat-checkbox>
                    </div>

                    <div class="col-sm-3 form-group mrg">
                      <ng-container *ngIf="isMenaraOrCarrier; else label2">
                        <label>Autorisation de circulation</label>
                      </ng-container>

                      <ng-template #label2><label>Passation 2</label></ng-template>
                      <div>
                        <button *ngIf="!currentVehicule.secondContracting" class="btn btn-success"
                          (click)="secondContracting.click()">
                          <i class="fa fa-camera" aria-hidden="true"></i>
                        </button>

                        <button *ngIf="currentVehicule.secondContracting" class="btn btn-success"
                          (click)="currentVehicule.secondContracting = null">
                          {{ currentVehicule.secondContracting.name }}
                          <i class="fa fa-trash-o" aria-hidden="true"></i>
                        </button>

                        <input #secondContracting name="secondContracting" style="display: none"
                          (change)="onSecondContractingChange($event)" type="file" class="form-control" />
                      </div>
                      <mat-checkbox *ngIf="currentVehicule.secondContractingUri" class="col-sm-4"
                        [value]="deleteSecondContracting" (change)="
                          secondContractingCheckboxChange($event.checked)
                        ">
                        Supprimer la photo
                      </mat-checkbox>
                    </div>

                    <div class="col-sm-3 form-group mrg" *ngFor="let item of passasions; let i = index">
                      <label>Passation {{ i + 3 }}</label>
                      <div>
                        <button class="btn btn-success" disabled>
                          <i class="fa fa-camera" aria-hidden="true"></i>
                        </button>

                        <input #fileInput style="display: none" (change)="onImageSelected($event, i)" type="file"
                          class="form-control" />
                      </div>

                      <mat-checkbox class="col-sm-4" (change)="deletePassion($event.checked, item)">
                        Supprimer la photo
                      </mat-checkbox>
                    </div>

                    <div class="col-sm-3 form-group mrg" *ngIf="
                        currentVehicule.secondContractingUri &&
                        currentVehicule.contractingUri
                      ">
                      <label>Passation {{ passasions.length + 3 }}</label>
                      <div>
                        <button *ngIf="!currentVehicule.passasion" class="btn btn-success" (click)="permis.click()">
                          <i class="fa fa-camera" aria-hidden="true"></i>
                        </button>

                        <button *ngIf="currentVehicule.passasion" class="btn btn-success"
                          (click)="currentVehicule.passation = null">
                          {{ currentVehicule.passasion.name }}
                          <i class="fa fa-trash-o" aria-hidden="true"></i>
                        </button>

                        <input #permis name="permis" style="display: none" (change)="onPassionChange($event)"
                          type="file" class="form-control" />
                      </div>
                    </div>
                  </div>

                  <div class="form-group mrg">
                    <label></label>
                    <app-email-manager [showActiveSectionOfAllVehicules]="false" [showActiveSection]="false"
                      [emailMode]="true" [emailsHash]="currentVehicule.emails" (onChange)="onEmailChange($event)">
                    </app-email-manager>
                  </div>

                  <!-- Email & Icon section -->

                  <!--
            ========================== Modal FOOTER (START) ===============================
          -->
                  <div class="box-footer">
                    <button *ngIf="mode == 'ADD'" (click)="addOrUpdateVehicule()" class="btn btn-info pull-right"
                      [disabled]="
                        !vehiculeForm.valid ||
                        !this.currentVehicule.device.idDevice
                      ">
                      <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                      Valider
                    </button>
                    <button *ngIf="mode == 'UPDATE'" (click)="addOrUpdateVehicule()" class="btn btn-info pull-right"
                      [disabled]="!vehiculeForm.valid">
                      <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                      Enregistrer
                    </button>
                    <button class="btn btn-default" (click)="cancel()">
                      Annuler
                    </button>
                  </div>
                </div>
                <!--
            ========================== Modal FOOTER (END) ===============================
          -->
              </form>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <ng-container *ngIf="mode == 'ASSIGN'">
      <div class="col-md-12">
        <h4 style="
            font-family: 'Inconsolata', monospace;
            font-weight: bold;
            color: #3c8dbc;
          ">
          <i class="fa fa-hand-o-right"></i>
          <span style="text-decoration: underline">Affecter conducteur au véhicule :
            {{ currentVehicule?.matricule }}</span>
        </h4>
      </div>

      <form #vehiculeForm="ngForm" class="form-horizontal">
        <div class="box-body">
          <!-- Groups & chauffeurs -->
          <div class="form-group has-success">
            <label class="col-sm-2 control-label">Conducteurs x</label>

            <div class="col-lg-4 col-md-4">
              
              <ng-select *ngIf="driversItems.length > 0"
                [items]="driversItems"
                bindLabel="text"
                bindValue="id"
                [multiple]="false"
                (change)="selectedDrivers($event)"
                [allowClear]="true"
                placeholder="aucun conducteur sélectionné">
              </ng-select>

              <span *ngIf="driversItems.length == 0" class="badge bg-green" style="cursor: pointer">
                <i class="fa fa-plus-circle" aria-hidden="true"></i> Vous n'avez
                aucun conducteur pour le moment
              </span>
            </div>

            <label class="col-sm-2 control-label">Date Affectation</label>
            <div class="col-lg-4 col-md-4">
              <input type="text" class="form-control input-sm" bsDatepicker name="assignmentDate"
                [(ngModel)]="assignmentDate" [bsConfig]="{
                  dateInputFormat: 'DD-MM-YYYY, HH:mm',
                  containerClass: 'theme-dark-blue'
                }" [maxDate]="endDate" placement="bottom" required />
            </div>
          </div>
        </div>

        <!--
          ========================== Modal FOOTER (START) ===============================
        -->
        <div class="box-footer">
          <button *ngIf="driversItems.length > 0" (click)="assignDriverToVehicule()" class="btn btn-info pull-right"
            [disabled]="!currentVehicule.driver">
            <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Affecter
          </button>
          <button class="btn btn-default" (click)="cancel()">Annuler</button>
        </div>
        <!--
          ========================== Modal FOOTER (END) ===============================
        -->
      </form>
    </ng-container>
  </ng-container>
</div>
