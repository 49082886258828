<mat-card class="mb-10 file-manager-box tagus-card" [class.component-dark-theme]="isDark()"
    [class.component-rtl-enabled]="isRTLEnabled()" style="height: 95%; margin-right: 10px; position: relative;
    ">

    <app-default-content *ngIf="!showPdf || !file"></app-default-content>
    <iframe class="body" *ngIf="showPdf && file && isPdf" [src]="file" style="border: none;" type="application/pdf"
        width="100%" height="100%">
        <p>It appears your web browser doesn't support viewing PDFs. You can <a [href]="file">download the PDF</a>
            instead.</p>
    </iframe>

    <app-pdf-excel-costum
        *ngIf="showPdf && file && !isPdf && currentRapport !== 'Rapport de Planification ciment' && currentRapport !== 'Rapport des remorques' && currentRapport !== 'Liste des Arrêts' && currentRapport !=='Etat actuel des boîtiers'">
    </app-pdf-excel-costum>

    <app-plan-ciment-excel *ngIf="
        showPdf &&
        file &&
        !isPdf &&
        currentRapport === 'Rapport de Planification ciment'
    ">
    </app-plan-ciment-excel>

    <app-etat-boitier-excel *ngIf="showPdf && file && !isPdf && currentRapport =='Etat actuel des boîtiers'">
    </app-etat-boitier-excel>
    <app-vehicule-activities-excel *ngIf="
            showPdf &&
            file &&
            !isPdf &&
        currentRapport === 'Liste des Arrêts'
        ">
    </app-vehicule-activities-excel>
    <app-remorque-excel *ngIf="showPdf && file && !isPdf && currentRapport === 'Rapport des remorques'">
    </app-remorque-excel>



</mat-card>