import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SigninComponent } from './authentification/signin/signin.component';
import { ClientManagementComponent } from './client-management/client-management.component';
import { RealtimeComponent } from './client-management/realtime/realtime.component';
import { DataManagementComponent } from './client-management/data-management/data-management.component';
import * as accountGuards from './utils/security/subAccount.guard';
import { AuthGuard } from './utils/security/auth.guard';
import {
  VehiculeComponent,
  VehiculeFormComponent,
  GroupComponent,
  GroupFormComponent,
  DriverComponent,
  DriverFormComponent,
  PoiComponent,
  MissionComponent,
  MissionTrackingComponent,
} from './client-management/data-management';
import { HistoricalComponent } from './client-management/historical/historical.component';
import {
  PathComponent,
  StopComponent,
  MileageComponent,
  PathPoiComponent,
} from './client-management/historical';
import {
  AlertSettingsComponent,
  SpeedSettingsComponent,
  SpeedCartoSettingsComponent,
  NonPoiSettingsComponent,
  IgnitionSettingsComponent,
  TowingSettingsComponent,
  ZoneSettingsComponent,
  BatterySettingsComponent,
  PoiSettingsComponent,
  DocumentSettingsComponent,
  OilChangeSettingsComponent,
  TireSettingsComponent,
  BatteriesSettingsComponent,
  BrakeSettingsComponent,
  ConsumptionSettingsComponent,
  AlertNotificationsComponent,
  AlertManagementComponent,
  ActivityHourSettingsComponent,
  RemisageSettingsComponent,
  ActivitySettingsComponent,
  ActivityDailySettingsComponent,
  ApproFuelSettingsComponent,
  ChomagSettingsComponent,
  ChargementSettingsComponent,
} from './client-management/alert-management';
import { ImportExportComponent } from './client-management/import-export';
import { AccountManagementComponent } from './client-management/account-management';
import { StatisticalComponent } from './client-management/statistical/statistical.component';
import { TechnicalDataComponent } from './client-management/statistical/technical-data/technical-data.component';
import { FuelDataComponent } from './client-management/statistical/fuel-data/fuel-data.component';
import { GlobalStopsReportComponent } from './client-management/new-import-export/_components/pdf-settings/global-stop-report/global-stop-report.component';
import { ListOfStopsReportComponent } from './client-management/new-import-export/_components/pdf-settings/list-of-stops-report/list-of-stops-report.component';
import { EtatBoitierActuelComponent } from './client-management/new-import-export/_components/pdf-settings/etat_boitier_actuel/etat_boitier_actuel.component';
import { FuelSettingsComponent } from './client-management/statistical/fuel-settings/fuel-settings.component';
import {
  GeoMarketingComponent,
  GeoMarketingTrackingComponent,
  VisitComponent,
  VisitTrackingComponent,
  PoiClientComponent,
} from './client-management/geo-marketing';
import { HelpComponent } from './client-management/help/help.component';
import { OverviewComponent } from './client-management/overview/overview.component';
import {
  DocumentCirculationComponent,
  CarburantComponent,
  VidangeComponent,
  LossesComponent,
  SinistresComponent,
  OffensesComponent,
  MaintenanceComponent,
  TireComponent,
  BatteryComponent,
  ReparationComponent,
  BrakeComponent,
  MultiTypeSettingsComponent,
  ProviderSettingsComponent,
  ParcSettingsComponent,
  driverChargeComponent,
  TollComponent,
  LavageComponent,
  ParckingComponent,
  CaRealizationComponent,
  LeasingComponent,
  DiversComponent,
  CLocationComponent,
  CarburantTemplateSettingComponent,
} from './client-management/parc-management';
import { ParcMangementComponent } from './client-management/parc-management/parc-management.component';
import { DashbordComponent } from './client-management/parc-management/dashbord';
import { ActivityBtntComponent } from './client-management/activity-overview-management/activity-btnt/activity-btnt.component';
import {
  ChargesPfbComponent,
  ChargesPfbTrackingComponent,
  PassageComponent,
  PassageFormComponent,
  ScheduleComponent,
  VehicleClassificationComponent,
} from './client-management/charges-pfb';
import {
  TransportTypeComponent,
  TransportTypeFormComponent,
  ValidationFormComponent,
  ValidatorSettingsComponent,
  ValidatorSettingsFormComponent,
} from './client-management/charges-pfb/parametrage';
import { ChargesPfbHistoryComponent } from './client-management/charges-pfb/charges-pfb-history/charges-pfb-history.component';
import { ProgramComponent } from './client-management/data-management/program/program.component';
import { ProgramFormComponent } from './client-management/data-management/program/program-form/program-form.component';
import {
  CimentDashboardComponent,
  CimentManagementComponent,
  CimentMissionComponent,
  CimentMissionScheduleComponent,
  CimentMissionTrackingComponent,
  CimentRotationComponent,
} from './client-management/ciment-management';
import { CimentMissionHistoryComponent } from './client-management/ciment-management/ciment-mission/ciment-mission-history/ciment-mission-history.component';
import { ActivityOverviewManagementComponent } from './client-management/activity-overview-management/activity-overview-management.component';
import {
  ActivityOverviewComponent,
  ActivityVehicleClassificationComponent,
  PositionByPoiComponent,
} from './client-management/activity-overview-management';
import { DrivingQualityComponent } from './client-management/statistical/driving-quality/driving-quality.component';
import {
  AlertManagementNewComponent,
  AlertNewComponent,
} from './client-management/alert-management-new';
import { RentalAgreementManagementComponent } from './client-management/rental-agreement-management/rental-agreement-management.component';
import {
  CimentSiteFormComponent,
  CimentSitesComponent,
} from './client-management/global-overview-management/ciment-sites';
import {
  CimentOverviewComponent,
  GlobalOverviewManagementComponent,
} from './client-management/global-overview-management';
import { FuelingPriceSettingComponent } from './client-management/parc-management/parc-settings/fueling-price-setting/fueling-price-setting.component';
import {
  CanalExportComponent,
  CanalPerformanceComponent,
} from './client-management/canal-performance';
import { SuiviCanalComponent } from './client-management/canal-performance/suivi-canal/suivi-canal.component';
import {
  AffectationChargeComponent,
  AffectationManagementComponent,
  HistorySuiviChargeComponent,
  SuiviChargeComponent,
} from './client-management/affectation-management';
import { TravelReportComponent } from './client-management/charges-pfb/travel-report/travel-report.component';
import {
  ActivityComponent,
  ExpensesComponent,
  TmmComponent,
} from './client-management/tmm';
import { MissionReturnTrackingComponent } from './client-management/charges-pfb/mission-return-tracking/mission-return-tracking.component';
import { DeadlinesComponent } from './client-management/parc-management/deadlines/deadlines.component';
import { MissionTrackingInfoComponent } from './client-management/charges-pfb/mission-tracking-info/mission-tracking-info.component';
import { PerformanceComponent } from './client-management/charges-pfb/performance/performance.component';
import { GroupsContainerComponent } from './client-management/realtime/components/groups-container/groups-container.component';
import { ControlOptionsComponent } from './client-management/realtime/components/control-options/control-options.component';
import { KmJrToolComponent } from './client-management/realtime/components/control-options/tool-bar/km-jr/tool-km-jour.component';
import { KmJrTrajetComponent } from './client-management/realtime/components/control-options/tool-bar/km-jr/km-jour-trajet/km-jour-trajet.component';
import { EnCoursComponent } from './client-management/realtime/components/control-options/tool-bar/en-cours/en-cours.component';
import { KmJourGuard } from './utils/security/km-jour.guard';

import { TonnageComponent } from './client-management/parc-management/divers/tonnage/tonnage.component';

import { ParametrageComponent } from './client-management/charges-pfb/parametrage/parametrage.component';
import { CanalPfbComponent } from './client-management/charges-pfb/parametrage/canal-pfb/canal-pfb.component';
import { CanalPfbFormComponent } from './client-management/charges-pfb/parametrage/canal-pfb/canal-pfb-form/canal-pfb-form.component';
import { ParametrageCanalComponent } from './client-management/canal-performance/parametrage-canal/parametrage-canal.component';
import {
  CanalTypeComponent,
  CanalTypeFormComponent,
} from './client-management/canal-performance/parametrage-canal/canal-type';
import { SupervisorComponent } from './client-management/canal-performance/parametrage-canal/supervisor/supervisor.component';
import { SupervisorFormComponent } from './client-management/canal-performance/parametrage-canal/supervisor/supervisor-form/supervisor-form.component';
import { IMileageDataComponent } from './client-management/statistical/mileage-data/mileage-data.component';

import { StopsToolComponent } from './client-management/realtime/components/control-options/tool-bar/stops/stops.component';
import { NewImportExportComponent } from './client-management/new-import-export';

const routes: Routes = [
  { path: '', redirectTo: '/signin', pathMatch: 'full' }, // Redirect to SignInComponent on load
  { path: 'signin', component: SigninComponent },

  {
    path: 'client',
    component: ClientManagementComponent,
    // //canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'realtime', pathMatch: 'full' },
      {
        path: 'alertManagementNew',
        component: AlertManagementNewComponent,
        children: [
          { path: '', redirectTo: 'alertNew', pathMatch: 'full' },
          { path: 'alertNew', component: AlertNewComponent },
        ],
      },
      {
        path: 'overview',
        component: OverviewComponent,
        canActivate: [accountGuards.OverviewGuard],
      },
      // add can activate location
      {
        path: 'rentalAgreementManagement',
        component: RentalAgreementManagementComponent,
        canActivate: [accountGuards.RentalAgreementGuard],
      },
      {
        path: 'activityManagement',
        component: ActivityOverviewManagementComponent,

        children: [
          { path: '', redirectTo: 'overview', pathMatch: 'full' },
          { path: 'overview', component: ActivityOverviewComponent },
          {
            path: 'vehiculeClassification',
            component: ActivityVehicleClassificationComponent,
          },
          { path: 'positionByPOI', component: PositionByPoiComponent },
          { path: 'activityBtnt', component: ActivityBtntComponent },
        ],
        canActivate: [accountGuards.ActivityOverview],
      },
      {
        path: 'geo-marketings',
        component: GeoMarketingComponent,

        children: [
          { path: '', redirectTo: 'geo-marketing', pathMatch: 'full' },
          { path: 'geo-marketing', component: GeoMarketingComponent },
        ],
        canActivate: [accountGuards.GeoMarketing],
      },
      {
        path: 'geo-marketing-tracking',
        component: GeoMarketingTrackingComponent,
        canActivate: [accountGuards.GeoMarketing],
      },
      {
        path: 'visit',
        component: VisitComponent,
        canActivate: [accountGuards.GeoMarketing],
      },
      {
        path: 'visit-tracking',
        component: VisitTrackingComponent,
        canActivate: [accountGuards.GeoMarketing],
      },
      {
        path: 'poi-client',
        component: PoiClientComponent,
        canActivate: [accountGuards.GeoMarketing],
      },
      {
        path: 'admin',
        component: AccountManagementComponent,
        canActivate: [accountGuards.AccountManagementGuard],
      },
      // { path: 'driverMission', component: DriverMissionManagementComponent, //canActivate: [accountGuards.AccountManagementGuard] },
      { path: 'help', component: HelpComponent },
      {
        path: 'realtime',
        component: RealtimeComponent,
        canActivate: [accountGuards.RealTimeGuard],
        children: [
          { path: '', component: GroupsContainerComponent },
          {
            path: ':id/bartool',
            component: ControlOptionsComponent,
            canActivate: [KmJourGuard],
            children: [
              {
                path: 'km-jour',
                component: KmJrToolComponent,
                children: [{ path: 'trajet', component: KmJrTrajetComponent }],
              },
              {
                path: 'en-cours',
                component: EnCoursComponent,
                children: [{ path: 'trajet', component: KmJrTrajetComponent }],
              },
              {
                path: 'en-stops',
                component: StopsToolComponent,
              },
            ],
          },
        ],
      },

      {
        path: 'historical',
        component: HistoricalComponent,
        children: [
          { path: '', redirectTo: 'path', pathMatch: 'full' },
          { path: 'path', component: PathComponent },
          { path: 'path-poi', component: PathPoiComponent },
          { path: 'stop', component: StopComponent },
          { path: 'mileage', component: MileageComponent },
        ],
        canActivate: [accountGuards.HistoricalGuard],
      },
      {
        path: 'statistical',
        component: StatisticalComponent,
        children: [
          { path: '', redirectTo: 'technicalData', pathMatch: 'full' },
          { path: 'technicalData', component: TechnicalDataComponent },
          { path: 'fuelData', component: FuelDataComponent },
          { path: 'mileageData', component: IMileageDataComponent },
          { path: 'fuelSettings', component: FuelSettingsComponent },
          { path: 'drivingQuality', component: DrivingQualityComponent },
        ],
        canActivate: [accountGuards.StatisticalGuard],
      },
      {
        path: 'dataManagement',
        component: DataManagementComponent,
        children: [
          { path: '', redirectTo: 'vehicule', pathMatch: 'full' },
          { path: 'vehicule', component: VehiculeComponent },
          { path: 'vehicule/form/:id/:mode', component: VehiculeFormComponent },
          { path: 'groupe', component: GroupComponent },
          { path: 'groupe/form/:id/:mode', component: GroupFormComponent },
          { path: 'driver', component: DriverComponent },
          { path: 'driver/form/:id/:mode', component: DriverFormComponent },
          { path: 'poi', component: PoiComponent },
          { path: 'program', component: ProgramComponent },
          { path: 'program/form/:id/:mode', component: ProgramFormComponent },
        ],
        canActivate: [accountGuards.DataManagementGuard],
      },
      {
        path: 'missions',
        component: DataManagementComponent,
        children: [
          { path: '', redirectTo: 'mission', pathMatch: 'full' },
          { path: 'mission', component: MissionComponent },
          { path: 'mission-tracking', component: MissionTrackingComponent },
        ],
        canActivate: [accountGuards.MissionGuard],
      },
      {
        path: 'parcManagement',
        component: ParcMangementComponent,
        children: [
          {
            path: '',
            children: [
              {
                path: 'documentCirculation',
                component: DocumentCirculationComponent,
              },

              {
                path: 'carburant',
                component: CarburantComponent,
              },
              {
                path: 'vidange',
                component: VidangeComponent,
              },
              {
                path: 'losses',
                component: LossesComponent,
                children: [
                  {
                    path: '',
                    children: [
                      { path: '', redirectTo: 'sinistres', pathMatch: 'full' },
                      { path: 'sinistres', component: SinistresComponent },
                      { path: 'offenses', component: OffensesComponent },
                    ],
                  },
                ],
              },
              {
                path: 'entretien',
                component: MaintenanceComponent,
                children: [
                  {
                    path: '',
                    children: [
                      { path: '', redirectTo: 'tire', pathMatch: 'full' },
                      { path: 'tire', component: TireComponent },
                      { path: 'battery', component: BatteryComponent },
                      { path: 'reparation', component: ReparationComponent },
                      { path: 'brake', component: BrakeComponent },
                    ],
                  },
                ],
              },
              {
                path: 'deadlines',
                component: DeadlinesComponent,
              },
              {
                path: 'dashbord',
                component: DashbordComponent,
              },
              {
                path: 'leasing',
                component: LeasingComponent,
              },
              {
                path: 'divers',
                component: DiversComponent,
                children: [
                  {
                    path: '',
                    children: [
                      { path: '', redirectTo: 'parcking', pathMatch: 'full' },
                      { path: 'parcking', component: ParckingComponent },
                      { path: 'lavage', component: LavageComponent },
                      { path: 'toll', component: TollComponent },
                      { path: 'tonnage', component: TonnageComponent },
                      {
                        path: 'ca-realization',
                        component: CaRealizationComponent,
                      },
                      { path: 'clocation', component: CLocationComponent },
                    ],
                  },
                ],
              },
              {
                path: 'driverCharge',
                component: driverChargeComponent,
              },
              {
                path: '',
                redirectTo: 'documentCirculation',
                pathMatch: 'full',
              },
            ],
          },
        ],
        canActivate: [accountGuards.ParcMangementGuard],
      },
      {
        path: 'parcSettings',
        component: ParcSettingsComponent,
        children: [
          {
            path: '',
            children: [
              { path: '', redirectTo: 'providers', pathMatch: 'full' },
              { path: 'providers', component: ProviderSettingsComponent },
            ],
          },
          {
            path: '',
            children: [
              { path: '', redirectTo: 'fueling-template', pathMatch: 'full' },
              {
                path: 'fueling-template',
                component: CarburantTemplateSettingComponent,
              },
            ],
          },
          {
            path: '',
            children: [
              { path: '', redirectTo: 'fueling-price', pathMatch: 'full' },
              {
                path: 'fueling-price',
                component: FuelingPriceSettingComponent,
              },
            ],
          },
          { path: 'types/:type', component: MultiTypeSettingsComponent },
        ],
      },
      { path: 'dataExport', component: ImportExportComponent },

      {
        path: 'newDataExport',
        component: NewImportExportComponent,
        children: [
          {
            path: '',
            redirectTo: 'global-stops-report',
            pathMatch: 'full',
          },
          {
            path: 'global-stops-report',
            component: GlobalStopsReportComponent,
          },

          {
            path: 'list-of-stops',
            component: ListOfStopsReportComponent,
          },

          {
            path: 'etat-actuel',
            component: EtatBoitierActuelComponent,
          },
          //  { path : 'chargesPfbTracking', component: ChargesPfbTrackingComponent}
        ],
      },
      {
        path: 'chargesPfb',
        component: ChargesPfbComponent,
        children: [
          { path: '', redirectTo: 'affectation', pathMatch: 'full' },
          { path: 'affectation', component: ChargesPfbComponent },
          //  { path : 'chargesPfbTracking', component: ChargesPfbTrackingComponent}
        ],
      },
      {
        path: 'cimentManagement',
        component: CimentManagementComponent,
        children: [
          { path: '', redirectTo: 'cimentDashboard', pathMatch: 'full' },
          { path: 'cimentDashboard', component: CimentDashboardComponent },
          { path: 'cimentSites', component: CimentSitesComponent },
          {
            path: 'cimentSites/form/:id/:mode',
            component: CimentSiteFormComponent,
          },
          { path: 'cimentRotation', component: CimentRotationComponent },
          {
            path: 'cimentMission',
            component: CimentMissionComponent,
            children: [
              { path: '', redirectTo: 'tracking', pathMatch: 'full' },
              { path: 'tracking', component: CimentMissionTrackingComponent },
              { path: 'history', component: CimentMissionHistoryComponent },
              { path: 'dashboard', component: CimentMissionScheduleComponent },
            ],
          },
        ],
        canActivate: [accountGuards.CimentManagement],
      },
      {
        path: 'globalOverviewManagement',
        component: GlobalOverviewManagementComponent,
        children: [
          { path: '', redirectTo: 'overview', pathMatch: 'full' },
          { path: 'overview', component: CimentOverviewComponent },
          { path: 'sites', component: CimentSitesComponent },
          { path: 'sites/form/:id/:mode', component: CimentSiteFormComponent },
        ],
        canActivate: [accountGuards.GlobalOverviewManagement],
      },
      {
        path: 'canalPerfomance',
        component: CanalPerformanceComponent,
        children: [
          { path: '', redirectTo: 'parametrage', pathMatch: 'full' },
          {
            path: 'parametrage',
            component: ParametrageCanalComponent,
            children: [
              { path: 'type', component: CanalTypeComponent },
              {
                path: 'type/form/:id/:mode',
                component: CanalTypeFormComponent,
              },
              { path: 'superviseur', component: SupervisorComponent },
            ],
          },
          { path: 'export', component: CanalExportComponent },
          { path: 'suivi', component: SuiviCanalComponent },
        ],
        canActivate: [accountGuards.CanalPerformanceManagement],
      },
      {
        path: 'affectationCharge',
        component: AffectationManagementComponent,
        children: [
          { path: '', redirectTo: 'affectation', pathMatch: 'full' },
          { path: 'affectation', component: AffectationChargeComponent },
          { path: 'suivi', component: SuiviChargeComponent },
          { path: 'history', component: HistorySuiviChargeComponent },
        ],
        canActivate: [accountGuards.AffectationManagement],
      },
      {
        path: 'tmm',
        component: TmmComponent,
        children: [
          { path: '', redirectTo: 'activity', pathMatch: 'full' },
          { path: 'activity', component: ActivityComponent },
          { path: 'overview', component: ExpensesComponent },
          { path: 'vehiculeClassification', component: ActivityVehicleClassificationComponent},
        ],
        canActivate: [accountGuards.TransportMonitoringMaintenance],
      },
      { path: 'chargesPfbHistory', component: ChargesPfbHistoryComponent },
      { path: 'chargesPfbTracking', component: ChargesPfbTrackingComponent },
      { path: 'chargesPfbSchedule', component: ScheduleComponent },
      { path: 'chargesPfbTravelRepport', component: TravelReportComponent },
      {
        path: 'chargesPfbMissionReturn',
        component: MissionReturnTrackingComponent,
      },
      {
        path: 'chargesPfbMissionTracking',
        component: MissionTrackingInfoComponent,
      },
      { path: 'performanceBTP', component: PerformanceComponent },
      {
        path: 'chargesPfbVehicleClassification',
        component: VehicleClassificationComponent,
      },
      {
        path: 'parametrage',
        component: ParametrageComponent,
        children: [
          { path: '', redirectTo: 'chargesPfbPassage', pathMatch: 'full' },
          {
            path: 'chargesPfbPassage/form/:id/:mode',
            component: PassageFormComponent,
          },
          { path: 'chargesPfbPassage', component: PassageComponent },
          {
            path: 'chargesPfbtransportType',
            component: TransportTypeComponent,
          },
          {
            path: 'chargesPfbtransportType/form/:id/:mode',
            component: TransportTypeFormComponent,
          },
          {
            path: 'chargesPfbvalidator',
            component: ValidatorSettingsComponent,
          },
          {
            path: 'chargesPfbValidator/form/:id/:mode',
            component: ValidatorSettingsFormComponent,
          },
          { path: 'chargesPfbvalidation', component: ValidationFormComponent },
          { path: 'canalsPfp', component: CanalPfbComponent },
          {
            path: 'canalsPfp/form/:id/:mode',
            component: CanalPfbFormComponent,
          },
        ],
        //canActivate: [accountGuards.chargesPfb],
      },

      {
        path: 'alertManagement',
        component: AlertManagementComponent,
        children: [
          { path: '', redirectTo: 'alertSettings', pathMatch: 'full' },
          {
            path: 'alertNotifications',
            component: AlertNotificationsComponent,
          },
          {
            path: 'alertSettings',
            component: AlertSettingsComponent,
            children: [
              { path: '', redirectTo: 'speedSetting', pathMatch: 'full' },
              { path: 'speedSetting', component: SpeedSettingsComponent },
              {
                path: 'speedCartoSetting',
                component: SpeedCartoSettingsComponent,
              },
              { path: 'poiSetting', component: PoiSettingsComponent },
              { path: 'noPoiSetting', component: NonPoiSettingsComponent },
              { path: 'ignitionSetting', component: IgnitionSettingsComponent },
              { path: 'towingSetting', component: TowingSettingsComponent },
              { path: 'zoneSetting', component: ZoneSettingsComponent },
              { path: 'batterySetting', component: BatterySettingsComponent },
              { path: 'documentSetting', component: DocumentSettingsComponent },
              {
                path: 'oilChangeSetting',
                component: OilChangeSettingsComponent,
              },
              { path: 'tireSetting', component: TireSettingsComponent },
              {
                path: 'batteriesSetting',
                component: BatteriesSettingsComponent,
              },
              { path: 'brakeSetting', component: BrakeSettingsComponent },
              {
                path: 'consumptionSetting',
                component: ConsumptionSettingsComponent,
              },
              {
                path: 'activityHourSetting',
                component: ActivityHourSettingsComponent,
              },
              { path: 'chomage', component: ChomagSettingsComponent },
              { path: 'chargement', component: ChargementSettingsComponent },
              { path: 'remisageSetting', component: RemisageSettingsComponent },
              { path: 'activitySetting', component: ActivitySettingsComponent },
              {
                path: 'activityDailySetting',
                component: ActivityDailySettingsComponent,
              },
              {
                path: 'approFuelSetting',
                component: ApproFuelSettingsComponent,
              },
            ],
          },
        ],
      },
    ],
  },
  { path: '**', component: SigninComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
