<div class="excel-container">
    <!-- Onglets dynamiques -->
    <div class="tabs">
        <button *ngFor="let sheet of sheetNames; let i = index" (click)="currentSheet = i"
            [class.active]="currentSheet === i">
            {{ sheet }}
        </button>
    </div>

    <!-- Contenu des feuilles -->
    <div class="scrollable-container">
        <div *ngIf="getActiveSheetData().length" class="table-container">
            <table>
                <thead>
                    <tr *ngIf="getActiveSheetData()[3]?.length">
                        <th>
                            <span class="fw-semibold">{{ currentUser }}</span>
                        </th>
                        <th [attr.colspan]="getActiveSheetData()[0].length - 3"
                            style="text-align: center; font-size: 15px;">
                            {{ getActiveSheetData()[0][2] }}
                        </th>
                        <th colspan="2" style="text-align: right;">
                            {{ getActiveSheetData()[0][getActiveSheetData()[0].length - 1] }}
                        </th>
                    </tr>
                    <tr class="header-row">
                        <th *ngFor="let header of getActiveSheetData()[3]">
                            {{ header }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let row of getActiveSheetData().slice(4)">
                        <tr>
                            <td *ngFor="let cell of row" class="truncate-cell">
                                {{ cell }}
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</div>