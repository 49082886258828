import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { StpsService } from 'src/app/client-management/realtime/services/stops.service';
import { VoyageHelperService } from 'src/app/client-management/realtime/services/voyage-helper.service';
import { StopAccident } from 'src/app/shared/model/sinistre.model';


@Component({
  selector: 'app-stops-childs',
  templateUrl: './stops-childs.component.html',
  styleUrls: ['./stops-childs.component.css'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)', opacity: 0 }), // Start off-screen to the right
        animate('0.5s ease-out', style({ transform: 'translateX(0)', opacity: 1 })) // Slide in from the right
      ])
    ]),
    trigger('slideOutIn', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)', opacity: 0 }), // Start off-screen to the right
        animate('0.5s ease-out', style({ transform: 'translateX(0)', opacity: 1 })) // Slide in from the right
      ])
    ])
  ]
})
export class StopsChildsComponent implements OnInit, OnDestroy{
  @Input() childDetail : StopAccident;
  @Input() itemIndex: number = 0; 
  @Output() changeTheSelectedStop: EventEmitter<number> = new EventEmitter<number>(); 

  clickedMarker: L.Marker | null = null;
  clickedMarker$: Subscription;
  filredAdress: string = '';

  editMode: boolean = false;
  isAnimated = false;


  constructor(
    public voyageHelperService: VoyageHelperService , 
    private stpsService: StpsService
  ){}

  ngOnInit(): void {
    let adress: string[] = this.childDetail.adress.address.state_district.split(/de|d'/) || [];
    this.filredAdress = adress && adress.length > 0 ? adress[adress.length - 1] : '';
    this.clickedMarker$ = this.stpsService.getClickedMarker().subscribe(result => {
      this.clickedMarker = result;
    });
  }

  changeTheSelected(){
    this.changeTheSelectedStop.emit(this.itemIndex);
  }

  isMarkerMatchingStop(): boolean {
    if (!this.clickedMarker || !this.childDetail) {
      return false;
    }
    const markerLatLng = this.clickedMarker.getLatLng();
    return markerLatLng.lat === this.childDetail.stopLatitude && markerLatLng.lng === this.childDetail.stopLongitude;
  }

  editingControl(event: any, condition: boolean){
    if(!this.isMarkerMatchingStop()){return};
    this.isAnimated = true;
    event.stopPropagation();
    this.editMode = condition;
    setTimeout(() => {
      this.isAnimated = false;
    }, 1000);
  }
  closeEditing(){
    this.isAnimated = true;
    this.editMode = false;
    setTimeout(() => {
      this.isAnimated = false;
    }, 1000);
  }

  isHighlighted(){
    return this.isMarkerMatchingStop() && !this.editMode;
  }
  isInEditMode(){
    return this.isMarkerMatchingStop() && this.editMode;
  }

  ngOnDestroy(): void {
      if(this.clickedMarker$){this.clickedMarker$.unsubscribe()};
  }
}
