import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewImportExportComponent } from './new-import-export.component';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatExpansionModule } from '@angular/material/expansion';

//import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatDateFormats,
  MatNativeDateModule,
  MatOptionModule,
} from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PdfTableComponent } from './_components/pdf-table/pdf-table.component';
import { PdfSettingsComponent } from './_components/pdf-settings/pdf-settings.component';
import { PdfDisplaySectionComponent } from './_components/pdf-display-section/pdf-display-section.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NewImportExportRoutingModule } from './new-import-export-routing.module';
import { DefaultContentComponent } from './_components/pdf-display-section/default-content/default-content.component';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { PdfExcelCostumComponent } from './_components/pdf-display-section/pdf-excel-costum/pdf-excel-costum.component';
import { EtatBoitierExcelComponent } from './_components/pdf-display-section/etat-boitier-excel/etat-boitier-excel.component';
import { VehiculeActivitiesExcelComponent } from './_components/pdf-display-section/vehicule-activities-excel/vehicule-activities-excel.component';
import { HeaderOptionsComponent } from './_components/pdf-display-section/header-options/header-options.component';
import { GlobalStopsReportComponent } from './_components/pdf-settings/global-stop-report/global-stop-report.component';
import { ListOfStopsReportComponent } from './_components/pdf-settings/list-of-stops-report/list-of-stops-report.component';
import { EtatBoitierActuelComponent } from './_components/pdf-settings/etat_boitier_actuel/etat_boitier_actuel.component';
import { RouterModule } from '@angular/router'; // Import RouterModule if routing is used
import { ToastrModule } from 'ngx-toastr'; // Import ToastrModule for notifications
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'; // Import Datepicker if you're using it
import { SharedPipesModule } from 'src/app/pipes/shared-pipes.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { TruncateModule } from 'src/app/pipes/truncate.pipe.module';
import { NgxLoadingModule } from 'ngx-loading';
import { ModalModule } from 'ngx-bootstrap/modal';

export const CUSTOM_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    NewImportExportComponent,
    PdfSettingsComponent,
    DefaultContentComponent,
    PdfDisplaySectionComponent,
    HeaderOptionsComponent,
    PdfTableComponent,
    PdfExcelCostumComponent,
    EtatBoitierExcelComponent,
    VehiculeActivitiesExcelComponent,
    GlobalStopsReportComponent,
    ListOfStopsReportComponent,
    EtatBoitierActuelComponent,
  ],

  imports: [
    CommonModule,
    NewImportExportRoutingModule,
    RouterModule,
    ToastrModule.forRoot(),
    BsDatepickerModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    ModalModule.forRoot(),
    FormsModule,
    NgSelectModule,
    FormsModule,
    CommonModule,
    NgxDaterangepickerMd,
    MatAutocompleteModule,
    MatCardModule,
    MatIconModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatExpansionModule,
    FormsModule,
    ReactiveFormsModule,
  ],

  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ],
  exports: [NewImportExportComponent],
})
export class NewImportExportModule {}
