import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ChargePfbInfoDto } from './charges-pfb';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ModalDirective, } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ChargesPfbService } from './charges-pfb.service';

declare var $: any;

@Component({
    selector: 'app-charges-pfb',
    templateUrl: './charges-pfb.component.html',
    styleUrls: ['./charges-pfb.component.scss']
})
export class ChargesPfbComponent implements OnInit {
    loading: boolean = false;
    /**
     * list charges
     */
  chargePfb: ChargePfbInfoDto[] = [];
  selectedChargePfb: ChargePfbInfoDto | null = null;
  selectedCharge: ChargePfbInfoDto | null = null;

  
  @ViewChild('chargePfbDeleteModal', { static: false }) chargePfbDeleteModal: ModalDirective;
  @ViewChild('chargePfbFormModal', { static: false }) chargePfbFormModal: ModalDirective;


    /**
     * mode = 'ADD'|'UPDATE'
     */
    mode: string;
    now: Date = new Date();

  public months: { id: number, name: string }[] = []; // Mettez à jour pour être un tableau d'objets
  public years: { id: number, name: string }[] = []; // Mettez à jour pour être un tableau d'objets

  selectedMonth: { id: number, name: string } | null = null; // Mettez à jour pour être un objet ou null
  selectedYear: { id: number, name: string } | null = null; // Mettez à jour pour être un objet ou null

    constructor(private toastr: ToastrService, public chargePfbService: ChargesPfbService, private localeService: BsLocaleService) {
        this.localeService.use('fr');
    }

    ngOnInit() {
        this.months = this.chargePfbService.months.filter(m => m.id <= this.now.getMonth() + 1);
        this.selectedMonth = this.months[this.months.length - 1];
        this.years = this.chargePfbService.getYears();
        this.selectedYear = this.years[0];
        this.loadChargePfb();
    }

    /**
     * check if manual or automatic mode and save the state
     * on local storage
     */
    ManuallyOrAutomatically() {
        if (this.chargePfbService.isManually) {
            localStorage.setItem('isManually', "true");
        } else {
            localStorage.setItem('isManually', "false");
        }
    }

    /**
     * load all charge pfb by year and month
     */
    loadChargePfb() {
        this.loading = true;
        this.chargePfbService.getHistoricalChargesByMonth(this.selectedMonth.id, this.selectedYear.id)
            .subscribe(chargePfb => {
                this.loading = false;
                this.chargePfb = chargePfb;
                this.selectedChargePfb = this.chargePfb[0];
            }, err => {
                this.loading = false;
                this.toastr.error("Erreur lors de chargements", "Charge")
            })
    }

    /**
     *
     * @param isByYear
     * on month or year event change
     */
    onMonthOrYearChange(isByYear: boolean) {

        this.selectedChargePfb = null;
        this.chargePfb = [];
        if (this.selectedYear.id == this.now.getFullYear()) {
            this.months = this.chargePfbService.months.filter(m => m.id <= this.now.getMonth() + 1);
            if (isByYear)
                this.selectedMonth = this.months[this.months.length - 1]

        } else {
            this.months = this.chargePfbService.months;
        }
        this.loadChargePfb();
    }

    /**
     *
     * @param mode
     * open charge pfb modal
     */
    openChargePfbForm(mode: string) {
        this.mode = mode;
        if (this.mode === 'UPDATE')
            this.selectedCharge = this.selectedChargePfb;
        else
            this.selectedCharge = null;
        this.chargePfbFormModal.show();
    }

    /**
     *
     * @param chargePfb
     * on save event load charge pfb
     */
    onChargePfbSaved(chargePfb: ChargePfbInfoDto) {
        this.chargePfbFormModal.hide();
        this.loadChargePfb();

    }

    /**
     *
     * delete selected charge pfb
     */
    onDelete() {
        if (!this.selectedChargePfb) return;
        this.loading = true;
        this.chargePfbService.delete(this.selectedChargePfb.name, this.selectedChargePfb.transportType.idTransportType)
            .subscribe(response => {
                this.loading = false;
                this.chargePfbDeleteModal.hide();

                if (response) {
                    this.toastr.success("Supprimé", "Charge PFB");
                    this.loadChargePfb();
                } else {
                    this.toastr.error("Erreur lors de suppression", "Charge PFB");
                }
            }, err => {
                this.loading = false;
                this.toastr.error("Erreur lors de suppression", "Charge PFB");
            })
    }

    onConfirmDelete() {
        this.chargePfbDeleteModal.show();

    }


    chargePfbFormModalhide(){
        this.chargePfbFormModal.hide();
    }



    chargePfbDeleteModalhide(){
        this.chargePfbDeleteModal.hide();

    }
}
