<aside class="main-sidebar scrollable-menu" style="font-family: 'Inconsolata', monospace;"
  (mouseenter)="changeStyle($event)" (mouseleave)="changeStyle($event)">
  <section class="sidebar" style="height: auto">
    <div class="user-panel">
      <img alt="{{ owner }}" class="img1 center-block" src="{{ avatar }}" />
      <img *ngIf="currentUser.username.indexOf('Menara') !== -1" alt="{{ owner }}" class="img2 center-block"
        src="{{ menaraIcon }}" />
      <img *ngIf="currentUser.username.indexOf('Menara') === -1" alt="{{ owner }}" class="img2 center-block"
        src="../../favicon.ico" />
      <div class="pull-left info">
        <a><i class="fa fa-circle text-success"></i> Online</a>
      </div>
    </div>
    <br />
    <ul class="sidebar-menu">
      <li class="treeview hov" *ngIf="
          signinService.isHasTransportMonitoringMaintenance() ||
          signinService.isHasRole('ROLE_TMM')
        ">
        <a>
          <i class="fa fa-line-chart"></i>
          <span>T.M.M</span>
          <span class="pull-right-container">
            <i class="fa fa-angle-left pull-right"></i>
          </span>
        </a>
        <ul class="treeview-menu">
          <li>
            <a routerLink="/client/tmm/activity">
              <i class="fa fa-area-chart"></i> Vue sur l'activité
            </a>
          </li>
          <li>
            <a routerLink="/client/tmm/overview">
              <i class="fa fa-balance-scale"></i> Vue sur les dépenses
            </a>
          </li>
          <li>
            <a routerLink="/client/tmm/vehiculeClassification">
              <i class="fa fa-truck"></i> Classification Véhicule
            </a>
          </li>
        </ul>
      </li>

      <li class="hov" routerLinkActive="active" *ngIf="
          signinService.isHasOverallViewManagement() ||
          signinService.isHasRole('ROLE_OVERALL_VIEW')
        ">
        <a routerLink="/client/overview">
          <i class="fa fa-dashboard"></i>
          <span>Vue sur l'ensemble</span>
        </a>
      </li>

      <li class="treeview hov" *ngIf="
          signinService.isHasGlobalOverviewManagement() ||
          signinService.isHasRole('ROLE_GLOBAL_OVERVIEW')
        ">
        <a>
          <i class="fa fa-th"></i>
          <span>Vue Globale</span>
          <span class="pull-right-container">
            <i class="fa fa-angle-left pull-right"></i>
          </span>
        </a>
        <ul class="treeview-menu">
          <li>
            <a routerLink="/client/globalOverviewManagement/sites">
              <i class="fa fa-circle-o"></i> Site
            </a>
          </li>
          <li>
            <a routerLink="/client/globalOverviewManagement/overview">
              <i class="fa fa-circle-o"></i> États des véhicules
            </a>
          </li>
        </ul>
      </li>

      <li class="treeview hov" *ngIf="
          signinService.isHasActivity() || signinService.isHasRole('ROLE_ACOV')
        ">
        <a>
          <i class="fa fa-tasks"></i>
          <span>Vue sur l'activité</span>
          <span class="pull-right-container">
            <i class="fa fa-angle-left pull-right"></i>
          </span>
        </a>
        <ul class="treeview-menu">
          <li>
            <a routerLink="/client/activityManagement/overview">
              <!-- <a routerLink="/client/tmm/activity"> -->
              <i class="fa fa-bar-chart"></i> Activité
            </a>
          </li>
          <li>
            <a routerLink="/client/activityManagement/vehiculeClassification">
              <i class="fa fa-bar-chart"></i> Classification Véhicule
            </a>
          </li>
          <li>
            <a routerLink="/client/activityManagement/activityBtnt">
              <i class="fa fa-bar-chart"></i> Position par P.O.I</a>
          </li>
          <!-- <li *ngIf="currentUser.username.indexOf('Menara') !== -1">
            <a routerLink="/client/activityManagement/tempsreelpoi">
              <i class="fa fa-bar-chart"></i> Arrêt P.O.I</a>
          </li> -->
        </ul>
      </li>

      <li routerLinkActive="active" *ngIf="
          signinService.isHasRole('ROLE_RT') || signinService.isRootAdmin()
        ">
        <a routerLink="/client/realtime">
          <i class="fa fa-globe"></i>
          <span>Temps réel</span>
        </a>
      </li>

      <li class="treeview" *ngIf="
          signinService.isHasRole('ROLE_PATH') || signinService.isRootAdmin()
        ">
        <a routerLink="/client/historical/path">
          <i class="fa fa-pie-chart"></i>
          <span>Historique</span>
          <span class="pull-right-container">
            <i class="fa fa-angle-left pull-right"></i>
          </span>
        </a>
        <ul class="treeview-menu">
          <li>
            <a routerLink="/client/historical/path">
              <i class="fa fa-road"></i> Liste des trajets</a>
          </li>
          <li>
            <a routerLink="/client/historical/path-poi">
              <i class="fa fa-road"></i> Liste des trajets P.O.I</a>
          </li>
          <li>
            <a routerLink="/client/historical/stop">
              <i class="fa fa-map-marker"></i> Liste des arrêts</a>
          </li>
          <li>
            <a routerLink="/client/historical/mileage">
              <i class="fa fa-tachometer"></i> Kilométrage</a>
          </li>
        </ul>
      </li>

      <li class="treeview hov" *ngIf="
          signinService.isHasGeoMarketing() ||
          signinService.isHasRole('ROLE_GEO')
        ">
        <a>
          <i class="fa fa-map"></i>
          <span>Géomarketing</span>
          <span class="pull-right-container">
            <i class="fa fa-angle-left pull-right"></i>
          </span>
        </a>
        <ul class="treeview-menu">
          <li>
            <a routerLink="/client/geo-marketings/geo-marketing">
              <i class="fa fa-circle-o"></i> Secteur
            </a>
          </li>
          <li>
            <a routerLink="/client/geo-marketing-tracking">
              <i class="fa fa-circle-o"></i> Suivi secteur
            </a>
          </li>
          <li>
            <a routerLink="/client/visit">
              <i class="fa fa-circle-o"></i> Tournées
            </a>
          </li>
          <li>
            <a routerLink="/client/visit-tracking">
              <i class="fa fa-circle-o"></i> Suivi Tournées
            </a>
          </li>
          <li>
            <a routerLink="/client/poi-client">
              <i class="fa fa-circle-o"></i> POI clients
            </a>
          </li>
        </ul>
      </li>

      <li class="treeview hov" *ngIf="
          signinService.isHasMissionManagement() ||
          signinService.isHasRole('ROLE_MISSION')
        ">
        <a>
          <i class="fa fa-bullseye"></i>
          <span>Gestion des missions</span>
          <span class="pull-right-container">
            <i class="fa fa-angle-left pull-right"></i>
          </span>
        </a>
        <ul class="treeview-menu">
          <li>
            <a routerLink="/client/missions/mission">
              <i class="fa fa-circle-o"></i> Missions
            </a>
          </li>
          <li>
            <a routerLink="/client/missions/mission-tracking">
              <i class="fa fa-circle-o"></i> Tracking missions
            </a>
          </li>
        </ul>
      </li>

      <li class="treeview hov" *ngIf="
          signinService.isHasCanalPerformanceManagement() ||
          signinService.isHasRole('ROLE_PERFORMANCE_CANAL')
        ">
        <a>
          <i class="fa fa-line-chart"></i>
          <span>Performance Canal</span>
          <span class="pull-right-container">
            <i class="fa fa-angle-left pull-right"></i>
          </span>
        </a>
        <ul class="treeview-menu">
          <li>
            <a routerLink="/client/canalPerfomance/parametrage/type">
              <i class="fa fa-circle-o"></i> Paramétrage
            </a>
          </li>
          <li>
            <a routerLink="/client/canalPerfomance/suivi">
              <i class="fa fa-circle-o"></i> Suivi Canal
            </a>
          </li>
          <li>
            <a routerLink="/client/canalPerfomance/export">
              <i class="fa fa-circle-o"></i> Rapport Canal
            </a>
          </li>
        </ul>
      </li>

      <li class="treeview hov" *ngIf="
          signinService.isHasAffectationManagement() ||
          signinService.isHasRole('ROLE_PERFORMANCE_CHANTIER')
        ">
        <a>
          <i class="fa fa-tachometer"></i>
          <span>Performance Chantier</span>
          <span class="pull-right-container">
            <i class="fa fa-angle-left pull-right"></i>
          </span>
        </a>

        <ul class="treeview-menu">
          <li>
            <a routerLink="/client/affectationCharge">
              <i class="fa fa-circle-o"></i> Affectation
            </a>
          </li>
          <li>
            <a routerLink="/client/affectationCharge/suivi">
              <i class="fa fa-circle-o"></i> Suivi
            </a>
          </li>
          <li>
            <a routerLink="/client/affectationCharge/history">
              <i class="fa fa-circle-o"></i> Historique
            </a>
          </li>
        </ul>
      </li>

      <li class="treeview hov" *ngIf="showCHPItem()">
        <a>
          <i class="fa fa-tachometer"></i>
          <span>Gestion Transport BTP</span>
          <span class="pull-right-container">
            <i class="fa fa-angle-left pull-right"></i>
          </span>
        </a>

        <ul class="treeview-menu">
          <li *ngIf="
              signinService.isHasRole('ROLE_CHARGE_PFB') ||
              signinService.isHasRole('ROLE_CHARGE_PFB_ORDONNENCEMENT') ||
              signinService.isRootAdmin()
            ">
            <a routerLink="/client/performanceBTP">
              <i class="fa fa-circle-o"></i> TDB
            </a>
          </li>
          <li *ngIf="
              signinService.isHasRole('ROLE_CHARGE_PFB') ||
              signinService.isHasRole('ROLE_CHARGE_PFB_ORDONNENCEMENT') ||
              signinService.isRootAdmin()
            ">
            <a routerLink="/client/chargesPfbSchedule">
              <i class="fa fa-circle-o"></i> Ordonnancement
            </a>
          </li>

          <li *ngIf="
              signinService.isHasRole('ROLE_CHARGE_PFB') ||
              signinService.isHasRole('ROLE_CHARGE_PFB_ORDONNENCEMENT') ||
              signinService.isRootAdmin()
            ">
            <a routerLink="/client/chargesPfbMissionTracking">
              <i class="fa fa-circle-o"></i> Tracking Mission</a>
          </li>

          <li *ngIf="
              signinService.isHasRole('ROLE_CHARGE_PFB') ||
              signinService.isHasRole('ROLE_CHARGE_PFB_ORDONNENCEMENT') ||
              signinService.isRootAdmin()
            ">
            <a routerLink="/client/chargesPfbTravelRepport">
              <i class="fa fa-circle-o"></i> Suivi Voyages
            </a>
          </li>
          <li *ngIf="
              signinService.isHasRole('ROLE_CHARGE_PFB') ||
              signinService.isHasRole('ROLE_CHARGE_PFB_PASSAGE') ||
              signinService.isRootAdmin()
            ">
            <a routerLink="/client/parametrage">
              <i class="fa fa-circle-o"></i> Paramétrage
            </a>
          </li>

          <!--          <li *ngIf="-->
          <!--              signinService.isHasRole('ROLE_CHARGE_PFB') ||-->
          <!--              signinService.isHasRole('ROLE_CHARGE_PFB_PASSAGE') ||-->
          <!--              signinService.isRootAdmin()-->
          <!--            ">-->
          <!--            <a routerLink="/client/chargesPfbPassage">-->
          <!--              <i class="fa fa-circle-o"></i> Passage-->
          <!--            </a>-->
          <!--          </li>-->
          <!--          <li *ngIf="-->
          <!--              signinService.isHasRole('ROLE_CHARGE_PFB') ||-->
          <!--              signinService.isHasRole('ROLE_CHARGE_PFB_TRANSPORT_TYPE') ||-->
          <!--              signinService.isRootAdmin()-->
          <!--            ">-->
          <!--            <a routerLink="/client/chargesPfbtransportType">-->
          <!--              <i class="fa fa-circle-o"></i> Type de Transport-->
          <!--            </a>-->
          <!--          </li>-->
          <!--          <li *ngIf="-->
          <!--              signinService.isHasRole('ROLE_CHARGE_PFB') ||-->
          <!--              signinService.isHasRole('ROLE_CHARGE_PFB_VALIDATOR') ||-->
          <!--              signinService.isRootAdmin()-->
          <!--            ">-->
          <!--            <a routerLink="/client/chargesPfbvalidator">-->
          <!--              <i class="fa fa-circle-o"></i> Validateurs-->
          <!--            </a>-->
          <!--          </li>-->

          <!--          <li *ngIf="-->
          <!--              signinService.isHasRole('ROLE_CHARGE_PFB') ||-->
          <!--              signinService.isHasRole('ROLE_CHARGE_PFB_VALIDATOR') ||-->
          <!--              signinService.isRootAdmin()-->
          <!--            ">-->
          <!--            <a routerLink="/client/chargesPfbvalidation">-->
          <!--              <i class="fa fa-circle-o"></i> Validation-->
          <!--            </a>-->
          <!--          </li>-->

          <li *ngIf="
              signinService.isHasRole('ROLE_CHARGE_PFB') ||
              signinService.isHasRole('ROLE_CHARGE_PFB_AFFECTATION') ||
              signinService.isRootAdmin()
            ">
            <a routerLink="/client/chargesPfb/affectation">
              <i class="fa fa-circle-o"></i> Affectation Charge PFB/BPE
            </a>
          </li>
          <li *ngIf="
              signinService.isHasRole('ROLE_CHARGE_PFB') ||
              signinService.isHasRole('ROLE_CHARGE_PFB_TRACKING') ||
              signinService.isRootAdmin()
            ">
            <a routerLink="/client/chargesPfbTracking">
              <i class="fa fa-circle-o"></i> Suivi Activité/Charge PFB/BPE
            </a>
          </li>
          <li *ngIf="
              signinService.isHasRole('ROLE_CHARGE_PFB') ||
              signinService.isHasRole('ROLE_CHARGE_PFB_TRACKING_HISTORY') ||
              signinService.isRootAdmin()
            ">
            <a routerLink="/client/chargesPfbHistory">
              <i class="fa fa-circle-o"></i> Historique Charge PFB/BPE
            </a>
          </li>

          <li *ngIf="
              signinService.isHasRole('ROLE_CHARGE_PFB') ||
              signinService.isRootAdmin()
            ">
            <!-- <a routerLink="/client/chargesPfbVehicleClassification">
              <i class="fa fa-circle-o"></i> Classification Véhicule/Pôle
            </a> -->
            <a routerLink="/client/tmm/vehiculeClassification">
              <i class="fa fa-circle-o"></i> Classification Véhicule/Pôle
            </a>
          </li>
        </ul>
      </li>

      <li class="treeview hov" *ngIf="
          signinService.isHasCimentManagement() ||
          signinService.isHasRole('ROLE_CIMENT')
        ">
        <a>
          <i class="fa fa-truck"></i>
          <span>TDB CTM</span>
          <span class="pull-right-container">
            <i class="fa fa-angle-left pull-right"></i>
          </span>
        </a>
        <ul class="treeview-menu">
          <li>
            <a routerLink="/client/cimentManagement/cimentDashboard">
              <i class="fa fa-circle-o"></i> Tableau de bord arrêt
            </a>
          </li>
          <li>
            <a routerLink="/client/cimentManagement/cimentRotation">
              <i class="fa fa-circle-o"></i> Rotation
            </a>
          </li>
          <li>
            <a routerLink="/client/cimentManagement/cimentMission/tracking">
              <i class="fa fa-circle-o"></i> Tracking missions
            </a>
          </li>
          <li>
            <a routerLink="/client/cimentManagement/cimentMission/history">
              <i class="fa fa-circle-o"></i> Historique
            </a>
          </li>
          <li>
            <a routerLink="/client/cimentManagement/cimentMission/dashboard">
              <i class="fa fa-circle-o"></i> Tableau de bord mission
            </a>
          </li>
        </ul>
      </li>

      <li class="hov" routerLinkActive="active" *ngIf="
          signinService.isHasRentalAgreementOption() ||
          signinService.isHasRole('ROLE_RAM')
        ">
        <a routerLink="/client/rentalAgreementManagement">
          <i class="fa fa fa-edit"></i>
          <span>Contrat de location</span>
        </a>
      </li>

      <li class="treeview" *ngIf="
          signinService.isHasRole('ROLE_DM') || signinService.isRootAdmin()
        ">
        <a routerLink="/client/dataManagement">
          <i class="fa fa-th-list"></i>
          <span>Gestion des données</span>
          <span class="pull-right-container">
            <i class="fa fa-angle-left pull-right"></i>
          </span>
        </a>
        <ul class="treeview-menu">
          <li>
            <a routerLink="/client/dataManagement">
              <i class="fa fa-circle-o"></i> Véhicules</a>
          </li>
          <li>
            <a routerLink="/client/dataManagement/groupe">
              <i class="fa fa-circle-o"></i> Groupes
            </a>
          </li>
          <li>
            <a routerLink="/client/dataManagement/driver">
              <i class="fa fa-circle-o"></i> Conducteurs
            </a>
          </li>
          <li>
            <a routerLink="/client/dataManagement/program">
              <i class="fa fa-circle-o"></i>Planification
            </a>
          </li>
          <li>
            <a routerLink="/client/dataManagement/poi">
              <i class="fa fa-circle-o"></i> Points d'intérêt
            </a>
          </li>
        </ul>
      </li>

      <li class="treeview hov" *ngIf="showGPItem()">
        <a>
          <i class="fa fa-car"></i>
          <span>Gestion de parc</span>
          <span class="pull-right-container">
            <i class="fa fa-angle-left pull-right"></i>
          </span>
        </a>
        <ul class="treeview-menu">
          <li *ngIf="
              signinService.isHasRole('ROLE_PM') ||
              signinService.isHasRole('ROLE_PM_CD') ||
              signinService.isRootAdmin()
            ">
            <a routerLink="/client/parcManagement/documentCirculation">
              <i class="fa fa-inbox"></i>
              Documents circulation
            </a>
          </li>

          <li *ngIf="
              signinService.isHasRole('ROLE_PM') ||
              signinService.isHasRole('ROLE_PM_FUEL') ||
              signinService.isRootAdmin()
            ">
            <a routerLink="/client/parcManagement/carburant">
              <i class="fa fa-battery-full"></i> Carburant
            </a>
          </li>
          <li *ngIf="
              signinService.isHasRole('ROLE_PM') ||
              signinService.isHasRole('ROLE_PM_VIDANGE') ||
              signinService.isRootAdmin()
            ">
            <a routerLink="/client/parcManagement/vidange">
              <i class="fa fa-filter"></i> Vidange
            </a>
          </li>
          <li *ngIf="
              signinService.isHasRole('ROLE_PM') ||
              signinService.isHasRole('ROLE_PM_MAINTENANCE') ||
              signinService.isRootAdmin()
            ">
            <a routerLink="/client/parcManagement/entretien">
              <i class="fa fa-wrench"></i> Entretien
            </a>
          </li>
          <li *ngIf="
              signinService.isHasRole('ROLE_PM') ||
              signinService.isHasRole('ROLE_PM_LOSSES') ||
              signinService.isRootAdmin()
            ">
            <a routerLink="/client/parcManagement/losses">
              <i class="fa fa-car"></i> Dégâts
            </a>
          </li>
          <li *ngIf="
              signinService.isHasRole('ROLE_PM') ||
              signinService.isHasRole('ROLE_PM_LEASING') ||
              signinService.isRootAdmin()
            ">
            <a routerLink="/client/parcManagement/leasing">
              <i class="fa fa-car"></i> Locations
            </a>
          </li>
          <li *ngIf="
              signinService.isHasRole('ROLE_PM') ||
              signinService.isHasRole('ROLE_PM_VARIOUS') ||
              signinService.isRootAdmin()
            ">
            <a routerLink="/client/parcManagement/divers">
              <i class="fa fa-car"></i> Divers
            </a>
          </li>
          <li *ngIf="
              signinService.isHasRole('ROLE_PM') ||
              signinService.isHasRole('ROLE_PM_DC') ||
              signinService.isRootAdmin()
            ">
            <a routerLink="/client/parcManagement/driverCharge">
              <i class="fa fa-user"></i> Charge conducteur
            </a>
          </li>
          <li *ngIf="
              signinService.isHasRole('ROLE_PM') ||
              signinService.isHasRole('ROLE_PM_DASHBOARD') ||
              signinService.isRootAdmin()
            ">
            <a routerLink="/client/parcManagement/dashbord">
              <i class="fa fa-line-chart"></i> TDB
            </a>
          </li>
          <li *ngIf="
              signinService.isHasRole('ROLE_PM_DASHBOARD') ||
              signinService.isRootAdmin()
            ">
            <a routerLink="/client/parcManagement/deadlines">
              <i class="fa fa-circle-o"></i> Echeance
            </a>
          </li>

          <li *ngIf="
              signinService.isHasRole('ROLE_PM') ||
              signinService.isHasRole('ROLE_PM_DASHBOARD') ||
              signinService.isRootAdmin()
            ">
            <a routerLink="/client/parcSettings">
              <i class="fa fa-gear"></i> Paramétrage
            </a>
          </li>
        </ul>
      </li>

      <li class="treeview" *ngIf="
          signinService.isHasCarburantOption() ||
          signinService.isHasRole('ROLE_SM')
        ">
        <a routerLink="/client/statistical/technicalData">
          <i class="fa fa-bar-chart"></i>
          <span>Rapport Graphique</span>
          <span class="pull-right-container">
            <i class="fa fa-angle-left pull-right"></i>
          </span>
        </a>
        <ul class="treeview-menu">
          <li>
            <a routerLink="/client/statistical/technicalData">
              <i class="fa fa-circle-o"></i> Données Techniques</a>
          </li>
          <li>
            <a routerLink="/client/statistical/fuelData">
              <i class="fa fa-circle-o"></i> Carburant</a>
          </li>
          <li>
            <a routerLink="/client/statistical/mileageData">
              <i class="fa fa-circle-o"></i> Kilométrage</a>
          </li>
          <li>
            <a routerLink="/client/statistical/drivingQuality">
              <i class="fa fa-circle-o"></i> Qualité De Conduite
            </a>
          </li>
          <li>
            <a routerLink="/client/statistical/fuelSettings">
              <i class="fa fa-circle-o"></i> Paramétrage</a>
          </li>
        </ul>
      </li>

      <li routerLink="/client/dataExport" routerLinkActive="active" class="hov" *ngIf="
          signinService.isHasRole('ROLE_RM') || signinService.isRootAdmin()
        ">
        <a>
          <i class="fa fa-book"></i>
          <span>Générateur de rapports</span>
        </a>
      </li>


      <!-- <li routerLink="/client/newDataExport" routerLinkActive="active" class="hov" *ngIf="
        signinService.isHasRole('ROLE_RM') || signinService.isRootAdmin()
      ">
        <a>
          <i class="fa fa-book"></i>
          <span>Nouveau Générateur</span>
        </a>
      </li> -->

      <li routerLinkActive="active" class="hov" *ngIf="
          signinService.isHasRole('ROLE_AM') || signinService.isRootAdmin()
        ">
        <a>
          <i class="fa fa-bell"></i>
          <span>Gestion des alertes</span>
          <span class="pull-right-container">
            <i class="fa fa-angle-left pull-right"></i>
          </span>
        </a>
        <ul class="treeview-menu">
          <li>
            <a routerLink="/client/alertManagement/alertSettings">
              <i class="fa fa-gear"></i> Paramétrage
            </a>
          </li>
          <li>
            <a routerLink="/client/alertManagement/alertNotifications">
              <i class="fa fa-exclamation-circle"></i> Notification
            </a>
          </li>

          <!-- <li>
            <a routerLink="/client/alertManagementNew/alertNew">
              <i class="fa fa-exclamation-circle"></i> Paramétrage Alerte </a>
          </li> -->
        </ul>
      </li>

      <li class="hov" routerLink="/client/admin" routerLinkActive="active" *ngIf="signinService.isRootAdmin()">
        <a>
          <i class="fa fa-user"></i>
          <span>Administration compte</span>
        </a>
      </li>
      <!--
      <li class="hov" routerLink="/client/driverMission" routerLinkActive="active" *ngIf="signinService.isRootAdmin()">
        <a>
          <i class="fa fa-tasks"></i>
          <span>Administration compte(Veh/Mss)</span>
        </a>
      </li> -->

      <li class="hov" routerLink="/client/help" routerLinkActive="active" *ngIf="signinService.isRootAdmin()">
        <a>
          <i class="fa fa-info-circle"></i>
          <span>SAV</span>
        </a>
      </li>
    </ul>
  </section>
</aside>