import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { nominatim_dns, token_google } from '../../../global.config';

interface LatLng {
  lat: number;
  lng: number;
}

@Injectable({
  providedIn: 'root',
})
export class GeocodingService {
  // geocodingSubscription: Subscription;
  constructor(private _http: HttpClient) {}

  inverseGeoconding(
    latitude: number,
    longitude: number,
    zoom: number
  ): Observable<any> {
    return this._http.get(
      nominatim_dns +
        '/reverse.php?format=json&lat=' +
        latitude +
        '&lon=' +
        longitude +
        '&zoom=' +
        zoom +
        '&accept-language=fr&addressdetails=1'
    );
  }

  inverseGeocondingGoogle(
    latitude: number,
    longitude: number
  ): Observable<any> {
    return this._http.get(
      'https://maps.googleapis.com/maps/api/geocode/json?latlng=' +
        latitude +
        ',' +
        longitude +
        '&key=' +
        token_google
    );
  }

  /**proccessing on nominatim response */
  proccessingNomitamAddress(addressWasReturned: any) {
    let address = null;
    /**addressWasReturned : get address as json object*/
    //let pattern = /[^\x00-\x7F|'é'|'è'|'ù'|'à'|'Ê'|'â'|'ô'|'î'|'û']\^ +| +$|( )+/g;
    let pattern = /[^\x00-\x7F|'é'|'è'|'ù'|'à'|'Ê'|'â'|'ô'|'î'|'û']/g;
    /**
     *  don't replace any char that appear in this (|'é'|'è'|'ù'|'à'|'Ê'|'â'|'ô'|'î'|'û')
     * ^\x00-\x7F replace all non asccii char
     *  replace any char in pattern with signe space
     * ^ + : any sequence of spaces at the beginning of the string
     *  +$ : any sequence of spaces at the end of the string
     *  () any sequence of spaces that matches none of the above
     */
    if (addressWasReturned.address) {
      let road;
      let city;
      let suburb;
      let postcode;
      try {
        road = addressWasReturned.address.road.replace(pattern, ' ');
      } catch (error) {}
      try {
        road = addressWasReturned.address.rd.replace(pattern, ' ');
      } catch (error) {}
      try {
        city = addressWasReturned.address.city.replace(pattern, ' ');
      } catch (error) {}
      try {
        city = addressWasReturned.address.cty.replace(pattern, ' ');
      } catch (error) {}
      try {
        suburb = addressWasReturned.address.suburb.replace(pattern, ' ');
      } catch (error) {}
      try {
        suburb = addressWasReturned.address.sb.replace(pattern, ' ');
      } catch (error) {}
      try {
        postcode = addressWasReturned.address.postcode.replace(pattern, ' ');
      } catch (error) {}
      try {
        postcode = addressWasReturned.address.pc.replace(pattern, ' ');
      } catch (error) {}
      if (road && city && suburb && postcode) {
        return road + ',' + city + ',' + suburb + ',' + postcode;
      }
      if (road && city && postcode) {
        return road + ',' + city + ',' + postcode;
      }
      let district;
      try {
        district = addressWasReturned.address.state_district.replace(
          pattern,
          ' '
        );
      } catch (error) {}
      try {
        district = addressWasReturned.address.sd.replace(pattern, ' ');
      } catch (error) {}
      if (road && district && postcode) {
        return road + ',' + district + ',' + postcode;
      }
    }
    if (addressWasReturned.display_name) {
      let display_name = addressWasReturned.display_name.split(',');
      let i = 1;
      let iterate = true;
      let city = '';
      try {
        let road = display_name[0].replace(pattern, ' ');
        city = display_name[1].replace(pattern, ' ');
        let country = display_name[display_name.length - 1].replace(
          pattern,
          ' '
        );
        if (road && city && country) return road + ',' + city + ',' + country;
      } catch (error) {}
    }
    if (addressWasReturned.display_name) {
      let truncatedDisplayName = addressWasReturned.display_name;
      let countCommas = 0;
      for (
        var i = 0, len = addressWasReturned.display_name.length;
        i < len;
        i++
      ) {
        if (addressWasReturned.display_name[i] == ',') {
          countCommas = countCommas + 1;
        } else if (countCommas >= 5) {
          try {
            truncatedDisplayName = addressWasReturned.display_name.substring(
              0,
              i - 1
            );
            return truncatedDisplayName.replace(pattern, ' ');
          } catch (error) {
            if (truncatedDisplayName) return truncatedDisplayName;
          }
        }
      }
    }
  }

  distance(lat1: number, lon1: number, lat2: number, lon2: number): number {
    let p = 0.017453292519943295; // Math.PI / 180
    let c = Math.cos;
    let a =
      0.5 -
      c((lat2 - lat1) * p) / 2 +
      (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;
    let distance = 12742 * Math.asin(Math.sqrt(a));
    return distance;
  }


  getMyIpAdress(): Observable<{ ip: string }> {
    return this._http.get<{ ip: string }>('http://api.ipify.org/?format=json');
  }

  getMyLocation(): Observable<any> {
    return this._http.get('http://ip-api.com/json');
  }

  /** start check if POint is in polygon */

  // Given three collinear points p, q, r,
  // the function checks if point q lies
  // on line segment 'pr'
  onSegment(p: LatLng, q: LatLng, r: LatLng): boolean {
    if (
      q.lng <= Math.max(p.lng, r.lng) &&
      q.lng >= Math.min(p.lng, r.lng) &&
      q.lat <= Math.max(p.lat, r.lat) &&
      q.lat >= Math.min(p.lat, r.lat)
    ) {
      return true;
    }
    return false;
  }


  // To find orientation of ordered triplet (p, q, r).
  // The function returns following values
  // 0 --> p, q and r are collinear
  // 1 --> Clockwise
  // 2 --> Counterclockwise

  orientation(p: LatLng, q: LatLng, r: LatLng): number {
    let val =
      (q.lng - p.lng) * (r.lat - q.lat) - (q.lat - p.lat) * (r.lng - q.lng);

    if (val === 0) {
      return 0; // collinear
    }
    return val > 0 ? 1 : 2; // clockwise or counterclockwise
  }


  // The function that returns true if
  // line segment 'p1q1' and 'p2q2' intersect.

  doIntersect(p1: LatLng, q1: LatLng, p2: LatLng, q2: LatLng): boolean {
    // Trouver les quatre orientations nécessaires pour
    // les cas généraux et spéciaux
    let o1 = this.orientation(p1, q1, p2);
    let o2 = this.orientation(p1, q1, q2);
    let o3 = this.orientation(p2, q2, p1);
    let o4 = this.orientation(p2, q2, q1);

    // Cas général
    if (o1 !== o2 && o3 !== o4) {
      return true;
    }

    // Cas spéciaux
    // p1, q1 et p2 sont colinéaires et
    // p2 est sur le segment p1q1
    if (o1 === 0 && this.onSegment(p1, p2, q1)) {
      return true;
    }

    // p1, q1 et p2 sont colinéaires et
    // q2 est sur le segment p1q1
    if (o2 === 0 && this.onSegment(p1, q2, q1)) {
      return true;
    }

    // p2, q2 et p1 sont colinéaires et
    // p1 est sur le segment p2q2
    if (o3 === 0 && this.onSegment(p2, p1, q2)) {
      return true;
    }

    // p2, q2 et q1 sont colinéaires et
    // q1 est sur le segment p2q2
    if (o4 === 0 && this.onSegment(p2, q1, q2)) {
      return true;
    }

    // Ne tombe dans aucun des cas ci-dessus
    return false;
  }


  // Returns true if the point p lies
  // inside the polygon[] with n vertices
  isInside(polygon: LatLng[], n: number, p: LatLng): boolean {
    // Il doit y avoir au moins 3 sommets dans le polygone[]
    if (n < 3) {
      return false;
    }

    // Créer un point pour le segment de ligne de 'p' à l'infini
    let extreme: LatLng = { lat: 10000, lng: p.lng };

    // Compter les intersections de la ligne ci-dessus
    // avec les côtés du polygone
    let count = 0;
    let i = 0;
    do {
      let next = (i + 1) % n;

      // Vérifier si le segment de ligne de 'p' à
      // 'extreme' intersecte avec le segment de ligne
      // de 'polygon[i]' à 'polygon[next]'
      if (this.doIntersect(polygon[i], polygon[next], p, extreme)) {
        // Si le point 'p' est colinéaire avec le segment
        // 'i-next', alors vérifier s'il est sur le segment.
        // S'il est sur le segment, retourner vrai, sinon faux
        if (this.orientation(polygon[i], p, polygon[next]) === 0) {
          return this.onSegment(polygon[i], p, polygon[next]);
        }

        count++;
      }
      i = next;
    } while (i !== 0);

    // Retourner vrai si le compte est impair, faux sinon
    return count % 2 === 1;
  }

  insidePolygon(point: LatLng, hash: string): boolean {
    // Assurez-vous que `point` est typé comme `LatLng`
    var polygon: LatLng[] = hash.split(';').map((item: string) => {
      var [lat, lng] = item.split(',').map(Number); // Convertit les valeurs en nombres
      return { lat, lng };
    });

    polygon.splice(-1);

    let n = polygon.length;
    return this.isInside(polygon, n, point);
  }


  private baseUrl = 'https://geocode-api.arcgis.com/arcgis/rest/services/World/GeocodeServer/suggest';
  private apiKey = 'AAPTxy8BH1VEsoebNVZXo8HurFkp4APBt3IPCjfIhO0U1sd_NJnK0QqY4WKlA0uETxcAPWQ0b6Dsg1yt7NwrSQP1v2TxSV9KT9fQv3Y6NHsbhlY_R-V3oTcxPVuV11hJffd_-Hy7xyevdL4qhY8x9kbUrWsJADag7MIFx3MpSVh5VQPb9wODsnk1Nd3yqDLErhDvD-whvEvh0VjHtNd-eUZVNkxl3cpuJhObxL1z3BOm520.AT1_MJj9n3As';



  getLocationFromSuggestion(magicKey: string): Observable<any> {
    const params = new HttpParams()
      .set('magicKey', magicKey)
      .set('token', this.apiKey)
      .set('f', 'json');

    const url = 'https://geocode-api.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates';
    return this._http.get(url, { params });
  }

  getSuggestions(text: string, countryCode: string, maxSuggestions: number = 5): Observable<any> {
    const params = new HttpParams()
      .set('text', text)
      .set('countryCode', countryCode)
      .set('maxSuggestions', maxSuggestions.toString())
      .set('token', this.apiKey)
      .set('f', 'json');

    return this._http.get(this.baseUrl, { params });
  }


  // beautify geoconding
  // getGeocoding(address: any): string {
  //   let geocoding: string = null;
  //
  //   if (address) {
  //     if (address.road != null) {
  //       geocoding = address.road;
  //     }
  //
  //     if (address.neighbourhood != null) {
  //       geocoding = geocoding
  //         ? geocoding + ' ' + address.neighbourhood
  //         : address.neighbourhood;
  //     }
  //
  //     if (address.city != null) {
  //       geocoding = geocoding
  //         ? geocoding + ' (' + address.city + ')'
  //         : address.city;
  //     }
  //
  //     if (geocoding == null) {
  //       geocoding = null;
  //     }
  //   }
  //
  //   return geocoding;
  // }
}