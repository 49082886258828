<form class="form-inline">

  <table style="border-spacing: 10px;border-collapse: separate;">
    <tr>
      <td>
        <label>P.O.I</label>
      </td>
      <td>
        <ng-select 
        [multiple]="true"
        bindLabel="text"
        bindValue="id" 
        [items]="poiItems" 
        placeholder="select POI" 
        [compareWith]="comparePois"
        [(ngModel)]="poisSelected" 
        (change)="selectedPoi($event)">
        </ng-select>
      </td>
    </tr>
    <tr>
      <td>
        <label>Durée d'arrêt (min)</label>
      </td>
      <td>
        <select
          *ngIf="stopTimeSelectMode" 
          class="form-control" 
          [(ngModel)]="poiAlertConfiguration.value2" 
          name="stopTime"
          (ngModelChange)="chooseStopTime($event)">
          <option *ngFor="let minute of minutes" [ngValue]="minute" style="font-size: 14px;" [ngClass]="{'autre': minute == 'Autre..'}">
            {{minute}}
          </option>
        </select>
        <input 
          *ngIf="!stopTimeSelectMode" 
          type="number" name="stopTime" 
          [(ngModel)]="poiAlertConfiguration.value2"
          class="form-control" placeholder="Durée d'arrêt">
        <i *ngIf="!stopTimeSelectMode" class="fa fa-times" style="color: #aa1111" (click)="stopTimeSelectMode = true;zoneAlertConfiguration.value2='10'"></i>
      </td>
    </tr>
  </table>

  <div style="margin: 10px 0 10px 0" *ngIf="!showAdvancedSettings" (click)="collapseAdvancedSettings()">
    <i class="fa fa-plus-square"></i> <span class="link">paramétrage avancé</span>
  </div>

  <div style="margin: 10px 0 10px 0" *ngIf="showAdvancedSettings" (click)="collapseAdvancedSettings()">
    <i class="fa fa-minus-square"></i> <span class="link">masquer paramétrage avancé</span>
  </div>

  <app-cron-settings *ngIf="showAdvancedSettings" [cron]="cronAlertConfiguration"></app-cron-settings>

  <app-email-manager [enableGlobalConfigToAllVehicules]="enableGlobalConfigToAllVehicules" [emailMode]="alert.emailMode" [enableGlobalConfig]="enableGlobalConfig" [emailsHash]="alert.emails"
    (onChange)="emailSettings($event)"></app-email-manager>

  <br>

  <div class="form-group">
      <label>Activer l'alert</label>
      <div class="input-group">
          <mat-slide-toggle [(ngModel)]="alert.isActive" name="isActive" ></mat-slide-toggle>
      </div>
  </div>

  <button type="submit" class="btn btn-flat btn-primary pull-right" style="margin-top: 45px;" (click)="save()">
      Sauvegarder
  </button>
</form>
