<mat-card class="mb-25 file-manager-box tagus-card sidebar-area bg-white box-shadow"
    [ngClass]="{ 'dark-sidebar': isSidebarDark(), 'right-sidebar': isRightSidebar() }"
    style="height: 100%; width: 100%;">
    <mat-card-header>
        <h4 class="box-title" style="font-family: 'Josefin Sans', sans-serif;">
            <i class="fa fa-file-excel-o"></i> Générateur des rapports
        </h4>
    </mat-card-header>
    <mat-card-content style="padding: 0 10px; height: 100%;">
        <mat-accordion [multi]="true">
            <div class="custom-scrollbar" style="height: 100%; overflow-y: auto;">
                <div class="sidebar-inner side-bar-rapport">
                    <div class="sidebar-menu">
                        <mat-expansion-panel class="sidebar-menu">
                            <mat-expansion-panel-header class="sidemenu-item">
                                <mat-panel-title>
                                    <span class="title" style="font-weight: 500;">Etat des boîtiers</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ul class="sidebar-sub-menu">
                                <li class="sidemenu-item" (click)="setCurentRapport('Etat actuel des boîtiers')">
                                    <a routerLink="/client/newDataExport/etat-actuel" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{ exact: true }"
                                        class="sidemenu-link sidemenu-link-custom">
                                        Etat actuel des boîtiers
                                    </a>
                                </li>


                                <li class="sidemenu-item" (click)="setCurentRapport('Etat actuel des boîtiers')">
                                    <a routerLink="/client/newDataExport/list-of-stops" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{ exact: true }"
                                        class="sidemenu-link sidemenu-link-custom">
                                        Liste des Arrêts
                                    </a>
                                </li>
                            </ul>
                        </mat-expansion-panel>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header class="sidemenu-item">
                                <mat-panel-title>
                                    <span class="title" style="font-weight: 500;">Arrêts</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ul class="sidebar-sub-menu">
                                <!-- <li routerLink="/client/newDataExport/global-stops-report" routerLinkActive="active"
                                    class="hov"
                                    *ngIf="signinService.isHasRole('ROLE_RE') || signinService.isRootAdmin()"
                                    (click)="setCurentRapport('Rapport globale des arret')">
                                    <a>

                                        <span>Rapport Global des Arrêts</span>
                                    </a>
                                </li> -->

                                <li class="sidemenu-item" (click)="setCurentRapport('Rapport globale des arret')">
                                    <a routerLink="/client/newDataExport/global-stops-report" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{ exact: true }"
                                        class="sidemenu-link sidemenu-link-custom">
                                        Rapport Global des Arrêts
                                    </a>
                                </li>


                                <li class="sidemenu-item" (click)="setCurentRapport('Liste des Arrêts')">
                                    <a routerLink="/client/newDataExport/list-of-stops" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{ exact: true }"
                                        class="sidemenu-link sidemenu-link-custom">
                                        Liste des Arrêts
                                    </a>
                                </li>


                            </ul>
                        </mat-expansion-panel>
                    </div>

                </div>

            </div>
        </mat-accordion>
    </mat-card-content>
</mat-card>