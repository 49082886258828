import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DrivingQualityComponent } from './driving-quality.component';
import { FormsModule } from '@angular/forms'; // Import if you're using forms in your component
import { ToastrModule } from 'ngx-toastr'; // For toastr notifications
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'; // For datepicker
import { HighchartsChartModule } from 'highcharts-angular';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { SharedPipesModule } from 'src/app/pipes/shared-pipes.module';
import { OrderPipModule } from 'src/app/pipes/order-by.pipe.module';
import { TruncateModule } from 'src/app/pipes/truncate.pipe.module';


@NgModule({
  declarations: [
    DrivingQualityComponent // Declare the component here
  ],
  imports: [
    CommonModule, // Import common Angular modules
    FormsModule, // Import this if you are using forms
    ToastrModule.forRoot(), // Initialize Toastr
    BsDatepickerModule.forRoot(), // Initialize Bootstrap datepicker
    HighchartsChartModule, // Import Highcharts module
    AngularMultiSelectModule,
    SharedPipesModule,
    OrderPipModule,
    TruncateModule
  ],
  exports: [
    DrivingQualityComponent // Export the component if you want to use it in other modules
  ]
})
export class DrivingQualityModule { }
