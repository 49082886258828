import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { OtNumber, Schedule } from '../schedule';
import { ScheduleService } from '../schedule.service';
import {Passage} from "../../parametrage/passage";

@Component({
  selector: 'app-numero-ot',
  templateUrl: './numero-ot.component.html',
  styleUrls: ['./numero-ot.component.scss']
})
export class NumeroOtComponent implements OnInit {

 
  vehiculesDropdownSettings = {};
  typeDropdownSettings = {};
  scheduleListForm_: any[] = [];
  scheduleList_: any[] = [];
  selectedVehicule: number;
  //selectedType: string = "";
  loading = false;

  otNum : OtNumber = new OtNumber();
  @Input()
  set scheduleListForm(scheduleListForm: any[]) {
    if (scheduleListForm) {
      this.scheduleListForm_ = Object.assign([], scheduleListForm);
    }
  }

  @Input()
  set scheduleList(scheduleList: Schedule[]) {
    if (scheduleList) {
      this.scheduleList_ = Object.assign([], scheduleList);
    }
  }
  @Input()  passage: Passage = new Passage();

  @Input() passages: any[] = [];
  selectedPassage: Passage = new Passage();

  @Output()
  hideOtMadal: EventEmitter<any> = new EventEmitter<any>();

  isOt: boolean = false;

  selectedType= [{idType: 5, type: 'Livraison'}];

  otNumberList : any[]= [{
    idType: 1, type: 'Mission'
  },
  {
    idType: 2, type: 'Autre'
  }]


  missionTypeList : any[]= [{
    idType: 1, type: 'Palette'
  },
  {
    idType: 2, type: 'Production'
  },
  {
    idType: 3, type: 'Achat'
  },
  {
    idType: 4, type: 'Divers'
  },
  {
    idType:  5, type: 'Livraison'
  },
  {
    idType:  6, type: 'Marketing'
  },
  {
    idType:  7, type: 'Retour a vide'
  },
  {
    idType: 8, type: 'Maintenance'
  },
  {
    idType: 9, type: 'Atelier'
  },
  {
    idType: 10, type: 'Transfert'
  }];

  dropdownSettings : any;

  constructor(private scheduleService: ScheduleService, public toastr: ToastrService) { this.selectedType = [{idType: 5, type: 'Livraison'}];}


  ngOnInit() {

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'idType',
      textField: 'type',
      selectAllText: 'Tout sélectionner',
      unSelectAllText: 'Tout désélectionner',
      itemsShowLimit: 22,
      allowSearchFilter: true
    };

    this.vehiculesDropdownSettings = {

      singleSelection: true,
      selectAllText: 'Tout sélectionner',
      unSelectAllText: 'Tout désélectionner',
      itemsShowLimit: 1,
      allowSearchFilter: true,
      idField: 'deviceId',
      textField: 'matricule',
      enableCheckAll: false,
    };
    // this.typeDropdownSettings = {
    //   singleSelection: true,
    //   idField: 'idType',
    //   textField: 'type',
    //   enableCheckAll: false,
    //   // selectAllText: 'Select All',
    //   // unSelectAllText: 'UnSelect All',
    //   itemsShowLimit: 1,
    //   allowSearchFilter: true,
    // // closeDropDownOnSelection: true,
    // };
      this.otNum.missionType = "Livraison";


    }

    onSelectVehicule(event : any){
      this.otNum.name = this.passage.mainPoi[0].name;
      this.otNum.nbVoyage = this.scheduleList_.filter(v => (v.deviceId == event.deviceId && v.color == "#2f9c36")).length
      this.otNum.deviceId = event.deviceId;
      console.log(this.otNum)
    }
  
    onSelectOtNumber(event : any){
      this.otNum.otNumber = "";
      if(event.idType == 2){
        this.isOt = true;
      }else{
        this.otNum.otNumber = event.type;
      }
    }
    onSelectMissionType(event : any){
      this.selectedType = [];
      this.otNum.missionType = event.type;
      this.selectedType.push(event)
    }
  
    onSaveotNumber(){
      this.loading = true;
      if(this.otNum.missionType == null){
        this.otNum.missionType = "Livraison";
      }
      this.scheduleService.addNumeroOt(this.otNum).subscribe(numOt => {
        this.loading = false;
        this.hideOtMadal.emit(numOt);
        this.isOt = false;
        this.otNum =  new OtNumber();
        if(numOt.message != null){
          this.toastr.error(numOt.message, "OT");
        }
      }, error => {
        this.loading = false;
      })
    }
  
    onDeSelectAll(event : any){
  
    }
  
    onHideModal(){
      this.hideOtMadal.emit();
    }
  


}
