export var vehciuleIcons = [
  { text: "voiture 1", uri: "automobile.png", speed: 120 },
  { text: "voiture 2", uri: "automobile_green.png", speed: 120 },
  { text: "voiture 3", uri: "partner1-left.png", speed: 120},
  { text: "voiture 4", uri: "pickup1-left.png", speed:120},
  { text: "truck 1", uri: "truck-1.png", speed: 120 },
  { text: "truck 2", uri: "tanker-truck3-left.png", speed: 90 },
  { text: "truck 3", uri: "tractor-truck-left.png", speed: 120 },  
  { text: "truck 4", uri: "truck11-left-blue.png", speed: 120 }, 
  { text: "truck 5", uri: "truck11-left-green.png", speed: 120 }, 
  { text: "truck 6", uri: "truck11-left-yellow.png", speed: 120 }, 
  { text: "truck 7", uri: "truck11-left-red.png", speed: 120 }, 
  { text: "rimtel", uri: "cabriolet.png", speed: 120 },
  { text: "bus", uri: "bus.png", speed: 90 },
  { text: "van", uri: "van.png", speed: 120 },
  { text: "ambulance", uri: "ambulance-left.png", speed: 90 },
  { text: "dépannage", uri:"tow-truck-left.png", speed: 90},
  { text: "camionette", uri: "truck.png", speed: 90 },
  { text: "mixer", uri: "mixer.png", speed: 80 },
  { text: "mixer1", uri: "mixer-truck-blue-left.png", speed: 90},
  { text: "mixer2", uri: "mixer-truck-green-left.png", speed: 90},
  { text: "mixer3", uri: "mixer-truck-red-left.png", speed: 90},
  { text: "mixer4", uri: "mixer-truck-yellow-left.png", speed: 90},
  { text: "van", uri: "van_green.png", speed: 120 },
  { text: "motorcycle", uri: "motorcycle.png", speed: 120 },
  { text: "scoter", uri: "delivery-scooter-left.png", speed: 60},
  { text: "excavator", uri: "excavator.png", speed: 80 },
  { text: "tractor", uri: "tractor.png", speed: 60 },
  { text: "forklift", uri: "forklift.png", speed: 60 },
  { text: "rimtel", uri: "rimtel.svg", speed: 120 },
  { text: "van", uri: "van.svg", speed: 120 },
  { text: "ambulance", uri: "ambulance.svg", speed: 90 },
  { text: "motorcycle", uri: "motorcycle.svg", speed: 120 },
  { text: "scoter", uri: "scoter.svg", speed: 60},
  { text: "box truck", uri: "box-truck.svg", speed: 120 }, 
  { text: "mixer", uri: "mixer.svg", speed: 80 },
  { text: "excavator", uri: "excavator.svg", speed: 80 },
  { text: "tractor", uri: "tractor.svg", speed: 60 },
  { text: "truck", uri: "truck.svg", speed: 120 },
  { text: "bus", uri: "bus.svg", speed: 90 },
  { text: "semi-truck", uri: "semi-truck.svg", speed: 120 },
];