import { DateInterval } from './../../../shared/model';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';
import { Path, PathPoi } from './path';
import { dns } from '../../../global.config';
import { Stop } from '../stop/stop';
import { createAuthorizationHeader } from '../../../utils/security/headers';
import {
  Group,
  VehiculeTravelReport,
} from '../../data-management/data-management.model';
import { User } from 'src/app/authentification/signin/credentials';
import { HttpClient } from '@angular/common/http';
import { Schedule } from '../../charges-pfb/schedule/schedule';
import {
  FuelingPriceSetting,
  TravelReportDto,
  Vehicule,
} from '../../parc-management';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class PathService {
  // Schedule
  private ScheduleShared: Schedule[] = [];
  /**current user */
  currentUser: User = new User();
  private _travelReports: BehaviorSubject<Map<number, TravelReportDto>> =
    new BehaviorSubject(new Map());
  private _travelReportsList: BehaviorSubject<TravelReportDto[]> =
    new BehaviorSubject([]);
  public readonly travelReports: Observable<Map<number, TravelReportDto>> =
    this._travelReports.asObservable();
  public readonly travelReportsList: Observable<TravelReportDto[]> =
    this._travelReportsList.asObservable();
  deviceStartDateMap: Map<number, Date> = new Map();
  fuelingPriceSetting: FuelingPriceSetting = new FuelingPriceSetting();
  constructor(private _http: HttpClient) {}

  updateTravelReport(
    travelReports: TravelReportDto[],
    vehicules: VehiculeTravelReport[],
    transportTypeIds: number[],
    startDate: Date
  ) {
    let travelReportMap = this._travelReports.value;
    if (travelReports.length > 0) {
      startDate = new Date(travelReports[0].beginPathTime);
    }
    var minStartDate: Date = startDate;

    travelReports.map((trv) => {
      trv.beginPathTime = new Date(trv.beginPathTime);
      if (minStartDate.getTime() > trv.beginPathTime.getTime())
        minStartDate = trv.beginPathTime;
      let trvMapValue = travelReportMap.get(trv.vehiculeId);
      if (trvMapValue) {
        trvMapValue.update(
          trv.beginPathTime,
          trv.endPathTime,
          trv.maxSpeed,
          trv.pathDuration,
          trv.distanceDriven,
          trv.fuelUsed,
          trv.pathStop,
          trv.otNumber,
          trv.missionType,
          trv.stopRpm
        );
      } else {
        var trvObject: TravelReportDto = new TravelReportDto();
        Object.assign(trvObject, trv);
        trvObject.vehicule = vehicules.find(
          (vehicule) => trv.vehiculeId == vehicule.idVehicule
        );
        trvObject.unitCost = this.fuelingPriceSetting.unitCost;
        trvObject.zone = trvObject.getZone();
        try {
          trvObject.calculateFuel();
        } catch {}
        trvObject.calculateFuelingPrincing();
        travelReportMap.set(trvObject.vehiculeId, trvObject);
      }
    });
    for (let idTransportType of transportTypeIds) {
      this.deviceStartDateMap.set(idTransportType, minStartDate);
    }

    travelReportMap.forEach((v, k) => {
      if (travelReports.findIndex((item) => item.vehiculeId === k) < 0) {
        travelReportMap.delete(k);
      }
    });
    this._travelReports.next(travelReportMap);
  }

  updateTravelReport2(
    travelReportsList: TravelReportDto[],
    vehicules: VehiculeTravelReport[],
    transportTypeIds: number[],
    startDate: Date
  ) {
    let travelReportMap: TravelReportDto[] = [];

    if (travelReportsList.length > 0) {
      startDate = new Date(travelReportsList[0].beginPathTime);
    }
    var minStartDate: Date = startDate;

    travelReportsList.map((trv) => {
      var trvObject: TravelReportDto = new TravelReportDto();
      Object.assign(trvObject, trv);
      trvObject.vehicule = vehicules.find(
        (vehicule) => trv.vehiculeId == vehicule.idVehicule
      );
      trvObject.unitCost = this.fuelingPriceSetting.unitCost;
      trvObject.zone = trvObject.getZone();
      try {
        trvObject.calculateFuel();
      } catch {}
      trvObject.calculateFuelingPrincing();
      travelReportMap.push(trvObject);
    });
    this._travelReportsList.next(travelReportMap);
  }

  getTravelReport(): Observable<TravelReportDto[]> {
    return this._travelReports.pipe(
      map((t) => {
        let travels: TravelReportDto[] = [];
        t.forEach((v, k) => {
          travels.push(v);
        });
        return travels;
      })
    );
  }

  getTravelReportList(): Observable<TravelReportDto[]> {
    return this._travelReportsList;
  }

  getAllPaths(
    deviceId: number,
    dateInterval: DateInterval
  ): Observable<Path[]> {
    let headers = createAuthorizationHeader();
    return this._http.post<Path[]>(dns + 'paths/' + deviceId, dateInterval, {
      headers: headers,
    });
  }

  getAllPoiPaths(
    deviceId: number,
    dateInterval: DateInterval,
    isByClient: boolean
  ): Observable<Path[]> {
    let headers = createAuthorizationHeader();
    return this._http.post<Path[]>(
      dns + 'paths/' + deviceId + '/poi?isByClient=' + isByClient,
      dateInterval,
      { headers: headers }
    );
  }

  getOnePath(deviceId: number, dateInterval: DateInterval): Observable<Path> {
    let headers = createAuthorizationHeader();
    return this._http.post<Path>(dns + 'paths/one/' + deviceId, dateInterval, {
      headers: headers,
    });
  }

  getCurrentPath(deviceId: number): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.post(dns + 'paths/currentPath/' + deviceId, null, {
      headers: headers,
    });
  }

  getPathDetails(
    deviceId: number,
    dateInterval: DateInterval
  ): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.post(dns + 'paths/details/' + deviceId, dateInterval, {
      headers: headers,
    });
  }

  getPathPoiDetails(path: PathPoi): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.post(dns + 'paths/details/poi', path, {
      headers: headers,
    });
  }

  getAllStops(
    deviceId: number,
    dateInterval: DateInterval
  ): Observable<Stop[]> {
    let headers = createAuthorizationHeader();
    return this._http.post<Stop[]>(dns + 'stops/' + deviceId, dateInterval, {
      headers: headers,
    });
  }

  exportPaths(
    idDevice: number,
    groupName: string,
    vehicule: string,
    dateInterval: DateInterval
  ): Observable<any> {
    /**get avatarUri */
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
    let avatartUri = '';
    if (this.currentUser.avatartUri) {
      avatartUri = this.currentUser.avatartUri;
    }
    let headers = createAuthorizationHeader();
    return this._http.post(
      dns +
        'pathsEx/' +
        idDevice +
        '?groupName=' +
        groupName +
        '&vehicule=' +
        vehicule +
        '&timezoneOffset=' +
        new Date().getTimezoneOffset(),
      dateInterval,
      { headers: headers, responseType: 'blob' as 'json' }
    );
  }

  exportStops(
    stops: any[],
    dateBegin: number, // Si `dateBegin` est une date, utilisez le type `Date`. Sinon, spécifiez le type approprié.
    dateEnd: number,   // Idem pour `dateEnd`
    matricule: string, // Si `matricule` est une chaîne, utilisez `string`.
    driverName: string, // Si `driverName` est une chaîne, utilisez `string`.
    groupName: string,
  ): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.post(
      dns +
        'stops/export?dateBegin=' +
        dateBegin +
        '&dateEnd=' +
        dateEnd +
        '&matricule=' +
        matricule +
        '&driverName=' +
        driverName +
        '&groupName=' +
        groupName +
        '&timezoneOffset=' +
        new Date().getTimezoneOffset(),
      stops,
      { headers: headers, responseType: 'blob' as 'json' }
    );
  }

    exportStopsPDF(
      stops: any[],
      dateBegin: number,
      dateEnd: number,
      matricule: string,
      driverName: string,
      groupName: string
    ): Observable<any> {
      let headers = createAuthorizationHeader();
      return this._http.post(
        dns +
          'stops/exportPdf?dateBegin=' +
          dateBegin +
          '&dateEnd=' +
          dateEnd +
          '&matricule=' +
          matricule +
          '&driverName=' +
          driverName +
          '&groupName=' +
          groupName +
          '&timezoneOffset=' +
          new Date().getTimezoneOffset(),
        stops,
        { headers: headers, responseType: 'blob' as 'json' }
      );
    }

  exportPathsPDF(
    paths: any[],
    vehicule: string,
    groupName: string,
    startDate: any,
    endDate: any
  ): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.post(
      dns +
        'pathsPdf?groupName=' +
        groupName +
        '&startDate=' +
        startDate +
        '&endDate=' +
        endDate +
        '&vehicule=' +
        vehicule +
        '&timezoneOffset=' +
        new Date().getTimezoneOffset(),
      paths,
      { headers: headers, responseType: 'blob' as 'json' }
    );
  }

  schedule(ordonnancement: any): Observable<Schedule[]> {
    let headers = createAuthorizationHeader();
    return this._http.post<Schedule[]>(
      dns + 'stops/schedule?timezoneOffset=' + new Date().getTimezoneOffset(),
      ordonnancement,
      { headers: headers }
    );
  }

  scheduleByGroup(dateInterval: DateInterval, groupId: number) {
    let headers = createAuthorizationHeader();
    return this._http.post<any[]>(
      dns + 'stops/scheduleByGroup?groupId=' + groupId,
      dateInterval,
      { headers: headers }
    );
  }

  scheduleByAllVehicule(ordonnancement: any) {
    let headers = createAuthorizationHeader();
    return this._http.post<any[]>(
      dns +
        'stops/scheduleByGroups?timezoneOffset=' +
        new Date().getTimezoneOffset(),
      ordonnancement,
      { headers: headers }
    );
  }

  exportPathsPoi(
    paths: Path[],
    groupName: string,
    startDate: number,
    endDate: number,
    vehicule: string
  ): Observable<any> {
    /**get avatarUri */
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
    let avatartUri = '';
    if (this.currentUser.avatartUri) {
      avatartUri = this.currentUser.avatartUri;
    }
    let headers = createAuthorizationHeader();
    return this._http.post(
      dns +
        'pathsEx/poi?groupName=' +
        groupName +
        '&startDate=' +
        startDate +
        '&endDate=' +
        endDate +
        '&vehicule=' +
        vehicule +
        '&avatarUri=' +
        avatartUri +
        '&timezoneOffset=' +
        new Date().getTimezoneOffset(),
      paths,
      { headers: headers, responseType: 'blob' as 'json' }
    );
  }

  saveTypicalPath(pathDto: any): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.post(dns + 'paths/typical', pathDto, {
      headers: headers,
    });
  }

  getAllTypicalPath(): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.get(dns + 'paths/typical', { headers: headers });
  }

  getNominatim(
    latitude: number,
    longitude: number,
    zoom: number
  ): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.get(
      dns +
        'nominatim?latitude=' +
        latitude +
        '&longitude=' +
        longitude +
        '&zoom=' +
        zoom,
      { headers: headers }
    );
  }

  //==================================
  // get Matricule By Device Id
  //==================================
  getMatriculeByDeviceId(group: Group, idDevice: number): string {
    let vehicules = group.vehicules;
    for (let i = 0; i < vehicules.length; i++) {
      if (vehicules[i].idDevice == idDevice) {
        return vehicules[i].matricule;
      }
    }
  }

  generateTravelReport(
    transportTypeIds: number[],
    dateInterval: DateInterval
  ): Observable<TravelReportDto[]> {
    let headers = createAuthorizationHeader();
    return this._http.post<TravelReportDto[]>(
      dns + 'paths/travelReport/' + transportTypeIds,
      dateInterval,
      { headers: headers }
    );
  }

  generateDashboardSuivieVoyage(
    transportTypeIds: number[],
    canal: number,
    dateInterval: DateInterval
  ): Observable<TravelReportDto[]> {
    let headers = createAuthorizationHeader();
    return this._http.post<TravelReportDto[]>(
      dns +
        'performance/getDashboardSuivieVoyage/' +
        canal +
        '/' +
        transportTypeIds +
        '/?timezoneOffset=' +
        new Date().getTimezoneOffset(),
      dateInterval,
      { headers: headers }
    );
  }

  // Getter and Setter for ScheduleShared
  setSharedData(data: any) {
    this.ScheduleShared = data;
  }

  getSharedData(): any {
    return this.ScheduleShared;
  }
}
