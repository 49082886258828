<div class="hiddenRow" >
  <div class="accordian-body">
    <!-- ------------------------------------------ -->
    <ng-container
      *ngFor="
        let vehicule of item.vehicules | orderBy : 'matricule';
        let l = count
      "
    >
      <div
        (click)="goToRealTimeRecord(item, vehicule)"
        class="group-item"
        [ngClass]="{
          selected:
            realTime.selectedVehicule?.idDevice == vehicule?.idDevice
        }"
        [hidden]="!realTime.isHiddenVehicule(item, vehicule)"

      >
        <div class="item-info">
          <div class="picture">
            <img
              [src]="
                vehicule?.driver && vehicule?.driver?.avatarUri
                  ? 'https://rimtrack.com' + vehicule.driver.avatarUri
                  : 'assets/icons/realtime/groups/user.png'
              "
              class="image-info"
            />
          </div>
          <div class="more-infos">
            <div class="car-user">
              <div style="flex: 1">
                <span
                  class="name-conducteur"
                  *ngIf="!vehicule?.driver"
                  width="8%"
                  data-toggle="tooltip"
                  title="{{ 'Conducteur Inconun' }}"
                >
                  ....
                </span>
                <span
                  *ngIf="vehicule?.driver"
                  class="name-conducteur"
                  width="8%"
                  data-toggle="tooltip"
                  title="{{ dataManagementService.getDriverName(vehicule?.driver) }}"
                >
                  {{
                    dataManagementService.getDriverName(vehicule?.driver)
                      | slice : 0 : 12
                  }}
                  <span
                    *ngIf="
                      dataManagementService.getDriverName(vehicule?.driver)
                        .length > 12
                    "
                    >...</span
                  >
                </span>
              </div>
              <div style="flex: 1">
                <span
                  class="matricule"
                  width="8%"
                  data-toggle="tooltip"
                  title="{{ vehicule.idDevice }}"
                >
                  {{
                    dataManagementService.getVehiculeName(vehicule)
                      | slice : 0 : 10
                  }}
                </span>
              </div>
              <div style="flex: 1;"
              class="date"
              *ngIf="vehicule?.realTimeRecord?.recordTime"
              [ngClass]="{
                technicalIssueRT:
                  vehicule?.realTimeRecord?.realTimeRecordStatus ==
                  'TECHNICAL_ISSUE',
                nonValid:
                  vehicule?.realTimeRecord &&
                  ((vehicule?.realTimeRecord?.validity === false &&
                    vehicule?.realTimeRecord?.realTimeRecordStatus !==
                      'TECHNICAL_ISSUE') ||
                    vehicule?.realTimeRecord?.realTimeRecordStatus == 'NON_VALID')
              }"
                width="8%"
                data-toggle="tooltip"
                title="{{ vehicule?.realTimeRecord?.recordTime | date}}"
            >
              {{ vehicule?.realTimeRecord?.recordTime | date : "HH:mm:ss" }}
            </div>
            </div>

            <div class="location">
              <div *ngIf="!vehicule?.realTimeRecord?.relativePosition" style="flex: 2;">
                <span
                  class="location-actuel"
                  *ngIf="realtimeHelperService.isValidPoint(vehicule?.realTimeRecord?.coordinate)"
                  width="8%"
                  data-toggle="tooltip"
                  title="{{ vehicule?.realTimeRecord?.geocoding }}"
                >
                  {{ vehicule?.realTimeRecord?.geocoding | slice: 0:25 }}
                </span>
                <span *ngIf="vehicule?.realTimeRecord?.geocoding?.length > 25"> ...</span>
                <span
                  class="location-actuel"
                  *ngIf="!realtimeHelperService.isValidPoint(vehicule?.realTimeRecord?.coordinate)"
                >
                  <small class="label bg-yellow">Aucune info</small>
                </span>
              </div>
              
              <div
                class="location-actuel"
                *ngIf="vehicule?.realTimeRecord?.relativePosition"
                width="8%"
                data-toggle="tooltip"
                title="{{ vehicule?.realTimeRecord?.relativePosition }}"
                style="flex: 2;"
              >
                <span>
                  {{ vehicule?.realTimeRecord?.relativePosition | slice: 0:25 }}
                </span>
                <span *ngIf="vehicule?.realTimeRecord?.relativePosition?.length > 25"> ...</span>
              </div>
            
              <div
                class="odometre"
                *ngIf="realTime.selectedVehicule?.idDevice == vehicule?.idDevice"
                style="flex: 1;"
              >
                <div style="font-weight: bold; font-size: 12px">
                  {{ (vehicule?.odo ? vehicule?.odo : 0) | number : ".1-1" }} KM
                </div>
              </div>
              <div style="flex: 1; justify-content: flex-end; display: flex;">
                <span class="vitesse">
                  {{ vehicule?.realTimeRecord?.speed }} Km/h
                </span>
              </div>
            </div>
            
          </div>
          <div class="date" *ngIf="!vehicule?.realTimeRecord?.recordTime">
            <small class="label bg-yellow">Aucune info</small>
          </div>
        </div>
      </div>
      <div
        *ngIf="realTime.selectedVehicule?.idDevice == vehicule?.idDevice"
      >
        <group-item-buttons [vehicule]="vehicule"></group-item-buttons>
      </div>
    </ng-container>
  </div>
</div>

