import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NewImportExportService } from '../../_service/new-import-export.service';

@Component({
  selector: 'app-pdf-settings',
  templateUrl: './pdf-settings.component.html',
  styleUrls: ['./pdf-settings.component.scss'],
})
export class PdfSettingsComponent implements OnInit, OnDestroy {
  rapportName: string | null = null;
  rapportName$: Subscription;

  constructor(
    private importExportService: NewImportExportService //public themeService: CustomizerSettingsService
  ) {}

  ngOnInit(): void {
    this.rapportName$ = this.importExportService
      .getRapport()
      .subscribe((rapport) => {
        this.rapportName = rapport;
      });
  }

  ngOnDestroy(): void {
    if (this.rapportName$) {
      this.rapportName$.unsubscribe();
    }
  }
}
