import { Component, OnInit, ViewChild } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { imagesPOIDir } from "../../../global.config";
import { GeocodingService } from "../../../utils/leaflet/service/geocoding.service";
import { MapService } from "../../../utils/leaflet/service/map.service";
import { PointInterest, RapportDto } from "../data-management.model";
import { DataManagementService } from "../data-management.service";
import * as L from 'leaflet';
import { ImportExportService } from "../../import-export/import-export.service";
import {Icon, Marker, Polygon} from "leaflet";
import { ModalDirective } from 'ngx-bootstrap/modal';


@Component({
  selector: "app-poi",
  templateUrl: "./poi.component.html",
  styleUrls: ["./poi.component.css"]
})
export class PoiComponent implements OnInit {

  importExport = true;


  @ViewChild('importModal', { static: false }) public importModal!: ModalDirective;

  @ViewChild('poiDeleteModal', { static: false })
  poiDeleteModal: ModalDirective;

  /** all poi*/
  pointInterests: PointInterest[] = [];

  /** selected poi*/
  pointInterest: PointInterest = new PointInterest();

      /** import  */
  selectedItem = 'POI';

  /** matker poi*/
  pointInterestsPoint: PointInterest[] = [];

  /** polygon poi*/
  pointInterestsPolygon: PointInterest[] = [];

  repport: RapportDto = new RapportDto();

  public searchTerm: string = null;
  /** poi by page*/
  dataPoint:any[] = [];
  dataPolygon:any[] = [];

  // pag vars
  public page: number = 1;
  public itemsPerPage: number = 25;
  public maxSize: number = 5;

  public pagePolgygon: number = 1;
  public itemsPerPagePolgygon: number = 25;
  public maxSizePolgygon: number = 5;
  /** delete modal */

  /** current selected poi */
  selectedPoi: PointInterest
  /** loader */
  loading = false;

  isDataLoaded: boolean = false;

  @ViewChild('childModal', { static: false }) public childModal: ModalDirective;

  public showChildModal(): void {
    if (this.childModal) {
      this.childModal.show();
    }
  }

  constructor(
    private mapService: MapService,
    private dataManagementService: DataManagementService,
    private geocodingService: GeocodingService,
    public toastr: ToastrService,
    public exportImport: ImportExportService,
  ) { }

  ngOnInit() {
    this.loadPOI();
  }

  //GET list of Points and Polygons
  loadPOI() {
    if (this.dataManagementService.pointInterests) {
      this.dataManagementService.pointInterests.forEach(pointInterest => {
        if (pointInterest.type == "MARKER") {
          this.pointInterestsPoint.push(pointInterest);
        } else if (pointInterest.type == "POLYGON") {
          this.pointInterestsPolygon.push(pointInterest);
        }
      });
      this.PageChangePoint({
        page: this.page,
        itemsPerPage: this.itemsPerPage
      });
      this.PageChangePolygon({
        page: this.pagePolgygon,
        itemsPerPage: this.itemsPerPagePolgygon
      });
      this.isDataLoaded = true;
    } else {
      this.dataManagementService
        .getAllPointInterests()
        .subscribe(pointInterests => {
          pointInterests.forEach(pointInterest => {
            if (pointInterest.type == "MARKER") {
              this.pointInterestsPoint.push(pointInterest);
            } else if (pointInterest.type == "POLYGON") {
              this.pointInterestsPolygon.push(pointInterest);
            }
          });
          this.dataManagementService.pointInterests = pointInterests;
          this.PageChangePoint({
            page: this.page,
            itemsPerPage: this.itemsPerPage
          });
          this.PageChangePolygon({
            page: this.pagePolgygon,
            itemsPerPage: this.itemsPerPagePolgygon
          });
          this.isDataLoaded = true;
        });
    }
  }

  //Pagination of Polygon page
  PageChangePolygon(page: any) {
    let start = (page.page - 1) * page.itemsPerPage;
    let end =
      page.itemsPerPage > -1
        ? start + page.itemsPerPage
        : this.pointInterestsPolygon.length;
    this.dataPolygon = this.pointInterestsPolygon.slice(start, end);
  }

  deletePointPolygon(poi: PointInterest) {
    this.selectedPoi = poi;
    this.poiDeleteModal.show();
  }
  onDelete() {
    this.deletePOI(this.selectedPoi);
  }

  initPoints() {
    var markersListLength = this.dataManagementService.pointInterests!.filter(
      pointInterest => pointInterest.type == "MARKER"
    ).length;

    // init !
    this.pointInterestsPoint = [];
    this.dataPoint = [];

    this.dataManagementService.pointInterests!.forEach(pointInterest => {
      if (pointInterest.type == "MARKER") {

        /** SEARCH WITH NAME IF VAR searchTerm EXISTS */
        if (this.searchTerm != null && this.searchTerm.length > 0) {
          if (pointInterest.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) != -1) {
            this.pointInterestsPoint.push(pointInterest);
          }
        } else {
          this.pointInterestsPoint.push(pointInterest);
        }

      }
    });

    /** if deleted element is the last element in the page*/
    if (markersListLength % this.itemsPerPage === 0) {
      if (this.page > 1) {
        this.page = this.page - 1;
      } else {
        this.page = 1;
      }
    }

    this.PageChangePoint({ page: this.page, itemsPerPage: this.itemsPerPage });
  }

  initPolygons() {
    var polygonsListLength = this.dataManagementService.pointInterests!.filter(
      pointInterest => pointInterest.type == "POLYGON"
    ).length;

    // init !
    this.pointInterestsPolygon = [];
    this.dataPolygon = [];

    this.dataManagementService.pointInterests!.forEach(pointInterest => {
      if (pointInterest.type == "POLYGON") {

        /** SEARCH WITH NAME IF VAR searchTerm EXISTS */
        if (this.searchTerm != null && this.searchTerm.length > 0) {
          if (pointInterest.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) != -1) {
            this.pointInterestsPolygon.push(pointInterest);
          }
        } else {
          this.pointInterestsPolygon.push(pointInterest);
        }

      }
    });

    /** if deleted element is the last element in the page*/
    if (polygonsListLength % this.itemsPerPagePolgygon === 0) {
      if (this.pagePolgygon > 1) {
        this.pagePolgygon = this.pagePolgygon - 1;
      } else {
        this.pagePolgygon = 1;
      }
    }

    this.PageChangePolygon({
      page: this.pagePolgygon,
      itemsPerPage: this.itemsPerPagePolgygon
    });
  }

  //Pagination of Point page
  PageChangePoint(page: any) {
    let start = (page.page - 1) * page.itemsPerPage;
    let end =
      page.itemsPerPage > -1
        ? start + page.itemsPerPage
        : this.pointInterestsPoint.length;
    this.dataPoint = this.pointInterestsPoint.slice(start, end);
  }


  deletePOI(point: PointInterest) {
    this.loading = true;
    this.dataManagementService
      .deletePointInterest(point.idPointInterest)
      .subscribe(
        suprimed => {
          this.poiDeleteModal.hide();
          if (suprimed) {
            this.mapService.removeMarkersFromMap();
            this.mapService.removeMarkersPoiFromMap();
            this.mapService.removePolygonsPoiFromMap();

            this.dataManagementService.pointInterests = this.dataManagementService.pointInterests!.filter(
              pointInterest =>
                pointInterest.idPointInterest != point.idPointInterest
            );

            if (point.type == "MARKER") {
              this.initPoints();
            }

            if (point.type == "POLYGON") {
              this.initPolygons();
            }

            this.toastr.success(
              "Operation de suppression est bien effectuée !",
              "info"
            );
            this.loading = false;
          } else {
            this.toastr.error(
              "L'operation de suppression est annulée !",
              "Error"
            );
            this.loading = false;
          }
        },
        () =>
          this.toastr.error("L'operation de suppression est annulée !", "Error")
      );
  }

  updatePOI(pointInterest: PointInterest) {
    this.pointInterest = pointInterest;
    this.showChildModal();
  }

  drawCircle(pointInterest: PointInterest) {
    let circle = L.circle(pointInterest.coordinate, {
      color: 'red',
      fillColor: '#f03',
      fillOpacity: 0.1,
      radius: pointInterest.ray
    });
    this.mapService.addCircle(circle);

    setTimeout(() => {
      this.mapService.removeCirclesFromMap();
    }, 9000);
  }

  displayPointPolygon(pointInterest: PointInterest) {
    this.toastr.success(
      pointInterest.name +
      " couvre un rayon de " +
      pointInterest.ray +
      " metres",
      "info"
    );

    this.mapService.removeMarkersFromMap();
    this.mapService.removeMarkersPoiFromMap();
    this.mapService.removePolygonsPoiFromMap();

    let popup =
      "<span class='leaflet-pelias-layer-icon-container'><div class='leaflet-pelias-layer-icon leaflet-pelias-layer-icon-point' title='layer: venue'></div></span> Nom : <strong>" +
      pointInterest.name +
      "</strong><br><hr><b>Adresse : " +
      pointInterest.address +
      "</b>";
    let marker: L.Marker<any> | null = null;

    if (pointInterest.type == "POLYGON") {
      let polygon = new Polygon(pointInterest.decode);
      if(pointInterest.plyColor != null)
      polygon.setStyle({fillColor: pointInterest.plyColor, color:pointInterest.plyColor, weight: 2});
      this.mapService.addPolygonPoi(polygon);
    }

    marker = new Marker(pointInterest.coordinate);

    marker.on("click", () => {
      this.mapService.map.setView(pointInterest.coordinate, 17);
    });

    marker.on("mouseover", () => {
      marker.openPopup();
    });

    marker.on("add", () => {
      marker.openPopup();
    });

    if (pointInterest.type == "MARKER") {
      this.drawCircle(pointInterest);
    }

    //Edit Marker
    marker.options.draggable = true;
    this.pointInterest = pointInterest;

    marker.on("drag", (e: any) => {
      pointInterest.coordinate = e.target._latlng;
    });

    marker.on("dragend", (e: any) => {
      pointInterest.coordinate = e.target._latlng;
      this.pointInterest.coordinate = e.target._latlng;

      this.geocodingService
        .inverseGeoconding(
          this.pointInterest.coordinate.lat,
          this.pointInterest.coordinate.lng,
          17
        )
        .subscribe(adress => {
          this.pointInterest.address = adress.display_name;
        });
      this.childModal.show();
    });

    marker.bindPopup(popup);
    marker.openPopup();
    marker.setIcon(
      new Icon({
        iconUrl: imagesPOIDir + pointInterest.imageUri,
        iconAnchor: [-2, 10],
        popupAnchor: [10, -25]
      })
    );
    this.mapService.addMarkerPoi(marker);
    var mapCenterMarker = new L.LatLng(
      pointInterest.coordinate.lat,
      pointInterest.coordinate.lng - 0.08
    );
    var mapCenterPolygin = new L.LatLng(
      pointInterest.coordinate.lat,
      pointInterest.coordinate.lng - 0.003
    );

    if (pointInterest.type == "POLYGON") {
      this.mapService.map.setView(mapCenterPolygin, 17);
    } else {
      this.mapService.map.setView(mapCenterMarker, 12);
    }
  }

  export() {
    this.repport.type = 'SITE_CLIENT';
    this.exportImport.export(this.pointInterest, this.repport, 0);
}

itemWasImported(res: boolean) {
  if (res) {
      this.importModal.hide();
      this.loadPOI();
  }
}

importMod(){
  this.importModal.show();
}

}
