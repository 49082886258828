import {
  Passage,
  TransportTypeInfoDto,
} from 'src/app/client-management/charges-pfb';
import {
  Group,
  Mission,
  VehiculesDtoWithGroup,
} from '../../client-management/data-management/data-management.model';

export class Credentials {
  username: string;
  password: string;

  constructor() {
    this.username = '';
    this.password = '';
  }
}

export class Rapport {
  idRapport: number;
  name: String;
  label: String;
}

export class Option {
  idOption: number;
  description: string;
}

export class User {
  id: number;
  displayName: string;
  username: string;
  email: string | null;
  enabled: boolean;
  token: string;
  isRoot: boolean;
  password: string;

  authorities: Authority[];
  groups: Group[];
  options: Option[];
  vehicules: VehiculesDtoWithGroup[];
  transportTypes: TransportTypeInfoDto[];
  passages: Passage[];
  actions: Action[];
  avatartUri: string;

  constructor() {
    this.username = '';
    this.email = '';
    this.authorities = [];
    this.groups = [];
    this.enabled = true;
    this.isRoot = false;
    this.options = [];
    this.vehicules = [];
    this.actions = [];
    this.transportTypes = [];
    this.passages = [];
  }
}

export class Action {
  id: number;
  type: 'CONNECT' | 'DISCONNECT';
  details: string;
  createdAt: number;

  constructor() {}
}

export type AuthorityName =
  | 'ROLE_ROOT'
  | 'ROLE_RT'
  | 'ROLE_PATH'
  | 'ROLE_PATH'
  | 'ROLE_DM'
  | 'ROLE_PM'
  | 'ROLE_AM'
  | 'ROLE_RM'
  | 'ROLE_RE'
  | 'ROLE_OV'
  | 'ROLE_ED'
  | 'ROLE_SM'
  | 'ROLE_OV'
  | 'ROLE_PM_CD'
  | 'ROLE_PM_FUEL'
  | 'ROLE_PM_VIDANGE'
  | 'ROLE_PM_MAINTENANCE'
  | 'ROLE_PM_LOSSES'
  | 'ROLE_PM_LEASING'
  | 'ROLE_PM_VARIOUS'
  | 'ROLE_PM_DC'
  | 'ROLE_PM_DASHBOARD'
  | 'ROLE_ACOV'
  | 'ROLE_ACBTNT'
  | 'ROLE_ACDTS'
  | 'ROLE_GEO'
  | 'ROLE_CHARGE_PFB'
  | 'ROLE_ENGINE_CONTROLE'
  | 'ROLE_POI'
  | 'ROLE_TEMPLATE_CARBURANT_NOMATIS'
  | 'ROLE_TEMPLATE_CARBURANT_ALEQ'
  | 'ROLE_TEMPLATE_CARBURANT_AMA'
  | 'ROLE_NOTIFICATION_CARBURANT'
  | 'ROLE_ETAT_ACTUEL'
  | 'ROLE_ETAT_ACTUEL_STOP'
  | 'ROLE_PATH_FUEL'
  | 'ROLE_PATH_POI'
  | 'ROLE_PATH_ZONE'
  | 'ROLE_TRANSPORT_BASE'
  | 'ROLE_REP_FUEL_VARIATION_GN'
  | 'ROLE_KILOMETRAGE'
  | 'ROLE_KILOMETRAGE_DAILY'
  | 'ROLE_SITE_CLIENT'
  | 'ROLE_SITE_POICLIENT'
  | 'ROLE_ARRETS'
  | 'ROLE_ARRETS_GLOBAL'
  | 'ROLE_STOP_BY_POI'
  | 'ROLE_STOP_NON_POI'
  | 'ROLE_DAILY_STOP_BY_POI'
  | 'ROLE_DAILY_STOP_BY_NON_POI'
  | 'ROLE_DELAY'
  | 'ROLE_ACTIVITY_GLOBAL'
  | 'ROLE_ACTIVITY'
  | 'ROLE_ACTIVITY_SPEED'
  | 'ROLE_ACTIVITY_MEAN'
  | 'ROLE_DIVIDED_ACTIVITY_SPEED'
  | 'ROLE_SPEEDING'
  | 'ROLE_RUNNING_DOCUMENT'
  | 'ROLE_EXPIRATION_DOCUMENT'
  | 'ROLE_FUELING'
  | 'ROLE_OIL_CHANGE'
  | 'ROLE_REPARATION_MODULE'
  | 'ROLE_OFFENSES_MODULE'
  | 'ROLE_IMPORT_FUEL'
  | 'ROLE_IMPORT_OIL_CHANGE'
  | 'ROLE_IMPORT_INSURANCE'
  | 'ROLE_IMPORT_VISIT'
  | 'ROLE_IMPORT_ROAD_TAXES'
  | 'ROLE_IMPORT_OPERATIONAL_CERTIFICATION'
  | 'ROLE_IMPORT_PERMIT_CIRCULATION'
  | 'ROLE_IMPORT_METOLOGICA_NOTBOOK'
  | 'ROLE_IMPORT_EXTINCTEURS'
  | 'ROLE_IMPORT_TONNAGE_TAX'
  | 'ROLE_IMPORT_LEASING'
  | 'ROLE_IMPORT_DIVERS'
  | 'ROLE_IMPORT_CA_REALIZATION'
  | 'ROLE_CUMULATIVE_COST_OF_PARK_MANAGEMENT'
  | 'ROLE_DRIVER_ASSIGNMENT_HISTORY'
  | 'ROLE_OIL_CHANGE_MONITORING'
  | 'ROLE_FUELING_MONITORING_CONSUMPTION'
  | 'ROLE_CHARGE_PFB_PASSAGE'
  | 'ROLE_CHARGE_PFB_TRANSPORT_TYPE'
  | 'ROLE_CHARGE_PFB_VALIDATOR'
  | 'ROLE_CHARGE_PFB_ORDONNENCEMENT'
  | 'ROLE_CHARGE_PFB_AFFECTATION'
  | 'ROLE_CHARGE_PFB_TRACKING'
  | 'ROLE_CHARGE_PFB_TRACKING_HISTORY'
  | 'ROLE_CIMENT'
  | 'ROLE_MISSION'
  | 'ROLE_RAM'
  | 'ROLE_GLOBAL_OVERVIEW'
  | 'ROLE_PERFORMANCE_CANAL'
  | 'ROLE_PERFORMANCE_CHANTIER'
  | 'ROLE_TEMPLATE_CARBURANT_SAM'
  | 'ROLE_TMM'
  | 'ROLE_OVERALL_VIEW';

export class Authority {
  id: string;
  name: AuthorityName;
  label: string;

  constructor() {}
}

export class UserMission {
  id: number;
  displayName: string;
  username: string;
  email: string;
  enabled: boolean;
  token: string;
  isRoot: boolean;
  password: string;
  missions: Mission[];
  vehicules: VehiculesDtoWithGroup[];

  constructor() {
    this.username = '';
    this.email = '';
    this.missions = [];
    this.enabled = true;
    this.isRoot = false;
    this.vehicules = [];
  }
}
