import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Mission, PointInterest } from 'src/app/client-management/data-management';
import { Schedule, ScheduleForm } from '../schedule';
import { ScheduleService } from '../schedule.service';
import { ValidatorDto } from "../../parametrage/validator-settings";
import { Passage, PassageService } from "../../parametrage/passage";

@Component({
  selector: 'app-scheduling-form',
  templateUrl: './scheduling-form.component.html',
  styleUrls: ['./scheduling-form.component.css']
})
export class SchedulingFormComponent implements OnInit {

  scheduleList_: Schedule[] = [];
  schedule: ScheduleForm = new ScheduleForm();
  validatorId: number = -1;
  loading = false;
  poiSelectMode = true;
  selectedEmails: string | any[] = [];
  //poiClient_: PoiClient[];
  poiClient_: PointInterest[];
  poiSource: number;
  isPoiClient: boolean = true;
  isAuthorSite: boolean = false;
  passage_ :Passage = new Passage();

  mission: Mission = new Mission();
  selectedPoi: { id: number, name: string }[] = [];
  clientAffe:  { id: number, name: string }[]=[];
  sites: {idPointInterest: number , name: string}[] = [];

  @Output()
  scheduleSaved: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  hideschedule: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  set scheduleList(scheduleList: Schedule[]) {
    if (scheduleList) {
      this.scheduleList_ = Object.assign([], scheduleList);
    }
  }

  validators_: ValidatorDto[] = [];

  @Input()
  set validators(validators: ValidatorDto[]) {
    if (validators) {
      this.validators_ = Object.assign([], validators);
    }
  }

  pointInterests_: String[] = [];
  oldScheduleName: string;
  @Input()
  set pointInterests(pointInterests: String[]) {
    if (pointInterests) {
      this.poiSelectMode = true;
      this.pointInterests_ = Object.assign([], pointInterests);
    }
  }

  @Input()
  set poiClient(poiClient: PointInterest[]){
    if(poiClient){
      this.poiClient_ = poiClient;
    }
  }

  @Input()
    set selectedPassage(passage :Passage){
      this.sites = [];
      if(passage){
        this.passage_ = Object.assign({}, passage);
        for(let p of this.passage_.secondaryPoi){
          this.sites.push({idPointInterest: p.idPointInterest,
            name: p.name});
        }
      }

    }
  @Input() clientSource:number;


  @Input()
  isBreakDown: boolean = false;

  @Input()
  isBreakDownModal: boolean = false;

  vehiculesDropdownSettings = {};
  poiDropdownSettings = {};
  emailsDropdownSettings = {};

  emailsList = [{
    "email": "test1@gmail.com"
  },
  {
    "email": "test2@gmail.com"
  },
  {
    "email": "test3@gmail.com"
  }]

  constructor(private scheduleService: ScheduleService, public toastr: ToastrService, public passageService: PassageService) { }

  ngOnInit() {

   // this.loadAllPassage();

    this.vehiculesDropdownSettings = {
      singleSelection: true,
      idField: 'deviceId',
      textField: 'matricule',
      enableCheckAll: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };

    this.poiDropdownSettings = {
      singleSelection: true,
      idField: 'idPointInterest',
      textField: 'name',
      enableCheckAll: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };

    this.emailsDropdownSettings = {
      singleSelection: false,
      idField: 'email',
      textField: 'email',
      enableCheckAll: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: true
    };
  }

  loadAllPassage() {
    this.loading = true;
    this.passageService.findAllPassages().subscribe(passages => {
      this.passageService.passages = passages;
    }, error => {
      this.toastr.error("Erreur lors de chargements", "Passage")
      this.loading = false;
    })
  }

  choosePoi($event: any) {
    this.poiSource = 0;
    this.selectedPoi = [];
    if ($event === 'Autre..') {
      this.poiSelectMode = false;
      this.schedule.name = null;
    }else{
      this.poiSource = $event;

      this.selectedPoi.push({
          id: this.poiSource,
          name: ""
        },
        {
          id: this.passage_.mainPoi[0].idPointInterest,
          name: ""
        })
    }
  }

  emailsToHash() {
    let separator = ';';
    if (this.schedule.emails && this.schedule.emails.length > 0){
      this.schedule.emails += separator;
    }
    for(let i = 0; i < this.selectedEmails.length; i++){
      if(i == this.selectedEmails.length - 1){
        separator = '';
      }
      this.schedule.emails += this.selectedEmails[i] + separator;
    }
  }

  onItemSelect(item: any) {
    this.schedule.deviceId = item.deviceId;
    this.schedule.matricule = item.matricule;
    if (this.isBreakDown) {
      if (this.isBreakDownModal) {
        if (this.scheduleList_.find(s => s.deviceId == this.schedule.deviceId && s.type == 'BreakDown' && s.active == true)) {
          this.schedule.active = true;
        } else {
          this.schedule.active = false;
        }
      } else {
        if (this.scheduleList_.find(s => s.deviceId == this.schedule.deviceId && s.type == 'Available' && s.active == true)) {
          this.schedule.active = true;
        } else {
          this.schedule.active = false;
        }
      }
    }
    //if(this.schedule.active){
      this.mission.vehicule = this.scheduleList_.find(s => s.deviceId == item.deviceId).vehiculeId;
    //}

  }

  onPoiSelect(poi: any){
    if(!this.isAuthorSite){
      this.schedule.name = poi.name;
      this.oldScheduleName = poi.name;
      this.selectedPoi = [];
      this.clientAffe=[];
      this.clientAffe.push({
        id: poi.idPointInterest,
        name: poi.name
      });
      this.selectedPoi.push(
        {
          id: this.passage_.mainPoi[0].idPointInterest,
          name: this.passage_.mainPoi[0].name
        },{
        id: poi.idPointInterest,
        name: poi.name
      },
      {
        id: this.passage_.mainPoi[0].idPointInterest,
        name: this.passage_.mainPoi[0].name
      })
    }
  }

  onPoiDeSelect(poi: any){
    if(!this.isAuthorSite){
      this.schedule.name = null;
      this.selectedPoi = [];
      this.clientAffe=[];
    }
  }
  onAutherPoiSelect(poi: any){
    this.selectedPoi = [];
    this.schedule.name = poi.name;
      this.selectedPoi.push({
        id: this.passage_.mainPoi[0].idPointInterest,
        name: this.passage_.mainPoi[0].name
      },{
        id:this.clientAffe[0].id,
        name:this.clientAffe[0].name
      },{
        id: poi.idPointInterest,
        name: poi.name
      }
    )
  }

  onAutherPoiDeSelect(poi:any){
    this.schedule.name = null;
    this.selectedPoi = [];
  }
  clientToggler(event :any){
    this.isPoiClient = !this.isPoiClient;
    this.selectedPoi = [];
    this.schedule.name = null;
  }

  siteToggler(event : any){
    if(!this.isAuthorSite){
      this.oldScheduleName = this.schedule.name;
      //this.schedule.name = null
    }else{
       this.schedule.name = this.oldScheduleName;
    }
    this.isAuthorSite = !this.isAuthorSite;
  }


  onSave() {
    this.loading = true;
    this.schedule.endStopTime = new Date();
    this.schedule.beginStopTime = new Date(this.scheduleList_.filter(s => s.deviceId == this.schedule.deviceId)[0].endStopTime);
    this.schedule.endStopTime.setMinutes(this.schedule.endStopTime.getMinutes() + 5);
    this.schedule.type = "Between";
    this.schedule.active = false;
    this.schedule.passage = this.passage_.idPassage;
    this.onSaveMission();
    this.loading = false;
    if(this.selectedEmails.length > 0)
    this.emailsToHash();
    this.scheduleService.add(this.schedule, this.validatorId).subscribe(schedule => {
      this.loading = false;
      this.schedule = new ScheduleForm();
      this.selectedEmails = [];
      this.scheduleSaved.emit();
       if(this.selectedPoi.length > 0)
         this.onSaveMission();
    }, error => {
      this.loading = false;
    }
    )
  }

  onSaveMission() {
    this.loading = true;
    this.mission.pointOfInterests = this.selectedPoi.map(item => item.id);
    this.mission.isReturn = true;
    this.scheduleService.addMission(this.mission)
        .subscribe(mission => {
           // this.missionSaved.emit(mission);
            this.mission = new Mission();
            this.loading = false;
            this.toastr.success("Enregistré", "Mission");
        }, err => {
            this.loading = false;
            this.toastr.error("Erreur lors d'ajout", "Mission")
        })
  }

  onCancel(scheduleForm: NgForm) {
    scheduleForm.reset();
    this.hideschedule.emit();
  }

  onEmailChange(emailPayload :any) {
    this.schedule.emails = emailPayload.emails;
  }

  onSaveBreakDown() {
    this.loading = true;
    this.schedule.beginStopTime = new Date();
    this.schedule.endStopTime = new Date();
    this.schedule.passage = this.passage_.idPassage;
    this.schedule.emails = null;
    if (this.isBreakDownModal) {
      this.schedule.type = 'BreakDown';
      this.schedule.name = 'Panne';
    } else {
      this.schedule.type = 'Available';
      this.schedule.name = 'Activer/Désactiver';
    }

    this.scheduleService.add(this.schedule, -1).subscribe(schedule => {
      this.loading = false;
      this.schedule = new ScheduleForm();
      this.scheduleSaved.emit();
    }, error => {
      let textError = JSON.parse(JSON.stringify(error.error || null));

      if (!textError.errorMessage) {
        textError.errorMessage = "SERVER ERROR";
      }

      this.toastr.error(textError.errorMessage, JSON.parse(JSON.stringify(error.error || null)).errorCode);

      this.loading = false;
    })
  }
}
