import { Component, OnInit, Output } from '@angular/core';
import { Group, DataManagementService, RapportDto, VehiculesDto } from 'src/app/client-management/data-management';
import { Subscription } from 'rxjs';
import { GeoMarketingInfoDto } from '../geo-marketing';
import { ToastrService } from 'ngx-toastr';
import { GeoMarketingService } from '../geo-marketing.service';
import { ImportExportService } from 'src/app/client-management/import-export';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { reportOf } from '../../import-export/report-item';
import { saveAs as importedSaveAs } from 'file-saver';

@Component({
  selector: 'app-geo-marketing-tracking',
  templateUrl: './geo-marketing-tracking.component.html',
  styleUrls: ['./geo-marketing-tracking.component.css']
})
export class GeoMarketingTrackingComponent implements OnInit {


  /** GROUP VAR */
  selectedGroup: Group = new Group();

  groups: Group[] = [];

  vehicules: VehiculesDto[] = [];

  loader = false;

  public ANIMATION_TYPES = ngxLoadingAnimationTypes;

  /** GROUP SUBSCRIPTION */
  // groupsSubscription: Subscription = null;

  /** GEOMARKETING VAR */
  selectedGeoMarketing: GeoMarketingInfoDto|null = null;

  geoMarketing: GeoMarketingInfoDto[] = [];

  loading: boolean;

  repport: RapportDto = new RapportDto();

  reportOf = true;

  maxDate: Date = new Date();


  groupsItemss:any = [
    { idGroupe: 1, nom: 'Group 1' },
    { idGroupe: 2, nom: 'Group 2' },
    { idGroupe: 3, nom: 'Group 3' }
  ];


  addCustomUser = (term: any) => ({idGroupe: term, nom: term});

  vehiculesItems: any[] = [];
  vehiculesSelected: any[] = [];

  groupsItems: any[] = [];
  selectedGroupsItems: any[] = [];
  selectedGroupsHash: string = "";
  selectedVehiculesHash: string = "";

  /**option */
  Selectedoption: string = 'ALL_VEHICULES';
  /**options */
  selectReportOf = reportOf;

  constructor(private dataManagementService: DataManagementService,
    private toastr: ToastrService,
    private geoMarketingService: GeoMarketingService,
    public exportImport: ImportExportService,
    private localeService: BsLocaleService
  ) {
    this.loader = this.exportImport.loader;

  }

  ngOnInit() {
    this.localeService.use('fr');

    var toDay = new Date();
    this.repport.dateInterval.startDate = new Date(toDay.getFullYear(), toDay.getMonth(), toDay.getDate(), 0, 0, 0, 0);
    this.repport.dateInterval.endDate = new Date(toDay.getFullYear(), toDay.getMonth(), toDay.getDate(), 23, 59, 59, 59);

    /**get only groups with more than one vehicule */
    this.dataManagementService.getAllGroupsDetails('').subscribe(res => {
      this.groups = res.filter(group => group.vehicules.length > 0);
      this.mapGroupsToItems(this.groups);
      if (this.groups) {
        this.getVehicules();
      }
      this.loading = false;
    });

    this.loadGeoMarketing();
    this.init();
  }

  init() {
    this.repport.rapportPayloadDto.byVehicules = false;
    this.repport.rapportPayloadDto.byGroups = false;
    this.Selectedoption = 'ALL_VEHICULES';
    this.repport.rapportPayloadDto.allVehicules = true;
  }

  /**on switching between report of all vehicule,by group and by vehicule */
  onSelectingReportOf() {
    /**give ability to downoald */
    switch (this.Selectedoption) {
      case 'ALL_VEHICULES':
        this.repport.rapportPayloadDto.allVehicules = true;
        this.repport.rapportPayloadDto.byGroups = false;
        this.repport.rapportPayloadDto.byVehicules = false;
        break;
      case 'BY_GROUPS':
        this.repport.rapportPayloadDto.allVehicules = false;
        this.repport.rapportPayloadDto.byGroups = true;
        this.repport.rapportPayloadDto.byVehicules = false;
        break;
      case 'BY_VEHICULE':
        this.repport.rapportPayloadDto.allVehicules = false;
        this.repport.rapportPayloadDto.byGroups = false;
        this.repport.rapportPayloadDto.byVehicules = true;
        break;
      default:
        this.repport.rapportPayloadDto.allVehicules = true;
    }
  }

  loadGeoMarketing() {
    this.loading = true;
    this.geoMarketingService.getGeoMarketing()
      .subscribe(geoMarketing => {
        this.loading = false;
        this.geoMarketing = geoMarketing;
      }, err => {
        this.loading = false;
        this.toastr.error("Erreur lors de chargements", "Secteur")
      })
  }

  /**get vehicule  Ids */
  getVehicules() { 
    this.groups.forEach(group => {
      group.vehicules.forEach(vehicule => {
        let v = new VehiculesDto();
        v.matricule = vehicule.matricule;
        v.idVehicule = vehicule.idVehicule;
        if (this.checkIfVehiculeExists(v)) {
          this.vehicules.push(v);
        }
      });
    });
 

    this.vehiculesToItems(this.vehicules);
  }

  /** check if vehicule already exists */
  checkIfVehiculeExists(vehicule: VehiculesDto) {
    let v = this.vehicules.filter(ve => ve.idVehicule === vehicule.idVehicule);
    if (v.length !== 0) {
      return false;
    } else {
      return true;
    }
  }

  mapGroupsToItems(groups: Group[]) {
    this.groupsItems = [];
    for (let i = 0; i < groups.length; i++) {
      let parentName: string;
      if (groups[i].nameGroupParent) {
        parentName = groups[i].nameGroupParent + ' / ' + groups[i].nom;
      } else { parentName = groups[i].nom }
      this.groupsItems.push({
        id: groups[i].idGroupe,
        text: parentName
      }); 
    }
  }

  mapSelectedGroupsToItems(groups: Group[]) {
    this.selectedGroupsItems = [];
    for (let i = 0; i < groups.length; i++) {
      this.selectedGroupsItems.push({
        id: groups[i].idGroupe,
        text: groups[i].nom
      });
    }

    this.selectedGroups(this.selectedGroupsItems);
  }

  selectedGroups(groups: { id: number; text: string }[]) {
    let prefix = "";
    this.selectedGroupsHash = "";
    groups.forEach(group => {
      this.selectedGroupsHash += prefix + group.id;
      prefix = ",";
    });
    this.repport.rapportPayloadDto.idGroups = this.selectedGroupsHash.split(',').map(x => +x);
  }

  vehiculesToItems(vehicules: VehiculesDto[]) {
    this.vehiculesItems = [];
    for (let i = 0; i < vehicules.length; i++) {
      this.vehiculesItems.push({

        id: vehicules[i].idVehicule,
        text: vehicules[i].matricule
      });
    }
  }

  itemsToVehicules(
    items: {
      id: {
        id: number;
      };
      text: string;
      parent: number;
    }[]
  ) {
    let vehicules: any[] = [];
    for (let i = 0; i < items.length; i++) {
      vehicules.push({
        idVehicule: items[i].id.id,
        matricule: items[i].text
      });
    }
    this.selectedVehicules(vehicules);

  }

  selectedVehicules(vehicules: { id: number; text: string }[]) {
    let prefix = "";
    this.selectedVehiculesHash = "";
    vehicules.forEach(vehicule => {
      this.selectedVehiculesHash += prefix + vehicule.id;
      prefix = ",";
    });
    this.repport.rapportPayloadDto.idVehicules = this.selectedVehiculesHash.split(',').map(x => +x);
  }


  export() {

    this.repport.type = 'UNVISITED_POI';
    for (let i = 0; i < this.selectedGeoMarketing.pointOfInterests.length; i++) {
      for (let j = 0; j < this.selectedGeoMarketing.pointOfInterests[i].myPois.length; j++) {
        this.repport.rapportPayloadDto.poiAdresses.push(this.selectedGeoMarketing.pointOfInterests[i].myPois[j].name);
      }
    }

    /**downoald file */
    this.loader = true;

    this.exportImport.getRepport(this.repport).subscribe(
      blob => {
        if (blob.size > 0) {
          importedSaveAs(blob, 'Rapport ' + this.exportImport.getReportName(this.repport.type) + '.xlsx');
        } else {
          this.toastr.warning('Aucune donnée entre ces deux dates !', 'Error');
        }
        this.repport.rapportPayloadDto.poiAdresses = [];

        this.loader = false;
      },
      error => {
        this.repport.rapportPayloadDto.poiAdresses = [];

        this.toastr.warning('Aucune donnée entre ces deux dates !', 'Error');
        this.loader = false;
      }
    );

  }
}
