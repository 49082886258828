import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { GeoMarketingInfoDto } from './geo-marketing';
import { GeoMarketingService } from './geo-marketing.service';
import { DataManagementComponent } from '../data-management';
import { ToastrService } from 'ngx-toastr';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { saveAs as importedSaveAs } from "file-saver";
declare var $: any; 
@Component({
    selector: 'app-geo-marketing',
    templateUrl: './geo-marketing.component.html',
    styleUrls: ['./geo-marketing.component.scss'],
    providers: [DataManagementComponent]
})
export class GeoMarketingComponent implements OnInit {

    loading: boolean = false;
    geoMarketing: GeoMarketingInfoDto[] = [];
    selectedGeoMarketing: GeoMarketingInfoDto | null = null;

  selectedGeo: GeoMarketingInfoDto = new GeoMarketingInfoDto();
    selectedPoi: { id: number, name: string }[] = [];

    @ViewChild('geoMarketingDeleteModal', { static: false })
    geoMarketingDeleteModal: ModalDirective;

   

    @ViewChild('geoMarketingFormModal', { static: false }) geoMarketingFormModal: ModalDirective;

    mode: 'ADD' | 'UPDATE' = 'ADD';


    constructor(private parent: DataManagementComponent, private toastr: ToastrService,
        private geoMarketingService: GeoMarketingService) { }

    ngOnInit() {
        this.parent.initAllIcons();
        this.loadGeoMarketing();
    }

    loadGeoMarketing() {
        this.loading = true;
        this.geoMarketingService.getGeoMarketing()
            .subscribe(geoMarketing => {
                this.loading = false;
                this.geoMarketing = geoMarketing;
                this.selectedGeoMarketing = this.geoMarketing[0];
            }, err => {
                this.loading = false;
                this.toastr.error("Erreur lors de chargements", "Secteur")
            })
    }

    /**
     * Afficher modal d'ajout
     */
    openGeoMarketingForm() {
        this.selectedGeo = new GeoMarketingInfoDto();
        this.selectedPoi = [];
        this.mode = 'ADD';
        //this.geoMarketingFormModal.show();
        this.geoMarketingFormModal.show();
    }

    /**
   * Afficher modal de modification
   */
    onUpdate(geoMarketing: GeoMarketingInfoDto) {
        this.selectedGeo = Object.assign({}, geoMarketing);
        this.selectedPoi = [];
        for (let i = 0; i < geoMarketing.pointOfInterests.length; i++) {
            this.selectedPoi.push({
                id: geoMarketing.pointOfInterests[i].idPointClient,
                name: geoMarketing.pointOfInterests[i].name
            });
        }
        this.mode = 'UPDATE';
        this.geoMarketingFormModal.show();
    }

    // ==================================
    // Exporting data of sector
    // ==================================
    loader: boolean = false;
    export() {
        this.loader = true;
        this.geoMarketingService.exportGeoMarketing(this.selectedGeoMarketing).subscribe(
            blob => {
                this.loader = false;
                if (blob.size !== 0) {
                    importedSaveAs(blob, "RAPPORT_SECTEUR.xlsx");
                }
            },
            () => {
                this.loader = false;
                this.toastr.warning("Aucune données à afficher !", "Error");
            }
        );
    }

    closeGeoMarketingForm() {
        this.geoMarketingFormModal.hide();
    }

    onGeoMarketingSaved(geoMarketing: GeoMarketingInfoDto) {
        this.geoMarketingFormModal.hide(); 
        this.geoMarketing.unshift(geoMarketing);
        if (this.geoMarketing.length > 0)
            this.selectedGeoMarketing = this.geoMarketing[0];
    }

    onGeoMarketingUpdated(geoMarketing: GeoMarketingInfoDto) {
        this.geoMarketingFormModal.hide(); 

        for (let i = 0; i < this.geoMarketing.length; i++) {
            if (this.geoMarketing[i].id == geoMarketing.id) {
                this.geoMarketing[i] = geoMarketing;
                break;
            }
        }
        this.selectedGeoMarketing = this.geoMarketing[0];
    }

    onDelete() {
        if (!this.selectedGeoMarketing) return;
        this.loading = true;
        this.geoMarketingService.delete(this.selectedGeoMarketing.id)
            .subscribe(response => {
                this.loading = false;
                this.geoMarketingDeleteModal.hide();
                if (response) {
                    this.toastr.success("Supprimé", "Secteur");
                    this.loadGeoMarketing();
                } else {
                    this.toastr.error("Erreur lors de suppression", "Secteur");
                }
            }, err => {
                this.loading = false;
                this.toastr.error("Erreur lors de suppression", "Secteur");
            })
    }

    onConfirmDelete() {
        this.geoMarketingDeleteModal.show();
    }
}
