import { Injectable } from '@angular/core';
import * as L from 'leaflet';
import { BehaviorSubject, Observable } from 'rxjs';
import { StopAccident } from 'src/app/shared/model/sinistre.model';

@Injectable({ providedIn: 'root' })
export class StpsService {
    private modeSimulation: boolean = false;
    private clickedMarker: BehaviorSubject<L.Marker | null> = new BehaviorSubject<L.Marker | null>(null);
  
    private markers: L.Marker[] = []; 
    private polylines: L.Polyline[] = []; 

    map: L.Map ;

    zoomHandler = () => this.handelMapZomHight(this.map);



    private hieghtMap = 0.6;

    printStopsHistory(points: StopAccident[], map: L.Map) {
        this.map = map;
        const stopIcon = L.icon({
            iconUrl: 'assets/icons/realtime/stops/record-button.png',
            iconSize: [25, 25],
            iconAnchor: [12, 12],
            popupAnchor: [0, -12]
        });
        this.clearMarkersAndLines();
        let previousPoint: L.LatLng | null = null;
        let firstPoint: L.LatLng | null = null;
        points.forEach((point: StopAccident, index: number) => {
        if (point.stopLatitude && point.stopLongitude) {
            const currentPoint = L.latLng(point.stopLatitude, point.stopLongitude);
            if (index === 0) {
                firstPoint = currentPoint;
            }
            const marker = L.marker(currentPoint, { icon: stopIcon, opacity: index === 0 ? 1 : 0.2 }).addTo(map);
            this.markers.push(marker);
            marker.on('click', () => {
                this.handleMarkerClick(marker, index, map);
            });
            if (previousPoint) {
                const polyline = L.polyline([previousPoint, currentPoint], {
                    color: 'blue',
                    weight: 3,
                    dashArray: '10, 10',
                    opacity: 0.2, 
                }).addTo(map);
                this.polylines.push(polyline);
            }
            previousPoint = currentPoint;
        }
        });
        if (firstPoint) {
        map.setView(firstPoint, 9);
        }
        map.on('zoomend', this.zoomHandler);
    };

    handelMapZomHight(map: L.Map) {
        const bounds = map.getBounds(); 
        const north = bounds.getNorth();
        const south = bounds.getSouth();
        
        this.hieghtMap =  (north - south);
 
    }
    handleMarkerClick(clickedMarker: L.Marker, markerIndex: number, map: L.Map) {
        this.setMarkerOpacity(clickedMarker, 1);
        if (this.clickedMarker.value && this.clickedMarker.value !== clickedMarker) {
            this.setMarkerOpacity(this.clickedMarker.value, 0.2);
        } 
        const newCenterLatLng = this.reformatPosition(map, clickedMarker);
        map.setView(newCenterLatLng, map.getZoom(), {
            animate: true,
            duration: 1
        });
        this.setClickedMarker(clickedMarker);
        this.updatePolylineVisibility(markerIndex);
    };
    onListItemClick(index: number) {
        const marker = this.markers[index];
        marker.fire('click'); 
    };
    hideAllMarkersAndPolylines(excludedMarker: L.Marker | null = null) {
        if(this.modeSimulation){
            this.displaySimulation(excludedMarker);
        }else{
            this.markers.forEach((marker) => {
                if (marker !== excludedMarker) {
                    marker.setOpacity(0); 
                } else {
                    marker.setOpacity(1);
                }
            });
            this.polylines.forEach((polyline) => {
                polyline.setStyle({ opacity: 0 });                    
            });
        }
    };
    displaySimulation(excludedMarker: L.Marker | null = null) {
        this.modeSimulation = true;
        this.markers.forEach((marker) => {
            if (!excludedMarker || marker !== excludedMarker) {
                marker.setOpacity(0.2); 
            } else {
                marker.setOpacity(1);
            }
        });
        this.polylines.forEach((polyline) => {
            polyline.setStyle({ opacity: 0.2 });                    
        });
    };
    clearMarkersAndLines() {
        this.markers.forEach((marker) => {
            marker.remove();
        });
        this.markers = [];
        this.polylines.forEach((polyline) => {
            polyline.remove();
        });
        this.polylines = [];
        this.setClickedMarker(null);
        this.setModeSimulation(false);
    };

    isInSimulation(){
        return this.modeSimulation;
    };
    setModeSimulation(simulation: boolean){
        this.modeSimulation = simulation;
    };
    getClickedMarker(): Observable<L.Marker|null>{
        return this.clickedMarker;
    };
    setClickedMarker(selectedMarker: L.Marker|null){
        this.clickedMarker.next(selectedMarker);
    };

    private setMarkerOpacity(marker: L.Marker, opacity: number) {
        marker.setOpacity(opacity);
    };
    private updatePolylineVisibility(clickedMarkerIndex: number) {
        if(!this.modeSimulation){return};
        this.polylines.forEach((polyline) => {
            polyline.setStyle({ opacity: 0.2 });
        });
        if (clickedMarkerIndex < this.polylines.length) {
            this.polylines[clickedMarkerIndex].setStyle({ opacity: 1 });
        };
    };
    private formatDuration(duration: number): string {
        const hours = Math.floor(duration / 3600);
        const minutes = Math.floor((duration % 3600) / 60);
        const seconds = duration % 60;
        let formattedDuration = '';
        if (hours > 0) {
            formattedDuration += `${hours}h `;
        }
        if (minutes > 0 || hours > 0) {
        formattedDuration += `${minutes}min `;
        }
        if (seconds > 0) {
        formattedDuration += `${seconds}s`;
        }
        return formattedDuration.trim();
    };
    private reformatPosition(map: any, clickedMarker: L.Marker): L.LatLngExpression {        
        return [
            clickedMarker.getLatLng().lat + (this.hieghtMap / 4),
            clickedMarker.getLatLng().lng
        ];  
    };  


}
