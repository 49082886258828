import { Marker } from 'leaflet';
interface CustomMarker extends Marker {
  setRotationOrigin(origin: string): void;
  setRotationAngle(angle: number): void;
}
export class RealTimeRecord {
  deviceid: string;
  idRealTimeRecord: number;
  recordTime: number;
  coordinate: { lat: number; lng: number };
  speed: number;
  temperature: string;
  validity: boolean;
  ignition: boolean;
  power: number;
  mems_x: number;
  mems_y: number;
  mems_z: number;
  sendFlag: number;
  type: 'AA' | 'GPRMC';
  satInView: number;
  signal: string;
  heading: number;
  matricule: string;
  mark: string;
  icon: string;
  realTimeRecordStatus: string;
  geocoding: string;
  geocodingDetails: string;
  relativePosition: string;
  rotationAngle: number;
  fuel: number;
  temp_engine: number;
  rpm: number;
  tfu: number;
  accum_odo: number;
  odo: number;
  fuel_rate: number;
  isInPoi: boolean = false;
  lastStop: number = 0;
  dureeStope: String = '';
  address: string;
  total_time_activity : number;
  lastStopStarTime: Date;
  lastPathStarTime: Date;
  iNsTOP: boolean;
  region :string='';



  //vehicule: Vehicule = null;

  constructor() {
    this.geocoding = `Chargement..`;
    this.geocodingDetails = 'Chargement..';
    this.speed = 0;
    //this.recordTime = new Date();
    this.coordinate = { lat: 0, lng: 0 };
    this.realTimeRecordStatus = '';
  }
}

export class CalTravel {
  deviceId?: number;

  lastId?: number;
  state?: string;
  lastTime?: Date;

  LastPositionLatitude?: number;
  LastPositionLongitude?: number;

  pathStartTime?: Date;
  pathStartPositionLatitude?: number;
  pathStartPositionLongitude?: number;

  pathMaxSpeed?: number;
  pathDistanceDriven?: number;
  PathDuration?: number;
  PathDoubtfulEndTime?: Date;
  reportStart?: boolean;
  stopStartTime?: Date;
  stopPositionLatitude?: number;
  stopPositionLongitude?: number;
  stopDuration?: number;
  StopDoubtfulEndTime?: Date;
  //reportStop?: SVGAnimatedNumber;
  mileageDistanceDriven?: number;

  mileageDuration?: number;
  mileageStartTime?: Date;
  mileageEndTime?: Date;

  constructor() {}
}

export class DataTravel {
  iddevice: string = '';
  dateNow: Date;
  dateStope: Date;

  constructor() {}
}

export class ListStop {
  deviceid: string;
  lastStop: number = 0;
  dureeStope: String = '';
  matricule: String = '';
}

export class MovingMarker {
  marker: CustomMarker | undefined;
  deviceId: number | undefined;
  targetCoordinates: Queue<Coordinates> | undefined;
  constructor() {
    // Initialize targetCoordinates as an empty queue when creating a MovingMarker instance
    this.targetCoordinates = new Queue<Coordinates>();
  }

  isMarkerInitialized(): boolean {
    return this.marker !== undefined;
  }
  clearMouvingMarker() {
    this.marker = undefined;
    this.deviceId = undefined;
    this.targetCoordinates = undefined;
    this.targetCoordinates = new Queue<Coordinates>();
  }
}

export class Coordinates {
  lat: number | undefined;
  lng: number | undefined;
  dateTram: number | undefined;
}

export class Queue<T> {
  private items: T[] = [];

  enqueue(item: T): void {
    // Check if the item already exists in the queue
    if (!this.contains(item)) {
      this.items.push(item);
    }
  }

  dequeue(): T | undefined {
    return this.items.shift();
  }

  peek(): T | undefined {
    return this.items[0];
  }

  isEmpty(): boolean {
    return this.items.length === 0;
  }

  size(): number {
    return this.items.length;
  }

  private contains(item: T): boolean {
    // Check if the item exists in the queue
    return this.items.some((existingItem) => existingItem === item);
  }
}

export interface StateCounts {
  activeCars: RealTimeRecord[];
  desactiveCars: RealTimeRecord[];
  nonValidCars: RealTimeRecord[];
  technicalIssueCars: RealTimeRecord[];
}
