<div class="default-container">
    <div class="default-pic">
        <div>
            <i class="ri-slideshow-view icon"></i>
        </div>
        <div>
            <p class="paragraph-1">Cliquer sur <i class="flaticon-view icon-eyse "> <mat-icon>poll</mat-icon> </i></p>
            <p style="font-size: 20px; color: #919191; margin: 0;">Pour afficher votre Rapport</p>
        </div>
    </div>
</div>