import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import {
  DataManagementService,
  GroupDto,
} from 'src/app/client-management/data-management';
import { CanalDto, CanalInfoDto } from '../../../canals';
import { CanalService } from '../../../canal.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-canal-type-form',
  templateUrl: './canal-type-form.component.html',
  styleUrls: ['./canal-type-form.component.css'],
})
export class CanalTypeFormComponent implements OnInit {
  mode: 'ADD' | 'UPDATE';
  loading: boolean = false;
  currentCanal: CanalInfoDto = new CanalInfoDto();

  groupsItems: { text: string | number; id: number }[] = [];
  groups: GroupDto[] = [];
  error: { errorMessage: string } = { errorMessage: '' };
  groupDropdownSettings = {};
  groupsDropdownSettings = {};
  constructor(
    private route: ActivatedRoute,
    public toastr: ToastrService,
    private router: Router,
    private groupeService: DataManagementService,
    private canalTypeService: CanalService
  ) {}

  ngOnInit() {

    this.route.params.subscribe((params: Params) => {
      this.mode = params.mode;
      this.init(params.id);
    });
    this.groupDropdownSettings = {
      singleSelection: false,
      idField: 'idGroupe',
      textField: 'nom',
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: true,
    };
    this.groupsDropdownSettings = {
      singleSelection: false,
      idField: 'idGroupe',
      textField: 'nom',
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 22,
      allowSearchFilter: true,
    };
  }

  init(typeId: any) {
    if (this.mode == 'ADD') {
      this.loading = true;
      this.groupeService.getAllGroups().subscribe(
        (groups) => {
          this.groups = groups;
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
    }

    if (this.mode == 'UPDATE') {
      this.loading = true;
      forkJoin([
        this.groupeService.getAllGroups(),
        this.canalTypeService.getOneCanal(typeId),
      ]).subscribe(
        ([groups, canalType]) => {
          this.currentCanal = canalType;
          this.groups = groups;
          this.loading = false;
        },
        (error) => {
          this.error = JSON.parse(JSON.stringify(error.error || null));

          if (!this.error.errorMessage) {
            this.error.errorMessage = 'SERVER ERROR';
          }

          this.loading = false;
        }
      );
    }
  }

  cancel() {
    this.router.navigate(['/client/canalPerfomance/parametrage/type']);
  }


  addCanal() {
    this.loading = true;
    let canalType: CanalDto = new CanalDto();
    Object.assign(canalType, this.currentCanal);
    console.log(this.currentCanal.groups);
    canalType.groups = this.currentCanal.groups.map((g) => g.idGroupe);
  
    this.canalTypeService
      .addCanal(canalType)
      .pipe(
        finalize(() => {
          this.loading = false;  // Ensure loading is turned off, regardless of success or failure
        })
      )
      .subscribe(
        (canalType) => {
          this.loading = false;
          this.canalTypeService.canals.push(canalType);
          this.toastr.success('Type de canal bien ajouté !');
          this.cancel();
        },
        (error) => {
          this.error = error;
          this.error = JSON.parse(JSON.stringify(error.error || null));
  
          if (!this.error.errorMessage) {
            this.error.errorMessage = 'SERVER ERROR';
          }
          this.loading = false;
        }
      );
  }



updateCanal() {
  this.loading = true;
  let canalType: CanalDto = new CanalDto();
  Object.assign(canalType, this.currentCanal);
  console.log(this.currentCanal.groups);
  canalType.groups = this.currentCanal.groups.map((g) => g.idGroupe);

  this.canalTypeService
    .updateCanal(canalType)
    .pipe(
      finalize(() => {
        this.loading = false;
      })
    )
    .subscribe(
      (canalType) => {
        this.loading = false;
        for (let i = 0; i < this.canalTypeService.canals.length; i++) {
          if (canalType.idCanal === this.canalTypeService.canals[i].idCanal) {
            this.canalTypeService.canals[i] = canalType;
            break;
          }
        }
        this.cancel();
        this.toastr.success('Type de canal bien modifié !');
      },
      (error) => {
        this.error = error;
        this.error = JSON.parse(JSON.stringify(error.error || null));

        if (!this.error.errorMessage) {
          this.error.errorMessage = 'SERVER ERROR';
        }
        this.loading = false;
      }
    );
}

}
