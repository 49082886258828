import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';
import { dns } from "../../../global.config";
import { createAuthorizationHeader } from "../../../utils/security/headers";
import { User } from 'src/app/authentification/signin/credentials';
import { HttpClient } from '@angular/common/http';
import { CalTravel, RealTimeRecord } from '../model/real-time.model';
import { Poi } from '../../client-management.model';
import { PathConfig } from '../../historical';
import { KmJourPath } from '../model/km-jour.model';
import { DateInterval, DateIntervalDto } from 'src/app/shared/model';
//import {RTRecordAccordingToDMDto, VehiculesAccordingToPoi} from "../overview/overview";

@Injectable()
export class RealtimeRestService {

  pathConfigs: PathConfig[] | null = null;

  dateInterval: { startDate: Date | string; endDate: Date | string };


  constructor(private _http: HttpClient) {

  }
  getRealTimeRecord(deviceId: number): Observable<RealTimeRecord> {
    let headers = createAuthorizationHeader();
    return this._http.get<RealTimeRecord>(dns + 'realTimeRecords/' + deviceId, {headers: headers});
  }

  getAllPois(): Observable<Poi[]> {
    let headers = createAuthorizationHeader();
    return this._http.get<Poi[]>(dns + 'pointInterests/dtos', {headers: headers});
  }

  getAllPathConfigs(): Observable<PathConfig[]>{
    let headers = createAuthorizationHeader();
    return this._http.get<PathConfig[]>(dns + 'pathconfig', {headers: headers});
  }


  getAllRealTimeRecords(): Observable<RealTimeRecord[]> {
    let headers = createAuthorizationHeader();
    let parms = "";
    let currentUser: User = JSON.parse(localStorage.getItem('currentUser') ?? '{}');
    if (!currentUser.isRoot) {
      parms = '?id=' + currentUser.id;
    }

  //   this.dateInterval = {
  //     startDate: new Date(new Date().setHours(0, 0, 0, 0)), // Start of the day
  //     endDate: new Date(new Date().setHours(23, 59, 59, 999)), // End of the day
  // };
    return this._http.get<RealTimeRecord[]>(dns + 'realTimeRecords',{ headers});
  }

  getVehiculesAccordingToPoi(): Observable<any[]> {
    let headers = createAuthorizationHeader();
    return this._http.get<any[]>(dns + 'realTimeRecords/poi', {headers: headers});
  }

  getCurrentState(isBySite : boolean): Observable<any> {
    let headers = createAuthorizationHeader();
    return this._http.get(dns + 'realTimeRecords/currentState?isBySite='+isBySite, {headers: headers});
  }

  getcaltravel(): Observable<CalTravel[]> {
    let headers = createAuthorizationHeader()
    return this._http.get<any[]>(dns + 'caltravel/', {headers: headers});
  }

  startEngine(idBoitier: number): Observable<Boolean> {
    let headers = createAuthorizationHeader()
    return this._http.post<Boolean>(dns + 'engine/start/' + idBoitier, null, {headers: headers});
  }

  stopEngine(idBoitier: number): Observable<Boolean> {
    let headers = createAuthorizationHeader()
    return this._http.post<Boolean>(dns + 'engine/stop/' + idBoitier, null, {headers: headers});
  }

  getVehiculesAlias(vehicules: any[]): string {
    let alias = '';
    let prefix = ''
    for (var i = 0; i < vehicules.length; i++) {
      if (vehicules[i].alias && vehicules[i].alias != "null") {
        alias += prefix + vehicules[i].alias;
      } else if (vehicules[i].matricule && vehicules[i].matricule != "null") {
        alias += prefix + vehicules[i].matricule;
      } else {
        alias += prefix + vehicules[i].idDevice;
      }
      prefix = ', '
    }
    return alias;
  }

  getVehiculeAlias(vehicule: any): string {
    let alias = '';
    if (vehicule.alias && vehicule.alias != "null") {
      return vehicule.alias;
    } else if (vehicule.matricule && vehicule.matricule != "null") {
      return vehicule.matricule;
    } else {
      return vehicule.idDevice + '';
    }
  }
  getRealTimeKmJour(deviceId: number, dateInterval: DateInterval): Observable<KmJourPath> {
    let headers = createAuthorizationHeader();
    return this._http.post<KmJourPath>(dns + 'realTimeRecords/pathsDay/' + deviceId, dateInterval, {headers: headers});
  }
}
