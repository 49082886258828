<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '0px',fullScreenBackdrop:true }"></ngx-loading>

<div class="row  ">

    <div class="col-md-12">

        <!-- Error Section !-->
        <ng-container *ngIf="error.errorMessage">
            <div class="col-md-12">
                <div class="alert alert-danger alert-dismissible" style="border-radius: 0px;text-align: center">
                    <button type="button" class="close" data-dismiss="alert" aria-hidden="true">
                        ×
                    </button>
                    <h4 style="color: white">
                        <i class="fa fa-exclamation-triangle"></i> Attention !
                    </h4>
                    {{error.errorMessage}}
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="mode == 'SHOW' && !loading && !error">
            <div class="box box-widget">
                <div class="box-header with-border">


                    <div class="user-block">
                        <div class="col-md-1">

                            <ngx-avatar *ngIf="currentGroupe.nom" class="img-circle" size="100"
                                [name]="currentGroupe.nom"></ngx-avatar>

                        </div>
                        <div class="col-md-8">
                            <span class="username">
                                <a> {{currentGroupe?.nom}} </a>
                                <img *ngIf="currentGroupe.imageUrl" src="{{url+currentGroupe.imageUrl}}" />
                            </span>
                        </div>

                    </div>


                    <div class="box-tools">

                        <button [routerLink]="['/client/dataManagement/groupe/form/',currentGroupe.idGroupe,'UPDATE']"
                            type="button" class="btn btn-warning btn-xs">
                            <i class="fa fa-edit"></i> Modifier
                        </button>

                    </div>

                </div>

                <div class="box-body">

                    <table class="table table-fixed table-striped table-hover table-condensed"
                        *ngIf="currentGroupe.vehicules && currentGroupe.vehicules.length > 0">
                        <thead>
                            <tr>
                                <th style="width:6%">Équipement</th>
                                <th style="width:6%">Matricule</th>
                                <th style="width:6%">Surnom</th>
                                <th style="width:6%">Marque</th>
                                <th style="width:6%">Sous marque</th>
                                <th style="width:6%">V Max</th>
                                <th style="width:6%">Conducteur</th>
                                <th style="width:10%">Mise en circulation</th>
                                <th style="width:20%">E-mails</th>
                                <th style="width:20%">

                                </th>
                                <th style="width:25%">

                                </th>
                            </tr>
                        </thead>
                        <tbody>

                            <ng-container>
                                <tr *ngFor="let vehicule of currentGroupe.vehicules">
                                    <td>{{vehicule?.device?.idDevice}}</td>
                                    <td>{{vehicule.matricule | noValue}}</td>
                                    <td>{{vehicule.alias | noValue}}</td>
                                    <td>{{vehicule.mark | noValue}}</td>
                                    <td>{{vehicule.subMark | noValue}}</td>
                                    <td>{{vehicule.maxSpeed | noValue}}</td>
                                    <td *ngIf="vehicule.driver">{{vehicule.driver.lastName}}</td>
                                    <td *ngIf="!vehicule.driver">...</td>
                                    <td>{{vehicule.circulationAt | date: 'yyyy-MM-dd HH:mm:ss'}}</td>
                                    <td>{{vehicule.emails | noValue}}</td>

                                    <td>
                                        <div *ngIf="vehicule.icon">
                                            <img src="{{url+vehicule.icon}}" width="30px" />
                                        </div>
                                    </td>

                                    <td style="width:25%">

                                        <a style="margin-left: 5px;" class="pull-right"
                                            [routerLink]="['/client/dataManagement/vehicule/form/', vehicule.idVehicule, 'UPDATE']"
                                            routerLinkActive="active">
                                            <button style="margin-left: 5px;" class="pull-right"
                                                (click)="goToVehiculeUpdate()" tooltip="Modifier"
                                                class="btn btn-warning">
                                                <i class="fa fa-cog" aria-hidden="true"></i>
                                            </button>
                                        </a>

                                        <a class="pull-right"
                                            [routerLink]="['/client/dataManagement/vehicule/form/', vehicule.idVehicule, 'SHOW']"
                                            routerLinkActive="active">
                                            <button (click)="goToVehiculeDetail()" tooltip="Details"
                                                class="btn ink-reaction btn-success">
                                                <i class="fa fa-eye" aria-hidden="true"></i>
                                            </button>
                                        </a>

                                    </td>
                                </tr>
                            </ng-container>


                        </tbody>
                    </table>

                    <ng-container *ngIf="currentGroupe.vehicules && currentGroupe.vehicules.length == 0">
                        <div class="alert alert-warning alert-dismissible"
                            style="border-radius: 0px;text-align: center">
                            <button type="button" class="close" data-dismiss="alert" aria-hidden="true">
                                ×
                            </button>
                            <h4 style="color: white">
                                <i class="fa fa-exclamation-triangle"></i> Attention !
                            </h4>
                            Ce groupe ne contient aucun vehicule !
                        </div>
                    </ng-container>
                </div>

            </div>
        </ng-container>
        <div class="container">
            <ng-container *ngIf="(mode == 'ADD' || mode == 'UPDATE' )&& !loading">
                <div class=" mt-2 mb-2 text-center   well bgg">
                    <h4 *ngIf="mode == 'ADD'"
                        style="font-family: 'Inconsolata', monospace;font-weight: bold;color: white;">
                        <i class="fa fa-plus-circle add"></i>
                        <span>Ajouter un nouveau groupe </span>
                    </h4>

                    <h4 *ngIf="mode == 'UPDATE'"
                        style="font-family: 'Inconsolata', monospace;font-weight: bold;color:white;">
                        <i class="fa fa-cog"></i>
                        <span>Modifier groupe </span>
                    </h4>
                </div>
                <div class="col-lg-12 well formBC">
                    <div class="row">
                        <form #groupForm="ngForm" class="form-horizontal">
                            <div class="col-sm-12">

                                <!-- nom & icon -->
                                <div class="form-group mrg">
                                    <label>Nom</label>
                                    <input [(ngModel)]="currentGroupe.nom" required name="nom" type="text"
                                        placeholder="Nom" class="form-control">
                                </div>


                                <div class="form-group mrg">
                                    <label>Icone</label>
                                    <div class="dropdown">

                                        <button class="btn btn-default dropdown-toggle" type="button"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"
                                            style="border-radius: 0px;">
                                            <img src="{{imagesCarsDir}}{{selectedImage.uri}}" width="17px" />
                                            {{selectedImage.text}}
                                            <span class="caret"></span>
                                        </button>

                                        <ul class="dropdown-menu" style="top: auto;top: 100%;border-radius: 0px;">
                                            <li *ngFor="let image of images" (click)="selectedImage = image;">
                                                <a>
                                                    <img src="{{imagesCarsDir}}{{image.uri}}" width="17px" />
                                                    {{image.text}}
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="form-group mrg">
                                    <label>Véhicules</label>
                                    <!-- <ng-select [multiple]="true" [items]="vehiculesItems" placeholder="select vehicule"
                                        [active]="vehiculesSelected" (data)="selectedVehicules($event)">
                                    </ng-select> -->
                                    <ng-multiselect-dropdown-angular7
                                    name="groupSel"
                                    [data]="vehiculesItems"
                                    placeholder="select Véhicule"
                                    [(ngModel)]="vehiculesSelected"
                                    [settings]="vehiculesDropdownSettings">
                                    </ng-multiselect-dropdown-angular7>


                                    <ng-multiselect-dropdown
                                    [placeholder]="'personnalisé'"
                                    [settings]="dropdownSettings"
                                    [data]="vehiculesItems"
                                    [(ngModel)]="vehiculesSelected"
                                    (onSelect)="onItemSelect($event)"
                                  >
                                  </ng-multiselect-dropdown>
                                  

                                </div>

                                <!-- Parent -->
                                <div class="col-lg-6 col-md-6" style="padding-left: unset;">
                                    <div class="form-group mrg">
                                        <label>Parent</label>
                                        <div>
                                            <select class="form-control" [(ngModel)]="currentGroupe.parent.idGroupe"
                                                name="parent">
                                                <option style="font-size: 14px; font-weight: bold;"
                                                    [ngValue]="null">Sans parent
                                                </option>
                                                <option style="font-size: 14px;" *ngFor="let parent of parents"
                                                    [ngValue]="parent.idGroupe">{{parent.nom}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- ========================== Modal FOOTER (START) ===============================-->
                            <div class="box-footer">
                                <button (click)="addGroup()" *ngIf="mode == 'ADD'" class="btn btn-info pull-right"
                                    [disabled]="!groupForm.valid">
                                    <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Valider
                                </button>
                                <button (click)="updateGroup()" *ngIf="mode == 'UPDATE'" class="btn btn-info pull-right"
                                    [disabled]="!groupForm.valid">
                                    <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Enregistrer
                                </button>
                                <button class=" btn btn-dark" (click)="cancel()">
                                    Annuler
                                </button>
                            </div>
                            <!-- ========================== Modal FOOTER (END) ===============================-->
                        </form>
                    </div>
                </div>

            </ng-container>
        </div>
    </div>

</div>
