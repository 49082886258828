import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { DataManagementService } from "../../data-management/data-management.service";
import { MapService } from "../../../utils/leaflet/service/map.service";
import { GeocodingService } from "../../../utils/leaflet/service/geocoding.service";
import { PathService } from "../path/path.service";
import { DatePipe } from "@angular/common";
import { Subscription } from "rxjs";
import {
  Group,
  Vehicule,
  PointInterest,
  RapportDto,
  PoiClient
} from "../../data-management/data-management.model";
import { imagesDir } from "../../../global.config";
import { Stop } from "./stop";

import * as L from 'leaflet';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ModalDirective } from "ngx-bootstrap/modal";
import { saveAs as importedSaveAs } from "file-saver";
import { ToastrService } from "ngx-toastr";
import { Poi } from '../../client-management.model';
import { SigninService } from 'src/app/authentification/signin/signin.service';
import {Icon, Marker} from "leaflet";

@Component({
  selector: "app-stop",
  templateUrl: "stop.component.html",
  styleUrls: ["stop.component.css"]
})
export class StopComponent implements OnInit, OnDestroy {
  loaded$: Subscription;
  startDate: Date = new Date();
  endDate: Date = new Date();

  repport: RapportDto = new RapportDto();

  AllGroups: Subscription;
  AllPaths: Subscription;

  pois: Poi[];
  groups: Group[];

  selectedGroup: Group = new Group();
  selectedVehicule: Vehicule = new Vehicule();

  stopClicked: Stop;
  stops: Stop[] = [];

  StopArray: any[] = [];

  isResultLoaded: boolean = true;
  showResultTable: boolean = false;

  pointInterest: PointInterest = new PointInterest();
  poiClient: PoiClient = new PoiClient();

  @ViewChild("childModal", { static: false }) public childModal: ModalDirective;
  @ViewChild("childModal2", { static: false }) public childModal2: ModalDirective;

  constructor(
    public dataManagementService: DataManagementService,
    private mapService: MapService,
    private geocodingService: GeocodingService,
    private pathService: PathService,
    public toastr: ToastrService,
    private datePipe: DatePipe,
    private signinService: SigninService,
    private localeService: BsLocaleService
  ) {
    this.localeService.use("fr");

    this.startDate.setTime(this.startDate.getTime() - 3 * 86400000);
    this.startDate.setHours(0, 0, 0, 0);
    this.endDate.setHours(23, 59, 59, 59);

    this.loaded$ = this.mapService.mapLoaded.subscribe(() => {
      this.mapService.map.removeLayer(this.mapService.baseMaps.OpenStreetMap);
      this.mapService.map.addLayer(this.mapService.baseMaps['Google Sat']);
    });
  }

  ngOnInit() {
    if (this.dataManagementService.groups) {
      this.groups = this.dataManagementService.groups.filter(
        group => group.vehicules.length > 0
      );
      if (this.dataManagementService.selectedGroup) {
        this.selectedGroup = this.dataManagementService.selectedGroup;
        if (this.dataManagementService.selectedVehicule) {
          this.selectedVehicule = this.dataManagementService.selectedVehicule;
        } else if (this.selectedGroup.vehicules.length > 0) {
          this.selectedVehicule = this.selectedGroup.vehicules[0];
        }
      } else {
        if (this.groups.length > 0 && this.groups) {
          this.selectedGroup = this.groups[0];
          this.dataManagementService.selectedGroup = this.selectedGroup;
        }
      }
      this.selectedVehicule = this.dataManagementService.selectedVehicule;
    } else {
      this.AllGroups = this.dataManagementService
        .getAllGroupsDetails("")
        .subscribe(groups => {
          this.groups = groups.filter(group => group.vehicules.length > 0);
          this.dataManagementService.groups = groups;
          this.dataManagementService.setGroups(groups);
          if (this.groups.length > 0 && this.groups) {
            this.selectedGroup = this.groups[0];
            this.dataManagementService.selectedGroup = this.selectedGroup;
            if (this.selectedGroup && this.selectedGroup.vehicules.length > 0) {
              this.selectedVehicule = this.selectedGroup.vehicules[0];
              this.dataManagementService.selectedVehicule = this.selectedVehicule;
            }
          }
        });
    }
  }

  ngOnDestroy() {
    if (this.AllGroups) {
      this.AllGroups.unsubscribe();
    }
    if (this.AllPaths) {
      this.AllPaths.unsubscribe();
    }
    if(this.loaded$){this.loaded$.unsubscribe()}
  }
  distance(lat1: any, lon1: any, lat2: any, lon2: any) {
    var p = 0.017453292519943295; // Math.PI / 180
    var c = Math.cos;
    var a =
      0.5 -
      c((lat2 - lat1) * p) / 2 +
      (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;
    var distance = 12742 * Math.asin(Math.sqrt(a));
    return distance;
  }

  addStopToArray(stop: any, event: any) {
    if (!event.ctrlKey) {
      this.StopArray = [];
      this.mapService.removeMarkersFromMap();
    }

    this.StopArray.push(stop);
    this.drawStop(stop);
  }

  StopSelected(stop: any) {
    return this.StopArray.indexOf(stop) !== -1;
  }

  chooseGroup(group: any) {
    this.dataManagementService.selectedGroup = group;
    this.selectedGroup = group;
    if (this.selectedGroup.vehicules.length > 0 && this.selectedGroup) {
      this.selectedVehicule = this.selectedGroup.vehicules[0];
      this.dataManagementService.selectedVehicule = this.selectedVehicule;
    }
  }

  chooseVehicule(vehicule: any) {
    this.selectedVehicule = vehicule;
    this.dataManagementService.selectedVehicule = vehicule;
  }

  getAllStops() {
    this.showResultTable = true;
    this.isResultLoaded = false;
    this.AllPaths = this.pathService
      .getAllStops(this.selectedVehicule.device.idDevice, {
        startDate: this.startDate,
        endDate: this.endDate
      })
      .subscribe(
        stops => {
          if (stops && stops.length) {
            this.toastr.success(
              this.dataManagementService.getVehiculeName(
                this.selectedVehicule
              ) +
              " a effectuer " +
              stops.length +
              " arrêts entre " +
              this.datePipe.transform(this.startDate, "dd/MM/yyyy HH:mm:ss", '', 'fr-fr') +
              " et " +
              this.datePipe.transform(this.endDate, "dd/MM/yyyy HH:mm:ss", '', 'fr-fr'),
              "info"
            );

            this.stops = stops;
            this.stops.forEach(stop => {
              stop.geocodingDetails = this.dataManagementService.getRelativePosition(
                stop.stopLatitude,
                stop.stopLongitude
              );
              stop.geocoding = stop.geocodingDetails;
              stop.beginStopTime = stop.beginStopTime;
              if (stop.geocodingDetails == null) {
                this.geocodingService
                  .inverseGeoconding(stop.stopLatitude, stop.stopLongitude, 17)
                  .subscribe(
                    adress => {
                      stop.geocoding = this.geocodingService.proccessingNomitamAddress(
                        adress
                      );
                      stop.geocodingDetails = stop.geocoding;

                    },
                    err => {
                      stop.geocodingDetails = null;
                      stop.geocoding = null;
                    }
                  );
              }
            });
          }

          this.showResultTable = true;
          this.isResultLoaded = true;
        },
        err => {
          this.isResultLoaded = true;
          this.showResultTable = true;
        }
      );
  }

  drawStop(stop: Stop) {
    if (
      this.stopClicked == null ||
      this.stopClicked.beginStopTime != stop.beginStopTime
    ) {
      this.stopClicked = new Stop();
      this.stopClicked.beginStopTime = stop.beginStopTime;
      this.stopClicked.endStopTime = stop.endStopTime;
    }

    let marker = new Marker({
      lat: stop.stopLatitude,
      lng: stop.stopLongitude
    });
    let beginStopTime: any = stop.beginStopTime;
    const pointDatePipe = new DatePipe(beginStopTime);
    let popup =
      "<b>Date début d'arrêt: </b>" +
      pointDatePipe.transform(stop.beginStopTime, "dd/MM/yyyy HH:mm:ss", '', 'fr-fr') +
      "<br><b>Durée d'arrêt: </b>" +
      stop.stopDurationStr +
      "<br><hr><b>" +
      stop.geocodingDetails +
      "</b>";
    marker.setIcon(
      new Icon({
        iconUrl: imagesDir + "endMarker.png",
        iconAnchor: [-2, 30],
        popupAnchor: [10, -25]
      })
    );

    marker.bindPopup(popup);

    marker.on("click", () => {
      this.mapService.map.setView(
        { lat: stop.stopLatitude, lng: stop.stopLongitude },
        17
      );
    });

    marker.on("add", () => {
      marker.openPopup();
    });

    this.mapService.addMarker(marker);

    this.mapService.map.setView(
      { lat: stop.stopLatitude, lng: stop.stopLongitude },
      15
    );
  }

  // ==================================
  // Exporting data of Stops
  // ==================================
  exportStops() {
    let driverName = "";
    if (this.selectedVehicule.driver) {
      driverName = this.selectedVehicule.driver.lastName + " " + this.selectedVehicule.driver.firstName;
    }
    this.pathService.exportStops(this.stops, this.startDate.getTime(), this.endDate.getTime(), this.selectedVehicule.matricule, driverName, this.selectedGroup.nom).subscribe(
      blob => {
        if (blob.size !== 0) {
          importedSaveAs(blob, "RAPPORT_ARRETS.xlsx");
        }
      },
      () => {
        this.toastr.error("Aucune données à afficher !", "Error");
      }
    );
  }

  exportStopsPDF() {
    let driverName = "";
    if (this.selectedVehicule.driver) {
      driverName = this.selectedVehicule.driver.lastName + " " + this.selectedVehicule.driver.firstName;
    }
    this.pathService.exportStopsPDF(this.stops, this.startDate.getTime(), this.endDate.getTime(), this.selectedVehicule.matricule, driverName, this.selectedGroup.nom).subscribe(
      blob => {
        if (blob.size !== 0) {
          importedSaveAs(blob, "RAPPORT_ARRETS.pdf");
        }
      },
      () => {
        this.toastr.error("Aucune données à afficher !", "Error");
      }
    );
  }

  tableToggle() {
    this.showResultTable = !this.showResultTable;
  }

  isSelectedStop(stop: Stop) {
    let i;
    for (i = 0; i < this.StopArray.length; i++) {
      if (this.StopArray[i] === stop) {
        return true;
      }
    }

    return false;
  }

  addPOI(stop: Stop) {
    this.pointInterest = new PointInterest();
    this.pointInterest.type = 0;
    this.pointInterest.coordinate = {
      lat: stop.stopLatitude,
      lng: stop.stopLongitude
    };
    this.pointInterest.address = stop.geocodingDetails;
    this.childModal.show();
  }

  addClient(stop: Stop) {
    this.poiClient = new PoiClient();
    this.poiClient.type = 0;
    this.poiClient.coordinate = {
      lat: stop.stopLatitude,
      lng: stop.stopLongitude
    };
    this.poiClient.address = stop.geocodingDetails;
    this.childModal2.show();
  }

  // To display google adress
  displayGoogleAdress(stop: Stop) {
    if (stop) {
      this.geocodingService
        .inverseGeocondingGoogle(stop.stopLatitude, stop.stopLongitude)
        .subscribe(response => {
          if (response && response.results[0]) {
            stop.geocoding = response.results[0].formatted_address;
            response.results.forEach((res: { formatted_address: string; }) => {
              stop.geocodingDetails += res.formatted_address + " ,";
            });
          }
        });
    }
  }
}
