import { EventEmitter, Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
//import { PDFDocument } from 'pdf-lib';
import { BehaviorSubject, Observable } from 'rxjs';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root',
})
export class NewImportExportService {
  private pdfSrc: BehaviorSubject<SafeResourceUrl | null> =
    new BehaviorSubject<SafeResourceUrl | null>(null);
  private pdf: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private excelData: BehaviorSubject<unknown[] | any[][] | null> =
    new BehaviorSubject<unknown[] | any[][] | null>(null);
  private downloadLink: BehaviorSubject<SafeResourceUrl | null> =
    new BehaviorSubject<SafeResourceUrl | null>(null);
  private curentRapport: BehaviorSubject<string | null> = new BehaviorSubject<
    string | null
  >(null);
  private showPdf: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  private refreshTrigger: BehaviorSubject<void> = new BehaviorSubject<void>(
    undefined
  ); // ou new BehaviorSubject<void>()

  public showPdfEmitter = new EventEmitter<void>();

  constructor(private sanitizer: DomSanitizer) {}

  isPdfObs(): Observable<boolean> {
    return this.pdf.asObservable();
  }
  isPdf(): boolean {
    return this.pdf.value;
  }
  setPdfExistince(isExist: boolean): void {
    this.pdf.next(isExist);
  }

  getCurentRapport(): string | null {
    return this.curentRapport.value;
  }
  getRapport(): Observable<string | null> {
    return this.curentRapport.asObservable();
  }
  setCurentRapport(rapport: string | null): void {
    this.curentRapport.next(rapport);
  }

  getPdfToBePrinted(): Observable<SafeResourceUrl | null> {
    return this.pdfSrc;
  }
  getDonloadPrinted(): Observable<SafeResourceUrl | null> {
    return this.downloadLink;
  }
  setPdfToBePrinted(blob: Blob | null): void {
    if (blob) {
      //  this.loadAndModifyPdf(blob);
    } else {
      this.pdfSrc.next(null);
      this.downloadLink.next(null);
    }
  }

  getShowPdf(): Observable<boolean> {
    return this.showPdf;
  }
  setShowPdf(show: boolean): void {
    this.showPdf.next(show);
  }

  // private async loadAndModifyPdf(pdfBlob: Blob): Promise<void> {
  //   this.blobToUint8Array(pdfBlob).then(async (pdfData) => {
  //     const pdfDoc = await PDFDocument.load(pdfData);
  //     const pdfBytes = await pdfDoc.save();
  //     const updatedBlob = new Blob([pdfBytes], { type: 'application/pdf' });
  //     const downloadURL = URL.createObjectURL(updatedBlob);
  //     this.pdfSrc.next(
  //       this.sanitizer.bypassSecurityTrustResourceUrl(
  //         downloadURL + '#view=FitV' + '&toolbar=1' + '&nameddest=test'
  //       )
  //     );
  //     this.downloadLink.next(
  //       this.sanitizer.bypassSecurityTrustResourceUrl(downloadURL)
  //     );
  //   });
  // }

  getExcelData(): Observable<unknown[] | any[][] | null> {
    return this.excelData.asObservable();
  }
  setExcelData(data: unknown[] | null | any[][]): void {
    this.excelData.next(data);
  }

  setExcelToBeProcessed(blob: Blob | null): void {
    if (blob) {
      this.loadAndModifyExcel(blob);
    } else {
      this.excelData.next(null);
      this.downloadLink.next(null);
    }
  }

  private async loadAndModifyExcel(excelBlob: Blob): Promise<void> {
    this.blobToUint8Array(excelBlob).then((excelData) => {
      // Lire le fichier Excel
      const workbook = XLSX.read(excelData, { type: 'array' });

      // Vérifier si le fichier contient une ou plusieurs feuilles
      if (workbook.SheetNames.length === 1) {
        // Cas où il y a une seule feuille
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        this.excelData.next(jsonData);

        // Créer un nouveau Blob Excel à partir des données
        const newExcelBlob = this.createExcelBlob(workbook);
        const downloadURL = URL.createObjectURL(newExcelBlob);

        // Mettre à jour les liens pour le téléchargement
        this.pdfSrc.next(
          this.sanitizer.bypassSecurityTrustResourceUrl(downloadURL)
        );
        this.downloadLink.next(
          this.sanitizer.bypassSecurityTrustResourceUrl(downloadURL)
        );
      } else {
        // Cas où il y a plusieurs feuilles
        const allSheetData: any[] = [];

        // Parcours de chaque feuille dans le classeur
        workbook.SheetNames.forEach((sheetName) => {
          const worksheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

          // Ajouter les données de la feuille dans allSheetData
          allSheetData.push({
            sheetName: sheetName,
            data: jsonData,
          });
        });

        // Mettre à jour excelData avec les données de toutes les feuilles
        this.excelData.next(allSheetData);

        // Créer un nouveau Blob Excel à partir des données (même si on a plusieurs feuilles)
        const newExcelBlob = this.createExcelBlob(workbook);
        const downloadURL = URL.createObjectURL(newExcelBlob);

        // Mettre à jour les liens pour le téléchargement
        this.pdfSrc.next(
          this.sanitizer.bypassSecurityTrustResourceUrl(downloadURL)
        );
        this.downloadLink.next(
          this.sanitizer.bypassSecurityTrustResourceUrl(downloadURL)
        );
      }
    });
  }

  private createExcelBlob(workbook: XLSX.WorkBook): Blob {
    const workbookOut = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    return new Blob([workbookOut], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
  }

  private blobToUint8Array(blob: Blob): Promise<Uint8Array> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () =>
        resolve(new Uint8Array(reader.result as ArrayBuffer));
      reader.onerror = reject;
      reader.readAsArrayBuffer(blob);
    });
  }

  getRefreshTrigger(): Observable<void> {
    return this.refreshTrigger.asObservable();
  }

  forceEmitRefresh(): void {
    this.refreshTrigger.next(); // Réémet le signal
  }
}
