export const iconsCar: any = {
      "rimtel.svg": `
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="191.186 126.752 153.541 220.089" style="enable-background:new 0 0 500 500;">
      <g id="OBJECTS">
         <g transform="matrix(1, 0, 0, 1, 17.822653, -122.620144)" style="">
            <g>
            <path style="fill:#b7ce96;" d="M286.92,300.854c0.415-18.645-0.242-23.81-4.928-28.651c-4.659-4.841-23.969-5.753-32.141-5.753
                  c-8.172,0-27.481,0.912-32.138,5.753c-4.686,4.841-5.362,9.063-4.916,28.651c1.477,26.832-3.259,129.254,4.131,135.976
                  c8.081,6.74,24.539,7.01,32.924,7.01c8.385,0,24.843-0.269,32.927-7.01C290.178,430.108,285.459,327.686,286.92,300.854z"></path>
            <path style="fill:#9cba73;" d="M283.654,305.082c0.345-18.05-0.376-19.731-4.648-24.157c-4.246-4.425-21.736-6.238-29.153-6.238
                  c-7.417,0-24.907,1.812-29.15,6.238c-4.274,4.426-5.035,4.111-4.639,24.157c0.078,4.172,0.65,10.453,1.934,16.648
                  c2.331,5.256,3.615,13.947,4.484,22.411c-0.958,1.379-1.516,4.102-1.513,6.983c-1.258,3.936-0.334,11.956-0.051,17.086
                  c0.016,0.281-0.024,1.173-0.009,1.454c0.014,0-0.901,17.718-0.265,25.538c0.159,1.892,0.545,4.511,0.862,5.098
                  c0.243,2.402-3.173,12.426-2.888,14.271c0,0,0.298,10.922,1.326,11.785c7.37,6.162,22.298,6.408,29.909,6.408
                  c7.611,0,22.539-0.247,29.912-6.408c1.028-0.863,1.331-11.785,1.331-11.785c0.286-1.845-3.128-11.869-2.885-14.271
                  c0.482-1.387,0.704-3.206,0.863-5.098c0.637-7.82-0.275-25.538-0.261-25.538c0.016-0.28-0.025-1.173-0.009-1.454
                  c0.284-5.131,1.208-13.151-0.05-17.086c0.004-2.881-0.555-5.603-1.513-6.983c0.868-8.464,2.152-17.155,4.482-22.411
                  C283.006,315.535,283.577,309.255,283.654,305.082z"></path>
            <g>
               <path style="fill:#b7ce96;" d="M238.851,302.655c-2.069-11.678-4.105-18.37-6.15-30.052c0.804-0.128,1.607-0.255,2.411-0.383
                     C236.364,284.03,237.602,290.849,238.851,302.655z"></path>
               <path style="fill:#b7ce96;" d="M260.878,302.655c1.245-11.805,2.48-18.625,3.731-30.435c0.803,0.128,1.607,0.255,2.411,0.383
                     C264.977,284.285,262.943,290.977,260.878,302.655z"></path>
            </g>
            <g>
               <path style="fill:#282F3A;" d="M220.918,368.21c0.046-7.82,0.062-10.13,0.049-17.951c-1.632-8.915-3.304-17.83-5.036-26.744
                     c-0.833,9.595-0.766,27.926-0.729,44.695C217.107,368.21,219.013,368.21,220.918,368.21z"></path>
               <path style="fill:#282F3A;" d="M215.208,369.664c0.084,17.539,0.247,32.667,0.25,32.667c1.738-2.376,3.467-4.752,5.186-7.129
                     c0.123-8.512,0.212-17.025,0.265-25.538C219.009,369.664,217.109,369.664,215.208,369.664z"></path>
            </g>
            <path style="fill:#b7ce96;" d="M249.859,310.912c0.012,0-18.738-0.238-32.367,9.875c1.559,7.47,3.075,14.94,4.562,22.411
                  c0,0,7.497-6.469,27.807-6.469c20.311,0,27.808,6.469,27.808,6.469c1.486-7.471,3.002-14.941,4.559-22.411
                  C268.597,310.674,249.848,310.912,249.859,310.912z"></path>
            <path style="fill:#282F3A;" d="M249.859,311.855c0.011,0-18.442-0.238-31.862,9.875c1.532,7.47,3.023,14.94,4.484,22.411
                  c0,0,7.383-6.469,27.38-6.469c19.997,0,27.38,6.469,27.38,6.469c1.461-7.471,2.951-14.941,4.482-22.411
                  C268.302,311.617,249.848,311.856,249.859,311.855z"></path>
            <g>
               <path style="fill:#3A454F;" d="M278.804,368.21c-0.045-7.82-0.061-10.13-0.049-17.951c1.632-8.915,3.303-17.83,5.034-26.744
                     c0.835,9.595,0.769,27.926,0.731,44.695C282.615,368.21,280.71,368.21,278.804,368.21z"></path>
               <path style="fill:#3A454F;" d="M284.514,369.664c-0.085,17.539-0.252,32.667-0.255,32.667c-1.738-2.376-3.466-4.752-5.185-7.129
                     c-0.121-8.512-0.208-17.025-0.261-25.538C280.713,369.664,282.614,369.664,284.514,369.664z"></path>
            </g>
            <path style="fill:#282F3A;" d="M278.211,400.3c-6.844,1.902-49.861,1.902-56.705,0c0.007,0-3.389,8.637-2.888,14.271
                  c4.628,2.863,14.56,6.5,31.238,6.5c16.678,0,26.611-3.637,31.24-6.5C281.598,408.936,278.204,400.3,278.211,400.3z"></path>
            <path style="fill:#3A454F;" d="M277.241,344.141c1.461-7.47,2.951-14.941,4.482-22.411c-13.422-10.113-31.875-9.875-31.864-9.875
                  c0.001,8.605,0.002,17.21,0.002,25.816C269.858,337.672,277.242,344.141,277.241,344.141z"></path>
            <path style="fill:#3A454F;" d="M278.211,400.3c-0.231,0.064-0.482,0.121-0.745,0.173c-0.07,0.014-0.16,0.026-0.235,0.04
                  c-0.155,0.027-0.306,0.057-0.471,0.081c-4.711,0.755-15.796,1.134-26.903,1.134c-0.001,6.449-0.002,12.897-0.003,19.345
                  c16.678,0,26.611-3.637,31.24-6.5C281.598,408.937,278.204,400.3,278.211,400.3z"></path>
            <path style="fill:#282F3A;" d="M249.851,266.45c-2.913,0-7.244,0.117-11.82,0.486c0.52,0.695,1.036,1.394,1.53,2.118
                  c0.341,0.495,20.241,0.495,20.581,0c0.494-0.724,1.009-1.424,1.529-2.118C257.095,266.567,252.765,266.45,249.851,266.45z"></path>
            <g>
               <path style="fill:#BDD6DB;" d="M218.703,273.716c-0.08,0.144-0.158,0.29-0.229,0.442
                     C218.545,274.006,218.624,273.861,218.703,273.716z"></path>
               <path style="fill:#BDD6DB;" d="M219.364,272.704c2.961-3.799,8.992-4.354,9.609-4.615c0.036-0.015,0.06-0.048,0.092-0.069
                     c-4.605,0.793-8.639,1.962-10.77,3.661C218.68,271.935,219.034,272.248,219.364,272.704z"></path>
               <path style="fill:#EC9100;" d="M219.365,272.705L219.365,272.705c-0.245,0.313-0.463,0.652-0.661,1.011
                     C218.903,273.359,219.12,273.018,219.365,272.705z"></path>
               <path style="fill:#EC9100;" d="M216.15,276.411c0.111,0.038,0.224,0.063,0.336,0.074c0.661,0.068,1.332-0.314,1.501-0.985
                     c0.085-0.339,0.198-0.656,0.322-0.962c0.052-0.13,0.108-0.255,0.166-0.379c0.072-0.152,0.149-0.298,0.229-0.442
                     c0.198-0.359,0.416-0.698,0.661-1.012c-0.33-0.456-0.684-0.77-1.068-1.023c-0.212,0.169-0.411,0.341-0.583,0.521
                     c-1.009,1.043-1.832,2.058-2.498,3.203C215.352,275.856,215.675,276.246,216.15,276.411z"></path>
            </g>
            <g>
               <path style="fill:#BDD6DB;" d="M284.485,275.504L284.485,275.504c0.023-0.063,0.053-0.123,0.068-0.189l0,0l0,0.001
                     C284.538,275.381,284.508,275.441,284.485,275.504z"></path>
               <path style="fill:#BDD6DB;" d="M283.949,276.226c-0.108,0.075-0.226,0.139-0.356,0.185
                     C283.723,276.365,283.842,276.302,283.949,276.226z"></path>
               <path style="fill:#BDD6DB;" d="M280.377,272.704c0.324-0.449,0.673-0.759,1.049-1.009c-2.12-1.701-6.144-2.872-10.743-3.667
                     c0.03,0.018,0.05,0.048,0.082,0.061C271.383,268.351,277.415,268.905,280.377,272.704z"></path>
               <path style="fill:#BDD6DB;" d="M284.426,275.666c-0.001,0.003-0.003,0.005-0.005,0.008c-0.054,0.106-0.12,0.206-0.196,0.297
                     c-0.025,0.029-0.056,0.053-0.084,0.08c0.153-0.156,0.267-0.342,0.344-0.547l0,0
                     C284.465,275.558,284.452,275.615,284.426,275.666z"></path>
               <path style="fill:#EC9100;" d="M280.376,272.705c0.24,0.308,0.456,0.64,0.651,0.992c0.086,0.154,0.169,0.311,0.246,0.474
                     c0.055,0.118,0.108,0.236,0.157,0.358c0.126,0.309,0.24,0.628,0.326,0.971C281.482,274.407,280.99,273.491,280.376,272.705
                     C280.377,272.704,280.376,272.704,280.376,272.705z"></path>
               <path style="fill:#EC9100;" d="M283.949,276.226c0.071-0.05,0.131-0.113,0.191-0.174l0,0
                     C284.081,276.113,284.021,276.175,283.949,276.226L283.949,276.226z"></path>
               <path style="fill:#EC9100;" d="M283.257,276.485c-0.33,0.034-0.663-0.045-0.935-0.216
                     C282.593,276.44,282.927,276.519,283.257,276.485z"></path>
               <path style="fill:#EC9100;" d="M284.421,275.674c0.001-0.003,0.003-0.005,0.005-0.008c0.026-0.052,0.039-0.109,0.059-0.163
                     c-0.664-1.202-1.485-2.26-2.493-3.301c-0.168-0.175-0.361-0.343-0.566-0.508c-0.376,0.251-0.724,0.561-1.049,1.009
                     c0.613,0.787,1.105,1.703,1.379,2.795c0.085,0.336,0.295,0.599,0.566,0.77c0.272,0.171,0.604,0.249,0.935,0.216
                     c0.112-0.011,0.224-0.036,0.336-0.074c0.131-0.045,0.248-0.109,0.356-0.185c0.072-0.051,0.132-0.113,0.192-0.175
                     c0.027-0.027,0.059-0.051,0.084-0.08C284.301,275.88,284.367,275.78,284.421,275.674z"></path>
            </g>
            <path style="fill:#9cba73;" d="M249.851,441.211c3.739,0,9.072-0.058,14.557-0.713c-0.027-0.131-0.054-0.263-0.081-0.394
                  c-0.237-1.155-1.337-2.019-2.468-1.961c-7.996,0.414-16.021,0.414-24.017,0c-1.13-0.058-2.231,0.805-2.469,1.961
                  c-0.027,0.132-0.054,0.263-0.081,0.394C240.78,441.154,246.112,441.211,249.851,441.211z"></path>
            <g>
               <path style="fill:#DD3E3E;" d="M230.539,441.608c0.002-0.01,0.005-0.019,0.007-0.029c0.186-0.781-0.288-1.545-1.06-1.729
                     c-2.297-0.549-4.572-1.193-6.818-1.931c-0.75-0.246-1.572,0.151-1.85,0.908c-0.003,0.009-0.007,0.019-0.01,0.028
                     c-0.05,0.137-0.055,0.275-0.065,0.413c2.779,1.396,6.014,2.365,9.376,3.038C230.311,442.114,230.471,441.888,230.539,441.608z"></path>
               <path style="fill:#DD3E3E;" d="M278.887,438.827c-0.278-0.756-1.1-1.154-1.85-0.908c-2.246,0.738-4.522,1.382-6.819,1.931
                     c-0.772,0.184-1.246,0.948-1.06,1.729c0.002,0.01,0.004,0.019,0.007,0.029c0.067,0.281,0.228,0.507,0.42,0.698
                     c3.362-0.673,6.597-1.642,9.377-3.038c-0.01-0.138-0.014-0.276-0.065-0.413C278.894,438.845,278.89,438.836,278.887,438.827z"></path>
            </g>
            <g>
               <path style="fill:#282F3A;" d="M213.722,326.224c-0.061,1.088-0.122,2.176-0.184,3.265c-0.016,0.278,0.114,0.545,0.338,0.698
                     c0.202,0.137,0.404,0.275,0.606,0.412c0.191-1.845,0.38-3.69,0.568-5.535c-0.316,0.179-0.633,0.358-0.949,0.537
                     C213.88,325.725,213.737,325.961,213.722,326.224z"></path>
               <path style="fill:#b7ce96;" d="M213.553,328.422c-2.775,0.7-5.549,1.399-8.322,2.099c-0.645,0.163-1.233-0.481-1.021-1.137
                     c0.373-1.158,1.452-2.734,4.497-3.622c3.074-0.897,4.62,0.084,5.369,1.04C214.529,327.38,214.243,328.248,213.553,328.422z"></path>
            </g>
            <g>
               <path style="fill:#282F3A;" d="M286.431,326.224c0.061,1.088,0.122,2.176,0.184,3.265c0.016,0.278-0.113,0.545-0.338,0.698
                     c-0.202,0.137-0.404,0.275-0.606,0.412c-0.191-1.845-0.38-3.69-0.568-5.535c0.316,0.179,0.633,0.358,0.949,0.537
                     C286.274,325.725,286.417,325.961,286.431,326.224z"></path>
               <path style="fill:#b7ce96;" d="M286.6,328.422c2.775,0.7,5.549,1.399,8.322,2.099c0.645,0.163,1.233-0.481,1.021-1.137
                     c-0.373-1.158-1.452-2.734-4.497-3.622c-3.074-0.897-4.62,0.084-5.369,1.04C285.624,327.38,285.911,328.248,286.6,328.422z"></path>
            </g>
            <g>
               <path style="fill:#FFA312;" d="M230.539,441.608c0.002-0.01,0.005-0.019,0.007-0.029c0.186-0.781-0.288-1.545-1.06-1.729
                     c-0.569-0.136-1.133-0.296-1.699-0.443c-0.078,0.772-0.157,1.544-0.236,2.316c0.844,0.215,1.701,0.41,2.567,0.584
                     C230.311,442.114,230.472,441.888,230.539,441.608z"></path>
               <path style="fill:#FFA312;" d="M269.158,441.579c0.002,0.01,0.004,0.019,0.007,0.029c0.067,0.281,0.227,0.507,0.42,0.698
                     c0.867-0.174,1.723-0.368,2.567-0.584c-0.079-0.772-0.157-1.544-0.235-2.316c-0.566,0.148-1.13,0.307-1.699,0.443
                     C269.447,440.034,268.972,440.798,269.158,441.579z"></path>
            </g>
            <g>
               <g>
                  <path style="fill:#282F3A;" d="M212.854,365.274c-0.004,0-0.008,0-0.013,0c-0.347,0-0.627-0.295-0.626-0.659
                        c0.005-1.307,0.009-2.614,0.012-3.921c0.001-0.364,0.283-0.658,0.629-0.658c0.004,0,0.008,0,0.013,0
                        c0.347,0,0.627,0.295,0.627,0.658c-0.003,1.307-0.007,2.614-0.011,3.921C213.483,364.979,213.201,365.274,212.854,365.274z"></path>
                  <path style="fill:#282F3A;" d="M212.752,374.34c-0.004,0-0.008,0-0.013,0c-0.348,0-0.627-0.295-0.623-0.658
                        c0.013-1.307,0.025-2.614,0.036-3.921c0.003-0.364,0.287-0.659,0.634-0.658c0.004,0,0.008,0,0.013,0
                        c0.347,0,0.627,0.295,0.624,0.658c-0.01,1.307-0.022,2.614-0.034,3.921C213.385,374.045,213.1,374.34,212.752,374.34z"></path>
               </g>
               <g>
                  <path style="fill:#282F3A;" d="M286.882,365.274c-0.004,0-0.008,0-0.013,0c-0.347,0-0.629-0.295-0.631-0.659
                        c-0.005-1.307-0.008-2.614-0.011-3.921c-0.001-0.364,0.28-0.658,0.627-0.658c0.004,0,0.008,0,0.013,0
                        c0.347,0,0.628,0.295,0.629,0.658c0.003,1.307,0.007,2.614,0.012,3.921C287.509,364.979,287.229,365.274,286.882,365.274z"></path>
                  <path style="fill:#282F3A;" d="M286.982,374.34c-0.004,0-0.008,0-0.013,0c-0.348,0-0.633-0.295-0.636-0.658
                        c-0.012-1.307-0.024-2.614-0.034-3.921c-0.003-0.364,0.277-0.659,0.624-0.658c0.004,0,0.008,0,0.013,0
                        c0.348,0,0.631,0.295,0.634,0.658c0.011,1.307,0.022,2.614,0.035,3.921C287.609,374.045,287.33,374.34,286.982,374.34z"></path>
               </g>
            </g>
            <g>
               <path style="fill:#282F3A;" d="M265.579,356.172h-31.005c-0.762-6.338-0.519-4.314-1.281-10.652
                     c11.037-2.605,22.53-2.605,33.567,0C266.098,351.858,266.342,349.834,265.579,356.172z"></path>
               <path style="fill:#3A454F;" d="M265.579,356.172c0.762-6.338,0.519-4.314,1.281-10.652c-5.518-1.302-11.151-1.953-16.783-1.953
                     v12.606H265.579z"></path>
            </g>
            </g>
         </g>
      </g>
      </svg>
      `,
      "van.svg" : `
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="136.343 100.501 187.368 234.743" style="enable-background:new 0 0 500 500;">
            <g id="OBJECTS" style="" transform="matrix(1.554164, 0, 0, 1.192591, -129.573046, -50.2972)">
            <path style="fill:#D8D8D8;" d="M274.088,285.78c-0.016,0.065-0.045,0.126-0.068,0.189
                        C274.043,285.906,274.072,285.846,274.088,285.78L274.088,285.78z"></path>
            <g transform="matrix(1, 0, 0, 1, -147.883517, -129.896853)">
                  <g>
                  <path style="fill: #b7ce96;" d="M414.164,300.854c0.415-18.645-0.242-23.81-4.927-28.651c-4.659-4.841-23.969-5.753-32.141-5.753
                              c-8.172,0-27.481,0.912-32.138,5.753c-4.686,4.841-5.362,9.063-4.916,28.651c1.477,26.832-3.259,129.254,4.131,135.976
                              c8.081,6.74,24.539,7.01,32.924,7.01c8.385,0,24.843-0.269,32.927-7.01C417.423,430.108,412.704,327.686,414.164,300.854z"></path>
                  <path style="fill: #9cba73;" d="M410.899,305.082c0-14.572-0.376-21.486-4.648-25.912c-4.246-4.425-21.736-6.238-29.153-6.238
                              s-24.906,1.812-29.15,6.238c-4.274,4.426-4.639,11.555-4.639,25.912c0,4.173,0.65,10.453,1.934,16.648
                              c2.331,5.256,3.615,13.947,4.484,22.411c-0.958,1.379-1.516,4.102-1.513,6.983c-1.258,3.936-0.334,17.359-0.051,22.489
                              c0.016,0.281-0.024,1.173-0.009,1.453c0.014,0-2.851,22.595-0.265,33.395l0.862,5.595c-0.67,4.068-3.074,8.438-2.888,9.638
                              c0,0,0.298,1.798,1.326,2.66c7.37,6.162,22.298,6.408,29.909,6.408c7.611,0,22.539-0.247,29.912-6.408
                              c1.028-0.863,1.331-2.66,1.331-2.66c0.196-1.266-2.229-5.505-2.885-9.638l0.863-5.595c1.274-8.972-0.275-33.395-0.261-33.395
                              c0.016-0.28-0.025-1.172-0.009-1.453c0.284-5.13,1.208-18.554-0.05-22.489c0.004-2.881-0.555-5.603-1.513-6.983
                              c0.868-8.464,2.152-17.155,4.482-22.411C410.251,315.535,410.822,309.255,410.899,305.082z"></path>
                  <path style="fill:#A2999A;" d="M387.531,320.838c0.568-0.108,1.136-0.215,1.704-0.323c-1.392-9.497-2.779-15.087-4.186-24.291
                              c-0.094-0.613-0.62-1.077-1.24-1.077h-13.415c-0.62,0-1.147,0.464-1.241,1.077c-1.404,9.204-2.789,14.794-4.18,24.291
                              c0.568,0.108,1.136,0.215,1.704,0.323c0.818-9.22,2.015-14.847,2.861-23.523c0.063-0.643,0.601-1.144,1.248-1.144h12.508
                              c0.646,0,1.184,0.499,1.247,1.142C385.4,305.991,386.712,311.618,387.531,320.838z"></path>
                  <path style="fill:#282F3A;" d="M377.104,311.855c0.011,0-18.442-0.238-31.862,9.875c1.532,7.47,3.023,14.94,4.484,22.411
                              c0,0,7.383-6.469,27.38-6.469c19.996,0,27.38,6.469,27.38,6.469c1.461-7.471,2.951-14.941,4.482-22.411
                              C395.547,311.617,377.093,311.856,377.104,311.855z"></path>
                  <g>
                  <g>
                        <path style="fill:#282F3A;" d="M348.163,373.613c0.046-7.82,0.062-15.534,0.049-23.354c-1.632-8.915-3.304-17.83-5.036-26.744
                                    c-0.833,9.595-0.766,33.329-0.729,50.098C344.352,373.613,346.258,373.613,348.163,373.613z"></path>
                        <path style="fill:#282F3A;" d="M342.453,375.066c0.084,17.539,0.247,40.525,0.25,40.524c1.738-2.376,3.467-4.752,5.186-7.129
                                    c0.123-8.512,0.212-24.882,0.265-33.395C346.254,375.066,344.353,375.066,342.453,375.066z"></path>
                  </g>
                  <g>
                        <path style="fill:#3A454F;" d="M406.049,373.613c-0.045-7.82-0.061-15.534-0.049-23.354c1.632-8.915,3.303-17.83,5.034-26.744
                                    c0.835,9.595,0.769,33.329,0.731,50.098C409.86,373.613,407.955,373.613,406.049,373.613z"></path>
                        <path style="fill:#3A454F;" d="M411.759,375.066c-0.085,17.539-0.252,40.525-0.255,40.524
                                    c-1.738-2.376-3.466-4.752-5.185-7.129c-0.121-8.512-0.208-24.882-0.261-33.395
                                    C407.958,375.066,409.859,375.066,411.759,375.066z"></path>
                  </g>
                  </g>
                  <path style="fill:#282F3A;" d="M405.456,414.057c-6.845,1.285-49.861,1.285-56.705,0c0.007,0-3.389,5.833-2.888,9.638
                              c4.628,1.934,14.56,4.39,31.238,4.39c16.678,0,26.611-2.456,31.24-4.39C408.843,419.89,405.449,414.057,405.456,414.057z"></path>
                  <path style="fill:#3A454F;" d="M404.486,344.141c1.461-7.471,2.951-14.941,4.482-22.411
                              c-13.422-10.113-31.875-9.874-31.864-9.875c0.001,8.605,0.002,17.21,0.002,25.816
                              C397.103,337.672,404.486,344.141,404.486,344.141z"></path>
                  <path style="fill:#3A454F;" d="M405.456,414.057c-0.231,0.043-0.482,0.082-0.745,0.116c-0.07,0.009-0.16,0.018-0.235,0.027
                              c-0.155,0.019-0.306,0.039-0.471,0.054c-4.711,0.51-15.796,0.766-26.902,0.766c-0.001,4.355-0.002,8.71-0.003,13.064
                              c16.678,0,26.611-2.456,31.24-4.39C408.843,419.89,405.449,414.057,405.456,414.057z"></path>
                  <path style="fill:#C3CCC7;" d="M377.096,441.211c3.739,0,9.072-0.058,14.557-0.713c-0.027-0.131-0.054-0.263-0.081-0.394
                              c-0.237-1.155-1.337-2.019-2.468-1.961c-7.996,0.414-16.022,0.414-24.017,0c-1.13-0.058-2.231,0.805-2.469,1.961
                              c-0.027,0.131-0.054,0.263-0.081,0.394C368.025,441.154,373.357,441.211,377.096,441.211z"></path>
                  <g>
                  <path style="fill:#DD3E3E;" d="M357.784,441.608c0.002-0.01,0.004-0.019,0.007-0.029c0.186-0.781-0.288-1.545-1.06-1.729
                                    c-2.297-0.549-4.572-1.193-6.818-1.931c-0.75-0.247-1.572,0.151-1.851,0.908c-0.003,0.009-0.007,0.019-0.01,0.028
                                    c-0.05,0.137-0.055,0.275-0.065,0.413c2.779,1.396,6.014,2.365,9.376,3.038C357.556,442.114,357.716,441.888,357.784,441.608z"></path>
                  <path style="fill:#DD3E3E;" d="M406.132,438.827c-0.278-0.756-1.1-1.154-1.85-0.908c-2.246,0.738-4.522,1.382-6.819,1.931
                                    c-0.772,0.184-1.246,0.948-1.06,1.729c0.002,0.01,0.005,0.019,0.007,0.029c0.067,0.281,0.228,0.507,0.42,0.698
                                    c3.362-0.673,6.597-1.642,9.377-3.038c-0.01-0.138-0.014-0.276-0.065-0.413C406.139,438.845,406.135,438.836,406.132,438.827z"></path>
                  </g>
                  <g>
                  <path style="fill:#282F3A;" d="M340.967,326.224c-0.061,1.088-0.122,2.176-0.184,3.265c-0.016,0.278,0.114,0.545,0.338,0.698
                                    c0.202,0.137,0.404,0.275,0.606,0.412c0.191-1.845,0.38-3.69,0.568-5.535c-0.316,0.179-0.633,0.358-0.949,0.537
                                    C341.125,325.725,340.982,325.961,340.967,326.224z"></path>
                  <path style="fill:#A2999A;" d="M340.798,328.422c-2.775,0.7-5.549,1.4-8.322,2.099c-0.645,0.163-1.233-0.481-1.021-1.137
                                    c0.373-1.158,1.452-2.734,4.497-3.622c3.074-0.897,4.62,0.084,5.369,1.04C341.774,327.38,341.488,328.248,340.798,328.422z"></path>
                  </g>
                  <g>
                  <path style="fill:#282F3A;" d="M413.676,326.224c0.061,1.088,0.122,2.176,0.184,3.265c0.016,0.278-0.113,0.545-0.338,0.698
                                    c-0.202,0.137-0.404,0.275-0.606,0.412c-0.191-1.845-0.38-3.69-0.568-5.535c0.316,0.179,0.633,0.358,0.949,0.537
                                    C413.519,325.725,413.662,325.961,413.676,326.224z"></path>
                  <path style="fill:#A2999A;" d="M413.845,328.422c2.775,0.7,5.549,1.4,8.322,2.099c0.645,0.163,1.232-0.481,1.022-1.137
                                    c-0.373-1.158-1.452-2.734-4.498-3.622c-3.074-0.897-4.62,0.084-5.369,1.04C412.869,327.38,413.156,328.248,413.845,328.422z"></path>
                  </g>
                  <g>
                  <path style="fill:#FFA312;" d="M357.784,441.608c0.002-0.01,0.005-0.019,0.007-0.029c0.186-0.781-0.288-1.545-1.06-1.729
                                    c-0.569-0.136-1.133-0.296-1.699-0.443c-0.078,0.772-0.157,1.544-0.236,2.316c0.844,0.215,1.701,0.41,2.567,0.584
                                    C357.556,442.114,357.716,441.888,357.784,441.608z"></path>
                  <path style="fill:#FFA312;" d="M396.403,441.579c0.002,0.01,0.005,0.019,0.007,0.029c0.067,0.281,0.228,0.507,0.42,0.698
                                    c0.867-0.174,1.723-0.368,2.568-0.584c-0.079-0.772-0.157-1.544-0.235-2.316c-0.566,0.147-1.13,0.307-1.699,0.443
                                    C396.691,440.034,396.217,440.798,396.403,441.579z"></path>
                  </g>
                  <g>
                  <g>
                        <path style="fill:#282F3A;" d="M340.099,372.426c-0.004,0-0.008,0-0.013,0c-0.347,0-0.627-0.295-0.626-0.659
                                    c0.005-1.307,0.009-2.614,0.012-3.921c0.001-0.364,0.283-0.659,0.629-0.658c0.004,0,0.009,0,0.013,0
                                    c0.347,0,0.627,0.295,0.627,0.658c-0.003,1.307-0.007,2.614-0.011,3.921C340.728,372.131,340.446,372.426,340.099,372.426z"></path>
                        <path style="fill:#282F3A;" d="M339.997,381.492c-0.004,0-0.008,0-0.013,0c-0.348,0-0.627-0.295-0.623-0.658
                                    c0.013-1.307,0.025-2.614,0.035-3.921c0.003-0.364,0.287-0.659,0.634-0.658c0.004,0,0.009,0,0.013,0
                                    c0.347,0,0.627,0.295,0.624,0.658c-0.01,1.307-0.022,2.614-0.034,3.921C340.63,381.197,340.345,381.492,339.997,381.492z"></path>
                  </g>
                  <g>
                        <path style="fill:#282F3A;" d="M414.127,372.426c-0.004,0-0.008,0-0.013,0c-0.347,0-0.629-0.295-0.631-0.659
                                    c-0.005-1.307-0.008-2.614-0.011-3.921c-0.001-0.364,0.28-0.659,0.627-0.658c0.004,0,0.008,0,0.013,0
                                    c0.347,0,0.628,0.295,0.629,0.658c0.003,1.307,0.007,2.614,0.012,3.921C414.753,372.131,414.474,372.426,414.127,372.426z"></path>
                        <path style="fill:#282F3A;" d="M414.227,381.492c-0.004,0-0.008,0-0.013,0c-0.348,0-0.633-0.295-0.636-0.658
                                    c-0.012-1.307-0.024-2.614-0.034-3.921c-0.003-0.364,0.277-0.659,0.624-0.658c0.004,0,0.008,0,0.013,0
                                    c0.348,0,0.632,0.295,0.634,0.658c0.011,1.307,0.022,2.614,0.035,3.921C414.854,381.197,414.575,381.492,414.227,381.492z"></path>
                  </g>
                  </g>
                  <g>
                  <path style="fill:#282F3A;" d="M 381.884 408.647 C 381.636 408.324 347.05 404.666 352.202 411.38 C 351.44 405.042 352.473 352.255 351.711 345.917 C 362.748 343.312 390.982 342.915 402.019 345.52 C 404.605 411.359 382.646 402.309 381.884 408.647 Z"></path>
                  <path style="fill:#3A454F;" d="M 401.18 406.658 C 401.942 400.32 401.562 353.048 402.324 346.71 C 396.805 345.408 382.954 343.567 377.322 343.567 L 381.5 408.144 L 392.444 408.144 L 401.18 406.658 Z"></path>
                  </g>
                  <g>
                  <path style="fill:#BDD6DB;" d="M346.083,276.3c-0.121,0.111-0.238,0.215-0.353,0.312
                                    C345.845,276.515,345.963,276.411,346.083,276.3z"></path>
                  <path style="fill:#BDD6DB;" d="M344.302,272.91c1.041,0.468,1.89,1.507,2.325,2.871c-0.16,0.158-0.314,0.306-0.464,0.446
                                    c0.715-0.669,1.536-1.551,2.518-2.44c2.445-2.213,4.976-3.644,4.012-5.03c-3.467,0.834-6.299,1.952-7.736,3.445
                                    C344.729,272.439,344.512,272.674,344.302,272.91z"></path>
                  <path style="fill:#EC9100;" d="M345.73,276.612c-0.033,0.028-0.066,0.056-0.098,0.083
                                    C345.665,276.668,345.698,276.64,345.73,276.612z"></path>
                  <path style="fill:#EC9100;" d="M346.163,276.227c-0.027,0.025-0.053,0.048-0.08,0.073
                                    C346.11,276.276,346.136,276.252,346.163,276.227z"></path>
                  <path style="fill:#EC9100;" d="M344.302,272.91c-0.547,0.613-1.032,1.229-1.459,1.88c-0.007,0.049-0.015,0.1-0.02,0.148
                                    c-0.015,0.163-0.02,0.323-0.014,0.477c0.003,0.079,0.003,0.159,0.011,0.235c0.024,0.203,0.071,0.394,0.137,0.575
                                    c0.02,0.056,0.043,0.11,0.067,0.163c0.089,0.194,0.193,0.379,0.336,0.537c0.101,0.112,0.204,0.194,0.308,0.263
                                    c0.029,0.019,0.059,0.035,0.088,0.051c0.083,0.046,0.168,0.079,0.253,0.099c0.024,0.006,0.048,0.015,0.073,0.019
                                    c0.108,0.018,0.218,0.018,0.331,0c0.022-0.003,0.045-0.012,0.067-0.016c0.095-0.02,0.191-0.051,0.289-0.093
                                    c0.032-0.013,0.063-0.027,0.095-0.042c0.114-0.055,0.23-0.12,0.35-0.2c0.006-0.004,0.011-0.006,0.017-0.01
                                    c0.129-0.087,0.263-0.191,0.4-0.303c0.032-0.026,0.065-0.055,0.098-0.083c0.115-0.097,0.232-0.201,0.353-0.312
                                    c0.027-0.024,0.053-0.048,0.08-0.073c0.15-0.141,0.304-0.289,0.464-0.446C346.192,274.417,345.344,273.378,344.302,272.91z"></path>
                  <path style="fill:#BDD6DB;" d="M405.529,273.788c0.982,0.889,1.803,1.771,2.518,2.44c-0.15-0.141-0.304-0.289-0.464-0.446
                                    c0.433-1.357,1.275-2.393,2.309-2.864c-0.21-0.24-0.427-0.478-0.656-0.714c-1.435-1.491-4.262-2.608-7.723-3.442
                                    C400.557,270.146,403.087,271.577,405.529,273.788z"></path>
                  <path style="fill:#EC9100;" d="M409.892,272.917c-1.034,0.472-1.876,1.507-2.309,2.864c0.16,0.158,0.314,0.306,0.464,0.446
                                    c0.027,0.025,0.053,0.048,0.08,0.073c0.121,0.111,0.238,0.215,0.353,0.312c0.033,0.028,0.066,0.056,0.098,0.083
                                    c0.137,0.112,0.271,0.216,0.4,0.303c0.006,0.004,0.011,0.006,0.017,0.01c0.12,0.08,0.236,0.145,0.35,0.2
                                    c0.032,0.015,0.063,0.029,0.095,0.042c0.098,0.042,0.195,0.073,0.29,0.093c0.022,0.005,0.045,0.013,0.067,0.016
                                    c0.113,0.018,0.223,0.017,0.331,0c0.025-0.004,0.048-0.014,0.073-0.019c0.086-0.021,0.17-0.054,0.253-0.099
                                    c0.029-0.016,0.059-0.031,0.088-0.051c0.104-0.069,0.207-0.151,0.308-0.263c0.143-0.159,0.248-0.343,0.336-0.537
                                    c0.024-0.053,0.047-0.107,0.067-0.163c0.066-0.181,0.113-0.372,0.137-0.575c0.009-0.076,0.008-0.156,0.011-0.235
                                    c0.006-0.154,0.001-0.314-0.014-0.477c-0.001-0.007-0.002-0.015-0.003-0.022C410.949,274.215,410.453,273.557,409.892,272.917z"></path>
                  </g>
                  </g>
            </g>
            </g>
      </svg>
      `,
      "bus.svg" : `
      <svg version="1.1" viewBox="406.935 -59.37 410.225 832.662" xmlns="http://www.w3.org/2000/svg">
            <defs></defs>
            <g transform="matrix(0, -1.62061, -1.819819, 0, 120.074327, 35.672181)" style="transform-origin: 488.468px 313.546px;">
            <g fill-rule="evenodd">
                  <path d="m259.94 369.84c-2.9666-11.948-3.7425-34.095-3.7425-56.28 0-22.109 0.77583-44.255 3.6957-56.165 2.192-9.287 10.724-13.739 23.046-13.397h363.12c16.427-0.15 31.943-0.189 39.151 0.953 15.971 2.589 27.153 7.191 29.616 14.802 4.7018 14.04 6.8878 33.943 6.8878 53.846 0 19.901-2.1428 39.84-6.8422 53.883-2.4642 7.609-13.69 12.176-29.662 14.764-7.2079 1.142-22.725 1.142-39.151 0.989h-363.12c-12.322 0.304-20.807-4.111-22.999-13.395" style="fill: rgb(252, 255, 0);"></path>
                  <path d="m624.49 246.06-53.121 2.768c-6.736 0.349-10.618 1.915-10.996 3.403l-1.145 4.393c-0.227 0.913 3.045 1.558 7.954 1.558h10.276c11.11 0 20.244-0.408 26.825-1.401l21.122-3.194c4.605-0.687 7.876-1.652 7.838-2.819l-0.076-3.689c0-0.705-4.758-1.226-8.677-1.019" fill="#8d8f93"></path>
                  <g fill="#d2d2d1">
                  <path d="m600.82 256.82 3.956-9.36-33.334 1.75c-2.356 0.114-4.754 0.457-7.078 1.028-4.033 1.066-3.615 2.093-4.717 6.43 0 0.839 5.937 1.142 6.659 1.142 11.493 0 23.06 0.19 34.514-0.99" style="fill: #9cba73;"></path>
                  <path d="m608.51 247.27-3.805 8.98c8.754-1.293 17.809-2.017 26.294-4.261 2.704-0.877 1.865-1.751 1.826-4.796-0.76-0.761-6.584-0.761-7.572-0.761l-16.743 0.838" style="fill: #9cba73;"></path>
                  </g>
                  <g fill="#8d8f93">
                  <path d="m633.32 246.44c1.104 0 2.016-0.456 3.385-1.902 2.208-2.321 1.106-3.615 0.381-6.317-0.799-2.854-0.267-2.397-2.168-5.214-1.181-1.675-2.437-1.902-4.529-1.902-1.065 0-2.129 0.876-1.903 1.902l2.93 11.531c0.228 1.027 0.875 1.902 1.904 1.902" style="fill: rgb(10, 11, 11);"></path>
                  <path d="m633.51 246.44c1.066-0.037 2.169-0.722 3.235-1.902 0.381-0.342 0.61-0.723 0.798-1.102 1.522 2.243 2.589 6.772 0.532 6.886-1.787 0.19-2.586-0.076-3.387-0.266-1.023-0.191-1.52-2.207-1.178-3.616"></path>
                  <path d="m563.46 248.34h2.359c1.596 0 2.932-0.274 2.932-0.603v-2.182c0-0.327-1.295-0.601-2.932-0.601h-2.359c-1.636 0-2.931 0.263-2.931 0.601v2.182c0 0.339 1.295 0.603 2.931 0.603"></path>
                  <path d="m554.93 262 0.05-1.786c24.873-0.078 47.756-0.152 51.568-0.801v0.077c18.695-2.282 31.802-7.572 44.049-6.431 8.594 0.836 10.583 5.899 12.033 11.415 4.403 16.592 6.392 32.765 6.392 49.128 0 16.361-1.719 32.496-6.338 49.049-1.504 5.48-3.439 10.541-12.087 11.378-12.247 1.18-25.301-4.147-44.049-6.393v0.037l-6.982-0.836c-0.216-0.532-0.377-1.105-0.377-1.75l6.771 0.875c2.308-17.847 4.026-35.161 4.026-52.439 0-17.312-1.826-34.588-4.082-52.36-3.223 0.989-19.553 0.837-50.974 0.837"></path>
                  </g>
                  <path d="m608.89 354.43c2.013-17.352 3.157-34.895 2.356-52.361-0.228-10.313-1.445-20.892-2.586-31.165-0.381-3.349-0.838-6.737-1.256-10.048 12.176-1.598 24.126-5.175 36.342-6.393 5.553-0.343 12.252-0.418 15.45 4.909 2.052 3.615 3.044 9.095 3.919 13.129 6.506 29.035 6.049 60.999-1.827 89.69-1.066 3.806-2.245 7.231-5.975 9.095-6.814 2.892-16.934 0.723-23.935-0.645-7.724-1.753-15.904-3.503-23.824-4.417-0.15-0.76 0.268-2.586 0.344-3.461 0.343-2.702 0.686-5.594 0.992-8.333" style="fill: rgb(255, 255, 255);"></path>
                  <path d="M 676.02 319.11 L 673.199 350.721 C 673.164 351.099 673.816 351.577 674.375 351.513 L 680.557 350.612 C 681.324 350.528 681.866 349.926 681.893 349.625 C 683.332 340.994 686.967 305.355 684.548 319.871 C 684.575 319.569 684.06 319.141 683.373 319.077 L 677.319 318.536 C 676.63 318.476 676.053 318.731 676.02 319.11" fill="#3b3b3c"></path>
                  <path d="m687.05 259.94c-1.6-9.017 4.718-14.459 14.535-5.936 3.157 2.778 9.436 10.504 9.361 24.773-7.231-1.94-22.603-11.377-23.896-18.837" style="fill: rgb(255, 255, 255);"></path>
                  <path d="M 291.54 359 C 291.54 361.437 290.546 363.336 289.519 363.336 L 279.786 363.336 C 278.652 363.336 274.031 360.597 273.96 358.313 C 271.625 339.858 269.997 326.73 269.997 313.488 C 269.997 300.398 271.571 287.154 273.944 268.699 C 274.017 266.417 278.652 263.714 279.786 263.714 L 289.519 263.714 C 290.546 263.714 291.521 265.617 291.521 268.014 C 289.825 283.197 288.404 298.228 288.404 313.524 C 288.404 328.824 289.095 343.816 291.54 359" fill="#8d8f93" style=""></path>
                  <path d="M 277.281 362.95 C 274.003 362.456 270.976 359.717 270.559 358.27 C 268.708 345.561 265.684 332.508 265.684 319.684 C 264.59 302.751 268.034 285.588 270.475 268.732 C 271.06 267.324 274.003 264.583 277.281 264.09 L 289.47 264.09 C 292.498 266.867 290.649 272.119 290.146 275.087 C 288.46 289.66 286.441 304.615 287.285 319.228 C 287.285 332.508 288.798 345.788 291.151 358.994 C 291.065 360.327 290.815 361.732 289.47 362.95 L 277.281 362.95" style="fill: #9cba73;"></path>
                  <rect style="fill: #b7ce96 ;" transform="matrix(0, -1, -1, 0, 625.595041, 577.268068)" x="210.51" y="63.566" width="107.651" height="265.537" rx="8.705" ry="8.705"></rect>
                  <g fill="#8d8f93">
                  <path d="m258.26 369.84c1.826 9.284 8.98 13.776 19.255 13.395h1.446l0.99-14.233-18.115 0.914c-2.89-12.822-4.072-34.438-4.072-56.204 0-21.384 1.026-42.962 4.034-56.317l18.113 0.875-0.989-14.233h-1.407c-10.275-0.381-17.429 4.11-19.255 13.397-2.435 11.871-3.043 34.017-3.043 56.165 0 18.455-0.611 38.28 3.043 56.241" style="fill: rgb(14, 14, 14);"></path>
                  <path d="m709.01 278.13v0.116h0.264l-0.264-0.116zm-0.04 70.78 0.304-0.077h-0.304v0.077zm-17.352 28.311c2.59 0.458 6.014-0.61 10.046-4.111 3.044-2.816 9.285-10.349 9.36-24.277h0.383c3.195-9.818 4.754-21.006 4.566-33.677h-0.039l0.039-0.227c-0.039-0.99-0.039-1.979-0.077-3.007h0.152c0.153-12.673-1.368-23.898-4.604-33.676h-0.42c-0.075-13.966-6.279-21.464-9.36-24.278-4.032-3.502-7.456-4.605-10.01-4.111l-4.641-5.44c1.675 0.15 3.121 0.343 4.3 0.532 13.318 2.589 22.606 7.153 24.66 14.763 3.957 14.079 5.784 33.944 5.784 53.885v1.559h-0.04c-0.074 19.37-1.901 38.51-5.744 52.172-2.054 7.608-11.342 12.176-24.66 14.764-1.216 0.227-2.663 0.38-4.336 0.531l4.641-5.402" style="fill: rgb(25, 26, 26);"></path>
                  <path d="m624.53 381.06-53.122-2.77c-6.737-0.344-10.619-1.912-10.999-3.401l-1.141-4.392c-0.228-0.915 3.043-1.558 7.955-1.558h10.273c11.11 0 20.243 0.409 26.828 1.402l21.118 3.193c4.605 0.685 7.878 1.652 7.839 2.819l-0.077 3.688c0 0.706-4.755 1.227-8.674 1.019"></path>
                  <rect x="-373.21" y="-262.54" width="67.617" height="23.71" style="stroke: rgb(0, 0, 0); fill: rgb(26, 25, 25); paint-order: fill;" transform="matrix(0, -1, -1, 0, 258.534063, -24.79687)" rx="5.031" ry="5.031"></rect>
                  <rect x="373.21" y="262.54" width="67.617" height="23.71" style="stroke: rgb(0, 0, 0); fill: rgb(26, 25, 25); paint-order: fill;" transform="matrix(0, -1, -1, 0, 732.824297, 721.623052)" rx="5.031" ry="5.031"></rect>
                  <rect x="-373.21" y="-262.54" width="67.617" height="23.71" style="stroke: rgb(0, 0, 0); fill: rgb(26, 25, 25); paint-order: fill;" transform="matrix(0, -1, -1, 0, 157.68958, -24.79687)" rx="5.031" ry="5.031"></rect>
                  <rect x="373.21" y="262.54" width="67.617" height="23.71" style="stroke: rgb(0, 0, 0); fill: rgb(26, 25, 25); paint-order: fill;" transform="matrix(0, -1, -1, 0, 632.823693, 721.623052)" rx="5.031" ry="5.031"></rect>
                  <rect x="-373.21" y="-262.54" width="67.617" height="23.71" style="stroke: rgb(0, 0, 0); fill: rgb(26, 25, 25); paint-order: fill;" transform="matrix(0, -1, -1, 0, 57.688908, -24.79687)" rx="5.031" ry="5.031"></rect>
                  <rect x="373.21" y="262.54" width="67.617" height="23.71" style="stroke: rgb(0, 0, 0); fill: rgb(26, 25, 25); paint-order: fill;" transform="matrix(0, -1, -1, 0, 822.111819, 721.338135)" rx="5.031" ry="5.031"></rect>
                  </g>
                  <g fill="#d2d2d1">
                  <path d="m600.82 370.3 3.956 9.362-33.334-1.75c-2.356-0.113-4.754-0.457-7.078-1.027-4.033-1.067-3.615-2.094-4.717-6.431 0-0.839 5.937-1.141 6.659-1.141 11.493 0 23.06-0.191 34.514 0.987" style="fill: #9cba73;"></path>
                  <path d="m608.51 379.85-3.805-8.982c8.754 1.294 17.809 2.018 26.294 4.263 2.704 0.873 1.865 1.751 1.826 4.794-0.76 0.761-6.584 0.761-7.572 0.761l-16.743-0.836" style="fill: #9cba73;"></path>
                  </g>
                  <g fill="#8d8f93">
                  <path d="m633.36 380.65c1.103 0 2.018 0.457 3.386 1.905 2.206 2.32 1.103 3.615 0.381 6.316-0.8 2.853-0.267 2.398-2.169 5.212-1.179 1.675-2.437 1.903-4.529 1.903-1.067 0-2.131-0.875-1.904-1.903l2.931-11.528c0.228-1.03 0.877-1.905 1.904-1.905" style="fill: rgb(21, 22, 23);"></path>
                  <path d="m633.55 380.65c1.064 0.039 2.171 0.725 3.233 1.905 0.383 0.342 0.61 0.722 0.801 1.102 1.522-2.245 2.589-6.772 0.533-6.886-1.79-0.192-2.589 0.075-3.389 0.265-1.025 0.191-1.522 2.207-1.178 3.614"></path>
                  <path d="m563.49 378.75h2.361c1.599 0 2.931 0.273 2.931 0.602v2.184c0 0.324-1.295 0.599-2.931 0.599h-2.361c-1.635 0-2.929-0.264-2.929-0.599v-2.184c0-0.339 1.294-0.602 2.929-0.602"></path>
                  </g>
                  <path d="m687.05 367.18c-1.6 9.018 4.718 14.46 14.535 5.935 3.157-2.778 9.436-10.502 9.361-24.772-7.231 1.94-22.603 11.379-23.896 18.837" style="fill: rgb(255, 255, 255);"></path>
                  <path d="m708.72 280.99c1.308 0.379 1.963 0.838 2.649 2.056 0.458 0.76 1.193 2.056 1.259 4.262 1.471 6.392 2.27 16.248 2.27 26.142s-1.044 19.749-2.254 26.104c-0.065 2.169-0.801 3.464-1.26 4.262-0.686 1.217-1.34 1.674-2.649 2.016 2.486-10.655 3.254-21.308 3.516-31.926 0.279-11.111-1.731-23.059-3.531-32.916" fill="#3b3b3c"></path>
                  <path d="m714.75 313.41c0-2.494-0.605-4.528-1.35-4.528s-1.349 2.034-1.349 4.528c0 2.493 0.604 4.529 1.349 4.529s1.35-2.036 1.35-4.529" fill="#e9e8e8"></path>
                  <path d="M 676.437 306.349 L 673.616 274.738 C 673.581 274.36 674.233 273.882 674.792 273.946 L 680.974 274.847 C 681.741 274.931 682.283 275.533 682.31 275.834 C 683.749 284.465 687.384 320.104 684.965 305.588 C 684.992 305.89 684.477 306.318 683.79 306.382 L 677.736 306.923 C 677.047 306.983 676.47 306.728 676.437 306.349" fill="#3b3b3c" style="transform-box: fill-box; transform-origin: 50% 50%;" transform="matrix(-1, 0, 0, -1, 0.000001, -0.00004)"></path>
            </g>
            <g fill="#211d1d">
                  <path d="m358.43 258.25 179.72-0.399v0.015l-179.72 0.4"></path>
                  <path d="m538.15 257.85c2.874 0 5.1-0.106 6.607-0.352 1.508-0.246 2.293-0.636 2.291-1.191h0.015c-6e-3 1.142-3.164 1.556-8.913 1.558v-0.015"></path>
                  <path d="m547.05 252.69h0.0152v3.6117h-0.0152v-3.6117z"></path>
                  <path d="m547.05 252.69c0-0.797-0.679-1.824-3.303-2.354l3e-3 -0.015c2.627 0.53 3.315 1.561 3.315 2.369h-0.015"></path>
                  <path d="m543.74 250.34-11.188-2.318 3e-3 -0.015 11.188 2.318"></path>
                  <path d="m532.56 248.02c-4.713-0.968-6.287-1.227-15.822-1.227-0.323 0-0.654 0-0.995 1e-3v-0.016h0.995c9.535 0 11.112 0.259 15.825 1.227l-3e-3 0.015"></path>
                  <path d="m515.74 246.8-158.3 0.307v-0.015l158.3-0.308"></path>
                  <path d="m457.62 260.29 0.42-16.287h0.015l-0.42 16.287"></path>
                  <path d="m704.3 278.67c-13.199-2.284-25.388-5.252-36.495-9.019l5e-3 -0.015c11.106 3.768 23.294 6.735 36.493 9.018l-3e-3 0.016"></path>
                  <path d="m687.15 259.15c-9.952-1.868-21.59-3.268-28.343-3.268-1.64 0-2.992 0.083-3.961 0.261l-3e-3 -0.015c0.971-0.178 2.324-0.261 3.964-0.261 6.755 0 18.393 1.4 28.346 3.268l-3e-3 0.015"></path>
                  <path d="m555 244.04h0.0148v17.96h-0.0148v-17.96z"></path>
                  <path d="m551.16 244.04h0.016v72.338h-0.016v-72.338z"></path>
                  <path d="m357.78 260.2h197.15v0.016h-197.15v-0.016z"></path>
                  <path d="m357.78 262.03h197.15v0.0152h-197.15v-0.0152z"></path>
                  <path d="m643.09 253.51-1.026-9.742 0.015-1e-3 1.026 9.742"></path>
                  <path d="m358.43 368.86 179.72 0.397v0.015l-179.72-0.397"></path>
                  <path d="m538.15 369.26c5.749 2e-3 8.907 0.418 8.913 1.557h-0.015c2e-3 -0.554-0.783-0.943-2.291-1.189-1.507-0.247-3.733-0.353-6.607-0.353v-0.015"></path>
                  <path d="m547.05 370.81h0.0152v3.6148h-0.0152v-3.6148z"></path>
                  <path d="m547.06 374.43c0 0.807-0.688 1.837-3.315 2.368l-3e-3 -0.015c2.624-0.531 3.303-1.557 3.303-2.353h0.015"></path>
                  <path d="m543.75 376.8-11.188 2.317-3e-3 -0.015 11.188-2.317"></path>
                  <path d="m532.56 379.11c-4.698 0.964-6.281 1.227-15.738 1.227-0.35 0-0.71 0-1.082-1e-3v-0.015c0.372 1e-3 0.732 1e-3 1.082 1e-3 9.458 0 11.037-0.263 15.735-1.227l3e-3 0.015"></path>
                  <path d="m515.74 380.34-158.3-0.306v-0.015l158.3 0.306"></path>
                  <path d="m457.68 366.87 0.417 16.249h-0.015l-0.418-16.249"></path>
                  <path d="m704.3 348.46c-13.199 2.283-25.387 5.251-36.493 9.018l-5e-3 -0.014c11.107-3.767 23.296-6.736 36.495-9.019l3e-3 0.015"></path>
                  <path d="m687.15 367.98c-9.953 1.867-21.591 3.267-28.346 3.267-1.641 0-2.993-0.082-3.964-0.261l3e-3 -0.015c0.969 0.178 2.321 0.261 3.961 0.261 6.753 0 18.391-1.4 28.343-3.267l3e-3 0.015"></path>
                  <path d="m555.04 365.08h0.0152v17.96h-0.0152v-17.96z"></path>
                  <path d="m357.78 366.9h197.15v0.0148h-197.15v-0.0148z"></path>
                  <path d="m357.78 365.08h197.15v0.016h-197.15v-0.016z"></path>
                  <path d="m643.15 373.57-1.028 9.74-0.015-1e-3 1.028-9.741"></path>
                  <path d="m707.81 277.68c2.697 10.475 4.595 21.764 4.595 35.105 0 1.391-0.021 2.805-0.063 4.243l-0.016-1e-3c0.043-1.437 0.063-2.851 0.063-4.242 0-13.34-1.897-24.627-4.594-35.101l0.015-4e-3"></path>
                  <path d="m707.76 349.14c2.696-10.473 4.593-21.756 4.593-35.093 0-1.394-0.021-2.81-0.064-4.25h0.016c0.042 1.44 0.063 2.856 0.063 4.25 0 13.338-1.897 24.623-4.593 35.097l-0.015-4e-3"></path>
            </g>
            <rect transform="matrix(1, 0, 0, -1, 0, 0)" x="299.32" y="-371.45" width="248.54" height="19.402" rx="6.517" ry="6.517" style="fill: #9cba73;"></rect>
            <g fill="#8d8f93" fill-rule="evenodd" style="" transform="matrix(1, 0, 0, 1.281659, 0, -113.626328)">
                  <path d="m494.42 373.81 4e-3 -3.9089c3e-3 -2.6473 0.18225-4.8515 0.39609-4.8501l1.4196 9e-3c0.2106 9.6e-4 0.38723 2.1465 0.38455 4.8551l-4e-3 3.9089c-3e-3 2.7069-0.1764 4.8482-0.39415 4.8468l-1.4196-9e-3c-0.22034-9.7e-4 -0.38917-2.1449-0.38649-4.8518"></path>
                  <path d="m526.46 373.52 4e-3 -3.9089c3e-3 -2.6473 0.18225-4.8515 0.39609-4.8501l1.4196 9e-3c0.2106 9.6e-4 0.38723 2.1465 0.38455 4.8551l-4e-3 3.9089c-3e-3 2.7069-0.1764 4.8482-0.39415 4.8468l-1.4196-9e-3c-0.22034-9.7e-4 -0.38917-2.1449-0.38649-4.8518"></path>
                  <path d="m463.11 373.7 4e-3 -3.7799c3e-3 -2.56 0.18225-4.6914 0.39609-4.6901l1.4196 9e-3c0.2106 9.1e-4 0.38723 2.0757 0.38455 4.6949l-4e-3 3.78c-3e-3 2.6176-0.1764 4.6882-0.39415 4.6869l-1.4196-9e-3c-0.22034-9.1e-4 -0.38917-2.0741-0.38649-4.6918"></path>
                  <path d="m430.5 373.57 4e-3 -3.7799c3e-3 -2.56 0.18225-4.6914 0.39609-4.6901l1.4196 9e-3c0.2106 8.8e-4 0.38723 2.0757 0.38455 4.6949l-4e-3 3.78c-3e-3 2.6176-0.1764 4.6882-0.39415 4.6869l-1.4196-9e-3c-0.22034-9.1e-4 -0.38917-2.0741-0.38649-4.6918"></path>
                  <path d="m398.59 373.57 4e-3 -3.7799c3e-3 -2.56 0.18225-4.6914 0.39609-4.6901l1.4196 9e-3c0.2106 8.8e-4 0.38723 2.0757 0.38455 4.6949l-4e-3 3.78c-3e-3 2.6176-0.1764 4.6882-0.39415 4.6869l-1.4196-9e-3c-0.22034-9.1e-4 -0.38917-2.0741-0.38649-4.6918"></path>
                  <path d="m366.59 373.57 4e-3 -3.7799c3e-3 -2.56 0.18225-4.6914 0.39609-4.6901l1.4196 9e-3c0.2106 8.8e-4 0.38723 2.0757 0.38455 4.6949l-4e-3 3.78c-3e-3 2.6176-0.1764 4.6882-0.39415 4.6869l-1.4196-9e-3c-0.22034-9.1e-4 -0.38917-2.0741-0.38649-4.6918"></path>
            </g>
            <rect transform="matrix(1, 0, 0, -1, 0, 0)" x="298.42" y="-276.53" width="247.984" height="22.548" style="fill: #9cba73;" rx="4.781" ry="4.781"></rect>
            <g fill="#8d8f93" fill-rule="evenodd" style="" transform="matrix(1.001784, 0, 0, 1.721155, -0.654144, -172.820192)">
                  <path d="m494.42 256.21 4e-3 -3.9089c3e-3 -2.6473 0.18225-4.8515 0.39609-4.8501l1.4196 9e-3c0.2106 9.6e-4 0.38723 2.1465 0.38455 4.8551l-4e-3 3.9089c-3e-3 2.7069-0.1764 4.8482-0.39415 4.8468l-1.4196-9e-3c-0.22035-9.7e-4 -0.38917-2.1449-0.38649-4.8518"></path>
                  <path d="m526.46 255.92 4e-3 -3.9089c3e-3 -2.6473 0.18225-4.8515 0.39609-4.8501l1.4196 9e-3c0.2106 9.6e-4 0.38724 2.1465 0.38456 4.8551l-4e-3 3.9089c-3e-3 2.7069-0.1764 4.8482-0.39416 4.8468l-1.4196-9e-3c-0.22034-9.7e-4 -0.38917-2.1449-0.38649-4.8518"></path>
                  <path d="m463.11 256.1 4e-3 -3.78c3e-3 -2.56 0.18226-4.6914 0.3961-4.6901l1.4196 9e-3c0.2106 8.8e-4 0.38723 2.0757 0.38455 4.6949l-4e-3 3.78c-3e-3 2.6176-0.1764 4.6882-0.39415 4.6869l-1.4196-9e-3c-0.22034-9.1e-4 -0.38918-2.0741-0.3865-4.6918"></path>
                  <path d="m430.5 255.97 4e-3 -3.78c3e-3 -2.56 0.18226-4.6914 0.3961-4.6901l1.4196 9e-3c0.2106 8.8e-4 0.38723 2.0757 0.38455 4.6949l-4e-3 3.78c-3e-3 2.6176-0.1764 4.6882-0.39415 4.6869l-1.4196-9e-3c-0.22034-9.1e-4 -0.38918-2.0741-0.3865-4.6918"></path>
                  <path d="m398.59 255.97 4e-3 -3.78c3e-3 -2.56 0.18225-4.6914 0.39609-4.6901l1.4196 9e-3c0.2106 8.8e-4 0.38723 2.0757 0.38455 4.6949l-4e-3 3.78c-3e-3 2.6176-0.1764 4.6882-0.39415 4.6869l-1.4196-9e-3c-0.22034-9.1e-4 -0.38917-2.0741-0.38649-4.6918"></path>
                  <path d="m366.59 255.97 4e-3 -3.78c3e-3 -2.56 0.18225-4.6914 0.39609-4.6901l1.4196 9e-3c0.2106 8.8e-4 0.38723 2.0757 0.38455 4.6949l-4e-3 3.78c-3e-3 2.6176-0.1764 4.6882-0.39415 4.6869l-1.4196-9e-3c-0.22034-9.1e-4 -0.38917-2.0741-0.38649-4.6918"></path>
            </g>
            </g>
      </svg>
       `,
      "scoter.svg" : `
      <svg version="1.1" id="Layer_2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="73.411 -198.977 508.338 819.413" style="enable-background:new 0 0 652 426;">
      <g style="transform-origin: 327.536px 215.417px;" transform="matrix(0, 1.226521, -1.302428, 0, -10.650281, 0.000123)">
      <g>
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="57.1699" y1="235.167" x2="57.1699" y2="192.2764">
            <stop offset="0.114" style="stop-color:#2C272C"></stop>
            <stop offset="0.1987" style="stop-color:#312C31"></stop>
            <stop offset="0.3007" style="stop-color:#3E3B3F"></stop>
            <stop offset="0.4111" style="stop-color:#555357"></stop>
            <stop offset="0.4869" style="stop-color:#68686C"></stop>
            <stop offset="0.6317" style="stop-color:#59585C"></stop>
            <stop offset="0.9129" style="stop-color:#342F34"></stop>
            <stop offset="0.9376" style="stop-color:#302B30"></stop>
            </linearGradient>
            <path style="fill:url(#SVGID_1_);" d="M90.59,227.329c0,4.329-3.51,7.838-7.838,7.838H36.16c-7.838,0-12.41-6.967-12.41-21.01
                  c0-14.369,6.205-21.88,12.41-21.88h46.592c4.328,0,7.838,3.509,7.838,7.838V227.329z"></path>
            <linearGradient id="SVGID_00000008129289306382095380000003593785322496957576_" gradientUnits="userSpaceOnUse" x1="57.1699" y1="235.167" x2="57.1699" y2="192.2764">
            <stop offset="0.114" style="stop-color:#282C27"></stop>
            <stop offset="0.1965" style="stop-color:#2D312C"></stop>
            <stop offset="0.2958" style="stop-color:#3C3E3A"></stop>
            <stop offset="0.4034" style="stop-color:#545551"></stop>
            <stop offset="0.4869" style="stop-color:#6C6B68"></stop>
            <stop offset="0.6278" style="stop-color:#5C5C59"></stop>
            <stop offset="0.9015" style="stop-color:#333631"></stop>
            <stop offset="0.9376" style="stop-color:#2D302B"></stop>
            </linearGradient>
            <path style="opacity:0.4;fill:url(#SVGID_00000008129289306382095380000003593785322496957576_);" d="M82.752,192.276H36.16
                  c-6.205,0-12.41,7.511-12.41,21.88c0,14.044,4.572,21.01,12.41,21.01h46.592c4.328,0,7.838-3.509,7.838-7.838v-27.215
                  C90.59,195.785,87.08,192.276,82.752,192.276z M39.875,224c0.334,2.356-6.203,2.829-8.75,1.625c-2.852-1.348-4.301-2.624-5.25-5.5
                  c-1.124-3.407-0.284-8.789,0.479-12.27c1.178-5.38,4.896-5.855,11.771-5.98C43.166,201.783,38,210.75,39.875,224z"></path>
            <linearGradient id="SVGID_00000145058885807589782770000006104533572353695107_" gradientUnits="userSpaceOnUse" x1="92.167" y1="181" x2="92.167" y2="246.667">
            <stop offset="0.14" style="stop-color:#0095CE"></stop>
            <stop offset="0.3727" style="stop-color:#1190FF"></stop>
            <stop offset="0.5263" style="stop-color:#89C7FF"></stop>
            <stop offset="0.6716" style="stop-color:#1190FF"></stop>
            <stop offset="0.7821" style="stop-color:#0C91F2"></stop>
            <stop offset="0.9877" style="stop-color:#0095CF"></stop>
            <stop offset="0.9905" style="stop-color:#0095CE"></stop>
            </linearGradient>
            <path style="fill: #9cba73;" d="M143.334,234.667c0,6.627-5.373,12-12,12
                  H60c-12,0-19-10.667-19-32.167c0-22,9.5-33.5,19-33.5h71.334c6.627,0,12,5.373,12,12V234.667z"></path>
            <defs>
            <filter id="Adobe_OpacityMaskFilter" filterUnits="userSpaceOnUse" x="54.062" y="183.348" width="16.968" height="61.402">
            <feFlood style="flood-color:white;flood-opacity:1" result="back"></feFlood>
            <feBlend in="SourceGraphic" in2="back" mode="normal"></feBlend>
            </filter>
            </defs>
            <mask maskUnits="userSpaceOnUse" x="54.062" y="183.348" width="16.968" height="61.402" id="SVGID_00000115476900400603834730000018189576818998343821_">
            <g style="filter:url(#Adobe_OpacityMaskFilter);">
            <defs>
                  <filter id="Adobe_OpacityMaskFilter_00000168795861724308970360000005346759941926902975_" filterUnits="userSpaceOnUse" x="54.062" y="183.348" width="16.968" height="61.402">
                  <feFlood style="flood-color:white;flood-opacity:1" result="back"></feFlood>
                  <feBlend in="SourceGraphic" in2="back" mode="normal"></feBlend>
                  </filter>
            </defs>
            <mask maskUnits="userSpaceOnUse" x="54.062" y="183.348" width="16.968" height="61.402" id="SVGID_00000115476900400603834730000018189576818998343821_">
                  <g style="filter:url(#Adobe_OpacityMaskFilter_00000168795861724308970360000005346759941926902975_);"></g>
            </mask>
            <linearGradient id="SVGID_00000125600312984555834400000003276001310549543564_" gradientUnits="userSpaceOnUse" x1="62.5461" y1="185.4072" x2="62.5461" y2="240.4985">
                  <stop offset="0" style="stop-color:#000000"></stop>
                  <stop offset="0.4621" style="stop-color:#FFFFFF"></stop>
                  <stop offset="0.8937" style="stop-color:#000000"></stop>
            </linearGradient>
            <path style="mask:url(#SVGID_00000115476900400603834730000018189576818998343821_);fill:url(#SVGID_00000125600312984555834400000003276001310549543564_);" d="
                              M71.03,183.348c0,0-16.575,4.153-16.964,32.152c-0.316,22.75,16.684,29.25,16.684,29.25s-6.421-10.416-6.421-30.083
                              S71.03,183.348,71.03,183.348z"></path>
            </g>
            </mask>
            <linearGradient id="SVGID_00000149355007393657217990000014958196770328060568_" gradientUnits="userSpaceOnUse" x1="62.5461" y1="185.4072" x2="62.5461" y2="240.4985">
            <stop offset="0" style="stop-color:#E80000"></stop>
            <stop offset="0.4621" style="stop-color:#FFFFFF"></stop>
            <stop offset="0.8937" style="stop-color:#F31400"></stop>
            </linearGradient>
            <path style="fill:url(#SVGID_00000149355007393657217990000014958196770328060568_);" d="M71.03,183.348
                  c0,0-16.575,4.153-16.964,32.152c-0.316,22.75,16.684,29.25,16.684,29.25s-6.421-10.416-6.421-30.083S71.03,183.348,71.03,183.348z
                  "></path>
            <g style="opacity:0.4;">
            <radialGradient id="SVGID_00000158010749560101994690000009176906249023855796_" cx="92.1675" cy="213.8335" r="42.9887" gradientUnits="userSpaceOnUse">
            <stop offset="0.1509" style="stop-color:#EED8F0"></stop>
            <stop offset="0.4233" style="stop-color:#D296D8"></stop>
            <stop offset="0.5754" style="stop-color:#CA90D3"></stop>
            <stop offset="0.8071" style="stop-color:#B581C7"></stop>
            <stop offset="0.8805" style="stop-color:#AD7BC2"></stop>
            </radialGradient>
            <path style="fill: #9cba73;" d="M131.334,181H60
                        c-5.926,0-11.851,4.476-15.471,13.181c1.062-1.179,2.322-2.175,3.807-2.889c3.646-1.753,9.264-2.717,12.897-0.339
                        c2.976,1.947,3.958,5.39,4.526,8.723c0.544,3.195,0.843,6.498,1.826,9.592c0.072-1.361,0.123-2.707,0.164-4.018
                        c0.021-0.643,1.021-0.645,1,0c-0.064,2.043-0.169,4.094-0.341,6.139c0.093,0.202,0.171,0.409,0.272,0.609
                        c0.184,0.363-0.094,0.697-0.396,0.749c-0.23,2.28-0.558,4.548-1.041,6.783c-0.934,4.318-2.359,9.376-5.781,12.439
                        c-3.347,2.997-8.887,3.649-13.07,2.131c-2.928-1.062-4.841-3.126-6.139-5.647c2.512,12.165,8.711,18.214,17.746,18.214h71.334
                        c6.627,0,12-5.373,12-12V193C143.334,186.373,137.961,181,131.334,181z"></path>
            </g>
            <g style="opacity:0.6;">
            <radialGradient id="SVGID_00000018227214791182002320000012152091959274835124_" cx="92.1675" cy="213.8335" r="42.9887" gradientUnits="userSpaceOnUse">
            <stop offset="0" style="stop-color:#1190FF"></stop>
            <stop offset="0.1201" style="stop-color:#0D91F3"></stop>
            <stop offset="0.3766" style="stop-color:#0693DE"></stop>
            <stop offset="0.6313" style="stop-color:#0195D2"></stop>
            <stop offset="0.8805" style="stop-color:#0095CE"></stop>
            </radialGradient>
            <path style="fill: #9cba73;" d="M131.334,181H60
                        c-3.443,0-6.882,1.522-9.873,4.495c3.252-1.543,7.186-2.061,10.903-2.147c4.182-0.096,8.364,0.149,12.546,0.153
                        c6.527,0.005,13.07,0.02,19.586,0.507c7.805,0.584,20.449,0.811,12.701,11.822c-2.246,3.19-4.985,4.4-8.758,2.946
                        c-3.83-1.476-7.107-4.248-10.967-5.602c-0.638,3.503,0.139,7.015,0.31,10.534c0.178,3.657-0.126,7.341-0.472,10.98
                        c-0.725,7.638-1.492,15.348-1.238,23.031c5.154-0.326,8.65-5.765,13.992-5.631c2.824,0.074,10.561,5.473,9.339,8.99
                        c-1.845,5.249-31.448,3.668-35.819,3.672c-5.932,0.004-14.309-0.31-20-2.25c-4.009-1.366-5.418-4.672-8.134-7.694
                        c3.15,7.915,8.593,11.861,15.884,11.861h71.334c6.627,0,12-5.373,12-12V193C143.334,186.373,137.961,181,131.334,181z"></path>
            </g>
            <g>
            <radialGradient id="SVGID_00000176747021121115316040000000139885166111444612_" cx="92.3105" cy="213.8438" r="32.6419" gradientUnits="userSpaceOnUse">
            <stop offset="0" style="stop-color:#1190FF"></stop>
            <stop offset="0.1201" style="stop-color:#0D91F3"></stop>
            <stop offset="0.3766" style="stop-color:#0693DE"></stop>
            <stop offset="0.6313" style="stop-color:#0195D2"></stop>
            <stop offset="0.8805" style="stop-color:#0095CE"></stop>
            </radialGradient>
            <path style="fill: #b7ce96;" d="M85.5,191.334
                        c0,0,11.333-0.667,16,13.166c0,0,6.063-12.125,10.938-17.687c4.875-5.563-6.938-5.75-6.938-5.75h19.313
                        c0,0-13.938,16.312-13.938,35.937s10.875,29.5,10.875,29.5l-22,0.125c0,0,7.688-0.812,9.813-2.75
                        c2.125-1.937-7.563-12.187-8.063-18.75c0,0-4.125,13.875-18.375,15.625L85.5,191.334z"></path>
            </g>
            <g>
            <g>
            <linearGradient id="SVGID_00000010300556335833328730000013990139485973317794_" gradientUnits="userSpaceOnUse" x1="85.46" y1="188" x2="85.46" y2="239.7718">
                  <stop offset="0.14" style="stop-color:#0095CE"></stop>
                  <stop offset="0.3727" style="stop-color:#1190FF"></stop>
                  <stop offset="0.5263" style="stop-color:#89C7FF"></stop>
                  <stop offset="0.6716" style="stop-color:#1190FF"></stop>
                  <stop offset="0.7821" style="stop-color:#0C91F2"></stop>
                  <stop offset="0.9877" style="stop-color:#0095CF"></stop>
                  <stop offset="0.9905" style="stop-color:#0095CE"></stop>
            </linearGradient>
            <path style="fill: #b7ce96;" d="M74.943,188.238h-5.318v53.858h5.318
                              c13.176,0,26.352-9.432,26.352-27.476C101.295,196.986,91.588,188.238,74.943,188.238z"></path>
            <g style="opacity:0.4;">
                  <radialGradient id="SVGID_00000129891292243797354660000008044121841905348512_" cx="73" cy="214" r="26.0012" gradientUnits="userSpaceOnUse">
                  <stop offset="0.1509" style="stop-color:#EED8F0"></stop>
                  <stop offset="0.4233" style="stop-color:#D296D8"></stop>
                  <stop offset="0.5754" style="stop-color:#CA90D3"></stop>
                  <stop offset="0.8071" style="stop-color:#B581C7"></stop>
                  <stop offset="0.8805" style="stop-color:#AD7BC2"></stop>
                  </radialGradient>
                  <path style="fill:url(#SVGID_00000129891292243797354660000008044121841905348512_);" d="M98.271,226.492
                                    c-2.611,2.997-7.994,6.575-12.083,6.491c-7.945-0.163-8.438-2.483-6.673-12.59c1.764-10.1-7.454-35.297,12.215-23.429
                                    c4.662,2.814,7.461,7.051,9.166,11.839c-1.972-13.738-11.256-20.565-25.953-20.565h-5.318v53.858h5.318
                                    c10.509,0,21.01-6.007,24.834-17.591C99.317,225.198,98.819,225.864,98.271,226.492z"></path>
            </g>
            <g style="opacity:0.4;">
                  <radialGradient id="SVGID_00000095305610968080233590000013124786832026588806_" cx="73.0002" cy="213.9999" r="26.0005" gradientUnits="userSpaceOnUse">
                  <stop offset="0.1509" style="stop-color:#EED8F0"></stop>
                  <stop offset="0.4233" style="stop-color:#D296D8"></stop>
                  <stop offset="0.5754" style="stop-color:#CA90D3"></stop>
                  <stop offset="0.8071" style="stop-color:#B581C7"></stop>
                  <stop offset="0.8805" style="stop-color:#AD7BC2"></stop>
                  </radialGradient>
                  <path style="fill: #b7ce96;" d="M92.527,195.749
                                    c3.313,2.133,5.674,5.053,7.332,8.383c-3.262-10.612-11.994-15.893-24.916-15.893h-5.318l0.541,4.262
                                    C78.666,191.334,85.543,191.251,92.527,195.749z"></path>
            </g>
            <g style="opacity:0.4;">
                  <radialGradient id="SVGID_00000027574927564219673670000017143627338913840317_" cx="72.9998" cy="213.9998" r="26.0016" gradientUnits="userSpaceOnUse">
                  <stop offset="0.1509" style="stop-color:#EED8F0"></stop>
                  <stop offset="0.4233" style="stop-color:#D296D8"></stop>
                  <stop offset="0.5754" style="stop-color:#CA90D3"></stop>
                  <stop offset="0.8071" style="stop-color:#B581C7"></stop>
                  <stop offset="0.8805" style="stop-color:#AD7BC2"></stop>
                  </radialGradient>
                  <path style="fill: #b7ce96;" d="M89.981,233.431
                                    c-6.267,2.778-13.759,3.184-20.356,1.28v7.385h5.318c9.986,0,19.953-5.435,24.203-15.915
                                    C96.693,229.222,93.751,231.76,89.981,233.431z"></path>
            </g>
            <g>
                  <linearGradient id="SVGID_00000167389691940824589860000016173647282052790669_" gradientUnits="userSpaceOnUse" x1="-27.3255" y1="204.2644" x2="-9.9943" y2="207.6238" gradientTransform="matrix(0.9786 -0.206 0.206 0.9786 45.9852 9.7836)">
                  <stop offset="0" style="stop-color:#5A6276"></stop>
                  <stop offset="0.0487" style="stop-color:#6D7184"></stop>
                  <stop offset="0.1585" style="stop-color:#94909E"></stop>
                  <stop offset="0.2466" style="stop-color:#ABA3AF"></stop>
                  <stop offset="0.3012" style="stop-color:#B4AAB5"></stop>
                  <stop offset="0.3902" style="stop-color:#BEB8C5"></stop>
                  <stop offset="0.5076" style="stop-color:#CED0E0"></stop>
                  <stop offset="0.6526" style="stop-color:#FAFBFF"></stop>
                  <stop offset="0.6955" style="stop-color:#F4F5FA"></stop>
                  <stop offset="0.7545" style="stop-color:#E2E4EA"></stop>
                  <stop offset="0.8228" style="stop-color:#C5C8D2"></stop>
                  <stop offset="0.8982" style="stop-color:#9CA2AF"></stop>
                  <stop offset="0.9785" style="stop-color:#697083"></stop>
                  <stop offset="1" style="stop-color:#5A6276"></stop>
                  </linearGradient>
                  <path style="fill:url(#SVGID_00000167389691940824589860000016173647282052790669_);" d="M78.181,215.168
                                    c-0.001,14.873-3.683,26.929-8.226,26.929c-4.543-0.002-8.224-12.057-8.223-26.93c0.001-14.873,3.684-26.928,8.227-26.928
                                    C74.5,188.239,78.182,200.295,78.181,215.168z"></path>
                  <g style="opacity:0.8;">
                  <g style="opacity:0.5;">
                  <linearGradient id="SVGID_00000121259362478391931400000010937181010637357232_" gradientUnits="userSpaceOnUse" x1="-868.2084" y1="350.4744" x2="-846.2872" y2="312.4727" gradientTransform="matrix(0.2763 -0.0124 0.0448 1.0029 290.9128 -128.1664)">
                        <stop offset="0.0053" style="stop-color:#626A7D"></stop>
                        <stop offset="0.5076" style="stop-color:#CED0E0"></stop>
                        <stop offset="0.509" style="stop-color:#D0D2E1"></stop>
                        <stop offset="0.5276" style="stop-color:#E5E6EE"></stop>
                        <stop offset="0.5483" style="stop-color:#F4F4F8"></stop>
                        <stop offset="0.5728" style="stop-color:#FCFCFD"></stop>
                        <stop offset="0.6105" style="stop-color:#FFFFFF"></stop>
                        <stop offset="0.6257" style="stop-color:#FFFFFF"></stop>
                        <stop offset="0.7182" style="stop-color:#FFFFFF"></stop>
                        <stop offset="0.7282" style="stop-color:#F4F4F6"></stop>
                        <stop offset="0.7578" style="stop-color:#D8DADE"></stop>
                        <stop offset="0.7898" style="stop-color:#C3C5CC"></stop>
                        <stop offset="0.8252" style="stop-color:#B4B6BF"></stop>
                        <stop offset="0.8664" style="stop-color:#ABAEB7"></stop>
                        <stop offset="0.9272" style="stop-color:#A8ABB5"></stop>
                  </linearGradient>
                  <path style="fill:url(#SVGID_00000121259362478391931400000010937181010637357232_);" d="M74.963,214.625
                                                c0.545,12.152-1.729,22.12-5.074,22.271c-3.347,0.151-6.499-9.578-7.041-21.722c-0.545-12.151,1.728-22.119,5.074-22.273
                                                C71.269,192.749,74.42,202.48,74.963,214.625z"></path>
                  </g>
                  <radialGradient id="SVGID_00000166639517381507362540000009650664173337961634_" cx="-40.1854" cy="182.1736" r="32.0677" gradientTransform="matrix(0.5247 -0.1979 0.138 0.9836 66.2146 33.9095)" gradientUnits="userSpaceOnUse">
                  <stop offset="0" style="stop-color:#FFFFFF"></stop>
                  <stop offset="0.2101" style="stop-color:#F3F2F4"></stop>
                  <stop offset="0.6002" style="stop-color:#D2CFD6"></stop>
                  <stop offset="0.678" style="stop-color:#CBC7CF"></stop>
                  <stop offset="0.6908" style="stop-color:#CBC7CF"></stop>
                  <stop offset="1" style="stop-color:#CBC7CF"></stop>
                  </radialGradient>
                  <path style="fill:url(#SVGID_00000166639517381507362540000009650664173337961634_);" d="M67.922,192.9
                                          c-3.347,0.154-5.619,10.122-5.074,22.273c0.542,12.145,3.694,21.874,7.041,21.722c3.346-0.15,5.619-10.119,5.074-22.271
                                          C74.42,202.48,71.269,192.749,67.922,192.9z M69.33,232.359c-6.845,0.302-8.568-38.223-1.725-38.534
                                          C74.449,193.522,76.172,232.05,69.33,232.359z"></path>
                  <radialGradient id="SVGID_00000129172817887708754580000015315171020329714586_" cx="-862.5104" cy="335.0349" r="27.8977" gradientTransform="matrix(0.2763 -0.0124 0.0448 1.0029 290.9128 -128.1664)" gradientUnits="userSpaceOnUse">
                  <stop offset="0" style="stop-color:#FFFFFF"></stop>
                  <stop offset="0.2101" style="stop-color:#F3F2F4"></stop>
                  <stop offset="0.6002" style="stop-color:#D2CFD6"></stop>
                  <stop offset="0.678" style="stop-color:#CBC7CF"></stop>
                  <stop offset="0.6908" style="stop-color:#CBC7CF"></stop>
                  <stop offset="1" style="stop-color:#CBC7CF"></stop>
                  </radialGradient>
                  <path style="fill:url(#SVGID_00000129172817887708754580000015315171020329714586_);" d="M69.533,230.777
                                          c-2.359,0.777-5.283-6.473-5.573-17.477c-0.265-10.033,1.6-17.812,3.179-17.543c1.577,0.263,1.236,2.953-0.001,12.375
                                          c-1.237,9.426,1.578,14.654,1.96,14.969C69.48,223.423,72.856,229.69,69.533,230.777z"></path>
                  </g>
                  <defs>
                  <filter id="Adobe_OpacityMaskFilter_00000142878199212781521700000015037998103850667195_" filterUnits="userSpaceOnUse" x="63.258" y="188.239" width="14.923" height="53.857">
                  <feFlood style="flood-color:white;flood-opacity:1" result="back"></feFlood>
                  <feBlend in="SourceGraphic" in2="back" mode="normal"></feBlend>
                  </filter>
                  </defs>
                  <mask maskUnits="userSpaceOnUse" x="63.258" y="188.239" width="14.923" height="53.857" id="SVGID_00000119800598170698680850000010190309326168862889_">
                  <g style="filter:url(#Adobe_OpacityMaskFilter_00000142878199212781521700000015037998103850667195_);">
                  <defs>
                        <filter id="Adobe_OpacityMaskFilter_00000156556384525622708990000009651811989297646759_" filterUnits="userSpaceOnUse" x="63.258" y="188.239" width="14.923" height="53.857">
                        <feFlood style="flood-color:white;flood-opacity:1" result="back"></feFlood>
                        <feBlend in="SourceGraphic" in2="back" mode="normal"></feBlend>
                        </filter>
                  </defs>
                  <mask maskUnits="userSpaceOnUse" x="63.258" y="188.239" width="14.923" height="53.857" id="SVGID_00000119800598170698680850000010190309326168862889_">
                        <g style="filter:url(#Adobe_OpacityMaskFilter_00000156556384525622708990000009651811989297646759_);"></g>
                  </mask>
                  <linearGradient id="SVGID_00000120533893214217730860000016687294285255112892_" gradientUnits="userSpaceOnUse" x1="-27.3258" y1="204.2643" x2="-9.9943" y2="207.6238" gradientTransform="matrix(0.9786 -0.206 0.206 0.9786 45.9852 9.7836)">
                        <stop offset="0.0546" style="stop-color:#808080"></stop>
                        <stop offset="0.0902" style="stop-color:#868686"></stop>
                        <stop offset="0.139" style="stop-color:#979797"></stop>
                        <stop offset="0.1956" style="stop-color:#B5B5B5"></stop>
                        <stop offset="0.2575" style="stop-color:#DDDDDD"></stop>
                        <stop offset="0.3031" style="stop-color:#FFFFFF"></stop>
                        <stop offset="1" style="stop-color:#FFFFFF"></stop>
                  </linearGradient>
                  <path style="mask:url(#SVGID_00000119800598170698680850000010190309326168862889_);fill:url(#SVGID_00000120533893214217730860000016687294285255112892_);" d="
                                                M69.959,188.239c-0.726-0.001-1.429,0.311-2.099,0.888c7.645-0.225,8.646,19.354,8.583,24.222
                                                c-0.041,3.123,0.976,19.451-5.665,26.542c-2.893,3.088-5.821-3.238-7.52-9.105c1.492,6.843,3.934,11.309,6.697,11.31
                                                c4.543,0,8.225-12.056,8.226-26.929C78.182,200.295,74.5,188.239,69.959,188.239z"></path>
                  </g>
                  </mask>
                  <linearGradient id="SVGID_00000106867292981678803740000000749159888603461268_" gradientUnits="userSpaceOnUse" x1="-27.3258" y1="204.2643" x2="-9.9943" y2="207.6238" gradientTransform="matrix(0.9786 -0.206 0.206 0.9786 45.9852 9.7836)">
                  <stop offset="0.0546" style="stop-color:#FFFCFA"></stop>
                  <stop offset="0.1263" style="stop-color:#FAF7F4"></stop>
                  <stop offset="0.2246" style="stop-color:#EDE8E2"></stop>
                  <stop offset="0.3031" style="stop-color:#DFD8CF"></stop>
                  <stop offset="1" style="stop-color:#74665C"></stop>
                  </linearGradient>
                  <path style="opacity:0.5;fill:url(#SVGID_00000106867292981678803740000000749159888603461268_);" d="M69.959,188.239
                                    c-0.726-0.001-1.429,0.311-2.099,0.888c7.645-0.225,8.646,19.354,8.583,24.222c-0.041,3.123,0.976,19.451-5.665,26.542
                                    c-2.893,3.088-5.821-3.238-7.52-9.105c1.492,6.843,3.934,11.309,6.697,11.31c4.543,0,8.225-12.056,8.226-26.929
                                    C78.182,200.295,74.5,188.239,69.959,188.239z"></path>
                  <path style="fill:#FFFFFF;" d="M68.476,209.191c-1.815-0.066-1.345-12.749,0.472-12.684
                                    C70.764,196.575,70.292,209.258,68.476,209.191z"></path>
                  <path style="fill:#FFFFFF;" d="M67.34,191.787c-2.816,2.4-5.037,14.305-4.988,22.556c0.049,8.252,1.813,20.585,5.65,24.383
                                    c1.313,1.298-1.436-1.271-2.639-14.394c-1.204-13.124,0.178-22.594,1.475-28.768C68.135,189.391,68.771,190.567,67.34,191.787z"></path>
                  <path style="fill:#FFFFFF;" d="M72.904,200.403c0,0,5.361,26.119-2.639,37.699c0,0,5.412-2.63,5.262-20.086
                                    C75.377,200.56,72.904,200.403,72.904,200.403z"></path>
            </g>
            </g>
            <defs>
            <filter id="Adobe_OpacityMaskFilter_00000072273703008656258140000006026480414256141186_" filterUnits="userSpaceOnUse" x="76.334" y="197.166" width="24.291" height="14.459">
                  <feFlood style="flood-color:white;flood-opacity:1" result="back"></feFlood>
                  <feBlend in="SourceGraphic" in2="back" mode="normal"></feBlend>
            </filter>
            </defs>
            <mask maskUnits="userSpaceOnUse" x="76.334" y="197.166" width="24.291" height="14.459" id="SVGID_00000134949958305785042310000005411342652213466755_">
            <g style="filter:url(#Adobe_OpacityMaskFilter_00000072273703008656258140000006026480414256141186_);">
                  <defs>
                  <filter id="Adobe_OpacityMaskFilter_00000106853632368457753310000000070170563903111090_" filterUnits="userSpaceOnUse" x="76.334" y="197.166" width="24.291" height="14.459">
                  <feFlood style="flood-color:white;flood-opacity:1" result="back"></feFlood>
                  <feBlend in="SourceGraphic" in2="back" mode="normal"></feBlend>
                  </filter>
                  </defs>
                  <mask maskUnits="userSpaceOnUse" x="76.334" y="197.166" width="24.291" height="14.459" id="SVGID_00000134949958305785042310000005411342652213466755_">
                  <g style="filter:url(#Adobe_OpacityMaskFilter_00000106853632368457753310000000070170563903111090_);"></g>
                  </mask>
                  <linearGradient id="SVGID_00000125568411222720054870000005084607438574800271_" gradientUnits="userSpaceOnUse" x1="76.334" y1="204.3956" x2="100.625" y2="204.3956">
                  <stop offset="0.0519" style="stop-color:#FFFFFF"></stop>
                  <stop offset="1" style="stop-color:#000000"></stop>
                  </linearGradient>
                  <path style="mask:url(#SVGID_00000134949958305785042310000005411342652213466755_);fill:url(#SVGID_00000125568411222720054870000005084607438574800271_);" d="
                                    M76.334,197.167c0,0,2.582,5.833,2.332,12.833c0,0,12.334,0,21.959,1.625C100.625,211.625,95.334,197.042,76.334,197.167z"></path>
            </g>
            </mask>
            <linearGradient id="SVGID_00000033341517192268848420000013995920840125796281_" gradientUnits="userSpaceOnUse" x1="76.334" y1="204.3956" x2="100.625" y2="204.3956">
            <stop offset="0.0519" style="stop-color:#FFFFFF"></stop>
            <stop offset="1" style="stop-color:#FF1710"></stop>
            </linearGradient>
            <path style="fill: #b7ce96;" d="M76.334,197.167
                        c0,0,2.582,5.833,2.332,12.833c0,0,12.334,0,21.959,1.625C100.625,211.625,95.334,197.042,76.334,197.167z"></path>
            <defs>
            <filter id="Adobe_OpacityMaskFilter_00000178894334156465833550000013480865967352009660_" filterUnits="userSpaceOnUse" x="77.125" y="219.25" width="23.25" height="10.684">
                  <feFlood style="flood-color:white;flood-opacity:1" result="back"></feFlood>
                  <feBlend in="SourceGraphic" in2="back" mode="normal"></feBlend>
            </filter>
            </defs>
            <mask maskUnits="userSpaceOnUse" x="77.125" y="219.25" width="23.25" height="10.684" id="SVGID_00000101809961477616909520000014855627841660023980_">
            <g style="filter:url(#Adobe_OpacityMaskFilter_00000178894334156465833550000013480865967352009660_);">
                  <defs>
                  <filter id="Adobe_OpacityMaskFilter_00000063592627831600588110000009309292286478166691_" filterUnits="userSpaceOnUse" x="77.125" y="219.25" width="23.25" height="10.684">
                  <feFlood style="flood-color:white;flood-opacity:1" result="back"></feFlood>
                  <feBlend in="SourceGraphic" in2="back" mode="normal"></feBlend>
                  </filter>
                  </defs>
                  <mask maskUnits="userSpaceOnUse" x="77.125" y="219.25" width="23.25" height="10.684" id="SVGID_00000101809961477616909520000014855627841660023980_">
                  <g style="filter:url(#Adobe_OpacityMaskFilter_00000063592627831600588110000009309292286478166691_);"></g>
                  </mask>
                  <linearGradient id="SVGID_00000065036490701449916790000016076488382539733394_" gradientUnits="userSpaceOnUse" x1="77.125" y1="224.592" x2="100.375" y2="224.592">
                  <stop offset="0.0519" style="stop-color:#FFFFFF"></stop>
                  <stop offset="1" style="stop-color:#000000"></stop>
                  </linearGradient>
                  <path style="mask:url(#SVGID_00000101809961477616909520000014855627841660023980_);fill:url(#SVGID_00000065036490701449916790000016076488382539733394_);" d="
                                    M78.5,221.334c0,0-0.5,5.916-1.375,8.541c0,0,18.875,1.5,23.25-10.625C100.375,219.25,95.334,223.334,78.5,221.334z"></path>
            </g>
            </mask>
            <linearGradient id="SVGID_00000070096245555015833900000018046537344974267045_" gradientUnits="userSpaceOnUse" x1="77.125" y1="224.592" x2="100.375" y2="224.592">
            <stop offset="0.0519" style="stop-color:#FFFFFF"></stop>
            <stop offset="1" style="stop-color:#FF1710"></stop>
            </linearGradient>
            <path style="opacity: 0.5; fill: #b7ce96;" d="M78.5,221.334
                        c0,0-0.5,5.916-1.375,8.541c0,0,18.875,1.5,23.25-10.625C100.375,219.25,95.334,223.334,78.5,221.334z"></path>
            <g>
            <defs>
                  <filter id="Adobe_OpacityMaskFilter_00000132075307172234959980000005966790222603454356_" filterUnits="userSpaceOnUse" x="73.5" y="189.501" width="7.334" height="50.333">
                  <feFlood style="flood-color:white;flood-opacity:1" result="back"></feFlood>
                  <feBlend in="SourceGraphic" in2="back" mode="normal"></feBlend>
                  </filter>
            </defs>
            <mask maskUnits="userSpaceOnUse" x="73.5" y="189.501" width="7.334" height="50.333" id="SVGID_00000051367930154861294990000011935806819043001994_">
                  <g style="filter:url(#Adobe_OpacityMaskFilter_00000132075307172234959980000005966790222603454356_);">
                  <defs>
                  <filter id="Adobe_OpacityMaskFilter_00000085950897239804561490000004740752089076605321_" filterUnits="userSpaceOnUse" x="73.5" y="189.501" width="7.334" height="50.333">
                        <feFlood style="flood-color:white;flood-opacity:1" result="back"></feFlood>
                        <feBlend in="SourceGraphic" in2="back" mode="normal"></feBlend>
                  </filter>
                  </defs>
                  <mask maskUnits="userSpaceOnUse" x="73.5" y="189.501" width="7.334" height="50.333" id="SVGID_00000051367930154861294990000011935806819043001994_">
                  <g style="filter:url(#Adobe_OpacityMaskFilter_00000085950897239804561490000004740752089076605321_);"></g>
                  </mask>
                  <radialGradient id="SVGID_00000003813285026501146180000005370919876718552740_" cx="77.167" cy="212.667" r="25.5006" gradientUnits="userSpaceOnUse">
                  <stop offset="0.2941" style="stop-color:#FFFFFF"></stop>
                  <stop offset="1" style="stop-color:#000000"></stop>
                  </radialGradient>
                  <path style="mask:url(#SVGID_00000051367930154861294990000011935806819043001994_);fill:url(#SVGID_00000003813285026501146180000005370919876718552740_);" d="
                                          M73.5,189.501c0,0,6.791,6.498,6,27.833c-0.666,18-4.666,22.5-4.666,22.5s6-4,6-25.5S73.5,189.501,73.5,189.501z"></path>
                  </g>
            </mask>
            <radialGradient id="SVGID_00000001622424349287021400000009543009819901328549_" cx="77.167" cy="212.667" r="25.5006" gradientUnits="userSpaceOnUse">
                  <stop offset="0.2941" style="stop-color:#FFFFFF"></stop>
                  <stop offset="1" style="stop-color:#5DB4FF"></stop>
            </radialGradient>
            <path style="fill:url(#SVGID_00000001622424349287021400000009543009819901328549_);" d="M73.5,189.501c0,0,6.791,6.498,6,27.833
                              c-0.666,18-4.666,22.5-4.666,22.5s6-4,6-25.5S73.5,189.501,73.5,189.501z"></path>
            </g>
            <g>
            <radialGradient id="SVGID_00000181060620032621993140000007424242230273601466_" cx="75.3339" cy="212.667" r="25.5005" gradientUnits="userSpaceOnUse">
                  <stop offset="0.1195" style="stop-color:#AD7BC2"></stop>
                  <stop offset="0.1929" style="stop-color:#B581C7"></stop>
                  <stop offset="0.4246" style="stop-color:#CA90D3"></stop>
                  <stop offset="0.5767" style="stop-color:#D296D8"></stop>
                  <stop offset="0.8491" style="stop-color:#EED8F0"></stop>
            </radialGradient>
            <path style="fill:url(#SVGID_00000181060620032621993140000007424242230273601466_);" d="M71.668,189.501
                              c0,0,6.79,6.498,6,27.833c-0.668,18-4.668,22.5-4.668,22.5s6-4,6-25.5S71.668,189.501,71.668,189.501z"></path>
            </g>
            </g>
      </g>
      <g>
            <path style="fill:#1190FF;" d="M551.75,178.75c0,0,41.5,3.5,56,5s20.75,11.5,22.5,14.75s1,23.5,0.25,28s-2,13.25-16,19.75
                  s-52,12.75-52,12.75L551.75,178.75z"></path>
            <g style="opacity:0.7;">
            <radialGradient id="SVGID_00000117667392309278788920000002875972180149796533_" cx="591.5358" cy="218.875" r="39.9558" gradientUnits="userSpaceOnUse">
            <stop offset="0" style="stop-color:#1190FF"></stop>
            <stop offset="0.1201" style="stop-color:#0D91F3"></stop>
            <stop offset="0.3766" style="stop-color:#0693DE"></stop>
            <stop offset="0.6313" style="stop-color:#0195D2"></stop>
            <stop offset="0.8805" style="stop-color:#0095CE"></stop>
            </radialGradient>
            <path style="fill:url(#SVGID_00000117667392309278788920000002875972180149796533_);" d="M551.75,178.75c0,0,41.5,3.5,56,5
                        s20.75,11.5,22.5,14.75s1,23.5,0.25,28s-2,13.25-16,19.75s-52,12.75-52,12.75L551.75,178.75z"></path>
            </g>
            <g>
            <radialGradient id="SVGID_00000145047861092765775750000016477167317224024755_" cx="623.5714" cy="221" r="38.9947" gradientUnits="userSpaceOnUse">
            <stop offset="0" style="stop-color:#1190FF"></stop>
            <stop offset="0.1201" style="stop-color:#0D91F3"></stop>
            <stop offset="0.3766" style="stop-color:#0693DE"></stop>
            <stop offset="0.6313" style="stop-color:#0195D2"></stop>
            <stop offset="0.8805" style="stop-color:#0095CE"></stop>
            </radialGradient>
            <path style="fill:url(#SVGID_00000145047861092765775750000016477167317224024755_);" d="M607.173,245.296
                        c-2.291-0.444-4.512-1.097-6.772-1.659c-1.177-0.293-2.243-0.523-3.173-1.326c-1.353-1.168-1.09-3.365-0.727-6.478
                        c0.304-2.599,4.692,0.702,6.599,1.779c3.58,2.021,7.877,2.753,11.917,3.365c3.182,0.481,5.966,0.399,8.557-0.615
                        c5.591-5.208,6.388-10.631,6.927-13.862c0.632-3.793,0.365-19.439-0.499-26c-1.667-5.166-5.103-9.37-11.251-9.72
                        c-3.966-0.226-7.604,1.167-11.31,2.378c-2.508,0.819-5.78,2.143-8.481,1.642c-1.977-0.367-1.965-1.999-2.44-3.668
                        c-0.435-1.526-1.147-3.745,0.571-4.801c5.262-3.231,12.018-0.695,17.951-0.949c-2.158-0.775-4.58-1.353-7.291-1.633
                        c-14.5-1.5-56-5-56-5L562.5,259c0,0,38-6.25,52-12.75c0.197-0.091,0.383-0.185,0.574-0.277
                        C612.431,245.964,609.747,245.795,607.173,245.296z"></path>
            </g>
            <g>
            <linearGradient id="SVGID_00000141457770405307565140000007234338794631580341_" gradientUnits="userSpaceOnUse" x1="596.3231" y1="215.9393" x2="630.192" y2="215.9393">
            <stop offset="0" style="stop-color:#A6A78F"></stop>
            <stop offset="0.2447" style="stop-color:#E3E3DD"></stop>
            <stop offset="0.338" style="stop-color:#C0C1B0"></stop>
            <stop offset="0.4781" style="stop-color:#8F906F"></stop>
            <stop offset="0.8426" style="stop-color:#FFFFFF"></stop>
            <stop offset="0.8612" style="stop-color:#EDEDE8"></stop>
            <stop offset="0.9074" style="stop-color:#C5C5B4"></stop>
            <stop offset="0.9475" style="stop-color:#A8A88F"></stop>
            <stop offset="0.9798" style="stop-color:#969777"></stop>
            <stop offset="1" style="stop-color:#8F906F"></stop>
            </linearGradient>
            <path style="fill:url(#SVGID_00000141457770405307565140000007234338794631580341_);" d="M596.668,212.5
                        c-0.535,12.489-1.5,21.667,6.5,23.667s20.333,9.834,24.333-4.833c4-14.667,3-23.667,0.5-31.167s-7.333-11-20-6
                        C595.334,199.167,597.168,200.834,596.668,212.5z"></path>
            <linearGradient id="SVGID_00000159457685507338344020000016742960093737087395_" gradientUnits="userSpaceOnUse" x1="596.3231" y1="215.9393" x2="630.192" y2="215.9393">
            <stop offset="0" style="stop-color:#71798E"></stop>
            <stop offset="1" style="stop-color:#71798E"></stop>
            </linearGradient>
            <path style="opacity:0.3;fill:url(#SVGID_00000159457685507338344020000016742960093737087395_);" d="M596.668,212.5
                        c-0.535,12.489-1.5,21.667,6.5,23.667s20.333,9.834,24.333-4.833c4-14.667,3-23.667,0.5-31.167s-7.333-11-20-6
                        C595.334,199.167,597.168,200.834,596.668,212.5z"></path>
            <linearGradient id="SVGID_00000101104888319230940680000008532138287823174021_" gradientUnits="userSpaceOnUse" x1="596.3231" y1="215.9392" x2="630.192" y2="215.9392">
            <stop offset="0" style="stop-color:#71798E"></stop>
            <stop offset="1" style="stop-color:#71798E"></stop>
            </linearGradient>
            <path style="opacity:0.6;fill:url(#SVGID_00000101104888319230940680000008532138287823174021_);" d="M628.001,200.167
                        c-1.696-5.088-4.468-8.336-10.029-8.336c-2.637,0-5.897,0.729-9.971,2.336c-12.667,5-10.833,6.667-11.333,18.333
                        c-0.535,12.489-1.5,21.667,6.5,23.667c4.336,1.084,9.945,3.882,14.811,3.882c4.111,0,7.69-1.997,9.522-8.715
                        C631.501,216.667,630.501,207.667,628.001,200.167z M626.536,231.07c-1.95,7.151-5.818,7.978-8.558,7.978
                        c-3.073,0-6.489-1.188-9.792-2.337c-1.69-0.587-3.286-1.143-4.776-1.515c-6.701-1.675-6.361-9.096-5.848-20.328
                        c0.035-0.761,0.07-1.537,0.104-2.325c0.065-1.52,0.091-2.877,0.114-4.075c0.149-7.848,0.177-9.263,10.587-13.372
                        c3.863-1.525,7.005-2.267,9.604-2.267c4.577,0,7.293,2.289,9.081,7.653C630.069,209.534,629.915,218.682,626.536,231.07z"></path>
            <g>
            <g>
                  <linearGradient id="SVGID_00000134239356995043171030000008419074665369258386_" gradientUnits="userSpaceOnUse" x1="1056.5013" y1="-451.4017" x2="1087.7833" y2="-445.3381" gradientTransform="matrix(-0.9786 0.206 -0.206 -0.9786 1571.0422 -443.4499)">
                  <stop offset="0" style="stop-color:#5A6276"></stop>
                  <stop offset="0.0487" style="stop-color:#6D7184"></stop>
                  <stop offset="0.1585" style="stop-color:#94909E"></stop>
                  <stop offset="0.2466" style="stop-color:#ABA3AF"></stop>
                  <stop offset="0.3012" style="stop-color:#B4AAB5"></stop>
                  <stop offset="0.3902" style="stop-color:#BEB8C5"></stop>
                  <stop offset="0.5076" style="stop-color:#CED0E0"></stop>
                  <stop offset="0.6526" style="stop-color:#FAFBFF"></stop>
                  <stop offset="0.6955" style="stop-color:#F4F5FA"></stop>
                  <stop offset="0.7545" style="stop-color:#E2E4EA"></stop>
                  <stop offset="0.8228" style="stop-color:#C5C8D2"></stop>
                  <stop offset="0.8982" style="stop-color:#9CA2AF"></stop>
                  <stop offset="0.9785" style="stop-color:#697083"></stop>
                  <stop offset="1" style="stop-color:#5A6276"></stop>
                  </linearGradient>
                  <path style="fill:url(#SVGID_00000134239356995043171030000008419074665369258386_);" d="M599.694,216.181
                                    c0.002-11.269,6.653-20.403,14.862-20.403c8.211,0.002,14.86,9.135,14.86,20.403c-0.002,11.269-6.658,20.402-14.867,20.402
                                    C606.346,236.583,599.692,227.449,599.694,216.181z"></path>
                  <g style="opacity:0.8;">
                  <g style="opacity:0.5;">
                  <linearGradient id="SVGID_00000103952218200155425560000012889464452413683634_" gradientUnits="userSpaceOnUse" x1="2635.9026" y1="-467.5197" x2="2659.1157" y2="-507.7609" gradientTransform="matrix(-0.2763 0.0124 -0.0448 -1.0029 1326.115 -305.4998)">
                        <stop offset="0.0053" style="stop-color:#626A7D"></stop>
                        <stop offset="0.5076" style="stop-color:#CED0E0"></stop>
                        <stop offset="0.509" style="stop-color:#D0D2E1"></stop>
                        <stop offset="0.5276" style="stop-color:#E5E6EE"></stop>
                        <stop offset="0.5483" style="stop-color:#F4F4F8"></stop>
                        <stop offset="0.5728" style="stop-color:#FCFCFD"></stop>
                        <stop offset="0.6105" style="stop-color:#FFFFFF"></stop>
                        <stop offset="0.6257" style="stop-color:#FFFFFF"></stop>
                        <stop offset="0.7182" style="stop-color:#FFFFFF"></stop>
                        <stop offset="0.7282" style="stop-color:#F4F4F6"></stop>
                        <stop offset="0.7578" style="stop-color:#D8DADE"></stop>
                        <stop offset="0.7898" style="stop-color:#C3C5CC"></stop>
                        <stop offset="0.8252" style="stop-color:#B4B6BF"></stop>
                        <stop offset="0.8664" style="stop-color:#ABAEB7"></stop>
                        <stop offset="0.9272" style="stop-color:#A8ABB5"></stop>
                  </linearGradient>
                  <path style="fill:url(#SVGID_00000103952218200155425560000012889464452413683634_);" d="M605.509,216.592
                                                c-0.986-9.207,3.123-16.76,9.169-16.873c6.048-0.115,11.743,7.257,12.723,16.458c0.985,9.206-3.121,16.759-9.169,16.876
                                                C612.185,233.167,606.489,225.794,605.509,216.592z"></path>
                  </g>
                  <radialGradient id="SVGID_00000157292583528569678320000012969840851033380284_" cx="972.3085" cy="-698.185" r="23.9175" gradientTransform="matrix(-2.1378 0 0 -0.8964 2694.1931 -414.4138)" gradientUnits="userSpaceOnUse">
                  <stop offset="0" style="stop-color:#FFFFFF"></stop>
                  <stop offset="0.2101" style="stop-color:#F3F2F4"></stop>
                  <stop offset="0.6002" style="stop-color:#D2CFD6"></stop>
                  <stop offset="0.678" style="stop-color:#CBC7CF"></stop>
                  <stop offset="0.6908" style="stop-color:#CBC7CF"></stop>
                  <stop offset="1" style="stop-color:#CBC7CF"></stop>
                  </radialGradient>
                  <path style="fill:url(#SVGID_00000157292583528569678320000012969840851033380284_);" d="M618.231,233.052
                                          c6.048-0.117,10.154-7.669,9.169-16.876c-0.979-9.201-6.675-16.573-12.723-16.458c-6.046,0.114-10.155,7.667-9.169,16.873
                                          C606.489,225.794,612.185,233.167,618.231,233.052z M615.687,203.155c12.369-0.229,15.484,28.96,3.116,29.195
                                          C606.438,232.581,603.322,203.389,615.687,203.155z"></path>
                  <radialGradient id="SVGID_00000090287088636674081360000006773141751350504873_" cx="2547.1262" cy="-539.497" r="23.5806" gradientTransform="matrix(-0.5907 0.0111 -0.0958 -0.899 2071.6978 -299.6519)" gradientUnits="userSpaceOnUse">
                  <stop offset="0" style="stop-color:#FFFFFF"></stop>
                  <stop offset="0.2101" style="stop-color:#F3F2F4"></stop>
                  <stop offset="0.6002" style="stop-color:#D2CFD6"></stop>
                  <stop offset="0.678" style="stop-color:#CBC7CF"></stop>
                  <stop offset="0.6908" style="stop-color:#CBC7CF"></stop>
                  <stop offset="1" style="stop-color:#CBC7CF"></stop>
                  </radialGradient>
                  <path style="fill:url(#SVGID_00000090287088636674081360000006773141751350504873_);" d="M615.32,204.354
                                          c4.264-0.589,9.548,4.904,10.071,13.242c0.479,7.602-2.892,13.496-5.745,13.292c-2.85-0.199-2.234-2.237,0.002-9.376
                                          c2.236-7.142-2.852-11.103-3.541-11.341C615.415,209.926,609.314,205.178,615.32,204.354z"></path>
                  </g>
                  <defs>
                  <filter id="Adobe_OpacityMaskFilter_00000140705005941855929590000014565063369739214267_" filterUnits="userSpaceOnUse" x="599.694" y="195.778" width="26.965" height="40.806">
                  <feFlood style="flood-color:white;flood-opacity:1" result="back"></feFlood>
                  <feBlend in="SourceGraphic" in2="back" mode="normal"></feBlend>
                  </filter>
                  </defs>
                  <mask maskUnits="userSpaceOnUse" x="599.694" y="195.778" width="26.965" height="40.806" id="SVGID_00000074437839641322654010000013105605773846830237_">
                  <g style="filter:url(#Adobe_OpacityMaskFilter_00000140705005941855929590000014565063369739214267_);">
                  <defs>
                        <filter id="Adobe_OpacityMaskFilter_00000132774891542517408830000017574178030294192570_" filterUnits="userSpaceOnUse" x="599.694" y="195.778" width="26.965" height="40.806">
                        <feFlood style="flood-color:white;flood-opacity:1" result="back"></feFlood>
                        <feBlend in="SourceGraphic" in2="back" mode="normal"></feBlend>
                        </filter>
                  </defs>
                  <mask maskUnits="userSpaceOnUse" x="599.694" y="195.778" width="26.965" height="40.806" id="SVGID_00000074437839641322654010000013105605773846830237_">
                        <g style="filter:url(#Adobe_OpacityMaskFilter_00000132774891542517408830000017574178030294192570_);"></g>
                  </mask>
                  <linearGradient id="SVGID_00000110433598636656475040000002825218847778537373_" gradientUnits="userSpaceOnUse" x1="1056.7725" y1="-451.3492" x2="1087.7706" y2="-445.3406" gradientTransform="matrix(-0.9786 0.206 -0.206 -0.9786 1571.0422 -443.4499)">
                        <stop offset="0.0546" style="stop-color:#808080"></stop>
                        <stop offset="0.0902" style="stop-color:#868686"></stop>
                        <stop offset="0.139" style="stop-color:#979797"></stop>
                        <stop offset="0.1956" style="stop-color:#B5B5B5"></stop>
                        <stop offset="0.2575" style="stop-color:#DDDDDD"></stop>
                        <stop offset="0.3031" style="stop-color:#FFFFFF"></stop>
                        <stop offset="1" style="stop-color:#FFFFFF"></stop>
                  </linearGradient>
                  <path style="mask:url(#SVGID_00000074437839641322654010000013105605773846830237_);fill:url(#SVGID_00000110433598636656475040000002825218847778537373_);" d="
                                                M614.55,236.584c1.312,0.001,2.583-0.235,3.794-0.673c-13.814,0.171-15.625-14.664-15.512-18.352
                                                c0.075-2.365-1.763-14.737,10.238-20.11c5.227-2.339,10.519,2.454,13.589,6.899c-2.695-5.185-7.107-8.568-12.103-8.569
                                                c-8.209,0-14.86,9.134-14.862,20.403C599.692,227.449,606.346,236.583,614.55,236.584z"></path>
                  </g>
                  </mask>
                  <linearGradient id="SVGID_00000016049854421675056080000001427347916972713357_" gradientUnits="userSpaceOnUse" x1="1056.7725" y1="-451.3492" x2="1087.7706" y2="-445.3406" gradientTransform="matrix(-0.9786 0.206 -0.206 -0.9786 1571.0422 -443.4499)">
                  <stop offset="0.0546" style="stop-color:#FFFCFA"></stop>
                  <stop offset="0.1263" style="stop-color:#FAF7F4"></stop>
                  <stop offset="0.2246" style="stop-color:#EDE8E2"></stop>
                  <stop offset="0.3031" style="stop-color:#DFD8CF"></stop>
                  <stop offset="1" style="stop-color:#74665C"></stop>
                  </linearGradient>
                  <path style="opacity:0.5;fill:url(#SVGID_00000016049854421675056080000001427347916972713357_);" d="M614.55,236.584
                                    c1.312,0.001,2.583-0.235,3.794-0.673c-13.814,0.171-15.625-14.664-15.512-18.352c0.075-2.365-1.763-14.737,10.238-20.11
                                    c5.227-2.339,10.519,2.454,13.589,6.899c-2.695-5.185-7.107-8.568-12.103-8.569c-8.209,0-14.86,9.134-14.862,20.403
                                    C599.692,227.449,606.346,236.583,614.55,236.584z"></path>
                  <path style="fill:#FFFFFF;" d="M617.23,220.709c3.28,0.05,2.431,9.66-0.852,9.61
                                    C613.097,230.268,613.949,220.658,617.23,220.709z"></path>
                  <path style="fill:#FFFFFF;" d="M619.283,233.895c5.09-1.818,9.102-10.838,9.015-17.09c-0.09-6.253-3.277-15.596-10.211-18.474
                                    c-2.372-0.984,2.593,0.963,4.768,10.906c2.175,9.943-0.321,17.118-2.664,21.796
                                    C617.847,235.711,616.696,234.819,619.283,233.895z"></path>
                  <path style="fill:#FFFFFF;" d="M609.229,227.367c0,0-9.689-19.789,4.768-28.563c0,0-9.781,1.993-9.51,15.219
                                    C604.759,227.249,609.229,227.367,609.229,227.367z"></path>
            </g>
            <linearGradient id="SVGID_00000061445504016515659720000010453200282303104902_" gradientUnits="userSpaceOnUse" x1="974.24" y1="-703.8974" x2="980.4376" y2="-703.8974" gradientTransform="matrix(-2.1378 0 0 -0.8964 2694.1931 -414.4138)">
                  <stop offset="0" style="stop-color:#71798E"></stop>
                  <stop offset="1" style="stop-color:#71798E"></stop>
            </linearGradient>
            <path style="opacity:0.6;fill:url(#SVGID_00000061445504016515659720000010453200282303104902_);" d="M611.463,235.627
                              c0,0-12.27-4.923-10.842-21.088c1.206-13.638,8.435-17.047,8.435-17.047s-10.842,3.031-10.842,19.32
                              S611.463,235.627,611.463,235.627z"></path>
            </g>
            </g>
            <g>
            <radialGradient id="SVGID_00000032623905819052773210000000010602125805263514_" cx="708.5012" cy="215.5" r="120.3767" gradientUnits="userSpaceOnUse">
            <stop offset="0.826" style="stop-color:#DDE9FF"></stop>
            <stop offset="0.8477" style="stop-color:#A4BAD8"></stop>
            <stop offset="0.8694" style="stop-color:#7292B6"></stop>
            <stop offset="0.8909" style="stop-color:#49709B"></stop>
            <stop offset="0.9117" style="stop-color:#295785"></stop>
            <stop offset="0.9317" style="stop-color:#124475"></stop>
            <stop offset="0.9507" style="stop-color:#05396C"></stop>
            <stop offset="0.9674" style="stop-color:#003569"></stop>
            </radialGradient>
            <path style="fill: #9cba73;" d="M551.75,178.75c0,0,41.5,3.5,56,5
                        s20.75,11.5,22.5,14.75s1,23.5,0.25,28s-2,13.25-16,19.75s-52,12.75-52,12.75L551.75,178.75z"></path>
            </g>
      </g>
      <g>
            <linearGradient id="SVGID_00000026877742708269336090000000980912094368374916_" gradientUnits="userSpaceOnUse" x1="175.667" y1="219.9707" x2="379.0835" y2="219.9707">
            <stop offset="0.2234" style="stop-color:#2A2828"></stop>
            <stop offset="0.4417" style="stop-color:#55554C"></stop>
            <stop offset="0.5596" style="stop-color:#67685B"></stop>
            <stop offset="0.6491" style="stop-color:#636457"></stop>
            <stop offset="0.7461" style="stop-color:#56574D"></stop>
            <stop offset="0.8462" style="stop-color:#42413C"></stop>
            <stop offset="0.9332" style="stop-color:#2A2828"></stop>
            </linearGradient>
            <path style="fill:url(#SVGID_00000026877742708269336090000000980912094368374916_);" d="M376.334,210.667
                  c0-21.656,5.747-72.836-0.001-77.333c-0.888-0.694-5.706,6.573-7,8C359.666,152,347,157,329,157s-76.666,0.667-87.666,0.334
                  c-11-0.334-21.334-9.667-32.334-15c-11-5.334-22.334-4.667-22.334-4.667L176.667,162c0,0,0,2.559,0,6.862
                  c0,22.251,0,91.435,0,98.138c0,4.303,0,6.862,0,6.862l9.999,24.333c0,0,11.334,0.667,22.334-4.667s21.334-14.667,32.334-15
                  S311,278.862,329,278.862s30.666,5,40.333,15.667c1.956,2.159,8.264,14.233,9.334,11.805c4.22-9.567-2.333-61.198-2.333-81.138
                  C376.334,220.295,376.334,215.369,376.334,210.667z"></path>
            <linearGradient id="SVGID_00000013178779469865883950000003017637799485591972_" gradientUnits="userSpaceOnUse" x1="176.667" y1="219.9707" x2="380.083" y2="219.9707">
            <stop offset="0.2234" style="stop-color:#282A29"></stop>
            <stop offset="0.4384" style="stop-color:#4C4F54"></stop>
            <stop offset="0.5596" style="stop-color:#5C5F67"></stop>
            <stop offset="0.6507" style="stop-color:#585B63"></stop>
            <stop offset="0.7493" style="stop-color:#4D5056"></stop>
            <stop offset="0.8511" style="stop-color:#3B3E40"></stop>
            <stop offset="0.9332" style="stop-color:#282A29"></stop>
            </linearGradient>
            <path style="fill:url(#SVGID_00000013178779469865883950000003017637799485591972_);" d="M379.986,287
                  c-0.116-3.908-0.33-8.16-0.598-12.576c-0.25-4.102-0.546-8.343-0.854-12.576c-0.31-4.251-0.632-8.492-0.931-12.576
                  c-0.325-4.445-0.623-8.701-0.849-12.576c-0.258-4.428-0.421-8.359-0.421-11.502c0-0.358,0-0.716,0-1.074c0-4.242,0-8.483,0-12.575
                  c0-0.291,0-0.589,0-0.879c0-3.302,0.134-7.293,0.34-11.697c0.184-3.926,0.425-8.181,0.681-12.576
                  c0.239-4.122,0.491-8.365,0.721-12.575c0.233-4.284,0.442-8.531,0.59-12.576c0.165-4.523,0.253-8.792,0.212-12.576
                  c-0.088-8.076-0.765-13.941-2.544-15.333c-0.04-0.032-0.089-0.046-0.145-0.046c-1.173,0-5.62,6.685-6.855,8.046
                  c-2.581,2.849-5.386,5.28-8.44,7.333C352.513,154.301,342.193,157,329,157c-14.4,0-54.827,0.427-76.054,0.427
                  c-5.308,0-9.413-0.026-11.612-0.093c-7.25-0.22-14.211-4.35-21.264-8.667c-3.648-2.233-7.32-4.515-11.07-6.333
                  c-8.799-4.267-17.813-4.693-21.066-4.693c-0.813,0-1.268,0.027-1.268,0.027l-4.52,11l-5.168,12.576L176.667,162
                  c0,0,0,2.559,0,6.862c0,1.459,0,3.124,0,4.957c0,3.607,0,7.877,0,12.575c0,3.938,0,8.175,0,12.576c0,4.09,0,8.318,0,12.576
                  c0,4.201,0,8.427,0,12.575c0,4.319,0,8.55,0,12.576c0,4.509,0,8.757,0,12.576c0,5.088,0,9.415,0,12.576c0,2.534,0,4.322,0,5.151
                  c0,4.303,0,6.862,0,6.862l0.231,0.563L182.066,287l4.6,11.195c0,0,0.454,0.027,1.268,0.027c3.255,0,12.268-0.427,21.066-4.693
                  c3.859-1.871,7.637-4.234,11.39-6.528c6.943-4.245,13.804-8.255,20.944-8.472c2.199-0.066,6.309-0.093,11.613-0.093
                  c21.225,0,61.652,0.427,76.053,0.427c13.037,0,23.267,2.639,31.595,8.138c3.17,2.093,6.073,4.588,8.738,7.528
                  c1.778,1.963,7.156,12.126,8.921,12.126c0.176,0,0.316-0.101,0.413-0.321C380,303.312,380.258,296.091,379.986,287z
                  M371.556,143.349c0.451-0.498,1.064-1.317,2.025-2.611c0.418-0.564,1.022-1.378,1.64-2.181c0.246,1.513,0.476,3.767,0.591,7.11
                  h-6.492C370.085,144.921,370.831,144.148,371.556,143.349z M188.686,140.648c3.637,0.07,11.402,0.699,19.006,4.385
                  c0.422,0.205,0.844,0.416,1.266,0.634h-22.334L188.686,140.648z M361.791,151.667h14.093c-0.011,1.997-0.051,4.178-0.121,6.576
                  h-29.899C351.82,156.869,357.099,154.686,361.791,151.667z M184.157,151.667h35.068c3.887,2.376,8.061,4.873,12.467,6.576h-50.237
                  L184.157,151.667z M179.667,170.818v-1.957v-4.62h195.877c-0.086,2.013-0.189,4.177-0.314,6.576H179.667z M179.667,183.394v-6.575
                  h195.236c-0.124,2.201-0.252,4.399-0.379,6.575H179.667z M179.667,195.97v-6.576h194.51c-0.135,2.363-0.254,4.526-0.359,6.576
                  H179.667z M179.667,208.546v-6.576H373.54c-0.101,2.511-0.163,4.671-0.19,6.576H179.667z M179.667,221.121v-6.575h193.667v6.575
                  H179.667z M179.667,233.697v-6.576h193.684c0.033,1.878,0.111,4.057,0.236,6.576H179.667z M179.667,246.273v-6.576h194.266
                  c0.142,2.226,0.298,4.486,0.446,6.576H179.667z M179.667,258.849v-6.576h195.149c0.074,0.997,0.148,2.001,0.224,3.01
                  c0.089,1.183,0.177,2.372,0.266,3.565H179.667z M179.667,271.424V267v-2.151h196.076c0.171,2.411,0.319,4.575,0.45,6.576H179.667z
                  M207.691,290.829c-7.604,3.686-15.369,4.315-19.006,4.385L186.542,290h22.792C208.785,290.287,208.238,290.564,207.691,290.829z
                  M219.545,284h-35.469l-2.702-6.576h50.86C227.64,279.122,223.332,281.689,219.545,284z M344.983,277.424h31.575
                  c0.133,2.376,0.239,4.557,0.32,6.576h-15.399C356.633,280.937,351.167,278.753,344.983,277.424z M376.813,300.459
                  c-0.725-1.107-1.441-2.253-1.973-3.101c-1.53-2.445-2.509-3.988-3.285-4.845c-0.788-0.87-1.603-1.708-2.44-2.514h7.941
                  C377.137,294.757,377.022,298.106,376.813,300.459z"></path>
            <g style="opacity:0.3;">
            <polygon style="fill:#FFFFFF;" points="389.417,149.083 360.584,149.083 361.75,148.083 389.417,148.083 		"></polygon>
            <polygon style="fill:#FFFFFF;" points="220.583,149.083 167.416,149.083 167.416,148.083 218.666,148.083 		"></polygon>
            <rect x="166.75" y="160.659" style="fill:#FFFFFF;" width="222.667" height="1"></rect>
            <rect x="166.75" y="173.235" style="fill:#FFFFFF;" width="222.667" height="1"></rect>
            <rect x="166.75" y="185.81" style="fill:#FFFFFF;" width="222.666" height="1"></rect>
            <rect x="166.75" y="198.386" style="fill:#FFFFFF;" width="222.667" height="1"></rect>
            <rect x="166.75" y="210.962" style="fill:#FFFFFF;" width="222.667" height="1"></rect>
            <rect x="166.75" y="223.538" style="fill:#FFFFFF;" width="222.667" height="1"></rect>
            <rect x="166.75" y="236.113" style="fill:#FFFFFF;" width="222.667" height="1"></rect>
            <rect x="166.75" y="248.69" style="fill:#FFFFFF;" width="222.667" height="1"></rect>
            <rect x="166.75" y="261.265" style="fill:#FFFFFF;" width="222.667" height="1"></rect>
            <rect x="166.75" y="273.841" style="fill:#FFFFFF;" width="222.667" height="1"></rect>
            <polygon style="fill:#FFFFFF;" points="389.417,287.417 361.667,287.417 360,286.417 389.417,286.417 		"></polygon>
            <polygon style="fill:#FFFFFF;" points="219.5,287.417 166.75,287.417 166.75,286.417 221.334,286.417 		"></polygon>
            </g>
            <linearGradient id="SVGID_00000112601936544606750170000007515448177248859790_" gradientUnits="userSpaceOnUse" x1="175.667" y1="219.9707" x2="379.0835" y2="219.9707">
            <stop offset="0.114" style="stop-color:#282C27"></stop>
            <stop offset="0.1965" style="stop-color:#2D312C"></stop>
            <stop offset="0.2958" style="stop-color:#3C3E3A"></stop>
            <stop offset="0.4034" style="stop-color:#545551"></stop>
            <stop offset="0.4869" style="stop-color:#6C6B68"></stop>
            <stop offset="0.6278" style="stop-color:#5C5C59"></stop>
            <stop offset="0.9015" style="stop-color:#333631"></stop>
            <stop offset="0.9376" style="stop-color:#2D302B"></stop>
            </linearGradient>
            <path style="opacity:0.4;fill:url(#SVGID_00000112601936544606750170000007515448177248859790_);" d="M376.334,225.195v-14.529
                  c0-21.656,5.747-72.836-0.001-77.333c-0.888-0.694-5.706,6.573-7,8C359.666,152,347,157,329,157s-76.666,0.667-87.666,0.334
                  c-11-0.334-21.334-9.667-32.334-15c-11-5.334-22.334-4.667-22.334-4.667L176.667,162c0,0,0,2.559,0,6.862V267
                  c0,4.303,0,6.862,0,6.862l9.999,24.333c0,0,11.334,0.667,22.334-4.667s21.334-14.667,32.334-15S311,278.862,329,278.862
                  s30.666,5,40.333,15.667c1.956,2.159,8.264,14.233,9.334,11.805C382.887,296.767,376.334,245.135,376.334,225.195z
                  M314.939,224.521c2.608,5.33,6.016,10.34,8.035,15.944c3.018,8.373,1.991,18.296-6.971,22.193
                  c-12.697,5.52-70.066,6.421-80.901-1.449c-9.089-6.604-5.425-18.472-3.032-27.575c3.391-12.898,4.253-20.292-0.674-32.966
                  c-2.236-5.752-4.741-11.826-4.762-18.101c-0.015-4.655,1.098-9.996,4.788-13.204c8.301-7.211,22.432-5.65,32.606-5.552
                  c15.525,0.148,31.003,1.159,46.51,1.831c4.904,0.212,16.979-0.072,16.682,7.534c-0.189,4.834-3.371,9.067-5.906,12.946
                  c-3.723,5.699-7.117,11.916-8.703,18.586C310.953,211.681,311.8,218.106,314.939,224.521z"></path>
            <linearGradient id="SVGID_00000173155712032789057820000011976031894445237181_" gradientUnits="userSpaceOnUse" x1="175.667" y1="217.9309" x2="379.0832" y2="217.9309">
            <stop offset="0.114" style="stop-color:#282C27"></stop>
            <stop offset="0.1965" style="stop-color:#2D312C"></stop>
            <stop offset="0.2958" style="stop-color:#3C3E3A"></stop>
            <stop offset="0.4034" style="stop-color:#545551"></stop>
            <stop offset="0.4869" style="stop-color:#6C6B68"></stop>
            <stop offset="0.6278" style="stop-color:#5C5C59"></stop>
            <stop offset="0.9015" style="stop-color:#333631"></stop>
            <stop offset="0.9376" style="stop-color:#2D302B"></stop>
            </linearGradient>
            <path style="opacity:0.4;fill:url(#SVGID_00000173155712032789057820000011976031894445237181_);" d="M215.202,287.588
                  c-5.317,0.438-8.657-2.061-9.496-7.393c-1.184-7.526,1.086-15.899,5.112-22.26c4.708-7.438,11.109-13.692,15.162-21.568
                  c3.048-5.924,5.002-12.472,4.487-19.195c-0.47-6.141-2.587-11.966-5.497-17.359c-3.068-5.687-6.766-10.997-10.521-16.246
                  c-2.883-4.03-6.08-7.904-8.086-12.471c-2.781-6.335-6.24-16.515-1.647-22.829c2.461-3.383,6.233-3.644,10.263-2.683
                  c-1.977-1.164-3.967-2.275-5.979-3.25c-11-5.334-22.334-4.667-22.334-4.667L176.667,162c0,0,0,2.559,0,6.862V267
                  c0,4.303,0,6.862,0,6.862l9.999,24.333c0,0,11.334,0.667,22.334-4.667c4.133-2.004,8.171-4.571,12.186-7.015
                  C219.211,287.014,217.223,287.421,215.202,287.588z"></path>
            <linearGradient id="SVGID_00000093872890965596815600000003810082064382054550_" gradientUnits="userSpaceOnUse" x1="175.6648" y1="219.9707" x2="379.0831" y2="219.9707">
            <stop offset="0.114" style="stop-color:#282C27"></stop>
            <stop offset="0.1965" style="stop-color:#2D312C"></stop>
            <stop offset="0.2958" style="stop-color:#3C3E3A"></stop>
            <stop offset="0.4034" style="stop-color:#545551"></stop>
            <stop offset="0.4869" style="stop-color:#6C6B68"></stop>
            <stop offset="0.6278" style="stop-color:#5C5C59"></stop>
            <stop offset="0.9015" style="stop-color:#333631"></stop>
            <stop offset="0.9376" style="stop-color:#2D302B"></stop>
            </linearGradient>
            <path style="opacity:0.4;fill:url(#SVGID_00000093872890965596815600000003810082064382054550_);" d="M376.334,225.195v-14.529
                  c0-21.656,5.747-72.836-0.001-77.333c-0.888-0.694-5.706,6.573-7,8C359.666,152,347,157,329,157c-2.451,0-5.66,0.013-9.397,0.033
                  c1.683,0.032,3.356,0.097,4.962,0.383c7.024,1.253,14.179,0.543,21.239,1.371c6.608,0.775,19.682,4.222,14.293,13.434
                  c-2.139,3.657-5.419,6.583-8.68,9.222c-3.382,2.737-6.863,5.388-10.457,7.839c-8.38,5.717-20.478,12.148-23.003,22.899
                  c-2.281,9.71,3.619,17.376,10.834,23.117c7.429,5.911,15.947,10.365,23.147,16.571c3.764,3.243,13.646,13.214,5.698,17.275
                  c-3.921,2.004-8.563,2.181-12.817,2.996c-7.37,1.411-14.591,3.345-22.031,4.462c-6.623,0.994-13.279,1.685-19.953,2.115
                  c10.901,0.08,20.527,0.145,26.164,0.145c18,0,30.666,5,40.333,15.667c1.956,2.159,8.264,14.233,9.334,11.805
                  C382.887,296.767,376.334,245.135,376.334,225.195z"></path>
      </g>
      <g>
            <linearGradient id="SVGID_00000137127325941129573470000005732596227623043239_" gradientUnits="userSpaceOnUse" x1="369.2958" y1="-408.9933" x2="618.3476" y2="-408.9933" gradientTransform="matrix(1 0 0 -1 0 -270.9863)">
            <stop offset="0.14" style="stop-color:#0095CE"></stop>
            <stop offset="0.3727" style="stop-color:#1190FF"></stop>
            <stop offset="0.5263" style="stop-color:#89C7FF"></stop>
            <stop offset="0.6716" style="stop-color:#1190FF"></stop>
            <stop offset="0.7821" style="stop-color:#0C91F2"></stop>
            <stop offset="0.9877" style="stop-color:#0095CF"></stop>
            <stop offset="0.9905" style="stop-color:#0095CE"></stop>
            </linearGradient>
            <path style="fill: #9cba73;" d="M370.667,178.514
                  C369,168.18,367,136.513,377,124.847s41.668-35,104.334-35c62.666,0,117.629,29.298,131.333,43.001
                  c3.167,3.166,5.667,12.333,5.667,22.333S619,175.847,611,181.847s-46.333,4-46.333,4L370.667,178.514z"></path>
            <g style="opacity:0.5;">
            <radialGradient id="SVGID_00000068677156088569372280000016273178485312740506_" cx="493.8217" cy="-408.9933" r="94.409" gradientTransform="matrix(1 0 0 -1 0 -270.9863)" gradientUnits="userSpaceOnUse">
            <stop offset="0" style="stop-color:#1190FF"></stop>
            <stop offset="0.1201" style="stop-color:#0D91F3"></stop>
            <stop offset="0.3766" style="stop-color:#0693DE"></stop>
            <stop offset="0.6313" style="stop-color:#0195D2"></stop>
            <stop offset="0.8805" style="stop-color:#0095CE"></stop>
            </radialGradient>
            <path style="fill: #9cba73;" d="M618.334,155.18
                        c0-10-2.5-19.167-5.667-22.333C598.963,119.145,544,89.847,481.334,89.847c-62.666,0-94.334,23.333-104.334,35
                        s-8,43.334-6.333,53.667l194,7.333c0,0,38.333,2,46.333-4S618.334,165.18,618.334,155.18z M601.307,150.925
                        c-0.171,4.097-1.411,8.205-4.474,11.058c-3.844,3.58-13.281,5.784-18.166,5.864c-26.947,0.441-63-12-78.103-5.983
                        c-5.084,2.025-16.658-1.594-11.897-0.683c29.465,5.64-61.68-18.216-90.667-9.333c-20.667,6.333-18.88,5.932-17.666-11.333
                        c0.686-9.769,16.68-15.153,25.333-18.333c12.82-4.712,25.383-8.12,38.667-10.333c14-2.333,23.333-3.333,39.666-4
                        c-9.666-1.333-15.712-0.518-22.666,0c-31.334,2.333-67.901,17.511-69.667,15c-1.946-2.768,3.315-10.019,19-17.666
                        c10.784-5.258,45.278-10.653,49.101-11.105c21.491-2.546,43.636,0.298,64.682,4.79c12.341,2.633,24.901,4.393,36.727,9.012
                        C588.709,118.63,555,110.514,540,109.18c-13.971-1.242-36.048-2.824-50-1c37.188,1.244,105.334,8,110.881,34.147
                        C601.441,144.972,601.434,147.886,601.307,150.925z"></path>
            </g>
            <g style="opacity:0.5;">
            <radialGradient id="SVGID_00000026853814365269884370000004439592568519664775_" cx="493.8218" cy="-408.9933" r="94.4092" gradientTransform="matrix(1 0 0 -1 0 -270.9863)" gradientUnits="userSpaceOnUse">
            <stop offset="0" style="stop-color:#1190FF"></stop>
            <stop offset="0.1201" style="stop-color:#0D91F3"></stop>
            <stop offset="0.3766" style="stop-color:#0693DE"></stop>
            <stop offset="0.6313" style="stop-color:#0195D2"></stop>
            <stop offset="0.8805" style="stop-color:#0095CE"></stop>
            </radialGradient>
            <path style="fill: #9cba73;" d="M382.026,127.796
                        c5.293-0.918,10.488-2.252,15.658-3.693c-0.962,0.382-1.919,0.78-2.862,1.212c-5.204,2.384-10.759,5.105-15.364,8.535
                        c-3.982,2.966-5.867,6.118-6.566,11.04c-0.633,4.459-0.196,8.99-0.725,13.457c-0.014,0.118,0.017,0.206,0.063,0.28
                        c0.076,4.015,10.126,0.999,12.088,0.406c3.647-1.1,7.199-2.463,10.886-3.445c6.981-1.86,14.182-3.006,21.425-2.83
                        c7.668,0.186,15.343,1.508,22.903,2.728c7.448,1.202,14.834,2.693,22.183,4.4c7.173,1.667,14.163,3.557,21.154,5.863
                        c6.947,2.292,14.129,3.617,21.165,5.579c0.614,0.172,0.875-0.764,0.285-0.954c3.162-1.481,4.631-5.777,8.404-6.54
                        c5.81-1.174,12.409,0.482,18.234,1.2c12.82,1.582,25.486,4.269,38.338,5.608c8.066,0.839,16.145,1.626,24.215,2.433
                        c4.388,0.438,11.111,2.07,14.829-1.368c3.187-2.947,3.482-7.831,3.941-11.874c0.76-6.681,1.441-13.669-0.132-20.287
                        c-1.126-4.734-4.807-8.073-8.911-10.398c-4.976-2.819-10.529-4.937-15.9-6.857c-7.363-2.633-14.904-4.607-22.6-5.989
                        c-5.223-0.938-10.469-1.747-15.678-2.751c2.476,0.064,4.928,0.523,7.393,0.892c2.897,0.433,5.82,0.576,8.731,0.894
                        c7.093,0.774,13.976,2.304,20.813,4.327c5.573,1.649,11.081,3.507,16.61,5.293c4.381,2.921,7.789,5.62,10.059,7.889
                        c3.167,3.166,5.667,12.333,5.667,22.333S619,175.847,611,181.847s-46.333,4-46.333,4l-194-7.333
                        c-1.665-10.319-3.66-41.907,6.292-53.614c0.008,0.3,0.027,0.599,0.079,0.896C377.455,128.187,380.103,128.13,382.026,127.796z"></path>
            </g>
            <defs>
            <filter id="Adobe_OpacityMaskFilter_00000021087902280945597100000015322658972599383428_" filterUnits="userSpaceOnUse" x="427.334" y="95.394" width="127.666" height="14.786">
            <feFlood style="flood-color:white;flood-opacity:1" result="back"></feFlood>
            <feBlend in="SourceGraphic" in2="back" mode="normal"></feBlend>
            </filter>
            </defs>
            <mask maskUnits="userSpaceOnUse" x="427.334" y="95.394" width="127.666" height="14.786" id="SVGID_00000088107888609716417630000013515908023540296891_">
            <g style="filter:url(#Adobe_OpacityMaskFilter_00000021087902280945597100000015322658972599383428_);">
            <defs>
                  <filter id="Adobe_OpacityMaskFilter_00000038382113319467101240000000480580166373576882_" filterUnits="userSpaceOnUse" x="427.334" y="95.394" width="127.666" height="14.786">
                  <feFlood style="flood-color:white;flood-opacity:1" result="back"></feFlood>
                  <feBlend in="SourceGraphic" in2="back" mode="normal"></feBlend>
                  </filter>
            </defs>
            <mask maskUnits="userSpaceOnUse" x="427.334" y="95.394" width="127.666" height="14.786" id="SVGID_00000088107888609716417630000013515908023540296891_">
                  <g style="filter:url(#Adobe_OpacityMaskFilter_00000038382113319467101240000000480580166373576882_);"></g>
            </mask>
            <radialGradient id="SVGID_00000060032845886661349520000011737312246608220092_" cx="490.6671" cy="-400.1662" r="72.1972" gradientTransform="matrix(1 0 0 -1 0 -270.9863)" gradientUnits="userSpaceOnUse">
                  <stop offset="0.4276" style="stop-color:#FFFFFF"></stop>
                  <stop offset="1" style="stop-color:#000000"></stop>
            </radialGradient>
            <path style="mask:url(#SVGID_00000088107888609716417630000013515908023540296891_);fill:url(#SVGID_00000060032845886661349520000011737312246608220092_);" d="
                              M427.334,110.18c0,0,62.999-12.333,127.666,0c0,0-36.332-16.333-81.666-14.666C428,97.18,427.334,110.18,427.334,110.18z"></path>
            </g>
            </mask>
            <radialGradient id="SVGID_00000036245799943882114070000009587578601216896696_" cx="490.6671" cy="-400.1662" r="72.1972" gradientTransform="matrix(1 0 0 -1 0 -270.9863)" gradientUnits="userSpaceOnUse">
            <stop offset="0.4276" style="stop-color:#FFFFFF"></stop>
            <stop offset="1" style="stop-color:#2CB0FF"></stop>
            </radialGradient>
            <path style="opacity: 0.5; fill: #b7ce96;" d="M427.334,110.18
                  c0,0,62.999-12.333,127.666,0c0,0-36.332-16.333-81.666-14.666C428,97.18,427.334,110.18,427.334,110.18z"></path>
            <defs>
            <filter id="Adobe_OpacityMaskFilter_00000036953563262221037720000015229299390236085650_" filterUnits="userSpaceOnUse" x="413.999" y="109.686" width="158.084" height="54.707">
            <feFlood style="flood-color:white;flood-opacity:1" result="back"></feFlood>
            <feBlend in="SourceGraphic" in2="back" mode="normal"></feBlend>
            </filter>
            </defs>
            <mask maskUnits="userSpaceOnUse" x="413.999" y="109.686" width="158.084" height="54.707" id="SVGID_00000093862185138916154200000003836267570865731245_">
            <g style="filter:url(#Adobe_OpacityMaskFilter_00000036953563262221037720000015229299390236085650_);">
            <defs>
                  <filter id="Adobe_OpacityMaskFilter_00000031900157503243003900000000021962638066806181_" filterUnits="userSpaceOnUse" x="413.999" y="109.686" width="158.084" height="54.707">
                  <feFlood style="flood-color:white;flood-opacity:1" result="back"></feFlood>
                  <feBlend in="SourceGraphic" in2="back" mode="normal"></feBlend>
                  </filter>
            </defs>
            <mask maskUnits="userSpaceOnUse" x="413.999" y="109.686" width="158.084" height="54.707" id="SVGID_00000093862185138916154200000003836267570865731245_">
                  <g style="filter:url(#Adobe_OpacityMaskFilter_00000031900157503243003900000000021962638066806181_);"></g>
            </mask>
            <radialGradient id="SVGID_00000030454578320298918110000010060077980055944866_" cx="495.3344" cy="-405.5002" r="85.6188" gradientTransform="matrix(1 0 0 -1 0 -270.9863)" gradientUnits="userSpaceOnUse">
                  <stop offset="0.2117" style="stop-color:#C9C9C9"></stop>
                  <stop offset="1" style="stop-color:#000000"></stop>
            </radialGradient>
            <path style="mask:url(#SVGID_00000093862185138916154200000003836267570865731245_);fill:url(#SVGID_00000030454578320298918110000010060077980055944866_);" d="
                              M421.334,151.181c0,0,48.038,5.175,67.333,9.999c4,1,6.001,2,11.667-0.667c5.666-2.667,32.333,1.667,45.333,2.667
                              S572,167.514,572,155.847c0-11.667,0.334-17.666-0.333-22c-0.667-4.333-7.001-15.333-44.667-21.333s-76-1.667-98.333,5.666
                              C406.334,125.514,414.334,150.849,421.334,151.181z"></path>
            </g>
            </mask>
            <radialGradient id="SVGID_00000107547950762182911180000009319898157605802907_" cx="495.3344" cy="-405.5002" r="85.6188" gradientTransform="matrix(1 0 0 -1 0 -270.9863)" gradientUnits="userSpaceOnUse">
            <stop offset="0.2117" style="stop-color:#F0F8FF"></stop>
            <stop offset="1" style="stop-color:#1190FF"></stop>
            </radialGradient>
            <path style="fill: #b7ce96;" d="M421.334,151.181
                  c0,0,48.038,5.175,67.333,9.999c4,1,6.001,2,11.667-0.667c5.666-2.667,32.333,1.667,45.333,2.667S572,167.514,572,155.847
                  c0-11.667,0.334-17.666-0.333-22c-0.667-4.333-7.001-15.333-44.667-21.333s-76-1.667-98.333,5.666
                  C406.334,125.514,414.334,150.849,421.334,151.181z"></path>
            <g>
            <path style="fill:#FFFFFF;" d="M453,150.014c0,0,41.5,8,92,9.5c0,0-37-5-38.5-12s-4.5-24-4-30s4.5-7,12.5-6.5c0,0-28.5-3.5-59,2.5
                        c0,0,29.5-4,35,1s4.5,23,2,30.5S453,150.014,453,150.014z"></path>
            </g>
      </g>
      <g>
            <linearGradient id="SVGID_00000010312000861359386080000006502677653009269424_" gradientUnits="userSpaceOnUse" x1="162.2474" y1="215.625" x2="229.0918" y2="215.625">
            <stop offset="0.5461" style="stop-color:#2A2828"></stop>
            <stop offset="0.6634" style="stop-color:#3A3935"></stop>
            <stop offset="0.8936" style="stop-color:#646457"></stop>
            <stop offset="0.9591" style="stop-color:#717162"></stop>
            </linearGradient>
            <path style="fill:url(#SVGID_00000010312000861359386080000006502677653009269424_);" d="M223.699,198.833
                  c-10.036-14.803-29.402-21.838-38.199-27.583c-12.25-8-5.5-22.25-5.5-22.25s-17.998,14.249-17.75,15
                  c6.618,20.044,12.775,40.544,10.887,61.92c-1.258,14.23-6.406,27.888-10.887,41.33c-0.25,0.75,17.75,15,17.75,15
                  S173.25,268,185.5,260c8.797-5.744,28.162-12.779,38.197-27.581C230.912,221.778,230.867,209.406,223.699,198.833z"></path>
            <linearGradient id="SVGID_00000152260891240368094840000000387789049721678487_" gradientUnits="userSpaceOnUse" x1="162.2477" y1="217.2493" x2="229.0911" y2="217.2493">
            <stop offset="0.0673" style="stop-color:#71798E"></stop>
            <stop offset="0.2061" style="stop-color:#9399A9"></stop>
            <stop offset="0.375" style="stop-color:#B5BAC4"></stop>
            <stop offset="0.5142" style="stop-color:#CACED5"></stop>
            <stop offset="0.6053" style="stop-color:#D2D5DB"></stop>
            <stop offset="0.6838" style="stop-color:#C5C8D0"></stop>
            <stop offset="0.8302" style="stop-color:#A1A7B4"></stop>
            <stop offset="1" style="stop-color:#71798E"></stop>
            </linearGradient>
            <path style="opacity:0.1;fill:url(#SVGID_00000152260891240368094840000000387789049721678487_);" d="M202.25,239.5
                  c-2.652,3.421-7.047,7.029-10.4,9.779c-2.639,2.163-5.627,4.423-6.82,7.712c2.573-0.882,4.764-2.656,6.968-4.206
                  c2.64-1.857,5.495-3.356,8.316-4.921c5.84-3.241,11.709-6.61,16.521-11.309c7.789-7.605,13.68-19.607,9.684-30.591
                  c-3.27-8.992-11.915-15.121-19.89-19.697c-4.135-2.372-8.408-4.489-12.752-6.447c-1.814-0.817-3.799-1.526-5.738-2.313
                  c1.453,1.669,3.189,3.19,4.573,4.757c3.245,3.675,7.521,6.681,10.288,10.735c4.801,7.033,6.598,15.349,6.207,23.982
                  C208.818,225.576,207.504,232.724,202.25,239.5z"></path>
            <linearGradient id="SVGID_00000057859952276708577340000012285694938280676533_" gradientUnits="userSpaceOnUse" x1="162.2462" y1="200.0437" x2="229.0776" y2="200.0437">
            <stop offset="0.0673" style="stop-color:#71798E"></stop>
            <stop offset="0.2061" style="stop-color:#9399A9"></stop>
            <stop offset="0.375" style="stop-color:#B5BAC4"></stop>
            <stop offset="0.5142" style="stop-color:#CACED5"></stop>
            <stop offset="0.6053" style="stop-color:#D2D5DB"></stop>
            <stop offset="0.6838" style="stop-color:#C5C8D0"></stop>
            <stop offset="0.8302" style="stop-color:#A1A7B4"></stop>
            <stop offset="1" style="stop-color:#71798E"></stop>
            </linearGradient>
            <path style="opacity:0.1;fill:url(#SVGID_00000057859952276708577340000012285694938280676533_);" d="M202.542,206.785
                  c7.961-0.981,15.907-2.126,23.867-3.112c-0.751-1.645-1.642-3.265-2.71-4.841c-1.342-1.979-2.854-3.814-4.48-5.53
                  c-6.26,1.43-12.424,3.365-18.584,5.215C201.565,201.164,202.188,203.944,202.542,206.785z"></path>
            <linearGradient id="SVGID_00000022555908893235726920000010931822866738109619_" gradientUnits="userSpaceOnUse" x1="162.2476" y1="237.6472" x2="229.0775" y2="237.6472">
            <stop offset="0.0673" style="stop-color:#71798E"></stop>
            <stop offset="0.2061" style="stop-color:#9399A9"></stop>
            <stop offset="0.375" style="stop-color:#B5BAC4"></stop>
            <stop offset="0.5142" style="stop-color:#CACED5"></stop>
            <stop offset="0.6053" style="stop-color:#D2D5DB"></stop>
            <stop offset="0.6838" style="stop-color:#C5C8D0"></stop>
            <stop offset="0.8302" style="stop-color:#A1A7B4"></stop>
            <stop offset="1" style="stop-color:#71798E"></stop>
            </linearGradient>
            <path style="opacity:0.1;fill:url(#SVGID_00000022555908893235726920000010931822866738109619_);" d="M200.086,228.917
                  c-0.787,2.272-1.727,4.464-2.814,6.538c0.034,0.032,0.069,0.064,0.103,0.096c2.861,2.658,5.711,5.328,8.598,7.957
                  c1.057,0.962,2.121,1.916,3.184,2.87c2.99-2.054,5.904-4.337,8.561-6.914c-1.341-0.616-2.676-1.247-3.992-1.918
                  C208.909,235.088,204.559,231.914,200.086,228.917z"></path>
            <linearGradient id="SVGID_00000034051728635866540170000010253551160229116055_" gradientUnits="userSpaceOnUse" x1="162.2461" y1="223.503" x2="229.0777" y2="223.503">
            <stop offset="0.0673" style="stop-color:#71798E"></stop>
            <stop offset="0.2061" style="stop-color:#9399A9"></stop>
            <stop offset="0.375" style="stop-color:#B5BAC4"></stop>
            <stop offset="0.5142" style="stop-color:#CACED5"></stop>
            <stop offset="0.6053" style="stop-color:#D2D5DB"></stop>
            <stop offset="0.6838" style="stop-color:#C5C8D0"></stop>
            <stop offset="0.8302" style="stop-color:#A1A7B4"></stop>
            <stop offset="1" style="stop-color:#71798E"></stop>
            </linearGradient>
            <path style="opacity:0.1;fill:url(#SVGID_00000034051728635866540170000010253551160229116055_);" d="M202.455,218.897
                  c-0.08,0.599-0.168,1.197-0.27,1.793c7.404,0.972,14.441,3.562,21.344,6.347c0.88,0.355,1.76,0.714,2.639,1.073
                  c0.836-1.75,1.494-3.53,1.97-5.329c-0.733-0.07-1.462-0.142-2.179-0.22C218.073,221.703,210.322,219.844,202.455,218.897z"></path>
      </g>
      <g>
            <linearGradient id="SVGID_00000119805651839900675440000014824576316668384437_" gradientUnits="userSpaceOnUse" x1="72.5003" y1="215.1447" x2="127.6837" y2="215.1447">
            <stop offset="0" style="stop-color:#24C3FF"></stop>
            <stop offset="0.3727" style="stop-color:#5DB4FF"></stop>
            <stop offset="0.5263" style="stop-color:#C2E2FF"></stop>
            <stop offset="0.6716" style="stop-color:#5DB4FF"></stop>
            <stop offset="0.7693" style="stop-color:#50B8FF"></stop>
            <stop offset="0.951" style="stop-color:#2DC1FF"></stop>
            <stop offset="0.9905" style="stop-color:#24C3FF"></stop>
            </linearGradient>
            <path style="fill: #9cba73;" d="M147,163.498
                  c-3.239-8.183-18.486,7.731-20.748,10.07c-11.023,11.408-23.705,24.847-24.305,41.556c-0.562,15.649,13.017,28.835,22.488,39.447
                  c1.974,2.211,19.056,21.053,22.564,12.178C149.906,259.399,149.906,170.851,147,163.498z"></path>
            <g>
            <radialGradient id="SVGID_00000145777821004621731690000000557250487694570895_" cx="125.5548" cy="215.1446" r="41.6565" gradientUnits="userSpaceOnUse">
            <stop offset="0" style="stop-color:#1190FF"></stop>
            <stop offset="0.1201" style="stop-color:#0D91F3"></stop>
            <stop offset="0.3766" style="stop-color:#0693DE"></stop>
            <stop offset="0.6313" style="stop-color:#0195D2"></stop>
            <stop offset="0.8805" style="stop-color:#0095CE"></stop>
            </radialGradient>
            <path style="fill: #9cba73;" d="M147,163.498
                        c-2.169-5.479-9.717-0.157-15.15,4.662c2.172-1.376,4.523-3.385,6.817-4.577c2.083-1.083,2.003-0.166,1.583,0.333
                        c-3.002,3.568-11,12.333-14.639,20.244c-4.084,8.883-6.523,18.235-6.716,28.083c-0.366,18.787,5.028,38.108,18.458,51.735
                        c0.4,0.406,4.563,3.52-1.604,0.437c-1.734-0.867-2.37-1.575-4.061-2.525c5.77,5.227,13.139,10.351,15.311,4.858
                        C149.906,259.399,149.906,170.851,147,163.498z"></path>
            </g>
            <linearGradient id="SVGID_00000085227782486372532650000008000956909302419903_" gradientUnits="userSpaceOnUse" x1="154.0981" y1="215.625" x2="207.5277" y2="215.625">
            <stop offset="0.14" style="stop-color:#0095CE"></stop>
            <stop offset="0.3727" style="stop-color:#1190FF"></stop>
            <stop offset="0.5263" style="stop-color:#89C7FF"></stop>
            <stop offset="0.6716" style="stop-color:#1190FF"></stop>
            <stop offset="0.7821" style="stop-color:#0C91F2"></stop>
            <stop offset="0.9877" style="stop-color:#0095CF"></stop>
            <stop offset="0.9905" style="stop-color:#0095CE"></stop>
            </linearGradient>
            <path style="fill:url(#SVGID_00000085227782486372532650000008000956909302419903_);" d="M184.5,178.25c-12.5-9-6-24.5-6-24.5
                  s-10.25,7.25-14.75,12.5c-12.869,15.015-12.869,83.735,0,98.75c4.5,5.25,14.75,12.5,14.75,12.5s-6.5-15.5,6-24.5
                  c12.27-8.834,23.327-17.436,23.021-37.375C207.827,195.687,196.77,187.085,184.5,178.25z"></path>
            <linearGradient id="SVGID_00000105405278956974273480000014223213897703436457_" gradientUnits="userSpaceOnUse" x1="165.125" y1="249" x2="165.125" y2="188.3356">
            <stop offset="0.14" style="stop-color:#0700E2"></stop>
            <stop offset="0.4392" style="stop-color:#4923F9"></stop>
            <stop offset="0.511" style="stop-color:#5A2CFF"></stop>
            <stop offset="0.5989" style="stop-color:#5A2CFF"></stop>
            <stop offset="0.6873" style="stop-color:#4D25FA"></stop>
            <stop offset="0.8514" style="stop-color:#2A12EE"></stop>
            <stop offset="0.9905" style="stop-color:#0700E2"></stop>
            </linearGradient>
            <path style="fill:url(#SVGID_00000105405278956974273480000014223213897703436457_);" d="M161,180.75c0,0,15.5,10,15.5,31.5
                  S167,247,160.5,252c0,0-6.75-15.75-6.75-38.25S161,180.75,161,180.75z"></path>
            <radialGradient id="SVGID_00000080204158851815191100000005193097951300184194_" cx="181.2843" cy="214.3159" r="24.5971" gradientUnits="userSpaceOnUse">
            <stop offset="0.14" style="stop-color:#24C3FF"></stop>
            <stop offset="0.6765" style="stop-color:#5DB4FF"></stop>
            <stop offset="0.7268" style="stop-color:#C2E2FF"></stop>
            <stop offset="0.8218" style="stop-color:#5DB4FF"></stop>
            <stop offset="0.8735" style="stop-color:#50B8FF"></stop>
            <stop offset="0.9696" style="stop-color:#2DC1FF"></stop>
            <stop offset="0.9905" style="stop-color:#24C3FF"></stop>
            </radialGradient>
            <path style="fill:url(#SVGID_00000080204158851815191100000005193097951300184194_);" d="M170.75,193.75
                  c5.441-2.864,14.5-4.5,21.75-1.75s14.25,10.5,14.25,22.25S198.25,236,189.5,239s-20-1-24-5.25s-8-9.75-7.5-14.75
                  s-1.25-9.75,1-14.25S166,196.25,170.75,193.75z"></path>
            <g style="opacity:0.8;">
            <linearGradient id="SVGID_00000007415451286953777130000011493106316808131496_" gradientUnits="userSpaceOnUse" x1="153.0292" y1="214.7837" x2="208.0344" y2="214.7837">
            <stop offset="0.0918" style="stop-color:#AD7BC2"></stop>
            <stop offset="0.8805" style="stop-color:#AD7BC2"></stop>
            </linearGradient>
            <path style="fill:url(#SVGID_00000007415451286953777130000011493106316808131496_);" d="M190.314,225.677
                        c-3.115-6.982-2.02-14.205-0.172-21.435c0.028-0.109,0.086-0.182,0.152-0.24c-0.156-0.117-0.305-0.228-0.438-0.315
                        c-1.252-0.829-2.494-1.672-3.73-2.524c-2.327-1.604-4.752-3.163-7.127-4.913c-7.375,4.125-13.746,10.007-13.5,18.625
                        c0.238,8.385,6.389,15.167,13.936,18.442c0.065-0.167,0.249-0.188,0.439-0.317c2.375-1.625,3.697-2.325,5.248-3.432
                        C186.848,228.337,188.83,227.229,190.314,225.677z"></path>
            </g>
            <g style="opacity:0.8;">
            <linearGradient id="SVGID_00000018223146639647732970000012986695719912374203_" gradientUnits="userSpaceOnUse" x1="154.0981" y1="215.625" x2="207.5277" y2="215.625">
            <stop offset="0.0918" style="stop-color:#0095CE"></stop>
            <stop offset="0.3819" style="stop-color:#1190FF"></stop>
            <stop offset="0.487" style="stop-color:#0C91F2"></stop>
            <stop offset="0.7335" style="stop-color:#0394D8"></stop>
            <stop offset="0.8805" style="stop-color:#0095CE"></stop>
            </linearGradient>
            <path style="fill:url(#SVGID_00000018223146639647732970000012986695719912374203_);" d="M184.5,178.25c-12.5-9-6-24.5-6-24.5
                        s-10.25,7.25-14.75,12.5c-12.869,15.015-12.869,83.735,0,98.75c4.5,5.25,14.75,12.5,14.75,12.5s-6.5-15.5,6-24.5
                        c12.27-8.834,23.327-17.436,23.021-37.375C207.827,195.687,196.77,187.085,184.5,178.25z M167.5,182.125
                        c2.659-3.864,13.176-1.569,16.5-0.25c3.493,1.387,9.826,5.262,12.125,8.25c1.25,1.625-0.287,1.356-0.75,1.125
                        c-4.992-2.482-11.219-2.882-16.375-2.25c-3.037,0.372-6.625,1.875-8.5-0.875C169.785,187.078,166.387,183.744,167.5,182.125z
                        M164.996,227.413c-5.547-7.425-6.455-18.325-0.652-25.826c3.08-4.896,9.797-7.893,15.322-8.586
                        c4.141-0.519,9.084-0.336,12.992,1.552c4.082,1.973,7.965,6.822,9.092,10.948c2.375,8.696,0.031,20.326-8.178,25.976
                        C184.852,237.479,171.48,236.095,164.996,227.413z M190.585,245.939c-4.376,3.126-10.089,5.003-14.534,5.275
                        c-1.982,0.121-4.877,0.168-6.629-0.888c-3.14-1.894-0.882-3.158,1.453-8.326c0.951-2.104,4.729-1.282,6.951-1.091
                        c6.509,0.561,12.543-0.425,18.297-3.591c0.378-0.208,0.857,0.132,0.733,0.565C195.948,241.077,193.58,243.8,190.585,245.939z"></path>
            </g>
            <g style="opacity:0.8;">
            <linearGradient id="SVGID_00000155122496448094909630000006171834719643413674_" gradientUnits="userSpaceOnUse" x1="154.0981" y1="215.625" x2="207.5268" y2="215.625">
            <stop offset="0.0918" style="stop-color:#0095CE"></stop>
            <stop offset="0.3819" style="stop-color:#1190FF"></stop>
            <stop offset="0.487" style="stop-color:#0C91F2"></stop>
            <stop offset="0.7335" style="stop-color:#0394D8"></stop>
            <stop offset="0.8805" style="stop-color:#0095CE"></stop>
            </linearGradient>
            <path style="fill:url(#SVGID_00000155122496448094909630000006171834719643413674_);" d="M170.2,255.772
                        c-1.089-2.458-3.678-6.104-2.757-8.961c0.525-1.626,1.459-3.079,1.994-4.701c0.181-0.55,0.352-1.1,0.523-1.648
                        c-0.141,0.151-0.273,0.309-0.417,0.456c-0.935,0.953-2.885,3.156-4.491,2.638c-1.602-0.516-2.021-3.029-2.348-4.424
                        c-0.436-1.865-1.03-4.088-0.688-6.014c0.058-0.323,0.476-0.45,0.734-0.299c1.477,0.862,2.916,1.766,4.452,2.501
                        c-5.706-6.637-9.983-15.206-8.868-24.146c0.897-7.192,6.574-13.305,12.284-17.674c-2.649,1.043-4.853,3.095-7.118,4.808
                        c-0.365,0.276-0.844-0.177-0.734-0.564c0.594-2.107,1.311-5.788,3.051-7.294c1.388-1.202,3.334-0.343,4.928,0.665
                        c-1.441-2.474-2.891-4.866-3.33-7.469c-0.111-0.342-0.153-0.665-0.115-0.96c-0.088-1.325,0.103-2.711,0.705-4.195
                        c2.423-5.963,5.688-11.445,8.852-17.025c0.268-4.424,1.643-7.713,1.643-7.713s-10.25,7.25-14.75,12.5
                        c-12.869,15.015-12.869,83.735,0,98.75c4.5,5.25,14.75,12.5,14.75,12.5s-1.409-3.364-1.652-7.867
                        C174.522,265.077,172.285,260.482,170.2,255.772z"></path>
            </g>
            <g>
            <linearGradient id="SVGID_00000075147811054000608670000010000154394891509415_" gradientUnits="userSpaceOnUse" x1="178" y1="232.25" x2="178" y2="197.3596">
            <stop offset="0.1103" style="stop-color:#989D9F"></stop>
            <stop offset="0.3247" style="stop-color:#D2D2D2"></stop>
            <stop offset="1" style="stop-color:#989D9F"></stop>
            </linearGradient>
            <path style="fill:url(#SVGID_00000075147811054000608670000010000154394891509415_);" d="M179.5,197.5l9.25,6.75
                        c0,0-1.75,3.5-2,11s2.25,10.5,2.25,10.5l-9.75,6.5c0,0-12.25-6.5-12.25-16.25S171.75,201.25,179.5,197.5z"></path>
            <linearGradient id="SVGID_00000050630017094732990990000001931376818695779758_" gradientUnits="userSpaceOnUse" x1="177.334" y1="230.4653" x2="177.334" y2="199.1588">
            <stop offset="0.1103" style="stop-color:#D9DCDD"></stop>
            <stop offset="0.3247" style="stop-color:#FFFFFF"></stop>
            <stop offset="1" style="stop-color:#D9DCDD"></stop>
            </linearGradient>
            <path style="fill:url(#SVGID_00000050630017094732990990000001931376818695779758_);" d="M179.346,199.285l7.988,5.549
                        c0,0-1.258,3.648-1.482,10.378c-0.225,6.729,1.316,10.289,1.316,10.289l-8.046,4.965c0,0-11.787-6.217-11.787-14.965
                        C167.334,206.752,172.392,202.649,179.346,199.285z"></path>
            <polygon style="fill:#003E4C;" points="170.375,213.875 172.577,213.965 172.5,214.75 170.5,214.938 		"></polygon>
            <polygon style="fill:#003E4C;" points="178.967,200.124 179.96,202.092 179.237,202.406 178.1,200.751 		"></polygon>
            <polygon style="fill:#003E4C;" points="172.567,206.216 174.453,207.357 174.008,208.007 172.164,207.209 		"></polygon>
            <polygon style="fill:#003E4C;" points="178.083,228.003 179.251,226.135 179.895,226.589 179.069,228.421 		"></polygon>
            <polygon style="fill:#003E4C;" points="172.445,222.198 174.378,221.14 174.717,221.851 173.102,223.044 		"></polygon>
            <polygon style="fill:#003E4C;" points="170.915,218.463 173.051,217.922 173.201,218.695 171.339,219.446 		"></polygon>
            <polygon style="fill:#003E4C;" points="175.202,225.776 176.763,224.22 177.287,224.808 176.069,226.404 		"></polygon>
            <polygon style="fill:#003E4C;" points="171.041,209.719 173.143,210.382 172.864,211.119 170.885,210.778 		"></polygon>
            <polygon style="fill:#003E4C;" points="175.457,202.607 176.936,204.24 176.324,204.736 174.787,203.442 		"></polygon>
            <path style="fill:#003E4C;" d="M186.75,215.25c0.053-1.568,0.172-2.951,0.326-4.174c-0.42-0.095-0.856-0.143-1.309-0.097
                        c-2.248,0.226-3.888,2.232-3.661,4.481c0.064,0.64,0.285,1.22,0.602,1.732l-5.993,6.192c-0.918,0.787-1.191,1.644,0.306,0.449
                        c0.987-0.788,4.108-3.407,6.647-5.561c0.801,0.624,1.829,0.958,2.92,0.849c0.098-0.01,0.188-0.041,0.284-0.058
                        C186.756,217.949,186.702,216.683,186.75,215.25z"></path>
            </g>
            <g style="opacity:0.6;">
            <linearGradient id="SVGID_00000107548389322635680700000013533186986161733031_" gradientUnits="userSpaceOnUse" x1="154.0981" y1="215.625" x2="207.5277" y2="215.625">
            <stop offset="0.0551" style="stop-color:#E8F2FF"></stop>
            <stop offset="0.7" style="stop-color:#005AD8"></stop>
            <stop offset="0.7101" style="stop-color:#0056CF"></stop>
            <stop offset="0.7573" style="stop-color:#0048AB"></stop>
            <stop offset="0.8045" style="stop-color:#003C8E"></stop>
            <stop offset="0.8515" style="stop-color:#003479"></stop>
            <stop offset="0.8979" style="stop-color:#002F6D"></stop>
            <stop offset="0.9433" style="stop-color:#002D69"></stop>
            </linearGradient>
            <path style="fill: #b7ce96;" d="M184.5,178.25c-12.5-9-6-24.5-6-24.5
                        s-10.25,7.25-14.75,12.5c-12.869,15.015-12.869,83.735,0,98.75c4.5,5.25,14.75,12.5,14.75,12.5s-6.5-15.5,6-24.5
                        c12.27-8.834,23.327-17.436,23.021-37.375C207.827,195.687,196.77,187.085,184.5,178.25z"></path>
            </g>
            <g>
            <radialGradient id="SVGID_00000060005800318876537710000017467545727883466170_" cx="167.2585" cy="215.625" r="44.7309" gradientUnits="userSpaceOnUse">
            <stop offset="0.852" style="stop-color:#BFDAF2"></stop>
            <stop offset="0.8542" style="stop-color:#B3D6F0"></stop>
            <stop offset="0.8643" style="stop-color:#84C5E7"></stop>
            <stop offset="0.8747" style="stop-color:#5CB6DF"></stop>
            <stop offset="0.8853" style="stop-color:#3AAAD9"></stop>
            <stop offset="0.8961" style="stop-color:#21A1D4"></stop>
            <stop offset="0.9072" style="stop-color:#0E9AD1"></stop>
            <stop offset="0.9189" style="stop-color:#0496CF"></stop>
            <stop offset="0.9319" style="stop-color:#0095CE"></stop>
            </radialGradient>
            <path style="fill: #b7ce96;" d="M179.068,259.037
                        c-5.521-8.409-9.881-17.445-11.834-27.397c-2.048-10.433-2.388-21.707,1.016-31.895c2.995-8.967,7.922-17.05,12.169-25.449
                        c-6.77-8.956-1.919-20.545-1.919-20.545s-10.25,7.25-14.75,12.5c-12.869,15.015-12.869,83.735,0,98.75
                        c4.5,5.25,14.75,12.5,14.75,12.5S174.359,267.606,179.068,259.037z"></path>
            </g>
            <radialGradient id="SVGID_00000155134414738043523560000008260209669941156489_" cx="277.5006" cy="212" r="158.2857" gradientUnits="userSpaceOnUse">
            <stop offset="0.7329" style="stop-color:#0095CE"></stop>
            <stop offset="0.8087" style="stop-color:#1190FF"></stop>
            <stop offset="0.8562" style="stop-color:#7CC2FF"></stop>
            <stop offset="0.8944" style="stop-color:#1190FF"></stop>
            <stop offset="0.9277" style="stop-color:#0C91F2"></stop>
            <stop offset="0.9897" style="stop-color:#0095CF"></stop>
            <stop offset="0.9905" style="stop-color:#0095CE"></stop>
            </radialGradient>
            <path style="fill: #9cba73;" d="M158.537,215.625
                  c0.232-14.179,1.969-28.38,14.713-48.375c14.5-22.75,22.75-33.5,25.25-64.75l-13,0.5c0,0-7.25,19.499-23.498,37.749
                  c-19.978,22.437-43.125,42.224-41.92,74.874c1.256,34.024,20.932,51.308,41.918,74.877c16.25,18.25,23.5,37.75,23.5,37.75l13,0.5
                  c-2.5-31.25-10.75-42-25.25-64.75C160.506,244.005,158.77,229.804,158.537,215.625z"></path>
            <g style="opacity:0.5;">
            <linearGradient id="SVGID_00000109752101826805325950000018404424167250831764_" gradientUnits="userSpaceOnUse" x1="120.0369" y1="212.9368" x2="198.503" y2="212.9368">
            <stop offset="0.0184" style="stop-color:#1190FF"></stop>
            <stop offset="0.2002" style="stop-color:#0C91F2"></stop>
            <stop offset="0.6264" style="stop-color:#0394D8"></stop>
            <stop offset="0.8805" style="stop-color:#0095CE"></stop>
            </linearGradient>
            <path style="fill: #b7ce96;" d="M136.719,258.922
                        c-2.16-5.207-3.322-10.551-2.803-16.196c0.639-6.945,1.611-13.864,2.054-20.827c0.478-7.533,0.465-15.238-1.362-22.61
                        c-1.313-5.293-3.193-10.449-2.603-15.984c0.61-5.72,3.835-11.071,7.001-15.718c1.739-2.552,3.576-5.033,5.41-7.517
                        c-13.811,15.738-25.188,32.415-24.334,55.552c0.793,21.461,8.916,36.26,20.071,50.179
                        C138.873,263.585,137.706,261.303,136.719,258.922z"></path>
            </g>
            <g style="opacity:0.5;">
            <linearGradient id="SVGID_00000143577502486959848910000011781318807217323910_" gradientUnits="userSpaceOnUse" x1="120.1213" y1="215.625" x2="198.4999" y2="215.625">
            <stop offset="0.0184" style="stop-color:#1190FF"></stop>
            <stop offset="0.2002" style="stop-color:#0C91F2"></stop>
            <stop offset="0.6264" style="stop-color:#0394D8"></stop>
            <stop offset="0.8805" style="stop-color:#0095CE"></stop>
            </linearGradient>
            <path style="fill: #9cba73;" d="M158.537,215.625
                        c0.232-14.179,1.969-28.38,14.713-48.375c14.5-22.75,22.75-33.5,25.25-64.75l-13,0.5c0,0-7.25,19.499-23.498,37.749
                        c-1.77,1.987-3.566,3.95-5.344,5.911c1.169,0.502,3.98-3.458,6.008,0.674c2.186,4.453,4.664,10.314,2.654,13.257
                        c-4.311,6.315-11.862,14.487-13.601,19.521c-2.163,6.262-0.974,13.284-0.36,19.719c0.689,7.251,0.554,14.561,0.35,21.836
                        c-0.408,14.51-3.132,27.273,5.291,40.333c1.592,2.47,6.669,9.424,7.334,12.334c0.566,2.478-1,5.333-3.334,9.666
                        c-0.621,1.152-2.334,2-4.623,0.273c1.858,2.04,3.73,4.102,5.623,6.227c16.25,18.25,23.5,37.75,23.5,37.75l13,0.5
                        c-2.5-31.25-10.75-42-25.25-64.75C160.506,244.005,158.77,229.804,158.537,215.625z"></path>
            </g>
            <g>
            <defs>
            <filter id="Adobe_OpacityMaskFilter_00000057849243914228084910000007697059080625329304_" filterUnits="userSpaceOnUse" x="137.834" y="116.633" width="49.34" height="67.904">
                  <feFlood style="flood-color:white;flood-opacity:1" result="back"></feFlood>
                  <feBlend in="SourceGraphic" in2="back" mode="normal"></feBlend>
            </filter>
            </defs>
            <mask maskUnits="userSpaceOnUse" x="137.834" y="116.633" width="49.34" height="67.904" id="SVGID_00000086692985999326762270000007476484625443604626_">
            <g style="filter:url(#Adobe_OpacityMaskFilter_00000057849243914228084910000007697059080625329304_);">
                  <defs>
                  <filter id="Adobe_OpacityMaskFilter_00000040546833219332661590000010698295903623855249_" filterUnits="userSpaceOnUse" x="137.834" y="116.633" width="49.34" height="67.904">
                  <feFlood style="flood-color:white;flood-opacity:1" result="back"></feFlood>
                  <feBlend in="SourceGraphic" in2="back" mode="normal"></feBlend>
                  </filter>
                  </defs>
                  <mask maskUnits="userSpaceOnUse" x="137.834" y="116.633" width="49.34" height="67.904" id="SVGID_00000086692985999326762270000007476484625443604626_">
                  <g style="filter:url(#Adobe_OpacityMaskFilter_00000040546833219332661590000010698295903623855249_);"></g>
                  </mask>
                  <radialGradient id="SVGID_00000162337945532982246500000006731474519371558572_" cx="168.6712" cy="150.7511" r="46.3333" gradientUnits="userSpaceOnUse">
                  <stop offset="0.0673" style="stop-color:#FFFFFF"></stop>
                  <stop offset="0.5796" style="stop-color:#000000"></stop>
                  </radialGradient>
                  <path style="mask:url(#SVGID_00000086692985999326762270000007476484625443604626_);fill:url(#SVGID_00000162337945532982246500000006731474519371558572_);" d="
                                    M181.166,120.667c0,0-19,22.833-25.166,28.333s-12,12.834-13.834,16.334c-1.832,3.5,9-8.333,4.834,2.333
                                    c-4.166,10.667-5.333,14.166-9.166,16.833c0,0,5.499,1.5,12.832-14.666c7.334-16.167,10-20.667,17.5-30.5
                                    c7.5-9.834,12.501-14.333,16.668-18.667C189,116.334,187.5,114.334,181.166,120.667z"></path>
            </g>
            </mask>
            <radialGradient id="SVGID_00000063621804921477504120000014934723749070348672_" cx="168.6712" cy="150.7511" r="46.3333" gradientUnits="userSpaceOnUse">
            <stop offset="0.0673" style="stop-color:#FFFFFF"></stop>
            <stop offset="0.5796" style="stop-color:#5DB4FF"></stop>
            </radialGradient>
            <path style="fill: #9cba73;" d="M181.166,120.667
                        c0,0-19,22.833-25.166,28.333s-12,12.834-13.834,16.334c-1.832,3.5,9-8.333,4.834,2.333c-4.166,10.667-5.333,14.166-9.166,16.833
                        c0,0,5.499,1.5,12.832-14.666c7.334-16.167,10-20.667,17.5-30.5c7.5-9.834,12.501-14.333,16.668-18.667
                        C189,116.334,187.5,114.334,181.166,120.667z"></path>
            <g>
            <path style="fill: #9cba73;" d="M179.5,124.875c0,0-20.5,27.75-23.625,33.625s-7.75,22.625-15.125,25.375s-9.5,2.5-9.5,2.5
                              s9.75-0.625,12.375-5.5s7.75-17.25,9.75-20.75S172.375,131.75,179.5,124.875z"></path>
            </g>
            </g>
            <g>
            <defs>
            <filter id="Adobe_OpacityMaskFilter_00000179618650454365935000000009474677392721297819_" filterUnits="userSpaceOnUse" x="137.834" y="242.297" width="49.34" height="67.904">
                  <feFlood style="flood-color:white;flood-opacity:1" result="back"></feFlood>
                  <feBlend in="SourceGraphic" in2="back" mode="normal"></feBlend>
            </filter>
            </defs>
            <mask maskUnits="userSpaceOnUse" x="137.834" y="242.297" width="49.34" height="67.904" id="SVGID_00000180338780905280434730000003723033784787701396_">
            <g style="filter:url(#Adobe_OpacityMaskFilter_00000179618650454365935000000009474677392721297819_);">
                  <defs>
                  <filter id="Adobe_OpacityMaskFilter_00000142140228054007141940000007348755707898703499_" filterUnits="userSpaceOnUse" x="137.834" y="242.297" width="49.34" height="67.904">
                  <feFlood style="flood-color:white;flood-opacity:1" result="back"></feFlood>
                  <feBlend in="SourceGraphic" in2="back" mode="normal"></feBlend>
                  </filter>
                  </defs>
                  <mask maskUnits="userSpaceOnUse" x="137.834" y="242.297" width="49.34" height="67.904" id="SVGID_00000180338780905280434730000003723033784787701396_">
                  <g style="filter:url(#Adobe_OpacityMaskFilter_00000142140228054007141940000007348755707898703499_);"></g>
                  </mask>
                  <radialGradient id="SVGID_00000134949075501291053670000007582143654810013597_" cx="168.6712" cy="-829.0687" r="46.3333" gradientTransform="matrix(1 0 0 -1 0 -552.9863)" gradientUnits="userSpaceOnUse">
                  <stop offset="0.0673" style="stop-color:#FFFFFF"></stop>
                  <stop offset="0.5796" style="stop-color:#000000"></stop>
                  </radialGradient>
                  <path style="mask:url(#SVGID_00000180338780905280434730000003723033784787701396_);fill:url(#SVGID_00000134949075501291053670000007582143654810013597_);" d="
                                    M181.166,306.167c0,0-19-22.833-25.166-28.333S144,265,142.166,261.5c-1.832-3.5,9,8.333,4.834-2.333
                                    s-5.333-14.166-9.166-16.833c0,0,5.499-1.5,12.832,14.666c7.334,16.167,10,20.667,17.5,30.5
                                    c7.5,9.834,12.501,14.333,16.668,18.667C189,310.5,187.5,312.5,181.166,306.167z"></path>
            </g>
            </mask>
            <radialGradient id="SVGID_00000002378783220219714210000002059332639940591038_" cx="168.6712" cy="-829.0687" r="46.3333" gradientTransform="matrix(1 0 0 -1 0 -552.9863)" gradientUnits="userSpaceOnUse">
            <stop offset="0.0673" style="stop-color:#FFFFFF"></stop>
            <stop offset="0.5796" style="stop-color:#5DB4FF"></stop>
            </radialGradient>
            <path style="fill: #9cba73;" d="M181.166,306.167
                        c0,0-19-22.833-25.166-28.333S144,265,142.166,261.5c-1.832-3.5,9,8.333,4.834-2.333s-5.333-14.166-9.166-16.833
                        c0,0,5.499-1.5,12.832,14.666c7.334,16.167,10,20.667,17.5,30.5c7.5,9.834,12.501,14.333,16.668,18.667
                        C189,310.5,187.5,312.5,181.166,306.167z"></path>
            <g>
            <path style="fill: #9cba73;" d="M179.5,301.959c0,0-20.5-27.75-23.625-33.625s-7.75-22.625-15.125-25.375s-9.5-2.5-9.5-2.5
                              s9.75,0.625,12.375,5.5s7.75,17.25,9.75,20.75S172.375,295.084,179.5,301.959z"></path>
            </g>
            </g>
            <g style="opacity:0.8;">
            <linearGradient id="SVGID_00000168101101673887628160000006126638833615604635_" gradientUnits="userSpaceOnUse" x1="120.0732" y1="215.625" x2="198.5002" y2="215.625">
            <stop offset="0.0184" style="stop-color:#1190FF"></stop>
            <stop offset="0.2002" style="stop-color:#0C91F2"></stop>
            <stop offset="0.6264" style="stop-color:#0394D8"></stop>
            <stop offset="0.8805" style="stop-color:#0095CE"></stop>
            </linearGradient>
            <path style="fill: #9cba73;" d="M158.537,215.625
                        c0.232-14.179,1.969-28.38,14.713-48.375c14.5-22.75,22.75-33.5,25.25-64.75l-13,0.5c0,0-7.242,19.48-23.475,37.722
                        c-1.703,1.913-3.455,3.778-5.067,5.757c10.292-8.229,16.75-17.017,22.042-24.979c2.268-3.413,3.822-6.12,8.666-6
                        c3.785,0.094,6.242,1.861,5.066,5.881c-1.398,4.773-3.688,9.531-6.254,13.89c-5.729,9.729-10.634,3.517-15.646,3.729
                        c-2.175,0.092-4.871,2.328-4.833,5.5c0.035,2.944,3.258,5.54,5.197,7.601c1.129,1.199,2.145,2.309,2.442,3.978
                        c0.407,2.283-0.593,4.841-1.448,6.887c-2.544,6.087-5.221,12.697-9.271,17.96c-2.358,3.066-5.818,3.497-9.339,3.968
                        c2.427,1.056,4.664,2.547,4.913,5.456c0.206,2.394-0.178,4.852-0.416,7.231c-0.373,3.72-0.811,7.433-1.181,11.153
                        c-0.396,3.979-0.895,8.043-0.796,12.046c0.092,3.643,0.632,7.305,0.445,10.952c-0.295,5.793,0.203,7.767-5.047,11.017
                        c0.029,0.195,3.016-0.224,5-0.25c3.018-0.04,5.488,5.453,6.858,7.686c2.293,3.736,4.819,7.976,5.981,12.245
                        c0.867,3.184,0.408,6.473-0.33,9.639c-1.113,4.768-5.599,15.051-1.26,19.18c4.072,3.876,10.164-5.816,14.999-2.804
                        c3.363,2.096,4.982,6.854,6.468,10.368c1.275,3.021,2.396,6.358,2.563,9.658c0.107,2.091-0.206,5.188-2.75,5.72
                        c-8.02,1.676-18.947-15.398-23.964-20.436c-0.579-0.582-1.16-1.161-1.742-1.74c15.318,17.797,22.176,36.233,22.176,36.233l13,0.5
                        c-2.5-31.25-10.75-42-25.25-64.75C160.506,244.005,158.77,229.804,158.537,215.625z"></path>
            </g>
            <g style="opacity:0.8;">
            <linearGradient id="SVGID_00000127743912765032165290000008298099299169963187_" gradientUnits="userSpaceOnUse" x1="120.0732" y1="322.3028" x2="198.5" y2="322.3028">
            <stop offset="0.0184" style="stop-color:#1190FF"></stop>
            <stop offset="0.2002" style="stop-color:#0C91F2"></stop>
            <stop offset="0.6264" style="stop-color:#0394D8"></stop>
            <stop offset="0.8805" style="stop-color:#0095CE"></stop>
            </linearGradient>
            <path style="fill: #b7ce96;" d="M185.012,316.516
                        c-1.715,0.1-3.414,0.286-5.079-0.125c3.779,7.06,5.567,11.859,5.567,11.859l13,0.5c-0.379-4.733-0.892-8.989-1.535-12.894
                        C196.125,316.875,188.977,316.285,185.012,316.516z"></path>
            </g>
            <g style="opacity:0.8;">
            <linearGradient id="SVGID_00000046299519195942490720000014425024187100800957_" gradientUnits="userSpaceOnUse" x1="120.0711" y1="108.9207" x2="198.5" y2="108.9207">
            <stop offset="0.0184" style="stop-color:#1190FF"></stop>
            <stop offset="0.2002" style="stop-color:#0C91F2"></stop>
            <stop offset="0.6264" style="stop-color:#0394D8"></stop>
            <stop offset="0.8805" style="stop-color:#0095CE"></stop>
            </linearGradient>
            <path style="fill: #b7ce96;" d="M191.286,114.74
                        c1.898,0.184,3.792,0.409,5.686,0.602c0.641-3.891,1.15-8.13,1.527-12.841l-13,0.5c0,0-1.756,4.716-5.467,11.673
                        C183.782,114.504,187.551,114.378,191.286,114.74z"></path>
            </g>
            <g style="opacity:0.8;">
            <linearGradient id="SVGID_00000057852994473209945980000002572518945219211147_" gradientUnits="userSpaceOnUse" x1="118.5288" y1="144.873" x2="199.593" y2="144.873">
            <stop offset="0.0184" style="stop-color:#1190FF"></stop>
            <stop offset="0.2002" style="stop-color:#0C91F2"></stop>
            <stop offset="0.6264" style="stop-color:#0394D8"></stop>
            <stop offset="0.8805" style="stop-color:#0095CE"></stop>
            </linearGradient>
            <path style="fill: #9cba73;" d="M166,144.5
                        c0.113,9.348,16.651,7.187,10.249-2.586C173.368,137.518,165.932,138.72,166,144.5z"></path>
            </g>
            <g style="opacity:0.8;">
            <linearGradient id="SVGID_00000081607784555159770440000009856364850346529168_" gradientUnits="userSpaceOnUse" x1="121.5414" y1="285.8908" x2="197.4131" y2="285.8908">
            <stop offset="0.0184" style="stop-color:#1190FF"></stop>
            <stop offset="0.2002" style="stop-color:#0C91F2"></stop>
            <stop offset="0.6264" style="stop-color:#0394D8"></stop>
            <stop offset="0.8805" style="stop-color:#0095CE"></stop>
            </linearGradient>
            <path style="fill: #9cba73;" d="M172.875,279.75
                        c-3.109-0.126-6.078,1.26-6.621,4.735c-1.054,6.744,4.711,10.364,9.371,4.89C178.105,286.461,178.147,279.964,172.875,279.75z"></path>
            </g>
            <linearGradient id="SVGID_00000002355294603462048930000012721889372825665161_" gradientUnits="userSpaceOnUse" x1="129.8642" y1="188.2501" x2="129.8642" y2="244.3058">
            <stop offset="0.14" style="stop-color:#0095CE"></stop>
            <stop offset="0.3727" style="stop-color:#1190FF"></stop>
            <stop offset="0.5263" style="stop-color:#89C7FF"></stop>
            <stop offset="0.6716" style="stop-color:#1190FF"></stop>
            <stop offset="0.7821" style="stop-color:#0C91F2"></stop>
            <stop offset="0.9877" style="stop-color:#0095CF"></stop>
            <stop offset="0.9905" style="stop-color:#0095CE"></stop>
            </linearGradient>
            <path style="fill: #b7ce96;" d="M134.5,214.75
                  c0-22.145-4.984-28.356-7.535-30.087c-0.609,1.215-1.195,2.441-1.736,3.691c2.298,1.139,7.021,5.961,7.021,26.396
                  c0,14.901-3.426,23.274-6.875,26.715c0.488,1.224,1.006,2.427,1.551,3.61C129.463,243.312,134.5,237.008,134.5,214.75z"></path>
            <linearGradient id="SVGID_00000053504155260748491910000016684194606284833697_" gradientUnits="userSpaceOnUse" x1="120.0371" y1="214.9097" x2="132.25" y2="214.9097">
            <stop offset="0" style="stop-color:#5A6276"></stop>
            <stop offset="0.0487" style="stop-color:#6D7184"></stop>
            <stop offset="0.1585" style="stop-color:#94909E"></stop>
            <stop offset="0.2466" style="stop-color:#ABA3AF"></stop>
            <stop offset="0.3012" style="stop-color:#B4AAB5"></stop>
            <stop offset="0.3902" style="stop-color:#BEB8C5"></stop>
            <stop offset="0.5076" style="stop-color:#CED0E0"></stop>
            <stop offset="0.6526" style="stop-color:#FAFBFF"></stop>
            <stop offset="0.6955" style="stop-color:#F4F5FA"></stop>
            <stop offset="0.7545" style="stop-color:#E2E4EA"></stop>
            <stop offset="0.8228" style="stop-color:#C5C8D2"></stop>
            <stop offset="0.8982" style="stop-color:#9CA2AF"></stop>
            <stop offset="0.9785" style="stop-color:#697083"></stop>
            <stop offset="1" style="stop-color:#5A6276"></stop>
            </linearGradient>
            <path style="fill:url(#SVGID_00000053504155260748491910000016684194606284833697_);" d="M132.25,214.75
                  c0-20.435-4.724-25.257-7.021-26.396c-3.527,8.152-5.521,17.085-5.146,27.269c0.363,9.828,2.27,18.253,5.293,25.842
                  C128.824,238.024,132.25,229.651,132.25,214.75z"></path>
            <g style="opacity:0.8;">
            <g style="opacity:0.5;">
            <linearGradient id="SVGID_00000010279155819149177500000001624093473323664527_" gradientUnits="userSpaceOnUse" x1="-662.1761" y1="352.3116" x2="-641.0679" y2="315.7195" gradientTransform="matrix(0.2763 -0.0124 0.0448 1.0029 290.9128 -128.1664)">
                  <stop offset="0.0053" style="stop-color:#626A7D"></stop>
                  <stop offset="0.5076" style="stop-color:#CED0E0"></stop>
                  <stop offset="0.509" style="stop-color:#D0D2E1"></stop>
                  <stop offset="0.5276" style="stop-color:#E5E6EE"></stop>
                  <stop offset="0.5483" style="stop-color:#F4F4F8"></stop>
                  <stop offset="0.5728" style="stop-color:#FCFCFD"></stop>
                  <stop offset="0.6105" style="stop-color:#FFFFFF"></stop>
                  <stop offset="0.6257" style="stop-color:#FFFFFF"></stop>
                  <stop offset="0.7182" style="stop-color:#FFFFFF"></stop>
                  <stop offset="0.7282" style="stop-color:#F4F4F6"></stop>
                  <stop offset="0.7578" style="stop-color:#D8DADE"></stop>
                  <stop offset="0.7898" style="stop-color:#C3C5CC"></stop>
                  <stop offset="0.8252" style="stop-color:#B4B6BF"></stop>
                  <stop offset="0.8664" style="stop-color:#ABAEB7"></stop>
                  <stop offset="0.9272" style="stop-color:#A8ABB5"></stop>
            </linearGradient>
            <path style="fill:url(#SVGID_00000010279155819149177500000001624093473323664527_);" d="M130.359,214.625
                              c0.406,12.152-1.291,22.12-3.791,22.271c-2.5,0.151-4.855-9.578-5.26-21.722c-0.408-12.151,1.29-22.119,3.79-22.273
                              C127.6,192.749,129.953,202.48,130.359,214.625z"></path>
            </g>
            <radialGradient id="SVGID_00000156580407804368094120000015113112406501957261_" cx="70.2995" cy="204.4031" r="32.0673" gradientTransform="matrix(0.392 -0.1979 0.1031 0.9836 78.2214 33.9095)" gradientUnits="userSpaceOnUse">
            <stop offset="0" style="stop-color:#FFFFFF"></stop>
            <stop offset="0.2101" style="stop-color:#F3F2F4"></stop>
            <stop offset="0.6002" style="stop-color:#D2CFD6"></stop>
            <stop offset="0.678" style="stop-color:#CBC7CF"></stop>
            <stop offset="0.6908" style="stop-color:#CBC7CF"></stop>
            <stop offset="1" style="stop-color:#CBC7CF"></stop>
            </radialGradient>
            <path style="fill:url(#SVGID_00000156580407804368094120000015113112406501957261_);" d="M125.099,192.9
                        c-2.5,0.154-4.198,10.122-3.79,22.273c0.404,12.145,2.76,21.874,5.26,21.722c2.5-0.15,4.197-10.119,3.791-22.271
                        C129.953,202.48,127.6,192.749,125.099,192.9z M126.15,232.359c-5.113,0.302-6.4-38.223-1.288-38.534
                        C129.975,193.522,131.262,232.05,126.15,232.359z"></path>
            <radialGradient id="SVGID_00000091699783206214008380000017198230569733531555_" cx="-642.0143" cy="337.7612" r="27.8984" gradientTransform="matrix(0.2064 -0.0124 0.0335 1.0029 246.0629 -128.1664)" gradientUnits="userSpaceOnUse">
            <stop offset="0" style="stop-color:#FFFFFF"></stop>
            <stop offset="0.2101" style="stop-color:#F3F2F4"></stop>
            <stop offset="0.6002" style="stop-color:#D2CFD6"></stop>
            <stop offset="0.678" style="stop-color:#CBC7CF"></stop>
            <stop offset="0.6908" style="stop-color:#CBC7CF"></stop>
            <stop offset="1" style="stop-color:#CBC7CF"></stop>
            </radialGradient>
            <path style="fill:url(#SVGID_00000091699783206214008380000017198230569733531555_);" d="M126.303,230.777
                        c-1.764,0.777-3.947-6.473-4.164-17.477c-0.197-10.033,1.195-17.812,2.375-17.543c1.178,0.263,0.924,2.953,0,12.375
                        c-0.925,9.426,1.178,14.654,1.463,14.969C126.264,223.423,128.785,229.69,126.303,230.777z"></path>
            </g>
            <path style="fill:#FFFFFF;" d="M125.512,209.191c-1.356-0.066-1.004-12.749,0.353-12.684
                  C127.221,196.575,126.869,209.258,125.512,209.191z"></path>
            <path style="fill:#FFFFFF;" d="M124.664,191.787c-2.104,2.4-3.764,14.305-3.727,22.556c0.037,8.252,1.354,20.585,4.222,24.383
                  c0.98,1.298-1.073-1.271-1.972-14.394c-0.9-13.124,0.133-22.594,1.102-28.768C125.258,189.391,125.733,190.567,124.664,191.787z"></path>
            <path style="fill:#FFFFFF;" d="M129.334,195.5c0,0,3.492,31.022-2.484,42.602c0,0,5.096-5.146,4.984-22.602
                  C131.721,198.044,129.334,195.5,129.334,195.5z"></path>
            <defs>
            <filter id="Adobe_OpacityMaskFilter_00000038392105863690512490000009566384619992660614_" filterUnits="userSpaceOnUse" x="103.657" y="173.124" width="25.744" height="80.275">
            <feFlood style="flood-color:white;flood-opacity:1" result="back"></feFlood>
            <feBlend in="SourceGraphic" in2="back" mode="normal"></feBlend>
            </filter>
            </defs>
            <mask maskUnits="userSpaceOnUse" x="103.657" y="173.124" width="25.744" height="80.275" id="SVGID_00000047740957938113049470000014822377355727970723_">
            <g style="filter:url(#Adobe_OpacityMaskFilter_00000038392105863690512490000009566384619992660614_);">
            <defs>
                  <filter id="Adobe_OpacityMaskFilter_00000145050931566451973330000007029716128465578916_" filterUnits="userSpaceOnUse" x="103.657" y="173.124" width="25.744" height="80.275">
                  <feFlood style="flood-color:white;flood-opacity:1" result="back"></feFlood>
                  <feBlend in="SourceGraphic" in2="back" mode="normal"></feBlend>
                  </filter>
            </defs>
            <mask maskUnits="userSpaceOnUse" x="103.657" y="173.124" width="25.744" height="80.275" id="SVGID_00000047740957938113049470000014822377355727970723_">
                  <g style="filter:url(#Adobe_OpacityMaskFilter_00000145050931566451973330000007029716128465578916_);"></g>
            </mask>
            <linearGradient id="SVGID_00000157309677030140856970000004149068493985324721_" gradientUnits="userSpaceOnUse" x1="103.5688" y1="213.6481" x2="123.4981" y2="213.3367">
                  <stop offset="0.0519" style="stop-color:#FFFFFF"></stop>
                  <stop offset="0.1378" style="stop-color:#F2F2F2"></stop>
                  <stop offset="0.2983" style="stop-color:#CECECE"></stop>
                  <stop offset="0.5161" style="stop-color:#959595"></stop>
                  <stop offset="0.7797" style="stop-color:#474747"></stop>
                  <stop offset="1" style="stop-color:#000000"></stop>
            </linearGradient>
            <path style="mask:url(#SVGID_00000047740957938113049470000014822377355727970723_);fill:url(#SVGID_00000157309677030140856970000004149068493985324721_);" d="
                              M103.75,214.5c1.236-10.93,9.25-25.25,18-34.25S131.5,170,126,181s-9.5,20.5-9.5,31.25s-0.917,23.026,9,37
                              C136.5,264.75,101.637,233.17,103.75,214.5z"></path>
            </g>
            </mask>
            <linearGradient id="SVGID_00000062177271696279774100000012577517721605602976_" gradientUnits="userSpaceOnUse" x1="103.5688" y1="213.6481" x2="123.4981" y2="213.3367">
            <stop offset="0.0519" style="stop-color:#E8E8E8"></stop>
            <stop offset="0.1505" style="stop-color:#DBE3E9"></stop>
            <stop offset="0.3349" style="stop-color:#B7D4ED"></stop>
            <stop offset="0.585" style="stop-color:#7EBDF3"></stop>
            <stop offset="0.8881" style="stop-color:#309DFC"></stop>
            <stop offset="1" style="stop-color:#1190FF"></stop>
            </linearGradient>
            <path style="fill: #b7ce96;" d="M103.75,214.5
                  c1.236-10.93,9.25-25.25,18-34.25S131.5,170,126,181s-9.5,20.5-9.5,31.25s-0.917,23.026,9,37
                  C136.5,264.75,101.637,233.17,103.75,214.5z"></path>
            <g>
            <defs>
            <filter id="Adobe_OpacityMaskFilter_00000088828234108347407400000004400140550383691932_" filterUnits="userSpaceOnUse" x="148.326" y="163.25" width="17.008" height="110.334">
                  <feFlood style="flood-color:white;flood-opacity:1" result="back"></feFlood>
                  <feBlend in="SourceGraphic" in2="back" mode="normal"></feBlend>
            </filter>
            </defs>
            <mask maskUnits="userSpaceOnUse" x="148.326" y="163.25" width="17.008" height="110.334" id="SVGID_00000093163840879803358980000004075309978924623796_">
            <g style="filter:url(#Adobe_OpacityMaskFilter_00000088828234108347407400000004400140550383691932_);">
                  <defs>
                  <filter id="Adobe_OpacityMaskFilter_00000036253784758981592660000010238222094986190726_" filterUnits="userSpaceOnUse" x="148.326" y="163.25" width="17.008" height="110.334">
                  <feFlood style="flood-color:white;flood-opacity:1" result="back"></feFlood>
                  <feBlend in="SourceGraphic" in2="back" mode="normal"></feBlend>
                  </filter>
                  </defs>
                  <mask maskUnits="userSpaceOnUse" x="148.326" y="163.25" width="17.008" height="110.334" id="SVGID_00000093163840879803358980000004075309978924623796_">
                  <g style="filter:url(#Adobe_OpacityMaskFilter_00000036253784758981592660000010238222094986190726_);"></g>
                  </mask>
                  <radialGradient id="SVGID_00000114075606908501389480000006212560042636874628_" cx="138.5" cy="212.7498" r="73.0842" gradientUnits="userSpaceOnUse">
                  <stop offset="0.2941" style="stop-color:#FFFFFF"></stop>
                  <stop offset="1" style="stop-color:#000000"></stop>
                  </radialGradient>
                  <path style="mask:url(#SVGID_00000093163840879803358980000004075309978924623796_);fill:url(#SVGID_00000114075606908501389480000006212560042636874628_);" d="
                                    M163.25,163.25c0,0-13,12.75-14.5,18.25s1.5,14,1,32.5s-4,34.75,3.75,44.5s11.834,15.084,11.834,15.084
                                    S155.5,259.25,152.75,250.5s-0.25-19.5,0-33.5s-3.25-29.25-0.75-35.75S163.25,163.25,163.25,163.25z"></path>
            </g>
            </mask>
            <radialGradient id="SVGID_00000083051603976228020290000011215839402419142838_" cx="138.5" cy="212.7498" r="73.0842" gradientUnits="userSpaceOnUse">
            <stop offset="0.2941" style="stop-color:#FFFFFF"></stop>
            <stop offset="1" style="stop-color:#5DB4FF"></stop>
            </radialGradient>
            <path style="fill: #9cba73;" d="M163.25,163.25c0,0-13,12.75-14.5,18.25
                        s1.5,14,1,32.5s-4,34.75,3.75,44.5s11.834,15.084,11.834,15.084S155.5,259.25,152.75,250.5s-0.25-19.5,0-33.5
                        s-3.25-29.25-0.75-35.75S163.25,163.25,163.25,163.25z"></path>
            </g>
            <defs>
            <filter id="Adobe_OpacityMaskFilter_00000082365051008687162280000008240547609856983195_" filterUnits="userSpaceOnUse" x="130" y="167.5" width="18.548" height="55.127">
            <feFlood style="flood-color:white;flood-opacity:1" result="back"></feFlood>
            <feBlend in="SourceGraphic" in2="back" mode="normal"></feBlend>
            </filter>
            </defs>
            <mask maskUnits="userSpaceOnUse" x="130" y="167.5" width="18.548" height="55.127" id="SVGID_00000144304057317660191680000015749727697646301872_">
            <g style="filter:url(#Adobe_OpacityMaskFilter_00000082365051008687162280000008240547609856983195_);">
            <defs>
                  <filter id="Adobe_OpacityMaskFilter_00000145025835870947131530000001442064079518114974_" filterUnits="userSpaceOnUse" x="130" y="167.5" width="18.548" height="55.127">
                  <feFlood style="flood-color:white;flood-opacity:1" result="back"></feFlood>
                  <feBlend in="SourceGraphic" in2="back" mode="normal"></feBlend>
                  </filter>
            </defs>
            <mask maskUnits="userSpaceOnUse" x="130" y="167.5" width="18.548" height="55.127" id="SVGID_00000144304057317660191680000015749727697646301872_">
                  <g style="filter:url(#Adobe_OpacityMaskFilter_00000145025835870947131530000001442064079518114974_);"></g>
            </mask>
            <linearGradient id="SVGID_00000152945740249413371540000007360694186826372012_" gradientUnits="userSpaceOnUse" x1="140.238" y1="220.4336" x2="138.2379" y2="168.5996">
                  <stop offset="0.2121" style="stop-color:#000000"></stop>
                  <stop offset="0.5495" style="stop-color:#FFFFFF"></stop>
                  <stop offset="0.8582" style="stop-color:#000000"></stop>
            </linearGradient>
            <path style="mask:url(#SVGID_00000144304057317660191680000015749727697646301872_);fill:url(#SVGID_00000152945740249413371540000007360694186826372012_);" d="
                              M133.5,185.834c0,0,10.833-2.167,10.666-7.334c-0.166-5.166-0.832-11-0.832-11s2.666,7.334,3.666,15
                              c1,7.667,2.166,25.167,1.166,36.667s-0.332-9-2.666-13.167c-2.334-4.166-9.334-3.333-9.334-3.333s-2.5-13-6.166-16.667
                              C130,186,132,186.501,133.5,185.834z"></path>
            </g>
            </mask>
            <linearGradient id="SVGID_00000055696887795822739230000007452737744750388158_" gradientUnits="userSpaceOnUse" x1="140.238" y1="220.4336" x2="138.2379" y2="168.5996">
            <stop offset="0.2121" style="stop-color:#FF755D"></stop>
            <stop offset="0.5495" style="stop-color:#FFFFFF"></stop>
            <stop offset="0.8582" style="stop-color:#FF755D"></stop>
            </linearGradient>
            <path style="opacity:0.5;fill:url(#SVGID_00000055696887795822739230000007452737744750388158_);" d="M133.5,185.834
                  c0,0,10.833-2.167,10.666-7.334c-0.166-5.166-0.832-11-0.832-11s2.666,7.334,3.666,15c1,7.667,2.166,25.167,1.166,36.667
                  s-0.332-9-2.666-13.167c-2.334-4.166-9.334-3.333-9.334-3.333s-2.5-13-6.166-16.667C130,186,132,186.501,133.5,185.834z"></path>
            <defs>
            <filter id="Adobe_OpacityMaskFilter_00000070109932230915379940000003973935900102213545_" filterUnits="userSpaceOnUse" x="131.082" y="203.32" width="17.409" height="55.3">
            <feFlood style="flood-color:white;flood-opacity:1" result="back"></feFlood>
            <feBlend in="SourceGraphic" in2="back" mode="normal"></feBlend>
            </filter>
            </defs>
            <mask maskUnits="userSpaceOnUse" x="131.082" y="203.32" width="17.409" height="55.3" id="SVGID_00000147221367815826801530000001486661382119005359_">
            <g style="filter:url(#Adobe_OpacityMaskFilter_00000070109932230915379940000003973935900102213545_);">
            <defs>
                  <filter id="Adobe_OpacityMaskFilter_00000042016256532441749010000017964869145365910189_" filterUnits="userSpaceOnUse" x="131.082" y="203.32" width="17.409" height="55.3">
                  <feFlood style="flood-color:white;flood-opacity:1" result="back"></feFlood>
                  <feBlend in="SourceGraphic" in2="back" mode="normal"></feBlend>
                  </filter>
            </defs>
            <mask maskUnits="userSpaceOnUse" x="131.082" y="203.32" width="17.409" height="55.3" id="SVGID_00000147221367815826801530000001486661382119005359_">
                  <g style="filter:url(#Adobe_OpacityMaskFilter_00000042016256532441749010000017964869145365910189_);"></g>
            </mask>
            <linearGradient id="SVGID_00000116954247622319978620000012702651787651351438_" gradientUnits="userSpaceOnUse" x1="108.0528" y1="-671.2167" x2="106.0528" y2="-723.0495" gradientTransform="matrix(0.9972 -0.0751 -0.0751 -0.9972 -19.4535 -455.1551)">
                  <stop offset="0.2121" style="stop-color:#000000"></stop>
                  <stop offset="0.5495" style="stop-color:#FFFFFF"></stop>
                  <stop offset="0.8582" style="stop-color:#000000"></stop>
            </linearGradient>
            <path style="mask:url(#SVGID_00000147221367815826801530000001486661382119005359_);fill:url(#SVGID_00000116954247622319978620000012702651787651351438_);" d="
                              M134.584,241.076c0,0,10.966,1.347,11.188,6.511c0.223,5.165-0.004,11.032-0.004,11.032s2.108-7.513,2.529-15.233
                              c0.422-7.72,0.27-25.258-1.592-36.651c-1.86-11.392,0.345,9-1.67,13.33c-2.014,4.33-9.057,4.025-9.057,4.025
                              s-1.516,13.151-4.896,17.083C131.082,241.173,133.039,240.524,134.584,241.076z"></path>
            </g>
            </mask>
            <linearGradient id="SVGID_00000122719748281175762770000012869026436224429203_" gradientUnits="userSpaceOnUse" x1="108.0528" y1="-671.2167" x2="106.0528" y2="-723.0495" gradientTransform="matrix(0.9972 -0.0751 -0.0751 -0.9972 -19.4535 -455.1551)">
            <stop offset="0.2121" style="stop-color:#FF755D"></stop>
            <stop offset="0.5495" style="stop-color:#FFFFFF"></stop>
            <stop offset="0.8582" style="stop-color:#FF755D"></stop>
            </linearGradient>
            <path style="opacity:0.5;fill:url(#SVGID_00000122719748281175762770000012869026436224429203_);" d="M134.584,241.076
                  c0,0,10.966,1.347,11.188,6.511c0.223,5.165-0.004,11.032-0.004,11.032s2.108-7.513,2.529-15.233
                  c0.422-7.72,0.27-25.258-1.592-36.651c-1.86-11.392,0.345,9-1.67,13.33c-2.014,4.33-9.057,4.025-9.057,4.025
                  s-1.516,13.151-4.896,17.083C131.082,241.173,133.039,240.524,134.584,241.076z"></path>
      </g>
      <g>
            <g>
            <linearGradient id="SVGID_00000181061073872121941120000012727312446499704194_" gradientUnits="userSpaceOnUse" x1="76.609" y1="-491.9023" x2="93.1145" y2="-429.3115" gradientTransform="matrix(0.9755 -0.22 -0.22 -0.9755 -7.8837 -52.8742)">
            <stop offset="0.0673" style="stop-color:#8F906F"></stop>
            <stop offset="0.209" style="stop-color:#AAAA92"></stop>
            <stop offset="0.3767" style="stop-color:#C5C5B4"></stop>
            <stop offset="0.5148" style="stop-color:#D6D5C9"></stop>
            <stop offset="0.6053" style="stop-color:#DCDBD1"></stop>
            <stop offset="0.6831" style="stop-color:#D1D1C4"></stop>
            <stop offset="0.8284" style="stop-color:#B6B6A0"></stop>
            <stop offset="1" style="stop-color:#8F906F"></stop>
            </linearGradient>
            <path style="fill:url(#SVGID_00000181061073872121941120000012727312446499704194_);" d="M167.055,374.641
                        c-0.348-3.943-1.004-15.664,1.706-22.339c2.71-6.677,7.642-8.984,11.132-1.229c3.492,7.756,8.976,32.317,3.961,50.705
                        C178.84,420.164,168.566,391.812,167.055,374.641z"></path>
            <linearGradient id="SVGID_00000120530817280793903130000002941670593107743118_" gradientUnits="userSpaceOnUse" x1="75.9394" y1="-448.6451" x2="95.172" y2="-448.6451" gradientTransform="matrix(0.9755 -0.22 -0.22 -0.9755 -7.8837 -52.8742)">
            <stop offset="0.2234" style="stop-color:#71798E"></stop>
            <stop offset="0.3219" style="stop-color:#9399A9"></stop>
            <stop offset="0.4418" style="stop-color:#B5BAC4"></stop>
            <stop offset="0.5406" style="stop-color:#CACED5"></stop>
            <stop offset="0.6053" style="stop-color:#D2D5DB"></stop>
            <stop offset="0.6838" style="stop-color:#C5C8D0"></stop>
            <stop offset="0.8302" style="stop-color:#A1A7B4"></stop>
            <stop offset="1" style="stop-color:#71798E"></stop>
            </linearGradient>
            <path style="opacity:0.5;fill:url(#SVGID_00000120530817280793903130000002941670593107743118_);" d="M185.604,377.827
                        c-0.301-2.518-0.648-4.998-1.131-7.2c-0.902-4.105-2.998-13.702-6.903-14.057c-1.708-0.156-3.026,3.022-3.763,4.694
                        c-1.036,2.346-2.253,7.408-2.253,7.408s2.079,11.96,0.739,16.235c-1.476,4.71-3.565-3.296-4.051-5.75
                        c-0.098-0.489-0.588-0.538-0.652-0.144c-0.23-1.51-0.412-2.978-0.535-4.372c-0.348-3.943-1.004-15.664,1.706-22.339
                        c2.71-6.677,7.642-8.984,11.132-1.229C181.965,355.674,184.738,366.192,185.604,377.827z"></path>
            <linearGradient id="SVGID_00000043457057032365658440000008707985947756361104_" gradientUnits="userSpaceOnUse" x1="75.9394" y1="-448.4393" x2="95.1705" y2="-448.4393" gradientTransform="matrix(0.9755 -0.22 -0.22 -0.9755 -7.8837 -52.8742)">
            <stop offset="0.2234" style="stop-color:#71798E"></stop>
            <stop offset="0.3219" style="stop-color:#9399A9"></stop>
            <stop offset="0.4418" style="stop-color:#B5BAC4"></stop>
            <stop offset="0.5406" style="stop-color:#CACED5"></stop>
            <stop offset="0.6053" style="stop-color:#D2D5DB"></stop>
            <stop offset="0.6838" style="stop-color:#C5C8D0"></stop>
            <stop offset="0.8302" style="stop-color:#A1A7B4"></stop>
            <stop offset="1" style="stop-color:#71798E"></stop>
            </linearGradient>
            <path style="opacity:0.5;fill:url(#SVGID_00000043457057032365658440000008707985947756361104_);" d="M177.655,347.975
                        c-2.218-1.011-3.727,0.423-4.773,2.828c-1.748,4.017-2.562,8.766-3.327,13.534c-0.05,0.306,0.108,0.548,0.283,0.63
                        c1.723,3.517,2.344,7.653,2.602,11.57c0.141,2.136,0.299,4.398-0.023,6.394c-0.155,0.968-0.575,2.652-1.344,3.049
                        c-2.74-3.163-3.461-11.025-3.74-14.989c-0.255-3.643-0.306-7.262-0.285-10.862c0.291-2.851,0.814-5.616,1.712-7.827
                        c2.312-5.698,6.241-8.19,9.513-3.984C178.07,348.189,177.864,348.071,177.655,347.975z"></path>
            <linearGradient id="SVGID_00000003069223549808340660000006523915359791494036_" gradientUnits="userSpaceOnUse" x1="76.9975" y1="-457.331" x2="81.4459" y2="-457.331" gradientTransform="matrix(0.9755 -0.22 -0.22 -0.9755 -7.8837 -52.8742)">
            <stop offset="0.2234" style="stop-color:#2A2828"></stop>
            <stop offset="0.459" style="stop-color:#3E3D36"></stop>
            <stop offset="0.7609" style="stop-color:#525243"></stop>
            <stop offset="0.9591" style="stop-color:#595A48"></stop>
            </linearGradient>
            <path style="fill:url(#SVGID_00000003069223549808340660000006523915359791494036_);" d="M167.588,374.264
                        c-0.447-3.029,0.238-7.913,1.558-8.295c1.319-0.383,2.357,6.216,2.689,10.669C172.896,390.991,169.411,386.582,167.588,374.264z"></path>
            <linearGradient id="SVGID_00000163042959407402171720000007586802360584412347_" gradientUnits="userSpaceOnUse" x1="79.1473" y1="-490.4835" x2="85.4005" y2="-466.7704" gradientTransform="matrix(0.9755 -0.22 -0.22 -0.9755 -7.8837 -52.8742)">
            <stop offset="0.0673" style="stop-color:#71798E"></stop>
            <stop offset="0.2061" style="stop-color:#9399A9"></stop>
            <stop offset="0.375" style="stop-color:#B5BAC4"></stop>
            <stop offset="0.5142" style="stop-color:#CACED5"></stop>
            <stop offset="0.6053" style="stop-color:#D2D5DB"></stop>
            <stop offset="0.6838" style="stop-color:#C5C8D0"></stop>
            <stop offset="0.8302" style="stop-color:#A1A7B4"></stop>
            <stop offset="1" style="stop-color:#71798E"></stop>
            </linearGradient>
            <path style="fill:url(#SVGID_00000163042959407402171720000007586802360584412347_);" d="M172.273,386.193
                        c0,0,1.379,8.763,3.869,15.658c0.898,2.488,1.904,5.08,2.145,5.026c0.241-0.054,2.934,2.542,4.9-4.437
                        c0,0-7.737-8.891-10.526-18.64L172.273,386.193z"></path>
            <linearGradient id="SVGID_00000003803413728705918550000012128896880017333660_" gradientUnits="userSpaceOnUse" x1="93.4266" y1="-468.2458" x2="78.5464" y2="-460.8519" gradientTransform="matrix(0.9755 -0.22 -0.22 -0.9755 -7.8837 -52.8742)">
            <stop offset="0.0673" style="stop-color:#71798E"></stop>
            <stop offset="0.2061" style="stop-color:#9399A9"></stop>
            <stop offset="0.375" style="stop-color:#B5BAC4"></stop>
            <stop offset="0.5142" style="stop-color:#CACED5"></stop>
            <stop offset="0.6053" style="stop-color:#D2D5DB"></stop>
            <stop offset="0.6838" style="stop-color:#C5C8D0"></stop>
            <stop offset="0.8302" style="stop-color:#A1A7B4"></stop>
            <stop offset="1" style="stop-color:#71798E"></stop>
            </linearGradient>
            <path style="fill:url(#SVGID_00000003803413728705918550000012128896880017333660_);" d="M172.356,377.589
                        c0,0,6.466,6.614,13.167,7.922l-0.118-2.023C185.405,383.487,177.793,382.257,172.356,377.589z"></path>
            <linearGradient id="SVGID_00000023976133900899956910000003714871207635301524_" gradientUnits="userSpaceOnUse" x1="94.984" y1="-461.9956" x2="80.807" y2="-454.9511" gradientTransform="matrix(0.9755 -0.22 -0.22 -0.9755 -7.8837 -52.8742)">
            <stop offset="0.0673" style="stop-color:#71798E"></stop>
            <stop offset="0.2061" style="stop-color:#9399A9"></stop>
            <stop offset="0.375" style="stop-color:#B5BAC4"></stop>
            <stop offset="0.5142" style="stop-color:#CACED5"></stop>
            <stop offset="0.6053" style="stop-color:#D2D5DB"></stop>
            <stop offset="0.6838" style="stop-color:#C5C8D0"></stop>
            <stop offset="0.8302" style="stop-color:#A1A7B4"></stop>
            <stop offset="1" style="stop-color:#71798E"></stop>
            </linearGradient>
            <path style="opacity:0.5;fill:url(#SVGID_00000023976133900899956910000003714871207635301524_);" d="M172.44,376.537l12.841,4.16
                        c0,0,0.055-4.241-0.691-8.301c0,0-9.676,1.456-12.294,2.559L172.44,376.537z"></path>
            <linearGradient id="SVGID_00000138534393054124625670000011941185886762846636_" gradientUnits="userSpaceOnUse" x1="93.0898" y1="-473.438" x2="72.9625" y2="-463.4369" gradientTransform="matrix(0.9755 -0.22 -0.22 -0.9755 -7.8837 -52.8742)">
            <stop offset="0.0673" style="stop-color:#71798E"></stop>
            <stop offset="0.2061" style="stop-color:#9399A9"></stop>
            <stop offset="0.375" style="stop-color:#B5BAC4"></stop>
            <stop offset="0.5142" style="stop-color:#CACED5"></stop>
            <stop offset="0.6053" style="stop-color:#D2D5DB"></stop>
            <stop offset="0.6838" style="stop-color:#C5C8D0"></stop>
            <stop offset="0.8302" style="stop-color:#A1A7B4"></stop>
            <stop offset="1" style="stop-color:#71798E"></stop>
            </linearGradient>
            <path style="fill:url(#SVGID_00000138534393054124625670000011941185886762846636_);" d="M168.112,381.322
                        c0,0,4.393,21.477,10.175,25.555c5.781,4.078,6.982-16.012,7.124-19.376c0.141-3.364-0.057-12.46-1.55-20.58
                        c0,0,1.558,12.889,0.89,20.643c-0.668,7.754-1.299,18.66-5.331,19.227C175.39,407.359,169.145,386.898,168.112,381.322z"></path>
            <linearGradient id="SVGID_00000134227713081790326840000006784089088944049031_" gradientUnits="userSpaceOnUse" x1="315.0076" y1="477.9807" x2="297.8084" y2="486.5268" gradientTransform="matrix(0.9705 0.2413 -0.2413 0.9705 -6.1091 -173.1716)">
            <stop offset="0.0673" style="stop-color:#71798E"></stop>
            <stop offset="0.2061" style="stop-color:#9399A9"></stop>
            <stop offset="0.375" style="stop-color:#B5BAC4"></stop>
            <stop offset="0.5142" style="stop-color:#CACED5"></stop>
            <stop offset="0.6053" style="stop-color:#D2D5DB"></stop>
            <stop offset="0.6838" style="stop-color:#C5C8D0"></stop>
            <stop offset="0.8302" style="stop-color:#A1A7B4"></stop>
            <stop offset="1" style="stop-color:#71798E"></stop>
            </linearGradient>
            <path style="fill:url(#SVGID_00000134227713081790326840000006784089088944049031_);" d="M167.421,370.188
                        c0,0-1.628-20.207,6.092-23.357c6.755-2.757,10.063,17.968,10.662,21.423c0.599,3.456,1.632,12.656,1.213,20.538
                        c0,0,0.21-12.666-1.52-20.719c-1.731-8.052-4.224-19.351-8.399-20.941C171.292,345.542,165.99,356.088,167.421,370.188z"></path>
            <linearGradient id="SVGID_00000036936047402045554560000006952181844382183578_" gradientUnits="userSpaceOnUse" x1="76.9516" y1="-462.7433" x2="79.025" y2="-462.7433" gradientTransform="matrix(0.9755 -0.22 -0.22 -0.9755 -7.8837 -52.8742)">
            <stop offset="0.0673" style="stop-color:#8F906F"></stop>
            <stop offset="0.209" style="stop-color:#AAAA92"></stop>
            <stop offset="0.3767" style="stop-color:#C5C5B4"></stop>
            <stop offset="0.5148" style="stop-color:#D6D5C9"></stop>
            <stop offset="0.6053" style="stop-color:#DCDBD1"></stop>
            <stop offset="0.6831" style="stop-color:#D1D1C4"></stop>
            <stop offset="0.8284" style="stop-color:#B6B6A0"></stop>
            <stop offset="1" style="stop-color:#8F906F"></stop>
            </linearGradient>
            <path style="fill:url(#SVGID_00000036936047402045554560000006952181844382183578_);" d="M168.57,377.375
                        c0,0,1.129,6.003,2.143,7.504c1.014,1.501,0.973-0.731,0.973-0.731S170.012,382.581,168.57,377.375z"></path>
            <g style="opacity:0.4;">
            <linearGradient id="SVGID_00000151539341366465660660000006317620209521555091_" gradientUnits="userSpaceOnUse" x1="77.568" y1="-460.0071" x2="80.259" y2="-460.0071" gradientTransform="matrix(0.9755 -0.22 -0.22 -0.9755 -7.8837 -52.8742)">
                  <stop offset="0.0673" style="stop-color:#8F906F"></stop>
                  <stop offset="0.209" style="stop-color:#AAAA92"></stop>
                  <stop offset="0.3767" style="stop-color:#C5C5B4"></stop>
                  <stop offset="0.5148" style="stop-color:#D6D5C9"></stop>
                  <stop offset="0.6053" style="stop-color:#DCDBD1"></stop>
                  <stop offset="0.6831" style="stop-color:#D1D1C4"></stop>
                  <stop offset="0.8284" style="stop-color:#B6B6A0"></stop>
                  <stop offset="1" style="stop-color:#8F906F"></stop>
            </linearGradient>
            <path style="fill:url(#SVGID_00000151539341366465660660000006317620209521555091_);" d="M169.165,376.707
                              c0,0,1.513,2.414,2.685,3.495l-0.102-1.387L169.165,376.707z"></path>
            </g>
            <g style="opacity:0.4;">
            <linearGradient id="SVGID_00000126321727770304592380000018304168445062024106_" gradientUnits="userSpaceOnUse" x1="78.3136" y1="-451.9132" x2="81.3283" y2="-451.9132" gradientTransform="matrix(0.9755 -0.22 -0.22 -0.9755 -7.8837 -52.8742)">
                  <stop offset="0.0673" style="stop-color:#8F906F"></stop>
                  <stop offset="0.209" style="stop-color:#AAAA92"></stop>
                  <stop offset="0.3767" style="stop-color:#C5C5B4"></stop>
                  <stop offset="0.5148" style="stop-color:#D6D5C9"></stop>
                  <stop offset="0.6053" style="stop-color:#DCDBD1"></stop>
                  <stop offset="0.6831" style="stop-color:#D1D1C4"></stop>
                  <stop offset="0.8284" style="stop-color:#B6B6A0"></stop>
                  <stop offset="1" style="stop-color:#8F906F"></stop>
            </linearGradient>
            <path style="fill:url(#SVGID_00000126321727770304592380000018304168445062024106_);" d="M169.252,373.356
                              c0,0,0.863-3.271,1.449-4.043c0,0-0.303-1.342-0.574-1.985C170.127,367.328,169.205,369.97,169.252,373.356z"></path>
            </g>
            </g>
            <linearGradient id="SVGID_00000017495688414124660440000002003669991738706865_" gradientUnits="userSpaceOnUse" x1="180.0064" y1="313.0105" x2="190.3398" y2="383.3434">
            <stop offset="0.0673" style="stop-color:#8F906F"></stop>
            <stop offset="0.209" style="stop-color:#AAAA92"></stop>
            <stop offset="0.3767" style="stop-color:#C5C5B4"></stop>
            <stop offset="0.5148" style="stop-color:#D6D5C9"></stop>
            <stop offset="0.6053" style="stop-color:#DCDBD1"></stop>
            <stop offset="0.6831" style="stop-color:#D1D1C4"></stop>
            <stop offset="0.8284" style="stop-color:#B6B6A0"></stop>
            <stop offset="1" style="stop-color:#8F906F"></stop>
            </linearGradient>
            <path style="fill:url(#SVGID_00000017495688414124660440000002003669991738706865_);" d="M182.167,319.334c0,0-3.833-5.5-5.5,1.166
                  c-1.667,6.667,1.667,3.333,3.667,10.167s3.166,14.833,4,23.5c0.833,8.667,1,21.167,1,25.167s4.333,7,4.333-0.334
                  c0-7.333-2.667-33.333-1.5-42.666C189.334,327,182.167,319.334,182.167,319.334z"></path>
            <linearGradient id="SVGID_00000154398105825662485200000009343481701214769340_" gradientUnits="userSpaceOnUse" x1="178.4324" y1="313.2431" x2="188.7659" y2="383.5769">
            <stop offset="0.0673" style="stop-color:#71798E"></stop>
            <stop offset="0.2061" style="stop-color:#9399A9"></stop>
            <stop offset="0.375" style="stop-color:#B5BAC4"></stop>
            <stop offset="0.5142" style="stop-color:#CACED5"></stop>
            <stop offset="0.6053" style="stop-color:#D2D5DB"></stop>
            <stop offset="0.6838" style="stop-color:#C5C8D0"></stop>
            <stop offset="0.8302" style="stop-color:#A1A7B4"></stop>
            <stop offset="1" style="stop-color:#71798E"></stop>
            </linearGradient>
            <path style="fill:url(#SVGID_00000154398105825662485200000009343481701214769340_);" d="M186.049,378.054
                  c-0.115-5.291-0.17-8.599-0.382-13.887c-0.167-4.167,0.8-2.642,0.833-2c0.119,2.346,0.413,4.685,0.861,6.994
                  c-0.297-3.308-0.83-18.345-1.027-21.661c-0.281,0.194-0.167,13.834-0.5,11.334c-0.906-6.79-1.455-13.619-2.652-20.361
                  c-0.523-2.939-1.065-6.079-2.412-8.775c-0.813-1.626-1.694-2.633-2.821-3.4c0.771,0.766,1.665,1.906,2.386,4.369
                  c2,6.833,3.166,14.833,4,23.5c0.833,8.667,1,21.167,1,25.167c0,1.786,0.863,3.369,1.819,3.926
                  C186.319,381.769,186.088,379.87,186.049,378.054z"></path>
            <linearGradient id="SVGID_00000149343852199668853590000006586907799517112704_" gradientUnits="userSpaceOnUse" x1="180.0072" y1="313.0114" x2="190.3406" y2="383.3441">
            <stop offset="0.2234" style="stop-color:#71798E"></stop>
            <stop offset="1" style="stop-color:#71798E"></stop>
            </linearGradient>
            <path style="opacity:0.6;fill:url(#SVGID_00000149343852199668853590000006586907799517112704_);" d="M188.167,336.334
                  c1.167-9.334-6-17-6-17s-3.833-5.5-5.5,1.166c-0.644,2.572-0.54,3.654-0.058,4.408c0.313-0.283,0.838-0.421,1.537-0.345
                  c2.307,0.251,4.037,1.692,4.684,3.787c4.004,12.984,4.088,30.226,5.504,49.317c0.333,4.5-0.441,4.769-1.734,5.136
                  c-0.002,0.001-0.004,0.001-0.006,0.001c1.279,1.353,3.073,0.916,3.073-3.804C189.667,371.667,187,345.667,188.167,336.334z"></path>
            <linearGradient id="SVGID_00000011008382039165154710000005918465938922832553_" gradientUnits="userSpaceOnUse" x1="189.7517" y1="355.4284" x2="206.4189" y2="352.0954">
            <stop offset="0.2234" style="stop-color:#2A2828"></stop>
            <stop offset="0.459" style="stop-color:#3E3D36"></stop>
            <stop offset="0.7609" style="stop-color:#525243"></stop>
            <stop offset="0.9591" style="stop-color:#595A48"></stop>
            </linearGradient>
            <path style="fill:url(#SVGID_00000011008382039165154710000005918465938922832553_);" d="M181.166,322.5
                  c0,0,2.501,1.001,3.668,6.334c1.166,5.333,10.999,50.167,11.832,53.5c0.834,3.333,3.334,6,9.168,4.5c5.832-1.5,5.5-5.667,5-8.834
                  c-0.5-3.166-10.5-48.333-10.834-51.333s1-3.833,2-4.333L181.166,322.5z"></path>
            <linearGradient id="SVGID_00000109712432322702532330000000841357991390185870_" gradientUnits="userSpaceOnUse" x1="189.7324" y1="355.3318" x2="206.3999" y2="351.9987">
            <stop offset="0.0673" style="stop-color:#71798E"></stop>
            <stop offset="0.2061" style="stop-color:#9399A9"></stop>
            <stop offset="0.375" style="stop-color:#B5BAC4"></stop>
            <stop offset="0.5142" style="stop-color:#CACED5"></stop>
            <stop offset="0.6053" style="stop-color:#D2D5DB"></stop>
            <stop offset="0.6838" style="stop-color:#C5C8D0"></stop>
            <stop offset="0.8302" style="stop-color:#A1A7B4"></stop>
            <stop offset="1" style="stop-color:#71798E"></stop>
            </linearGradient>
            <path style="opacity:0.2;fill:url(#SVGID_00000109712432322702532330000000841357991390185870_);" d="M181.334,322.5
                  c4.093,0.37,5.49,1.145,6.333,4.834c1.333,5.833,3.143,13.323,4.167,18.5c1.613,8.165,3.547,16.141,5.413,24.253
                  c1.293,5.621,2.654,11.228,3.614,16.91c0.59,0.159,1.229,0.266,1.964,0.262c-2.972-12.608-5.696-25.255-8.228-37.965
                  c-1.362-6.844-4.227-16.654-5.764-23.46c-0.648-2.868,0.768-2.384,3.258-3.421l-10.926,0.087c0,0,0.014,0.005,0.034,0.015
                  C181.242,322.507,181.283,322.496,181.334,322.5z"></path>
            <linearGradient id="SVGID_00000046311390967410270470000003709348087275514035_" gradientUnits="userSpaceOnUse" x1="189.8544" y1="355.9412" x2="206.5219" y2="352.6081">
            <stop offset="0.0673" style="stop-color:#71798E"></stop>
            <stop offset="0.2061" style="stop-color:#9399A9"></stop>
            <stop offset="0.375" style="stop-color:#B5BAC4"></stop>
            <stop offset="0.5142" style="stop-color:#CACED5"></stop>
            <stop offset="0.6053" style="stop-color:#D2D5DB"></stop>
            <stop offset="0.6838" style="stop-color:#C5C8D0"></stop>
            <stop offset="0.8302" style="stop-color:#A1A7B4"></stop>
            <stop offset="1" style="stop-color:#71798E"></stop>
            </linearGradient>
            <path style="opacity:0.3;fill:url(#SVGID_00000046311390967410270470000003709348087275514035_);" d="M205.887,372.296
                  c-1.68-7.278-3.394-14.548-5.07-21.827c-0.866-3.762-1.705-7.528-2.653-11.27c-0.767-3.028-2.3-9.233-2.496-12.366
                  c-0.256-4.062,2.696-2.501,5.515-4.494l-14.453,0.116c2.762,0.9,4.263,3.8,5.115,6.638c1.56,5.188,2.677,10.652,3.898,15.926
                  c3.227,13.934,6.406,27.966,9.077,42.038c0.327-0.061,0.663-0.134,1.015-0.224c1.308-0.336,2.291-0.812,3.047-1.376
                  C207.838,381.084,206.898,376.684,205.887,372.296z"></path>
            <linearGradient id="SVGID_00000094594924262392541210000014174647087547049908_" gradientUnits="userSpaceOnUse" x1="166.166" y1="286.8335" x2="175.5" y2="286.8335">
            <stop offset="0.2234" style="stop-color:#2A2828"></stop>
            <stop offset="0.459" style="stop-color:#3E3D36"></stop>
            <stop offset="0.7609" style="stop-color:#525243"></stop>
            <stop offset="0.9591" style="stop-color:#595A48"></stop>
            </linearGradient>
            <ellipse style="fill:url(#SVGID_00000094594924262392541210000014174647087547049908_);" cx="170.833" cy="286.834" rx="4.667" ry="5"></ellipse>
            <linearGradient id="SVGID_00000134938627856189074990000015916950845998186414_" gradientUnits="userSpaceOnUse" x1="166.1672" y1="289.2539" x2="175.4994" y2="289.2539">
            <stop offset="0.0673" style="stop-color:#71798E"></stop>
            <stop offset="0.2061" style="stop-color:#9399A9"></stop>
            <stop offset="0.375" style="stop-color:#B5BAC4"></stop>
            <stop offset="0.5142" style="stop-color:#CACED5"></stop>
            <stop offset="0.6053" style="stop-color:#D2D5DB"></stop>
            <stop offset="0.6838" style="stop-color:#C5C8D0"></stop>
            <stop offset="0.8302" style="stop-color:#A1A7B4"></stop>
            <stop offset="1" style="stop-color:#71798E"></stop>
            </linearGradient>
            <path style="opacity:0.3;fill:url(#SVGID_00000134938627856189074990000015916950845998186414_);" d="M173.701,290.201
                  c0.143,0.047,0.285,0.094,0.428,0.141c0.55-0.6,0.966-1.341,1.182-2.176c-1.325,0.265-2.639,0.537-3.915,0.97
                  C172.008,289.697,172.959,289.956,173.701,290.201z"></path>
            <linearGradient id="SVGID_00000068646321019562453780000007302188876577469370_" gradientUnits="userSpaceOnUse" x1="166.166" y1="284.1727" x2="175.4994" y2="284.1727">
            <stop offset="0.0673" style="stop-color:#8F906F"></stop>
            <stop offset="0.209" style="stop-color:#AAAA92"></stop>
            <stop offset="0.3767" style="stop-color:#C5C5B4"></stop>
            <stop offset="0.5148" style="stop-color:#D6D5C9"></stop>
            <stop offset="0.6053" style="stop-color:#DCDBD1"></stop>
            <stop offset="0.6831" style="stop-color:#D1D1C4"></stop>
            <stop offset="0.8284" style="stop-color:#B6B6A0"></stop>
            <stop offset="1" style="stop-color:#8F906F"></stop>
            </linearGradient>
            <path style="fill:url(#SVGID_00000068646321019562453780000007302188876577469370_);" d="M170.605,281.848
                  c-0.605,0.033-1.179,0.189-1.703,0.445c0.191,1.415,0.462,2.818,0.799,4.205C170.029,284.952,170.365,283.408,170.605,281.848z"></path>
            <linearGradient id="SVGID_00000116914840901037720480000001403385233602327737_" gradientUnits="userSpaceOnUse" x1="166.1671" y1="284.7427" x2="175.4995" y2="284.7427">
            <stop offset="0.0673" style="stop-color:#71798E"></stop>
            <stop offset="0.2061" style="stop-color:#9399A9"></stop>
            <stop offset="0.375" style="stop-color:#B5BAC4"></stop>
            <stop offset="0.5142" style="stop-color:#CACED5"></stop>
            <stop offset="0.6053" style="stop-color:#D2D5DB"></stop>
            <stop offset="0.6838" style="stop-color:#C5C8D0"></stop>
            <stop offset="0.8302" style="stop-color:#A1A7B4"></stop>
            <stop offset="1" style="stop-color:#71798E"></stop>
            </linearGradient>
            <path style="opacity:0.3;fill:url(#SVGID_00000116914840901037720480000001403385233602327737_);" d="M171.1,287.363
                  c1.359-0.855,2.556-1.942,3.771-2.999c-0.561-1.038-1.455-1.845-2.533-2.241c-0.269,1.729-0.605,3.463-1.326,5.044
                  C171.048,287.23,171.069,287.297,171.1,287.363z"></path>
            <linearGradient id="SVGID_00000006681857496424733450000000949471737870223009_" gradientUnits="userSpaceOnUse" x1="157.3699" y1="332.0405" x2="172.4903" y2="332.0405">
            <stop offset="0.0409" style="stop-color:#595A48"></stop>
            <stop offset="0.2391" style="stop-color:#525243"></stop>
            <stop offset="0.541" style="stop-color:#3E3D36"></stop>
            <stop offset="0.7766" style="stop-color:#2A2828"></stop>
            </linearGradient>
            <path style="fill:url(#SVGID_00000006681857496424733450000000949471737870223009_);" d="M168.5,287.167
                  c-2.156,4.672-8.166,19-9.5,24.5s-2.334,10.613-1,21.333c1.334,10.72,7.666,37.5,9,42.5s5.166,6.001,2.5-9.666
                  c-2.666-15.667-6.667-31-7.334-39c-0.666-8,2.001-17.167,5.168-25.667c3.166-8.5,4.166-10,5-12
                  C173.166,287.167,170.5,282.833,168.5,287.167z"></path>
            <linearGradient id="SVGID_00000154383662827184623600000004143086565367858816_" gradientUnits="userSpaceOnUse" x1="157.3472" y1="332.0194" x2="172.4905" y2="332.0194">
            <stop offset="0.0673" style="stop-color:#71798E"></stop>
            <stop offset="0.2061" style="stop-color:#9399A9"></stop>
            <stop offset="0.375" style="stop-color:#B5BAC4"></stop>
            <stop offset="0.5142" style="stop-color:#CACED5"></stop>
            <stop offset="0.6053" style="stop-color:#D2D5DB"></stop>
            <stop offset="0.6838" style="stop-color:#C5C8D0"></stop>
            <stop offset="0.8302" style="stop-color:#A1A7B4"></stop>
            <stop offset="1" style="stop-color:#71798E"></stop>
            </linearGradient>
            <path style="opacity:0.3;fill:url(#SVGID_00000154383662827184623600000004143086565367858816_);" d="M172.334,289.167
                  c0.779-1.872-1.506-5.785-3.441-2.727c0.494-0.182,0.99-0.165,1.461,0.207c1.659,1.312,0.213,4.529-0.39,6.013
                  c-3.421,8.428-7.069,16.774-8.648,25.807c-1.079,6.176-0.328,8.241,0.685,14.367c0.947,5.732,2.05,11.463,3.166,17.166
                  c1.135,5.792,1.984,9.551,3.168,15.334c0.473,2.311,1.72,11.109,1.026,13.396c1.056-0.391,1.674-3.88,0.14-12.896
                  c-2.666-15.667-6.667-31-7.334-39c-0.666-8,2.001-17.167,5.168-25.667C170.5,292.667,171.5,291.167,172.334,289.167z"></path>
            <linearGradient id="SVGID_00000054244339678583374670000013486078722766167936_" gradientUnits="userSpaceOnUse" x1="178.334" y1="320.5833" x2="203.8642" y2="320.5833">
            <stop offset="0.14" style="stop-color:#0095CE"></stop>
            <stop offset="0.3727" style="stop-color:#1190FF"></stop>
            <stop offset="0.5263" style="stop-color:#89C7FF"></stop>
            <stop offset="0.6716" style="stop-color:#1190FF"></stop>
            <stop offset="0.7821" style="stop-color:#0C91F2"></stop>
            <stop offset="0.9877" style="stop-color:#0095CF"></stop>
            <stop offset="0.9905" style="stop-color:#0095CE"></stop>
            </linearGradient>
            <path style="fill: #b7ce96;" d="M203.864,321.636
                  c0,0.845-0.686,1.531-1.53,1.531h-22.47c-0.845,0-1.53-0.686-1.53-1.531v-2.105c0-0.845,0.686-1.531,1.53-1.531h22.47
                  c0.845,0,1.53,0.686,1.53,1.531V321.636z"></path>
            <linearGradient id="SVGID_00000099648106704511700380000002994536732427314580_" gradientUnits="userSpaceOnUse" x1="158.0278" y1="332" x2="169" y2="332">
            <stop offset="0.0673" style="stop-color:#71798E"></stop>
            <stop offset="0.2061" style="stop-color:#9399A9"></stop>
            <stop offset="0.375" style="stop-color:#B5BAC4"></stop>
            <stop offset="0.5142" style="stop-color:#CACED5"></stop>
            <stop offset="0.6053" style="stop-color:#D2D5DB"></stop>
            <stop offset="0.6838" style="stop-color:#C5C8D0"></stop>
            <stop offset="0.8302" style="stop-color:#A1A7B4"></stop>
            <stop offset="1" style="stop-color:#71798E"></stop>
            </linearGradient>
            <path style="fill:url(#SVGID_00000099648106704511700380000002994536732427314580_);" d="M169,288.167
                  c0,0-9.166,19.667-9.833,31.167c-0.667,11.5,7.833,49.167,8.833,56.5c0,0-8.667-36.333-9.5-45
                  C157.667,322.167,156.5,311.333,169,288.167z"></path>
            <path style="fill: rgb(255, 255, 255);" d="M182.167,319.334c4.333-0.459,5.083-0.584,9.708-0.584s9.75,0.875,9.75,0.875s-4.75,1-10.125,0.875
                  S182.167,319.334,182.167,319.334z"></path>
      </g>
      <g>
            <g>
            <linearGradient id="SVGID_00000113325054129263748240000011037377357045476022_" gradientUnits="userSpaceOnUse" x1="208.0037" y1="90.7136" x2="224.5091" y2="153.3044" gradientTransform="matrix(0.9755 0.22 -0.22 0.9755 -7.8837 -113.5409)">
            <stop offset="0.0673" style="stop-color:#8F906F"></stop>
            <stop offset="0.209" style="stop-color:#AAAA92"></stop>
            <stop offset="0.3767" style="stop-color:#C5C5B4"></stop>
            <stop offset="0.5148" style="stop-color:#D6D5C9"></stop>
            <stop offset="0.6053" style="stop-color:#DCDBD1"></stop>
            <stop offset="0.6831" style="stop-color:#D1D1C4"></stop>
            <stop offset="0.8284" style="stop-color:#B6B6A0"></stop>
            <stop offset="1" style="stop-color:#8F906F"></stop>
            </linearGradient>
            <path style="fill:url(#SVGID_00000113325054129263748240000011037377357045476022_);" d="M167.055,56.192
                        c-0.348,3.943-1.004,15.664,1.706,22.339c2.71,6.677,7.642,8.984,11.132,1.229c3.492-7.756,8.976-32.317,3.961-50.705
                        C178.84,10.669,168.566,39.022,167.055,56.192z"></path>
            <linearGradient id="SVGID_00000092419853283763704030000002209524496260905344_" gradientUnits="userSpaceOnUse" x1="207.3341" y1="133.9708" x2="226.5667" y2="133.9708" gradientTransform="matrix(0.9755 0.22 -0.22 0.9755 -7.8837 -113.5409)">
            <stop offset="0.2234" style="stop-color:#71798E"></stop>
            <stop offset="0.3219" style="stop-color:#9399A9"></stop>
            <stop offset="0.4418" style="stop-color:#B5BAC4"></stop>
            <stop offset="0.5406" style="stop-color:#CACED5"></stop>
            <stop offset="0.6053" style="stop-color:#D2D5DB"></stop>
            <stop offset="0.6838" style="stop-color:#C5C8D0"></stop>
            <stop offset="0.8302" style="stop-color:#A1A7B4"></stop>
            <stop offset="1" style="stop-color:#71798E"></stop>
            </linearGradient>
            <path style="opacity:0.5;fill:url(#SVGID_00000092419853283763704030000002209524496260905344_);" d="M185.604,53.006
                        c-0.301,2.518-0.648,4.998-1.131,7.2c-0.902,4.105-2.998,13.702-6.903,14.057c-1.708,0.156-3.026-3.022-3.763-4.694
                        c-1.036-2.346-2.253-7.408-2.253-7.408s2.079-11.96,0.739-16.235c-1.476-4.71-3.565,3.296-4.051,5.75
                        c-0.098,0.489-0.588,0.538-0.652,0.144c-0.23,1.51-0.412,2.978-0.535,4.372c-0.348,3.943-1.004,15.664,1.706,22.339
                        c2.71,6.677,7.642,8.984,11.132,1.229C181.965,75.159,184.738,64.641,185.604,53.006z"></path>
            <linearGradient id="SVGID_00000134948501476207323380000006675260755959204245_" gradientUnits="userSpaceOnUse" x1="207.3341" y1="134.1766" x2="226.5652" y2="134.1766" gradientTransform="matrix(0.9755 0.22 -0.22 0.9755 -7.8837 -113.5409)">
            <stop offset="0.2234" style="stop-color:#71798E"></stop>
            <stop offset="0.3219" style="stop-color:#9399A9"></stop>
            <stop offset="0.4418" style="stop-color:#B5BAC4"></stop>
            <stop offset="0.5406" style="stop-color:#CACED5"></stop>
            <stop offset="0.6053" style="stop-color:#D2D5DB"></stop>
            <stop offset="0.6838" style="stop-color:#C5C8D0"></stop>
            <stop offset="0.8302" style="stop-color:#A1A7B4"></stop>
            <stop offset="1" style="stop-color:#71798E"></stop>
            </linearGradient>
            <path style="opacity:0.5;fill:url(#SVGID_00000134948501476207323380000006675260755959204245_);" d="M177.655,82.858
                        c-2.218,1.011-3.727-0.423-4.773-2.828c-1.748-4.017-2.562-8.766-3.327-13.534c-0.05-0.306,0.108-0.548,0.283-0.63
                        c1.723-3.517,2.344-7.653,2.602-11.57c0.141-2.136,0.299-4.398-0.023-6.394c-0.155-0.968-0.575-2.652-1.344-3.049
                        c-2.74,3.163-3.461,11.025-3.74,14.989c-0.255,3.643-0.306,7.262-0.285,10.862c0.291,2.851,0.814,5.616,1.712,7.827
                        c2.312,5.698,6.241,8.19,9.513,3.984C178.07,82.645,177.864,82.763,177.655,82.858z"></path>
            <linearGradient id="SVGID_00000091730876164154491820000010562931440342212498_" gradientUnits="userSpaceOnUse" x1="208.3921" y1="125.2849" x2="212.8405" y2="125.2849" gradientTransform="matrix(0.9755 0.22 -0.22 0.9755 -7.8837 -113.5409)">
            <stop offset="0.2234" style="stop-color:#2A2828"></stop>
            <stop offset="0.459" style="stop-color:#3E3D36"></stop>
            <stop offset="0.7609" style="stop-color:#525243"></stop>
            <stop offset="0.9591" style="stop-color:#595A48"></stop>
            </linearGradient>
            <path style="fill:url(#SVGID_00000091730876164154491820000010562931440342212498_);" d="M167.588,56.569
                        c-0.447,3.029,0.238,7.913,1.558,8.295c1.319,0.383,2.357-6.216,2.689-10.67C172.896,39.842,169.411,44.252,167.588,56.569z"></path>
            <linearGradient id="SVGID_00000141442204178025873230000008301677217432434102_" gradientUnits="userSpaceOnUse" x1="210.542" y1="92.1324" x2="216.7952" y2="115.8455" gradientTransform="matrix(0.9755 0.22 -0.22 0.9755 -7.8837 -113.5409)">
            <stop offset="0.0673" style="stop-color:#71798E"></stop>
            <stop offset="0.2061" style="stop-color:#9399A9"></stop>
            <stop offset="0.375" style="stop-color:#B5BAC4"></stop>
            <stop offset="0.5142" style="stop-color:#CACED5"></stop>
            <stop offset="0.6053" style="stop-color:#D2D5DB"></stop>
            <stop offset="0.6838" style="stop-color:#C5C8D0"></stop>
            <stop offset="0.8302" style="stop-color:#A1A7B4"></stop>
            <stop offset="1" style="stop-color:#71798E"></stop>
            </linearGradient>
            <path style="fill:url(#SVGID_00000141442204178025873230000008301677217432434102_);" d="M172.273,44.641
                        c0,0,1.379-8.763,3.869-15.658c0.898-2.488,1.904-5.08,2.145-5.026c0.241,0.054,2.934-2.542,4.9,4.437
                        c0,0-7.737,8.891-10.526,18.64L172.273,44.641z"></path>
            <linearGradient id="SVGID_00000106109397127659346450000008350378546083035310_" gradientUnits="userSpaceOnUse" x1="224.8212" y1="114.3701" x2="209.9411" y2="121.764" gradientTransform="matrix(0.9755 0.22 -0.22 0.9755 -7.8837 -113.5409)">
            <stop offset="0.0673" style="stop-color:#71798E"></stop>
            <stop offset="0.2061" style="stop-color:#9399A9"></stop>
            <stop offset="0.375" style="stop-color:#B5BAC4"></stop>
            <stop offset="0.5142" style="stop-color:#CACED5"></stop>
            <stop offset="0.6053" style="stop-color:#D2D5DB"></stop>
            <stop offset="0.6838" style="stop-color:#C5C8D0"></stop>
            <stop offset="0.8302" style="stop-color:#A1A7B4"></stop>
            <stop offset="1" style="stop-color:#71798E"></stop>
            </linearGradient>
            <path style="fill:url(#SVGID_00000106109397127659346450000008350378546083035310_);" d="M172.356,53.245
                        c0,0,6.466-6.614,13.167-7.922l-0.118,2.023C185.405,47.346,177.793,48.577,172.356,53.245z"></path>
            <linearGradient id="SVGID_00000173871164951815223420000012249536845886997430_" gradientUnits="userSpaceOnUse" x1="226.3786" y1="120.6203" x2="212.2017" y2="127.6648" gradientTransform="matrix(0.9755 0.22 -0.22 0.9755 -7.8837 -113.5409)">
            <stop offset="0.0673" style="stop-color:#71798E"></stop>
            <stop offset="0.2061" style="stop-color:#9399A9"></stop>
            <stop offset="0.375" style="stop-color:#B5BAC4"></stop>
            <stop offset="0.5142" style="stop-color:#CACED5"></stop>
            <stop offset="0.6053" style="stop-color:#D2D5DB"></stop>
            <stop offset="0.6838" style="stop-color:#C5C8D0"></stop>
            <stop offset="0.8302" style="stop-color:#A1A7B4"></stop>
            <stop offset="1" style="stop-color:#71798E"></stop>
            </linearGradient>
            <path style="opacity:0.5;fill:url(#SVGID_00000173871164951815223420000012249536845886997430_);" d="M172.44,54.297l12.841-4.16
                        c0,0,0.055,4.241-0.691,8.301c0,0-9.676-1.456-12.294-2.559L172.44,54.297z"></path>
            <linearGradient id="SVGID_00000011008107354431676640000013554759521070806950_" gradientUnits="userSpaceOnUse" x1="224.4845" y1="109.1778" x2="204.3571" y2="119.179" gradientTransform="matrix(0.9755 0.22 -0.22 0.9755 -7.8837 -113.5409)">
            <stop offset="0.0673" style="stop-color:#71798E"></stop>
            <stop offset="0.2061" style="stop-color:#9399A9"></stop>
            <stop offset="0.375" style="stop-color:#B5BAC4"></stop>
            <stop offset="0.5142" style="stop-color:#CACED5"></stop>
            <stop offset="0.6053" style="stop-color:#D2D5DB"></stop>
            <stop offset="0.6838" style="stop-color:#C5C8D0"></stop>
            <stop offset="0.8302" style="stop-color:#A1A7B4"></stop>
            <stop offset="1" style="stop-color:#71798E"></stop>
            </linearGradient>
            <path style="fill:url(#SVGID_00000011008107354431676640000013554759521070806950_);" d="M168.112,49.512
                        c0,0,4.393-21.477,10.175-25.555c5.781-4.078,6.982,16.012,7.124,19.376c0.141,3.364-0.057,12.459-1.55,20.58
                        c0,0,1.558-12.889,0.89-20.643c-0.668-7.754-1.299-18.66-5.331-19.227C175.39,23.474,169.145,43.936,168.112,49.512z"></path>
            <linearGradient id="SVGID_00000112629427907305827220000017928835138401233803_" gradientUnits="userSpaceOnUse" x1="170.9054" y1="-101.5931" x2="153.7063" y2="-93.0469" gradientTransform="matrix(0.9705 -0.2413 -0.2413 -0.9705 -6.1091 6.757)">
            <stop offset="0.0673" style="stop-color:#71798E"></stop>
            <stop offset="0.2061" style="stop-color:#9399A9"></stop>
            <stop offset="0.375" style="stop-color:#B5BAC4"></stop>
            <stop offset="0.5142" style="stop-color:#CACED5"></stop>
            <stop offset="0.6053" style="stop-color:#D2D5DB"></stop>
            <stop offset="0.6838" style="stop-color:#C5C8D0"></stop>
            <stop offset="0.8302" style="stop-color:#A1A7B4"></stop>
            <stop offset="1" style="stop-color:#71798E"></stop>
            </linearGradient>
            <path style="fill:url(#SVGID_00000112629427907305827220000017928835138401233803_);" d="M167.421,60.646
                        c0,0-1.628,20.207,6.092,23.357c6.755,2.757,10.063-17.968,10.662-21.423c0.599-3.456,1.632-12.656,1.213-20.538
                        c0,0,0.21,12.666-1.52,20.719c-1.731,8.052-4.224,19.351-8.399,20.941C171.292,85.292,165.99,74.746,167.421,60.646z"></path>
            <linearGradient id="SVGID_00000041261990989507722170000001376143031337583279_" gradientUnits="userSpaceOnUse" x1="208.3463" y1="119.8726" x2="210.4196" y2="119.8726" gradientTransform="matrix(0.9755 0.22 -0.22 0.9755 -7.8837 -113.5409)">
            <stop offset="0.0673" style="stop-color:#8F906F"></stop>
            <stop offset="0.209" style="stop-color:#AAAA92"></stop>
            <stop offset="0.3767" style="stop-color:#C5C5B4"></stop>
            <stop offset="0.5148" style="stop-color:#D6D5C9"></stop>
            <stop offset="0.6053" style="stop-color:#DCDBD1"></stop>
            <stop offset="0.6831" style="stop-color:#D1D1C4"></stop>
            <stop offset="0.8284" style="stop-color:#B6B6A0"></stop>
            <stop offset="1" style="stop-color:#8F906F"></stop>
            </linearGradient>
            <path style="fill:url(#SVGID_00000041261990989507722170000001376143031337583279_);" d="M168.57,53.459
                        c0,0,1.129-6.003,2.143-7.504c1.014-1.501,0.973,0.731,0.973,0.731S170.012,48.252,168.57,53.459z"></path>
            <g style="opacity:0.4;">
            <linearGradient id="SVGID_00000078018213080632502100000011677283643930690713_" gradientUnits="userSpaceOnUse" x1="208.9627" y1="122.6087" x2="211.6537" y2="122.6087" gradientTransform="matrix(0.9755 0.22 -0.22 0.9755 -7.8837 -113.5409)">
                  <stop offset="0.0673" style="stop-color:#8F906F"></stop>
                  <stop offset="0.209" style="stop-color:#AAAA92"></stop>
                  <stop offset="0.3767" style="stop-color:#C5C5B4"></stop>
                  <stop offset="0.5148" style="stop-color:#D6D5C9"></stop>
                  <stop offset="0.6053" style="stop-color:#DCDBD1"></stop>
                  <stop offset="0.6831" style="stop-color:#D1D1C4"></stop>
                  <stop offset="0.8284" style="stop-color:#B6B6A0"></stop>
                  <stop offset="1" style="stop-color:#8F906F"></stop>
            </linearGradient>
            <path style="fill:url(#SVGID_00000078018213080632502100000011677283643930690713_);" d="M169.165,54.127
                              c0,0,1.513-2.414,2.685-3.495l-0.102,1.387L169.165,54.127z"></path>
            </g>
            <g style="opacity:0.4;">
            <linearGradient id="SVGID_00000098203087549472492450000001278778110618972305_" gradientUnits="userSpaceOnUse" x1="209.7083" y1="130.7027" x2="212.7229" y2="130.7027" gradientTransform="matrix(0.9755 0.22 -0.22 0.9755 -7.8837 -113.5409)">
                  <stop offset="0.0673" style="stop-color:#8F906F"></stop>
                  <stop offset="0.209" style="stop-color:#AAAA92"></stop>
                  <stop offset="0.3767" style="stop-color:#C5C5B4"></stop>
                  <stop offset="0.5148" style="stop-color:#D6D5C9"></stop>
                  <stop offset="0.6053" style="stop-color:#DCDBD1"></stop>
                  <stop offset="0.6831" style="stop-color:#D1D1C4"></stop>
                  <stop offset="0.8284" style="stop-color:#B6B6A0"></stop>
                  <stop offset="1" style="stop-color:#8F906F"></stop>
            </linearGradient>
            <path style="fill:url(#SVGID_00000098203087549472492450000001278778110618972305_);" d="M169.252,57.478
                              c0,0,0.863,3.27,1.449,4.043c0,0-0.303,1.342-0.574,1.985C170.127,63.506,169.205,60.863,169.252,57.478z"></path>
            </g>
            </g>
            <linearGradient id="SVGID_00000181080105361965870830000011073111456988640663_" gradientUnits="userSpaceOnUse" x1="180.0064" y1="-284.2371" x2="190.3398" y2="-213.9042" gradientTransform="matrix(1 0 0 -1 0 -166.4141)">
            <stop offset="0.0673" style="stop-color:#8F906F"></stop>
            <stop offset="0.209" style="stop-color:#AAAA92"></stop>
            <stop offset="0.3767" style="stop-color:#C5C5B4"></stop>
            <stop offset="0.5148" style="stop-color:#D6D5C9"></stop>
            <stop offset="0.6053" style="stop-color:#DCDBD1"></stop>
            <stop offset="0.6831" style="stop-color:#D1D1C4"></stop>
            <stop offset="0.8284" style="stop-color:#B6B6A0"></stop>
            <stop offset="1" style="stop-color:#8F906F"></stop>
            </linearGradient>
            <path style="fill:url(#SVGID_00000181080105361965870830000011073111456988640663_);" d="M182.167,111.5c0,0-3.833,5.5-5.5-1.166
                  c-1.667-6.667,1.667-3.333,3.667-10.167c2-6.833,3.166-14.833,4-23.5c0.833-8.667,1-21.167,1-25.167s4.333-7,4.333,0.334
                  c0,7.333-2.667,33.333-1.5,42.666C189.334,103.834,182.167,111.5,182.167,111.5z"></path>
            <linearGradient id="SVGID_00000183237339917411817320000017515492905526103684_" gradientUnits="userSpaceOnUse" x1="178.4324" y1="-284.0045" x2="188.7659" y2="-213.6707" gradientTransform="matrix(1 0 0 -1 0 -166.4141)">
            <stop offset="0.0673" style="stop-color:#71798E"></stop>
            <stop offset="0.2061" style="stop-color:#9399A9"></stop>
            <stop offset="0.375" style="stop-color:#B5BAC4"></stop>
            <stop offset="0.5142" style="stop-color:#CACED5"></stop>
            <stop offset="0.6053" style="stop-color:#D2D5DB"></stop>
            <stop offset="0.6838" style="stop-color:#C5C8D0"></stop>
            <stop offset="0.8302" style="stop-color:#A1A7B4"></stop>
            <stop offset="1" style="stop-color:#71798E"></stop>
            </linearGradient>
            <path style="fill:url(#SVGID_00000183237339917411817320000017515492905526103684_);" d="M186.049,52.779
                  c-0.115,5.291-0.17,8.599-0.382,13.887c-0.167,4.167,0.8,2.642,0.833,2c0.119-2.346,0.413-4.685,0.861-6.994
                  c-0.297,3.308-0.83,18.345-1.027,21.661c-0.281-0.194-0.167-13.834-0.5-11.334c-0.906,6.79-1.455,13.619-2.652,20.361
                  c-0.523,2.938-1.065,6.079-2.412,8.775c-0.813,1.626-1.694,2.633-2.821,3.4c0.771-0.766,1.665-1.906,2.386-4.369
                  c2-6.833,3.166-14.833,4-23.5c0.833-8.667,1-21.167,1-25.167c0-1.786,0.863-3.369,1.819-3.926
                  C186.319,49.065,186.088,50.963,186.049,52.779z"></path>
            <linearGradient id="SVGID_00000026860090424013715830000017683359293569936778_" gradientUnits="userSpaceOnUse" x1="180.0072" y1="-284.2362" x2="190.3407" y2="-213.9035" gradientTransform="matrix(1 0 0 -1 0 -166.4141)">
            <stop offset="0.2234" style="stop-color:#71798E"></stop>
            <stop offset="1" style="stop-color:#71798E"></stop>
            </linearGradient>
            <path style="opacity:0.6;fill:url(#SVGID_00000026860090424013715830000017683359293569936778_);" d="M188.167,94.5
                  c1.167,9.334-6,17-6,17s-3.833,5.5-5.5-1.166c-0.644-2.572-0.54-3.654-0.058-4.408c0.313,0.283,0.838,0.421,1.537,0.345
                  c2.307-0.251,4.037-1.692,4.684-3.787c4.004-12.984,4.088-30.226,5.504-49.317c0.333-4.5-0.441-4.769-1.734-5.136
                  c-0.002,0-0.004,0-0.006-0.001c1.279-1.353,3.073-0.916,3.073,3.804C189.667,59.167,187,85.167,188.167,94.5z"></path>
            <linearGradient id="SVGID_00000060712292837061340470000000312476047283830152_" gradientUnits="userSpaceOnUse" x1="189.7517" y1="-241.8192" x2="206.4189" y2="-245.1522" gradientTransform="matrix(1 0 0 -1 0 -166.4141)">
            <stop offset="0.2234" style="stop-color:#2A2828"></stop>
            <stop offset="0.459" style="stop-color:#3E3D36"></stop>
            <stop offset="0.7609" style="stop-color:#525243"></stop>
            <stop offset="0.9591" style="stop-color:#595A48"></stop>
            </linearGradient>
            <path style="fill:url(#SVGID_00000060712292837061340470000000312476047283830152_);" d="M181.166,108.334
                  c0,0,2.501-1.001,3.668-6.334c1.166-5.333,10.999-50.167,11.832-53.5c0.834-3.333,3.334-6,9.168-4.5c5.832,1.5,5.5,5.667,5,8.834
                  c-0.5,3.166-10.5,48.333-10.834,51.333s1,3.833,2,4.333L181.166,108.334z"></path>
            <linearGradient id="SVGID_00000113322130751689952640000017680610238510643877_" gradientUnits="userSpaceOnUse" x1="189.7324" y1="-241.9159" x2="206.3999" y2="-245.249" gradientTransform="matrix(1 0 0 -1 0 -166.4141)">
            <stop offset="0.0673" style="stop-color:#71798E"></stop>
            <stop offset="0.2061" style="stop-color:#9399A9"></stop>
            <stop offset="0.375" style="stop-color:#B5BAC4"></stop>
            <stop offset="0.5142" style="stop-color:#CACED5"></stop>
            <stop offset="0.6053" style="stop-color:#D2D5DB"></stop>
            <stop offset="0.6838" style="stop-color:#C5C8D0"></stop>
            <stop offset="0.8302" style="stop-color:#A1A7B4"></stop>
            <stop offset="1" style="stop-color:#71798E"></stop>
            </linearGradient>
            <path style="opacity:0.2;fill:url(#SVGID_00000113322130751689952640000017680610238510643877_);" d="M181.334,108.334
                  c4.093-0.37,5.49-1.145,6.333-4.834c1.333-5.833,3.143-13.323,4.167-18.5c1.613-8.165,3.547-16.141,5.413-24.253
                  c1.293-5.621,2.654-11.228,3.614-16.91c0.59-0.159,1.229-0.266,1.964-0.262c-2.972,12.608-5.696,25.255-8.228,37.965
                  c-1.362,6.844-4.227,16.654-5.764,23.46c-0.648,2.868,0.768,2.384,3.258,3.421l-10.926-0.087c0,0,0.014-0.005,0.034-0.015
                  C181.242,108.326,181.283,108.338,181.334,108.334z"></path>
            <linearGradient id="SVGID_00000044866093473040257480000018344905552726671501_" gradientUnits="userSpaceOnUse" x1="189.8544" y1="-241.3065" x2="206.5219" y2="-244.6396" gradientTransform="matrix(1 0 0 -1 0 -166.4141)">
            <stop offset="0.0673" style="stop-color:#71798E"></stop>
            <stop offset="0.2061" style="stop-color:#9399A9"></stop>
            <stop offset="0.375" style="stop-color:#B5BAC4"></stop>
            <stop offset="0.5142" style="stop-color:#CACED5"></stop>
            <stop offset="0.6053" style="stop-color:#D2D5DB"></stop>
            <stop offset="0.6838" style="stop-color:#C5C8D0"></stop>
            <stop offset="0.8302" style="stop-color:#A1A7B4"></stop>
            <stop offset="1" style="stop-color:#71798E"></stop>
            </linearGradient>
            <path style="opacity:0.3;fill:url(#SVGID_00000044866093473040257480000018344905552726671501_);" d="M205.887,58.537
                  c-1.68,7.278-3.394,14.548-5.07,21.827c-0.866,3.762-1.705,7.528-2.653,11.27c-0.767,3.028-2.3,9.233-2.496,12.366
                  c-0.256,4.062,2.696,2.501,5.515,4.494l-14.453-0.116c2.762-0.9,4.263-3.8,5.115-6.638c1.56-5.188,2.677-10.652,3.898-15.926
                  c3.227-13.934,6.406-27.966,9.077-42.038c0.327,0.06,0.663,0.134,1.015,0.224c1.308,0.336,2.291,0.812,3.047,1.376
                  C207.838,49.75,206.898,54.15,205.887,58.537z"></path>
            <linearGradient id="SVGID_00000167370325876358785180000001724948223760309893_" gradientUnits="userSpaceOnUse" x1="166.166" y1="-310.4141" x2="175.5" y2="-310.4141" gradientTransform="matrix(1 0 0 -1 0 -166.4141)">
            <stop offset="0.2234" style="stop-color:#2A2828"></stop>
            <stop offset="0.459" style="stop-color:#3E3D36"></stop>
            <stop offset="0.7609" style="stop-color:#525243"></stop>
            <stop offset="0.9591" style="stop-color:#595A48"></stop>
            </linearGradient>
            <ellipse style="fill: rgb(42, 40, 40);" cx="170.833" cy="144" rx="4.667" ry="5"></ellipse>
            <linearGradient id="SVGID_00000158749388814341782570000006559323520132415403_" gradientUnits="userSpaceOnUse" x1="166.1672" y1="-307.9937" x2="175.4994" y2="-307.9937" gradientTransform="matrix(1 0 0 -1 0 -166.4141)">
            <stop offset="0.0673" style="stop-color:#71798E"></stop>
            <stop offset="0.2061" style="stop-color:#9399A9"></stop>
            <stop offset="0.375" style="stop-color:#B5BAC4"></stop>
            <stop offset="0.5142" style="stop-color:#CACED5"></stop>
            <stop offset="0.6053" style="stop-color:#D2D5DB"></stop>
            <stop offset="0.6838" style="stop-color:#C5C8D0"></stop>
            <stop offset="0.8302" style="stop-color:#A1A7B4"></stop>
            <stop offset="1" style="stop-color:#71798E"></stop>
            </linearGradient>
            <path style="opacity:0.3;fill:url(#SVGID_00000158749388814341782570000006559323520132415403_);" d="M173.701,140.632
                  c0.143-0.047,0.285-0.094,0.428-0.141c0.55,0.6,0.966,1.341,1.182,2.176c-1.325-0.265-2.639-0.537-3.915-0.97
                  C172.008,141.136,172.959,140.878,173.701,140.632z"></path>
            <linearGradient id="SVGID_00000160910588764817083260000007939408298678620841_" gradientUnits="userSpaceOnUse" x1="166.166" y1="-313.075" x2="175.4994" y2="-313.075" gradientTransform="matrix(1 0 0 -1 0 -166.4141)">
            <stop offset="0.0673" style="stop-color:#8F906F"></stop>
            <stop offset="0.209" style="stop-color:#AAAA92"></stop>
            <stop offset="0.3767" style="stop-color:#C5C5B4"></stop>
            <stop offset="0.5148" style="stop-color:#D6D5C9"></stop>
            <stop offset="0.6053" style="stop-color:#DCDBD1"></stop>
            <stop offset="0.6831" style="stop-color:#D1D1C4"></stop>
            <stop offset="0.8284" style="stop-color:#B6B6A0"></stop>
            <stop offset="1" style="stop-color:#8F906F"></stop>
            </linearGradient>
            <path style="fill:url(#SVGID_00000160910588764817083260000007939408298678620841_);" d="M170.605,148.986
                  c-0.605-0.033-1.179-0.189-1.703-0.445c0.191-1.415,0.462-2.818,0.799-4.205C170.029,145.881,170.365,147.425,170.605,148.986z"></path>
            <linearGradient id="SVGID_00000121241022621632483860000015252959982477885859_" gradientUnits="userSpaceOnUse" x1="166.1671" y1="-312.5049" x2="175.4995" y2="-312.5049" gradientTransform="matrix(1 0 0 -1 0 -166.4141)">
            <stop offset="0.0673" style="stop-color:#71798E"></stop>
            <stop offset="0.2061" style="stop-color:#9399A9"></stop>
            <stop offset="0.375" style="stop-color:#B5BAC4"></stop>
            <stop offset="0.5142" style="stop-color:#CACED5"></stop>
            <stop offset="0.6053" style="stop-color:#D2D5DB"></stop>
            <stop offset="0.6838" style="stop-color:#C5C8D0"></stop>
            <stop offset="0.8302" style="stop-color:#A1A7B4"></stop>
            <stop offset="1" style="stop-color:#71798E"></stop>
            </linearGradient>
            <path style="opacity:0.3;fill:url(#SVGID_00000121241022621632483860000015252959982477885859_);" d="M171.1,143.471
                  c1.359,0.855,2.556,1.942,3.771,2.999c-0.561,1.038-1.455,1.845-2.533,2.241c-0.269-1.729-0.605-3.463-1.326-5.044
                  C171.048,143.604,171.069,143.536,171.1,143.471z"></path>
            <linearGradient id="SVGID_00000065792908528196909080000000564537630746510986_" gradientUnits="userSpaceOnUse" x1="157.3699" y1="-265.2071" x2="172.4903" y2="-265.2071" gradientTransform="matrix(1 0 0 -1 0 -166.4141)">
            <stop offset="0.0409" style="stop-color:#595A48"></stop>
            <stop offset="0.2391" style="stop-color:#525243"></stop>
            <stop offset="0.541" style="stop-color:#3E3D36"></stop>
            <stop offset="0.7766" style="stop-color:#2A2828"></stop>
            </linearGradient>
            <path style="fill:url(#SVGID_00000065792908528196909080000000564537630746510986_);" d="M168.5,143.667
                  c-2.156-4.672-8.166-19-9.5-24.5s-2.334-10.613-1-21.333s7.666-37.5,9-42.5s5.166-6.001,2.5,9.666s-6.667,31-7.334,39
                  c-0.666,8,2.001,17.167,5.168,25.667c3.166,8.5,4.166,10,5,12C173.166,143.667,170.5,148.001,168.5,143.667z"></path>
            <linearGradient id="SVGID_00000018230928737364639970000014793570712586237585_" gradientUnits="userSpaceOnUse" x1="157.3472" y1="-265.2282" x2="172.4905" y2="-265.2282" gradientTransform="matrix(1 0 0 -1 0 -166.4141)">
            <stop offset="0.0673" style="stop-color:#71798E"></stop>
            <stop offset="0.2061" style="stop-color:#9399A9"></stop>
            <stop offset="0.375" style="stop-color:#B5BAC4"></stop>
            <stop offset="0.5142" style="stop-color:#CACED5"></stop>
            <stop offset="0.6053" style="stop-color:#D2D5DB"></stop>
            <stop offset="0.6838" style="stop-color:#C5C8D0"></stop>
            <stop offset="0.8302" style="stop-color:#A1A7B4"></stop>
            <stop offset="1" style="stop-color:#71798E"></stop>
            </linearGradient>
            <path style="opacity:0.3;fill:url(#SVGID_00000018230928737364639970000014793570712586237585_);" d="M172.334,141.667
                  c0.779,1.872-1.506,5.785-3.441,2.727c0.494,0.182,0.99,0.165,1.461-0.207c1.659-1.312,0.213-4.529-0.39-6.013
                  c-3.421-8.428-7.069-16.774-8.648-25.807c-1.079-6.176-0.328-8.241,0.685-14.367c0.947-5.732,2.05-11.463,3.166-17.166
                  c1.135-5.792,1.984-9.551,3.168-15.334c0.473-2.311,1.72-11.109,1.026-13.396c1.056,0.391,1.674,3.88,0.14,12.896
                  c-2.666,15.667-6.667,31-7.334,39c-0.666,8,2.001,17.167,5.168,25.667C170.5,138.167,171.5,139.667,172.334,141.667z"></path>
            <linearGradient id="SVGID_00000159454405563237399630000000904946593700919684_" gradientUnits="userSpaceOnUse" x1="178.334" y1="-276.6644" x2="203.8642" y2="-276.6644" gradientTransform="matrix(1 0 0 -1 0 -166.4141)">
            <stop offset="0.14" style="stop-color:#0095CE"></stop>
            <stop offset="0.3727" style="stop-color:#1190FF"></stop>
            <stop offset="0.5263" style="stop-color:#89C7FF"></stop>
            <stop offset="0.6716" style="stop-color:#1190FF"></stop>
            <stop offset="0.7821" style="stop-color:#0C91F2"></stop>
            <stop offset="0.9877" style="stop-color:#0095CF"></stop>
            <stop offset="0.9905" style="stop-color:#0095CE"></stop>
            </linearGradient>
            <path style="fill: #b7ce96;" d="M203.864,109.198
                  c0-0.845-0.686-1.531-1.53-1.531h-22.47c-0.845,0-1.53,0.686-1.53,1.531v2.105c0,0.845,0.686,1.531,1.53,1.531h22.47
                  c0.845,0,1.53-0.686,1.53-1.531V109.198z"></path>
            <linearGradient id="SVGID_00000127023031340468840020000001940117956905227906_" gradientUnits="userSpaceOnUse" x1="158.0278" y1="-265.2476" x2="169" y2="-265.2476" gradientTransform="matrix(1 0 0 -1 0 -166.4141)">
            <stop offset="0.0673" style="stop-color:#71798E"></stop>
            <stop offset="0.2061" style="stop-color:#9399A9"></stop>
            <stop offset="0.375" style="stop-color:#B5BAC4"></stop>
            <stop offset="0.5142" style="stop-color:#CACED5"></stop>
            <stop offset="0.6053" style="stop-color:#D2D5DB"></stop>
            <stop offset="0.6838" style="stop-color:#C5C8D0"></stop>
            <stop offset="0.8302" style="stop-color:#A1A7B4"></stop>
            <stop offset="1" style="stop-color:#71798E"></stop>
            </linearGradient>
            <path style="fill:url(#SVGID_00000127023031340468840020000001940117956905227906_);" d="M169,142.667
                  c0,0-9.166-19.667-9.833-31.167C158.5,100,167,62.333,168,55c0,0-8.667,36.333-9.5,45S156.5,119.501,169,142.667z"></path>
            <path style="fill:#FFFFFF;" d="M182.167,111.5c4.333,0.459,5.083,0.584,9.708,0.584s9.75-0.875,9.75-0.875s-4.75-1-10.125-0.875
                  S182.167,111.5,182.167,111.5z"></path>
      </g>
      <g>
            <linearGradient id="SVGID_00000052811902175409431210000012493089412560763830_" gradientUnits="userSpaceOnUse" x1="369.2958" y1="292.5067" x2="618.3476" y2="292.5067">
            <stop offset="0.14" style="stop-color:#0095CE"></stop>
            <stop offset="0.3727" style="stop-color:#1190FF"></stop>
            <stop offset="0.5263" style="stop-color:#89C7FF"></stop>
            <stop offset="0.6716" style="stop-color:#1190FF"></stop>
            <stop offset="0.7821" style="stop-color:#0C91F2"></stop>
            <stop offset="0.9877" style="stop-color:#0095CF"></stop>
            <stop offset="0.9905" style="stop-color:#0095CE"></stop>
            </linearGradient>
            <path style="fill: #9cba73;" d="M370.667,252
                  C369,262.334,367,294.001,377,305.667c10,11.667,41.668,35,104.334,35c62.666,0,117.629-29.298,131.333-43
                  c3.167-3.167,5.667-12.333,5.667-22.333S619,254.667,611,248.667s-46.333-4-46.333-4L370.667,252z"></path>
            <g style="opacity:0.5;">
            <radialGradient id="SVGID_00000072241059852734636970000006875766513214793130_" cx="493.8217" cy="292.5067" r="94.409" gradientUnits="userSpaceOnUse">
            <stop offset="0" style="stop-color:#1190FF"></stop>
            <stop offset="0.1201" style="stop-color:#0D91F3"></stop>
            <stop offset="0.3766" style="stop-color:#0693DE"></stop>
            <stop offset="0.6313" style="stop-color:#0195D2"></stop>
            <stop offset="0.8805" style="stop-color:#0095CE"></stop>
            </radialGradient>
            <path style="fill: #9cba73;" d="M611,248.667c-8-6-46.333-4-46.333-4
                        l-194,7.333C369,262.334,367,294.001,377,305.667c10,11.667,41.668,35,104.334,35c62.666,0,117.629-29.298,131.333-43
                        c3.167-3.167,5.667-12.333,5.667-22.333S619,254.667,611,248.667z M600.881,288.186c-5.547,26.148-73.693,32.903-110.881,34.148
                        c13.952,1.824,36.029,0.242,50-1c15-1.334,48.709-9.45,21.177,1.304c-11.826,4.619-24.387,6.378-36.727,9.012
                        c-21.046,4.491-43.19,7.336-64.682,4.79c-3.822-0.453-38.316-5.848-49.101-11.105c-15.685-7.648-20.946-14.899-19-17.667
                        c1.766-2.511,38.333,12.667,69.667,15c6.954,0.518,13,1.333,22.666,0c-16.333-0.667-25.666-1.667-39.666-4
                        c-13.284-2.214-25.847-5.621-38.667-10.333c-8.653-3.181-24.646-8.565-25.333-18.334c-1.214-17.265-3.001-17.666,17.666-11.333
                        c28.987,8.883,120.132-14.973,90.667-9.333c-4.761,0.911,6.813-2.709,11.897-0.683c15.103,6.017,51.155-6.425,78.103-5.983
                        c4.885,0.08,14.322,2.284,18.166,5.864c3.063,2.853,4.303,6.96,4.474,11.058C601.434,282.628,601.441,285.542,600.881,288.186z"></path>
            </g>
            <g style="opacity:0.5;">
            <radialGradient id="SVGID_00000173163362094256068810000005378992686500721572_" cx="493.8218" cy="292.5068" r="94.4092" gradientUnits="userSpaceOnUse">
            <stop offset="0" style="stop-color:#1190FF"></stop>
            <stop offset="0.1201" style="stop-color:#0D91F3"></stop>
            <stop offset="0.3766" style="stop-color:#0693DE"></stop>
            <stop offset="0.6313" style="stop-color:#0195D2"></stop>
            <stop offset="0.8805" style="stop-color:#0095CE"></stop>
            </radialGradient>
            <path style="fill: #9cba73;" d="M382.026,302.717
                        c5.293,0.918,10.488,2.251,15.658,3.693c-0.962-0.382-1.919-0.78-2.862-1.212c-5.204-2.384-10.759-5.104-15.364-8.535
                        c-3.982-2.966-5.867-6.118-6.566-11.04c-0.633-4.459-0.196-8.99-0.725-13.456c-0.014-0.118,0.017-0.206,0.063-0.28
                        c0.076-4.015,10.126-0.999,12.088-0.406c3.647,1.1,7.199,2.463,10.886,3.445c6.981,1.86,14.182,3.006,21.425,2.83
                        c7.668-0.186,15.343-1.508,22.903-2.728c7.448-1.202,14.834-2.693,22.183-4.4c7.173-1.667,14.163-3.557,21.154-5.863
                        c6.947-2.292,14.129-3.617,21.165-5.579c0.614-0.172,0.875,0.764,0.285,0.954c3.162,1.481,4.631,5.777,8.404,6.539
                        c5.81,1.174,12.409-0.481,18.234-1.2c12.82-1.582,25.486-4.269,38.338-5.607c8.066-0.839,16.145-1.626,24.215-2.433
                        c4.388-0.439,11.111-2.07,14.829,1.368c3.187,2.947,3.482,7.831,3.941,11.874c0.76,6.681,1.441,13.669-0.132,20.287
                        c-1.126,4.734-4.807,8.073-8.911,10.398c-4.976,2.819-10.529,4.937-15.9,6.858c-7.363,2.633-14.904,4.607-22.6,5.989
                        c-5.223,0.938-10.469,1.748-15.678,2.751c2.476-0.064,4.928-0.523,7.393-0.892c2.897-0.433,5.82-0.576,8.731-0.894
                        c7.093-0.774,13.976-2.304,20.813-4.327c5.573-1.649,11.081-3.507,16.61-5.293c4.381-2.921,7.789-5.62,10.059-7.889
                        c3.167-3.167,5.667-12.333,5.667-22.333S619,254.667,611,248.667s-46.333-4-46.333-4l-194,7.333
                        c-1.665,10.319-3.66,41.907,6.292,53.614c0.008-0.3,0.027-0.599,0.079-0.897C377.455,302.327,380.103,302.384,382.026,302.717z"></path>
            </g>
            <defs>
            <filter id="Adobe_OpacityMaskFilter_00000103983705638797649640000011054240414253114515_" filterUnits="userSpaceOnUse" x="427.334" y="320.334" width="127.666" height="14.786">
            <feFlood style="flood-color:white;flood-opacity:1" result="back"></feFlood>
            <feBlend in="SourceGraphic" in2="back" mode="normal"></feBlend>
            </filter>
            </defs>
            <mask maskUnits="userSpaceOnUse" x="427.334" y="320.334" width="127.666" height="14.786" id="SVGID_00000135663896705334968970000009118468199904965539_">
            <g style="filter:url(#Adobe_OpacityMaskFilter_00000103983705638797649640000011054240414253114515_);">
            <defs>
                  <filter id="Adobe_OpacityMaskFilter_00000120516821035688792620000015329485529340225196_" filterUnits="userSpaceOnUse" x="427.334" y="320.334" width="127.666" height="14.786">
                  <feFlood style="flood-color:white;flood-opacity:1" result="back"></feFlood>
                  <feBlend in="SourceGraphic" in2="back" mode="normal"></feBlend>
                  </filter>
            </defs>
            <mask maskUnits="userSpaceOnUse" x="427.334" y="320.334" width="127.666" height="14.786" id="SVGID_00000135663896705334968970000009118468199904965539_">
                  <g style="filter:url(#Adobe_OpacityMaskFilter_00000120516821035688792620000015329485529340225196_);"></g>
            </mask>
            <radialGradient id="SVGID_00000039135640622214062280000005896239650628997518_" cx="490.6671" cy="301.3338" r="72.1972" gradientUnits="userSpaceOnUse">
                  <stop offset="0.4276" style="stop-color:#FFFFFF"></stop>
                  <stop offset="1" style="stop-color:#000000"></stop>
            </radialGradient>
            <path style="mask:url(#SVGID_00000135663896705334968970000009118468199904965539_);fill:url(#SVGID_00000039135640622214062280000005896239650628997518_);" d="
                              M427.334,320.334c0,0,62.999,12.333,127.666,0c0,0-36.332,16.333-81.666,14.666C428,333.334,427.334,320.334,427.334,320.334z"></path>
            </g>
            </mask>
            <radialGradient id="SVGID_00000124851591735907715640000009135626781441766790_" cx="490.6671" cy="301.3338" r="72.1972" gradientUnits="userSpaceOnUse">
            <stop offset="0.4276" style="stop-color:#FFFFFF"></stop>
            <stop offset="1" style="stop-color:#2CB0FF"></stop>
            </radialGradient>
            <path style="opacity: 0.5; fill: #b7ce96;" d="M427.334,320.334
                  c0,0,62.999,12.333,127.666,0c0,0-36.332,16.333-81.666,14.666C428,333.334,427.334,320.334,427.334,320.334z"></path>
            <defs>
            <filter id="Adobe_OpacityMaskFilter_00000155837209025978021020000002271054224162215075_" filterUnits="userSpaceOnUse" x="413.999" y="266.121" width="158.084" height="54.707">
            <feFlood style="flood-color:white;flood-opacity:1" result="back"></feFlood>
            <feBlend in="SourceGraphic" in2="back" mode="normal"></feBlend>
            </filter>
            </defs>
            <mask maskUnits="userSpaceOnUse" x="413.999" y="266.121" width="158.084" height="54.707" id="SVGID_00000038406862567759002040000017315203014636608427_">
            <g style="filter:url(#Adobe_OpacityMaskFilter_00000155837209025978021020000002271054224162215075_);">
            <defs>
                  <filter id="Adobe_OpacityMaskFilter_00000107584320547450808000000003957434656734753427_" filterUnits="userSpaceOnUse" x="413.999" y="266.121" width="158.084" height="54.707">
                  <feFlood style="flood-color:white;flood-opacity:1" result="back"></feFlood>
                  <feBlend in="SourceGraphic" in2="back" mode="normal"></feBlend>
                  </filter>
            </defs>
            <mask maskUnits="userSpaceOnUse" x="413.999" y="266.121" width="158.084" height="54.707" id="SVGID_00000038406862567759002040000017315203014636608427_">
                  <g style="filter:url(#Adobe_OpacityMaskFilter_00000107584320547450808000000003957434656734753427_);"></g>
            </mask>
            <radialGradient id="SVGID_00000098194171971980914370000015724239769142798486_" cx="495.3344" cy="295.9998" r="85.6188" gradientUnits="userSpaceOnUse">
                  <stop offset="0.2117" style="stop-color:#C9C9C9"></stop>
                  <stop offset="1" style="stop-color:#000000"></stop>
            </radialGradient>
            <path style="mask:url(#SVGID_00000038406862567759002040000017315203014636608427_);fill:url(#SVGID_00000098194171971980914370000015724239769142798486_);" d="
                              M421.334,279.333c0,0,48.038-5.175,67.333-9.999c4-1,6.001-2,11.667,0.666c5.666,2.667,32.333-1.666,45.333-2.666
                              S572,263,572,274.667c0,11.667,0.334,17.667-0.333,22S564.666,312,527,318s-76,1.667-98.333-5.666
                              C406.334,305,414.334,279.665,421.334,279.333z"></path>
            </g>
            </mask>
            <radialGradient id="SVGID_00000149373137765803129640000008552576071954420361_" cx="495.3344" cy="295.9998" r="85.6188" gradientUnits="userSpaceOnUse">
            <stop offset="0.2117" style="stop-color:#F0F8FF"></stop>
            <stop offset="1" style="stop-color:#1190FF"></stop>
            </radialGradient>
            <path style="fill: #b7ce96;" d="M421.334,279.333
                  c0,0,48.038-5.175,67.333-9.999c4-1,6.001-2,11.667,0.666c5.666,2.667,32.333-1.666,45.333-2.666S572,263,572,274.667
                  c0,11.667,0.334,17.667-0.333,22S564.666,312,527,318s-76,1.667-98.333-5.666C406.334,305,414.334,279.665,421.334,279.333z"></path>
            <g>
            <path style="fill:#FFFFFF;" d="M453,280.5c0,0,41.5-8,92-9.5c0,0-37,5-38.5,12s-4.5,24-4,30s4.5,7,12.5,6.5c0,0-28.5,3.5-59-2.5
                        c0,0,29.5,4,35-1s4.5-23,2-30.5S453,280.5,453,280.5z"></path>
            </g>
      </g>
      <g>
            <radialGradient id="SVGID_00000078041154627907891850000008496850420230675618_" cx="534.667" cy="198.6671" r="103.335" gradientUnits="userSpaceOnUse">
            <stop offset="0" style="stop-color:#002948"></stop>
            <stop offset="0.2242" style="stop-color:#003C6A"></stop>
            <stop offset="0.5369" style="stop-color:#005493"></stop>
            <stop offset="0.7837" style="stop-color:#0063AD"></stop>
            <stop offset="0.9319" style="stop-color:#0068B6"></stop>
            </radialGradient>
            <path style="fill:url(#SVGID_00000078041154627907891850000008496850420230675618_);" d="M547.5,175.25c0,0,35.717,5.736,40,8.5
                  c3.299,2.129,11.75,2,12,17c0.204,12.258,2.5,32-3.25,37s-13,8.25-13,8.25s16.179,0.06,20.5-3c4.321-3.06,6.75-8.25,6.75-22
                  s1-30.25-9.25-34.25s-16-9-16-9s25.5,5.75,28.5,7.75s4.75,5.25,5,20.25s2,30.75-5,38.75s-24.5,10.75-27.75,10.25
                  s-44.5-0.5-44.5-0.5s43.75-11.5,47.5-15.5s6.5-35,4.75-42.25c-0.646-2.68-2.113-4.745-3.705-6.283
                  C587.329,187.593,547.5,175.25,547.5,175.25z"></path>
      </g>
      <g>
            <path style="fill: #9cba73;" d="M333.666,199.667c10.897-9.75,36.333-31,52.667-38.667c16.334-7.666,35.334-5.667,54.334-4.333
                  c19,1.333,85.666,14.334,120.333,22.667c34.667,8.333,34.333,12.333,34,30.333s0.999,26.666-9.667,32
                  c-10.666,5.333-119.999,29.667-154.666,32.667S383.666,268,371.333,261C359,254,339.334,239.333,333,233
                  C326.667,226.667,321,211,333.666,199.667z"></path>
            <g style="opacity:0.5;">
            <linearGradient id="SVGID_00000064318777375115568900000016326770545855826050_" gradientUnits="userSpaceOnUse" x1="326.0896" y1="215.1542" x2="595.065" y2="215.1542">
            <stop offset="0" style="stop-color:#0095CE"></stop>
            <stop offset="0.1843" style="stop-color:#0793E2"></stop>
            <stop offset="0.4312" style="stop-color:#0E91F7"></stop>
            <stop offset="0.5934" style="stop-color:#1190FF"></stop>
            <stop offset="1" style="stop-color:#0095CE"></stop>
            </linearGradient>
            <path style="fill:url(#SVGID_00000064318777375115568900000016326770545855826050_);" d="M561,179.334
                        c-2.057-0.494-4.229-1.005-6.493-1.529c4.14,1.629,23.66,6.529,28.66,10.196c3.68,2.699-10.957,1.077-14.146,0.2
                        c-14.413-3.966-77.1-16.605-93.577-19.378c-22.804-3.837-38.624-5.952-63.277-4.655c-12.667,0.667-19,2-21.546,1.606
                        c-3.537-0.548,4.853-5.31,7.213-6.439c3.833-1.834,11.493-2.786,16.772-2.758c5.543,0.029,11.069,0.183,16.614,0.11
                        c2.679-0.035,5.414-0.15,8.13-0.113c-18.56-1.327-37.061-3.062-53.018,4.428c-16.334,7.667-41.77,28.917-52.667,38.667
                        C321,211,326.667,226.667,333,233c6.334,6.333,26,21,38.333,28c12.333,7,24.667,16.334,59.334,13.334
                        c9.661-0.836,25.124-3.33,42.632-6.607c-0.39,0.032-0.779,0.063-1.169,0.096c-15.791,1.306-46.987,8.615-62.13,3.345
                        c-7.391-2.572-17.999-7.954-8.666-6.833c12.5,1.5,28.897,0.498,49.833-2.5c34.41-4.928,66.987-12.618,100.833-20
                        c9.057-1.975,18.775-6.334,28.352-4.497c2.378,0.456,11.724-0.199-4.019,6.831c-1.984,0.886-10.766,3.172-12.801,3.93
                        c11.249-2.849,19.281-5.17,21.801-6.431c10.666-5.334,9.334-14,9.667-32S595.667,187.667,561,179.334z"></path>
            </g>
            <g style="opacity:0.5;">
            <linearGradient id="SVGID_00000103231429892051020090000015518934170324739998_" gradientUnits="userSpaceOnUse" x1="326.0896" y1="215.1542" x2="595.065" y2="215.1542">
            <stop offset="0" style="stop-color:#0095CE"></stop>
            <stop offset="0.1843" style="stop-color:#0793E2"></stop>
            <stop offset="0.4312" style="stop-color:#0E91F7"></stop>
            <stop offset="0.5934" style="stop-color:#1190FF"></stop>
            <stop offset="1" style="stop-color:#0095CE"></stop>
            </linearGradient>
            <path style="fill: #b7ce96;" d="M561,179.334
                        c-2.057-0.494-4.229-1.005-6.493-1.529c4.14,1.629,23.66,6.529,28.66,10.196c3.68,2.699-10.957,1.077-14.146,0.2
                        c-14.413-3.966-77.1-16.605-93.577-19.378c-22.804-3.837-38.624-5.952-63.277-4.655c-12.667,0.667-19,2-21.546,1.606
                        c-3.537-0.548,4.853-5.31,7.213-6.439c3.833-1.834,11.493-2.786,16.772-2.758c5.543,0.029,11.069,0.183,16.614,0.11
                        c2.679-0.035,5.414-0.15,8.13-0.113c-18.56-1.327-37.061-3.062-53.018,4.428c-16.334,7.667-41.77,28.917-52.667,38.667
                        C321,211,326.667,226.667,333,233c6.334,6.333,26,21,38.333,28c12.333,7,24.667,16.334,59.334,13.334
                        c9.661-0.836,25.124-3.33,42.632-6.607c-0.39,0.032-0.779,0.063-1.169,0.096c-15.791,1.306-46.987,8.615-62.13,3.345
                        c-7.391-2.572-17.999-7.954-8.666-6.833c12.5,1.5,28.897,0.498,49.833-2.5c34.41-4.928,66.987-12.618,100.833-20
                        c9.057-1.975,18.775-6.334,28.352-4.497c2.378,0.456,11.724-0.199-4.019,6.831c-1.984,0.886-10.766,3.172-12.801,3.93
                        c11.249-2.849,19.281-5.17,21.801-6.431c10.666-5.334,9.334-14,9.667-32S595.667,187.667,561,179.334z"></path>
            </g>
            <g>
            <linearGradient id="SVGID_00000145033298400965188330000017612181347475912074_" gradientUnits="userSpaceOnUse" x1="326.0958" y1="213.9188" x2="595.0649" y2="213.9188">
            <stop offset="0" style="stop-color:#0095CE"></stop>
            <stop offset="0.1843" style="stop-color:#0793E2"></stop>
            <stop offset="0.4312" style="stop-color:#0E91F7"></stop>
            <stop offset="0.5934" style="stop-color:#1190FF"></stop>
            <stop offset="1" style="stop-color:#0095CE"></stop>
            </linearGradient>
            <path style="fill: #9cba73;" d="M580.959,185.048
                        c2.882,1.633,6.157,3.704,7.041,5.119c1.666,2.667-11,3-11,3s9,0.5,11.833,1.667c2.833,1.166,3.833,5.5,3.833,16
                        s-0.499,15.333-2.166,17.5c-1.667,2.166-13,3-13,3s11.832,1.166,11.666,4.333c-0.128,2.436-2.336,4.383-6.684,7.123
                        c1.264-0.434,2.227-0.811,2.851-1.123c10.666-5.334,9.334-14,9.667-32C595.25,196.159,595.499,190.535,580.959,185.048z"></path>
            </g>
            <path style="fill: #9cba73;" d="M350.333,188.667c12.155-7.948,23.667-19,53.667-22s68.667,3.667,107,10.667s53.333,10.666,60,14
                  c6.667,3.333,6.667,6,6.667,20s0,20.333-10,24.666c-10,4.334-44.334,12.001-81.667,18.667
                  c-37.333,6.667-78.666,12.333-101.333,3.667c-22.667-8.667-56.477-23.34-57.001-41.667
                  C327.333,205,341.666,194.334,350.333,188.667z"></path>
            <g style="opacity:0.4;">
            <linearGradient id="SVGID_00000112629229669433840560000001591410877871005371_" gradientUnits="userSpaceOnUse" x1="327.6603" y1="214.3362" x2="577.667" y2="214.3362">
            <stop offset="0" style="stop-color:#0095CE"></stop>
            <stop offset="0.4066" style="stop-color:#1190FF"></stop>
            <stop offset="0.5688" style="stop-color:#0E91F7"></stop>
            <stop offset="0.8157" style="stop-color:#0793E2"></stop>
            <stop offset="1" style="stop-color:#0095CE"></stop>
            </linearGradient>
            <path style="fill: #9cba73;" d="M571,191.334
                        c-6.667-3.334-21.667-7-60-14s-77-13.667-107-10.667s-41.512,14.052-53.667,22c-8.667,5.667-23,16.333-22.667,28
                        c0.524,18.326,34.334,33,57.001,41.667c22.667,8.666,64,3,101.333-3.667c37.333-6.666,71.667-14.333,81.667-18.667
                        c10-4.333,10-10.666,10-24.666S577.667,194.667,571,191.334z M346.25,228.5c1.922,3.053-1.107,5.127-5.25,2.75
                        c-4.176-2.395-10.273-8.884-10.98-10.348c-2.817-5.832,0.173-11.881,4.482-15.727c11.198-9.993,14.753-9.244,10.248-1.675
                        C342.064,208.012,342,221.75,346.25,228.5z M572,210.5c0.262,12.567-1.624,18.057-11.5,20.25
                        c-14.177,3.149-40.1,7.805-54.25,11.25c-22.723,5.532-52.626,9.664-76,11c-22.832,1.305-68.37-6.118-74.099-35.669
                        c-2.143-11.058,7.534-19.487,15.74-25.151c20.694-14.281,41.123-21.273,66.406-18.898c38.627,3.631,77.679,13.847,115.356,22.832
                        C561.717,198.037,571.75,198.5,572,210.5z"></path>
            </g>
            <g>
            <path style="fill:#2FECFF;" d="M414.01,260.883c-0.871,0-1.743-0.011-2.617-0.033l0.025-1c0.866,0.023,1.729,0.033,2.592,0.033
                        l0.394-0.001l0.004,1L414.01,260.883z M417.419,260.83l-0.031-1c0.99-0.03,1.983-0.073,2.982-0.127l0.055,0.999
                        C419.418,260.756,418.417,260.799,417.419,260.83z M408.38,260.724c-0.996-0.057-1.997-0.131-3.009-0.222l0.09-0.996
                        c1,0.09,1.991,0.163,2.976,0.22L408.38,260.724z M423.428,260.509l-0.074-0.997c0.985-0.073,1.978-0.154,2.982-0.244l0.088,0.996
                        C425.415,260.354,424.417,260.436,423.428,260.509z M402.372,260.183c-0.478-0.058-0.957-0.121-1.44-0.188
                        c-0.498-0.069-1.018-0.153-1.559-0.253l0.182-0.983c0.525,0.097,1.031,0.18,1.514,0.247c0.478,0.066,0.953,0.128,1.425,0.186
                        L402.372,260.183z M429.415,259.978l-0.101-0.995c0.979-0.099,1.971-0.204,2.978-0.315l0.109,0.994
                        C431.391,259.773,430.397,259.879,429.415,259.978z M435.385,259.326l-0.113-0.993l2.977-0.347l0.117,0.993L435.385,259.326z
                        M396.407,259.098c-0.934-0.229-1.91-0.489-2.923-0.777l0.273-0.962c1.001,0.284,1.966,0.542,2.888,0.767L396.407,259.098z
                        M441.346,258.628l-0.117-0.993l2.979-0.349l0.115,0.993L441.346,258.628z M447.302,257.938l-0.113-0.993
                        c0.977-0.111,1.971-0.221,2.983-0.332l0.109,0.994C449.268,257.718,448.276,257.828,447.302,257.938z M390.591,257.455
                        c-0.934-0.293-1.893-0.604-2.868-0.931l0.318-0.948c0.97,0.325,1.921,0.634,2.849,0.925L390.591,257.455z M453.274,257.273
                        l-0.119-0.993c0.914-0.109,1.91-0.235,2.98-0.376l0.131,0.991C455.192,257.036,454.192,257.162,453.274,257.273z M459.253,256.49
                        l-0.139-0.99c0.949-0.132,1.942-0.274,2.974-0.424l0.145,0.989C461.199,256.216,460.204,256.357,459.253,256.49z M465.21,255.626
                        l-0.148-0.989c0.963-0.144,1.954-0.294,2.971-0.451l0.152,0.988C467.166,255.331,466.175,255.481,465.21,255.626z
                        M384.872,255.542c-0.934-0.33-1.879-0.671-2.833-1.022l0.346-0.938c0.95,0.349,1.892,0.689,2.821,1.018L384.872,255.542z
                        M471.154,254.712L471,253.724l2.966-0.47l0.158,0.987L471.154,254.712z M477.093,253.762l-0.16-0.987l2.963-0.487l0.164,0.986
                        L477.093,253.762z M379.218,253.464c-0.933-0.354-1.87-0.716-2.809-1.083l0.365-0.932c0.935,0.366,1.868,0.727,2.799,1.081
                        L379.218,253.464z M483.024,252.779l-0.166-0.986l2.961-0.502l0.168,0.985L483.024,252.779z M488.948,251.768l-0.17-0.985
                        l2.958-0.516l0.174,0.985L488.948,251.768z M373.607,251.269c-0.933-0.374-1.862-0.75-2.787-1.129l0.379-0.926
                        c0.923,0.378,1.851,0.754,2.781,1.127L373.607,251.269z M494.869,250.73l-0.176-0.984l2.956-0.529l0.178,0.984L494.869,250.73z
                        M500.784,249.665l-0.18-0.983l2.946-0.542l0.182,0.983L500.784,249.665z M368.04,248.99c-0.935-0.39-1.86-0.78-2.773-1.167
                        l0.391-0.921c0.911,0.387,1.835,0.776,2.768,1.166L368.04,248.99z M506.685,248.574l-0.184-0.983l2.955-0.558l0.188,0.982
                        L506.685,248.574z M512.598,247.451l-0.189-0.982l2.947-0.571l0.191,0.981L512.598,247.451z M362.499,246.639
                        c-0.939-0.405-1.859-0.805-2.759-1.199l0.4-0.916c0.897,0.393,1.818,0.792,2.755,1.197L362.499,246.639z M518.495,246.299
                        l-0.193-0.981c0.985-0.195,1.967-0.392,2.944-0.589l0.197,0.981C520.466,245.907,519.482,246.103,518.495,246.299z
                        M524.389,245.111l-0.201-0.98c0.987-0.202,1.968-0.405,2.939-0.607l0.205,0.979C526.358,244.706,525.376,244.909,524.389,245.111
                        z M356.985,244.227c-0.951-0.42-1.869-0.831-2.749-1.226l0.41-0.912c0.878,0.395,1.794,0.804,2.743,1.224L356.985,244.227z
                        M530.272,243.884l-0.207-0.979c0.987-0.21,1.966-0.42,2.935-0.63l0.213,0.977C532.242,243.464,531.262,243.674,530.272,243.884z
                        M536.15,242.609l-0.217-0.977c0.989-0.219,1.966-0.438,2.929-0.657l0.223,0.975C538.12,242.17,537.142,242.389,536.15,242.609z
                        M351.495,241.762c-0.986-0.448-1.902-0.868-2.739-1.253l0.418-0.908c0.835,0.384,1.751,0.804,2.735,1.251L351.495,241.762z
                        M542.016,241.276l-0.227-0.974c0.99-0.231,1.965-0.462,2.92-0.692l0.234,0.972C543.986,240.814,543.01,241.045,542.016,241.276z
                        M547.866,239.868l-0.242-0.971c0.995-0.248,1.965-0.494,2.908-0.738l0.252,0.968
                        C549.837,239.372,548.863,239.619,547.866,239.868z M346.022,239.242c-1.742-0.814-2.738-1.29-2.738-1.29l0.432-0.902
                        c0.001,0.001,0.994,0.476,2.73,1.287L346.022,239.242z M553.694,238.355l-0.262-0.965c1-0.272,1.964-0.542,2.889-0.808
                        l0.277,0.961C555.668,237.811,554.7,238.082,553.694,238.355z M559.489,236.677l-0.297-0.955c1.014-0.316,1.964-0.627,2.843-0.932
                        l0.328,0.945C561.475,236.044,560.515,236.357,559.489,236.677z M565.207,234.669l-0.379-0.925
                        c1.086-0.446,1.977-0.871,2.649-1.263l0.504,0.863C567.271,233.761,566.337,234.206,565.207,234.669z M570.537,231.736
                        l-0.545-0.839c1.056-0.685,1.802-1.205,2.418-1.688l0.617,0.787C572.386,230.498,571.618,231.035,570.537,231.736z
                        M575.23,227.783l-0.813-0.583c0.507-0.708,0.89-1.516,1.203-2.545l0.957,0.292C576.231,226.083,575.804,226.984,575.23,227.783z
                        M577.252,221.97l-0.984-0.174c0.152-0.865,0.301-1.837,0.461-2.944l0.99,0.143C577.557,220.113,577.406,221.096,577.252,221.97z"></path>
            </g>
            <g>
            <path style="fill:#2FECFF;" d="M577.001,209.037l0.995-0.114l0.003,0.04L577.001,209.037z M576.866,205.98
                        c-0.032-1.142-0.059-2.094-0.176-2.934l0.99-0.138c0.125,0.895,0.152,1.873,0.185,3.004L576.866,205.98z M575.784,200.445
                        c-0.435-0.682-1.064-1.357-1.925-2.065l0.635-0.772c0.943,0.775,1.641,1.528,2.134,2.3L575.784,200.445z M571.469,196.681
                        c-0.381-0.242-0.788-0.492-1.225-0.751c-0.338-0.202-0.785-0.42-1.328-0.649l0.389-0.921c0.585,0.247,1.073,0.486,1.451,0.711
                        c0.444,0.264,0.861,0.52,1.25,0.767L571.469,196.681z M343.051,195.149l-0.602-0.799c0.035-0.027,0.883-0.663,2.486-1.741
                        l0.559,0.83C343.915,194.501,343.059,195.143,343.051,195.149z M566.129,194.267c-0.862-0.279-1.819-0.569-2.861-0.866
                        l0.273-0.962c1.056,0.301,2.024,0.594,2.896,0.876L566.129,194.267z M560.374,192.614c-0.924-0.242-1.895-0.488-2.907-0.738
                        l0.239-0.971c1.018,0.251,1.994,0.497,2.922,0.742L560.374,192.614z M347.998,191.805l-0.535-0.845
                        c0.771-0.489,1.627-1.02,2.563-1.585l0.518,0.856C349.613,190.792,348.764,191.319,347.998,191.805z M554.551,191.174
                        c-0.948-0.223-1.925-0.448-2.925-0.675l0.221-0.976c1.004,0.228,1.982,0.454,2.933,0.677L554.551,191.174z M548.694,189.844
                        c-0.96-0.21-1.938-0.423-2.934-0.636l0.209-0.977c0.997,0.213,1.977,0.426,2.939,0.637L548.694,189.844z M353.12,188.714
                        l-0.498-0.867c0.83-0.477,1.706-0.97,2.626-1.475l0.48,0.877C354.814,187.75,353.944,188.24,353.12,188.714z M542.823,188.585
                        c-0.969-0.202-1.95-0.406-2.94-0.609l0.201-0.98c0.992,0.204,1.974,0.407,2.944,0.61L542.823,188.585z M536.94,187.378
                        l-2.945-0.59l0.195-0.98l2.947,0.59L536.94,187.378z M531.049,186.207l-2.947-0.575l0.19-0.981l2.951,0.575L531.049,186.207z
                        M358.368,185.834l-0.465-0.886c0.862-0.452,1.756-0.91,2.681-1.373l0.447,0.895C360.112,184.93,359.225,185.385,358.368,185.834z
                        M525.147,185.064l-2.948-0.562l0.185-0.983l2.95,0.562L525.147,185.064z M519.246,183.944l-2.952-0.552l0.184-0.983l2.954,0.553
                        L519.246,183.944z M363.72,183.158l-0.43-0.903c0.887-0.422,1.796-0.846,2.729-1.27l0.414,0.91
                        C365.506,182.317,364.601,182.738,363.72,183.158z M513.342,182.844l-2.955-0.544l0.182-0.983l2.955,0.544L513.342,182.844z
                        M507.43,181.758l-2.956-0.539l0.18-0.983l2.956,0.539L507.43,181.758z M369.169,180.685l-0.397-0.918
                        c0.906-0.391,1.832-0.78,2.775-1.166l0.379,0.926C370.989,179.91,370.069,180.297,369.169,180.685z M501.518,180.684l-2.958-0.535
                        l0.178-0.984l2.958,0.535L501.518,180.684z M495.602,179.615l-2.959-0.534l0.178-0.984l2.959,0.534L495.602,179.615z
                        M489.683,178.544l-2.961-0.541l0.182-0.983l2.959,0.541L489.683,178.544z M374.708,178.422l-0.361-0.933
                        c0.925-0.357,1.864-0.71,2.819-1.058l0.342,0.939C376.559,177.716,375.626,178.067,374.708,178.422z M483.77,177.451l-2.95-0.561
                        l0.188-0.982l2.948,0.561L483.77,177.451z M380.329,176.376l-0.322-0.946c0.939-0.321,1.893-0.636,2.858-0.945l0.305,0.952
                        C382.21,175.743,381.263,176.057,380.329,176.376z M477.87,176.331l-2.948-0.552l0.182-0.983l2.952,0.553L477.87,176.331z
                        M471.97,175.236c-0.964-0.175-1.948-0.352-2.951-0.529l0.174-0.984c1.005,0.177,1.991,0.354,2.957,0.529L471.97,175.236z
                        M386.027,174.556l-0.285-0.959c0.954-0.283,1.919-0.56,2.896-0.827l0.264,0.965C387.933,174.001,386.974,174.275,386.027,174.556
                        z M466.065,174.194c-0.972-0.165-1.958-0.331-2.958-0.495l0.162-0.986c1.002,0.164,1.99,0.33,2.964,0.496L466.065,174.194z
                        M460.147,173.224c-0.977-0.154-1.965-0.306-2.962-0.454l0.147-0.989c1.001,0.149,1.991,0.301,2.971,0.455L460.147,173.224z
                        M391.793,172.973l-0.244-0.97c0.965-0.243,1.941-0.477,2.928-0.704l0.223,0.975C393.721,172.498,392.752,172.731,391.793,172.973
                        z M454.219,172.34c-0.982-0.139-1.972-0.273-2.97-0.405l0.131-0.991c1.001,0.132,1.993,0.268,2.977,0.406L454.219,172.34z
                        M397.617,171.659l-0.189-0.982c0.979-0.189,1.971-0.363,2.97-0.522l0.158,0.987C399.566,171.3,398.587,171.472,397.617,171.659z
                        M448.276,171.556c-0.985-0.12-1.979-0.237-2.975-0.349l0.111-0.994c1.001,0.113,1.996,0.23,2.985,0.351L448.276,171.556z
                        M442.321,170.888c-0.99-0.101-1.984-0.197-2.981-0.287l0.09-0.996c1.001,0.091,1.999,0.187,2.993,0.288L442.321,170.888z
                        M403.51,170.717l-0.129-0.992c0.991-0.128,1.988-0.243,2.993-0.346l0.102,0.995C405.48,170.477,404.491,170.59,403.51,170.717z
                        M436.35,170.349c-0.994-0.078-1.991-0.149-2.989-0.215l0.065-0.998c1.004,0.066,2.005,0.138,3.003,0.216L436.35,170.349z
                        M409.451,170.108l-0.076-0.997c0.995-0.077,1.997-0.142,3.004-0.197l0.055,0.999C411.435,169.967,410.44,170.032,409.451,170.108
                        z M430.368,169.96c-0.995-0.052-1.992-0.096-2.99-0.133l0.037-0.999c1.002,0.037,2.005,0.082,3.006,0.133L430.368,169.96z
                        M415.419,169.783l-0.033-0.999c0.999-0.034,2.003-0.057,3.009-0.07l0.014,1C417.408,169.728,416.412,169.751,415.419,169.783z
                        M424.388,169.739c-0.998-0.021-1.995-0.034-2.99-0.039l0.004-1c1.001,0.004,2.004,0.018,3.008,0.039L424.388,169.739z"></path>
            </g>
            <defs>
            <filter id="Adobe_OpacityMaskFilter_00000066505827817615644100000016278130213787283127_" filterUnits="userSpaceOnUse" x="346.25" y="176.873" width="53.503" height="73.994">
            <feFlood style="flood-color:white;flood-opacity:1" result="back"></feFlood>
            <feBlend in="SourceGraphic" in2="back" mode="normal"></feBlend>
            </filter>
            </defs>
            <mask maskUnits="userSpaceOnUse" x="346.25" y="176.873" width="53.503" height="73.994" id="SVGID_00000162338866652784730370000010358098226274885511_">
            <g style="filter:url(#Adobe_OpacityMaskFilter_00000066505827817615644100000016278130213787283127_);">
            <defs>
                  <filter id="Adobe_OpacityMaskFilter_00000049213516818691063550000010548604564351561129_" filterUnits="userSpaceOnUse" x="346.25" y="176.873" width="53.503" height="73.994">
                  <feFlood style="flood-color:white;flood-opacity:1" result="back"></feFlood>
                  <feBlend in="SourceGraphic" in2="back" mode="normal"></feBlend>
                  </filter>
            </defs>
            <mask maskUnits="userSpaceOnUse" x="346.25" y="176.873" width="53.503" height="73.994" id="SVGID_00000162338866652784730370000010358098226274885511_">
                  <g style="filter:url(#Adobe_OpacityMaskFilter_00000049213516818691063550000010548604564351561129_);"></g>
            </mask>
            <linearGradient id="SVGID_00000039834059355487404770000015712148185388471203_" gradientUnits="userSpaceOnUse" x1="346.25" y1="213.8697" x2="399.7527" y2="213.8697">
                  <stop offset="0.2254" style="stop-color:#FFFFFF"></stop>
                  <stop offset="0.2943" style="stop-color:#F8F8F8"></stop>
                  <stop offset="0.3931" style="stop-color:#E5E5E5"></stop>
                  <stop offset="0.51" style="stop-color:#C6C6C6"></stop>
                  <stop offset="0.6409" style="stop-color:#9B9B9B"></stop>
                  <stop offset="0.7832" style="stop-color:#636363"></stop>
                  <stop offset="0.933" style="stop-color:#202020"></stop>
                  <stop offset="1" style="stop-color:#000000"></stop>
            </linearGradient>
            <path style="mask:url(#SVGID_00000162338866652784730370000010358098226274885511_);fill:url(#SVGID_00000039834059355487404770000015712148185388471203_);" d="
                              M359.25,188c-8.128,5.362-13,18-13,27.25s3.433,22.128,13.75,27.5C408.5,268,399,227.5,399,214S407,156.5,359.25,188z"></path>
            </g>
            </mask>
            <linearGradient id="SVGID_00000125580481750388938190000017408300298114395540_" gradientUnits="userSpaceOnUse" x1="346.25" y1="213.8697" x2="399.7527" y2="213.8697">
            <stop offset="0.2254" style="stop-color:#FFFFFF"></stop>
            <stop offset="0.2963" style="stop-color:#F8FCFF"></stop>
            <stop offset="0.3981" style="stop-color:#E5F3FF"></stop>
            <stop offset="0.5185" style="stop-color:#C6E4FF"></stop>
            <stop offset="0.6532" style="stop-color:#9BCFFF"></stop>
            <stop offset="0.7998" style="stop-color:#63B4FF"></stop>
            <stop offset="0.9541" style="stop-color:#2094FF"></stop>
            <stop offset="1" style="stop-color:#0B8AFF"></stop>
            </linearGradient>
            <path style="opacity: 0.7; fill: #b7ce96;" d="M359.25,188
                  c-8.128,5.362-13,18-13,27.25s3.433,22.128,13.75,27.5C408.5,268,399,227.5,399,214S407,156.5,359.25,188z"></path>
            <defs>
            <filter id="Adobe_OpacityMaskFilter_00000116945037700183740480000007758245448635301289_" filterUnits="userSpaceOnUse" x="386" y="173.73" width="180.5" height="76.972">
            <feFlood style="flood-color:white;flood-opacity:1" result="back"></feFlood>
            <feBlend in="SourceGraphic" in2="back" mode="normal"></feBlend>
            </filter>
            </defs>
            <mask maskUnits="userSpaceOnUse" x="386" y="173.73" width="180.5" height="76.972" id="SVGID_00000132787046659546602190000011043217050348213692_">
            <g style="filter:url(#Adobe_OpacityMaskFilter_00000116945037700183740480000007758245448635301289_);">
            <defs>
                  <filter id="Adobe_OpacityMaskFilter_00000155830977426364100500000002167757040233012870_" filterUnits="userSpaceOnUse" x="386" y="173.73" width="180.5" height="76.972">
                  <feFlood style="flood-color:white;flood-opacity:1" result="back"></feFlood>
                  <feBlend in="SourceGraphic" in2="back" mode="normal"></feBlend>
                  </filter>
            </defs>
            <mask maskUnits="userSpaceOnUse" x="386" y="173.73" width="180.5" height="76.972" id="SVGID_00000132787046659546602190000011043217050348213692_">
                  <g style="filter:url(#Adobe_OpacityMaskFilter_00000155830977426364100500000002167757040233012870_);"></g>
            </mask>
            <linearGradient id="SVGID_00000160163554992455002380000014543708042491217343_" gradientUnits="userSpaceOnUse" x1="386" y1="212.2165" x2="566.5" y2="212.2165">
                  <stop offset="0.0614" style="stop-color:#000000"></stop>
                  <stop offset="0.0656" style="stop-color:#272727"></stop>
                  <stop offset="0.0712" style="stop-color:#545454"></stop>
                  <stop offset="0.0774" style="stop-color:#7D7D7D"></stop>
                  <stop offset="0.0841" style="stop-color:#A0A0A0"></stop>
                  <stop offset="0.0912" style="stop-color:#BEBEBE"></stop>
                  <stop offset="0.0991" style="stop-color:#D6D6D6"></stop>
                  <stop offset="0.108" style="stop-color:#E8E8E8"></stop>
                  <stop offset="0.1185" style="stop-color:#F5F5F5"></stop>
                  <stop offset="0.1322" style="stop-color:#FDFDFD"></stop>
                  <stop offset="0.1607" style="stop-color:#FFFFFF"></stop>
                  <stop offset="0.2295" style="stop-color:#EAEAEA"></stop>
                  <stop offset="0.5345" style="stop-color:#D6D6D6"></stop>
                  <stop offset="0.5655" style="stop-color:#C8C8C8"></stop>
                  <stop offset="0.6235" style="stop-color:#A4A4A4"></stop>
                  <stop offset="0.7025" style="stop-color:#6A6A6A"></stop>
                  <stop offset="0.7983" style="stop-color:#1B1B1B"></stop>
                  <stop offset="0.8294" style="stop-color:#000000"></stop>
            </linearGradient>
            <path style="mask:url(#SVGID_00000132787046659546602190000011043217050348213692_);fill:url(#SVGID_00000160163554992455002380000014543708042491217343_);" d="
                              M395.5,176c0,0,22-6.5,70.5,2.5s99.5,19,99.5,19s-24-4-33-1.5s-16,7.5-15.5,19.5s4.5,16,16.5,15.5s33-2,33-2s-79,15-109.5,19
                              s-51,4-71-3c0,0,26.5,9,23-13s0-39.5,2-48S395.5,176,395.5,176z"></path>
            </g>
            </mask>
            <linearGradient id="SVGID_00000056428289067024708700000008425314671154686365_" gradientUnits="userSpaceOnUse" x1="386" y1="212.2165" x2="566.5" y2="212.2165">
            <stop offset="0.0614" style="stop-color:#0085D2"></stop>
            <stop offset="0.0656" style="stop-color:#2798D9"></stop>
            <stop offset="0.0712" style="stop-color:#54ADE1"></stop>
            <stop offset="0.0774" style="stop-color:#7DC1E8"></stop>
            <stop offset="0.0841" style="stop-color:#A0D2EE"></stop>
            <stop offset="0.0912" style="stop-color:#BEE0F3"></stop>
            <stop offset="0.0991" style="stop-color:#D6EBF8"></stop>
            <stop offset="0.108" style="stop-color:#E8F4FB"></stop>
            <stop offset="0.1185" style="stop-color:#F5FAFD"></stop>
            <stop offset="0.1322" style="stop-color:#FDFEFF"></stop>
            <stop offset="0.1607" style="stop-color:#FFFFFF"></stop>
            <stop offset="0.2295" style="stop-color:#0B90FF"></stop>
            <stop offset="0.26" style="stop-color:#239AFF"></stop>
            <stop offset="0.4512" style="stop-color:#B7D7FF"></stop>
            <stop offset="0.5345" style="stop-color:#F2EFFF"></stop>
            <stop offset="0.5698" style="stop-color:#E4EAFF"></stop>
            <stop offset="0.6359" style="stop-color:#C1DEFF"></stop>
            <stop offset="0.7258" style="stop-color:#87CAFF"></stop>
            <stop offset="0.8294" style="stop-color:#3CB0FF"></stop>
            </linearGradient>
            <path style="opacity: 0.7; fill: #b7ce96;" d="M395.5,176
                  c0,0,22-6.5,70.5,2.5s99.5,19,99.5,19s-24-4-33-1.5s-16,7.5-15.5,19.5s4.5,16,16.5,15.5s33-2,33-2s-79,15-109.5,19s-51,4-71-3
                  c0,0,26.5,9,23-13s0-39.5,2-48S395.5,176,395.5,176z"></path>
            <path style="fill: #b7ce96;" d="M577.567,211.334c0,0-0.037-1.127-0.107-3.24c-0.039-1.055-0.088-2.355-0.145-3.885
                  c-0.082-1.523-0.186-3.283-0.54-5.181c-0.348-1.888-1.099-3.919-2.91-5.291c-0.887-0.694-1.938-1.284-3.068-1.839
                  c-0.582-0.245-1.176-0.494-1.779-0.747c-0.613-0.254-1.234-0.537-1.88-0.724c-5.088-1.749-11.148-3.213-17.727-4.651
                  c-3.292-0.738-6.739-1.439-10.313-2.143c-3.571-0.72-7.263-1.464-11.058-2.229c-7.599-1.516-15.611-3.115-23.9-4.769
                  c-8.288-1.663-16.884-3.219-25.643-4.645c-2.191-0.348-4.393-0.681-6.604-0.997c-2.21-0.327-4.427-0.649-6.651-0.939
                  c-4.452-0.576-8.929-1.092-13.41-1.535c-4.481-0.443-8.97-0.814-13.442-1.076c-2.236-0.143-4.467-0.292-6.694-0.359
                  c-2.226-0.099-4.444-0.18-6.655-0.188c-8.848-0.148-17.524,0.206-25.863,1.397c-8.247,1.221-16.19,3.127-23.227,6.128
                  c-7.087,2.911-13.216,6.845-18.795,10.635c-2.773,1.907-5.516,3.728-8.094,5.385c-2.539,1.692-4.931,3.357-7.066,5.048
                  c-4.293,3.36-7.668,6.75-9.958,9.905c-2.33,3.133-3.48,6.045-3.889,8.086c-0.277,1.015-0.276,1.822-0.322,2.362
                  c-0.039,0.541-0.06,0.82-0.06,0.82l-0.2,0.006c0,0,0.007-0.284,0.02-0.833c0.021-0.55-0.018-1.375,0.215-2.422
                  c0.316-2.105,1.354-5.15,3.586-8.472c2.186-3.354,5.559-6.893,9.793-10.458c2.123-1.784,4.458-3.572,7.024-5.334
                  c2.573-1.729,5.194-3.549,7.956-5.529c5.507-3.916,11.713-8.102,18.999-11.081c3.613-1.53,7.434-2.854,11.421-3.871
                  c1.979-0.566,4.02-0.963,6.07-1.408c1.02-0.242,2.076-0.365,3.121-0.544c1.047-0.157,2.105-0.355,3.156-0.481
                  c8.42-1.1,17.258-1.386,26.154-1.102c2.228,0.052,4.46,0.178,6.697,0.321c2.239,0.112,4.479,0.306,6.722,0.494
                  c2.245,0.176,4.485,0.435,6.73,0.677c2.245,0.226,4.484,0.523,6.725,0.804c4.479,0.559,8.949,1.196,13.391,1.883
                  c2.226,0.312,4.441,0.655,6.65,1.004c2.21,0.338,4.41,0.692,6.6,1.061c8.765,1.429,17.391,2.834,25.738,4.195
                  c4.17,0.698,8.271,1.384,12.285,2.056c4.016,0.669,7.94,1.35,11.746,2.096c7.627,1.407,14.812,2.887,21.409,4.445
                  c3.309,0.727,6.452,1.577,9.45,2.335c1.49,0.423,2.938,0.86,4.342,1.288c1.406,0.411,2.761,0.971,4.075,1.426
                  c0.67,0.219,1.272,0.517,1.88,0.789c0.613,0.281,1.217,0.556,1.81,0.827c1.155,0.611,2.256,1.268,3.22,2.07
                  c0.974,0.789,1.727,1.813,2.196,2.864c0.483,1.051,0.716,2.118,0.855,3.113c0.275,1.998,0.292,3.771,0.304,5.308
                  c0.002,1.537-0.016,2.842-0.044,3.899c-0.063,2.114-0.096,3.242-0.096,3.242H577.567z"></path>
            <g>
            <path style="fill: #9cba73;" d="M590.867,190.481c0,0,0.242,0.238,0.711,0.701c0.45,0.484,1.124,1.232,1.758,2.362
                        c0.649,1.12,1.226,2.637,1.583,4.478c0.368,1.84,0.5,3.987,0.563,6.374c0.065,2.392,0.014,5.019,0.02,7.886
                        c-0.003,2.864,0.037,5.96,0,9.287c-0.07,3.318-0.134,6.913-1.12,10.648c-0.531,1.851-1.344,3.724-2.598,5.378
                        c-1.24,1.674-2.945,2.975-4.749,4.064c-0.483,0.267-0.869,0.507-1.441,0.798c-0.479,0.189-0.96,0.38-1.443,0.571
                        c-0.483,0.191-0.97,0.383-1.458,0.577c-0.496,0.18-1.018,0.328-1.529,0.495c-2.057,0.651-4.159,1.33-6.275,1.897
                        c-4.234,1.233-8.66,2.287-13.191,3.446c-4.545,1.093-9.21,2.238-14.01,3.264c-4.789,1.074-9.689,2.13-14.678,3.182
                        c-9.979,2.083-20.29,4.235-30.768,6.421c-5.238,1.093-10.515,2.214-15.828,3.264c-5.299,1.119-10.632,2.179-15.967,3.233
                        c-10.676,2.085-21.368,4.096-32.003,5.554c-2.673,0.335-5.312,0.631-7.993,0.919c-1.322,0.095-2.642,0.19-3.957,0.286l-1.97,0.142
                        l-1.988,0.064c-1.324,0.04-2.645,0.08-3.96,0.12c-1.313-0.017-2.623-0.033-3.928-0.05c-1.306,0.01-2.606-0.101-3.9-0.173
                        c-1.295-0.074-2.586-0.143-3.865-0.311c-5.129-0.499-10.146-1.504-14.886-3.049c-4.746-1.53-9.181-3.62-13.297-5.904
                        c-2.064-1.137-4.05-2.318-6.007-3.456c-0.489-0.283-0.976-0.565-1.461-0.846c-0.489-0.291-0.977-0.582-1.463-0.871
                        c-0.954-0.581-1.9-1.156-2.839-1.728c-3.736-2.351-7.287-4.763-10.615-7.119c-6.665-4.737-12.621-9.284-17.699-13.578
                        c-2.527-2.156-4.881-4.217-6.845-6.363c-1.912-2.282-3.181-4.649-4.068-6.898c-1.791-4.506-1.936-8.396-1.738-10.936
                        c0.109-1.279,0.271-2.252,0.433-2.894c0.147-0.645,0.224-0.978,0.224-0.978l0.193,0.049c0,0-0.063,0.332-0.182,0.976
                        c-0.135,0.639-0.257,1.607-0.313,2.868c-0.097,2.504,0.201,6.306,2.114,10.61c0.946,2.13,2.269,4.422,4.1,6.494
                        c1.963,2.031,4.34,4.002,6.906,6.074c5.174,4.096,11.167,8.545,17.872,13.142c3.365,2.298,6.88,4.602,10.622,6.871
                        c0.946,0.559,1.9,1.122,2.862,1.69c0.476,0.28,0.952,0.563,1.432,0.845c0.49,0.281,0.982,0.564,1.477,0.848
                        c1.97,1.136,3.948,2.303,5.98,3.413c4.056,2.23,8.352,4.233,12.953,5.699c4.596,1.479,9.466,2.42,14.468,2.98
                        c1.246,0.177,2.511,0.256,3.779,0.34c1.268,0.082,2.539,0.204,3.822,0.205c1.283,0.029,2.57,0.057,3.861,0.086
                        c1.289-0.027,2.582-0.054,3.879-0.081l1.949-0.044l1.972-0.124c1.316-0.083,2.636-0.166,3.959-0.249
                        c2.619-0.272,5.251-0.544,7.893-0.818c2.646-0.351,5.302-0.702,7.966-1.056c2.66-0.403,5.328-0.808,8.001-1.213
                        c2.677-0.382,5.348-0.841,8.026-1.274c2.677-0.443,5.361-0.858,8.035-1.34c5.351-0.939,10.703-1.879,16.021-2.889
                        c5.318-1.007,10.601-2.085,15.847-3.136c10.471-2.197,20.755-4.459,30.656-6.867c9.918-2.333,19.447-4.821,28.509-7.052
                        c4.515-1.173,8.905-2.325,13.126-3.496c2.137-0.55,4.152-1.182,6.146-1.785c0.498-0.156,0.996-0.289,1.487-0.461
                        c0.49-0.188,0.979-0.375,1.464-0.561c0.486-0.187,0.969-0.372,1.45-0.556c0.418-0.23,0.834-0.458,1.249-0.685
                        c1.678-0.992,3.221-2.145,4.328-3.614c1.121-1.453,1.87-3.117,2.382-4.825c0.93-3.462,1.148-6.979,1.281-10.266
                        c0.173-6.616,0.323-12.384,0.448-17.134c0.03-2.365-0.018-4.477-0.307-6.277c-0.274-1.801-0.781-3.275-1.352-4.389
                        c-0.56-1.128-1.17-1.878-1.587-2.378c-0.444-0.486-0.674-0.736-0.674-0.736L590.867,190.481z"></path>
            </g>
      </g>
      <g>
            <linearGradient id="SVGID_00000102528348393902615370000012586240302212944817_" gradientUnits="userSpaceOnUse" x1="504.022" y1="214.792" x2="616.7195" y2="214.792">
            <stop offset="0" style="stop-color:#A6A78F"></stop>
            <stop offset="0.2447" style="stop-color:#E3E3DD"></stop>
            <stop offset="0.338" style="stop-color:#C0C1B0"></stop>
            <stop offset="0.4781" style="stop-color:#8F906F"></stop>
            <stop offset="0.8426" style="stop-color:#FFFFFF"></stop>
            <stop offset="0.8612" style="stop-color:#EDEDE8"></stop>
            <stop offset="0.9074" style="stop-color:#C5C5B4"></stop>
            <stop offset="0.9475" style="stop-color:#A8A88F"></stop>
            <stop offset="0.9798" style="stop-color:#969777"></stop>
            <stop offset="1" style="stop-color:#8F906F"></stop>
            </linearGradient>
            <path style="fill:url(#SVGID_00000102528348393902615370000012586240302212944817_);" d="M509,260.5
                  c11.062-2.765,73.5-14.25,82-16.75s13.75-7,13.25-30.5s-2-24-16.75-28.5c-14.68-4.478-72.75-15.5-79-17.25s-4.75-6.5,3-5.75
                  s70,10.25,80.75,12.25s15.5,1.5,20.75,12.75s4.25,44.25,1.25,50S611,248.5,599.5,251.5s-79.5,15-87.25,16.25S500,262.75,509,260.5z
                  "></path>
            <linearGradient id="SVGID_00000170241332770081556450000018268054007383711653_" gradientUnits="userSpaceOnUse" x1="504.0239" y1="214.7347" x2="616.7216" y2="214.7347">
            <stop offset="0" style="stop-color:#71798E"></stop>
            <stop offset="1" style="stop-color:#71798E"></stop>
            </linearGradient>
            <path style="opacity:0.6;fill:url(#SVGID_00000170241332770081556450000018268054007383711653_);" d="M512.5,262.5
                  c11.169-2.291,73.5-14.25,82-16.75s13.75-11,13.25-34.5s-2-24-16.75-28.5c-14.68-4.478-72.75-15.5-79-17.25
                  c-3.039-0.851-8.75-1.5-3.778-3.702c-4.737,0.688-4.949,4.238,0.278,5.702c6.25,1.75,64.32,12.772,79,17.25
                  c14.75,4.5,16.25,5,16.75,28.5s-4.75,28-13.25,30.5s-70.938,13.985-82,16.75c-7.161,1.791-5.772,6.109-1.001,7.171
                  C507.456,266.905,502.316,264.589,512.5,262.5z"></path>
            <linearGradient id="SVGID_00000160882893028021103520000006026059050527193480_" gradientUnits="userSpaceOnUse" x1="525.75" y1="214.25" x2="622.0655" y2="214.25">
            <stop offset="0.0673" style="stop-color:#71798E"></stop>
            <stop offset="0.2061" style="stop-color:#9399A9"></stop>
            <stop offset="0.375" style="stop-color:#B5BAC4"></stop>
            <stop offset="0.5142" style="stop-color:#CACED5"></stop>
            <stop offset="0.6053" style="stop-color:#D2D5DB"></stop>
            <stop offset="0.6838" style="stop-color:#C5C8D0"></stop>
            <stop offset="0.8302" style="stop-color:#A1A7B4"></stop>
            <stop offset="1" style="stop-color:#71798E"></stop>
            </linearGradient>
            <path style="opacity:0.5;fill:url(#SVGID_00000160882893028021103520000006026059050527193480_);" d="M525.75,164.25
                  c0,0,68.75,11.5,73.25,12.25s10.25,3,12,9.25s6,39.25,1.25,50.5s-8.75,14-16.25,15.5s-67.5,12.5-67.5,12.5s59.75-11.5,61.75-13.5
                  s-54,8.25-54,8.25s54.25-11,59-12.5s8.75-7,10.5-11.5s5.75-28.75,0-44.75c-2.195-6.108-7.25-5.75-10.25-6.75S535,169.25,535,169.25
                  s53.758,11.615,58.5,11.25C596.75,180.25,591.5,176,525.75,164.25z"></path>
            <g>
            <path style="fill:#FFFFFF;" d="M599.786,182.639c-1.591,0.404-3.242,0.599-4.904,0.684c0.284,0.077,0.499,0.138,0.618,0.178
                        c3,1,8.055,0.642,10.25,6.75c1.295,3.604,2.093,7.627,2.535,11.749c0.516-4.405-0.857-8.963-1.637-13.273
                        c-0.249-1.379-1.111-4.532,0.942-5.034c0.915-0.224,1.865-0.036,2.802,0.408c-2.226-4.999-7.316-6.92-11.393-7.599
                        c-0.075-0.013-0.172-0.029-0.282-0.047c1.54,0.629,2.834,1.531,3.489,2.935C603.06,181.217,601.377,182.234,599.786,182.639z"></path>
            </g>
            <g>
            <path style="fill:#FFFFFF;" d="M608.071,239.94c-1.906,0.919-2.788-1.43-2.562-2.914c0.393-2.569,1.562-5.758,1.79-8.693
                        c-0.532,3.167-1.111,5.539-1.55,6.666c-1.75,4.5-5.75,10-10.5,11.5c-1.027,0.324-4.37,1.093-8.977,2.099
                        c2.867,0.397,5.846-0.094,8.607-0.903c1.922-0.563,3.768-1.666,5.708-2.097c3.18-0.705,2.274,2.68,0.847,4.213
                        c-0.164,0.176-0.342,0.337-0.511,0.508c4.57-1.921,7.735-5.565,11.326-14.07c0.18-0.426,0.343-0.894,0.498-1.38
                        C611.591,236.953,610.104,238.96,608.071,239.94z"></path>
            </g>
      </g>
      </g>
      </svg>
      `,
      "motorcycle.svg": `
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="244.299 55.926 167.931 290.96">
      <g style="" transform="matrix(0.290505, 0, 0, 0.335362, 178.005739, 30.540568)">
            <path fill="#738B93" d="M 617.35 172.145 C 627.028 170.194 637.024 172.38 646.726 173.325 L 646.726 176.034 C 636.362 174.659 627.968 173.321 617.35 173.325 L 617.35 172.145 Z"></path>
            <path d="M 651 162.91 C 652.572 151.701 654.254 149.743 665.21 153.35 C 662.348 159.548 660.388 166.72 658.648 173.325 C 666.832 174.878 723.742 191.546 727.8 195.903 C 724.964 201.9 721.164 204.118 714.672 205.628 C 704.982 203.078 651.85 188.504 644.654 184.103 C 643.804 181.563 645.7 178.355 646.726 176.034 L 646.726 173.325 C 647.828 169.762 649.314 166.234 651 162.91 Z" style=""></path>
            <path d="M 665.21 153.35 C 677.664 157.689 690.39 161.574 703.034 165.34 C 721.038 170.703 738.53 170.95 727.8 195.903 C 723.742 191.546 666.832 174.878 658.648 173.325 C 660.388 166.72 662.348 159.548 665.21 153.35 Z" style=""></path>
            <path d="M 377.13 176.034 C 384.09 192.376 373.104 185.899 363.632 188.827 L 321.666 202.242 C 315.318 204.246 307.298 210.38 302.468 202.39 C 300.178 198.605 294.004 181.274 295.232 177.177 C 296.886 171.667 342.38 159.781 349.436 157.362 C 362.862 152.76 363.822 142.213 371.262 159.972 C 372.996 164.58 374.75 169 377.13 173.325 C 377.166 174.167 377.35 175.227 377.13 176.034 Z" style=""></path>
            <path d="M 372.494 349.362 L 373.46 349.146 C 376.158 350.932 406.926 411.982 416.206 425.304 C 400.94 447.79 387.648 472.152 372.494 494.136 L 372.494 349.362 Z" style=""></path>
            <path d="M 449.27 716.42 C 449.638 713.146 451.594 715.204 453.49 716.42 C 457.926 716.662 463.286 715.668 467.53 716.42 C 471.136 715.45 484.484 716.398 489.188 716.404 L 555.51 716.42 L 555.51 744.31 C 555.542 751.988 557.07 777.046 553.886 783.064 C 552.864 788.702 553.842 800.486 553.854 806.692 L 553.896 868.306 C 553.746 937.038 479.782 929.526 471.15 887.22 C 468.714 875.288 469.994 860.778 469.998 848.466 L 470.012 799.574 C 470.002 794.662 471.004 785.066 467.53 781.46 C 464.028 787.412 471.62 795.714 462.814 799.62 C 457.566 801.948 450.958 800.59 449.27 794.75 C 448.536 790.406 449.214 784.91 449.27 780.462 C 449.49 765.646 449.466 750.754 449.27 735.936 C 449.31 729.43 449.382 722.924 449.27 716.42 Z" style=""></path>
            <path fill="#ACC7D2" d="M 449.27 716.42 C 449.638 713.146 451.594 715.204 453.49 716.42 C 457.926 716.662 463.286 715.668 467.53 716.42 L 467.524 767.75 C 467.506 770.72 465.722 779.378 467.53 781.46 C 464.028 787.412 471.62 795.714 462.814 799.62 C 457.566 801.948 450.958 800.59 449.27 794.75 C 448.536 790.406 449.214 784.91 449.27 780.462 C 449.49 765.646 449.466 750.754 449.27 735.936 C 449.31 729.43 449.382 722.924 449.27 716.42 Z"></path>
            <path fill="#E8E5E4" d="M 449.27 716.42 C 449.638 713.146 451.594 715.204 453.49 716.42 L 453.506 769.686 C 453.52 776.152 455.648 788.996 453.102 794.766 C 451.762 794.512 451.026 793.448 449.748 793.792 L 449.27 794.75 C 448.536 790.406 449.214 784.91 449.27 780.462 C 449.49 765.646 449.466 750.754 449.27 735.936 C 449.31 729.43 449.382 722.924 449.27 716.42 Z"></path>
            <path fill="#384752" d="M 545.57 846.904 C 544.288 865.564 548.426 878.648 532.288 892.496 C 505.188 915.748 473.634 885.366 477.852 851.412 C 485.264 860.986 490.762 870.196 503.184 873.446 C 521.756 878.306 540.966 865.19 545.57 846.904 Z"></path>
            <path fill="#5B6A72" d="M 477.852 851.412 C 478.546 842.948 477.434 833.94 477.852 825.262 C 498.104 855.146 525.264 852.66 545.57 825.262 L 545.57 846.904 C 540.966 865.19 521.756 878.306 503.184 873.446 C 490.762 870.196 485.264 860.986 477.852 851.412 Z"></path>
            <path fill="#1D2E34" d="M 467.53 716.42 C 471.136 715.45 484.484 716.398 489.188 716.404 L 555.51 716.42 L 555.51 744.31 C 533.246 748.176 517.222 751.742 493.658 748.924 C 484.866 747.874 476.404 744.796 467.53 744.31 C 467.472 735.098 468.244 725.586 467.53 716.42 Z"></path>
            <path fill="#5B6A72" d="M 477.852 785.326 C 505.316 786.184 515.588 788.672 545.57 785.326 C 546.024 801.242 545.864 813.974 536.022 827.19 C 522.264 842.212 503.3 843.208 488.776 828.694 C 476.382 816.31 477.78 801.602 477.852 785.326 Z"></path>
            <path d="M 416.206 425.304 C 417.36 427.314 418.666 429.328 419.554 431.47 C 434.18 450.76 448.896 468.232 470.074 481 C 474.464 483.646 478.866 484.09 481.606 485.74 C 446.376 499.976 434.146 514.842 434.984 552.366 L 434.984 563.934 C 440.348 568.54 434.99 606.374 436.578 618.224 C 436.664 625.568 436.612 632.912 436.578 640.258 C 436.078 643.424 436.314 646.778 436.578 649.962 C 435.12 666.658 429.16 693.534 441.31 707.428 C 444.104 710.626 448.854 711.808 449.27 716.42 C 449.382 722.924 449.31 729.43 449.27 735.936 C 449.466 750.754 449.49 765.646 449.27 780.462 C 411.098 764.14 389.23 719.768 379.738 681.876 C 370.356 657.828 372.488 596.354 372.484 569.764 L 372.494 494.136 C 387.648 472.152 400.94 447.79 416.206 425.304 Z" style="fill: #9cba73;"></path>
            <path d="M 419.554 431.47 C 434.18 450.76 448.896 468.232 470.074 481 C 474.464 483.646 478.866 484.09 481.606 485.74 C 446.376 499.976 434.146 514.842 434.984 552.366 L 434.984 563.934 C 440.348 568.54 434.99 606.374 436.578 618.224 C 436.664 625.568 436.612 632.912 436.578 640.258 C 436.078 643.424 436.314 646.778 436.578 649.962 C 435.12 666.658 429.16 693.534 441.31 707.428 C 444.104 710.626 448.854 711.808 449.27 716.42 C 449.382 722.924 449.31 729.43 449.27 735.936 C 449.466 750.754 449.49 765.646 449.27 780.462 C 411.098 764.14 389.23 719.768 379.738 681.876 L 380.11 681.422 C 381.022 681.194 414.892 692.766 420.486 694.462 L 419.54 456.526 C 419.522 448.21 418.876 439.754 419.554 431.47 Z" style="fill: #9cba73;"></path>
            <path fill="#000304" d="M 434.984 563.934 C 440.348 568.54 434.99 606.374 436.578 618.224 C 436.664 625.568 436.612 632.912 436.578 640.258 C 436.078 643.424 436.314 646.778 436.578 649.962 L 434.984 649.962 L 434.984 563.934 Z"></path>
            <path fill="#1D2E34" d="M 449.27 735.936 C 432.38 736.228 432.716 666.606 434.984 649.962 L 436.578 649.962 C 435.12 666.658 429.16 693.534 441.31 707.428 C 444.104 710.626 448.854 711.808 449.27 716.42 C 449.382 722.924 449.31 729.43 449.27 735.936 Z"></path>
            <path d="M 461.09 104.99 C 471.02 91.935 558.624 96.023 563.668 104.99 C 565.402 107.724 571.36 110.201 574.172 112.288 C 589.498 123.663 597.408 142.26 607.512 158.041 C 624.832 160.136 628.49 156.782 651 162.91 C 649.314 166.234 647.828 169.762 646.726 173.325 C 637.024 172.38 627.028 170.194 617.35 172.145 C 585.1 180.104 568.232 211.212 557.088 239.972 C 555.518 244.378 553.838 248.708 552.05 253.03 C 543.278 262.446 531.098 259.544 519.382 259.57 C 505.682 259.602 483.524 263.426 474.042 253.03 C 471.708 248.774 469.656 244.336 467.53 239.972 C 458.912 204.758 433.612 172.831 395.69 170.403 L 395.69 172.145 C 389.134 172.613 383.122 173.134 377.13 176.034 C 377.35 175.227 377.166 174.167 377.13 173.325 C 374.75 169 372.996 164.58 371.262 159.972 C 384.136 152.337 404.774 153.797 418.546 158.041 C 431.086 134.388 437.394 119.935 461.09 104.99 Z" style="fill: #b7ce96;"></path>
            <path fill="#E8E5E4" d="M 487.154 205.628 C 494.456 174.266 533.69 174.487 539.724 207.508 C 534.236 207.228 530.568 198.087 526.442 194.684 C 522.886 191.753 518.248 189.94 514.418 187.314 L 513.91 186.958 C 494.99 191.916 493.886 205.554 488.744 207.348 C 488.07 207.582 488.184 207.424 487.488 207.008 L 487.154 205.628 Z"></path>
            <path fill="#E1D2BA" d="M 513.91 186.958 L 514.418 187.314 C 518.248 189.94 522.886 191.753 526.442 194.684 C 530.568 198.087 534.236 207.228 539.724 207.508 C 541.672 245.708 483.634 246.366 487.154 205.628 L 487.488 207.008 C 488.184 207.424 488.07 207.582 488.744 207.348 C 493.886 205.554 494.99 191.916 513.91 186.958 Z"></path>
            <path d="M 371.262 159.972 C 384.136 152.337 404.774 153.797 418.546 158.041 C 419.446 158.811 419.806 158.778 420.948 159.038 C 467.814 169.708 474.94 238.484 491.268 242.592 C 496.956 244.024 532.908 244.346 537.266 241.808 C 551.78 233.362 553.246 177.937 607.512 158.041 C 624.832 160.136 628.49 156.782 651 162.91 C 649.314 166.234 647.828 169.762 646.726 173.325 C 637.024 172.38 627.028 170.194 617.35 172.145 C 585.1 180.104 568.232 211.212 557.088 239.972 C 555.518 244.378 553.838 248.708 552.05 253.03 C 543.278 262.446 531.098 259.544 519.382 259.57 C 505.682 259.602 483.524 263.426 474.042 253.03 C 471.708 248.774 469.656 244.336 467.53 239.972 C 458.912 204.758 433.612 172.831 395.69 170.403 L 395.69 172.145 C 389.134 172.613 383.122 173.134 377.13 176.034 C 377.35 175.227 377.166 174.167 377.13 173.325 C 374.75 169 372.996 164.58 371.262 159.972 Z" style=""></path>
            <path fill="#738B93" d="M 377.13 173.325 C 383.156 170.206 389.028 169.394 395.69 170.403 L 395.69 172.145 C 389.134 172.613 383.122 173.134 377.13 176.034 C 377.35 175.227 377.166 174.167 377.13 173.325 Z"></path>
            <path d="M 461.09 104.99 C 471.02 91.935 558.624 96.023 563.668 104.99 C 559.332 118.844 560.088 162.108 545.256 165.038 C 537.806 166.51 490.31 166.818 482.518 165.56 C 468.68 163.325 469.708 146.706 467.534 135.875 C 465.466 125.57 462.88 115.345 461.09 104.99 Z" style=""></path>
            <path d="M 481.606 485.74 C 488.02 487.234 531.436 486.044 539.724 485.74 C 558.568 491.372 574.794 501.942 584.362 519.602 C 594.014 537.418 589.822 565.178 590.584 586.336 C 591.026 598.584 591.454 618.172 590.554 629.78 C 590.83 633.21 590.916 636.834 590.554 640.258 C 590.458 641.924 590.388 643.524 589.994 645.152 L 589.3 645.47 L 588.506 645.048 C 588.936 648.436 589.28 651.85 588.506 655.22 C 590.874 661.102 590.362 665.332 591.912 670.436 C 592.888 690.748 592.572 704.848 574.728 716.42 L 555.51 716.42 L 489.188 716.404 C 484.484 716.398 471.136 715.45 467.53 716.42 C 463.286 715.668 457.926 716.662 453.49 716.42 C 451.594 715.204 449.638 713.146 449.27 716.42 C 448.854 711.808 444.104 710.626 441.31 707.428 C 429.16 693.534 435.12 666.658 436.578 649.962 C 436.314 646.778 436.078 643.424 436.578 640.258 C 436.612 632.912 436.664 625.568 436.578 618.224 C 434.99 606.374 440.348 568.54 434.984 563.934 L 434.984 552.366 C 434.146 514.842 446.376 499.976 481.606 485.74 Z" style="fill: #b7ce96;"></path>
            <path fill="#28353E" d="M 434.984 552.366 L 435.718 554.23 C 436.636 553.794 436.734 553.03 437.174 552.144 L 437.604 552.366 C 436.214 572.454 438.96 600.464 436.578 618.224 C 434.99 606.374 440.348 568.54 434.984 563.934 L 434.984 552.366 Z"></path>
            <path fill="#384752" d="M 481.606 485.74 C 488.02 487.234 531.436 486.044 539.724 485.74 C 558.568 491.372 574.794 501.942 584.362 519.602 C 594.014 537.418 589.822 565.178 590.584 586.336 C 591.026 598.584 591.454 618.172 590.554 629.78 C 590.83 633.21 590.916 636.834 590.554 640.258 C 590.458 641.924 590.388 643.524 589.994 645.152 L 589.3 645.47 L 588.506 645.048 L 588.506 636.68 C 588.172 612.282 591.41 567.334 587.528 544.88 C 583.868 523.71 563.084 503.158 542.81 498.144 C 532.994 495.714 491.052 495.924 480.862 497.898 C 449.32 504.006 444.088 526.43 437.784 551.66 L 437.604 552.366 L 437.174 552.144 C 436.734 553.03 436.636 553.794 435.718 554.23 L 434.984 552.366 C 434.146 514.842 446.376 499.976 481.606 485.74 Z"></path>
            <path fill="#28353E" d="M 588.506 636.68 C 590.096 627.408 588.698 616.98 590.472 629.25 L 590.554 629.78 C 590.83 633.21 590.916 636.834 590.554 640.258 C 590.458 641.924 590.388 643.524 589.994 645.152 L 589.3 645.47 L 588.506 645.048 L 588.506 636.68 Z"></path>
            <path d="M 436.578 649.962 C 436.314 646.778 436.078 643.424 436.578 640.258 C 436.996 653.548 435.344 674.272 446.094 684.276 C 464.122 701.054 562.172 703.494 581 683.064 C 588.44 674.994 587.864 665.31 588.506 655.22 C 590.874 661.102 590.362 665.332 591.912 670.436 C 592.888 690.748 592.572 704.848 574.728 716.42 L 555.51 716.42 L 489.188 716.404 C 484.484 716.398 471.136 715.45 467.53 716.42 C 463.286 715.668 457.926 716.662 453.49 716.42 C 451.594 715.204 449.638 713.146 449.27 716.42 C 448.854 711.808 444.104 710.626 441.31 707.428 C 429.16 693.534 435.12 666.658 436.578 649.962 Z" style=""></path>
            <path d="M 395.69 170.403 C 433.612 172.831 458.912 204.758 467.53 239.972 C 469.656 244.336 471.708 248.774 474.042 253.03 C 483.524 263.426 505.682 259.602 519.382 259.57 C 531.098 259.544 543.278 262.446 552.05 253.03 C 553.838 248.708 555.518 244.378 557.088 239.972 C 568.232 211.212 585.1 180.104 617.35 172.145 L 617.35 173.325 L 616.494 174.164 C 617.464 178.003 624.168 186.124 626.634 190.158 C 647.272 223.934 669.566 288.48 661.456 329.35 C 659.736 338.016 654.15 344.822 651.798 353.09 C 652.91 366.888 651.76 380.986 651.798 394.838 C 652.168 408.694 651.808 422.63 651.804 436.496 L 651.808 581.822 C 651.804 604.826 652.132 654.952 644.286 676.38 C 634.392 718.556 613.376 758.15 574.728 780.462 C 573.052 782.482 573.678 791.714 574.728 794.75 C 571.716 799.398 567.582 801.502 561.91 800.262 C 553.398 798.4 557.958 788.294 553.886 783.064 C 557.07 777.046 555.542 751.988 555.51 744.31 L 555.51 716.42 L 574.728 716.42 C 592.572 704.848 592.888 690.748 591.912 670.436 C 590.362 665.332 590.874 661.102 588.506 655.22 C 589.28 651.85 588.936 648.436 588.506 645.048 L 589.3 645.47 L 589.994 645.152 C 590.388 643.524 590.458 641.924 590.554 640.258 C 590.916 636.834 590.83 633.21 590.554 629.78 C 591.454 618.172 591.026 598.584 590.584 586.336 C 589.822 565.178 594.014 537.418 584.362 519.602 C 574.794 501.942 558.568 491.372 539.724 485.74 C 531.436 486.044 488.02 487.234 481.606 485.74 C 478.866 484.09 474.464 483.646 470.074 481 C 448.896 468.232 434.18 450.76 419.554 431.47 C 418.666 429.328 417.36 427.314 416.206 425.304 C 406.926 411.982 376.158 350.932 373.46 349.146 L 372.494 349.362 C 371.212 342.09 366.32 336.206 364.784 328.966 C 362.874 319.948 364.224 309.154 365.06 299.994 C 369.522 251.066 383.906 213.474 410.426 173.325 C 405.574 172.498 400.61 172.067 395.69 172.145 L 395.69 170.403 Z" style="fill: #9cba73;"></path>
            <path fill="#384752" d="M 590.554 640.258 C 592.568 649.972 592.106 660.534 592.994 670.436 L 591.912 670.436 C 590.362 665.332 590.874 661.102 588.506 655.22 C 589.28 651.85 588.936 648.436 588.506 645.048 L 589.3 645.47 L 589.994 645.152 C 590.388 643.524 590.458 641.924 590.554 640.258 Z"></path>
            <path fill="#1D2E34" d="M 591.912 670.436 L 592.994 670.436 C 593.868 686.806 592.69 727.862 574.728 735.936 C 574.512 729.448 574.786 722.916 574.728 716.42 C 592.572 704.848 592.888 690.748 591.912 670.436 Z"></path>
            <path fill="#ACC7D2" d="M 555.51 716.42 L 574.728 716.42 C 574.786 722.916 574.512 729.448 574.728 735.936 L 574.704 765.152 C 574.688 770.2 574.386 775.44 574.728 780.462 C 573.052 782.482 573.678 791.714 574.728 794.75 C 571.716 799.398 567.582 801.502 561.91 800.262 C 553.398 798.4 557.958 788.294 553.886 783.064 C 557.07 777.046 555.542 751.988 555.51 744.31 L 555.51 716.42 Z"></path>
            <path fill="#E8E5E4" d="M 574.728 794.75 C 573.298 794.152 572.2 793.68 571.062 792.576 C 569.766 788.264 567.17 736.326 574.728 735.936 L 574.704 765.152 C 574.688 770.2 574.386 775.44 574.728 780.462 C 573.052 782.482 573.678 791.714 574.728 794.75 Z"></path>
            <path d="M 467.53 239.972 C 469.656 244.336 471.708 248.774 474.042 253.03 C 463.328 253.972 452.438 264.862 445.914 272.652 C 413.77 311.028 423.156 369.544 461.026 400.278 C 497.968 430.26 549.724 421.192 578.774 385.038 C 608.522 348.016 602.028 289.268 564.482 259.796 C 560.798 256.906 556.694 254.04 552.05 253.03 C 553.838 248.708 555.518 244.378 557.088 239.972 C 599.726 260.11 619.284 312.92 606.832 357.212 C 592.164 409.396 538.982 443.59 485.792 428.19 C 401.728 403.852 385.568 284.034 467.53 239.972 Z" style=""></path>
            <path d="M 612.22 422.478 C 613.228 419.45 615.242 416.766 616.8 414.002 L 626.906 396.076 C 630.574 389.57 647.478 355.1 651.798 353.09 C 652.91 366.888 651.76 380.986 651.798 394.838 C 652.168 408.694 651.808 422.63 651.804 436.496 L 651.808 581.822 C 651.804 604.826 652.132 654.952 644.286 676.38 C 643.302 675.99 609.234 692.502 602.984 694.462 L 602.992 461.566 C 603 453.398 603.904 444.446 602.984 436.384 C 606.304 431.904 608.222 426.516 612.22 422.478 Z" style="fill: #9cba73;"></path>
            <path d="M 612.22 422.478 C 613.228 419.45 615.242 416.766 616.8 414.002 L 626.906 396.076 C 630.574 389.57 647.478 355.1 651.798 353.09 C 652.91 366.888 651.76 380.986 651.798 394.838 C 651.226 406.014 651.826 417.4 651.826 428.604 L 651.798 496.692 C 647.246 489.878 644.32 481.264 640.426 473.966 C 631.216 456.704 621.39 439.782 612.22 422.478 Z" style=""></path>
            <path fill="#1D2E34" d="M 552.05 253.03 C 556.694 254.04 560.798 256.906 564.482 259.796 C 602.028 289.268 608.522 348.016 578.774 385.038 C 549.724 421.192 497.968 430.26 461.026 400.278 C 423.156 369.544 413.77 311.028 445.914 272.652 C 452.438 264.862 463.328 253.972 474.042 253.03 C 483.524 263.426 505.682 259.602 519.382 259.57 C 531.098 259.544 543.278 262.446 552.05 253.03 Z"></path>
            </g>
            <defs>
            <linearGradient id="gradient_0" gradientUnits="userSpaceOnUse" x1="175.98958" y1="101.04845" x2="161.18323" y2="79.173019" gradientTransform="matrix(2, 0, 0, 2, 0, 0)">
                  <stop offset="0" stop-color="#303947"></stop>
                  <stop offset="1" stop-color="#4C5D69"></stop>
            </linearGradient>
            </defs>
            <defs>
            <linearGradient id="gradient_1" gradientUnits="userSpaceOnUse" x1="179.02408" y1="319.93716" x2="258.29559" y2="284.58228" gradientTransform="matrix(2, 0, 0, 2, 0, 0)">
                  <stop offset="0" stop-color="#616F77"></stop>
                  <stop offset="1" stop-color="#6B8285"></stop>
            </linearGradient>
            </defs>
            <defs>
            <linearGradient id="gradient_2" gradientUnits="userSpaceOnUse" x1="256.00717" y1="121.07781" x2="256.59286" y2="53.280506" gradientTransform="matrix(2, 0, 0, 2, 0, 0)">
                  <stop offset="0" stop-color="#627A85"></stop>
                  <stop offset="1" stop-color="#78969D"></stop>
            </linearGradient>
            </defs>
      </svg>
      `,
      "truck.svg":`
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="545.592 169.221 237.213 499.486" style="enable-background:new 0 0 1130 800;">
      <g transform="matrix(0, 1, -1, 0, -116.847909, -37.628983)" style="transform-origin: 780.386px 450.998px;">
            <rect x="657.76" y="433.104" transform="matrix(6.123234e-17 -1 1 6.123234e-17 262.2495 1161.73)" style="fill:#4F4751;" width="108.459" height="33.273"></rect>
            <rect x="987.357" y="479.127" transform="matrix(6.123234e-17 -1 1 6.123234e-17 513.2598 1475.3398)" style="fill:#930000;" width="13.885" height="3.826"></rect>
            <rect x="987.357" y="419.399" transform="matrix(6.123234e-17 -1 1 6.123234e-17 572.9873 1415.6123)" style="fill:#930000;" width="13.885" height="3.826"></rect>
            <path style="fill:#190F0F;" d="M656.707,404.068v92.421c0,8.627-6.993,15.62-15.62,15.62H616.83
                        c-8.626,0-15.619-6.993-15.619-15.62v-92.421c0-8.626,6.993-15.62,15.619-15.62h24.257
                        C649.714,388.449,656.707,395.442,656.707,404.068z"></path>
            <path style="fill:#190F0F;" d="M799.626,391.535v115.692c0,4.2-3.405,7.605-7.605,7.605h-34.077c-4.2,0-7.604-3.405-7.604-7.605
                        V391.535c0-4.201,3.404-7.605,7.604-7.605h34.077C796.221,383.93,799.626,387.335,799.626,391.535z"></path>
            <path style="fill:#190F0F;" d="M977.87,391.535v115.692c0,4.2-3.404,7.605-7.604,7.605h-34.077c-4.2,0-7.605-3.405-7.605-7.605
                        V391.535c0-4.201,3.405-7.605,7.605-7.605h34.077C974.466,383.93,977.87,387.335,977.87,391.535z"></path>
            <path style="fill: rgb(239, 205, 65);" d="M 561.16 475.561 L 561.171 490.822 C 561.178 499.809 571.857 508.446 580.845 508.442 L 583.991 508.441 L 583.968 478.248 L 561.16 475.561 Z"></path>
            <path style="fill:#9cba73;" d="M994.3,408.871v81.738c0,9.655-7.827,17.482-17.482,17.482h-251.46V391.389h251.46
                        C986.473,391.389,994.3,399.216,994.3,408.871z"></path>
            <path style="fill: #b7ce96;" d="M994.3,452.501v-43.63c0-9.655-7.827-17.482-17.482-17.482h-251.46v61.112H994.3z"></path>
            <path style="fill:#EFCD41;" d="M 560.647 424.092 L 560.658 408.831 C 560.665 399.844 571.344 391.207 580.332 391.211 L 583.478 391.212 L 583.455 421.405 L 560.647 424.092 Z"></path>
            <path style="fill:#9cba73;" d="M686.281,391.389v116.703H590.07c-9.695,0-25.511-12.216-25.511-22.528v-74.357
                        c0-9.222,14.984-19.817,23.654-19.817H686.281z"></path>
            <path style="fill:#b7ce96;" d="M564.56,411.206v6.742c0-9.223,14.984-19.818,23.654-19.818h98.067v-6.741h-98.067
                        C579.544,391.389,564.56,401.984,564.56,411.206z"></path>
            <path style="fill:#323530;" d="M610.146,396.628l4.727,1.407l27.007,8.015v86.898l-31.733,9.192
                        c-1.998-7.353-3.537-14.421-4.699-21.143C596.373,428.716,610.146,396.628,610.146,396.628z"></path>
            <path style="fill:#646663;" d="M610.146,396.628l4.727,1.407c6.157,6.205,10.56,22.091,10.56,40.706
                        c0,23.986-7.292,43.435-16.258,43.435c-1.297,0-2.539-0.402-3.728-1.178C596.373,428.716,610.146,396.628,610.146,396.628z"></path>
            <path style="fill:#9cba73;" d="M687.98,398.862h-41.154c-5.317,0-9.628,4.586-9.628,10.242v80.063
                        c0,6.325,4.82,11.452,10.767,11.452l39.851-2.587L687.98,398.862z"></path>
            <path style="opacity:0.44;fill:#b7ce96;" d="M690.977,414.226l-0.984,68.835l-35.212,2.194c-5.617,0-10.171-3.579-10.171-7.994
                        v-55.887c0-3.947,4.071-7.148,9.095-7.148H690.977z"></path>
            <polygon style="fill:#323530;" points="631.106,504.928 656.935,495.591 686.281,495.591 686.281,504.827 	"></polygon>
            <polygon style="fill:#323530;" points="631.106,394.33 656.935,402.947 686.282,402.947 686.282,394.422 	"></polygon>
            <path style="fill:#b7ce96;" d="M573.787,492.745l27.424-7.49c0,0-6.494-31.386-1.317-63.31l2.46-8.704l-28.566-7.421
                        C573.787,405.82,563.659,436.994,573.787,492.745z"></path>
            <path d="M640.179,505.685v10.419c0,1.95-1.595,3.546-3.545,3.546l0,0c-1.949,0-3.545-1.596-3.545-3.546v-10.419
                        c0-1.949,1.596-3.545,3.545-3.545l0,0C638.584,502.141,640.179,503.736,640.179,505.685z"></path>
            <path d="M640.179,385.89v10.419c0,1.95-1.595,3.545-3.545,3.545l0,0c-1.949,0-3.545-1.595-3.545-3.545V385.89
                        c0-1.95,1.596-3.545,3.545-3.545l0,0C638.584,382.345,640.179,383.94,640.179,385.89z"></path>
            <g></g>
            <path style="fill:#191719;" d="M695.672,396.015v107.451c0,2.555-2.071,4.626-4.626,4.626h-4.765V391.389h4.765
                        C693.601,391.389,695.672,393.46,695.672,396.015z"></path>
            <path style="" d="M669.628,481.472c0,5.414,4.584,9.803,10.24,9.803h6.413v-35.117h-16.653V481.472z"></path>
            <rect x="669.628" y="440.431" style="fill:#657A79;" width="16.653" height="15.726"></rect>
            <path style="" d="M679.868,404.668c-5.656,0-10.24,4.389-10.24,9.803v25.96h16.653v-35.763H679.868z"></path>
            <path style="opacity: 0.19;" d="M679.305,462.097v19.792c0,1.732-1.404,3.138-3.138,3.138h-0.139
                        c-1.732,0-3.138-1.405-3.138-3.138v-19.792c0-1.733,1.405-3.138,3.138-3.138h0.139C677.9,458.959,679.305,460.363,679.305,462.097z
                        "></path>
            <path style="opacity: 0.19;" d="M679.305,415.4v19.792c0,1.733-1.404,3.138-3.138,3.138h-0.139
                        c-1.732,0-3.138-1.404-3.138-3.138V415.4c0-1.733,1.405-3.138,3.138-3.138h0.139C677.9,412.263,679.305,413.667,679.305,415.4z"></path>
            </g>
      </svg>
      `,
      "box-truck.svg":`
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="336.246 24.514 160.015 349.417" style="enable-background:new 0 0 500 500;">
            <g id="objects" style="" transform="matrix(1, 0, 0, 0.716604, 0, 13.797111)">
            <g>
                  <g>
                  <g>
                  <path style="fill:#9cba73;" d="M370.351,171.112h93.073v-65.124c0,0,1.233-4.315,1.233-8.629c0-4.315,0-22.478,0-22.478
                                                            l-2.157-3.082c0,0,2.774-23.114-45.612-23.114c-48.386,0-45.612,23.114-45.612,23.114l-2.157,3.082c0,0,0,18.163,0,22.478
                                                            c0,4.314,1.233,8.629,1.233,8.629V171.112z"></path>
                  <path style="fill:#9cba73;" d="M377.523,65.243c-0.208,1.197-0.251,2.413-0.315,3.623l-0.113,3.636
                                                            c-0.054,2.425-0.077,4.849-0.064,7.275c-0.047,4.848,0.065-0.798,0.099,4.051l0.509,29.098l-2.233-2.233l20.741-0.288
                                                            l20.741-0.102l20.741,0.099l20.741,0.285l-2.239,2.239l0.508-29.098c0.032-4.849,0.147,0.797,0.101-4.051
                                                            c0.012-2.426-0.009-4.85-0.063-7.275l-0.112-3.636c-0.063-1.21-0.106-2.426-0.313-3.623c0.358,1.166,0.556,2.374,0.774,3.573
                                                            c0.208,1.202,0.397,2.407,0.557,3.616c0.309,2.417,0.59,4.839,0.829,7.263c0.439,4.851,0.846-0.792,1.101,4.067
                                                            c0.266,4.859,0.546,9.719,0.684,14.58l0.411,14.584l0.062,2.207l-2.301,0.032l-20.741,0.285l-20.741,0.099l-20.741-0.102
                                                            l-20.741-0.288l-2.296-0.032l0.062-2.202l0.413-14.584c0.138-4.861,0.421-9.721,0.684-14.58c0.257-4.859,0.66,0.784,1.099-4.067
                                                            c0.24-2.424,0.519-4.846,0.827-7.263c0.161-1.209,0.348-2.414,0.556-3.616C376.968,67.617,377.165,66.408,377.523,65.243z"></path>
                  <path style="fill: rgb(59, 59, 59);" d="M458.369,171.112v-58.186c0,0-13.412-12.904-41.482-12.904c-28.07,0-41.482,12.904-41.482,12.904
                                                            v58.186H458.369z"></path>
                  <g>
                        <path style="fill:#F2F2F2;" d="M375.406,155.343v10.64l63.087-63.087c-2.642-0.751-5.571-1.391-8.755-1.886L375.406,155.343z"></path>
                        <path style="fill:#F2F2F2;" d="M381.373,171.112l65.293-65.293c-1.116-0.488-2.302-0.968-3.581-1.435l-66.728,66.728H381.373z"></path>
                        <polygon style="fill:#F2F2F2;" points="448.406,171.112 458.369,161.149 458.369,150.509 437.766,171.112 					"></polygon>
                  </g>
                  <path style="fill:#9cba73;" d="M384.228,171.112h65.32v-31.284l9.027-22.746l-0.205-4.156l-1.866-1.527l-10.18,24.346
                                                            c0,0-8.147-5.249-29.436-5.249c-21.29,0-29.436,5.249-29.436,5.249l-10.18-24.346l-1.866,1.527l-0.205,4.156l9.027,22.746
                                                            V171.112z"></path>
                  <path style="fill:#b7ce96;" d="M377.272,111.399c0,0-14.376-2.085-14.376,6.802h14.376V111.399z"></path>
                  <path style="fill:#b7ce96;" d="M456.503,111.399c0,0,14.376-2.085,14.376,6.802h-14.376V111.399z"></path>
                  <path style="fill:#b7ce96;" d="M377.749,64.878c0,0,0.144-0.293,0.542-0.756c0.39-0.466,1.017-1.117,1.938-1.791
                                                            c0.912-0.683,2.087-1.434,3.514-2.132c1.415-0.726,3.079-1.412,4.941-2.008c1.859-0.599,3.904-1.164,6.094-1.612
                                                            c1.091-0.258,2.219-0.47,3.379-0.647c1.157-0.191,2.342-0.397,3.554-0.504c1.211-0.127,2.443-0.257,3.689-0.388
                                                            c1.25-0.08,2.514-0.161,3.789-0.242c2.55-0.192,5.143-0.166,7.737-0.259c2.617,0.09,5.156,0.066,7.727,0.257
                                                            c1.278,0.082,2.546,0.163,3.799,0.243c1.247,0.131,2.478,0.261,3.689,0.388c1.212,0.108,2.397,0.31,3.555,0.502
                                                            c1.16,0.177,2.288,0.389,3.379,0.647c2.19,0.448,4.236,1.013,6.095,1.613c1.862,0.596,3.527,1.28,4.941,2.008
                                                            c1.427,0.699,2.602,1.45,3.514,2.133c0.921,0.675,1.547,1.326,1.937,1.792c0.397,0.463,0.541,0.757,0.541,0.757
                                                            s-0.173-0.277-0.611-0.692c-0.427-0.425-1.118-0.977-2.062-1.576c-0.943-0.6-2.144-1.244-3.591-1.82
                                                            c-1.436-0.598-3.11-1.139-4.954-1.657c-1.848-0.501-3.877-0.962-6.05-1.302c-1.08-0.203-2.198-0.36-3.349-0.481
                                                            c-1.15-0.117-2.309-0.337-3.511-0.413c-1.196-0.107-2.413-0.216-3.644-0.327c-1.233-0.06-2.48-0.12-3.738-0.181
                                                            c-2.499-0.152-5.127-0.084-7.667-0.141c-2.564,0.054-5.137-0.012-7.657,0.139c-1.261,0.061-2.512,0.122-3.748,0.182
                                                            c-1.231,0.11-2.447,0.22-3.644,0.327c-1.201,0.078-2.361,0.293-3.511,0.411c-1.15,0.121-2.269,0.278-3.349,0.481
                                                            c-2.173,0.34-4.202,0.801-6.05,1.303c-1.843,0.518-3.519,1.058-4.954,1.657c-1.447,0.576-2.648,1.221-3.591,1.821
                                                            c-0.944,0.6-1.635,1.152-2.062,1.577C377.922,64.601,377.749,64.878,377.749,64.878z"></path>
                  <path style="fill:#F2F2F2;" d="M376.737,59.429c0,0,11.852-1.959,18.544-1.959c6.691,0,5.216-6.936-0.609-6.642
                                                            C388.847,51.122,380.841,55.297,376.737,59.429z"></path>
                  <path style="fill:#F2F2F2;" d="M457.038,59.429c0,0-11.852-1.959-18.544-1.959c-6.691,0-5.216-6.936,0.609-6.642
                                                            C444.928,51.122,452.934,55.297,457.038,59.429z"></path>
                  </g>
                  <rect x="370.351" y="171.112" style="fill:#9cba73;" width="93.111" height="10.14"></rect>
                  </g>
                  <path style="fill:#b7ce96;" d="M 467.136 455.669 L 364.364 456.642 C 360.475 456.642 360.735 452.002 360.735 448.678 L 359.597 187.272 C 359.597 183.948 362.75 181.253 366.639 181.253 L 467.136 181.253 C 471.025 181.253 474.178 183.948 474.178 187.272 L 473.609 449.65 C 473.61 452.974 471.026 455.669 467.136 455.669 Z"></path>
                  <rect x="287.689" y="265.517" transform="matrix(0, 1, -1, 0, 732.504278, -96.781701)" style="fill:#9cba73;" width="252.568" height="100.199"></rect>
                  <rect x="242.398" y="313.629" transform="matrix(0, 1, -1, 0, 684.392217, -53.954117)" style="fill: rgb(204, 204, 204);" width="255.184" height="3.975"></rect>
                  <rect x="337.324" y="385.25" transform="matrix(0, 1, -1, 0, 852.236457, -148.881395)" style="fill: rgb(204, 204, 204);" width="253.968" height="4.883"></rect>
            </g>
            </g>
      </svg>
      `,
      "tractor.svg": `
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:v="https://svgstorm.com" viewBox="26.186 -204.248 686.464 1211.29">
      <g style="" transform="matrix(1.579403, 0, 0, 1.730596, -91.817809, -217.405072)">
            <g fill="None" fill-opacity="0.0" stroke="#C7D3E3" stroke-opacity="1.00" stroke-width="0.3">
                        <path d="
                  M 191.00 73.00 
                  C 188.65 92.64 188.97 118.77 216.00 112.00
                  C 216.26 109.65 214.97 105.93 218.00 105.00
                  C 221.67 105.00 225.33 105.00 229.00 105.00
                  C 229.50 105.00 230.00 105.00 230.00 105.00
                  C 233.07 88.76 231.54 70.16 236.00 55.00
                  C 274.17 55.67 313.65 53.67 351.00 56.00
                  C 353.50 71.71 353.72 88.91 356.00 105.00
                  C 360.00 105.00 364.00 105.00 368.00 105.00
                  C 371.03 105.98 369.70 109.64 370.00 112.00
                  C 379.68 113.41 387.80 110.69 396.00 107.00
                  C 398.49 89.76 388.37 63.25 408.00 55.00
                  C 389.00 60.59 399.94 92.60 373.02 77.98 C 345.91 83.66 362.21 44.47 334.00 54.00 C 313.00 54.00 292.00 54.00 271.00 54.00 C 251.61 53.64 222.85 48.24 231.00 77.00
                  C 218.22 80.52 203.44 76.93 191.00 73.00 Z"></path>
                  </g>
                  <g fill="#2F3951" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 148.00 69.00 
                  C 148.00 103.67 148.00 138.33 148.00 173.00
                  C 147.81 178.14 152.42 183.28 157.00 185.00
                  C 161.94 186.77 167.67 185.72 173.00 186.00
                  C 170.67 143.17 172.67 99.25 172.00 56.00
                  C 162.05 54.29 148.91 57.63 148.00 69.00 Z"></path>
                  </g>
                  <g fill="#151B2F" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 173.00 186.00 
                  C 180.34 186.54 186.26 182.24 189.00 176.00
                  C 189.08 175.51 189.00 175.00 189.00 175.00
                  C 189.00 163.33 189.00 151.67 189.00 140.00
                  C 191.88 137.52 189.94 136.48 189.00 134.00
                  C 189.94 133.43 190.82 132.85 192.00 133.00
                  C 199.23 129.22 207.20 128.10 216.00 129.00
                  C 219.45 138.70 216.41 153.74 219.00 166.00
                  C 221.00 166.00 223.00 166.00 225.00 166.00
                  C 225.62 146.08 230.49 125.86 228.00 106.00
                  C 217.22 106.99 206.49 122.64 194.00 109.00
                  C 193.50 108.95 193.00 109.00 193.00 109.00
                  C 183.96 101.14 191.45 80.73 189.00 68.00
                  C 189.08 66.55 188.93 65.14 188.00 64.00
                  C 183.71 60.07 179.11 54.19 172.00 56.00
                  C 172.67 99.25 170.67 143.17 173.00 186.00 Z"></path>
                  </g>
                  <g fill="#28334D" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 350.00 56.00 
                  C 312.67 56.00 275.33 56.00 238.00 56.00
                  C 246.27 75.91 276.33 61.74 294.00 66.00 C 312.07 61.99 340.08 75.54 350.00 56.00 Z"></path>
                  </g>
                  <g fill="#161C30" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 390.00 110.00 
                  C 385.93 116.05 374.03 115.25 369.00 112.00
                  C 369.00 110.33 369.00 108.67 369.00 107.00
                  C 365.46 104.98 360.95 106.45 357.00 106.00
                  C 356.77 126.39 358.32 146.36 361.00 166.00
                  C 363.00 166.00 365.00 166.00 367.00 166.00
                  C 370.00 154.30 365.88 138.17 370.00 129.00
                  C 375.33 129.00 380.67 129.00 386.00 129.00
                  C 389.92 129.48 392.97 132.43 397.00 133.00
                  C 397.84 144.21 399.24 157.26 397.00 168.00
                  C 397.00 169.00 397.00 170.00 397.00 171.00
                  C 415.66 208.31 416.25 154.48 414.00 136.00 C 414.11 109.44 413.84 81.79 414.00 56.00
                  C 388.49 57.73 399.18 90.49 397.00 108.00
                  C 394.81 109.19 392.29 109.24 390.00 110.00 Z"></path>
                  </g>
                  <g fill="#2F3A51" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 397.00 171.00 
                  C 397.23 171.34 397.00 172.50 397.00 173.00
                  C 396.41 175.27 397.85 176.90 398.00 179.00
                  C 398.00 179.00 397.91 179.52 398.00 180.00
                  C 405.12 189.03 416.61 186.98 427.00 187.00
                  C 431.12 184.07 438.28 181.47 438.00 175.00
                  C 432.66 136.51 449.45 87.02 428.00 56.00
                  C 428.00 56.00 427.50 56.00 427.00 56.00
                  C 427.00 56.00 426.50 56.00 426.00 56.00
                  C 428.33 98.50 426.34 142.08 427.00 185.00
                  C 420.01 146.90 425.99 98.18 424.00 56.00
                  C 420.67 56.00 417.33 56.00 414.00 56.00
                  C 413.84 81.79 414.11 109.44 414.00 136.00 C 416.25 154.48 415.66 208.31 397.00 171.00 Z"></path>
                  </g>
                  <g fill="#111B32" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 426.00 56.00 
                  C 425.50 56.00 424.50 56.00 424.00 56.00
                  C 425.99 98.18 420.01 146.90 427.00 185.00
                  C 426.34 142.08 428.33 98.50 426.00 56.00 Z"></path>
                  </g>
                  <g fill="#475163" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 438.00 175.00 
                  C 439.69 139.65 439.64 101.69 438.00 66.00
                  C 437.26 61.15 432.31 57.40 428.00 56.00
                  C 449.45 87.02 432.66 136.51 438.00 175.00 Z"></path>
                  </g>
                  <g fill="#131D33" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 161.00 57.00 
                  C 158.33 96.94 156.34 147.56 162.00 185.00
                  C 161.33 142.42 163.33 99.17 161.00 57.00 Z"></path>
                  </g>
                  <g fill="#b7ce96" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 231.00 105.00 
                  C 233.43 119.79 229.77 135.82 228.00 150.00
                  C 227.35 155.16 229.31 161.66 227.00 166.00
                  C 226.76 166.32 227.00 167.00 227.00 167.00
                  C 224.79 218.94 219.29 270.06 217.00 322.00
                  C 219.69 323.01 224.99 322.41 224.00 327.00
                  C 226.48 323.51 231.21 329.88 235.00 330.00
                  C 232.52 268.92 239.23 205.46 240.00 142.00 C 248.13 116.99 224.03 59.59 266.00 68.00 C 291.35 74.28 349.23 50.84 342.00 91.00 C 344.23 123.43 345.65 156.09 346.00 189.00 C 344.93 219.39 353.48 259.15 345.00 284.00
                  C 348.21 294.01 360.53 333.65 338.00 329.00
                  C 343.01 305.63 314.52 314.06 301.00 314.00 C 284.40 315.88 264.52 310.17 250.00 317.00
                  C 265.28 312.48 282.82 316.23 299.00 315.00 C 312.05 317.78 341.30 306.10 336.00 329.00
                  C 340.85 331.15 346.73 329.46 352.00 330.00
                  C 348.45 242.35 349.51 153.11 341.00 67.00
                  C 307.18 61.68 262.51 78.07 236.00 58.00
                  C 236.00 58.00 235.00 57.96 235.00 58.00
                  C 234.58 73.83 233.51 90.04 231.00 105.00 Z"></path>
                  </g>
                  <g fill="#b7ce96" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 341.00 67.00 
                  C 349.51 153.11 348.45 242.35 352.00 330.00
                  C 346.73 329.46 340.85 331.15 336.00 329.00
                  C 344.09 334.98 354.44 328.68 362.00 325.00
                  C 362.96 322.61 365.91 322.68 368.00 322.00
                  C 370.38 292.47 363.98 262.12 364.00 232.00
                  C 364.00 231.00 364.00 230.00 364.00 229.00
                  C 361.10 208.86 360.11 186.22 359.00 166.00
                  C 358.75 146.15 354.11 125.42 357.00 106.00
                  C 360.95 106.45 365.46 104.98 369.00 107.00
                  C 369.09 106.11 368.13 105.80 368.00 105.00
                  C 364.00 105.00 360.00 105.00 356.00 105.00
                  C 350.72 92.83 353.23 74.52 351.00 60.00
                  C 346.78 60.44 346.20 67.94 341.00 67.00 Z"></path>
                  </g>
                  <g fill="#9cba73" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 235.00 330.00 
                  C 239.67 330.00 244.33 330.00 249.00 330.00
                  C 251.21 326.58 249.42 321.18 250.00 317.00
                  C 264.52 310.17 284.40 315.88 301.00 314.00 C 314.52 314.06 343.01 305.63 338.00 329.00
                  C 360.53 333.65 348.21 294.01 345.00 284.00
                  C 353.48 259.15 344.93 219.39 346.00 189.00 C 345.65 156.09 344.23 123.43 342.00 91.00 C 349.23 50.84 291.35 74.28 266.00 68.00 C 224.03 59.59 248.13 116.99 240.00 142.00 C 239.23 205.46 232.52 268.92 235.00 330.00 Z"></path>
                  </g>
                  <g fill="#585E6D" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 148.00 173.00 
                  C 148.00 138.33 148.00 103.67 148.00 69.00
                  C 146.33 102.53 146.33 139.40 148.00 173.00 Z"></path>
                  </g>
                  <g fill="#254973" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 225.00 166.00 
                  C 225.50 166.00 226.84 165.77 227.00 166.00
                  C 229.31 161.66 227.35 155.16 228.00 150.00
                  C 227.41 135.72 233.27 117.93 229.00 106.00
                  C 229.00 106.00 228.50 106.00 228.00 106.00
                  C 230.49 125.86 225.62 146.08 225.00 166.00 Z"></path>
                  </g>
                  <g fill="#6A7385" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 192.00 133.00 
                  C 191.43 133.94 190.85 134.82 191.00 136.00
                  C 199.36 134.75 210.23 138.55 217.00 134.00
                  C 210.45 135.05 200.39 136.89 195.00 133.00
                  C 197.31 133.45 198.07 130.47 200.00 130.00
                  C 205.00 130.00 210.00 130.00 215.00 130.00
                  C 215.80 130.13 216.11 131.09 217.00 131.00
                  C 216.87 130.20 215.91 129.89 216.00 129.00
                  C 207.20 128.10 199.23 129.22 192.00 133.00 Z"></path>
                  </g>
                  <g fill="#b7ce96" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 218.00 271.00 
                  C 218.00 272.33 218.00 273.67 218.00 275.00
                  C 219.87 291.53 211.50 308.47 217.00 324.00
                  C 226.88 325.77 221.68 339.66 223.00 347.00
                  C 203.87 354.49 157.82 334.02 164.00 367.00 C 164.00 394.33 164.00 421.67 164.00 449.00
                  C 164.00 449.50 164.00 450.00 164.00 450.00
                  C 164.00 458.00 164.00 466.00 164.00 474.00
                  C 164.00 515.33 164.00 556.67 164.00 598.00 C 157.50 645.83 214.80 638.75 246.00 636.00
                  C 250.79 632.15 257.35 631.57 263.00 629.00
                  C 257.37 626.31 264.03 628.06 266.00 626.00
                  C 227.28 627.02 161.82 637.46 179.00 577.00 C 179.00 509.00 179.00 441.00 179.00 373.00 C 177.16 347.40 217.03 365.34 234.00 360.00 C 279.67 360.00 325.33 360.00 371.00 360.00
                  C 366.32 338.98 399.27 353.53 411.22 348.22 C 431.97 347.97 417.89 378.88 422.00 393.00 C 422.66 411.58 420.68 430.86 423.00 449.00
                  C 423.26 448.75 424.00 449.00 424.00 449.00
                  C 423.75 448.75 424.00 448.00 424.00 448.00
                  C 424.00 429.67 424.00 411.33 424.00 393.00 C 419.75 379.12 433.55 348.13 415.00 347.00
                  C 414.50 347.00 413.47 346.89 413.00 347.00
                  C 408.94 348.44 404.08 348.40 400.00 347.00
                  C 399.56 346.84 399.00 347.00 399.00 347.00
                  C 387.00 347.00 375.00 347.00 363.00 347.00
                  C 362.97 348.10 361.66 347.81 361.00 348.00
                  C 347.86 349.95 331.46 349.20 319.00 348.00
                  C 318.00 348.00 317.00 348.00 316.00 348.00
                  C 315.50 348.00 315.00 348.00 315.00 348.00
                  C 307.41 349.65 296.52 349.68 289.00 348.00
                  C 288.50 348.00 288.00 348.00 288.00 348.00
                  C 267.49 349.00 243.34 351.00 224.00 347.00
                  C 224.00 340.67 224.00 334.33 224.00 328.00
                  C 224.00 327.50 224.00 327.00 224.00 327.00
                  C 224.99 322.41 219.69 323.01 217.00 322.00
                  C 219.29 270.06 224.79 218.94 227.00 167.00
                  C 226.00 167.00 225.00 167.00 224.00 167.00
                  C 224.78 202.29 221.42 237.10 218.00 271.00 Z"></path>
                  </g>
                  <g fill="#141A2D" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 257.00 347.00 
                  C 253.92 331.91 276.44 333.50 271.00 347.00
                  C 275.26 348.61 282.81 348.70 287.00 347.00
                  C 282.97 341.10 289.11 335.69 294.00 334.00
                  C 296.74 337.75 303.34 341.27 299.00 347.00
                  C 303.42 349.25 309.82 347.39 315.00 348.00
                  C 315.00 348.00 315.50 348.00 316.00 348.00
                  C 316.25 347.75 316.00 347.00 316.00 347.00
                  C 315.75 346.75 315.00 347.00 315.00 347.00
                  C 313.13 345.86 314.39 342.87 314.00 341.00
                  C 314.18 337.96 318.36 336.61 317.00 333.00
                  C 320.00 333.00 323.00 333.00 326.00 333.00
                  C 323.37 335.95 326.49 337.27 329.00 338.00
                  C 328.44 340.52 330.19 344.30 328.00 346.00
                  C 328.00 346.50 328.00 347.00 328.00 347.00
                  C 339.00 347.00 350.00 347.00 361.00 347.00
                  C 361.00 341.00 361.00 335.00 361.00 329.00
                  C 335.79 344.90 339.93 314.20 321.00 316.00 C 301.67 316.00 282.33 316.00 263.00 316.00 C 246.98 316.71 248.34 344.67 225.00 328.00
                  C 225.00 334.33 225.00 340.67 225.00 347.00
                  C 235.67 347.00 246.33 347.00 257.00 347.00 Z"></path>
                  </g>
                  <g fill="#20161D" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 314.00 341.00 
                  C 317.65 335.23 326.41 334.22 328.00 342.00
                  C 328.00 343.33 328.00 344.67 328.00 346.00
                  C 330.19 344.30 328.44 340.52 329.00 338.00
                  C 326.49 337.27 323.37 335.95 326.00 333.00
                  C 323.00 333.00 320.00 333.00 317.00 333.00
                  C 318.36 336.61 314.18 337.96 314.00 341.00 Z"></path>
                  </g>
                  <g fill="#3A2322" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 287.00 347.00 
                  C 284.13 334.95 302.10 334.88 299.00 347.00
                  C 303.34 341.27 296.74 337.75 294.00 334.00
                  C 289.11 335.69 282.97 341.10 287.00 347.00 Z"></path>
                  </g>
                  <g fill="#FDE9D6" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 173.00 342.00 
                  C 176.50 333.03 187.89 338.37 186.00 347.00
                  C 186.38 346.78 187.00 347.00 187.00 347.00
                  C 190.30 336.75 175.29 330.43 173.00 342.00 Z"></path>
                  </g>
                  <g fill="#3B2420" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 257.00 347.00 
                  C 257.00 347.00 258.00 347.01 258.00 347.00
                  C 255.18 334.67 273.82 334.65 271.00 347.00
                  C 276.44 333.50 253.92 331.91 257.00 347.00 Z"></path>
                  </g>
                  <g fill="#E1B194" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 173.00 342.00 
                  C 173.00 343.67 173.00 345.33 173.00 347.00
                  C 176.84 348.61 182.17 348.57 186.00 347.00
                  C 187.89 338.37 176.50 333.03 173.00 342.00 Z"></path>
                  </g>
                  <g fill="#6E4534" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 258.00 347.00 
                  C 258.32 346.76 259.00 347.00 259.00 347.00
                  C 256.38 335.88 272.76 335.73 270.00 347.00
                  C 270.38 346.78 271.00 347.00 271.00 347.00
                  C 273.82 334.65 255.18 334.67 258.00 347.00 Z"></path>
                  </g>
                  <g fill="#82523C" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 287.00 347.00 
                  C 287.32 346.76 288.00 347.00 288.00 347.00
                  C 285.00 336.10 301.24 335.95 298.00 347.00
                  C 298.32 346.76 299.00 347.00 299.00 347.00
                  C 302.10 334.88 284.13 334.95 287.00 347.00 Z"></path>
                  </g>
                  <g fill="#D2A58D" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 400.00 347.00 
                  C 404.08 348.40 408.94 348.44 413.00 347.00
                  C 416.38 334.18 397.08 334.80 400.00 347.00 Z"></path>
                  </g>
                  <g fill="#AD775B" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 259.00 347.00 
                  C 262.21 348.59 266.79 348.53 270.00 347.00
                  C 272.76 335.73 256.38 335.88 259.00 347.00 Z"></path>
                  </g>
                  <g fill="#D38252" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 288.00 347.00 
                  C 288.03 347.50 288.00 348.00 288.00 348.00
                  C 288.00 348.00 288.50 348.00 289.00 348.00
                  C 291.84 347.41 295.98 349.22 298.00 347.00
                  C 301.24 335.95 285.00 336.10 288.00 347.00 Z"></path>
                  </g>
                  <g fill="#AF775D" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 316.00 347.00 
                  C 316.00 347.00 316.25 347.75 316.00 348.00
                  C 317.00 348.00 318.00 348.00 319.00 348.00
                  C 321.72 347.83 324.67 348.61 327.00 347.00
                  C 329.96 335.77 313.03 335.80 316.00 347.00 Z"></path>
                  </g>
                  <g fill="#E58548" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 175.00 347.00 
                  C 193.57 350.24 175.05 329.28 175.00 347.00 Z"></path>
                  </g>
                  <g fill="#E48348" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 266.00 339.00 
                  C 249.33 344.09 276.47 352.65 266.00 339.00 Z"></path>
                  </g>
                  <g fill="#E38148" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 326.00 347.00 
                  C 326.21 329.70 307.88 350.17 326.00 347.00 Z"></path>
                  </g>
                  <g fill="#E1864A" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 411.00 347.00 
                  C 414.03 336.94 399.49 337.44 402.00 347.00
                  C 405.00 347.00 408.00 347.00 411.00 347.00 Z"></path>
                  </g>
                  <g fill="#b7ce96" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 371.00 360.00 
                  C 382.67 360.00 394.33 360.00 406.00 360.00
                  C 410.66 402.81 406.67 449.64 408.00 494.00 C 402.74 535.15 418.87 587.07 399.25 621.25 C 358.53 632.16 311.27 622.96 268.00 626.00
                  C 267.50 626.00 266.50 626.00 266.00 626.00
                  C 264.03 628.06 257.37 626.31 263.00 629.00
                  C 257.35 631.57 250.79 632.15 246.00 636.00
                  C 299.96 632.98 357.73 642.14 409.25 631.25 C 437.23 588.38 414.78 526.54 422.00 476.00
                  C 422.66 467.17 420.67 457.01 423.00 449.00
                  C 420.68 430.86 422.66 411.58 422.00 393.00 C 417.89 378.88 431.97 347.97 411.22 348.22 C 399.27 353.53 366.32 338.98 371.00 360.00 Z"></path>
                  </g>
                  <g fill="#B2D2F4" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 266.00 626.00 
                  C 266.50 626.00 267.50 626.00 268.00 626.00
                  C 311.30 617.68 366.43 638.07 404.00 615.00
                  C 365.90 631.77 318.19 619.01 276.00 623.00
                  C 248.25 623.67 218.20 626.21 191.00 622.00
                  C 166.75 589.59 186.31 537.33 180.00 497.00 C 180.00 451.67 180.00 406.33 180.00 361.00
                  C 243.50 361.67 308.32 359.67 371.00 362.00
                  C 371.50 362.00 372.00 362.00 372.00 362.00
                  C 392.09 359.73 413.06 359.68 405.00 386.00 C 404.35 461.92 406.31 538.49 404.00 614.00
                  C 410.32 531.49 406.34 443.79 406.00 360.00
                  C 394.33 360.00 382.67 360.00 371.00 360.00
                  C 325.33 360.00 279.67 360.00 234.00 360.00 C 217.03 365.34 177.16 347.40 179.00 373.00 C 179.00 441.00 179.00 509.00 179.00 577.00 C 161.82 637.46 227.28 627.02 266.00 626.00 Z"></path>
                  </g>
                  <g fill="#86B3E6" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 404.00 614.00 
                  C 404.05 614.49 404.00 615.00 404.00 615.00
                  C 366.43 638.07 311.30 617.68 268.00 626.00
                  C 311.27 622.96 358.53 632.16 399.25 621.25 C 418.87 587.07 402.74 535.15 408.00 494.00 C 406.67 449.64 410.66 402.81 406.00 360.00
                  C 406.34 443.79 410.32 531.49 404.00 614.00 Z"></path>
                  </g>
                  <g fill="#F3F4F4" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 191.00 622.00 
                  C 218.20 626.21 248.25 623.67 276.00 623.00
                  C 307.67 597.30 283.38 542.15 291.00 504.00 C 293.66 461.32 285.68 410.80 295.00 373.00
                  C 295.00 450.67 295.00 528.33 295.00 606.00
                  C 336.38 590.90 316.41 538.26 321.00 503.00 C 321.00 460.00 321.00 417.00 321.00 374.00
                  C 329.33 435.91 319.34 509.94 326.00 575.00
                  C 368.79 520.35 341.14 441.28 349.00 375.00 C 360.04 378.45 351.12 419.83 354.00 438.00 C 355.33 462.01 351.34 488.58 356.00 511.00
                  C 368.20 463.66 373.50 411.59 372.00 362.00
                  C 372.00 362.00 371.50 362.00 371.00 362.00
                  C 307.67 362.00 244.33 362.00 181.00 362.00
                  C 181.00 407.00 181.00 452.00 181.00 497.00 C 186.56 537.67 169.21 587.93 191.00 622.00 Z"></path>
                  </g>
                  <g fill="#b7ce96" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 276.00 623.00 
                  C 318.19 619.01 365.90 631.77 404.00 615.00
                  C 404.00 615.00 404.05 614.49 404.00 614.00
                  C 406.31 538.49 404.35 461.92 405.00 386.00 C 413.06 359.68 392.09 359.73 372.00 362.00
                  C 373.50 411.59 368.20 463.66 356.00 511.00
                  C 351.34 488.58 355.33 462.01 354.00 438.00 C 351.12 419.83 360.04 378.45 349.00 375.00 C 341.14 441.28 368.79 520.35 326.00 575.00
                  C 319.34 509.94 329.33 435.91 321.00 374.00
                  C 321.00 417.00 321.00 460.00 321.00 503.00 C 316.41 538.26 336.38 590.90 295.00 606.00
                  C 295.00 528.33 295.00 450.67 295.00 373.00
                  C 285.68 410.80 293.66 461.32 291.00 504.00 C 283.38 542.15 307.67 597.30 276.00 623.00 Z"></path>
                  </g>
                  <g fill="#737883" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 125.00 457.00 
                  C 125.00 457.00 125.00 457.50 125.00 458.00
                  C 125.00 458.50 125.00 459.00 125.00 459.00
                  C 127.12 456.13 128.99 452.04 133.00 451.00
                  C 133.47 450.88 134.50 451.00 135.00 451.00
                  C 143.09 448.69 153.16 450.65 162.00 450.00
                  C 162.50 450.00 163.84 450.23 164.00 450.00
                  C 164.00 450.00 164.00 449.50 164.00 449.00
                  C 163.00 449.00 162.00 449.00 161.00 449.00
                  C 148.81 450.24 132.40 444.61 125.00 457.00 Z"></path>
                  </g>
                  <g fill="#3D77B9" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 422.00 476.00 
                  C 412.17 527.62 457.87 645.06 372.00 638.00
                  C 459.40 648.49 413.10 526.64 423.00 474.00
                  C 424.23 466.31 420.48 456.11 425.00 450.00
                  C 425.19 449.91 425.00 449.00 425.00 449.00
                  C 425.00 449.00 424.50 449.00 424.00 449.00
                  C 424.00 449.00 423.26 448.75 423.00 449.00
                  C 420.67 457.01 422.66 467.17 422.00 476.00 Z"></path>
                  </g>
                  <g fill="#12192B" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 126.00 473.00 
                  C 144.37 476.09 171.18 478.49 162.00 451.00
                  C 153.00 451.00 144.00 451.00 135.00 451.00
                  C 134.50 451.00 133.47 450.88 133.00 451.00
                  C 125.58 455.62 125.28 464.69 126.00 473.00 Z"></path>
                  </g>
                  <g fill="#203F66" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 126.00 473.00 
                  C 126.00 473.00 125.00 473.04 125.00 473.00
                  C 142.84 478.69 174.43 478.70 162.00 451.00
                  C 171.18 478.49 144.37 476.09 126.00 473.00 Z"></path>
                  </g>
                  <g fill="#121A2C" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 462.00 457.00 
                  C 462.00 457.00 462.20 456.40 462.00 456.00
                  C 459.30 455.02 458.15 450.96 455.00 451.00
                  C 444.67 451.00 434.33 451.00 424.00 451.00
                  C 424.05 458.73 423.58 466.63 425.00 474.00
                  C 437.33 474.00 449.67 474.00 462.00 474.00
                  C 462.00 468.33 462.00 462.67 462.00 457.00 Z"></path>
                  </g>
                  <g fill="#9cba73" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 423.00 474.00 
                  C 413.10 526.64 459.40 648.49 372.00 638.00
                  C 341.67 638.00 311.33 638.00 281.00 638.00 C 258.19 643.74 203.07 621.42 215.00 661.00
                  C 233.53 666.40 297.15 647.22 279.00 677.00
                  C 284.52 673.78 287.42 668.43 286.00 662.00
                  C 264.53 657.34 239.02 661.33 216.00 660.00
                  C 209.88 620.33 268.33 646.51 293.00 639.00 C 317.91 646.62 376.93 620.16 371.00 660.00
                  C 347.99 661.33 322.43 657.34 301.00 662.00
                  C 328.81 663.05 367.30 651.65 382.00 675.00
                  C 382.00 678.00 382.00 681.00 382.00 684.00
                  C 383.26 684.08 382.56 686.06 384.00 686.00
                  C 383.20 683.49 382.72 680.81 383.00 678.00
                  C 400.96 671.37 432.80 679.05 456.00 675.00
                  C 458.00 675.00 460.00 675.00 462.00 675.00
                  C 462.00 678.33 462.00 681.67 462.00 685.00
                  C 462.25 684.75 463.00 685.00 463.00 685.00
                  C 465.87 681.64 461.58 675.23 466.00 673.00
                  C 481.88 649.46 468.67 608.44 473.00 578.00 C 473.00 544.00 473.00 510.00 473.00 476.00
                  C 470.16 475.41 466.02 477.22 464.00 475.00
                  C 463.77 474.65 464.00 473.50 464.00 473.00
                  C 463.42 468.82 465.21 463.42 463.00 460.00
                  C 470.46 484.85 435.73 475.48 423.00 474.00 Z"></path>
                  </g>
                  <g fill="#8EBAE8" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 123.00 474.00 
                  C 100.10 478.62 117.36 518.32 112.00 538.00 C 112.00 563.00 112.00 588.00 112.00 613.00 C 116.70 633.27 100.69 668.28 124.00 676.00
                  C 103.33 665.51 116.83 633.73 113.00 613.00 C 113.00 588.33 113.00 563.67 113.00 539.00 C 118.35 519.31 101.11 479.57 124.00 475.00
                  C 124.23 474.65 124.00 473.50 124.00 473.00
                  C 123.95 473.23 123.26 473.76 123.00 474.00 Z"></path>
                  </g>
                  <g fill="#9cba73" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 124.00 475.00 
                  C 101.11 479.57 118.35 519.31 113.00 539.00 C 113.00 563.67 113.00 588.33 113.00 613.00 C 116.83 633.73 103.33 665.51 124.00 676.00
                  C 124.80 675.87 125.11 674.91 126.00 675.00
                  C 127.00 675.00 128.00 675.00 129.00 675.00
                  C 135.32 675.30 141.45 676.57 148.00 676.00
                  C 163.41 675.94 178.94 676.45 194.00 675.00
                  C 197.94 675.72 201.10 673.51 205.00 674.00
                  C 205.00 675.00 205.00 676.00 205.00 677.00
                  C 204.74 680.03 205.74 683.37 204.00 686.00
                  C 204.04 689.15 199.98 690.30 199.00 693.00
                  C 206.79 689.12 206.53 680.57 206.00 673.00
                  C 212.14 671.88 214.55 667.05 215.00 661.00
                  C 203.07 621.42 258.19 643.74 281.00 638.00 C 311.33 638.00 341.67 638.00 372.00 638.00
                  C 457.87 645.06 412.17 527.62 422.00 476.00
                  C 414.78 526.54 437.23 588.38 409.25 631.25 C 357.73 642.14 299.96 632.98 246.00 636.00
                  C 214.80 638.75 157.50 645.83 164.00 598.00 C 164.00 556.67 164.00 515.33 164.00 474.00
                  C 152.04 477.90 136.64 476.07 124.00 475.00 Z"></path>
                  </g>
                  <g fill="#2C4D75" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 286.00 662.00 
                  C 301.10 654.57 297.60 670.04 306.00 676.00
                  C 301.75 672.29 299.91 667.76 301.00 662.00
                  C 322.43 657.34 347.99 661.33 371.00 660.00
                  C 376.93 620.16 317.91 646.62 293.00 639.00 C 268.33 646.51 209.88 620.33 216.00 660.00
                  C 239.02 661.33 264.53 657.34 286.00 662.00 Z"></path>
                  </g>
                  <g fill="#849BBA" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 206.00 673.00 
                  C 222.56 653.63 257.19 664.94 284.00 662.00
                  C 286.74 669.99 277.93 673.17 277.00 680.00
                  C 277.25 679.75 278.00 680.00 278.00 680.00
                  C 278.57 679.06 279.15 678.18 279.00 677.00
                  C 297.15 647.22 233.53 666.40 215.00 661.00
                  C 214.55 667.05 212.14 671.88 206.00 673.00 Z"></path>
                  </g>
            </g>
      </svg>
      `,
      "ambulance.svg": `
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="441.348 202.063 226.034 382.581" style="enable-background:new 0 0 1130 800;">
      <g transform="matrix(0, 1.137737, -1.128353, 0, -279.394259, -222.065843)" style="transform-origin: 832.806px 612.955px;">
            <path style="fill:#EFCD41;" d="M696.588,636.509v14.599c0,5.794,4.696,10.49,10.489,10.49l0,0v-25.089H696.588z"></path>
            <path style="fill:#EFCD41;" d="M696.39,591.165l0.008-14.39c0.003-5.909,4.796-10.696,10.705-10.692l0,0l-0.014,25.089
                        L696.39,591.165z"></path>
            <path style="fill:#BF0202;" d="M971.492,652.351v6.205c0,4.547-3.686,8.232-8.232,8.232l0,0v-22.671l0,0
                        C967.807,644.117,971.492,647.804,971.492,652.351z"></path>
            <path style="fill:#BF0202;" d="M971.492,566.883v6.206c0,4.547-3.686,8.232-8.232,8.232l0,0V558.65l0,0
                        C967.807,558.65,971.492,562.337,971.492,566.883z"></path>
            <path style="fill: rgb(255, 255, 255);" d="M783.72,562.447v101.289h-73.167c-3.413,0-6.031-2.05-7.237-4.894
                        c-6.137-14.607-9.195-30.259-9.195-45.893c0-15.538,3.021-31.067,9.045-45.534c0.828-1.985,2.324-3.52,4.131-4.319
                        c0.923-0.424,1.939-0.649,2.984-0.649H783.72z"></path>
            <path style="fill:#5A6867;" d="M785.614,662.117l-0.925,1.619h-74.137c-3.413,0-6.031-2.05-7.237-4.894
                        c-6.137-14.607-9.195-30.259-9.195-45.893c0-15.538,3.021-31.067,9.045-45.534c0.828-1.985,2.324-3.52,4.131-4.319
                        c-0.027,0.057-0.047,0.094-0.047,0.094c-12.207,29.007-12.216,62.287,0.15,91.417c1.272,2.983,4.059,7.745,7.137,7.745
                        L785.614,662.117z"></path>
            <path style="opacity: 0.51; fill: rgb(255, 255, 255);" d="M703.246,581.636c-1.975,19.736-1.972,41.094,0.031,60.886
                        c0.56,5.538,4.506,9.984,9.614,10.935l13.588,2.529c0,0-9.923-71.317,0.822-88.023l-14.466,2.729
                        C707.734,571.654,703.8,576.102,703.246,581.636z"></path>
            <path style="fill:#323530;" d="M735.664,657.027l24.438-7.673v-72.479l-24.438-7.872
                        C735.664,569.003,722.146,603.113,735.664,657.027z"></path>
            <polygon style="fill:#323530;" points="743.507,661.598 762.327,652.916 783.71,652.916 783.71,661.504 	"></polygon>
            <polygon style="fill:#323530;" points="744.131,564.268 763.488,572.53 785.296,572.056 785.614,563.459 	"></polygon>
            <path style="fill:#FF0000;" d="M766.037,641.674c0,4.679,3.53,8.472,7.886,8.472h4.939v-30.351h-12.825V641.674z"></path>
            <rect x="766.037" y="606.202" style="fill:#657A79;" width="12.825" height="13.593"></rect>
            <path style="fill:#0843FF;" d="M773.923,575.293c-4.355,0-7.886,3.793-7.886,8.472v22.438h12.825v-30.909H773.923z"></path>
            <path style="opacity:0.19;fill:#A5C4C2;" d="M773.49,624.685v17.591c0,1.364-1.106,2.47-2.47,2.47l0,0
                        c-1.364,0-2.471-1.105-2.471-2.47v-17.591c0-1.364,1.106-2.47,2.471-2.47l0,0C772.384,622.216,773.49,623.321,773.49,624.685z"></path>
            <path style="opacity:0.19;fill:#A5C4C2;" d="M773.49,584.326v17.591c0,1.364-1.106,2.47-2.47,2.47l0,0
                        c-1.364,0-2.471-1.105-2.471-2.47v-17.591c0-1.364,1.106-2.47,2.471-2.47l0,0C772.384,581.856,773.49,582.962,773.49,584.326z"></path>
            <path style="fill:#9cba73;" d="M966.731,554.626v114.701H790.826c-0.405,0-0.801-0.038-1.187-0.104
                        c-2.767-0.499-4.979-2.711-5.685-5.572c-0.16-0.621-0.245-1.289-0.245-1.967v-99.436c0-4.197,3.182-7.623,7.116-7.623H966.731z"></path>
            <path style="fill:#9cba73;" d="M790.969,554.624c-4.006,0-7.253,3.247-7.253,7.253v51.215h183.02v-58.468H790.969z"></path>
            <path style="opacity:0.51;fill:#9cba73;" d="M802.903,578.815c-10.598,0-19.188,8.591-19.188,19.188v23.057
                        c0,10.598,8.591,19.188,19.188,19.188h127.079v-61.432H802.903z"></path>
            <g>
                  <polygon style="fill:#323530;" points="859.581,643.487 823.878,643.487 809.336,665.274 859.581,665.274 		"></polygon>
                  <polygon style="fill:#323530;" points="867.032,665.274 942.441,665.274 922.164,643.487 867.032,643.487 		"></polygon>
            </g>
            <g>
                  <polygon style="fill:#323530;" points="859.581,579.388 823.878,579.388 809.336,557.601 859.581,557.601 		"></polygon>
                  <polygon style="fill:#323530;" points="867.032,557.601 942.441,557.601 922.164,579.388 867.032,579.388 		"></polygon>
            </g>
            <rect x="783.713" y="636.509" style="fill:#F40B16;" width="146.27" height="15.219"></rect>
            <rect x="783.713" y="571.194" style="fill: rgb(244, 11, 22); fill-rule: nonzero;" width="146.27" height="15.239"></rect>
            <path style="fill:#323530;" d="M952.751,577.985c-5.313,5.313-8.29,8.291-13.603,13.602v43.743
                        c5.313,5.313,8.29,8.291,13.603,13.602h4.075v-70.948H952.751z"></path>
            <path style="fill:#5A6867;" d="M966.731,554.626v114.701H790.826c-0.358,0-0.763-0.038-1.187-0.104
                        c-2.767-0.499-4.979-2.711-5.685-5.572c0.838,0.103,1.534,0.179,1.751,0.179h175.915V554.626H966.731z"></path>
            <rect x="703.107" y="610.787" transform="matrix(6.123234e-17 -1 1 6.123234e-17 98.7202 1325.3226)" style="fill:#F40B16;" width="17.829" height="5.029"></rect>
            <rect x="703.724" y="610.6" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 1424.042 1226.6025)" style="fill:#F40B16;" width="16.595" height="5.403"></rect>
            <path style="" d="M744.131,564.268c0,0,1.905-8.169,5.321-11.838c3.415-3.67,7.783-0.854,7.783-0.854l-3.951,12.514
                        L744.131,564.268z"></path>
            <path style="" d="M743.507,661.598c0,0,2.054,8.178,5.454,11.862c3.4,3.685,7.78,0.891,7.78,0.891l-3.976-12.775
                        L743.507,661.598z"></path>
            </g>
      </svg>
      `,
      "semi-truck.svg": `
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="331.33 39.263 164.931 478.881" style="enable-background:new 0 0 500 500;">
            <g id="objects">
                  <g>
                        <g>
                        <g>
                        <path style="fill:#9cba73;" d="M370.351,171.112h93.073v-65.124c0,0,1.233-4.315,1.233-8.629c0-4.315,0-22.478,0-22.478
                                                l-2.157-3.082c0,0,2.774-23.114-45.612-23.114c-48.386,0-45.612,23.114-45.612,23.114l-2.157,3.082c0,0,0,18.163,0,22.478
                                                c0,4.314,1.233,8.629,1.233,8.629V171.112z"></path>
                        <path style="fill:#9cba73;" d="M377.523,65.243c-0.208,1.197-0.251,2.413-0.315,3.623l-0.113,3.636
                                                c-0.054,2.425-0.077,4.849-0.064,7.275c-0.047,4.848,0.065-0.798,0.099,4.051l0.509,29.098l-2.233-2.233l20.741-0.288
                                                l20.741-0.102l20.741,0.099l20.741,0.285l-2.239,2.239l0.508-29.098c0.032-4.849,0.147,0.797,0.101-4.051
                                                c0.012-2.426-0.009-4.85-0.063-7.275l-0.112-3.636c-0.063-1.21-0.106-2.426-0.313-3.623c0.358,1.166,0.556,2.374,0.774,3.573
                                                c0.208,1.202,0.397,2.407,0.557,3.616c0.309,2.417,0.59,4.839,0.829,7.263c0.439,4.851,0.846-0.792,1.101,4.067
                                                c0.266,4.859,0.546,9.719,0.684,14.58l0.411,14.584l0.062,2.207l-2.301,0.032l-20.741,0.285l-20.741,0.099l-20.741-0.102
                                                l-20.741-0.288l-2.296-0.032l0.062-2.202l0.413-14.584c0.138-4.861,0.421-9.721,0.684-14.58c0.257-4.859,0.66,0.784,1.099-4.067
                                                c0.24-2.424,0.519-4.846,0.827-7.263c0.161-1.209,0.348-2.414,0.556-3.616C376.968,67.617,377.165,66.408,377.523,65.243z"></path>
                        <path style="fill: rgb(59, 59, 59);" d="M458.369,171.112v-58.186c0,0-13.412-12.904-41.482-12.904c-28.07,0-41.482,12.904-41.482,12.904
                                                v58.186H458.369z"></path>
                        <g>
                              <path style="fill:#F2F2F2;" d="M375.406,155.343v10.64l63.087-63.087c-2.642-0.751-5.571-1.391-8.755-1.886L375.406,155.343z"></path>
                              <path style="fill:#F2F2F2;" d="M381.373,171.112l65.293-65.293c-1.116-0.488-2.302-0.968-3.581-1.435l-66.728,66.728H381.373z"></path>
                              <polygon style="fill:#F2F2F2;" points="448.406,171.112 458.369,161.149 458.369,150.509 437.766,171.112 					"></polygon>
                        </g>
                        <path style="fill:#9cba73;" d="M384.228,171.112h65.32v-31.284l9.027-22.746l-0.205-4.156l-1.866-1.527l-10.18,24.346
                                                c0,0-8.147-5.249-29.436-5.249c-21.29,0-29.436,5.249-29.436,5.249l-10.18-24.346l-1.866,1.527l-0.205,4.156l9.027,22.746
                                                V171.112z"></path>
                        <path style="fill:#b7ce96;" d="M377.272,111.399c0,0-14.376-2.085-14.376,6.802h14.376V111.399z"></path>
                        <path style="fill:#b7ce96;" d="M456.503,111.399c0,0,14.376-2.085,14.376,6.802h-14.376V111.399z"></path>
                        <path style="fill:#b7ce96;" d="M377.749,64.878c0,0,0.144-0.293,0.542-0.756c0.39-0.466,1.017-1.117,1.938-1.791
                                                c0.912-0.683,2.087-1.434,3.514-2.132c1.415-0.726,3.079-1.412,4.941-2.008c1.859-0.599,3.904-1.164,6.094-1.612
                                                c1.091-0.258,2.219-0.47,3.379-0.647c1.157-0.191,2.342-0.397,3.554-0.504c1.211-0.127,2.443-0.257,3.689-0.388
                                                c1.25-0.08,2.514-0.161,3.789-0.242c2.55-0.192,5.143-0.166,7.737-0.259c2.617,0.09,5.156,0.066,7.727,0.257
                                                c1.278,0.082,2.546,0.163,3.799,0.243c1.247,0.131,2.478,0.261,3.689,0.388c1.212,0.108,2.397,0.31,3.555,0.502
                                                c1.16,0.177,2.288,0.389,3.379,0.647c2.19,0.448,4.236,1.013,6.095,1.613c1.862,0.596,3.527,1.28,4.941,2.008
                                                c1.427,0.699,2.602,1.45,3.514,2.133c0.921,0.675,1.547,1.326,1.937,1.792c0.397,0.463,0.541,0.757,0.541,0.757
                                                s-0.173-0.277-0.611-0.692c-0.427-0.425-1.118-0.977-2.062-1.576c-0.943-0.6-2.144-1.244-3.591-1.82
                                                c-1.436-0.598-3.11-1.139-4.954-1.657c-1.848-0.501-3.877-0.962-6.05-1.302c-1.08-0.203-2.198-0.36-3.349-0.481
                                                c-1.15-0.117-2.309-0.337-3.511-0.413c-1.196-0.107-2.413-0.216-3.644-0.327c-1.233-0.06-2.48-0.12-3.738-0.181
                                                c-2.499-0.152-5.127-0.084-7.667-0.141c-2.564,0.054-5.137-0.012-7.657,0.139c-1.261,0.061-2.512,0.122-3.748,0.182
                                                c-1.231,0.11-2.447,0.22-3.644,0.327c-1.201,0.078-2.361,0.293-3.511,0.411c-1.15,0.121-2.269,0.278-3.349,0.481
                                                c-2.173,0.34-4.202,0.801-6.05,1.303c-1.843,0.518-3.519,1.058-4.954,1.657c-1.447,0.576-2.648,1.221-3.591,1.821
                                                c-0.944,0.6-1.635,1.152-2.062,1.577C377.922,64.601,377.749,64.878,377.749,64.878z"></path>
                        <path style="fill:#F2F2F2;" d="M376.737,59.429c0,0,11.852-1.959,18.544-1.959c6.691,0,5.216-6.936-0.609-6.642
                                                C388.847,51.122,380.841,55.297,376.737,59.429z"></path>
                        <path style="fill:#F2F2F2;" d="M457.038,59.429c0,0-11.852-1.959-18.544-1.959c-6.691,0-5.216-6.936,0.609-6.642
                                                C444.928,51.122,452.934,55.297,457.038,59.429z"></path>
                        </g>
                        <rect x="370.351" y="171.112" style="fill:#9cba73;" width="93.111" height="10.14"></rect>
                        </g>
                        <path style="fill:#b7ce96;" d="M 467.136 502.313 L 364.364 503.451 C 360.475 503.451 360.735 498.023 360.735 494.134 L 359.597 188.295 C 359.597 184.406 362.75 181.253 366.639 181.253 L 467.136 181.253 C 471.025 181.253 474.178 184.406 474.178 188.295 L 473.609 495.271 C 473.61 499.16 471.026 502.313 467.136 502.313 Z"></path>
                        <rect x="350.829" y="265.517" transform="matrix(0, 1, -1, 0, 732.504278, -162.38553)" style="fill:#9cba73;" width="308" height="100.199"></rect>
                        <rect x="292.568" y="313.629" transform="matrix(0, 1, -1, 0, 684.392217, -104.124261)" style="fill:#CCCCCC;" width="308" height="3.975"></rect>
                        <rect x="409.09" y="315.602" transform="matrix(0, 1, -1, 0, 782.588873, -220.646837)" style="fill:#CCCCCC;" width="308" height="4"></rect>
                  </g>
            </g>
      </svg>
      `,
      "excavator.svg": `
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:v="https://svgstorm.com" viewBox="208.439 -357.047 504.822 963.654">
      <g style="transform-origin: 470.5px 132.5px;" transform="matrix(0, 0.999997, -1.000003, 0, -0.00002, -0.000008)">
            <g fill="#9cba73" fill-opacity="0.98" stroke="None">
                  <path d="
                        M 789.00 26.00 
                        C 780.19 47.65 795.10 52.75 815.00 49.00 C 828.47 44.97 860.42 59.27 857.00 37.00
                        C 859.19 40.09 857.43 45.16 858.00 49.00
                        C 873.67 49.00 889.33 49.00 905.00 49.00
                        C 905.00 108.33 905.00 167.67 905.00 227.00
                        C 889.67 227.00 874.33 227.00 859.00 227.00
                        C 859.48 231.63 857.92 236.80 860.00 241.00
                        C 839.36 248.16 799.89 231.44 788.00 250.00
                        C 812.67 250.00 837.33 250.00 862.00 250.00
                        C 880.36 245.94 898.07 242.50 916.00 238.00
                        C 916.00 199.00 916.00 160.00 916.00 121.00 C 908.92 90.25 935.36 31.45 891.00 31.00
                        C 891.00 31.00 890.50 31.01 890.00 31.00
                        C 883.15 30.44 876.71 28.25 870.00 27.00
                        C 866.77 25.87 863.20 26.14 860.00 25.00
                        C 837.32 27.33 812.50 25.33 789.00 26.00 Z"></path>
                        </g>
                        <g fill="#9cba73" fill-opacity="1.00" stroke="None">
                              <rect x="315.239" y="140.203" width="139.433" height="106.848" style="stroke: #9cba73; fill: #9cba73;" transform="matrix(0, -1, 1, 0, -88.383132, 526.261528)"></rect>
                              <rect x="315.239" y="97.446" width="139.433" height="74.263" style="stroke: rgb(0, 0, 0); fill: #b7ce96;" transform="matrix(0, -1, 1, 0, 56.296783, 526.261161)"></rect>
                        </g>
                        <g fill="#9cba73" fill-opacity="1.00" stroke="None">
                              <path d="
                        M 314.00 31.00 
                        C 314.00 48.67 314.00 66.33 314.00 84.00
                        C 314.00 85.33 314.00 86.67 314.00 88.00
                        C 314.00 88.50 314.00 89.00 314.00 89.00
                        C 334.67 89.00 355.33 89.00 376.00 89.00
                        C 377.78 109.23 414.26 95.41 430.00 98.00
                        C 430.00 95.33 430.00 92.67 430.00 90.00
                        C 427.34 89.73 424.27 90.72 422.00 89.00
                        C 382.27 102.77 400.29 48.88 398.00 26.00
                        C 370.00 26.00 342.00 26.00 314.00 26.00
                        C 314.00 27.67 314.00 29.33 314.00 31.00 Z"></path>
                        </g>
                        <g fill="#b7ce96" fill-opacity="1.00" stroke="None">
                              <path d="
                        M 784.00 28.00 
                        C 786.84 40.90 776.45 69.84 798.00 65.00 C 832.33 65.00 866.67 65.00 901.00 65.00
                        C 901.00 113.33 901.00 161.67 901.00 210.00
                        C 862.00 210.00 823.00 210.00 784.00 210.00
                        C 784.00 222.67 784.00 235.33 784.00 248.00
                        C 722.00 248.00 660.00 248.00 598.00 248.00
                        C 598.00 229.33 598.00 210.67 598.00 192.00 C 595.92 179.26 605.01 142.44 587.00 152.00
                        C 590.33 152.00 593.67 152.00 597.00 152.00
                        C 597.66 184.25 595.67 217.20 598.00 249.00
                        C 594.83 249.61 590.35 247.75 588.00 250.00
                        C 654.67 250.00 721.33 250.00 788.00 250.00
                        C 799.89 231.44 839.36 248.16 860.00 241.00
                        C 857.92 236.80 859.48 231.63 859.00 227.00
                        C 874.33 227.00 889.67 227.00 905.00 227.00
                        C 905.00 167.67 905.00 108.33 905.00 49.00
                        C 889.33 49.00 873.67 49.00 858.00 49.00
                        C 857.43 45.16 859.19 40.09 857.00 37.00
                        C 860.42 59.27 828.47 44.97 815.00 49.00 C 795.10 52.75 780.19 47.65 789.00 26.00
                        C 787.33 26.00 785.67 26.00 784.00 26.00
                        C 783.75 26.28 784.00 27.50 784.00 28.00 Z"></path>
                        </g>
                        <g fill="#9cba73" fill-opacity="1.00" stroke="None">
                              <path d="
                        M 488.00 99.00 
                        C 488.00 99.00 488.00 99.50 488.00 100.00
                        C 488.00 116.67 488.00 133.33 488.00 150.00
                        C 520.28 153.65 555.41 147.68 587.00 153.00
                        C 587.00 152.50 587.00 152.00 587.00 152.00
                        C 605.01 142.44 595.92 179.26 598.00 192.00 C 598.00 210.67 598.00 229.33 598.00 248.00
                        C 660.00 248.00 722.00 248.00 784.00 248.00
                        C 784.00 235.33 784.00 222.67 784.00 210.00
                        C 823.00 210.00 862.00 210.00 901.00 210.00
                        C 901.00 161.67 901.00 113.33 901.00 65.00
                        C 866.67 65.00 832.33 65.00 798.00 65.00 C 776.45 69.84 786.84 40.90 784.00 28.00
                        C 685.67 28.00 587.33 28.00 489.00 28.00
                        C 488.34 51.51 490.33 76.24 488.00 99.00 Z"></path>
                        </g>
                        <g fill="#F4F6F4" fill-opacity="0.08" stroke="None">
                              <path d="
                        M 262.00 85.00 
                        C 261.76 84.80 262.00 84.00 262.00 84.00
                        C 258.67 68.51 258.67 46.45 262.00 31.00
                        C 262.46 30.86 263.00 31.00 263.00 31.00
                        C 278.76 28.67 296.49 30.66 313.00 30.00
                        C 313.00 30.00 313.11 29.47 313.00 29.00
                        C 295.44 30.93 274.31 25.09 259.00 32.00 C 260.93 49.22 255.09 70.02 262.00 85.00 Z"></path>
                        </g>
                        <g fill="#454D4F" fill-opacity="1.00" stroke="None">
                              <path d="
                        M 261.00 82.00 
                        C 277.50 84.32 295.08 82.34 312.00 83.00
                        C 311.05 67.09 312.57 47.61 312.00 31.00
                        C 295.67 31.00 279.33 31.00 263.00 31.00
                        C 263.00 31.00 262.46 30.86 262.00 31.00
                        C 259.68 47.47 261.66 65.09 261.00 82.00 Z"></path>
                        </g>
                        <g fill="#DB9C07" fill-opacity="1.00" stroke="None"></g>
                        <g fill="#B59844" fill-opacity="1.00" stroke="None">
                              <path d="
                        M 733.00 64.00 
                        C 707.99 57.40 694.12 70.12 703.33 94.67 C 728.42 101.95 741.36 88.75 733.00 64.00 Z"></path>
                        </g>
                        <g fill="#796A34" fill-opacity="1.00" stroke="None">
                              <path d="
                        M 703.00 66.00 
                        C 703.08 65.81 704.50 66.00 705.00 66.00
                        C 712.67 66.00 720.33 66.00 728.00 66.00
                        C 729.67 66.00 731.33 66.00 733.00 66.00
                        C 724.15 64.32 711.93 64.35 703.00 66.00 Z"></path>
                        </g>
                        <g fill="#5C5439" fill-opacity="1.00" stroke="None">
                              <path d="
                        M 733.00 66.00 
                        C 731.33 66.00 729.67 66.00 728.00 66.00
                        C 728.53 67.60 730.75 66.72 732.00 67.00
                        C 738.06 89.91 725.42 97.73 704.00 93.00
                        C 704.62 84.08 702.73 74.48 705.00 66.00
                        C 704.50 66.00 703.08 65.81 703.00 66.00
                        C 703.00 75.33 703.00 84.67 703.00 94.00
                        C 713.00 94.00 723.00 94.00 733.00 94.00
                        C 733.00 84.67 733.00 75.33 733.00 66.00 Z"></path>
                        </g>
                        <g fill="#414D5C" fill-opacity="1.00" stroke="None">
                              <path d="
                        M 728.00 66.00 
                        C 720.33 66.00 712.67 66.00 705.00 66.00
                        C 702.73 74.48 704.62 84.08 704.00 93.00
                        C 725.42 97.73 738.06 89.91 732.00 67.00
                        C 730.75 66.72 728.53 67.60 728.00 66.00 Z"></path>
                        </g>
                        <g fill="#C6CACC" fill-opacity="0.18" stroke="None">
                              <path d="
                        M 262.00 85.00 
                        C 277.80 85.67 295.39 84.54 312.00 85.00
                        C 312.35 84.95 312.74 84.12 313.00 84.00
                        C 296.00 84.00 279.00 84.00 262.00 84.00
                        C 262.00 84.00 261.76 84.80 262.00 85.00 Z"></path>
                        </g>
                        <g fill="#b7ce96" fill-opacity="1.00" stroke="None">
                              <path d="
                        M 314.00 90.00 
                        C 334.33 90.00 354.67 90.00 375.00 90.00
                        C 375.06 93.53 374.26 97.37 377.00 100.00
                        C 359.27 96.97 350.74 106.47 357.00 124.00
                        C 381.67 124.00 406.33 124.00 431.00 124.00
                        C 433.24 115.78 432.18 104.79 430.00 98.00
                        C 414.26 95.41 377.78 109.23 376.00 89.00
                        C 355.33 89.00 334.67 89.00 314.00 89.00
                        C 314.00 89.00 313.96 90.00 314.00 90.00 Z"></path>
                        </g>
                        <g fill="#b7ce96" fill-opacity="1.00" stroke="None">
                              <path d="
                        M 303.00 98.00 
                        C 326.50 100.33 351.08 98.34 375.00 99.00
                        C 352.16 101.36 326.04 102.05 303.00 100.00
                        C 303.00 100.00 302.25 99.75 302.00 100.00
                        C 301.75 100.25 302.00 101.00 302.00 101.00
                        C 278.57 103.33 253.18 101.34 229.00 102.00
                        C 229.00 107.00 229.00 112.00 229.00 117.00
                        C 247.19 117.98 263.62 113.46 282.00 115.00 C 297.27 109.04 328.82 122.43 299.00 126.00
                        C 299.04 126.00 299.00 127.00 299.00 127.00
                        C 317.18 123.76 336.97 123.50 357.00 124.00
                        C 350.74 106.47 359.27 96.97 377.00 100.00
                        C 374.26 97.37 375.06 93.53 375.00 90.00
                        C 354.67 90.00 334.33 90.00 314.00 90.00
                        C 310.33 90.00 306.67 90.00 303.00 90.00
                        C 303.00 92.67 303.00 95.33 303.00 98.00 Z"></path>
                        </g>
                        <g fill="#b7ce96" fill-opacity="1.00" stroke="None">
                              <path d="
                        M 430.00 98.00 
                        C 432.18 104.79 433.24 115.78 431.00 124.00
                        C 436.67 124.00 442.33 124.00 448.00 124.00
                        C 452.52 121.87 458.06 123.52 463.00 123.00
                        C 463.00 115.33 463.00 107.67 463.00 100.00
                        C 455.07 100.75 444.39 101.33 448.00 90.00
                        C 442.00 90.00 436.00 90.00 430.00 90.00
                        C 430.00 92.67 430.00 95.33 430.00 98.00 Z"></path>
                        </g>
                        <g fill="#b7ce96" fill-opacity="1.00" stroke="None">
                              <path d="
                        M 303.00 100.00 
                        C 326.04 102.05 352.16 101.36 375.00 99.00
                        C 351.08 98.34 326.50 100.33 303.00 98.00
                        C 303.00 98.50 303.23 99.84 303.00 100.00 Z"></path>
                        </g>
                        <g fill="#F1F3FA" fill-opacity="0.07" stroke="None">
                              <path d="
                        M 463.00 99.00 
                        C 471.00 99.00 479.00 99.00 487.00 99.00
                        C 487.00 99.00 487.00 98.50 487.00 98.00
                        C 474.67 98.00 462.33 98.00 450.00 98.00
                        C 453.34 100.29 458.83 98.36 463.00 99.00 Z"></path>
                        </g>
                        <g fill="#B4B8BD" fill-opacity="0.52" stroke="None">
                              <path d="
                        M 463.00 100.00 
                        C 463.25 100.25 464.00 100.00 464.00 100.00
                        C 471.67 100.00 479.33 100.00 487.00 100.00
                        C 487.00 100.00 487.00 99.50 487.00 99.00
                        C 479.00 99.00 471.00 99.00 463.00 99.00
                        C 463.00 99.00 462.75 99.75 463.00 100.00 Z"></path>
                        </g>
                        <g fill="#F1C47C" fill-opacity="0.53" stroke="None">
                              <path d="
                        M 229.00 101.00 
                        C 253.33 101.00 277.67 101.00 302.00 101.00
                        C 302.00 101.00 301.75 100.25 302.00 100.00
                        C 277.67 100.00 253.33 100.00 229.00 100.00
                        C 229.00 100.00 229.24 100.68 229.00 101.00 Z"></path>
                        </g>
                        <g fill="#6F4D1E" fill-opacity="1.00" stroke="None">
                              <path d="
                        M 463.00 123.00 
                        C 441.30 115.83 441.39 159.21 463.00 152.00
                        C 463.00 159.00 463.00 166.00 463.00 173.00
                        C 463.00 173.50 463.00 174.00 463.00 174.00
                        C 464.69 166.98 464.62 157.33 463.00 150.00
                        C 462.54 149.86 462.00 150.00 462.00 150.00
                        C 444.06 155.49 449.65 135.97 449.00 125.00
                        C 453.33 125.00 457.67 125.00 462.00 125.00
                        C 462.50 125.00 463.00 125.00 463.00 125.00
                        C 465.26 117.49 463.39 108.92 464.00 101.00
                        C 464.00 100.50 464.00 100.00 464.00 100.00
                        C 464.00 100.00 463.25 100.25 463.00 100.00
                        C 463.00 107.67 463.00 115.33 463.00 123.00 Z"></path>
                        </g>
                        <g fill="#b7ce96" fill-opacity="1.00" stroke="None">
                              <path d="
                        M 228.00 118.00 
                        C 229.28 118.28 231.50 117.24 232.00 119.00
                        C 238.67 119.00 245.33 119.00 252.00 119.00
                        C 252.10 118.65 252.83 118.27 253.00 118.00
                        C 270.14 113.35 291.31 117.32 310.00 116.00
                        C 302.17 129.42 271.81 118.43 255.00 122.00
                        C 255.00 123.33 255.00 124.67 255.00 126.00
                        C 269.67 126.00 284.33 126.00 299.00 126.00
                        C 328.82 122.43 297.27 109.04 282.00 115.00 C 263.62 113.46 247.19 117.98 229.00 117.00
                        C 229.00 112.00 229.00 107.00 229.00 102.00
                        C 253.18 101.34 278.57 103.33 302.00 101.00
                        C 277.67 101.00 253.33 101.00 229.00 101.00
                        C 226.68 105.67 228.65 112.50 228.00 118.00 Z"></path>
                        </g>
                        <g fill="#29323E" fill-opacity="1.00" stroke="None">
                              <path d="
                        M 463.00 125.00 
                        C 463.00 125.00 462.50 125.00 462.00 125.00
                        C 446.36 122.14 450.05 137.95 450.00 149.00
                        C 453.83 149.63 458.99 147.72 462.00 150.00
                        C 462.00 150.00 462.54 149.86 463.00 150.00
                        C 471.80 164.96 460.64 178.12 451.00 180.00
                        C 459.67 180.00 468.33 180.00 477.00 180.00
                        C 477.66 171.17 475.67 161.01 478.00 153.00
                        C 479.47 149.50 484.09 151.57 487.00 151.00
                        C 487.00 134.33 487.00 117.67 487.00 101.00
                        C 479.67 101.00 472.33 101.00 465.00 101.00
                        C 463.79 108.70 467.50 118.84 463.00 125.00 Z"></path>
                        </g>
                        <g fill="#FBB51A" fill-opacity="1.00" stroke="None">
                              <path d="
                        M 253.00 118.00 
                        C 255.03 119.77 253.53 123.48 254.00 126.00
                        C 254.00 126.04 255.00 126.00 255.00 126.00
                        C 255.00 124.67 255.00 123.33 255.00 122.00
                        C 271.81 118.43 302.17 129.42 310.00 116.00
                        C 291.31 117.32 270.14 113.35 253.00 118.00 Z"></path>
                        </g>
                        <g fill="#EDD799" fill-opacity="0.64" stroke="None">
                              <path d="
                        M 228.00 148.00 
                        C 228.05 147.85 229.00 148.00 229.00 148.00
                        C 229.25 147.75 229.00 147.00 229.00 147.00
                        C 229.00 139.67 229.00 132.33 229.00 125.00
                        C 229.00 124.50 229.00 124.00 229.00 124.00
                        C 228.84 121.65 228.45 118.08 232.00 119.00
                        C 231.50 117.24 229.28 118.28 228.00 118.00
                        C 228.00 128.00 228.00 138.00 228.00 148.00 Z"></path>
                        </g>
                        <g fill="#B6C1BC" fill-opacity="1.00" stroke="None">
                              <path d="
                        M 229.00 124.00 
                        C 229.16 123.77 230.50 124.00 231.00 124.00
                        C 231.00 123.00 231.00 122.00 231.00 121.00
                        C 237.89 122.20 243.91 121.04 251.00 121.00
                        C 251.00 122.00 251.00 123.00 251.00 124.00
                        C 251.50 124.00 252.00 124.00 252.00 124.00
                        C 252.00 122.33 252.00 120.67 252.00 119.00
                        C 245.33 119.00 238.67 119.00 232.00 119.00
                        C 228.45 118.08 228.84 121.65 229.00 124.00 Z"></path>
                        </g>
                        <g fill="#B36203" fill-opacity="1.00" stroke="None">
                              <path d="
                        M 448.00 124.00 
                        C 399.43 127.76 346.00 121.13 299.00 128.00
                        C 299.00 133.67 299.00 139.33 299.00 145.00
                        C 303.84 145.21 313.11 142.92 311.00 151.00
                        C 361.64 151.43 412.75 149.99 463.00 152.00
                        C 441.39 159.21 441.30 115.83 463.00 123.00
                        C 458.06 123.52 452.52 121.87 448.00 124.00 Z"></path>
                        </g>
                        <g fill="#BAC0C7" fill-opacity="0.62" stroke="None"></g>
                        <g fill="#C87504" fill-opacity="1.00" stroke="None">
                              <path d="
                        M 299.00 127.00 
                        C 299.00 127.50 299.00 128.00 299.00 128.00
                        C 346.00 121.13 399.43 127.76 448.00 124.00
                        C 442.33 124.00 436.67 124.00 431.00 124.00
                        C 406.33 124.00 381.67 124.00 357.00 124.00
                        C 336.97 123.50 317.18 123.76 299.00 127.00 Z"></path>
                        </g>
                        <g fill="#FDFEFD" fill-opacity="0.03" stroke="None"></g>
                        <g fill="#3C3429" fill-opacity="1.00" stroke="None">
                              <path d="
                        M 462.00 150.00 
                        C 458.99 147.72 453.83 149.63 450.00 149.00
                        C 450.05 137.95 446.36 122.14 462.00 125.00
                        C 457.67 125.00 453.33 125.00 449.00 125.00
                        C 449.65 135.97 444.06 155.49 462.00 150.00 Z"></path>
                        </g>
                        <g fill="#000000" fill-opacity="0.00" stroke="None"></g>
                        <g fill="#DB9F38" fill-opacity="0.87" stroke="None">
                              <path d="
                        M 299.00 128.00 
                        C 299.00 128.00 299.00 127.50 299.00 127.00
                        C 299.00 127.00 299.04 126.00 299.00 126.00
                        C 284.33 126.00 269.67 126.00 255.00 126.00
                        C 255.00 126.00 254.00 126.04 254.00 126.00
                        C 267.93 128.98 284.67 125.02 299.00 128.00 Z"></path>
                        </g>
                        <g fill="#FEFFD7" fill-opacity="0.02" stroke="None">
                              <path d="
                        M 298.00 129.00 
                        C 284.32 126.67 268.50 128.67 254.00 128.00
                        C 267.68 130.33 283.50 128.33 298.00 129.00 Z"></path>
                        </g>
                        <g fill="#DE9D07" fill-opacity="1.00" stroke="None"></g>
                        <g fill="#DC9B08" fill-opacity="1.00" stroke="None"></g>
                        <g fill="#FEF2C1" fill-opacity="0.16" stroke="None">
                              <path d="
                        M 251.00 146.00 
                        C 251.23 146.05 251.88 146.74 252.00 147.00
                        C 266.06 142.42 282.87 146.27 298.00 145.00
                        C 298.00 145.00 298.00 144.50 298.00 144.00
                        C 282.53 145.27 265.39 141.42 251.00 146.00 Z"></path>
                        </g>
                        <g fill="#F7D47C" fill-opacity="0.71" stroke="None">
                              <path d="
                        M 252.00 147.00 
                        C 252.19 147.41 252.00 148.00 252.00 148.00
                        C 252.25 148.25 253.00 148.00 253.00 148.00
                        C 266.47 143.37 283.98 147.31 299.00 146.00
                        C 298.96 146.00 299.00 145.00 299.00 145.00
                        C 299.00 145.00 298.50 145.00 298.00 145.00
                        C 282.87 146.27 266.06 142.42 252.00 147.00 Z"></path>
                        </g>
                        <g fill="#b7ce96" fill-opacity="1.00" stroke="None">
                              <path d="
                        M 299.00 146.00 
                        C 302.67 146.00 306.33 146.00 310.00 146.00
                        C 299.44 157.36 272.09 147.89 255.00 151.00
                        C 255.65 152.12 254.07 156.08 253.00 154.00
                        C 244.67 154.00 236.33 154.00 228.00 154.00
                        C 228.00 160.67 228.00 167.33 228.00 174.00
                        C 228.00 174.00 228.50 174.00 229.00 174.00
                        C 253.58 174.66 278.87 172.67 303.00 175.00
                        C 326.46 172.91 352.86 173.72 376.00 176.00
                        C 352.17 178.33 327.25 176.34 303.00 177.00
                        C 303.00 180.00 303.00 183.00 303.00 186.00
                        C 306.67 186.00 310.33 186.00 314.00 186.00
                        C 333.83 187.81 355.37 187.38 375.22 186.22 C 379.43 166.92 412.51 181.56 429.00 177.00
                        C 429.26 180.03 428.26 183.37 430.00 186.00
                        C 436.00 186.00 442.00 186.00 448.00 186.00
                        C 448.00 184.33 448.00 182.67 448.00 181.00
                        C 448.00 179.67 448.00 178.33 448.00 177.00
                        C 442.17 176.34 435.01 178.32 430.00 176.00
                        C 434.91 171.92 442.84 174.92 449.00 174.00
                        C 453.63 173.52 458.80 175.08 463.00 173.00
                        C 463.00 166.00 463.00 159.00 463.00 152.00
                        C 412.75 149.99 361.64 151.43 311.00 151.00
                        C 313.11 142.92 303.84 145.21 299.00 145.00
                        C 299.00 145.00 298.96 146.00 299.00 146.00 Z"></path>
                        </g>
                        <g fill="#F9B825" fill-opacity="1.00" stroke="None">
                              <path d="
                        M 253.00 148.00 
                        C 253.00 150.00 253.00 152.00 253.00 154.00
                        C 254.07 156.08 255.65 152.12 255.00 151.00
                        C 272.09 147.89 299.44 157.36 310.00 146.00
                        C 306.33 146.00 302.67 146.00 299.00 146.00
                        C 283.98 147.31 266.47 143.37 253.00 148.00 Z"></path>
                        </g>
                        <g fill="#3F3322" fill-opacity="1.00" stroke="None">
                              <path d="
                        M 463.00 174.00 
                        C 459.25 176.22 453.51 174.41 449.00 175.00
                        C 449.00 176.67 449.00 178.33 449.00 180.00
                        C 449.41 179.81 450.00 180.00 450.00 180.00
                        C 450.50 180.00 451.00 180.00 451.00 180.00
                        C 460.64 178.12 471.80 164.96 463.00 150.00
                        C 464.62 157.33 464.69 166.98 463.00 174.00 Z"></path>
                        </g>
                        <g fill="#E0C88B" fill-opacity="1.00" stroke="None">
                              <path d="
                        M 488.00 151.00 
                        C 488.00 151.50 488.00 152.00 488.00 152.00
                        C 520.20 152.22 552.60 151.56 584.67 152.33 C 588.44 183.03 583.78 217.27 587.00 248.00
                        C 587.00 216.33 587.00 184.67 587.00 153.00
                        C 555.41 147.68 520.28 153.65 488.00 150.00
                        C 488.00 150.00 488.00 150.50 488.00 151.00 Z"></path>
                        </g>
                        <g fill="#A5ADB4" fill-opacity="1.00" stroke="None">
                              <path d="
                        M 478.00 153.00 
                        C 478.00 162.00 478.00 171.00 478.00 180.00
                        C 478.00 180.50 478.00 181.00 478.00 181.00
                        C 478.25 181.25 479.00 181.00 479.00 181.00
                        C 479.00 171.33 479.00 161.67 479.00 152.00
                        C 482.00 152.00 485.00 152.00 488.00 152.00
                        C 488.00 152.00 488.00 151.50 488.00 151.00
                        C 488.00 151.00 487.50 151.00 487.00 151.00
                        C 484.09 151.57 479.47 149.50 478.00 153.00 Z"></path>
                        </g>
                        <g fill="#FEB60F" fill-opacity="1.00" stroke="None"></g>
                        <g fill="#EBF3F6" fill-opacity="1.00" stroke="None">
                              <path d="
                        M 479.00 181.00 
                        C 479.66 206.91 477.67 233.53 480.00 259.00
                        C 498.10 261.33 518.15 259.34 537.00 260.00 C 552.06 255.52 584.76 269.88 587.00 251.00
                        C 582.12 221.71 586.77 185.91 584.67 154.33 C 553.64 150.56 519.06 155.22 488.00 152.00
                        C 485.00 152.00 482.00 152.00 479.00 152.00
                        C 479.00 161.67 479.00 171.33 479.00 181.00 Z" style="fill: rgb(137, 137, 137);"></path>
                        </g>
                        <g fill="#DED8C7" fill-opacity="1.00" stroke="None">
                              <path d="
                        M 587.00 251.00 
                        C 586.75 250.73 587.00 250.00 587.00 250.00
                        C 587.00 249.50 587.00 248.50 587.00 248.00
                        C 583.78 217.27 588.44 183.03 584.67 152.33 C 552.60 151.56 520.20 152.22 488.00 152.00
                        C 519.06 155.22 553.64 150.56 584.67 154.33 C 586.77 185.91 582.12 221.71 587.00 251.00 Z"></path>
                        </g>
                        <g fill="#9cba73" fill-opacity="1.00" stroke="None">
                              <path d="
                        M 587.00 153.00 
                        C 587.00 184.67 587.00 216.33 587.00 248.00
                        C 587.00 248.50 587.00 249.50 587.00 250.00
                        C 587.25 249.75 588.00 250.00 588.00 250.00
                        C 590.35 247.75 594.83 249.61 598.00 249.00
                        C 595.67 217.20 597.66 184.25 597.00 152.00
                        C 593.67 152.00 590.33 152.00 587.00 152.00
                        C 587.00 152.00 587.00 152.50 587.00 153.00 Z"></path>
                        </g>
                        <g fill="#5C656B" fill-opacity="1.00" stroke="None">
                              <path d="
                        M 477.00 180.00 
                        C 477.50 180.00 478.00 180.00 478.00 180.00
                        C 478.00 171.00 478.00 162.00 478.00 153.00
                        C 475.67 161.01 477.66 171.17 477.00 180.00 Z"></path>
                        </g>
                        <g fill="#996E2C" fill-opacity="0.99" stroke="None">
                              <path d="
                        M 449.00 174.00 
                        C 448.43 174.94 447.85 175.82 448.00 177.00
                        C 448.00 178.33 448.00 179.67 448.00 181.00
                        C 448.00 180.84 448.74 180.12 449.00 180.00
                        C 449.00 178.33 449.00 176.67 449.00 175.00
                        C 453.51 174.41 459.25 176.22 463.00 174.00
                        C 463.00 174.00 463.00 173.50 463.00 173.00
                        C 458.80 175.08 453.63 173.52 449.00 174.00 Z"></path>
                        </g>
                        <g fill="#b7ce96" fill-opacity="1.00" stroke="None">
                              <path d="
                        M 303.00 175.00 
                        C 303.24 175.31 303.00 176.50 303.00 177.00
                        C 327.25 176.34 352.17 178.33 376.00 176.00
                        C 352.86 173.72 326.46 172.91 303.00 175.00 Z"></path>
                        </g>
                        <g fill="#b7ce96" fill-opacity="1.00" stroke="None">
                              <path d="
                        M 448.00 177.00 
                        C 447.85 175.82 448.43 174.94 449.00 174.00
                        C 442.84 174.92 434.91 171.92 430.00 176.00
                        C 435.01 178.32 442.17 176.34 448.00 177.00 Z"></path>
                        </g>
                        <g fill="#FAE7AA" fill-opacity="0.43" stroke="None"></g>
                        <g fill="#9cba73" fill-opacity="1.00" stroke="None">
                              <path d="
                        M 314.00 186.00 
                        C 314.00 188.00 314.00 190.00 314.00 192.00
                        C 314.00 209.67 314.00 227.33 314.00 245.00
                        C 314.39 246.57 313.06 249.22 315.00 250.00
                        C 341.43 247.67 369.82 249.67 397.00 249.00
                        C 401.20 224.10 381.73 173.86 424.00 186.00
                        C 426.00 186.00 428.00 186.00 430.00 186.00
                        C 428.26 183.37 429.26 180.03 429.00 177.00
                        C 412.51 181.56 379.43 166.92 375.22 186.22 C 355.37 187.38 333.83 187.81 314.00 186.00 Z" style=""></path>
                        </g>
                        <g fill="#414D5D" fill-opacity="1.00" stroke="None">
                              <path d="
                        M 704.00 211.00 
                        C 724.26 217.87 735.93 208.35 732.00 188.00
                        C 713.45 179.41 696.71 188.67 704.00 211.00 Z"></path>
                        </g>
                        <g fill="#455048" fill-opacity="1.00" stroke="None">
                              <path d="
                        M 704.00 211.00 
                        C 703.91 211.89 704.87 212.20 705.00 213.00
                        C 713.33 213.00 721.67 213.00 730.00 213.00
                        C 734.43 206.63 730.84 196.08 732.00 188.00
                        C 735.93 208.35 724.26 217.87 704.00 211.00 Z"></path>
                        </g>
                        <g fill="#444D50" fill-opacity="1.00" stroke="None">
                              <path d="
                        M 261.00 194.00 
                        C 261.67 210.50 259.67 228.32 262.00 244.00
                        C 278.33 244.00 294.67 244.00 311.00 244.00
                        C 312.43 226.43 311.66 209.60 312.00 192.00
                        C 295.67 192.00 279.33 192.00 263.00 192.00
                        C 261.81 191.85 260.49 192.60 261.00 194.00 Z"></path>
                  </g>
            </g>
      </svg>
      `,
      "mixer.svg":`
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:v="https://svgstorm.com" viewBox="0 -9.725 390.758 771.725">
      <g>
            <g fill="#b7ce96" fill-opacity="1.00" stroke="None">
                  <path d="
                  M 116.00 18.00 
                  C 110.44 19.38 104.85 20.66 99.00 21.00
                  C 87.70 21.52 75.47 26.92 71.00 38.00
                  C 71.60 41.26 70.17 43.91 70.00 47.00
                  C 70.63 43.34 77.64 34.95 81.00 43.00
                  C 90.00 39.37 99.57 37.40 109.00 35.00
                  C 110.56 35.41 111.69 34.54 113.00 34.00
                  C 103.96 23.55 124.05 23.01 131.00 23.00
                  C 135.90 23.75 131.71 29.91 135.00 30.00
                  C 168.10 26.22 203.25 25.40 236.00 31.00
                  C 234.75 29.46 232.56 26.45 235.00 25.00
                  C 240.38 20.78 262.85 23.23 255.00 34.00
                  C 258.52 35.51 262.34 35.89 266.00 37.00
                  C 273.22 38.15 280.12 40.63 287.00 43.00
                  C 289.95 35.44 296.07 42.84 297.00 47.00
                  C 299.31 22.26 269.74 20.15 252.00 19.00
                  C 207.59 12.22 159.59 13.75 116.00 18.00 Z"></path>
                  </g>
                  <g fill="#1A2329" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 109.00 35.00 
                  C 109.19 35.02 109.65 35.96 110.00 36.00
                  C 116.10 35.45 121.91 33.72 128.00 33.00
                  C 127.53 31.02 124.40 33.11 124.00 31.00
                  C 127.52 31.70 130.40 29.99 133.00 28.00
                  C 133.76 25.81 131.70 24.68 131.00 23.00
                  C 124.05 23.01 103.96 23.55 113.00 34.00
                  C 111.69 34.54 110.56 35.41 109.00 35.00 Z"></path>
                  </g>
                  <g fill="#48748F" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 133.00 28.00 
                  C 132.70 29.25 131.17 29.71 131.00 31.00
                  C 132.28 30.72 134.50 31.76 135.00 30.00
                  C 131.71 29.91 135.90 23.75 131.00 23.00
                  C 131.70 24.68 133.76 25.81 133.00 28.00 Z"></path>
                  </g>
                  <g fill="#192329" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 235.00 25.00 
                  C 233.59 30.03 239.33 31.12 243.00 31.00
                  C 242.76 32.67 240.15 31.17 240.00 33.00
                  C 248.32 34.68 256.77 35.88 265.00 38.00
                  C 265.32 38.03 265.80 37.02 266.00 37.00
                  C 262.34 35.89 258.52 35.51 255.00 34.00
                  C 262.85 23.23 240.38 20.78 235.00 25.00 Z"></path>
                  </g>
                  <g fill="#395765" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 135.00 30.00 
                  C 134.50 31.76 132.28 30.72 131.00 31.00
                  C 131.17 29.71 132.70 29.25 133.00 28.00
                  C 130.40 29.99 127.52 31.70 124.00 31.00
                  C 124.40 33.11 127.53 31.02 128.00 33.00
                  C 150.46 29.59 173.54 28.48 197.00 29.00
                  C 203.72 30.78 211.11 29.87 218.00 31.00
                  C 223.93 30.27 229.09 32.58 235.00 32.00
                  C 236.40 33.21 238.26 32.98 240.00 33.00
                  C 240.15 31.17 242.76 32.67 243.00 31.00
                  C 239.33 31.12 233.59 30.03 235.00 25.00
                  C 232.56 26.45 234.75 29.46 236.00 31.00
                  C 203.25 25.40 168.10 26.22 135.00 30.00 Z"></path>
                  </g>
                  <g fill="#b7ce96" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 128.00 33.00 
                  C 121.91 33.72 116.10 35.45 110.00 36.00
                  C 99.88 41.01 77.71 37.83 83.00 56.00
                  C 117.59 50.45 152.87 47.49 189.00 48.00
                  C 192.23 41.92 195.66 35.80 197.00 29.00
                  C 173.54 28.48 150.46 29.59 128.00 33.00 Z"></path>
                  </g>
                  <g fill="#9cba73" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 189.00 48.00 
                  C 196.60 48.60 204.84 46.75 212.00 49.00
                  C 210.33 42.89 217.76 37.46 218.00 31.00
                  C 211.11 29.87 203.72 30.78 197.00 29.00
                  C 195.66 35.80 192.23 41.92 189.00 48.00 Z"></path>
                  </g>
                  <g fill="#b7ce96" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 212.00 49.00 
                  C 217.00 49.00 222.00 49.00 227.00 49.00
                  C 233.18 49.83 230.41 41.33 234.00 39.00
                  C 235.19 36.81 236.93 34.33 235.00 32.00
                  C 229.09 32.58 223.93 30.27 218.00 31.00
                  C 217.76 37.46 210.33 42.89 212.00 49.00 Z"></path>
                  </g>
                  <g fill="#9cba73" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 234.00 39.00 
                  C 234.37 40.54 232.57 41.40 233.00 43.00
                  C 241.96 45.12 257.18 36.78 260.00 49.00
                  C 255.11 48.53 250.65 49.49 246.00 50.00
                  C 252.39 51.83 260.11 51.78 267.00 53.00
                  C 270.54 49.66 269.32 43.07 265.00 41.00
                  C 265.83 39.25 268.44 40.34 270.00 40.00
                  C 269.26 37.80 265.67 40.31 265.00 38.00
                  C 256.77 35.88 248.32 34.68 240.00 33.00
                  C 238.26 32.98 236.40 33.21 235.00 32.00
                  C 236.93 34.33 235.19 36.81 234.00 39.00 Z"></path>
                  </g>
                  <g fill="#365260" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 81.00 43.00 
                  C 80.83 45.72 81.61 48.67 80.00 51.00
                  C 91.06 65.43 122.94 47.32 143.00 51.00 C 190.67 46.86 241.79 52.70 286.00 56.00
                  C 285.98 56.10 285.00 56.00 285.00 56.00
                  C 284.50 56.00 284.00 56.00 284.00 56.00
                  C 278.30 55.21 272.49 54.62 267.00 53.00
                  C 260.11 51.78 252.39 51.83 246.00 50.00
                  C 239.84 49.34 232.34 51.32 227.00 49.00
                  C 222.00 49.00 217.00 49.00 212.00 49.00
                  C 204.84 46.75 196.60 48.60 189.00 48.00
                  C 152.87 47.49 117.59 50.45 83.00 56.00
                  C 77.71 37.83 99.88 41.01 110.00 36.00
                  C 109.65 35.96 109.19 35.02 109.00 35.00
                  C 99.57 37.40 90.00 39.37 81.00 43.00 Z"></path>
                  </g>
                  <g fill="#325565" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 265.00 38.00 
                  C 265.67 40.31 269.26 37.80 270.00 40.00
                  C 274.85 40.60 279.48 42.17 284.00 44.00
                  C 284.43 44.18 285.00 44.00 285.00 44.00
                  C 286.87 45.14 285.61 48.13 286.00 50.00
                  C 286.80 50.13 287.11 51.09 288.00 51.00
                  C 286.28 48.73 287.27 45.66 287.00 43.00
                  C 280.12 40.63 273.22 38.15 266.00 37.00
                  C 265.80 37.02 265.32 38.03 265.00 38.00 Z"></path>
                  </g>
                  <g fill="#b7ce96" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 227.00 49.00 
                  C 232.34 51.32 239.84 49.34 246.00 50.00
                  C 250.65 49.49 255.11 48.53 260.00 49.00
                  C 257.18 36.78 241.96 45.12 233.00 43.00
                  C 232.57 41.40 234.37 40.54 234.00 39.00
                  C 230.41 41.33 233.18 49.83 227.00 49.00 Z"></path>
                  </g>
                  <g fill="#1A2125" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 70.00 47.00 
                  C 65.95 45.48 61.40 46.11 57.00 46.00
                  C 57.00 46.00 56.25 45.75 56.00 46.00
                  C 56.00 46.00 56.00 46.50 56.00 47.00
                  C 56.00 52.33 56.00 57.67 56.00 63.00
                  C 63.08 63.19 54.43 44.16 65.00 50.00
                  C 66.67 50.00 68.33 50.00 70.00 50.00
                  C 73.28 50.43 76.47 51.48 80.00 51.00
                  C 81.61 48.67 80.83 45.72 81.00 43.00
                  C 77.64 34.95 70.63 43.34 70.00 47.00 Z"></path>
                  </g>
                  <g fill="#b7ce96" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 267.00 53.00 
                  C 272.49 54.62 278.30 55.21 284.00 56.00
                  C 284.00 52.00 284.00 48.00 284.00 44.00
                  C 279.48 42.17 274.85 40.60 270.00 40.00
                  C 268.44 40.34 265.83 39.25 265.00 41.00
                  C 269.32 43.07 270.54 49.66 267.00 53.00 Z"></path>
                  </g>
                  <g fill="#1A2126" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 287.00 43.00 
                  C 287.27 45.66 286.28 48.73 288.00 51.00
                  C 291.17 51.23 294.12 50.95 297.00 50.00
                  C 300.51 50.55 305.24 48.84 308.00 51.00
                  C 311.23 53.75 306.17 61.88 311.00 63.00
                  C 313.32 58.33 311.35 51.50 312.00 46.00
                  C 306.91 45.91 301.76 45.81 297.00 47.00
                  C 296.07 42.84 289.95 35.44 287.00 43.00 Z"></path>
                  </g>
                  <g fill="#4B8397" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 284.00 56.00 
                  C 284.00 56.00 284.50 56.00 285.00 56.00
                  C 285.00 52.00 285.00 48.00 285.00 44.00
                  C 285.00 44.00 284.43 44.18 284.00 44.00
                  C 284.00 48.00 284.00 52.00 284.00 56.00 Z"></path>
                  </g>
                  <g fill="#132022" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 285.00 56.00 
                  C 285.00 56.00 285.98 56.10 286.00 56.00
                  C 286.00 54.00 286.00 52.00 286.00 50.00
                  C 285.61 48.13 286.87 45.14 285.00 44.00
                  C 285.00 48.00 285.00 52.00 285.00 56.00 Z"></path>
                  </g>
                  <g fill="#9cba73" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 70.00 53.00 
                  C 70.00 53.50 70.00 54.00 70.00 54.00
                  C 70.00 64.33 70.00 74.67 70.00 85.00
                  C 67.33 85.00 64.67 85.00 62.00 85.00
                  C 63.77 87.03 67.48 85.53 70.00 86.00
                  C 69.34 114.58 71.33 143.87 69.00 172.00
                  C 75.33 172.00 81.67 172.00 88.00 172.00
                  C 88.50 172.00 89.00 172.00 89.00 172.00
                  C 152.33 172.00 215.67 172.00 279.00 172.00
                  C 279.50 172.00 280.00 172.00 280.00 172.00
                  C 286.33 172.00 292.67 172.00 299.00 172.00
                  C 294.35 145.35 298.32 114.33 297.00 86.00
                  C 297.00 85.50 297.00 85.00 297.00 85.00
                  C 297.00 74.67 297.00 64.33 297.00 54.00
                  C 297.00 53.50 297.00 53.00 297.00 53.00
                  C 297.00 52.00 297.00 51.00 297.00 50.00
                  C 294.12 50.95 291.17 51.23 288.00 51.00
                  C 287.11 51.09 286.80 50.13 286.00 50.00
                  C 286.00 52.00 286.00 54.00 286.00 56.00
                  C 241.79 52.70 190.67 46.86 143.00 51.00 C 122.94 47.32 91.06 65.43 80.00 51.00
                  C 76.47 51.48 73.28 50.43 70.00 50.00
                  C 70.00 51.00 70.00 52.00 70.00 53.00 Z"></path>
                  </g>
                  <g fill="#505456" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 311.00 63.00 
                  C 306.17 61.88 311.23 53.75 308.00 51.00
                  C 308.73 54.36 305.88 65.01 311.00 63.00 Z"></path>
                  </g>
                  <g fill="#181D21" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 62.00 85.00 
                  C 64.67 85.00 67.33 85.00 70.00 85.00
                  C 70.00 74.67 70.00 64.33 70.00 54.00
                  C 54.72 52.74 64.98 75.37 62.00 85.00 Z"></path>
                  </g>
                  <g fill="#161C21" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 297.00 85.00 
                  C 299.67 85.00 302.33 85.00 305.00 85.00
                  C 305.00 75.00 305.00 65.00 305.00 55.00
                  C 302.81 54.48 299.38 56.14 298.00 54.00
                  C 298.00 54.00 297.25 54.25 297.00 54.00
                  C 297.00 64.33 297.00 74.67 297.00 85.00 Z"></path>
                  </g>
                  <g fill="#363A3C" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 305.00 85.00 
                  C 305.50 85.00 306.00 85.00 306.00 85.00
                  C 306.00 75.00 306.00 65.00 306.00 55.00
                  C 303.67 53.39 300.72 54.17 298.00 54.00
                  C 299.38 56.14 302.81 54.48 305.00 55.00
                  C 305.00 65.00 305.00 75.00 305.00 85.00 Z"></path>
                  </g>
                  <g fill="#b7ce96" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 68.00 172.00 
                  C 68.50 171.95 69.00 172.00 69.00 172.00
                  C 71.33 143.87 69.34 114.58 70.00 86.00
                  C 67.48 85.53 63.77 87.03 62.00 85.00
                  C 62.00 85.00 61.46 84.86 61.00 85.00
                  C 56.36 98.76 60.32 116.65 59.00 132.00 C 62.73 144.15 50.23 171.59 68.00 172.00 Z"></path>
                  </g>
                  <g fill="#40667B" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 297.00 86.00 
                  C 298.00 86.00 299.00 86.00 300.00 86.00
                  C 302.00 86.00 304.00 86.00 306.00 86.00
                  C 305.81 85.91 306.00 85.00 306.00 85.00
                  C 306.00 85.00 305.50 85.00 305.00 85.00
                  C 302.33 85.00 299.67 85.00 297.00 85.00
                  C 297.00 85.00 297.00 85.50 297.00 86.00 Z"></path>
                  </g>
                  <g fill="#b7ce96" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 306.00 86.00 
                  C 310.30 89.88 306.93 97.59 308.00 103.00
                  C 302.04 102.73 306.28 93.21 305.00 89.00
                  C 302.88 89.37 299.61 88.88 300.00 86.00
                  C 299.00 86.00 298.00 86.00 297.00 86.00
                  C 298.32 114.33 294.35 145.35 299.00 172.00
                  C 318.19 172.87 305.23 144.32 309.00 132.00 C 307.10 116.77 312.87 97.99 306.00 85.00
                  C 306.00 85.00 305.81 85.91 306.00 86.00 Z"></path>
                  </g>
                  <g fill="#C5C6C7" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 68.00 184.00 
                  C 74.67 184.00 81.33 184.00 88.00 184.00
                  C 88.00 180.00 88.00 176.00 88.00 172.00
                  C 81.67 172.00 75.33 172.00 69.00 172.00
                  C 69.00 172.00 68.50 171.95 68.00 172.00
                  C 68.00 176.00 68.00 180.00 68.00 184.00 Z"></path>
                  </g>
                  <g fill="#9cba73" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 89.00 287.00 
                  C 90.67 286.56 91.34 284.54 93.00 284.00
                  C 94.78 282.24 97.54 281.83 99.00 280.00
                  C 101.33 256.17 99.34 231.25 100.00 207.00 C 99.17 179.01 125.15 178.70 148.00 183.00
                  C 149.20 183.67 150.58 184.64 151.00 186.00
                  C 153.16 188.76 151.45 193.49 152.00 197.00
                  C 160.33 197.00 168.67 197.00 177.00 197.00
                  C 177.39 195.43 176.06 192.78 178.00 192.00
                  C 208.07 187.95 245.62 190.92 279.00 190.00
                  C 279.00 188.00 279.00 186.00 279.00 184.00
                  C 279.00 180.00 279.00 176.00 279.00 172.00
                  C 215.67 172.00 152.33 172.00 89.00 172.00
                  C 89.00 210.33 89.00 248.67 89.00 287.00 Z"></path>
                  </g>
                  <g fill="#C5C5C6" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 280.00 184.00 
                  C 286.67 184.00 293.33 184.00 300.00 184.00
                  C 299.55 180.05 301.02 175.54 299.00 172.00
                  C 292.67 172.00 286.33 172.00 280.00 172.00
                  C 280.00 176.00 280.00 180.00 280.00 184.00 Z"></path>
                  </g>
                  <g fill="#2E2F31" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 99.00 280.00 
                  C 105.46 278.46 111.28 274.79 118.00 274.00
                  C 131.21 269.70 144.95 267.19 159.00 266.00
                  C 159.63 262.17 157.72 257.01 160.00 254.00
                  C 160.22 253.70 160.88 253.26 161.00 253.00
                  C 162.85 248.76 166.29 244.66 169.00 241.00
                  C 160.26 236.94 154.84 213.50 169.00 211.00
                  C 167.45 205.74 172.31 202.72 177.00 204.00
                  C 177.00 201.67 177.00 199.33 177.00 197.00
                  C 168.67 197.00 160.33 197.00 152.00 197.00
                  C 151.45 193.49 153.16 188.76 151.00 186.00
                  C 151.00 205.33 151.00 224.67 151.00 244.00 C 158.53 271.27 125.83 259.71 110.00 262.00
                  C 110.00 235.67 110.00 209.33 110.00 183.00
                  C 122.67 183.00 135.33 183.00 148.00 183.00
                  C 125.15 178.70 99.17 179.01 100.00 207.00 C 99.34 231.25 101.33 256.17 99.00 280.00 Z"></path>
                  </g>
                  <g fill="#555555" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 151.00 186.00 
                  C 150.58 184.64 149.20 183.67 148.00 183.00
                  C 135.33 183.00 122.67 183.00 110.00 183.00
                  C 110.00 209.33 110.00 235.67 110.00 262.00
                  C 125.83 259.71 158.53 271.27 151.00 244.00 C 151.00 224.67 151.00 205.33 151.00 186.00 Z"></path>
                  </g>
                  <g fill="#EEEEEE" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 68.00 197.00 
                  C 74.67 197.00 81.33 197.00 88.00 197.00
                  C 88.00 192.67 88.00 188.33 88.00 184.00
                  C 81.33 184.00 74.67 184.00 68.00 184.00
                  C 64.68 185.16 64.81 195.85 68.00 197.00 Z"></path>
                  </g>
                  <g fill="#EBEBEB" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 178.00 192.00 
                  C 178.00 196.00 178.00 200.00 178.00 204.00
                  C 180.67 204.00 183.33 204.00 186.00 204.00
                  C 186.00 216.00 186.00 228.00 186.00 240.00
                  C 180.39 240.54 174.18 238.84 169.00 241.00
                  C 166.29 244.66 162.85 248.76 161.00 253.00
                  C 177.60 251.82 191.67 258.75 208.00 259.00
                  C 238.21 257.58 269.81 260.67 297.30 260.30 C 315.61 243.60 307.99 203.35 300.00 184.00
                  C 293.33 184.00 286.67 184.00 280.00 184.00
                  C 280.00 184.00 279.01 184.06 279.00 184.00
                  C 279.00 186.00 279.00 188.00 279.00 190.00
                  C 245.62 190.92 208.07 187.95 178.00 192.00 Z"></path>
                  </g>
                  <g fill="#C6C6C6" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 68.00 233.00 
                  C 74.67 233.00 81.33 233.00 88.00 233.00
                  C 88.00 221.00 88.00 209.00 88.00 197.00
                  C 81.33 197.00 74.67 197.00 68.00 197.00
                  C 68.00 209.00 68.00 221.00 68.00 233.00 Z"></path>
                  </g>
                  <g fill="#D1D2D3" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 169.00 211.00 
                  C 154.84 213.50 160.26 236.94 169.00 241.00
                  C 174.18 238.84 180.39 240.54 186.00 240.00
                  C 186.00 228.00 186.00 216.00 186.00 204.00
                  C 183.33 204.00 180.67 204.00 178.00 204.00
                  C 175.95 205.44 173.41 204.93 171.00 205.00
                  C 169.30 206.64 170.88 209.75 169.00 211.00 Z"></path>
                  </g>
                  <g fill="#EDEDEE" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 66.00 245.00 
                  C 72.84 247.24 80.74 245.40 88.00 246.00
                  C 88.00 241.67 88.00 237.33 88.00 233.00
                  C 81.33 233.00 74.67 233.00 68.00 233.00
                  C 63.75 234.27 67.04 241.35 66.00 245.00 Z"></path>
                  </g>
                  <g fill="#C6C6C6" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 88.00 262.00 
                  C 88.00 256.67 88.00 251.33 88.00 246.00
                  C 80.74 245.40 72.84 247.24 66.00 245.00
                  C 66.03 257.86 73.93 265.68 88.00 262.00 Z"></path>
                  </g>
                  <g fill="#D5D5D5" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 160.00 254.00 
                  C 159.37 257.83 161.28 262.99 159.00 266.00
                  C 144.95 267.19 131.21 269.70 118.00 274.00
                  C 110.78 284.72 110.17 300.06 107.00 313.00
                  C 127.03 299.28 165.70 310.80 193.00 307.00 C 222.99 308.32 255.74 304.35 284.00 309.00
                  C 281.71 302.37 283.18 294.53 278.00 289.00
                  C 260.21 270.29 232.07 269.88 208.00 266.00
                  C 208.00 264.00 208.00 262.00 208.00 260.00
                  C 208.00 260.00 208.04 259.00 208.00 259.00
                  C 191.67 258.75 177.60 251.82 161.00 253.00
                  C 160.88 253.26 160.22 253.70 160.00 254.00 Z"></path>
                  </g>
                  <g fill="#27282B" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 278.00 289.00 
                  C 278.00 289.00 278.65 288.77 279.00 289.00
                  C 279.00 280.00 279.00 271.00 279.00 262.00
                  C 258.24 257.34 231.18 261.33 208.00 260.00
                  C 208.00 262.00 208.00 264.00 208.00 266.00
                  C 232.07 269.88 260.21 270.29 278.00 289.00 Z"></path>
                  </g>
                  <g fill="#B2B9C2" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 99.00 280.00 
                  C 97.54 281.83 94.78 282.24 93.00 284.00
                  C 71.57 326.75 73.39 378.50 62.00 425.00
                  C 70.33 425.00 78.67 425.00 87.00 425.00
                  C 87.50 425.00 88.00 425.00 88.00 425.00
                  C 93.19 388.21 100.55 350.19 107.00 313.00
                  C 110.17 300.06 110.78 284.72 118.00 274.00
                  C 111.28 274.79 105.46 278.46 99.00 280.00 Z"></path>
                  </g>
                  <g fill="#E7E7E7" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 107.00 313.00 
                  C 100.55 350.19 93.19 388.21 88.00 425.00
                  C 160.56 425.58 232.39 423.25 305.00 424.00
                  C 298.75 385.46 290.92 347.34 284.00 309.00
                  C 255.74 304.35 222.99 308.32 193.00 307.00 C 165.70 310.80 127.03 299.28 107.00 313.00 Z"></path>
                  </g>
                  <g fill="#9cba73" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 88.00 425.00 
                  C 88.00 425.00 87.50 425.00 87.00 425.00
                  C 89.33 460.83 87.34 497.75 88.00 534.00
                  C 79.67 534.00 71.33 534.00 63.00 534.00
                  C 63.00 534.00 63.05 534.51 63.00 535.00
                  C 97.21 534.19 131.51 533.68 166.00 534.00
                  C 192.33 534.00 218.67 534.00 245.00 534.00
                  C 264.10 531.67 285.15 533.66 305.00 533.00
                  C 305.00 496.67 305.00 460.33 305.00 424.00
                  C 232.39 423.25 160.56 425.58 88.00 425.00 Z"></path>
                  </g>
                  <g fill="#798F9B" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 305.00 533.00 
                  C 305.00 533.50 305.00 534.00 305.00 534.00
                  C 306.67 498.47 306.67 459.60 305.00 424.00
                  C 305.00 460.33 305.00 496.67 305.00 533.00 Z"></path>
                  </g>
                  <g fill="#9cba73" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 63.00 534.00 
                  C 71.33 534.00 79.67 534.00 88.00 534.00
                  C 87.34 497.75 89.33 460.83 87.00 425.00
                  C 78.67 425.00 70.33 425.00 62.00 425.00
                  C 62.67 461.18 60.67 498.57 63.00 534.00 Z"></path>
                  </g>
                  <g fill="#8DB6CE" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 245.00 534.00 
                  C 265.00 534.00 285.00 534.00 305.00 534.00
                  C 305.00 534.00 305.00 533.50 305.00 533.00
                  C 285.15 533.66 264.10 531.67 245.00 534.00 Z"></path>
                  </g>
                  <g fill="#B1B8C2" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 63.00 535.00 
                  C 81.33 581.85 61.17 662.09 115.00 683.00
                  C 116.97 686.66 116.03 691.68 118.00 695.00
                  C 132.77 698.20 147.70 701.59 163.00 703.00
                  C 161.16 700.07 162.32 696.34 162.00 693.00
                  C 131.41 693.46 107.82 682.32 109.00 649.00
                  C 102.74 610.87 94.56 573.35 89.00 535.00
                  C 114.50 534.33 141.32 536.33 166.00 534.00
                  C 131.51 533.68 97.21 534.19 63.00 535.00 Z"></path>
                  </g>
                  <g fill="#E7E7E7" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 109.00 649.00 
                  C 165.65 653.66 226.67 649.67 285.00 651.00
                  C 291.97 612.09 299.64 573.31 305.00 534.00
                  C 285.00 534.00 265.00 534.00 245.00 534.00
                  C 218.67 534.00 192.33 534.00 166.00 534.00
                  C 141.32 536.33 114.50 534.33 89.00 535.00
                  C 94.56 573.35 102.74 610.87 109.00 649.00 Z"></path>
                  </g>
                  <g fill="#D5D5D5" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 162.00 693.00 
                  C 164.52 693.47 168.23 691.97 170.00 694.00
                  C 179.33 694.00 188.67 694.00 198.00 694.00
                  C 200.35 691.75 204.83 693.61 208.00 693.00
                  C 223.91 690.28 240.05 688.77 255.00 683.00
                  C 271.12 681.16 286.27 668.46 285.00 651.00
                  C 226.67 649.67 165.65 653.66 109.00 649.00
                  C 107.82 682.32 131.41 693.46 162.00 693.00 Z"></path>
                  </g>
                  <g fill="#A9B0B9" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 208.00 693.00 
                  C 207.15 696.30 207.46 699.88 206.00 703.00
                  C 221.04 700.14 238.02 697.03 252.00 695.00
                  C 254.34 691.82 253.06 686.66 255.00 683.00
                  C 240.05 688.77 223.91 690.28 208.00 693.00 Z"></path>
                  </g>
                  <g fill="#9cba73" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 198.00 694.00 
                  C 188.67 694.00 179.33 694.00 170.00 694.00
                  C 168.33 694.00 166.67 694.00 165.00 694.00
                  C 156.90 734.04 213.05 735.32 203.00 694.00
                  C 201.33 694.00 199.67 694.00 198.00 694.00 Z"></path>
                  </g>
                  <g fill="#D6D6D7" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 206.00 703.00 
                  C 206.16 707.49 204.28 711.39 205.00 716.00
                  C 224.45 714.69 256.20 724.53 252.00 695.00
                  C 238.02 697.03 221.04 700.14 206.00 703.00 Z"></path>
                  </g>
                  <g fill="#D5D5D5" fill-opacity="1.00" stroke="None">
                        <path d="
                  M 163.00 703.00 
                  C 147.70 701.59 132.77 698.20 118.00 695.00
                  C 112.85 724.99 145.81 716.11 165.00 717.00
                  C 164.71 712.23 164.04 707.56 163.00 703.00 Z"></path>
                  </g>
            </g>
      </svg>
      `,
}
