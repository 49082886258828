import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientManagementComponent } from './client-management.component'; // Import the component
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from '../header/header.component';
import { LeftSidebarComponent } from '../left-sidebar/left-sidebar.component';
import { HeaderModule } from '../header/header.module';
import { LeftSidebarModule } from '../left-sidebar/left-sidebar.module';

@NgModule({
  declarations: [
    ClientManagementComponent, // Declare the component
    // HeaderComponent, // Add this component
    // LeftSidebarComponent // Add this component
  ],
  imports: [
    CommonModule, 
    FormsModule,
    RouterModule,
    HeaderModule,
    LeftSidebarModule,
  ],
  exports: [
    ClientManagementComponent 
  ]
})
export class ClientManagementModule {}
