import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrentStateComponent } from './current-state.component';
import { FormsModule } from '@angular/forms';
import { HighchartsChartModule } from 'highcharts-angular';
import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
  declarations: [
    CurrentStateComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    HighchartsChartModule,
    ModalModule.forRoot(),

  ],
  exports: [
    CurrentStateComponent // Export the component if it needs to be used outside this module
  ]
})
export class CurrentStateModule { }
