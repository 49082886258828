import { Component, OnDestroy, OnInit } from '@angular/core';
import { NewImportExportService } from './_service/new-import-export.service';
@Component({
  selector: 'app-new-import-export',
  templateUrl: './new-import-export.component.html',
  styleUrls: ['./new-import-export.component.scss'],
})
export class NewImportExportComponent implements OnDestroy, OnInit {
  constructor(private importExpotService: NewImportExportService) {}
  ngOnInit() {
    console.log('it works');
  }

  ngOnDestroy(): void {
    this.importExpotService.setExcelToBeProcessed(null);
    this.importExpotService.setShowPdf(false);
    this.importExpotService.setCurentRapport(null);
  }
}
