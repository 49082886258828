import { Component, OnInit } from "@angular/core";
import { MileageService } from "./../../historical/mileage/mileage.service";
import { Mileage } from "./../../historical/mileage/mileage";
import { Subscription } from "rxjs";
import { Group, Vehicule } from "./../../data-management/data-management.model";
import { DataManagementService } from "./../../data-management/data-management.service";
import { ToastrService } from "ngx-toastr";
// const Highcharts = require("highcharts/highstock");
// import "highcharts/adapters/standalone-framework.src";
import * as Highcharts from 'highcharts';

import { BsLocaleService } from 'ngx-bootstrap/datepicker';
interface IMileageData {
  startHour: Date | string;
  endHour: Date | string;
}


@Component({
  selector: "app-mileagedata",
  templateUrl: "mileage-data.component.html",
  styleUrls: ["mileage-data.component.css"]
})
export class IMileageDataComponent implements OnInit {
  chart: any; 
  groups: Group[];
  selectedGroup: Group = new Group();
  selectedVehicule: Vehicule = new Vehicule();
  options: Highcharts.Options; // Options du graphique
  Highcharts: typeof Highcharts = Highcharts;
  isResultLoaded: boolean = true;

  AllGroups: Subscription;

  vehicule: Vehicule;

  distance: Object[] = [];

  Mileagedata: Mileage[];

  startDate: Date = new Date();
  endDate: Date = new Date();

  constructor(
    private mileageService: MileageService,
    private dataManagementService: DataManagementService,
    public toastr: ToastrService,
    private localeService: BsLocaleService
  ) {
    this.localeService.use("fr");
  }

  ngOnInit() {
    this.startDate.setTime(this.startDate.getTime());
    this.endDate.setTime(this.endDate.getTime() + 3600000);
    this.startDate.setHours(0,0,0,0);
    this.endDate.setHours(23,59,59,59);

    if (this.dataManagementService.groups) {
      this.groups = this.dataManagementService.groups.filter(
        group => group.vehicules.length > 0
      );
      if (this.dataManagementService.selectedGroup) {
        this.selectedGroup = this.dataManagementService.selectedGroup;
        if (this.dataManagementService.selectedVehicule) {
          this.selectedVehicule = this.dataManagementService.selectedVehicule;
        } else if (this.selectedGroup.vehicules.length > 0) {
          this.selectedVehicule = this.selectedGroup.vehicules[0];
        }
      } else {
        if (this.groups.length > 0 && this.groups) {
          this.selectedGroup = this.groups[0];
          this.dataManagementService.selectedGroup = this.selectedGroup;
        }
      }
      this.selectedVehicule = this.dataManagementService.selectedVehicule!;
    } else {
      this.AllGroups = this.dataManagementService
        .getAllGroupsDetails("")
        .subscribe(groups => {
          this.groups = groups.filter(group => group.vehicules.length > 0);
          this.dataManagementService.groups = groups;
          this.dataManagementService.setGroups(groups)
          if (this.groups.length > 0 && this.groups) {
            this.selectedGroup = this.groups[0];
            this.dataManagementService.selectedGroup = this.selectedGroup;
            if (this.selectedGroup && this.selectedGroup.vehicules.length > 0) {
              this.selectedVehicule = this.selectedGroup.vehicules[0];
              this.dataManagementService.selectedVehicule = this.selectedVehicule;
            }
          }
        });
    }
  }


  displayChart() {
    this.distance = [];
    let yaxis: Highcharts.YAxisOptions[] = [];
    
    this.mileageService
      .getAllMileages(this.selectedVehicule.idDevice, {
        startDate: this.startDate,
        endDate: this.endDate,
      })
      .subscribe((res) => {
        this.Mileagedata = res;
        this.Mileagedata.splice(this.Mileagedata.length - 1);
  
        if (this.Mileagedata.length <= 1) {
          this.toastr.warning("Pas de données à afficher");
          return;
        }
  
        this.Mileagedata.sort(this.compare);
  
        for (let i = 1; i < this.Mileagedata.length; i++) {
          let start: number = new Date(this.Mileagedata[i - 1].startHour).getTime();
          let end: number = new Date(this.Mileagedata[i].startHour).getTime();
        
          if (end - start === 3600000) {
            this.distance.push({
              x: start,
              y: this.Mileagedata[i].drivingDistance,
            });
          } else if (end - start > 3600000) {
            let j = Math.floor((end - start) / 3600000);
        
            for (let k = 0; k < j; k++) {
              let add: number = 3600000 * (k + 1);
              this.distance.push({ x: start + add, y: 0 });
            }
          }
        }
        
  
        yaxis.push({
          title: {
            text: "Distance",
            style: {
              color: "rgb(23, 119, 182)",
            },
          },
          labels: {
            format: "{value} KM",
            style: {
              color: "rgb(23, 119, 182)",
            },
          },
          min: 0,
        });
  
        this.options = {
          title: { text: "Véhicule: " + this.selectedVehicule.matricule },
          chart: {
            type: "column",
            renderTo: "container",
            height: 420,
          },
          xAxis: [
            {
              type: "datetime",
              labels: {
                formatter: function (): string {
                  return Highcharts.dateFormat(
                    "%d/%m/%Y %H:%M",
                    (this as any).value
                  );
                },
              },
              crosshair: true,
            },
          ],
          rangeSelector: {
            enabled: false,
          },
          yAxis: yaxis,
          plotOptions: {
            series: {
              turboThreshold: 5000,
            },
          },
          series: [
            {
              name: "Distance",
              type: "column", // Assurez-vous que le type est valide
              data: this.distance,
              color: "rgb(23, 119, 182)",
              xAxis: 0,
              yAxis: 0,
              tooltip: {
                valueSuffix: " KM",
                valueDecimals: 2,
              },
              dataGrouping: {
                units: [["day", [1]]],
                groupPixelWidth: 80,
              },
            } as Highcharts.SeriesColumnOptions, // Ajout du typage explicite
          ],
        } as Highcharts.Options; // Typage explicite des options
      });
  }

  compare(a: IMileageData, b: IMileageData): number {
    return new Date(a.endHour).getTime() - new Date(b.startHour).getTime();
  }



  chooseGroup(group : any) {
    this.dataManagementService.selectedGroup = group;
    this.selectedGroup = group;
    if (this.selectedGroup.vehicules.length > 0 && this.selectedGroup) {
      this.selectedVehicule = this.selectedGroup.vehicules[0];
      this.dataManagementService.selectedVehicule = this.selectedVehicule;
      this.chooseVehicule(this.selectedVehicule);
    }
  }

  chooseVehicule(vehicule: any) {
    this.selectedVehicule = vehicule;
    this.dataManagementService.selectedVehicule = vehicule;
  }
}
