export class DateInterval {
  startDate: Date | any;
  endDate: Date | any;
  timezoneOffset?: number;

  constructor(startDate?: Date | any, endDate?: Date | any) {
      this.startDate = startDate;
      this.endDate = endDate;
  }
}


export class DateIntervalDto {
  startDate: string;
  endDate: string;
}
