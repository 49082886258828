import { ToastrService } from "ngx-toastr";
import { Component, OnInit } from "@angular/core";
import { TechnicalDataService } from "./technical-data.service";
import { TechnicalData, DeviceOpt, DeviceCarb } from "./technical-data";
import { forkJoin, Subscription } from "rxjs";
import { Group, Vehicule } from "./../../data-management/data-management.model";
import { DataManagementService } from "./../../data-management/data-management.service";
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

// const Highcharts = require("highcharts/highcharts.src");
// import "highcharts/adapters/standalone-framework.src";
import * as Highcharts from 'highcharts';
interface IDeviceOpt {
  id: number;
  name: string;
}
interface IChartData {
  y?: number;  // Propriété y est optionnelle et peut être un nombre
}

@Component({
  selector: "app-technicaldata",
  templateUrl: "technical-data.component.html",
  styleUrls: ["technical-data.component.css"]
})
export class TechnicalDataComponent implements OnInit {
  //Chart vars
  chart: any;
  options: Object;
  serieName: any;

  //Device Options
  deviceopt: DeviceOpt;

  //Device Carburant
  devicecarb: DeviceCarb;

  groups: Group[];
  selectedGroup: Group = new Group();
  selectedVehicule: Vehicule = new Vehicule();
  AllGroups: Subscription;
  vehicule: Vehicule;

  technicaldata: TechnicalData[];

  startDate: Date = new Date();
  endDate: Date = new Date();

  //MultiSelelct vars

  dropdownList: any[] = [];
  selectItems: any[] = [];
  dropdownSettings = {};

  isResultLoaded: boolean = true;


  constructor(
    private technicaldataservice: TechnicalDataService,
    private dataManagementService: DataManagementService,
    public toastr: ToastrService,
    private localeService: BsLocaleService
  ) {
    this.localeService.use("fr");
  }

  ngOnInit() {
    //init multiselect
    this.dropdownSettings = {
      singleSelection: false,
      text: "Select option",
      selectAllText: "Select Tous",
      unSelectAllText: "UnSelect Tous",
      enableSearchFilter: false,
      classes: "custom-class-multiselect",
      badgeShowLimit: 1
    };

    this.startDate.setTime(this.startDate.getTime());
    this.endDate.setTime(this.endDate.getTime() + 3600000);
    this.startDate.setHours(0,0,0,0);
    this.endDate.setHours(23,59,59,59);

    if (this.dataManagementService.groups) {
      this.groups = this.dataManagementService.groups.filter(
        group => group.vehicules.length > 0
      );
      if (this.dataManagementService.selectedGroup) {
        this.selectedGroup = this.dataManagementService.selectedGroup;
        if (this.dataManagementService.selectedVehicule) {
          this.selectedVehicule = this.dataManagementService.selectedVehicule;
        } else if (this.selectedGroup.vehicules.length > 0) {
          this.selectedVehicule = this.selectedGroup.vehicules[0];
        }
      } else {
        if (this.groups.length > 0 && this.groups) {
          this.selectedGroup = this.groups[0];
          this.dataManagementService.selectedGroup = this.selectedGroup;
        }
      }
      this.selectedVehicule = this.dataManagementService.selectedVehicule;

      this.getDeviceOpt(this.selectedVehicule.idDevice);
    } else {
      this.AllGroups = this.dataManagementService
        .getAllGroupsDetails("")
        .subscribe(groups => {
          this.groups = groups.filter(group => group.vehicules.length > 0);
          this.dataManagementService.groups = groups;
          this.dataManagementService.setGroups(groups);
          if (this.groups.length > 0 && this.groups) {
            this.selectedGroup = this.groups[0];
            this.dataManagementService.selectedGroup = this.selectedGroup;
            if (this.selectedGroup && this.selectedGroup.vehicules.length > 0) {
              this.selectedVehicule = this.selectedGroup.vehicules[0];
              this.dataManagementService.selectedVehicule = this.selectedVehicule;
              this.getDeviceOpt(this.selectedVehicule.idDevice);
            }
          }
        });
    }
  }

  ngOnDestroy() {
    if (this.AllGroups) this.AllGroups.unsubscribe();
  }

  //Get Device Options and fill the dropdownList
  getDeviceOpt(deviceid: number) {
    if (deviceid <= 0) {
      this.toastr.warning("Id de boitier introuvable !", "Erreur")
      return;
    }
    this.dropdownList = [];
    this.isResultLoaded = false;
    this.technicaldataservice.getDeviceOpt(deviceid).subscribe(response => {
      this.deviceopt = response;
      this.dropdownList.push({ id: 1, option: "speed", itemName: "Vitesse" });
      if (this.deviceopt.useIgnition != 0) {
        this.dropdownList.push({
          id: 0,
          option: "ignition",
          itemName: "Ignition"
        });
      }
      if (this.deviceopt.useTemp != 0) {
        this.dropdownList.push({
          id: 2,
          option: "temp",
          itemName: "Température"
        });
      }
      if (
        this.deviceopt.useFuel != 0 ||
        this.deviceopt.useFms != 0 ||
        this.deviceopt.useJ1708 != 0
      ) {
        this.dropdownList.push({
          id: 3,
          option: "fuel",
          itemName: "Carburant"
        });
      }
      if (this.deviceopt.useFms != 0 || this.deviceopt.useJ1708 != 0) {
        this.dropdownList.push({ id: 4, option: "rpm", itemName: "RPM" });
        this.dropdownList.push({
          id: 5,
          option: "temp_engine",
          itemName: "Temp Moteur"
        });
        this.dropdownList.push({
          id: 6,
          option: "fuel_rate",
          itemName: "Consomation Inst"
        });
      }
      this.isResultLoaded = true;
    });
  }

  onItemSelect(item: any) {
  }

  displayChart() {
    const devopt: IDeviceOpt[] = this.selectItems.map(itm => itm.option);
  
    // Initialize data arrays
    const speed: any[] = [];
    const fuel: any[] = [];
    const temp: any[] = [];
    const ignition: any[] = [];
    const rpm: any[] = [];
    const temp_engine: any[] = [];
    const fuel_rate: any[] = [];
    let ok = false;
  
    // Fetch `devicecarb` and `technicaldata` simultaneously
    forkJoin({
      deviceCarb: this.technicaldataservice.getDeviceCarb(this.selectedVehicule.idDevice),
      fuelData: this.technicaldataservice.getFuelDetail(
        this.selectedVehicule.idDevice,
        { startDate: this.startDate, endDate: this.endDate },
        devopt
      )
    }).subscribe({
      next: ({ deviceCarb, fuelData }) => {
        this.devicecarb = deviceCarb;
        this.technicaldata = fuelData;
  
        if (this.technicaldata.length === 0) {
          this.toastr.warning("Pas de données à afficher");
          return;
        }
  
        // Process data
        this.technicaldata.forEach(stat => {
          if (stat.speed !== undefined) {
            speed.push({ x: stat.date, y: stat.speed });
          }
          if (stat.fuel !== undefined) {
            const min = this.devicecarb.min;
            const max = this.devicecarb.max;
            const volume = this.devicecarb.volume;
            if (max > min && stat.fuel >= min && stat.fuel <= max) {
              fuel.push({ x: stat.date, y: ((stat.fuel - min) / (max - min)) * volume });
            }
          }
          if (stat.ignition !== undefined) {
            ignition.push({ x: stat.date, y: stat.ignition ? 1 : 0 });
            ok = true;
          }
          if (stat.rpm !== undefined && ok) rpm.push({ x: stat.date, y: stat.rpm });
          if (stat.temp_engine !== undefined && ok) temp_engine.push({ x: stat.date, y: stat.temp_engine });
          if (stat.fuel_rate !== undefined && ok) fuel_rate.push({ x: stat.date, y: stat.fuel_rate });
        });
  
        // Align ignition data
        for (let i = 1; i < ignition.length; i++) {
          if (ignition[i].y !== ignition[i - 1].y) {
            ignition[i].x = ignition[i - 1].x;
          }
        }
  
        // Generate chart
        const yaxis = [];
        const  serie: Highcharts.SeriesOptionsType[] = [];
  
        if (this.ChartSeries(ignition)) {
          yaxis.push({ title: { text: "Ignition", style: { color: "rgb(255, 127, 0)" } }, min: 0, max: 1 });
          serie.push({ name: "Ignition", type: "area", color: "rgb(255, 127, 0)", data: ignition });
        }
  
        if (this.ChartSeries(speed)) {
          yaxis.push({ title: { text: "Vitesse", style: { color: "rgb(44, 161, 33)" } }, min: 0 });
          serie.push({ name: "Vitesse", data: speed, color: "rgb(44, 161, 33)"  } as Highcharts.SeriesOptionsType);
        }
  
        if (this.ChartSeries(fuel)) {
          yaxis.push({ title: { text: "Carburant", style: { color: "rgb(23, 119, 182)" } }, min: 0, opposite: yaxis.length > 0 });
          serie.push({ name: "Carburant", data: fuel, color: "rgb(23, 119, 182)" } as Highcharts.SeriesOptionsType);
        }
  
        if (this.ChartSeries(temp)) {
          yaxis.push({ title: { text: "Température", style: { color: "rgb(107, 57, 156)" } }, min: 0, opposite: yaxis.length > 0 });
          serie.push({ name: "Température", data: temp, color: "rgb(107, 57, 156)" } as Highcharts.SeriesOptionsType);
        }
  
        // Initialize Highcharts
        this.options = new Highcharts.Chart({
          chart: { renderTo: "container", type: "spline", height: 420, zoomType: "x" },
          title: { text: `Véhicule: ${this.selectedVehicule.matricule}` },
          xAxis: { type: "datetime", tickInterval: 3600 * 1000 },
          yAxis: yaxis,
          plotOptions: { series: { turboThreshold: 10000 } },
          series: serie
        });
      },
      error: err => {
        console.error("Error fetching data:", err);
        this.toastr.error("Erreur lors du chargement des données.");
      }
    });
  }

  //Return the position of Yaxis
  PositionYaxis(data: any, option: string) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].title.text === option) {
        return i;
      }
    }
  }

  //check if the series is empty or not
  ChartSeries(data: IChartData[] = []): boolean {
    let ok: boolean = false;
    data.forEach(dt => {
      if (dt.y !== undefined) {
        ok = true;
      }
    });
    return ok;
  }



  chooseGroup(group: any) {
    this.dataManagementService.selectedGroup = group;
    this.selectedGroup = group;
    if (this.selectedGroup.vehicules.length > 0 && this.selectedGroup) {
      this.selectedVehicule = this.selectedGroup.vehicules[0];
      this.dataManagementService.selectedVehicule = this.selectedVehicule;
      this.selectItems = [];
      this.chooseVehicule(this.selectedVehicule);
    }
  }

  chooseVehicule(vehicule: any) {
    this.selectedVehicule = vehicule;
    this.dataManagementService.selectedVehicule = vehicule;
    this.getDeviceOpt(this.selectedVehicule.idDevice);
    this.selectItems = [];
  }
}
