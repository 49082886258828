import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Vehicule } from 'src/app/client-management/canal-performance';
import { DataManagementService } from 'src/app/client-management/data-management';
import { RealtimeProcessingService } from 'src/app/client-management/realtime/services/real-time-processing.service';
import { RealtimeService } from 'src/app/client-management/realtime/services/realtime.service';
import { StpsService } from 'src/app/client-management/realtime/services/stops.service';
import { ToolKmJourService } from 'src/app/client-management/realtime/services/tool-km-jour.service';
import { DateInterval } from 'src/app/shared/model';
import { FormattedStopDto } from 'src/app/shared/model/sinistre.model';
import { MapService } from 'src/app/utils/leaflet/service/map.service';


@Component({
  selector: 'stops-en-cours',
  templateUrl: './stops.component.html',
  styleUrls: ['./stops.component.scss'],
}) 
export class StopsToolComponent implements OnInit, OnDestroy {
  idDevice: number;
  selectedVehicule: Vehicule;
  intervaleDate: DateInterval;
  kmJourStop: FormattedStopDto | null;
  showTrajet: boolean = false;
  clickedMarker: L.Marker | null = null;
  
  route$: Subscription;
  deviceChange$: Subscription;
  intervaleDate$: Subscription;
  selectedVehicule$: Subscription;
  kmJourStop$: Subscription;
  clickedMarker$: Subscription;

  constructor(
    private dataManagementService: DataManagementService,
    private realTimeService: RealtimeService,
    private toolKmJourService: ToolKmJourService,
    private mapService: MapService,
    private stopsService: StpsService,
    private route: ActivatedRoute,
    private realTimeProcessing: RealtimeProcessingService,
  ) {}

  ngOnInit() {
    this.setupRouting();
    this.setUpSubscriptions();
    this.realTimeService.setChangeDevice();
    this.mapSetup();
  }
  mapSetup(){
    this.mapService.controlMarkers(this.selectedVehicule.idDevice, false);
    this.mapService.removeLayers();
  }

  setupRouting() {
    const parentRoute = this.route.parent;
    if (parentRoute) {
        this.route$=  parentRoute.paramMap.subscribe((params) => {
          const idParam = params.get('id');
          if (idParam) {
            this.idDevice = +idParam;
          }
        })
    }
  }
  setUpSubscriptions(){
    this.vehiculeSubscription(); 
    this.changingDeviceMessage();
    this.setupKmJourPathSubscription();
    this.intervalDateSubscription();
    this.clickedMarkerSubscription();
  }
  changingDeviceMessage(){
    this.deviceChange$ = this.realTimeService.getDeviceChange().subscribe(v =>{
      this.featchData();
    })
  }
  intervalDateSubscription(){
    this.intervaleDate$= this.realTimeProcessing.getIntervalDate().subscribe((interval) => {
      this.toolKmJourService.setLoaded(false);
      this.intervaleDate = interval;
      this.featchData();
    });
  }
  featchData(){
    this.dataManagementService
      .getStopsInfos(this.intervaleDate, this.selectedVehicule.idDevice)
        .subscribe(kmJourStop => { 

          this.toolKmJourService.setKmJourStop(kmJourStop);
          this.printPolyline();
          this.stopsService.hideAllMarkersAndPolylines(this.clickedMarker);
      },
      error => {
        this.toolKmJourService.setKmJourStop(null);
      }, () =>{
        this.toolKmJourService.setLoaded(true);
      });
  }

  itemClicked(index: number) {
    this.stopsService.onListItemClick(index);
  }

  printPolyline() { 
    if (this.kmJourStop && this.kmJourStop.stopDto && this.kmJourStop.stopDto.points.length > 0) {
      this.stopsService.printStopsHistory(
        this.kmJourStop.stopDto.points,
        this.mapService.map
      );
    }
  }
  vehiculeSubscription(){
    this.selectedVehicule = this.realTimeService.selectedVehicule;
  }
  clickedMarkerSubscription(){
    this.clickedMarker$ = this.stopsService.getClickedMarker().subscribe(clicked => {
      this.clickedMarker = clicked;
      this.stopsService.hideAllMarkersAndPolylines(clicked);
    })
  }
  showingTrajet() {
    this.showTrajet = !this.showTrajet;
  }
  setupKmJourPathSubscription() {
    this.kmJourStop$ = this.toolKmJourService
      .getKmJourStop()
      .subscribe((kmJourStop) => {
          this.kmJourStop = kmJourStop;
      });
  }

  ngOnDestroy(): void {
    if (this.route$) {this.route$.unsubscribe();}
    if (this.deviceChange$) {this.deviceChange$.unsubscribe();}
    if (this.intervaleDate$) {this.intervaleDate$.unsubscribe();}
    if (this.selectedVehicule$) {this.selectedVehicule$.unsubscribe();}
    if (this.kmJourStop$) {this.kmJourStop$.unsubscribe();}
    if (this.clickedMarker$) {this.clickedMarker$.unsubscribe();}
    this.stopsService.clearMarkersAndLines();
    this.mapService.map.off('zoomend', this.stopsService.zoomHandler);
  }


}
