import {Component, OnInit} from '@angular/core';
import {RTRecordAccordingToDMDto, VehiculesAccordingToPoi} from "../overview";
import {OverviewService} from "../overview.service";
import { RealtimeRestService } from '../../realtime/services/realtime-rest.service';

@Component({
  selector: 'app-vehicule-according-poi',
  templateUrl: './vehicule-according-poi.component.html',
  styleUrls: ['./vehicule-according-poi.component.css']
})
export class VehiculeAccordingPoiComponent implements OnInit {

  vehiculesAccordingToPoi: VehiculesAccordingToPoi[] = [];
  selectedVehiculeAccordingToPoi: VehiculesAccordingToPoi | null= null;

  constructor(public realTimeRestService: RealtimeRestService,public overviewService:OverviewService) {
  }

  ngOnInit() {
    this.realTimeRestService.getVehiculesAccordingToPoi().subscribe(vehiculesAccordingToPoi => {
      this.vehiculesAccordingToPoi = vehiculesAccordingToPoi; 
    });

  }

}
