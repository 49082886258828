<form #scheduleForm="ngForm" class="form-horizontal">
  <div *ngIf="!isBreakDown" class="box-body">

      <div class="form-group">

        <label class="col-sm-2 control-label">Client</label>
        <!-- <div class="col-sm-10">
          <select *ngIf="pointInterests_.length > 0 && poiSelectMode" class="form-control input-sm" name="type" [(ngModel)]="schedule.name" name="name" required
            (ngModelChange)="choosePoi($event)">
            <option *ngFor="let poi of poiClient_" [ngValue]="poi.idPointInterest" style="font-size: 14px;">
              {{poi.name | capitalizefirst}}
            </option>
            <option class="autre">Autre..</option>
          </select>
          <input *ngIf="pointInterests_.length == 0 || !poiSelectMode" type="text" [(ngModel)]="schedule.name" name="name" class="form-control input-sm" placeholder="Client"
          required>
          <span *ngIf="pointInterests_.length == 0 || !poiSelectMode" style="color: #9f191f;position: absolute;top: 7px;right: 0px;" (click)="schedule.name = null;poiSelectMode = true;">
            <i class="fa fa-times"></i>
        </span>
        </div> -->
        <div class="col-sm-8">
          <div *ngIf="isPoiClient">
            <ng-multiselect-dropdown-angular7 name="poiClient" [data]="poiClient_" placeholder="select Poi"
             [settings]="poiDropdownSettings" (onSelect)="onPoiSelect($event)" (onDeSelect)="onPoiDeSelect($event)"
            required>
          </ng-multiselect-dropdown-angular7>
        </div>

          <div *ngIf="!isPoiClient">
            <input type="text" [(ngModel)]="schedule.name" name="name" class="form-control input-sm" placeholder="Autre..."
            required>
          </div>
        </div>
        <div class="col-sm-2">
          <label class="toggler-wrapper style-23">
            <input type="checkbox" [checked]="isPoiClient" (change)="clientToggler($event)">
            <div class="toggler-slider">
              <div class="toggler-knob"></div>
            </div>
          </label>

       </div>
      </div>

    <div class="form-group">

      <label class="col-sm-2 control-label">Véhicule</label>
      <div class="col-sm-8">

        <ng-multiselect-dropdown-angular7 name="scheduleSelected" [data]="scheduleList_" placeholder="select Véhicule"
          [(ngModel)]="schedule.deviceId" [settings]="vehiculesDropdownSettings" (onSelect)="onItemSelect($event)"
          required>
        </ng-multiselect-dropdown-angular7>
      </div>
      <div class="col-sm-2" *ngIf="isPoiClient">
        <label class="toggler-wrapper style-23">
          <input type="checkbox" [checked]="isAuthorSite" (change)="siteToggler($event)">
          <div class="toggler-slider authorSite">
            <div class="toggler-knob"></div>
          </div>
        </label>

     </div>
    </div>
    <div class="form-group" *ngIf="isAuthorSite && isPoiClient">

      <label class="col-sm-2 control-label">Autre Site</label>
      <div class="col-sm-8">
            <ng-multiselect-dropdown-angular7 name="authorSite" [data]="sites" placeholder="select Poi"
              [settings]="poiDropdownSettings" (onSelect)="onAutherPoiSelect($event)" (onDeSelect)="onAutherPoiDeSelect($event)"
            required>
          </ng-multiselect-dropdown-angular7>
      </div>
    </div>

    <div class="form-group">

      <label class="col-sm-2 control-label">Validateur</label>
      <div class="col-sm-10">
        <select class="form-control input-sm" name="type" [(ngModel)]="validatorId" required>
          <option *ngFor="let validator of validators_" [ngValue]="validator?.idValidator" style="font-size: 14px;">
            {{validator.completName | capitalizefirst}}
          </option>
        </select>
      </div>
    </div>

    <!-- <div class="form-group">

      <label class="col-sm-2 control-label">Emails</label>
      <div class="col-sm-10">
        <ng-multiselect-dropdown-angular7 name="emailsSelected" [(ngModel)]="selectedEmails" [data]="emailsList" placeholder="select email"
           [settings]="emailsDropdownSettings">
        </ng-multiselect-dropdown-angular7>
      </div>
    </div> -->

    <div class="form-group">
      <!-- <label class="col-lg-1 col-md-1 control-label">Emails</label> -->
      <div class="col-sm-1"></div>
      <div class="col-sm-6" style=" margin-left: 50px;">
        <!-- <input type="text" name="email" class="form-control input-sm" placeholder="Email"> -->
        <app-email-manager [showActiveSectionOfAllVehicules]="false" [showActiveSection]="false" [emailMode]="true"
          [emailsHash]="schedule.emails" (onChange)="onEmailChange($event)">
        </app-email-manager>
      </div>
      <div class="form-group" style="font-family: 'Inconsolata', monospace;">
        <button type="button" class="btn btn-success margin btn-flat pull-right" (click)="onSave()">
          <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Valider
        </button>
        <button type="button" class="btn btn-flat margin pull-right" (click)="onCancel(scheduleForm)">Annuler
        </button>
      </div>
    </div>

  </div>

  <div *ngIf="isBreakDown" class="box-body">

    <div class="has-success col-md-12">

      <label class="col-lg-1 col-md-1 control-label">Véhicule</label>
      <div class="col-lg-3 col-md-3 mrg">

        <ng-multiselect-dropdown-angular7 name="scheduleSelected" [data]="scheduleList_" placeholder="select Véhicule"
          [(ngModel)]="schedule.deviceId" [settings]="vehiculesDropdownSettings" (onSelect)="onItemSelect($event)"
          required>
        </ng-multiselect-dropdown-angular7>
      </div>

      <div class="col-lg-3 col-md-3 mrg">
        <select class="form-control input-sm" name="type" [(ngModel)]="schedule.active" required>
          <option value=true>
            Oui
          </option>
          <option value=false>
            Non
          </option>
        </select>
      </div>

      <div class="col-lg-2 col-md-2">
        <button type="submit" class="btn btn-info pull-right" (click)="onSaveBreakDown()"
          [disabled]="!scheduleForm.form.valid || loading">
          <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> Valider
        </button>
      </div>
    </div>
  </div>

</form>